import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteTrainingTemplateMutationRequest from './get-delete-training-template-mutation-request'

import { IUseDeleteMutation, IDeleteFn } from '../../interfaces'
import { ITrainingTemplate } from '../../interfaces'

const useDeleteTrainingTemplateMutation: IUseDeleteMutation<ITrainingTemplate, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteTrainingTemplateMutationRequest(params), [params])

  const deleteTrainingTemplate: IDeleteFn<ITrainingTemplate> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITrainingTemplate, Error, any>({ mutationFn: deleteTrainingTemplate, ...options })

  return response
}

export default useDeleteTrainingTemplateMutation
