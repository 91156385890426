import axios from 'axios'
import config from '../../config'

import { IFeatureFlags } from '../../interfaces'

const updateAirportFeatures = async ({ airportId, features }: { airportId: number; features: IFeatureFlags }) => {
  const url = `${config.baseUrl}/Airports/${airportId}/Features`
  return await axios.put<IFeatureFlags>(url, features)
}

export default updateAirportFeatures
