import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'

import config from '../../config'

import { IPostRequestObject } from '../../interfaces'

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'safetyconcerns'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const getDownloadSafetyConcernsCSVQueryRequest = (): IPostRequestObject => ({
  method: 'post',
  url: `${config.baseUrl}/SafetyConcerns/Download`,
})

const useDownloadSafetyConcernsCSVQuery = (
  options: UseMutationOptions<Blob, Error, any> = {},
): UseMutationResult<Blob, Error, any> => {
  const request = useMemo(() => getDownloadSafetyConcernsCSVQueryRequest(), [])

  const fetchCSVFile = useCallback(
    async (data: any): Promise<Blob> =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchCSVFile,
    ...options,
  })

  return response
}

export default useDownloadSafetyConcernsCSVQuery
