import config from '../../config'

import { IGetSortFacilitiesMutationRequest } from './get-sort-facilities-mutation-request-types'

const getSortFacilitiesMutationRequest: IGetSortFacilitiesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Facilities/Sort`,
  }
}

export default getSortFacilitiesMutationRequest
