import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISafetyItem, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  id: number
}

const getSafetyItemByIdQueryRequest = (params: Params): IGetRequestObject => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/SafetyItems/${id}`,
  }
}

const useSafetyItemByIdQuery = buildGetQuery<Params, undefined, ISafetyItem | undefined>({
  getRequest: getSafetyItemByIdQueryRequest,
  getQueryKey: params => ['safety-item-by-id', params.id],
  defaultOptions: { initialData: undefined },
})

export default useSafetyItemByIdQuery
