import React, { useRef } from 'react'
import cn from 'classnames'
import { func } from 'prop-types'

import s from './with-addons.module.scss'

const withAddons = Component => props => {
  const { addonAfter, addonBefore, ...componentProps } = props

  const fieldDecoratorClassName = cn('input-group')
  const addoneBeforeClassName = cn(s['addon-before'], 'input-group-text')
  const addonAfterClassName = cn(s['addon-after'], 'input-group-text')

  const componentRef = useRef(null)

  return (
    <div className={fieldDecoratorClassName}>
      {addonBefore && <span className={addoneBeforeClassName}>{addonBefore(componentRef)}</span>}

      {Component && <Component {...componentProps} componentRef={componentRef} />}

      {addonAfter && <span className={addonAfterClassName}>{addonAfter(componentRef)}</span>}
    </div>
  )
}

export const addonsPropTypes = {
  addonBefore: func,
  addonAfter: func,
}

export default withAddons
