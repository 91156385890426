import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateInspectionMutationRequest from './get-update-inspection-mutation-request'
import enrichInspection from './enrich-inspection'

import { IUseUpdateMutation, IUpdateFn, IInspection, IInspectionUpdateUpsert } from '../../interfaces'

const useUpdateInspectionMutation: IUseUpdateMutation<IInspection, IInspectionUpdateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateInspectionMutationRequest(params), [params])

  const updateInspection: IUpdateFn<IInspectionUpdateUpsert, IInspection> = useCallback(
    async (data: IInspectionUpdateUpsert) => axios({ ...request, data }).then(res => enrichInspection(res.data)),
    [request],
  )

  const response = useMutation<IInspection, Error, IInspectionUpdateUpsert>({
    mutationFn: updateInspection,
    ...options,
  })

  return response
}

export default useUpdateInspectionMutation
