import { useRef } from 'react'
import { useClickOutside } from '../../hooks/use-click-outside'
import { useModal } from '../../hooks/use-modal'

const useActionsButton = () => {
  const { isOpen, closeModal, toggleModal } = useModal()
  const buttonRef = useRef(null)

  useClickOutside(buttonRef, closeModal)

  return { isOpen, toggleModal, buttonRef }
}

export default useActionsButton
