import { buildGetQuery } from '../../util'
import config from '../../config'

import { IGetGetRequest, IWorkOrder } from '../../interfaces'

type Params = {
  airportId: number
  id: number
}

const getCarryOversByInspectionIdQueryRequest: IGetGetRequest = params => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WorkOrders/CarryOvers/${id}`,
  }
}

const useCarryOversByInspectionIdQuery = buildGetQuery<Params, undefined, IWorkOrder[] | undefined>({
  getRequest: getCarryOversByInspectionIdQueryRequest,
  getQueryKey: params => ['carry-overs-by-inspection-id', params?.airportId, params?.airportId],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useCarryOversByInspectionIdQuery
