import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { UseMutationResult, UseMutationOptions } from '@tanstack/react-query'

import getSortChecklistTemplatesMutationRequest, {
  IGetSortChecklistTemplatesMutationRequestParams,
} from './get-sort-checklist-templates-mutation-request'

export interface ISortChecklistTemplates {
  (data: any): Promise<Blob>
}

export interface IUseSortChecklistTemplatesMutation {
  (
    params: IGetSortChecklistTemplatesMutationRequestParams,
    options?: UseMutationOptions<any, Error, any>,
  ): UseMutationResult<any, Error, any>
}

const useSortChecklistTemplatesMutation: IUseSortChecklistTemplatesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortChecklistTemplatesMutationRequest(params), [params])

  const sortChecklistTemplates: ISortChecklistTemplates = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortChecklistTemplates,
    ...options,
  })

  return response
}

export default useSortChecklistTemplatesMutation
