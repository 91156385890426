import { IActivityLogValues } from './activity-log-form-types'
import { IActivityLogAddUpsert } from '../../../../interfaces'

export const activityLogValuesToActivitylogAddUpsert = (
  activityLogValues: IActivityLogValues,
): IActivityLogAddUpsert | undefined => {
  if (!activityLogValues) {
    return undefined
  }

  return {
    ...activityLogValues,
    id: 0,
  }
}
