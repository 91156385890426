import config from '../../config'

import { IGetSortWorkOrderTypesMutationRequest } from './get-sort-work-order-types-mutation-request-types'

const getSortWorkOrderTypesMutationRequest: IGetSortWorkOrderTypesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/WorkOrderTypes/Sort`,
  }
}

export default getSortWorkOrderTypesMutationRequest
