import { validateSoftRequired, validateRequired } from '../../../../util/validation'

import {
  NONE_FREQUENCY,
  DAILY_FREQUENCY,
  WEEKLY_FREQUENCY,
  MONTHLY_FREQUENCY,
  YEARLY_FREQUENCY,
} from '../../../../features/admin-inspections/reminders-table.constants'

import { IReminderFormUpsertValues, IReminderFormValues } from './reminder-form-types'
import { IReminder, IReminderAddUpsert, IReminderUpdateUpsert } from '../../../../interfaces'

export const validateReminderValue = (reminderValues: IReminderFormValues) => {
  let errorObject: any = {
    reminder: {
      reminderFrequencyId: undefined,
      name: undefined,

      daily: {
        separationCount: undefined,
      },

      weekly: {
        separationCount: undefined,
        dayOfWeek: undefined,
      },

      monthly: {
        type: undefined,

        type1: {
          dayOfMonth: undefined,
          separationCount: undefined,
        },
        type2: {
          weekOfMonth: undefined,
          dayOfWeek: undefined,
        },
      },

      yearly: {
        type: undefined,
        separationCount: undefined,

        type1: {
          monthOfYear: undefined,
          dayOfMonth: undefined,
        },
        type2: {
          weekOfMonth: undefined,
          dayOfWeek: undefined,
          monthOfYear: undefined,
        },
      },

      startDate: undefined,
      endDate: undefined,
    },
  }

  errorObject.reminder.reminderFrequencyId = validateSoftRequired(reminderValues?.reminder?.reminderFrequencyId)

  // Daily validation
  if (reminderValues?.reminder?.reminderFrequencyId === DAILY_FREQUENCY) {
    errorObject.reminder.daily.separationCount = validateRequired(reminderValues?.reminder?.daily?.separationCount)
  }

  // Weekly validation
  if (reminderValues?.reminder?.reminderFrequencyId === WEEKLY_FREQUENCY) {
    errorObject.reminder.weekly.separationCount = validateRequired(reminderValues?.reminder?.weekly?.separationCount)

    errorObject.reminder.weekly.dayOfWeek = validateRequired(reminderValues?.reminder?.weekly?.dayOfWeek)
  }

  // Monthly validation
  if (reminderValues?.reminder?.reminderFrequencyId === MONTHLY_FREQUENCY) {
    errorObject.reminder.monthly.type = validateRequired(reminderValues?.reminder?.monthly?.type)

    if (reminderValues?.reminder?.monthly?.type === 'type1') {
      errorObject.reminder.monthly.type1.dayOfMonth = validateRequired(
        reminderValues?.reminder?.monthly?.type1?.dayOfMonth,
      )

      errorObject.reminder.monthly.type1.separationCount = validateRequired(
        reminderValues?.reminder?.monthly?.type1?.separationCount,
      )
    } else if (reminderValues?.reminder?.monthly?.type === 'type2') {
      // Monthly type2 validation
      errorObject.reminder.monthly.type2.weekOfMonth = validateRequired(
        reminderValues?.reminder?.monthly?.type2?.weekOfMonth,
      )

      errorObject.reminder.monthly.type2.dayOfWeek = validateRequired(
        reminderValues?.reminder?.monthly?.type2?.dayOfWeek,
      )
    }
  }

  // Yearly validation
  if (reminderValues?.reminder?.reminderFrequencyId === YEARLY_FREQUENCY) {
    errorObject.reminder.yearly.type = validateRequired(reminderValues?.reminder?.yearly?.type)

    errorObject.reminder.yearly.separationCount = validateRequired(reminderValues?.reminder?.yearly?.separationCount)

    if (reminderValues?.reminder?.yearly?.type === 'type1') {
      errorObject.reminder.yearly.type1.monthOfYear = validateRequired(
        reminderValues?.reminder?.yearly?.type1?.monthOfYear,
      )

      errorObject.reminder.yearly.type1.dayOfMonth = validateRequired(
        reminderValues?.reminder?.yearly?.type1?.dayOfMonth,
      )
    } else if (reminderValues?.reminder?.yearly?.type === 'type2') {
      // yearly type2 validation
      errorObject.reminder.yearly.type2.weekOfMonth = validateRequired(
        reminderValues?.reminder?.yearly?.type2?.weekOfMonth,
      )

      errorObject.reminder.yearly.type2.dayOfWeek = validateRequired(reminderValues?.reminder?.yearly?.type2?.dayOfWeek)

      errorObject.reminder.yearly.type2.monthOfYear = validateRequired(
        reminderValues?.reminder?.yearly?.type2?.monthOfYear,
      )
    }
  }

  errorObject.reminder.startDate = validateSoftRequired(reminderValues?.reminder?.startDate)

  return errorObject
}

export const reminderToReminderFormValues = (reminder: IReminder): IReminderFormUpsertValues | undefined => {
  if (!reminder) {
    return undefined
  }

  let reminderFormUpsertValues: IReminderFormUpsertValues = {
    reminder: {
      reminderFrequencyId: reminder?.reminderFrequencyId,
      startDate: reminder?.startDate,
      endDate: reminder?.endDate,
    },
  }

  if (!reminderFormUpsertValues.reminder) {
    return undefined
  }

  switch (reminder?.reminderFrequencyId) {
    case NONE_FREQUENCY: {
      break
    }
    case DAILY_FREQUENCY: {
      reminderFormUpsertValues.reminder.daily = {
        separationCount: String(reminder?.separationCount),
      }
      break
    }
    case WEEKLY_FREQUENCY: {
      reminderFormUpsertValues.reminder.weekly = {
        separationCount: String(reminder?.separationCount),
        dayOfWeek: reminder?.dayOfWeek,
      }
      break
    }
    case MONTHLY_FREQUENCY: {
      const monthType = reminder?.dayOfMonth && reminder?.separationCount ? 'type1' : 'type2'

      reminderFormUpsertValues.reminder.monthly = {
        type: monthType,
      }

      if (monthType === 'type1') {
        reminderFormUpsertValues.reminder.monthly[monthType] = {
          dayOfMonth: reminder?.dayOfMonth,
          separationCount: String(reminder?.separationCount),
        }
      } else {
        reminderFormUpsertValues.reminder.monthly[monthType] = {
          weekOfMonth: reminder?.weekOfMonth,
          dayOfWeek: reminder?.dayOfWeek,
        }
      }

      break
    }
    case YEARLY_FREQUENCY: {
      const yearType = reminder?.monthOfYear && reminder?.dayOfWeek && reminder?.weekOfMonth ? 'type2' : 'type1'

      reminderFormUpsertValues.reminder.yearly = {
        type: yearType,
      }

      reminderFormUpsertValues.reminder.yearly.separationCount = String(reminder?.separationCount)

      if (yearType === 'type1') {
        reminderFormUpsertValues.reminder.yearly[yearType] = {
          monthOfYear: reminder?.monthOfYear,
          dayOfMonth: reminder?.dayOfMonth,
        }
      } else {
        reminderFormUpsertValues.reminder.yearly[yearType] = {
          weekOfMonth: reminder?.weekOfMonth,
          dayOfWeek: reminder?.dayOfWeek,
          monthOfYear: reminder?.monthOfYear,
        }
      }
      break
    }
    default: {
    }
  }

  return reminderFormUpsertValues
}

export const reminderFormValuesToReminderAddUpsert = (
  reminderFormValues: IReminderFormValues,
): IReminderAddUpsert | null => {
  try {
    let reminderAddUpsert: IReminderAddUpsert = {
      id: 0,
      name: undefined,
      dayOfMonth: undefined,
      dayOfWeek: undefined,
      maximumOccurrences: undefined,
      monthOfYear: undefined,
      separationCount: 0,
      weekOfMonth: undefined,
      description: undefined,
      reminderFrequencyId: reminderFormValues.reminder.reminderFrequencyId,
      startDate: reminderFormValues.reminder.startDate,
      endDate: reminderFormValues.reminder.endDate,
      gracePeriodInDays: 0,
      isActive: true,
    }

    if (reminderAddUpsert) {
      switch (reminderFormValues.reminder.reminderFrequencyId) {
        case NONE_FREQUENCY: {
          break
        }
        case DAILY_FREQUENCY: {
          reminderAddUpsert.separationCount = reminderFormValues?.reminder?.daily?.separationCount
          break
        }
        case WEEKLY_FREQUENCY: {
          reminderAddUpsert.separationCount = reminderFormValues?.reminder?.weekly?.separationCount
          reminderAddUpsert.dayOfWeek = reminderFormValues?.reminder?.weekly?.dayOfWeek
          break
        }
        case MONTHLY_FREQUENCY: {
          const monthType = reminderFormValues?.reminder?.monthly?.type

          if (monthType === 'type1') {
            reminderAddUpsert.dayOfMonth = reminderFormValues?.reminder?.monthly?.[monthType]?.dayOfMonth
            reminderAddUpsert.separationCount = reminderFormValues?.reminder?.monthly?.[monthType]?.separationCount
          } else if (monthType === 'type2') {
            reminderAddUpsert.weekOfMonth = reminderFormValues?.reminder?.monthly?.[monthType]?.weekOfMonth
            reminderAddUpsert.dayOfWeek = reminderFormValues?.reminder?.monthly?.[monthType]?.dayOfWeek
          }

          break
        }
        case YEARLY_FREQUENCY: {
          reminderAddUpsert.separationCount = reminderFormValues?.reminder?.yearly?.separationCount

          const yearType = reminderFormValues?.reminder?.yearly?.type

          if (yearType === 'type1') {
            reminderAddUpsert.monthOfYear = reminderFormValues?.reminder?.yearly?.[yearType]?.monthOfYear
            reminderAddUpsert.dayOfMonth = reminderFormValues?.reminder?.yearly?.[yearType]?.dayOfMonth
          } else if (yearType === 'type2') {
            reminderAddUpsert.weekOfMonth = reminderFormValues?.reminder?.yearly?.[yearType]?.weekOfMonth
            reminderAddUpsert.dayOfWeek = reminderFormValues?.reminder?.yearly?.[yearType]?.dayOfWeek
            reminderAddUpsert.monthOfYear = reminderFormValues?.reminder?.yearly?.[yearType]?.monthOfYear
          }
          break
        }
        default: {
        }
      }
    }

    return reminderAddUpsert
  } catch (err) {
    console.error('Couldnt parse reminder values to reminder', err)
    return null
  }
}

export const reminderFormValuesToReminderUpdateUpsert = (
  reminderFormValues: IReminderFormValues,
  reminder: IReminder,
): IReminderUpdateUpsert | null => {
  const trainingtemplateUpdateUpsert = reminderFormValuesToReminderAddUpsert(reminderFormValues)

  if (!trainingtemplateUpdateUpsert) {
    return null
  }

  return { ...trainingtemplateUpdateUpsert, id: reminder.id }
}
