import { FC, useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { MarkerClusterer, InfoWindow } from '@react-google-maps/api'
import { ClustererOptions } from '@react-google-maps/marker-clusterer'

import { clusterIconStyles } from './marker-layout-constants'
import useMarkerIcons, { ACTIVE_MARKER_COLOR, DEFAULT_MARKER_COLOR } from './hooks/use-marker-icons'
import Marker from './marker'

import { useGoogleMaps } from './google-maps-provider'

import { IMarkerLayoutProps } from './layout-types'
import { IGeoJson } from '../../interfaces/services/geo-json'

const markerClustererOptions: ClustererOptions = {
  averageCenter: true,
  maxZoom: 15,
  styles: clusterIconStyles,
}

const defaultGetId = (geoJson: IGeoJson) => geoJson?.properties?.id

const MarkerLayout: FC<IMarkerLayoutProps> = props => {
  const {
    geoJson,
    renderPopUp,
    activeId,
    setActiveId,
    editingGeoJsonId,
    renderEditPopUp,
    onUpdate,
    isClustering = false,
    infoId,
    setInfoId,
    visible,
    getId = defaultGetId,
  } = props

  const { isMeasuring } = useGoogleMaps()

  const [activeMarkerId, setActiveMarkerId] = useState<number | undefined>(undefined)

  const _activeMarkerId = useMemo(() => activeId || activeMarkerId, [activeId, activeMarkerId])

  const _setActiveMarkerId = useCallback(
    (id: number | undefined) => (setActiveId ? setActiveId(id) : setActiveMarkerId(id)),
    [setActiveId, setActiveMarkerId],
  )

  const [infoMarkerId, setInfoMarkerId] = useState<number | undefined>(undefined)

  const _infoMarkerId = useMemo(() => infoId || infoMarkerId, [infoId, infoMarkerId])

  const _setInfoMarkerId = useCallback(
    (id: number | undefined) => (setInfoId ? setInfoId(id) : setInfoMarkerId(id)),
    [setInfoId, setInfoMarkerId],
  )

  const infoMarker = useMemo(
    () => (_infoMarkerId ? geoJson.find(geoJsonItem => getId(geoJsonItem) === _infoMarkerId) : null),
    [getId, _infoMarkerId, geoJson],
  )

  const icons = useMarkerIcons({ geoJson })

  const onMarkerClick = useCallback(
    (geoJson: IGeoJson) => {
      _setActiveMarkerId(getId(geoJson))
      _setInfoMarkerId(getId(geoJson))
    },
    [getId, _setActiveMarkerId, _setInfoMarkerId],
  )

  const renderMarkers = useCallback(
    (clusterer?: any) => (
      <>
        {geoJson.map(geoJson => {
          const icon =
            getId(geoJson) === _activeMarkerId
              ? icons[ACTIVE_MARKER_COLOR]
              : icons[geoJson.properties.color || DEFAULT_MARKER_COLOR]

          return (
            <Marker
              key={getId(geoJson)}
              geoJson={geoJson}
              onClick={!isMeasuring ? onMarkerClick : undefined}
              icon={icon}
              editable={editingGeoJsonId === getId(geoJson)}
              renderEditPopUp={renderEditPopUp}
              onUpdate={onUpdate}
              clusterer={clusterer}
              visible={visible}
            />
          )
        })}
      </>
    ),
    [
      isMeasuring,
      getId,
      _activeMarkerId,
      editingGeoJsonId,
      icons,
      geoJson,
      onUpdate,
      onMarkerClick,
      renderEditPopUp,
      visible,
    ],
  )

  const markerClustererRef = useRef<any>()
  useEffect(() => {
    if (markerClustererRef.current) {
      setTimeout(() => markerClustererRef.current.repaint(), 200)
    }
  }, [visible, geoJson])

  return (
    <>
      {isClustering ? (
        <MarkerClusterer
          options={markerClustererOptions}
          ignoreHidden
          onLoad={(markerClusterer: any) => {
            markerClustererRef.current = markerClusterer
          }}
        >
          {clusterer => renderMarkers(clusterer)}
        </MarkerClusterer>
      ) : (
        renderMarkers()
      )}

      {infoMarker && getId(infoMarker) !== editingGeoJsonId && renderPopUp && (
        <InfoWindow
          position={{
            lng: infoMarker.geometry.coordinates[0] as number,
            lat: infoMarker.geometry.coordinates[1] as number,
          }}
          onCloseClick={() => {
            _setActiveMarkerId(undefined)
            _setInfoMarkerId(undefined)
          }}
          options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
        >
          {renderPopUp && <>{renderPopUp(infoMarker, { setInfoId: _setInfoMarkerId })}</>}
        </InfoWindow>
      )}
    </>
  )
}

export default MarkerLayout
