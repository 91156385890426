import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import { getPlaceholderTextFromLabel } from '../../../../util'
import { validateRequired } from '../../../../util/validation'

import { useChecklistTemplatesQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { IChecklistFormProps } from './checklist-form-types'

const subTitleLabel = 'Subtitle'

const ChecklistForm: FC<IChecklistFormProps> = ({ handleSubmit, submitting, formName }) => {
  const { airportId } = useProfile()

  const { data: checklistTemplates } = useChecklistTemplatesQuery({ airportId }, { activeOnly: true })

  const checklistTemplatesOptions = useMemo(
    () =>
      (checklistTemplates || []).map(checklistTemplate => ({
        value: checklistTemplate.id,
        label: checklistTemplate.name,
      })),
    [checklistTemplates],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Select
        name="templateId"
        options={checklistTemplatesOptions}
        label="Template"
        validate={validateRequired}
        disabled={submitting}
      />
      <RFFFields.Input
        name="subName"
        label={subTitleLabel}
        placeholder={getPlaceholderTextFromLabel(subTitleLabel)}
        disabled={submitting}
      />
    </form>
  )
}

export default ChecklistForm
