import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IAuthClaim, IGetRequestObject } from '../../interfaces'

type QueryObject = {
  activeOnly?: boolean
}

const getAuthClaimsRequest = (_: undefined, queryObject: QueryObject | undefined): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/AuthClaims${queryString}`,
  }
}

const useAuthClaimsQuery = buildGetQuery<undefined, QueryObject | undefined, IAuthClaim[] | undefined>({
  getRequest: getAuthClaimsRequest,
  getQueryKey: (_, queryObject) => ['auth-claims', queryObject?.activeOnly || ''],
})

export default useAuthClaimsQuery
