import { useEffect, lazy, Suspense, useMemo } from 'react'
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import AppTitle from './app-title'
import config from './config'
import { AuthProvider } from './security'
import { TelemetryProvider } from './telemetry'
import withAppContext from './with-app-context'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { ai } from './telemetry/telemetry-service'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PrivateRoute } from './security/private-route'
import HealthCheck from './components/health-check/health-check'
import { useAppContext } from './app-context'
import { showNewVersionAlert, GoogleMapsProvider } from './components'
import NavigationMenu from './components/navigation-menu/navigation-menu'
import SafetyConcern from './features/safety-concern/safety-concern'

import './app.scss'
import './app-layout.scss'
import './css/bootstrap.min.scss'
import './css/style.bundle.scss'

const Inspections = lazy(() => import('./features/inspections/inspections'))
const Dashboard = lazy(() => import('./features/dashboard/dashboard'))
const Admin = lazy(() => import('./features/admin/admin'))
const SuperAdmin = lazy(() => import('./features/super-admin/super-admin'))

const Spinner = lazy(() => import('./components/spinner/spinner'))
const Footer = lazy(() => import('./components').then(module => ({ default: module.Footer })))
const Alert = lazy(() => import('./components/alert/alert'))
const Error = lazy(() => import('./features/error'))
const Search = lazy(() => import('./features/search/search'))
const FAQ = lazy(() => import('./features/faq/faq'))
const EditInspection = lazy(() => import('./features/inspections/edit-inspection'))
const AddDiscrepancy = lazy(() => import('./features/discrepancies/add-edit-discrepancy/add-discrepancy-page'))
const EditDiscrepancy = lazy(() => import('./features/discrepancies/add-edit-discrepancy/edit-discrepancy-page'))
const Journal = lazy(() => import('./features/journal/journal'))
const Profile = lazy(() => import('./features/profile/profile'))
const Reports = lazy(() => import('./features/reports/reports-page'))
const WorkOrdersLanding = lazy(() => import('./features/work-orders/work-orders-landing'))
const CheckLists = lazy(() => import('./features/checklists/checklists-page'))
const ChecklistPage = lazy(() => import('./features/checklists/checklist-page'))
const TrainingRecordsPage = lazy(() => import('./features/trainings/trainings-routes'))
const UpdateTrainingRecordPage = lazy(() =>
  import('./features/trainings/update-training-record/update-training-record'),
)
const UpdateMyTrainingPage = lazy(() => import('./features/trainings/update-my-training/update-my-training'))
const ReleaseNotes = lazy(() => import('./features/release-notes/release-notes'))
const NotFound = lazy(() => import('./features'))
const SafetyReport = lazy(() => import('./features/safety-report/safety-report'))

const queryClient = new QueryClient()

const baseTitle = 'App 139 -'

const baseSafetyConcernPath = '/safety-concern'

const AppContent = () => {
  const location = useLocation()

  const isSafetyReport = useMemo(() => location.pathname === '/safety-report', [location.pathname])

  if (isSafetyReport) {
    return (
      <>
        <GoogleMapsProvider apiKey={config.googleMaps.apiKey} libraries={config.googleMaps.libraries}>
          <SafetyReport />
        </GoogleMapsProvider>

        <div className="alert-overlay-container">
          <div className="alert-overlay">
            <Alert timeoutInMilliseconds={config.alert.timeoutInMilliseconds} />
          </div>
        </div>
      </>
    )
  }

  return (
    <AuthProvider>
      <div className="app-139-page">
        <header className="w-100">
          <NavigationMenu />
        </header>

        <Suspense
          fallback={
            <div className="vh-100">
              <Spinner />
            </div>
          }
        >
          <main className="app-139-main">
            <Routes>
              <Route
                path="/healthcheck"
                element={
                  <AppTitle title={`${baseTitle} Health Check`}>
                    <HealthCheck />
                  </AppTitle>
                }
              />

              <Route path="/signin-oidc" element={<div />} />

              <Route
                path="/safety-report"
                element={
                  <AppTitle title={`${baseTitle} Report Safety Concern`}>
                    <SafetyReport />
                  </AppTitle>
                }
              />

              <Route element={<PrivateRoute />}>
                <Route
                  path="/"
                  element={
                    <AppTitle title={`${baseTitle} Dashboard`}>
                      <Dashboard />
                    </AppTitle>
                  }
                />

                <Route
                  path={`${baseSafetyConcernPath}/*`}
                  element={<SafetyConcern baseTitle={baseTitle} basePath={baseSafetyConcernPath} />}
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Admin`}>
                      <Admin />
                    </AppTitle>
                  }
                  path="admin/*"
                />
                <Route
                  element={
                    <AppTitle title={`${baseTitle} Hero`}>
                      <SuperAdmin />
                    </AppTitle>
                  }
                  path="hero/*"
                />
                <Route
                  element={
                    <AppTitle title={`${baseTitle} FAQ`}>
                      <FAQ />
                    </AppTitle>
                  }
                  path="faq"
                />
                <Route path="inspections/*">
                  <Route
                    element={
                      <AppTitle title={`${baseTitle} Inspections - List`}>
                        <Inspections />
                      </AppTitle>
                    }
                    index
                  />
                  <Route
                    element={
                      <AppTitle title={`${baseTitle} Inspection - Edit`}>
                        <EditInspection />
                      </AppTitle>
                    }
                    path=":inspectionId"
                  />
                  <Route
                    element={
                      <AppTitle title={`${baseTitle} Inspection - Add Discrepancy`}>
                        <AddDiscrepancy />
                      </AppTitle>
                    }
                    path=":inspectionId/discrepancy"
                  />
                  <Route
                    element={
                      <AppTitle title={`${baseTitle} Inspection - Edit Disrepancy`}>
                        <EditDiscrepancy />
                      </AppTitle>
                    }
                    path=":inspectionId/discrepancy/:discrepancyId"
                  />
                </Route>
                <Route
                  element={
                    <AppTitle title={`${baseTitle} Journal`}>
                      <Journal />
                    </AppTitle>
                  }
                  path="/journal"
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Training Records`}>
                      <TrainingRecordsPage />
                    </AppTitle>
                  }
                  path="/trainings/*"
                />

                <Route
                  path="/trainings/records/update/:trainingId"
                  element={
                    <AppTitle title={`${baseTitle} Update Training Record`}>
                      <UpdateTrainingRecordPage />
                    </AppTitle>
                  }
                />

                <Route
                  path="/trainings/my-trainings/update/:trainingSessionId"
                  element={
                    <AppTitle title={`${baseTitle} Update My Training`}>
                      <UpdateMyTrainingPage />
                    </AppTitle>
                  }
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Profile`}>
                      <Profile />
                    </AppTitle>
                  }
                  path="profile/*"
                />
                <Route
                  path="reports/*"
                  element={
                    <AppTitle title={`${baseTitle} Reports`}>
                      <Reports />
                    </AppTitle>
                  }
                />
                <Route
                  element={
                    <AppTitle title={`${baseTitle} Search`}>
                      <Search />
                    </AppTitle>
                  }
                  path="search/*"
                />
                <Route
                  element={
                    <AppTitle title={`${baseTitle} Work Orders - List`}>
                      <WorkOrdersLanding />
                    </AppTitle>
                  }
                  path="work-orders/*"
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Checklists`}>
                      <CheckLists />
                    </AppTitle>
                  }
                  path="checklists"
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Checklists`}>
                      <ChecklistPage />
                    </AppTitle>
                  }
                  path="checklists/:checklistId"
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Release Notes`}>
                      <ReleaseNotes />
                    </AppTitle>
                  }
                  path="release-notes/*"
                />

                <Route
                  element={
                    <AppTitle title={`${baseTitle} Not Found`}>
                      <NotFound />
                    </AppTitle>
                  }
                  path="*"
                />
              </Route>
            </Routes>
          </main>
        </Suspense>

        <footer className="app-139-footer px-3 py-0 w-100">
          <Footer />
        </footer>

        <div className="alert-overlay-container">
          <div className="alert-overlay">
            <Alert timeoutInMilliseconds={config.alert.timeoutInMilliseconds} />
          </div>
        </div>
      </div>
    </AuthProvider>
  )
}

const App = () => {
  const { dispatch: appDispatch } = useAppContext()

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const scripts = document.getElementsByTagName('script')
        const bundlePathPrefix = '/static/js/'
        const mainBundlePrefix = 'main.'
        if (scripts?.length > 0) {
          for (let i = 0; i < scripts.length; i++) {
            const script = scripts[i]
            if (script.src.includes(`${bundlePathPrefix}${mainBundlePrefix}`)) {
              const index = script.src.indexOf(bundlePathPrefix)
              const currentBundle = script.src.substring(index + bundlePathPrefix.length)
              fetch(`/index.html?timestamp=${Date.now()}`)
                .then(response => response.text())
                .then(response => {
                  const bundleUpdated =
                    response.includes(`${bundlePathPrefix}${mainBundlePrefix}`) && !response.includes(currentBundle)
                  if (bundleUpdated) {
                    appDispatch(showNewVersionAlert())
                  }
                })
              break
            }
          }
        }
      } catch {
        console.error('Something went wrong while checking for new version.')
      }
    }, 60000)
    return () => clearInterval(interval)
  }, [appDispatch])

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={Error}>
          <TelemetryProvider config={config.appInsights}>
            <AppContent />
          </TelemetryProvider>
        </ErrorBoundary>
      </BrowserRouter>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default withAITracking(ai.reactPlugin, withAppContext(App), 'App', 'app-139-page')
