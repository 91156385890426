import { createContext, useContext, useState, useCallback } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { useMapCenter } from './hooks/use-map-center'

const GoogleMapsContext = createContext({
  isLoaded: false,
  loadError: Error,
  centerCoordinates: undefined,
  setCenterCoordinates: coords => {},
  mapMode: 'undefined',
  setMapMode: mode => {},
  measureTool: null,
  isMeasuring: false,
  setMeasureTool: measureTool => {},
  setIsMeasuring: isMeasuring => {},
  onStartMeasure: () => {},
  onStopMeasure: () => {},
})

export const GoogleMapsProvider = ({ children, ...loadScriptOptions }) => {
  const DEFAULT_ZOOM = 15
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: loadScriptOptions.apiKey,
    libraries: loadScriptOptions.libraries,
  })

  const [centerCoordinates, setCenterCoordinates] = useMapCenter(undefined)

  const [mapMode, setMapMode] = useState('clear')
  const [cachedMapMode, setCachedMapMode] = useState(mapMode)

  const [zoom, setZoom] = useState(DEFAULT_ZOOM)

  const [map, setMap] = useState(null)

  const [measureTool, setMeasureTool] = useState(null)

  const [isMeasuring, setIsMeasuring] = useState(false)

  const onStartMeasure = useCallback(() => {
    if (measureTool) {
      setCachedMapMode(mapMode)
      measureTool.start()
    }
  }, [mapMode, measureTool])

  const onStopMeasure = useCallback(() => {
    if (measureTool) {
      setMapMode(cachedMapMode)
      measureTool?.end()
    }
  }, [cachedMapMode, measureTool])

  return (
    <GoogleMapsContext.Provider
      value={{
        isLoaded,
        loadError,
        centerCoordinates,
        setCenterCoordinates,
        mapMode,
        setMapMode,
        zoom,
        setZoom,
        DEFAULT_ZOOM,
        map,
        setMap,
        measureTool,
        isMeasuring,
        setIsMeasuring,
        setMeasureTool,
        onStartMeasure,
        onStopMeasure,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  )
}

export const useGoogleMaps = () => useContext(GoogleMapsContext)
