export function isPart139(category) {
  return category === 1
}

export function isSPI(category) {
  return category === 2
}

export function getShortName(category) {
  if (isPart139(category)) {
    return '139'
  }

  if (isSPI(category)) {
    return 'SPI'
  }

  return ''
}
