import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { ICondition, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
  facilityIds?: number[]
}

const getConditionsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Conditions${queryString}`,
  }
}

const useConditionsQuery = buildGetQuery<Params, QueryObject | undefined, ICondition[] | undefined>({
  getRequest: getConditionsRequest,
  getQueryKey: (params, queryObject) => [
    'conditions',
    params.airportId,
    queryObject?.activeOnly,
    queryObject?.facilityIds,
  ],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useConditionsQuery
