import React, { useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'

type ReportPreviewRendererProps = {
  pageIndex: any
  content: any
  styles: any
}

const ReportPreviewRenderer: React.FC<ReportPreviewRendererProps> = props => {
  const { pageIndex, content, styles } = props

  const iframeRef = useRef(null)

  useEffect(() => {
    if (iframeRef.current) {
      // @ts-ignore
      const iframeDoc = iframeRef.current.contentDocument

      // Append the styles to the iframe's head
      styles.forEach((styleElement: any) => {
        const styleNode = iframeDoc.createElement(styleElement.type)
        if (styleElement.type === 'style') {
          styleNode.innerHTML = styleElement.props.dangerouslySetInnerHTML.__html
        } else if (styleElement.type === 'link') {
          styleNode.setAttribute('rel', 'stylesheet')
          styleNode.setAttribute('href', styleElement.props.href)
        }
        iframeDoc.head.appendChild(styleNode)
      })

      // Create a container div and append it to the iframe's body
      const appContainer = iframeDoc.createElement('div')
      iframeDoc.body.innerHTML = '' // Clear any previous content
      iframeDoc.body.appendChild(appContainer)

      // Render the content into the new container
      const container = React.createElement('div', {}, content[pageIndex])

      // Use createRoot to mount the component
      const root = createRoot(appContainer)
      root.render(container)
    }
  }, [content, styles, pageIndex])

  return (
    <div className="d-flex flex-column h-100">
      <iframe ref={iframeRef} title="Report Preview" style={{ width: '100%', height: '100%' }} />
    </div>
  )
}

export default ReportPreviewRenderer
