import { Form } from 'react-final-form'

import { SafetyConcernForm as SafetyConcernFormComponent } from '../../../components'

const SafetyConcernForm = (props: any) => {
  const { onSubmit, initialValues, formName, additionalOptions, baseStatus, setForm, onChange, disabled, isViewMode } =
    props

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      component={SafetyConcernFormComponent}
      formName={formName}
      baseStatus={baseStatus}
      additionalOptions={additionalOptions}
      setForm={setForm}
      onChange={onChange}
      disabled={disabled}
      isViewMode={isViewMode}
      keepDirtyOnReinitialize
    />
  )
}

export default SafetyConcernForm
