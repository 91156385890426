import { useState, useCallback } from 'react'

export const useModal = (options = { isOpenByDefault: false }) => {
  const [isOpen, setIsOpen] = useState(options.isOpenByDefault)

  const toggleModal = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen])

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen])

  return { isOpen, toggleModal, closeModal, openModal }
}
