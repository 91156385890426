import { QueryObserverOptions } from '@tanstack/react-query'
import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISafetyConcern, IGetRequestObject, EntityState } from '../../interfaces'

type QueryObject = {
  entityState: EntityState
}

const getSafetyConcernsRequest = (params: undefined, queryObject: QueryObject | undefined): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/SafetyConcerns${queryString}`,
  }
}

const useSafetyConcernsQueryBase = buildGetQuery<undefined, QueryObject | undefined, ISafetyConcern[] | undefined>({
  getRequest: getSafetyConcernsRequest,
  getQueryKey: (params, queryObject) => ['safety-concerns', queryObject?.entityState],
})

const useSafetyConcernsQuery = (
  queryObject?: QueryObject | undefined,
  options?: QueryObserverOptions<ISafetyConcern[] | undefined, Error>,
) => useSafetyConcernsQueryBase(undefined, queryObject, options)

export default useSafetyConcernsQuery
