const isEmptyFilterValue = value => {
  if (Array.isArray(value) && value.length === 0) return true

  if (value === 'All') return true

  if (value === '') return true

  if (!value && typeof value !== 'boolean') return true

  return false
}

export const clearFilter = filter =>
  Object.keys(filter).reduce((clearFilter, filterKey) => {
    const isEmpty = isEmptyFilterValue(filter[filterKey])

    if (!isEmpty) {
      clearFilter[filterKey] = filter[filterKey]
    }

    return clearFilter
  }, {})
