import { IWorkOrder } from '../interfaces'

const getWorkOrderAuditInfo = (workOrder: IWorkOrder) => ({
  startedAt: workOrder.workOrderStartedAt,
  startedById: workOrder.workOrderStartedBy,
  startedByName: workOrder.workOrderStartedByName,
  endedAt: workOrder.workOrderEndedAt,
  endedById: workOrder.workOrderEndedBy,
})

export default getWorkOrderAuditInfo
