import React, { useCallback } from 'react'
import cn from 'classnames'

import Input from '../form/input/input'
import Button from '../button/button'

import s from './search-input.module.scss'

export type ISearchInputProps = {
  inputClassName?: string
  wrapperClassName?: string
  onChange: (val: string) => any
  placeholder?: string
  value?: string
  disabled?: boolean
}

const SearchInput: React.FC<ISearchInputProps> = props => {
  const { wrapperClassName, inputClassName, onChange, placeholder, ...restProps } = props

  const handleClear = useCallback(() => {
    onChange && onChange('')
  }, [onChange])

  return (
    <div className={cn('position-relative', wrapperClassName)}>
      <i className={cn('bi bi-search', s['search-icon'])} />

      <Input
        className={cn(s['search-input-preview'], inputClassName)}
        placeholder={placeholder}
        onChange={onChange}
        {...restProps}
      />

      <Button className={s['close-icon-button']} type="ghost-icon" onClick={handleClear} buttonType="button">
        <i className={cn('bi bi-x-lg', s['close-icon'])} />
      </Button>
    </div>
  )
}

SearchInput.defaultProps = {
  inputClassName: '',
  onChange: () => {},
  placeholder: 'Start typing to filter...',
  value: '',
  wrapperClassName: '',
}

export default SearchInput
