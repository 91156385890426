import config from '../../config'

import { IGetSortConditionsMutationRequest } from './get-sort-conditions-mutation-request-types'

const getSortConditionsMutationRequest: IGetSortConditionsMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Conditions/Sort`,
  }
}

export default getSortConditionsMutationRequest
