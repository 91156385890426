import { useCallback, useState } from 'react'

const useCheckboxTree = props => {
  const { onChange, value: values } = props

  const [expandedValues, setExpandedValues] = useState([])

  const toggleTreeNodeExpand = useCallback(
    value => {
      let computedExpandedValues = []

      if (expandedValues.indexOf(value) > -1) {
        computedExpandedValues = expandedValues.filter(expandedValue => expandedValue !== value)
      } else {
        computedExpandedValues = [...expandedValues, value]
      }

      setExpandedValues(computedExpandedValues)
    },
    [expandedValues],
  )

  const handleChange = useCallback(
    (node, checked) => {
      let computedValues

      if (checked) {
        const nodeIndex = values.findIndex(v => v.value === node.value)

        if (nodeIndex !== -1) {
          computedValues = [...values.slice(0, nodeIndex), node, ...values.slice(nodeIndex + 1)]
        } else {
          computedValues = [...values, node]
        }
      } else {
        computedValues = values.filter(v => v.value !== node.value)
      }

      onChange(computedValues)
    },
    [onChange, values],
  )

  const [filter, setFilter] = useState({})

  return { handleChange, filter, setFilter, expandedValues, toggleTreeNodeExpand }
}

export default useCheckboxTree
