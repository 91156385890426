import { validateReminderValue } from '../reminder-form/reminder-form.utils'
import {
  reminderToReminderFormValues,
  reminderFormValuesToReminderAddUpsert,
  reminderFormValuesToReminderUpdateUpsert,
} from '../reminder-form'

import {
  ISavedSearchFilterReminderFormValues,
  ISavedSearchFilterReminderUpsertFormValues,
} from './saved-search-filter-reminder-form-types'
import {
  ISavedSearchFilterReminder,
  ISavedSearchFilterReminderAddUpsert,
  ISavedSearchFilterReminderUpdateUpsert,
} from '../../../../interfaces'

export const validateSavedSearchFilterReminderValue = (
  savedSearchFilterReminderValues: ISavedSearchFilterReminderFormValues,
) => {
  let errorObject: any = {
    userIds: undefined,
    sortOrder: undefined,
    reminder: {},
  }

  errorObject.reminder = validateReminderValue(savedSearchFilterReminderValues)?.reminder

  const isUsersAbsent =
    !savedSearchFilterReminderValues?.userIds || savedSearchFilterReminderValues?.userIds?.length === 0
  const isNotificationGroupsAbsent =
    !savedSearchFilterReminderValues?.reminder?.notificationGroupIds ||
    savedSearchFilterReminderValues?.reminder?.notificationGroupIds?.length === 0

  if (isUsersAbsent && isNotificationGroupsAbsent) {
    errorObject.reminder.notificationGroupIds = 'You must select at least one user or department'
    errorObject.userIds = 'You must select at least  one user or department'
  }

  return errorObject
}

export const savedSearchFilterReminderToSavedSearchFilterReminderFormValues = (
  savedSearchFilterReminder?: ISavedSearchFilterReminder,
): ISavedSearchFilterReminderUpsertFormValues | undefined => {
  if (!savedSearchFilterReminder) {
    return savedSearchFilterReminder
  }

  let savedSearchFilterReminderFormValues = {
    featureId: savedSearchFilterReminder.savedSearchFilterType,
    savedSearchFilterId: savedSearchFilterReminder.savedSearchFilterId,
    userIds: savedSearchFilterReminder.userIds,

    reminder: {
      ...reminderToReminderFormValues(savedSearchFilterReminder.reminder)?.reminder,
      notificationGroupIds: savedSearchFilterReminder.reminder.notificationGroupIds,
      name: savedSearchFilterReminder.reminder.name,
      description: savedSearchFilterReminder.reminder.description,
      isActive: savedSearchFilterReminder.reminder.isActive,
    },
  }

  return savedSearchFilterReminderFormValues
}

export const savedSearchFilterReminderFormValuesToSavedSearchFilterReminderAddUpsert = (
  savedSearchFilterReminderUpsert: ISavedSearchFilterReminderFormValues,
): ISavedSearchFilterReminderAddUpsert | undefined => {
  if (!savedSearchFilterReminderUpsert) {
    return savedSearchFilterReminderUpsert
  }

  let savedSearchFilterReminderAddUpsert = {
    id: 0,
    reminderId: 0,
    feature: savedSearchFilterReminderUpsert.featureId,
    savedSearchFilterId: savedSearchFilterReminderUpsert.savedSearchFilterId,
    userIds: savedSearchFilterReminderUpsert.userIds,

    reminder: {
      ...reminderFormValuesToReminderAddUpsert(savedSearchFilterReminderUpsert),
      id: 0,
      notificationGroupIds: savedSearchFilterReminderUpsert.reminder.notificationGroupIds,
      name: savedSearchFilterReminderUpsert.reminder.name,
      description: savedSearchFilterReminderUpsert.reminder.description,
      isActive: savedSearchFilterReminderUpsert.reminder.isActive,
    },
  }

  return savedSearchFilterReminderAddUpsert
}

export const savedSearchFilterReminderFormValuesToSavedSearchFilterReminderUpdateUpsert = (
  savedSearchFilterReminderUpsert: ISavedSearchFilterReminderFormValues,
  savedSearchFilterReminder: ISavedSearchFilterReminder,
): ISavedSearchFilterReminderUpdateUpsert | undefined => {
  if (!savedSearchFilterReminderUpsert) {
    return savedSearchFilterReminderUpsert
  }

  let savedSearchFilterReminderUpdateUpsert = {
    id: savedSearchFilterReminder.id,
    reminderId: savedSearchFilterReminder.reminder.id,
    feature: savedSearchFilterReminder.savedSearchFilterType,
    savedSearchFilterId: savedSearchFilterReminder.savedSearchFilterId,
    userIds: savedSearchFilterReminderUpsert.userIds,

    reminder: {
      ...reminderFormValuesToReminderUpdateUpsert(savedSearchFilterReminderUpsert, savedSearchFilterReminder.reminder),
      id: savedSearchFilterReminder.reminder.id,
      notificationGroupIds: savedSearchFilterReminderUpsert.reminder.notificationGroupIds,
      name: savedSearchFilterReminderUpsert.reminder.name,
      description: savedSearchFilterReminderUpsert.reminder.description,
      note: savedSearchFilterReminderUpsert.reminder.description,
      isActive: savedSearchFilterReminderUpsert.reminder.isActive,
    },
  }

  return savedSearchFilterReminderUpdateUpsert
}
