import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateChecklistMutationRequest: IGetPutRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Checklists/instances`,
  }
}

export default getUpdateChecklistMutationRequest
