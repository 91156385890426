import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortInspectionSearchFilter } from '../../../../interfaces'
import { IInspectionFilterValues, IInspectionFilterUpsertValues } from './inspection-filter-form-types'

export const objectToInspectionFilterValues = (object?: { [key: string]: any }): IInspectionFilterUpsertValues => ({
  dateRange: { 0: object?.startedAtBegin || '', 1: object?.startedAtEnd || '' },
  inspectorIds: object?.inspectorIds || [],
  inspectionCategoryIds: object?.inspectionCategoryIds || [],
  inspectionTypeIds: object?.inspectionTypeIds || [],
  inspectionSubtypeIds: object?.inspectionSubtypeIds || [],
  searchText: object?.searchText || '',
})

export const savedSearchFilterToSavedInspectionFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): IInspectionFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let inspectionFilterObject
  try {
    inspectionFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    inspectionFilterObject = {}
  }

  const inspectionFilterValues = objectToInspectionFilterValues(inspectionFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    inspectionFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return inspectionFilterValues
}

export const savedInspectionFilterValuesToSavedSearchFilterAddUpsert = (
  savedInspectionFilterValues: IInspectionFilterValues | undefined,
): IShortInspectionSearchFilter => ({
  startedAtBegin: savedInspectionFilterValues?.dateRange?.[0] || '',
  startedAtEnd: savedInspectionFilterValues?.dateRange?.[1] || '',
  inspectorIds: savedInspectionFilterValues?.inspectorIds || [],
  inspectionCategoryIds: savedInspectionFilterValues?.inspectionCategoryIds || [],
  inspectionTypeIds: savedInspectionFilterValues?.inspectionTypeIds || [],
  inspectionSubtypeIds: savedInspectionFilterValues?.inspectionSubtypeIds || [],
  searchText: savedInspectionFilterValues?.searchText || '',
})

export const savedInspectionFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedInspectionFilterValues: IInspectionFilterValues | undefined,
): IShortInspectionSearchFilter => ({
  startedAtBegin: savedInspectionFilterValues?.dateRange?.[0] || '',
  startedAtEnd: savedInspectionFilterValues?.dateRange?.[1] || '',
  inspectorIds: savedInspectionFilterValues?.inspectorIds || [],
  inspectionCategoryIds: savedInspectionFilterValues?.inspectionCategoryIds || [],
  inspectionTypeIds: savedInspectionFilterValues?.inspectionTypeIds || [],
  inspectionSubtypeIds: savedInspectionFilterValues?.inspectionSubtypeIds || [],
  searchText: savedInspectionFilterValues?.searchText || '',
})
