import { useEffect } from 'react'

const useFavicon = (faviconUrl?: string) => {
  useEffect(() => {
    if (!faviconUrl) {
      return
    }

    let link: any = document.querySelector("link[rel~='icon']")

    if (!link) {
      link = document.createElement('link')
      if (link) {
        link.rel = 'icon'
        document.head.appendChild(link)
      }
    }
    link.href = faviconUrl
  }, [faviconUrl])
}

export default useFavicon
