import { useCallback, useState, useRef, useEffect } from 'react'

import { useWindowEvent } from './use-window-event'

const getIsMobileView = () => window.innerWidth <= 992

export const useContentMapLayout = ({ defaultShowMapOnFullScreen = true, enabled = true }: any) => {
  const [showContent, setShowContent] = useState(true)

  const [showMap, setShowMap] = useState(getIsMobileView() ? false : defaultShowMapOnFullScreen)

  const [isMobileView, setIsMobileView] = useState(getIsMobileView())

  const prevIsMobileView = useRef(isMobileView)

  const toggleView = useCallback(() => {
    if (!enabled) {
      return
    }

    if (isMobileView) {
      setShowContent(showContent => !showContent)
      setShowMap((showMap: boolean) => !showMap)
    } else {
      setShowMap((showMap: boolean) => !showMap)
    }
  }, [isMobileView, enabled])

  const handleResize = useCallback(() => {
    if (!enabled) {
      return
    }
    setTimeout(() => {
      setIsMobileView(getIsMobileView())
    }, 500)
  }, [enabled])

  useEffect(() => {
    if (!enabled) {
      return
    }

    if (prevIsMobileView.current !== isMobileView) {
      if (isMobileView) {
        // Window view to mobile - hide map
        setShowMap(false)
      } else {
        // Mobile view to window - show content if it was hidden
        if (!showContent) {
          setShowContent(true)
        }
        // Show map if it should be rendered on window view
        setShowMap(defaultShowMapOnFullScreen)
      }
    }

    prevIsMobileView.current = isMobileView
  }, [isMobileView, showContent, setShowMap, defaultShowMapOnFullScreen, enabled])

  useWindowEvent('resize', handleResize, enabled)

  return { showContent, showMap, toggleView }
}
