import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useChecklistFilterQueries from './use-checklist-filter-queries'

import { IChecklistFilterFormFiledsProps, IChecklistFilterValues } from './checklist-filter-form-types'

const nameof = nameofFactory<IChecklistFilterValues>()

const dateRangeLabel = 'Checklist Date Range'
const inspectorLabel = 'Inspector'
const typeLabel = 'Type'
const searchTextLabel = 'Text Search'

const ChecklistFilterFormFields: React.FC<IChecklistFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const { authUsersQuery, authUsersOptions, checklistTemplatesQuery, checklistTemplatesOptions } =
    useChecklistFilterQueries(values)

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('dateRange')}
            label={dateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(dateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('createdBy')}
            label={inspectorLabel}
            placeholder={getPlaceholderTextFromLabel(inspectorLabel)}
            options={authUsersOptions}
            disabled={disabled || authUsersQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('templateIds')}
            label={typeLabel}
            placeholder={getPlaceholderTextFromLabel(typeLabel)}
            options={checklistTemplatesOptions}
            disabled={disabled || checklistTemplatesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.SearchInput
            name={nameof('searchText')}
            label={searchTextLabel}
            placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChecklistFilterFormFields
