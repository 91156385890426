import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'

import { useAddTagMutation, useTagsQuery } from '../../services'
import { bySortOrderAscending } from '../../util'
import { RFFFields, showErrorAlert } from '../../components'
import { useProfile } from '../../security'
import { ITag, ITagAddUpsert, TagCategory } from '../../interfaces'
import { useQueryClient } from '@tanstack/react-query'
import { useClickOutside } from '../../hooks/use-click-outside'
import { useAppContext } from '../../app-context'

type TagsProps = {
  addSelectedTagIds: Function
  tagCategory?: TagCategory
}

export const Tags: React.FC<TagsProps> = ({ addSelectedTagIds, tagCategory = TagCategory.ActivityLog }) => {
  const { airportId } = useProfile()
  const { dispatch } = useAppContext()
  const selectRef = useRef(null)
  // @ts-ignore
  const { data: tags = [], isLoading, isFetching, queryKey } = useTagsQuery({ airportId }, { tagCategory })

  const sortedTags = useMemo(() => tags.sort(bySortOrderAscending), [tags])

  const focus = useCallback(() => {
    if (selectRef.current) {
      // @ts-ignore
      selectRef.current.focus()
    }
  }, [])

  const tagOptions = useMemo(
    () => [
      ...sortedTags.map((tag: ITag) => ({
        label: tag.name,
        value: tag.id,
      })),
    ],
    [sortedTags],
  )

  const queryClient = useQueryClient()

  const onCreateSuccess = useCallback(
    (tag: ITag) => {
      addSelectedTagIds([tag.id])
      queryClient.invalidateQueries({ queryKey })
    },
    [addSelectedTagIds, queryClient, queryKey],
  )

  const { mutate: createTag, isLoading: isTagCreating } = useAddTagMutation(
    { airportId },
    {
      onSuccess: onCreateSuccess,
      onError: (error: any) => {
        if (error.request.status === 409) {
          dispatch(showErrorAlert('Label already exists'))
        } else {
          dispatch(showErrorAlert('Error while creating label'))
        }
      }
    },
  )

  const onCreateNewTag = useCallback(
    (input: string) => {
      const newTag: ITagAddUpsert = {
        name: input,
        tagCategory: tagCategory,
        airportId: airportId,
      }
      createTag(newTag)
    },
    [airportId, createTag, tagCategory],
  )

  const isDisabled = isLoading || isFetching || isTagCreating

  const [isFocused, setIsFocused] = useState(false)

  const wrapperRef = useRef()
  useClickOutside(wrapperRef, () => setIsFocused(false))

  const prevIsFocusedRef = useRef(isFocused)
  const prevIsDisabledRef = useRef(isDisabled)

  useEffect(() => {
    if (prevIsFocusedRef.current && prevIsDisabledRef.current && !isDisabled) {
      focus()
    }

    prevIsFocusedRef.current = isFocused
    prevIsDisabledRef.current = isDisabled
  }, [focus, isDisabled, isFocused])

  return (
    // @ts-ignore
    <div ref={wrapperRef}>
      <RFFFields.Select
        innerRef={selectRef}
        name="tagIds"
        label="Labels"
        canCreate={true}
        onCreateOption={onCreateNewTag}
        onCreateSuccess={onCreateSuccess}
        options={tagOptions}
        disabled={isLoading || isFetching || isTagCreating}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isMulti
      />
    </div>
  )
}

export default Tags
