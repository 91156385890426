import React, { useCallback, useEffect, useState } from 'react'
import { createPopper, preventOverflow } from '@popperjs/core'

import ButtonFilteredDropdownBlanket from './button-filtered-dropdown-blanket'
import ButtonFilteredDropdownMenu from './button-filtered-dropdown-menu'

type ButtonFilteredDropdownSelectProps = {
  buttonRef: any
  children: any
  className: string
  isOpen: boolean
  target: any
  onClose: Function
}

const ButtonFilteredDropdownSelect: React.FC<ButtonFilteredDropdownSelectProps> = ({
  buttonRef,
  children,
  className,
  isOpen,
  target,
  onClose,
}) => {
  const [dropdownRef, setDropdownRef] = useState(null)
  const [popperInstance, setPopperInstance] = useState(null)
  const [isPopperReady, setIsPopperReady] = useState(false)

  const dropdownRefCB = useCallback(
    (node: any) => {
      if (node !== null) {
        setDropdownRef(node)
        if (dropdownRef) {
          const popperInstance = createPopper(buttonRef, dropdownRef, {
            placement: 'bottom-end',
            modifiers: [preventOverflow],
          })
          // @ts-ignore
          setPopperInstance(popperInstance)
          setIsPopperReady(true)
          // forces children to reposition properly
          popperInstance.forceUpdate()
        }
      }
      return node
    },
    [buttonRef, dropdownRef],
  )

  useEffect(() => {
    if (!isOpen) {
      setIsPopperReady(false)
      if (popperInstance) {
        // @ts-ignore
        popperInstance.destroy()
      }
    }
  }, [isOpen, popperInstance])

  // @ts-ignore
  return (
    <div className={className}>
      {target}
      {isOpen && (
        <>
          <ButtonFilteredDropdownMenu menuref={dropdownRefCB} isPopperReady={isPopperReady}>
            {children}
          </ButtonFilteredDropdownMenu>
          <ButtonFilteredDropdownBlanket onClick={onClose} className="" />
        </>
      )}
    </div>
  )
}

export default ButtonFilteredDropdownSelect
