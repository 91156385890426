import getPersonalizationMapStatusesTypeRequest from './get-personalization-map-statuses-type-request'
import useAxios from '../use-axios'

const usePersonalizationMapStatuses = ({ airportId }) => {
  const request = getPersonalizationMapStatusesTypeRequest({ airportId })

  const { data = [], error, loading, refetch } = useAxios(request)

  return { data, error, loading, execute: refetch }
}

export default usePersonalizationMapStatuses
