import { FC, useMemo } from 'react'

import { HideShowButton, Fields } from '../../../components'

import { useSafetyConcernStatusesQuery, usePrioritiesQuery, EntityState } from '../../../services'
import { useModal } from '../../../hooks'
import { toOptions } from '../../../util'
import { useProfile } from '../../../security'

import s from './safety-concerns-filter.module.scss'

type SafetyConcernFilterProps = {
  searchFilter: {
    searchText: string
    filterType?: string
    statusIds: number[]
    priorityIds: number[]
    sortBy?: number
    isSortByAsc: boolean
  }
  setSearchFilter: (values: { [key: string]: any }) => void
}

const filterTypeOptions = [
  { value: 'status', label: 'Status' },
  { value: 'priority', label: 'Priority' },
]

const sortByOptions = [
  { value: 1, label: 'Status' },
  { value: 2, label: 'Priority' },
]

const SafetyConcernFilter: FC<SafetyConcernFilterProps> = props => {
  const { searchFilter, setSearchFilter } = props

  const { airportId } = useProfile()

  const {
    isOpen: isFilterVisible,
    openModal: openFilter,
    closeModal: closeFilter,
  } = useModal({ isOpenByDefault: true })

  const isPrioritiesFilterEnabled = searchFilter.filterType === 'priority'

  const isStatusesFilterEnabled = searchFilter.filterType === 'status'

  const statusesQuery = useSafetyConcernStatusesQuery(
    { activeOnly: true, entityState: EntityState.Open },
    { enabled: isStatusesFilterEnabled },
  )

  const statusesOptions = useMemo(() => toOptions(statusesQuery?.data), [statusesQuery?.data])

  const prioritiesQuery = usePrioritiesQuery({ airportId }, { activeOnly: true })

  const prioritiesOptions = useMemo(() => toOptions(prioritiesQuery?.data), [prioritiesQuery?.data])

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <HideShowButton onHide={closeFilter} onShow={openFilter} show={isFilterVisible} text="Filter" />

        <div className="d-flex align-items-center">
          <span className="pr-2">Sort by:</span>

          <Fields.Select
            componentProps={{
              placeholder: 'Enter Sort By',
              wrapperClassName: 'mb-0',
              options: sortByOptions,
              onChange: (sortBy: number) => setSearchFilter({ sortBy }),
              value: searchFilter.sortBy,
            }}
          />

          {searchFilter.sortBy && (
            <button
              className="btn btn-link min-w-60px pl-1"
              onClick={() => setSearchFilter({ isSortByAsc: !searchFilter.isSortByAsc })}
            >
              {searchFilter.isSortByAsc ? 'Desc' : 'Asc'}
            </button>
          )}
        </div>
      </div>

      {isFilterVisible && (
        <div className="mb-4">
          <Fields.SearchInput
            componentProps={{
              wrapperClassName: 'mb-2',
              placeholder: 'Enter Text Search',
              maxLength: 50,
              onChange: (searchText: string) => setSearchFilter({ searchText }),
              value: searchFilter.searchText,
            }}
          />

          <div className="d-flex justify-content-between mt-2 flex-grow-0">
            <div className={s['filter-type-select']}>
              <Fields.Select
                componentProps={{
                  placeholder: 'Enter Filter Type',
                  wrapperClassName: 'mb-0',
                  options: filterTypeOptions,
                  onChange: (filterType: string) => setSearchFilter({ filterType }),
                  value: searchFilter.filterType,
                }}
              />
            </div>

            <div className={s['filter-type-definition']}>
              {isPrioritiesFilterEnabled && (
                <Fields.Select
                  componentProps={{
                    placeholder: 'Enter Priorities',
                    wrapperClassName: 'mb-0',
                    options: prioritiesOptions,
                    onChange: (priorityIds: number[]) => setSearchFilter({ priorityIds }),
                    value: searchFilter.priorityIds,
                    isMulti: true,
                  }}
                />
              )}

              {isStatusesFilterEnabled && (
                <Fields.Select
                  componentProps={{
                    placeholder: 'Enter Statuses',
                    wrapperClassName: 'mb-0',
                    options: statusesOptions,
                    onChange: (statusIds: number[]) => setSearchFilter({ statusIds }),
                    value: searchFilter.statusIds,
                    isMulti: true,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SafetyConcernFilter
