import { FC } from 'react'
import { Form } from 'react-final-form'

import { AuditInfoForm as AuditInfoFormComponent, IAuditInfoFormValues, IAuditInfoUpsertFormValues } from '../form'

type AuditInfoFormProps = {
  onSubmit: (values: IAuditInfoFormValues) => void
  initialValues?: IAuditInfoUpsertFormValues
  formName: string
  isEnded?: boolean
}

const AuditInfoForm: FC<AuditInfoFormProps> = props => {
  const { onSubmit, initialValues, formName, isEnded } = props

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      formName={formName}
      component={AuditInfoFormComponent}
      isEnded={isEnded}
      keepDirtyOnReinitialize
    />
  )
}

export default AuditInfoForm
