import { useMemo } from 'react'
import ReactDatePicker from 'react-datepicker'
import cn from 'classnames'
import { format as fnsFormat } from 'date-fns'

import config from '../../../config'
import { dateStringToDate } from './date-picker-utils'
import DatePickerInput from './date-picker-input'
import DatePickerHeader from '../common/date/date-picker-header'
import 'react-datepicker/dist/react-datepicker.css'
import '../common/date/date-picker.css'

import s from '../common/date/date-picker.module.scss'

import TimePicker from './time-picker'

const DatePicker = props => {
  const { onChange, value = '', isInvalid, isDateOnly, name, isViewMode = false, shouldCloseOnSelect=false, ...datePickerProps } = props

  const visualFormat = useMemo(() => {
    if (isDateOnly) return config.dateAndTimeFormats.visualDate

    return `${config.dateAndTimeFormats.visualDate} ${config.dateAndTimeFormats.visualTimeFull}`
  }, [isDateOnly])

  const dateFormat = useMemo(() => {
    return isDateOnly ? config.dateAndTimeFormats.date : config.dateAndTimeFormats.dateAndTime
  }, [isDateOnly])

  const inputClassNames = useMemo(
    () =>
      cn('form-control', {
        'border-danger': isInvalid,
      }),
    [isInvalid],
  )

  const datePickerInputType = useMemo(() => {
    return isDateOnly ? 'date' : 'date-and-time'
  }, [isDateOnly])

  const date = useMemo(() => dateStringToDate(value, dateFormat), [value, dateFormat])

  if (isViewMode) {
    return <div className={s['date-picker-view']}>{date && fnsFormat(new Date(date), visualFormat)}</div>
  }

  return (
    <div className={s['date-picker-wrapper']}>
      <ReactDatePicker
        renderCustomHeader={DatePickerHeader}
        calendarClassName={s.calendar}
        className={inputClassNames}
        weekDayClassName={() => s['calendar-week-day']}
        dayClassName={() => s['calendar-day']}
        customInput={<DatePickerInput type={datePickerInputType} />}
        onChange={date => onChange(fnsFormat(date, dateFormat))}
        selected={date}
        showTimeInput={!isDateOnly}
        customTimeInput={<TimePicker />}
        disabledKeyboardNavigation
        autoComplete="off"
        shouldCloseOnSelect={shouldCloseOnSelect}
        dateFormat={visualFormat}
        locale="en"
        name={name}
        {...datePickerProps}
      />
    </div>
  )
}

export default DatePicker
