import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import {
  useSafetyConcernStatusesQuery,
  usePrioritiesQuery,
} from '../../../../services'

const useSafetyConcernFilterQueries = () => {
  const { airportId } = useProfile()

  const statusesQuery = useSafetyConcernStatusesQuery({ activeOnly: true })

  const statusOptions = useMemo(
    () =>
      (statusesQuery?.data || []).map(status => ({
        label: status.name,
        value: status.id,
      })),
    [statusesQuery?.data],
  )

  const prioritiesQuery = usePrioritiesQuery({ airportId }, { activeOnly: true }, { refetchOnWindowFocus: false })

  const priorityOptions = useMemo(
    () =>
      (prioritiesQuery?.data || []).map(priority => ({
        label: priority.name,
        value: priority.id,
      })),
    [prioritiesQuery?.data],
  )

  const booleanSearchOptions = [
    { label: 'All', value: 0 },
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
  ]

  return {
    statusesQuery,
    statusOptions,

    prioritiesQuery,
    priorityOptions,

    booleanSearchOptions
  }
}

export default useSafetyConcernFilterQueries
