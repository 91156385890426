import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteInspectionDiscrepancyMutationRequest from './get-delete-inspection-discrepancy-mutation-request'

import { IUseDeleteMutation, IDeleteFn } from '../../interfaces'
import { IDiscrepancy } from '../../interfaces'

const useDeleteInspectionDiscrepancyMutation: IUseDeleteMutation<IDiscrepancy, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteInspectionDiscrepancyMutationRequest(params), [params])

  const deleteInspectionDiscrepancy: IDeleteFn<IDiscrepancy> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<IDiscrepancy, Error, any>({ mutationFn: deleteInspectionDiscrepancy, ...options })

  return response
}

export default useDeleteInspectionDiscrepancyMutation
