import getExternalNotams from './get-external-notams'
import useAxios from '../use-axios'

const useNotams = () => {
  const request = getExternalNotams()

  const { data = [], error, loading, makeRequest: execute } = useAxios(request)

  const response = {
    data,
    error,
    loading,
    execute,
  }

  return response
}

export default useNotams
