import { IGetPolygonCoordinates } from './get-polygon-coordinates-types'

export const getPolygonCoordinates: IGetPolygonCoordinates = polygon => {
  const polygonPosition = polygon.getPath().getArray()

  if (!polygonPosition) {
    return null
  }

  return [polygonPosition.map(x => [x.lng(), x.lat()])]
}
