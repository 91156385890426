import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import { getPlaceholderTextFromLabel } from '../../../../util'
import { validateRequired } from '../../../../util/validation'

import { useAuthUsersSelectListQuery, useTrainingByIdQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { ITrainingSessionFormProps } from './training-session-form-types'

const trainerLabel = 'Trainer'
const noteLabel = 'Note'
const attendeeLabel = 'Attendee'

const TrainingSessionForm: FC<ITrainingSessionFormProps> = ({
  handleSubmit,
  submitting,
  formName,
  isStartedAtVisible,
  isEndedAtVisible,
  disabled,
  values,
  initialValues,
  ...rest
}) => {
  const { airportId } = useProfile()

  const { data: users } = useAuthUsersSelectListQuery({ airportId }, undefined)

  const { data: training, isLoading: isTrainingLoading } = useTrainingByIdQuery(
    { id: values.trainingId },
    { includeDetail: true },
    { enabled: values.trainingId !== undefined },
  )

  const trainerOptions = useMemo(() => {
    return (users || []).map(user => ({
      value: user.id,
      label: user.userName,
    }))
  }, [users])

  const userOptions = useMemo(() => {
    if (!training || isTrainingLoading) {
      return []
    }

    const filteredUsers = (users || []).map(user => {
      const isAttendee = training.details.attendedUserIds?.includes(user.id)

      let isDisabled = isAttendee

      if (initialValues?.id) {
        const currentSession = (training?.details?.sessions || []).find(session => session.id === initialValues.id)

        if (currentSession) {
          const isAttendeeInCurrentSession = (currentSession.attendeeUserIds || []).includes(user.id)
          isDisabled = !isAttendeeInCurrentSession
        }
      }

      return {
        value: user.id,
        label: user.userName,
        isDisabled: isDisabled,
      }
    })

    return filteredUsers
  }, [users, training, isTrainingLoading, initialValues?.id])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Select
        name="trainer"
        label={trainerLabel}
        placeholder={getPlaceholderTextFromLabel(trainerLabel)}
        validate={validateRequired}
        disabled={submitting}
        options={trainerOptions}
      />

      <RFFFields.Textarea
        name="note"
        label={noteLabel}
        placeholder={getPlaceholderTextFromLabel(noteLabel)}
        disabled={submitting}
      />

      {isStartedAtVisible && (
        <RFFFields.DatePicker
          name="trainingStartedAt"
          label="Start Date"
          placeholderText="Enter Start Date"
          disabled={submitting}
          isDateOnly
          shouldCloseOnSelect
          withPortal
        />
      )}

      {isEndedAtVisible && (
        <RFFFields.DatePicker
          name="trainingEndedAt"
          label="Ended at"
          placeholderText="Enter Ended Date"
          disabled={submitting}
          isDateOnly
          shouldCloseOnSelect
          withPortal
        />
      )}

      <RFFFields.Select
        name="attendeeUserIds"
        label={attendeeLabel}
        placeholder={getPlaceholderTextFromLabel(attendeeLabel)}
        validate={validateRequired}
        disabled={submitting}
        options={userOptions}
        isMulti
      />
    </form>
  )
}

export default TrainingSessionForm
