import React, { useMemo } from 'react'

import FaqFormFields from './faq-form-fields'

import { IFaqFormProps } from './faq-form-types'

const FaqForm: React.FC<IFaqFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, form } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])
  return (
    <form onSubmit={handleSubmit} id={formName}>
      <FaqFormFields disabled={isDisabled} addSelectedTagIds={form.mutators.addSelectedTagIds} />
    </form>
  )
}

export default FaqForm
