import config from '../../config'

import { IGetGetRequest } from '../../interfaces'

const getDiscrepancyByIdQueryRequest: IGetGetRequest = params => {
  // @ts-ignore
  const { airportId, inspectionId, discrepancyId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Inspections/${inspectionId}/Discrepancy/${discrepancyId}`,
  }
}

export default getDiscrepancyByIdQueryRequest
