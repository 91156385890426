import qs from 'qs'

import config from '../../config'

import { IGetGetRequest } from '../../interfaces'

const getInspectionTypeByInspectionIdRequest: IGetGetRequest<any> = (params, queryObject) => {
  const { airportId, id } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Inspections/${id}/Type/Details${queryString}`,
  }
}

export default getInspectionTypeByInspectionIdRequest
