import config from '../../config'
import { buildGetQuery } from '../../util'

import { IPagedResults, IActivityLog, IActivityLogSearchFilter } from '../../interfaces'

type Params = {
  airportId: number
}

const getActivityLogsSearchRequest = (params: Params, data: IActivityLogSearchFilter): any => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/ActivityLogs/Search`,
    data,
  }
}

const useActivityLogsSearchQuery = buildGetQuery<
  Params,
  IActivityLogSearchFilter,
  IPagedResults<IActivityLog[]> | undefined
>({
  getRequest: (params, queryObject) => getActivityLogsSearchRequest(params, queryObject),
  getQueryKey: (params, queryObject) => [
    'activity-logs-search',
    params.airportId,
    queryObject.searchBeginDate,
    queryObject.searchEndDate,
    queryObject?.searchIds || '',
    queryObject?.searchTagIds || '',
    queryObject?.searchText || '',
    queryObject.limit,
    queryObject.page,
    queryObject?.sortBy || '',
    queryObject?.sortOrder || '',
  ],
})

export default useActivityLogsSearchQuery
