import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getAddTrainingTemplateMutationRequest from './get-add-training-template-mutation-request'

import { IUseAddMutation, IAddFn } from '../../interfaces'
import { ITrainingTemplate, ITrainingTemplateAddUpsert } from '../../interfaces'

const useAddTrainingTemplateMutation: IUseAddMutation<ITrainingTemplate, ITrainingTemplateAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getAddTrainingTemplateMutationRequest(params), [params])

  const addTraining: IAddFn<ITrainingTemplateAddUpsert, ITrainingTemplate> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITrainingTemplate, Error, any>({
    mutationFn: addTraining,
    ...options,
  })

  return response
}

export default useAddTrainingTemplateMutation
