import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteLocationMutationRequest from './get-delete-checklist-template-mutation-request'

import { IUseDeleteMutation, IDeleteFn } from '../../interfaces'
import { IChecklistTemplate } from '../../interfaces'

const useDeleteLocationMutation: IUseDeleteMutation<IChecklistTemplate, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteLocationMutationRequest(params), [params])

  const deleteLocation: IDeleteFn<IChecklistTemplate> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklistTemplate, Error, any>({ mutationFn: deleteLocation, ...options })

  return response
}

export default useDeleteLocationMutation
