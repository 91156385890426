import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateTrainingTemplateMutationRequest: IGetPutRequest = params => {
  return {
    method: 'put',
    url: `${config.baseUrl}/TrainingTemplates/`,
  }
}

export default getUpdateTrainingTemplateMutationRequest
