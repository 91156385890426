import { FC, useMemo } from 'react'
import { Polygon as GooglePolygon } from '@react-google-maps/api'

import { useIntervalUpdate } from '../../hooks'
import EditablePolygon from './editable-polygon'

import { IPolygonProps } from './polygon-types'

const Polygon: FC<IPolygonProps> = ({
  geoJson,
  onClick,
  animation = false,
  color,
  editable,
  renderEditPopUp,
  onUpdate,
}) => {
  const path = useMemo(() => {
    if (!Array.isArray(geoJson.geometry.coordinates[0])) {
      return []
    }

    return geoJson.geometry.coordinates[0].map(c => ({
      lat: c[1],
      lng: c[0],
    }))
  }, [geoJson])

  const { value: opacity } = useIntervalUpdate({
    min: 0.1,
    max: 0.99,
    step: 0.007,
    defaultValue: 0.1,
    interval: 5,
    active: animation,
  })

  const polygonOptions = useMemo(
    () => ({
      fillColor: color ? color : geoJson.properties.color,
      fillOpacity: opacity,
      strokeColor: color ? color : geoJson.properties.color,
      clickable: onClick !== undefined,
    }),
    [geoJson, color, opacity, onClick],
  )

  return editable ? (
    <EditablePolygon
      geoJson={geoJson}
      path={path}
      options={polygonOptions}
      renderEditPopUp={renderEditPopUp}
      onUpdate={onUpdate}
    />
  ) : (
    <GooglePolygon onClick={onClick ? () => onClick(geoJson) : undefined} options={polygonOptions} path={path} />
  )
}

export default Polygon
