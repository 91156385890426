import { useCallback, useMemo } from 'react';
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getCreateLocationMutationRequest from './get-create-location-mutation-request'

import { ILocation, ILocationUpsert } from '../../interfaces'
import { IUseCreateLocationMutation, ICreateLocation } from './types'

const useCreateLocationMutation: IUseCreateLocationMutation = (params, options = {}) => {
    const request = useMemo(() => getCreateLocationMutationRequest(params), [params,])

    const createLocation: ICreateLocation = useCallback(async (data) => axios({ ...request, data }).then(res => res.data)
        , [request])

    const response = useMutation<ILocation, Error, ILocationUpsert>({ mutationFn: createLocation, ...options })

    return response
}

export default useCreateLocationMutation
