import { FC, useCallback, useMemo, useState, useEffect, ReactElement } from 'react'
import { FormApi } from 'final-form'

import {
  showErrorAlert,
  InfoCard,
  DocumentListItem,
  Location,
  useGoogleMaps,
  mapMode as MAP_MODES,
  ISafetyConcernFieldsAdditionalOptions,
  ISafetyConcernValues,
  ISafetyConcernUpsertValues,
} from '../../../components'
import SafetyConcernForm from './safety-concern-form'

import { useDownloadDocumentMutation } from '../../../services'
import { useAppContext } from '../../../app-context'
import { useProfile } from '../../../security'
import { options, renderLocalDate } from '../../../util'

import { IGeoJson, IDocument, ISafetyReport } from '../../../interfaces'

type SafetyConcernInfoProps = {
  hasWriteAccess?: boolean
  setSafetyConcernGeoJson: (geoJson: IGeoJson | undefined) => void
  setHeaderControls: (headerControls?: ReactElement) => void
  additionalSaveSafetyConcernOptions: ISafetyConcernFieldsAdditionalOptions
  isSafetyConcernFilled?: boolean
  handleSaveSafetyConcern: (safetyConcernValues: ISafetyConcernValues) => void
  saveHeaderControls: ReactElement
  setSaveSafetyConcernForm?: (saveSafetyConcernForm: FormApi<ISafetyConcernValues>) => void
  saveSafetyConcernInitialValues?: ISafetyConcernUpsertValues
  setSaveSafetyConcernValues?: (safetyConcernUpsertValues: ISafetyConcernUpsertValues) => void
  isSaveFormDisabled?: boolean
  safetyReport?: ISafetyReport
}

const SafetyConcernInfo: FC<SafetyConcernInfoProps> = props => {
  const {
    hasWriteAccess,
    setHeaderControls,
    setSafetyConcernGeoJson,
    additionalSaveSafetyConcernOptions,
    handleSaveSafetyConcern,
    saveHeaderControls,
    setSaveSafetyConcernForm,
    saveSafetyConcernInitialValues,
    setSaveSafetyConcernValues,
    isSaveFormDisabled,
    safetyReport,
  } = props

  const { airportId } = useProfile()

  const { dispatch: appDispatch } = useAppContext()

  const { mapMode, setMapMode } = useGoogleMaps()

  useEffect(() => {
    setHeaderControls(saveHeaderControls)
    return () => setHeaderControls(undefined)
  }, [setHeaderControls, saveHeaderControls])

  const infoCardOptions = useMemo(() => {
    if (!safetyReport) {
      return []
    }

    return [
      { title: 'Reporter Name', value: safetyReport.reporterName || 'Anonymous' },
      { title: 'Reporter Email Address', value: safetyReport.reporterEmailAddress, hiddenOnNoValue: true },
      { title: 'Reporter Phone', value: safetyReport.reporterPhoneNumber, hiddenOnNoValue: true },
      {
        title: 'Severity',
        value: safetyReport.severity
          ? options.severity?.find(severity => severity.value === safetyReport.severity)?.label
          : undefined,
      },
      { title: 'Was anyone injured', value: safetyReport.injuryOccurred ? 'yes' : 'no' },
      { title: 'Did damage occur', value: safetyReport.damageOccurred ? 'yes' : 'no' },
      { title: 'Is this a recurring safety concern', value: safetyReport.recurringConcern ? 'yes' : 'no' },
      { title: 'Observed At', value: renderLocalDate(safetyReport.observedAt) },
      { title: 'Description', value: safetyReport.description },
    ]
  }, [safetyReport])

  const { mutateAsync: downloadDocument } = useDownloadDocumentMutation({ airportId })

  const [downloadingDocumentIds, setDownloadingDocumentIds] = useState<number[]>([])

  const handleDownloadDocument = useCallback(
    async (document: IDocument) => {
      if (!document.url) {
        return
      }

      setDownloadingDocumentIds(downloadingDocumentIds => [...downloadingDocumentIds, document.id])

      try {
        await downloadDocument({ url: document.url, name: String(document.name) })
      } catch (err) {
        console.warn('Something went wrong during document download', err)
        appDispatch(showErrorAlert('Something went wrong during document download'))
      }

      setDownloadingDocumentIds(downloadingDocumentIds =>
        downloadingDocumentIds.filter(documentId => documentId !== document.id),
      )
    },
    [downloadDocument, appDispatch],
  )

  const onMapModeChange = useCallback(
    async (newMapMode: string) => {
      let mapMode = newMapMode

      if (newMapMode === MAP_MODES.CLEAR) {
        setSafetyConcernGeoJson(undefined)
        mapMode = MAP_MODES.MARKER
      }

      setMapMode(mapMode)
    },
    [setMapMode, setSafetyConcernGeoJson],
  )

  useEffect(() => () => setMapMode(MAP_MODES.CLEAR), [setMapMode])

  return (
    <>
      <SafetyConcernForm
        onSubmit={handleSaveSafetyConcern}
        disabled={isSaveFormDisabled || !hasWriteAccess}
        isViewMode={!hasWriteAccess}
        initialValues={saveSafetyConcernInitialValues}
        formName="safety-concern-form"
        additionalOptions={additionalSaveSafetyConcernOptions}
        setForm={setSaveSafetyConcernForm}
        onChange={setSaveSafetyConcernValues}
        baseStatus={'Doing'}
      />

      {safetyReport && (
        <InfoCard title="Reported Concern" options={infoCardOptions}>
          {safetyReport.documents && safetyReport.documents?.length > 0 && (
            <div className="mt-4">
              <h5>Attachments</h5>

              {safetyReport.documents.map(document => (
                <DocumentListItem
                  key={document.id}
                  document={document}
                  downloadDocument={() => handleDownloadDocument(document)}
                  isDocumentDownloading={downloadingDocumentIds.includes(document.id)}
                />
              ))}
            </div>
          )}
        </InfoCard>
      )}

      <div className="mb-4">
        <Location
          hasSavedLocations={false}
          hideSavedLocations={true}
          mapMode={mapMode}
          setMapMode={onMapModeChange}
          disabled={isSaveFormDisabled || !hasWriteAccess}
        />
      </div>
    </>
  )
}

export default SafetyConcernInfo
