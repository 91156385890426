import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject, IWorkOrder } from '../../interfaces'

type Params = {
  id: number
}

const getSafetyConcernWorkOrdersRequest = (params: Params): IGetRequestObject => {
  const { id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/SafetyConcerns/${id}/WorkOrders`,
  }
}

const useSafetyConcernWorkOrdersQueryBase = buildGetQuery<Params, undefined, IWorkOrder[] | undefined>({
  getRequest: getSafetyConcernWorkOrdersRequest,
  getQueryKey: params => ['safety-concern-work-orders', params.id],
  defaultOptions: { initialData: undefined },
})

const useSafetyConcernWorkOrdersQuery = (
  params: Params,
  options?: QueryObserverOptions<IWorkOrder[] | undefined, Error>,
) => useSafetyConcernWorkOrdersQueryBase(params, undefined, options)

export default useSafetyConcernWorkOrdersQuery
