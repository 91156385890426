import axios from 'axios'

import config from '../../config'

const updateWorkOrderDates = async ({ airportId, request }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders/UpdateDates`
    const response = await axios.put(url, request)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default updateWorkOrderDates
