import { useMemo, useState, ReactElement, useCallback, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'

import {
  PageLayout,
  Button,
  DropdownButton,
  getMapMode,
  useGoogleMaps,
  mapMode as mapModes,
  safetyConcernValuesToSafetyConcernUpdateUpsert,
  safetyConcernToSafetyConcernValues,
  showSuccessAlert,
  showErrorAlert,
  ISafetyConcernValues,
  ISafetyConcernUpsertValues,
} from '../../../components'
import SafetyConcernBuilder from './safety-concern-builder'

import { useSafetyConcernByIdQuery, useUpdateSafetyConcernMutation } from '../../../services'
import { useAppContext } from '../../../app-context'
import { isDeepEqual } from '../../../util'
import { claims, useAccessControl } from '../../../security'

import { IGeoJson } from '../../../interfaces'

const UpdateSafetyConcernPage = () => {
  const navigate = useNavigate()

  const { hasAccess } = useAccessControl()

  const hasSafetyConcernWriteAccess = useMemo(() => hasAccess([claims.safetyConcern.write]), [hasAccess])

  const { dispatch: appDispatch } = useAppContext()

  const { safetyConcernId } = useParams()

  const { setMapMode } = useGoogleMaps()

  const [safetyConcernGeoJson, setSafetyConcernGeoJson] = useState<IGeoJson | undefined>()

  const safetyConcernQuery = useSafetyConcernByIdQuery(
    { id: Number(safetyConcernId) },
    {
      enabled: safetyConcernId !== undefined && safetyConcernId !== null,
      onSuccess: safetyConcern => {
        let newMapMode

        if (!safetyConcern?.completed) {
          if (safetyConcern?.geoJson) {
            setSafetyConcernGeoJson(safetyConcern.geoJson)
            newMapMode = getMapMode(safetyConcern.geoJson)
          } else {
            newMapMode = mapModes.MARKER
          }
        } else {
          newMapMode = mapModes.CLEAR
        }

        setMapMode(newMapMode)
        setSafetyConcernGeoJson(safetyConcern?.geoJson)
      },
    },
  )

  const { data: safetyConcern, refetch: refetchSafetyConcern, isFetching: isSafetyConcernFetching } = safetyConcernQuery

  const location = useLocation()

  const [headerControls, setHeaderControls] = useState<ReactElement | undefined>(undefined)

  const [backSearch] = useState((location?.state as any)?.backSearch)

  const _headerControls = useMemo(
    () => (
      <div>
        <Button
          onClick={() => navigate({ pathname: '/safety-concern/safety-concern-list', search: backSearch })}
          className={cn(hasSafetyConcernWriteAccess && 'mr-2')}
          type="secondary"
        >
          Back To Safety Concerns
        </Button>

        {hasSafetyConcernWriteAccess && headerControls}
      </div>
    ),
    [hasSafetyConcernWriteAccess, headerControls, navigate, backSearch],
  )

  const { mutateAsync: updateSafetyConcern, isLoading: isSafetyConcernUpdating } = useUpdateSafetyConcernMutation({})

  const isSafetyConcernCompleted = safetyConcern?.completed

  const isSaveDisabled = useMemo(
    () => isSafetyConcernFetching || isSafetyConcernUpdating || isSafetyConcernCompleted,
    [isSafetyConcernFetching, isSafetyConcernUpdating, isSafetyConcernCompleted],
  )

  const saveSafetyConcernInitialValues = useMemo(
    () => safetyConcernToSafetyConcernValues(safetyConcern),
    [safetyConcern],
  )

  const [saveSafetyConcernValues, setSaveSafetyConcernValues] = useState<ISafetyConcernUpsertValues | undefined>(
    saveSafetyConcernInitialValues,
  )

  const isSafetyConcernChanged = useMemo(
    () =>
      !isDeepEqual(saveSafetyConcernInitialValues, saveSafetyConcernValues) ||
      !isDeepEqual(safetyConcernGeoJson, safetyConcern?.geoJson || undefined),
    [saveSafetyConcernValues, saveSafetyConcernInitialValues, safetyConcernGeoJson, safetyConcern?.geoJson],
  )

  const isSaveButtonDisabled = useMemo(
    () => isSaveDisabled || !isSafetyConcernChanged,
    [isSaveDisabled, isSafetyConcernChanged],
  )

  const isReopenButtonDisabled = useMemo(
    () => isSafetyConcernUpdating || isSafetyConcernFetching || !isSafetyConcernCompleted,
    [isSafetyConcernUpdating, isSafetyConcernFetching, isSafetyConcernCompleted],
  )

  const [saveSafetyConcernForm, setSaveSafetyConcernForm] = useState<any>()

  const isUpdateAndCompleteRef = useRef<boolean>()

  const handleReopenSafetyConcern = useCallback(async () => {
    if (!safetyConcern) {
      return
    }

    const safetyConcernValues = safetyConcernToSafetyConcernValues(safetyConcern)

    if (!safetyConcernValues) {
      return
    }

    let safetyConcernUpdateUpsert = safetyConcernValuesToSafetyConcernUpdateUpsert(safetyConcernValues, {
      safetyConcern,
      geoJson: safetyConcernGeoJson,
    })

    if (!safetyConcernUpdateUpsert) {
      return
    }

    safetyConcernUpdateUpsert.completed = false

    try {
      await updateSafetyConcern(safetyConcernUpdateUpsert)
    } catch (err) {
      appDispatch(showErrorAlert('Error reopening safety concern'))
      console.warn(err)
      return
    }

    appDispatch(showSuccessAlert('Safety concern successfully reopened'))
    refetchSafetyConcern()
  }, [appDispatch, safetyConcern, updateSafetyConcern, refetchSafetyConcern, safetyConcernGeoJson])

  const handleUpdateSafetyConcern = useCallback(
    async (safetyConcernValues: ISafetyConcernValues) => {
      if (!safetyConcern) {
        return
      }

      let safetyConcernUpdateUpsert = safetyConcernValuesToSafetyConcernUpdateUpsert(safetyConcernValues, {
        safetyConcern,
        geoJson: safetyConcernGeoJson,
      })

      if (!safetyConcernUpdateUpsert) {
        return
      }

      try {
        if (isUpdateAndCompleteRef.current) {
          safetyConcernUpdateUpsert.completed = true
        }

        await updateSafetyConcern(safetyConcernUpdateUpsert)
      } catch (err) {
        appDispatch(showErrorAlert('Error updating safety concern'))
        console.warn(err)
        return
      }

      let successMessage = 'Safety concern successfully updated'
      if (isUpdateAndCompleteRef.current) {
        successMessage = 'Safety concern successfully updated and completed'
      }

      setMapMode(mapModes.CLEAR)
      appDispatch(showSuccessAlert(successMessage))
      refetchSafetyConcern()
    },
    [appDispatch, safetyConcern, updateSafetyConcern, refetchSafetyConcern, safetyConcernGeoJson, setMapMode],
  )

  const saveHeaderControls = useMemo(() => {
    if (isSafetyConcernCompleted) {
      return (
        <Button onClick={handleReopenSafetyConcern} disabled={isReopenButtonDisabled}>
          Reopen
        </Button>
      )
    }

    return (
      <DropdownButton
        className="ml-2"
        disabled={isSaveButtonDisabled && isSaveDisabled}
        iconClassName="bi-chevron-down ml-2"
        type="primary"
        options={[
          {
            label: 'Save',
            disabled: isSaveButtonDisabled,
            handler: () => {
              isUpdateAndCompleteRef.current = false
              saveSafetyConcernForm?.submit()
            },
          },
          {
            label: isSafetyConcernChanged ? 'Save & Complete' : 'Complete',
            disabled: isSaveDisabled,
            handler: () => {
              isUpdateAndCompleteRef.current = true
              saveSafetyConcernForm?.submit()
            },
          },
        ]}
      >
        Save
      </DropdownButton>
    )
  }, [
    isSaveButtonDisabled,
    isSaveDisabled,
    isReopenButtonDisabled,
    isSafetyConcernChanged,
    saveSafetyConcernForm,
    handleReopenSafetyConcern,
    isSafetyConcernCompleted,
  ])

  const pageTitle = useMemo(
    () => `Safety Concern ${safetyConcernQuery?.data?.summary ? `- ${safetyConcernQuery?.data?.summary}` : ''}`,
    [safetyConcernQuery?.data?.summary],
  )

  return (
    <PageLayout title={pageTitle} headerControls={_headerControls}>
      <SafetyConcernBuilder
        hasWriteAccess={hasSafetyConcernWriteAccess}
        safetyConcernQuery={safetyConcernQuery}
        setHeaderControls={setHeaderControls}
        handleSaveSafetyConcern={handleUpdateSafetyConcern}
        saveHeaderControls={saveHeaderControls}
        setSafetyConcernGeoJson={setSafetyConcernGeoJson}
        safetyConcernGeoJson={safetyConcernGeoJson}
        setSaveSafetyConcernForm={setSaveSafetyConcernForm}
        saveSafetyConcernInitialValues={saveSafetyConcernInitialValues}
        setSaveSafetyConcernValues={setSaveSafetyConcernValues}
        isSaveFormDisabled={isSaveDisabled}
      />
    </PageLayout>
  )
}

export default UpdateSafetyConcernPage
