import axios from 'axios'

import config from '../../config'

const createInspectionType = (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/InspectionTypes`
  return axios.post(url, data)
}

export default createInspectionType
