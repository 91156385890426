import { FC, useCallback } from 'react'
import cn from 'classnames'

import { Button } from '../../button'

import s from './pagination-buttons.module.scss'

type PaginationButtonsProps = {
  pageIndex: number
  setPageIndex: any
  canPreviousPage: boolean
  canNextPage: boolean
  totalPageCount: number
}

const PaginationButtons: FC<PaginationButtonsProps> = props => {
  const { pageIndex, setPageIndex, canPreviousPage, canNextPage, totalPageCount } = props

  const goToFirstPage = useCallback(() => setPageIndex(0), [setPageIndex])
  const goToLastPage = useCallback(() => setPageIndex(totalPageCount - 1), [setPageIndex, totalPageCount])

  const goToPrevPage = useCallback(() => setPageIndex(pageIndex - 1), [setPageIndex, pageIndex])
  const goToNextPage = useCallback(() => setPageIndex(pageIndex + 1), [setPageIndex, pageIndex])

  return (
    <div className={s.container}>
      <Button
        onClick={goToFirstPage}
        disabled={!canPreviousPage}
        className={s['pagination-btn']}
        type="secondary-icon"
        dataTestId="to-fist-page"
      >
        <i className={cn('d-flex bi bi-chevron-double-left', s['pagination-icon'])} />
      </Button>

      <Button
        onClick={goToPrevPage}
        disabled={!canPreviousPage}
        className={s['pagination-btn']}
        type="secondary-icon"
        dataTestId="to-prev-page"
      >
        <i className={cn('d-flex bi bi bi-chevron-left', s['pagination-icon'])} />
      </Button>

      <Button className={s['pagination-btn']} disabled type="primary" dataTestId="current-page">
        {pageIndex + 1}
      </Button>

      <Button
        onClick={goToNextPage}
        disabled={!canNextPage}
        className={s['pagination-btn']}
        type="secondary-icon"
        dataTestId="to-next-page"
      >
        <i className={cn('d-flex bi bi-chevron-right', s['pagination-icon'])} />
      </Button>

      <Button
        onClick={goToLastPage}
        disabled={!canNextPage}
        className={s['pagination-btn']}
        type="secondary-icon"
        dataTestId="to-last-page"
      >
        <i className={cn('d-flex bi bi-chevron-double-right', s['pagination-icon'])} />
      </Button>
    </div>
  )
}

export default PaginationButtons
