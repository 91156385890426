import { useMemo } from 'react'
import { array } from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import MoreSelectedTooltip from './more-selected-tooltip'
import './more-selected-badge.scss'

const MoreSelectedBadge = ({ items }) => {
  const label = useMemo(() => {
    return `+ ${items?.length} item${items?.length !== 1 ? 's' : ''}`
  }, [items?.length])

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <MoreSelectedTooltip items={items} />
        </Tooltip>
      }
    >
      <div className="more-selected-badge-wrapper">
        <span className="px-1">{label}</span>
      </div>
    </OverlayTrigger>
  )
}

export default MoreSelectedBadge

MoreSelectedBadge.propTypes = {
  items: array,
}
