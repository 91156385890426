import { Outlet, useLocation } from 'react-router-dom'
import { Suspense, useEffect } from 'react'

import { GoogleMapsProvider, Spinner } from '../components'
import { MaintenanceBanner } from '../components/maintenance-banner'

import ProfileDrawer from '../features/drawers/profile-drawer/profile-drawer'
import NavigationMenuDrawer from '../features/drawers/navigation-menu-drawer/navigation-menu-drawer'
import FaqDrawer from '../features/drawers/faq-drawer/faq-drawer'
import NotificationsDrawer from '../features/drawers/notifications-drawer/notification-drawer'

import { useAppDataLoader } from '../services'

import config from '../config'
import { useAuthContext } from './auth-context'
import { useReleaseNotesConfig } from '../use-release-notes-config'

export const PrivateRoute = () => {
  const { loading, error } = useAppDataLoader()
  const { pathname } = useLocation()

  const { isAuthenticated } = useAuthContext()

  useReleaseNotesConfig({ enabled: isAuthenticated })

  useEffect(() => {
    const lastPathKey = 'last-path'
    sessionStorage.setItem(lastPathKey, pathname)
  }, [pathname])

  if (isAuthenticated) {
    let content = <Outlet />

    if (error) {
      content = (
        <h6 className="d-flex w-screen h-100 justify-content-center align-items-center text-danger">
          Error loading application data. Please refresh.
        </h6>
      )
    }

    if (loading) {
      content = <Spinner />
    }

    return (
      <Suspense
        fallback={
          <div className="vh-100">
            <Spinner />
          </div>
        }
      >
        <GoogleMapsProvider apiKey={config.googleMaps.apiKey} libraries={config.googleMaps.libraries}>
          <MaintenanceBanner />

          <ProfileDrawer />

          <NavigationMenuDrawer />

          <FaqDrawer />

          <NotificationsDrawer />

          {content}
        </GoogleMapsProvider>
      </Suspense>
    )
  }

  return null
}
