import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISavedSearchFilterReminder, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

const getSavedSearchFilterRemindersRequest = (params: Params): IGetRequestObject => {
  const { airportId } = params
  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Reminders/SavedSearch`,
  }
}

const useSavedSearchFilterRemindersQueryBase = buildGetQuery<
  Params,
  undefined,
  ISavedSearchFilterReminder[] | undefined
>({
  getRequest: getSavedSearchFilterRemindersRequest,
  getQueryKey: params => ['saved-search-filter-reminders', params.airportId],
})

const useSavedSearchFilterRemindersQuery = (
  params: Params,
  options?: QueryObserverOptions<ISavedSearchFilterReminder[] | undefined, Error>,
) => useSavedSearchFilterRemindersQueryBase(params, undefined, options)

export default useSavedSearchFilterRemindersQuery
