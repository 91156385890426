import { useEffect, useMemo } from 'react'

import { useAppContext } from '../app-context'
import { useProfile, useAuthContext } from '../security'
import { useAirportByIdQuery, usePersonalizationMapStatusesQuery } from '../services'

const useAppDataLoader = () => {
  const { dispatch } = useAppContext()
  const authContext = useAuthContext()
  const { isAuthenticated } = authContext
  const { airportId } = useProfile()

  const {
    data: airportData,
    error: airportDataError,
    isInitialLoading: isAirportDataLoading,
  } = useAirportByIdQuery({ airportId }, undefined, {
    enabled: isAuthenticated,
  })

  const {
    data: personalizationMapStatuses,
    error: personalizationMapStatusesError,
    isInitialLoading: isPersonalizationMapStatusesLoading,
  } = usePersonalizationMapStatusesQuery({ airportId }, { enabled: isAuthenticated })

  useEffect(() => {
    if (isAirportDataLoading || isPersonalizationMapStatusesLoading) {
      return
    }

    if (airportDataError || personalizationMapStatusesError) {
      return
    }

    if (airportData && personalizationMapStatuses) {
      let appData = {
        airport: airportData,
        personalizationMapStatuses: personalizationMapStatuses,
      }

      dispatch({ type: 'APP_DATA_LOADED', payload: appData })
    }
  }, [
    dispatch,
    isAirportDataLoading,
    isPersonalizationMapStatusesLoading,
    airportData,
    airportDataError,
    personalizationMapStatuses,
    personalizationMapStatusesError,
  ])

  const error = useMemo(() => {
    if (airportDataError || personalizationMapStatusesError) {
      return {
        message: 'Unable to load the application',
        stack: `${(airportDataError || personalizationMapStatusesError)?.message} - ${
          (airportDataError || personalizationMapStatusesError)?.stack
        }`,
      }
    }

    return null
  }, [airportDataError, personalizationMapStatusesError])

  return { loading: isAirportDataLoading || isPersonalizationMapStatusesLoading, error }
}

export default useAppDataLoader
