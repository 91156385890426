import createMarkerIcon from './icons/create-marker-icon'
import geolocationMarkerIcon from './icons/geolocation-marker-icon'

export const createUrlFromPin = pin => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(pin)}`

export const getPinUrl = fillColor => {
  const pin = createMarkerIcon({ fillColor, borderWidth: 1 })
  const url = createUrlFromPin(pin)
  return url
}

export const getGeolocationPinUrl = fillColor => {
  const pin = geolocationMarkerIcon({ fillColor, borderWidth: 1 })
  const url = createUrlFromPin(pin)
  return url
}
