const checkAccess = (userClaims, resourceClaims) => {
  const safeUserClaims = Array.isArray(userClaims) ? userClaims : [userClaims]
  const safeResourceClaims = Array.isArray(resourceClaims) ? resourceClaims : [resourceClaims]

  if (safeUserClaims.length === 0 || safeResourceClaims.length === 0) {
    // There are no user claims to check; do not allow access
    return false
  }

  if (resourceClaims === '*') {
    // There are no resource claims to check against; allow access
    return true
  }

  if (safeResourceClaims.length === 0) {
    return true
  }

  // Check if user has any claims which match the allowed resource claims
  const intersectingClaims = safeUserClaims.filter(userClaim => safeResourceClaims.includes(userClaim))

  return !!intersectingClaims.length
}

export default checkAccess
