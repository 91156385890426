import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject } from '../../interfaces'

const getInspectionCategoriesRequest = (): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/InspectionCategories`,
})

const useInspectionCategoriesQuery = buildGetQuery<undefined, undefined, any[] | undefined>({
  getRequest: getInspectionCategoriesRequest,
  getQueryKey: () => ['inspection-categories'],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useInspectionCategoriesQuery
