import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import {
  IUseUpdateMutation,
  IUpdateFn,
  IChecklistReminder,
  IChecklistReminderUpdateUpsert,
  IGetPutRequest,
} from '../../interfaces'

import config from '../../config'

const getUpdateChecklistReminderMutationRequest: IGetPutRequest = params => ({
  method: 'put',
  url: `${config.baseUrl}/${params.airportId}/Reminders/Checklist/`,
})

const useUpdateChecklistReminderMutation: IUseUpdateMutation<IChecklistReminder, IChecklistReminderUpdateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateChecklistReminderMutationRequest(params), [params])

  const updateChecklistReminder: IUpdateFn<IChecklistReminderUpdateUpsert, IChecklistReminder> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklistReminder, Error, any>({
    mutationFn: updateChecklistReminder,
    ...options,
  })

  return response
}

export default useUpdateChecklistReminderMutation
