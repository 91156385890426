import { FC, useCallback } from 'react'
import cn from 'classnames'

import { Button } from '../../button'
import { useAppContext } from '../../../app-context'
import { actions } from '../../../actions'

type ProfileButtonProps = {
  className?: string
}

const ProfileButton: FC<ProfileButtonProps> = props => {
  const { className } = props

  const { state, dispatch } = useAppContext()

  const toggleProfileDrawer = useCallback(
    () =>
      state.isProfileDrawerOpen
        ? dispatch(actions.profileDrawer.closeProfileDrawer())
        : dispatch(actions.profileDrawer.openProfileDrawer()),
    [state.isProfileDrawerOpen, dispatch],
  )

  return (
    <Button type="ghost-icon" className={cn('navbar-icon-actions-button', className)} onClick={toggleProfileDrawer}>
      <i className="bi bi-person-fill" />
    </Button>
  )
}

export default ProfileButton
