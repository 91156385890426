import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { UseMutationResult, UseMutationOptions } from '@tanstack/react-query'

import getDownloadWildlifeCSVQueryRequest, {
  IGetDownloadWildlifesCSVMutationRequestParams,
} from './get-download-wildlifes-mutation-request'

export interface IFetchWildlifesCSVFile {
  (data: any): Promise<Blob>
}

export interface IUseDownloadWildlifesCSVMutation {
  (
    params: IGetDownloadWildlifesCSVMutationRequestParams,
    options?: UseMutationOptions<Blob, Error, any>,
  ): UseMutationResult<Blob, Error, any>
}

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'wildlifes'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadWildlifeCSVQuery: IUseDownloadWildlifesCSVMutation = (params, options = {}) => {
  const request = useMemo(() => getDownloadWildlifeCSVQueryRequest(params), [params])

  const fetchFacilityLocationsCSVFile: IFetchWildlifesCSVFile = useCallback(
    async (data: any) =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchFacilityLocationsCSVFile,
    ...options,
  })

  return response
}

export default useDownloadWildlifeCSVQuery
