import s from './button-filtered-dropdown.module.scss'

type ButtonFilteredDropdownSelectProps = {
  label: string
}

// Formatting for select items that serve as a parent and are
// not clickable
const ButtonFilteredDropdownGroup: React.FC<ButtonFilteredDropdownSelectProps> = data => (
  <div className={s['filtered-dropdown-group-label-container']}>
    <span className={s['filtered-dropdown-group-label']}>
      <i className="bi bi-dash" />
      {data.label}
      <i className="bi bi-dash" />
    </span>
  </div>
)

export default ButtonFilteredDropdownGroup
