import React, { useRef, useEffect } from 'react'

import WildlifeSightingFormFields from './wildlife-sighting-form-fields'

import { IWildlifeSightingFormProps } from './wildlife-sighting-form-types'

const areEqual = (a: any, b: any) => {
  return a === b
}

const WildlifeSightingForm: React.FC<IWildlifeSightingFormProps> = props => {
  const { formName, handleSubmit, onChange, values, valid: isFormValid, labelClassName } = props

  const isInitialValues = useRef(true)
  const prevValuesRef = useRef(values)

  useEffect(() => {
    if (typeof onChange === 'function') {
      if (isInitialValues.current || !areEqual(prevValuesRef.current, values)) {
        onChange(values, isFormValid, true)
        prevValuesRef.current = values
        isInitialValues.current = false
      } else {
        onChange(values, isFormValid, false)
      }
    }
  }, [onChange, isFormValid, values])

  return (
    <form onSubmit={handleSubmit} id={formName} className="mb-2">
      <WildlifeSightingFormFields values={values} labelClassName={labelClassName} />
    </form>
  )
}

export default WildlifeSightingForm
