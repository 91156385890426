import config from '../../config'

import { IGetDeleteRequest } from '../../interfaces'

const getDeleteInspectionDiscrepancyMutationRequest: IGetDeleteRequest = params => {
  // @ts-ignore
  const { airportId, inspectionId } = params

  return {
    method: 'delete',
    url: `${config.baseUrl}/${airportId}/Inspections/${inspectionId}/Discrepancy/`,
  }
}

export default getDeleteInspectionDiscrepancyMutationRequest
