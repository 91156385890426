import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel, options } from '../../../../util'

import { INotamFilterFormFiledsProps, INotamFilterValues } from './notam-filter-form-types'

const nameof = nameofFactory<INotamFilterValues>()

const issuedDateRangeLabel = 'Issued Date Range UTC'
const effectiveStartDateRangeLabel = 'Effective Start Date Range UTC'
const effectiveEndDateRangeLabel = 'Effective End Date Range UTC'
const typeLabel = 'Type'
const classificationLabel = 'Classification'
const searchTextLabel = 'Text Search'

const NotamFilterFormFields: React.FC<INotamFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo } = props

  return (
    <>
      <RFFFields.DateRangePicker
        name={nameof('issuedDateRange')}
        label={issuedDateRangeLabel}
        placeholderText={getPlaceholderTextFromLabel(issuedDateRangeLabel)}
        disabled={disabled || isDateRangeDisabled}
        info={dateRangeInfo}
        isDateOnly
      />

      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('effectiveStartDateRange')}
            label={effectiveStartDateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(effectiveStartDateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.DateRangePicker
            name={nameof('effectiveEndDateRange')}
            label={effectiveEndDateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(effectiveEndDateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('typeIds')}
            label={typeLabel}
            placeholderText={getPlaceholderTextFromLabel(typeLabel)}
            options={options.notamType}
            disabled={disabled}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('classificationIds')}
            label={classificationLabel}
            placeholderText={getPlaceholderTextFromLabel(classificationLabel)}
            options={options.classification}
            disabled={disabled}
            isMulti
          />
        </Col>
      </Row>

      <RFFFields.SearchInput
        name={nameof('searchText')}
        label={searchTextLabel}
        placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
        disabled={disabled}
      />
    </>
  )
}

export default NotamFilterFormFields
