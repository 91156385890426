import React, { useState, useCallback, useEffect, useRef } from 'react'
import cn from 'classnames'
import { string, func, instanceOf } from 'prop-types'

import { validateTime, validateRequired, composeValidators } from '../../../util/validation'
import withFieldDecorator from '../adapters/with-field-decorator'
import { Input } from '../input'
import { useModal } from '../../../hooks/use-modal'

import s from './time-picker.module.scss'

const DecoratedInput = withFieldDecorator(Input)
const timeValidator = composeValidators(validateTime, validateRequired)

const TimePicker = ({ date, value, onChange }) => {
  const { isOpen, toggleModal } = useModal()
  const [tempValue, setTempValue] = useState(value)

  const cachedValue = useRef(tempValue)

  useEffect(() => {
    if (value !== cachedValue.current) {
      setTempValue(value)
    }
  }, [value])

  const handleTimeChange = useCallback(
    time => {
      const timeError = timeValidator(time, date)

      if (!timeError) {
        onChange(time)
      }

      setTempValue(time)
    },
    [onChange, setTempValue, date],
  )

  return (
    <div className={s['time-picker']}>
      <button className={s['time-picker-toggle']} onClick={toggleModal} type="button">
        <i className={cn('bi bi-clock', s['time-picker-icon'])} />
      </button>

      {(isOpen || Boolean(value)) && (
        <DecoratedInput componentProps={{ onChange: handleTimeChange, value: tempValue, type: 'time' }} />
      )}
    </div>
  )
}

TimePicker.propTypes = {
  date: instanceOf(Date),
  value: string,
  onChange: func,
}

export default TimePicker
