import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getDownloadLocationsQueryRequest from './get-download-locations-query-request'

import { IUseDownloadLocationsQuery, IFetchFacilityLocationsFile } from './types'

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'locations'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

// @ts-ignore
const useDownloadLocationsQuery: IUseDownloadLocationsQuery = (params, queryObject, options = {}) => {
  const request = useMemo(() => getDownloadLocationsQueryRequest(params, queryObject), [params, queryObject])

  const fetchFacilityLocationsFile: IFetchFacilityLocationsFile = useCallback(
    () =>
      axios(request).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useQuery<Blob, Error>([], fetchFacilityLocationsFile, {
    ...options,
  })

  return response
}

export default useDownloadLocationsQuery
