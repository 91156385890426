import { FC, useMemo } from 'react'

import SafetyConcernActionFormFields from './safety-concern-action-form-fields'

import { ISafetyConcernActionFormProps } from './safety-concern-action-form-types'

const SafetyConcernActionForm: FC<ISafetyConcernActionFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, form, initialValues } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <SafetyConcernActionFormFields disabled={isDisabled} form={form} initialValues={initialValues} />
    </form>
  )
}

export default SafetyConcernActionForm
