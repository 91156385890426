import config from '../../config'

export interface IGetTrainingsSearchByAttendeePerspectiveQueryObject {
  startedAt?: string
  endedAt?: string
  trainingStatusIds?: number[]
  employeeIds?: number[]
  trainingTemplateIds?: number[]
  notificationGroupIds?: number[]
  searchText?: string
  sortBy?: string
  sortOrder?: string
}

export interface IGetTrainingsSearchByAttendeePerspectiveRequestObject {
  method: 'post'
  url: string
  data?: IGetTrainingsSearchByAttendeePerspectiveQueryObject
}

export interface IGetTrainingsSearchByAttendeePerspectiveRequest {
  (
    querObject?: IGetTrainingsSearchByAttendeePerspectiveQueryObject,
  ): IGetTrainingsSearchByAttendeePerspectiveRequestObject
}

const getTrainingsSearchByAttendeePerspectiveRequest: IGetTrainingsSearchByAttendeePerspectiveRequest = querObject => {
  return {
    method: 'post',
    url: `${config.baseUrl}/Trainings/SearchByAttendeePerspective`,
    data: querObject,
  }
}

export default getTrainingsSearchByAttendeePerspectiveRequest
