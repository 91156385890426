import { useContext, useMemo } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cn from 'classnames'

import DocumentsList from './documents-list'

import { DocumentUploadContext } from './document-upload-context'
import withDocumentUploadContext from './with-document-upload-context'

import './uploader.css'
import './viewer.scss'

// @ts-ignore
import s from './documents-upload.module.scss'

const DocumentUpload = () => {
  const { documents, localDocumentsUploadState, disabled, handleAddDocumentClick, handleViewDocumentsClick } =
    useContext(DocumentUploadContext)

  const isAnyDocumentsUploadedWithErrors = useMemo(
    () => localDocumentsUploadState.find(localDocumentUploadState => localDocumentUploadState.error),
    [localDocumentsUploadState],
  )

  return (
    <>
      <h5 className="mb-2">Attachments</h5>

      <div className="mb-4">
        <Button onClick={handleAddDocumentClick} variant="outline-secondary" disabled={disabled}>
          Add Attachments
        </Button>

        {documents.length > 0 && (
          <Button variant="outline-secondary" className="ml-2" onClick={() => handleViewDocumentsClick()}>
            View Attachments
          </Button>
        )}

        {isAnyDocumentsUploadedWithErrors && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id={`tooltip-right`}>Files with error wouldn't be saved</Tooltip>}
          >
            <i className={cn('bi bi-exclamation-triangle ml-2', s['warning-icon'])} />
          </OverlayTrigger>
        )}
      </div>

      <DocumentsList />
    </>
  )
}

export default withDocumentUploadContext(DocumentUpload)
