import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import { useAuthUsersSelectListQuery } from '../../../../services'
import { options, toOptions, capitalizeFirst } from '../../../../util'

import { INotificationFilterUpsertValues } from './notification-filter-form-types'
import { SelectOption } from '../../select'

const useNotificationFilterQueries = (values?: INotificationFilterUpsertValues) => {
  const { airportId } = useProfile()

  const featureOptions = useMemo(() => toOptions(options.features), [])

  const notificationTypeOptions = useMemo(() => {
    if (!values?.featureIds) {
      return []
    }

    return options.features
      .filter(feature => values?.featureIds?.includes(feature.id))
      .reduce<SelectOption[]>(
        (notificationTypes, feature) => [
          ...notificationTypes,
          ...feature.notificationTypes.map(notificationType => ({
            label: notificationType.name,
            value: notificationType.id,
          })),
        ],
        [],
      )
  }, [values?.featureIds])

  const authUsersQuery = useAuthUsersSelectListQuery({ airportId }, undefined)

  const authUsersOptions = useMemo(
    () => toOptions(authUsersQuery.data, { getLabel: user => capitalizeFirst(`${user.lastName}, ${user.firstName}`) }),
    [authUsersQuery.data],
  )

  return {
    featureOptions,
    notificationTypeOptions,

    authUsersQuery,
    authUsersOptions,
  }
}

export default useNotificationFilterQueries
