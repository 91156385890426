import { FC, MutableRefObject, useRef, useMemo } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import Button from '../../button/button'
import Fields from '../fields'

import { IAttribute } from './attributes-types'

import s from './attributes.module.scss'

type DragDropObject = {
  attributeIndex: number
  attribute: IAttribute
  dragRef: MutableRefObject<HTMLDivElement | null>
}

type AttributesRowPropw = {
  isAutoValue?: boolean
  attribute: IAttribute
  attributeIndex: number
  onAttributeNameChange: (value: string) => void
  onAttributeValueChange: (value: string) => void
  onAttributeDeleteClick: () => void
  disabled?: boolean
  swapAttributes?: (dragItem: DragDropObject, dropItem: DragDropObject) => void
  namePlaceholder?: string
  valuePlaceholder?: string
}

const dndType = 'ATTRIBUTES_ROW'

const AttributesRow: FC<AttributesRowPropw> = props => {
  const {
    isAutoValue,
    attribute,
    attributeIndex,
    onAttributeNameChange,
    onAttributeValueChange,
    onAttributeDeleteClick,
    disabled,
    swapAttributes,
    namePlaceholder,
    valuePlaceholder,
  } = props

  const dragRef = useRef<HTMLDivElement | null>(null)
  const dropRef = useRef<HTMLDivElement | null>(null)

  const dragDropItem: DragDropObject = useMemo(
    () => ({ attribute, attributeIndex, dragRef }),
    [attribute, attributeIndex],
  )

  const [, connectDragSource] = useDrag<DragDropObject, DragDropObject>(
    () => ({
      type: dndType,
      item: dragDropItem,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: disabled,
    }),
    [dragDropItem, disabled],
  )

  const [, connectDropSource] = useDrop<DragDropObject>({
    accept: dndType,
    hover(dragItem) {
      if (!dropRef.current || !dragItem.dragRef) {
        return
      }

      if (dragItem.attributeIndex === dragDropItem.attributeIndex) {
        return
      }

      swapAttributes && swapAttributes(dragItem, dragDropItem)
    },
  })

  return (
    <div
      className={s['attributes-row']}
      ref={ref => {
        dragRef.current = ref
        dropRef.current = ref

        connectDragSource(ref)
        connectDropSource(ref)
      }}
    >
      <div className={s['attributes-drag-icon']}>
        <i className="bi bi-grip-vertical" />
      </div>

      <Fields.Input
        componentProps={{
          wrapperClassName: isAutoValue ? 'w-100 pr-5 mb-0' : 'mb-0 w-100',
          onChange: onAttributeNameChange,
          placeholder: namePlaceholder || 'Attribute name',
          value: attribute.name,
          disabled: disabled,
        }}
      />

      {!isAutoValue && (
        <Fields.Textarea
          componentProps={{
            wrapperClassName: 'mb-0 w-100',
            onChange: onAttributeValueChange,
            placeholder: valuePlaceholder || 'Attribute value',
            value: attribute.value,
            disabled: disabled,
            minRows: 1,
          }}
        />
      )}

      <Button
        type="ghost-icon"
        className={s['attributes-delete-btn']}
        buttonType="button"
        onClick={onAttributeDeleteClick}
        disabled={disabled}
      >
        <i className="bi-trash-fill text-black-50" />
      </Button>
    </div>
  )
}

export default AttributesRow
