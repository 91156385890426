import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'
import Tags from '../../../../features/journal/tags'

import { validateRequired, validateMaxSymbols, composeValidators } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'

import { IActivityLogFormFiledsProps, IActivityLogValues } from './activity-log-form-types'

const validateMaxSymbols100 = validateMaxSymbols(100, 'Activity must not exceed 100 characters')

const nameof = nameofFactory<IActivityLogValues>()

const dateLabel = 'Date'
const activityLabel = 'Activity'
const descriptionLabel = 'Description'

const ActivityLogFormFields: React.FC<IActivityLogFormFiledsProps> = props => {
  const { disabled, addSelectedTagIds, enableDocumentUpload = true } = props

  return (
    <>
      <Row>
        <Col>
          <RFFFields.Input
            name={nameof('name')}
            label={activityLabel}
            placeholder={getPlaceholderTextFromLabel(activityLabel)}
            disabled={disabled}
            validate={composeValidators(validateRequired, validateMaxSymbols100)}
          />
        </Col>

        <Col>
          <RFFFields.DatePicker
            name={nameof('startedAt')}
            placeholder={getPlaceholderTextFromLabel(dateLabel)}
            label="Date"
            disabled={disabled}
            validate={validateRequired}
            popperPlacement="bottom-start"
          />
        </Col>
      </Row>

      <RFFFields.Textarea
        name={nameof('description')}
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        disabled={disabled}
        maxRows={15}
      />

      {addSelectedTagIds && <Tags addSelectedTagIds={addSelectedTagIds} />}

      {enableDocumentUpload && (
        <RFFFields.DocumentUpload name={nameof('documents')} parentType="Journal" disabled={disabled} />
      )}
    </>
  )
}

export default ActivityLogFormFields
