import Fields from './fields'

import withRFF from './adapters/with-rff'
import withAdditionalProps from '../../hoc/with-additional-props'

const RFFFields = {
  Attributes: withRFF(Fields.Attributes),
  ButtonGroup: withRFF(Fields.ButtonGroup),
  Input: withRFF(Fields.Input),
  DualList: withRFF(Fields.DualList),
  DocumentUpload: withRFF(Fields.DocumentUpload),
  MaskedInput: withRFF(Fields.MaskedInput),
  SearchInput: withRFF(Fields.SearchInput),
  Select: withRFF(Fields.Select),
  Switch: withAdditionalProps(withRFF(Fields.Switch), { type: 'checkbox' }),
  DatePicker: withRFF(Fields.DatePicker),
  DateRangePicker: withRFF(Fields.DateRangePicker),
  Checkbox: withRFF(Fields.Checkbox),
  CheckboxGroup: withRFF(Fields.CheckboxGroup),
  Radio: withAdditionalProps(withRFF(Fields.Radio), { type: 'radio' }),
  RadioGroup: withRFF(Fields.RadioGroup),
  ColorPicker: withRFF(Fields.ColorPicker),
  Textarea: withRFF(Fields.Textarea),
  CheckboxTree: withRFF(Fields.CheckboxTree),
}

export default RFFFields
