import axios from 'axios'

import config from '../../config'

const deleteNotifications = ({ airportId, userId }) => {
  const url = `${config.baseUrl}/${airportId}/Notifications?userId=${userId}`

  return axios.delete(url)
}

export default deleteNotifications
