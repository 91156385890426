import { FC } from 'react'

import { IGeoLocationButtonProps } from './geo-location-button-types'

const GeoLocationButton: FC<IGeoLocationButtonProps> = ({ onClick }) => (
  <button className="btn bg-white google-map-button mx-3 p-0 h-40px w-40px rounded-sm" onClick={onClick}>
    <i className="bi bi-cursor-fill p-0"></i>
  </button>
)

export default GeoLocationButton
