import { FC } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import get from 'lodash.get'

import RFFFields from '../../react-final-form-fields'
import { IDashboardWidgetsFormProps } from './dashboard-widgets-form-types'

/* eslint-disable no-unused-vars */
export enum WidgetType {
  OPEN_INSPECTIONS = 1,
  CLOSED_INSPECTIONS = 2,
  OPEN_WORK_ORDERS = 3,
  CLOSED_WORK_ORDERS = 4,
  WORK_ORDER_INSIGHTS_BY_FACILITY = 5,
  REMINDERS = 6,
  NOTAMS = 7,
  MY_TRAININGS = 8,
}

const WIDGET_TYPE_ID_TO_NAME = {
  [WidgetType.OPEN_INSPECTIONS]: 'Open Inspections',
  [WidgetType.CLOSED_INSPECTIONS]: 'Closed Inspections',
  [WidgetType.OPEN_WORK_ORDERS]: 'Open Work Orders',
  [WidgetType.CLOSED_WORK_ORDERS]: 'Closed WorkOrders',
  [WidgetType.WORK_ORDER_INSIGHTS_BY_FACILITY]: 'WorkOrder Insights By Facility',
  [WidgetType.REMINDERS]: 'Reminders',
  [WidgetType.NOTAMS]: 'Notams',
  [WidgetType.MY_TRAININGS]: 'My Trainings',
}

const DashboardWidgetsForm: FC<IDashboardWidgetsFormProps> = props => {
  const { handleSubmit, submitting, formName, values } = props

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <FieldArray name="widgets">
        {({ fields }) =>
          fields.map((name, index) => (
            <RFFFields.Switch
              key={name}
              name={`${name}.isVisible`}
              // @ts-ignore
              label={WIDGET_TYPE_ID_TO_NAME[get(values, name + '.widgetTypeId')]}
              disabled={submitting}
              onLabel="Show"
              offLabel="Hide"
              isInverted
            />
          ))
        }
      </FieldArray>
    </form>
  )
}

export default DashboardWidgetsForm
