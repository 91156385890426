import config from '../../config'

import { IGetSortSafetyItemResponsesMutationRequest } from './get-sort-safety-item-responses-mutation-request-types'

const getSortSafetyItemResponsesMutationRequest: IGetSortSafetyItemResponsesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/SafetyItemResponses/Sort`,
  }
}

export default getSortSafetyItemResponsesMutationRequest
