import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortWorkOrderSearchFilter } from '../../../../interfaces'
import { IWorkOrderFilterValues, IWorkOrderFilterUpsertValues } from './work-order-filter-form-types'

export const objectToWorkOrderFilterValues = (object?: { [key: string]: any }): IWorkOrderFilterUpsertValues => ({
  dateRange: { 0: object?.startedAtBegin || object?.endedAtBegin || '', 1: object?.startedAtEnd || object?.endedAtEnd ||'' },
  workOrderTypeIds: object?.workOrderTypeIds || [],
  statusIds: object?.statusIds || [],
  priorityIds: object?.priorityIds || [],
  facilityIds: object?.facilityIds || [],
  conditionIds: object?.conditionIds || [],
  notificationGroupIds: object?.notificationGroupIds || [],
  inspectionSubtypeIds: object?.inspectionSubtypeIds || [],
  searchText: object?.searchText || '',
  dateType: object?.dateType || 'StartedAt'
})

export const savedSearchFilterToSavedWorkOrderFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null, endedAtFromTimeSpan?: ITimeSpan | null },
): IWorkOrderFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let workOrderFilterObject
  try {
    workOrderFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    workOrderFilterObject = {}
  }

  const workOrderFilterValues = objectToWorkOrderFilterValues(workOrderFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    workOrderFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  if (dynamicOptions?.endedAtFromTimeSpan) {
    workOrderFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.endedAtFromTimeSpan)
  }

  return workOrderFilterValues
}

export const savedWorkOrderFilterValuesToSavedSearchFilterAddUpsert = (
  savedWorkOrderFilterValues: IWorkOrderFilterValues | undefined,
): IShortWorkOrderSearchFilter => ({
  startedAtBegin: savedWorkOrderFilterValues?.dateType === 'StartedAt' ? (savedWorkOrderFilterValues?.dateRange?.[0] || '') : '',
  startedAtEnd: savedWorkOrderFilterValues?.dateType === 'StartedAt' ? (savedWorkOrderFilterValues?.dateRange?.[1] || '') : '',
  endedAtBegin: savedWorkOrderFilterValues?.dateType === 'EndedAt' ? (savedWorkOrderFilterValues?.dateRange?.[0] || '') : '',
  endedAtEnd: savedWorkOrderFilterValues?.dateType === 'EndedAt' ? (savedWorkOrderFilterValues?.dateRange?.[1] || '') : '',
  workOrderTypeIds: savedWorkOrderFilterValues?.workOrderTypeIds || [],
  statusIds: savedWorkOrderFilterValues?.statusIds || [],
  priorityIds: savedWorkOrderFilterValues?.priorityIds || [],
  facilityIds: savedWorkOrderFilterValues?.facilityIds || [],
  conditionIds: savedWorkOrderFilterValues?.conditionIds || [],
  notificationGroupIds: savedWorkOrderFilterValues?.notificationGroupIds || [],
  inspectionSubtypeIds: savedWorkOrderFilterValues?.inspectionSubtypeIds || [],
  searchText: savedWorkOrderFilterValues?.searchText || '',
  dateType: savedWorkOrderFilterValues?.dateType || 'StartedAt',
})

export const savedWorkOrderFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedWorkOrderFilterValues: IWorkOrderFilterValues | undefined,
): IShortWorkOrderSearchFilter => ({
  startedAtBegin: savedWorkOrderFilterValues?.dateType === 'StartedAt' ? (savedWorkOrderFilterValues?.dateRange?.[0] || '') : '',
  startedAtEnd: savedWorkOrderFilterValues?.dateType === 'StartedAt' ? (savedWorkOrderFilterValues?.dateRange?.[1] || '') : '',
  endedAtBegin: savedWorkOrderFilterValues?.dateType === 'EndedAt' ? (savedWorkOrderFilterValues?.dateRange?.[0] || '') : '',
  endedAtEnd: savedWorkOrderFilterValues?.dateType === 'EndedAt' ? (savedWorkOrderFilterValues?.dateRange?.[1] || '') : '',
  workOrderTypeIds: savedWorkOrderFilterValues?.workOrderTypeIds || [],
  statusIds: savedWorkOrderFilterValues?.statusIds || [],
  priorityIds: savedWorkOrderFilterValues?.priorityIds || [],
  facilityIds: savedWorkOrderFilterValues?.facilityIds || [],
  conditionIds: savedWorkOrderFilterValues?.conditionIds || [],
  notificationGroupIds: savedWorkOrderFilterValues?.notificationGroupIds || [],
  inspectionSubtypeIds: savedWorkOrderFilterValues?.inspectionSubtypeIds || [],
  searchText: savedWorkOrderFilterValues?.searchText || '',
  dateType: savedWorkOrderFilterValues?.dateType || 'StartedAt',
})
