import config from '../../config'

const getIsFacilityInInspectionTypeBuildersRequest = (params = {}) => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/InspectionTypeBuilders/FacilityExists/${id}`,
  }
}

export default getIsFacilityInInspectionTypeBuildersRequest
