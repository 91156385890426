import { useCallback } from 'react'
import { arrayOf, shape, string, func, number, oneOfType } from 'prop-types'

import { Checkbox } from '../checkbox'

import s from './checkbox-group.module.scss'

const isChecked = (values, value) => {
  if (!values || !Array.isArray(values)) {
    return false
  }

  return values.indexOf(value) > -1
}

const CheckboxGroup = props => {
  const { value: values, onChange, options, ...restProps } = props

  const getOnChange = useCallback(
    activeValue => e => {
      let updatedValues

      if (e.target.checked) {
        updatedValues = [...values, activeValue]
      } else {
        updatedValues = values.filter(v => v !== activeValue)
      }

      onChange(updatedValues)
    },
    [onChange, values],
  )

  return (
    <div>
      {options.map(option => (
        <Checkbox
          {...restProps}
          className={s['checkbox-group-list-item']}
          label={option.label}
          onChange={getOnChange(option.value)}
          checked={option.checked || isChecked(values, option.value)}
          key={option.value}
          disabled={option.disabled || restProps?.disabled}
        />
      ))}
    </div>
  )
}

CheckboxGroup.propTypes = {
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    }),
  ).isRequired,
  value: arrayOf(oneOfType([string, number])).isRequired,
  onChange: func.isRequired,
}

CheckboxGroup.defaultProps = {
  options: [],
  value: [],
  onChange: () => {},
}

export default CheckboxGroup
