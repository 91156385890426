import { IInspectionTypeBuilder } from '../interfaces'

const isItbsEqual = (itb1: IInspectionTypeBuilder, itb2: IInspectionTypeBuilder) => {
  if (typeof itb1.inspectionTypeId !== 'number') {
    return false
  }

  const isItb1SubtypePresented = typeof itb1.inspectionSubtypeId === 'number'
  const isItb2SubtypePresented = typeof itb2.inspectionSubtypeId === 'number'

  if (isItb1SubtypePresented !== isItb2SubtypePresented) {
    return false
  }

  return itb1.inspectionTypeId === itb2.inspectionTypeId && itb1.inspectionSubtypeId === itb2.inspectionSubtypeId
}

export default isItbsEqual
