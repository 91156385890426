import axios from 'axios'

import config from '../../config'

const deleteWorkOrder = async ({ airportId, workOrderId }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders/${workOrderId}`

    const response = await axios.delete(url)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default deleteWorkOrder
