import { Canvg, presets } from 'canvg'

import { IToPng } from './to-png-types'

const DEFAULT_WIDTH = 100
const DEFAULT_HEIGHT = 100

const toPng: IToPng = async svg => {
    const canvas = new OffscreenCanvas(DEFAULT_WIDTH, DEFAULT_HEIGHT)
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    // @ts-ignore:next-line
    const v = await Canvg.from(ctx, svg, presets)

    await v.render()

    const blob = await canvas.convertToBlob()
    const pngUrl = URL.createObjectURL(blob)

    return pngUrl
}

export default toPng
