import { FC } from 'react'

import RFFFields from '../../react-final-form-fields'

import { validateRequired } from '../../../../util/validation'
import { nameofFactory } from '../../../../util'

import { ITableSettingsFormFiledsProps, ITableSettingsValues } from './table-settings-form-types'

const nameof = nameofFactory<ITableSettingsValues>()

const activeColumnsLabel = 'Active Columns'

const TableSettingsFormFields: FC<ITableSettingsFormFiledsProps> = props => {
  const { disabled, columnsOptions } = props

  return (
    <>
      <RFFFields.CheckboxGroup
        name={nameof('activeColumnIds')}
        label={activeColumnsLabel}
        disabled={disabled}
        options={columnsOptions}
        validate={validateRequired}
      />
    </>
  )
}

export default TableSettingsFormFields
