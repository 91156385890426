import { FC, useCallback } from 'react'
import cn from 'classnames'

import { Button } from '../../button'
import { useAppContext } from '../../../app-context'
import { actions } from '../../../actions'

import s from './hamburger-button.module.scss'

type HamburgerButtonProps = {
  className?: string
}

const HamburgerButton: FC<HamburgerButtonProps> = props => {
  const { className } = props

  const { state, dispatch } = useAppContext()

  const toggleProfileDrawer = useCallback(
    () =>
      state.isProfileDrawerOpen
        ? dispatch(actions.navigationMenuDrawer.closeNavigationMenuDrawer())
        : dispatch(actions.navigationMenuDrawer.openNavigationMenuDrawer()),
    [state.isProfileDrawerOpen, dispatch],
  )
  return (
    <Button
      buttonType="button"
      className={cn('navbar-icon-actions-button', s['hamburger-button'], className)}
      onClick={toggleProfileDrawer}
      type="ghost-icon"
    >
      <i className="bi bi-list" />
    </Button>
  )
}

export default HamburgerButton
