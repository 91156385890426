import React from 'react'
import cn from 'classnames'

import { Button } from '../../../components/button'

import useAirport from '../../../features/use-airport'

import { capitalizeFirst } from '../../../util'

import s from '../drawer-header.module.scss'

type NavigationMenuDrawerHeaderProps = {
  onClose: () => any
}

const NavigationMenuDrawerHeader: React.FC<NavigationMenuDrawerHeaderProps> = props => {
  const { onClose } = props

  const airport = useAirport()

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <h4 className="mb-0">{capitalizeFirst(airport.name)}</h4>

      <div className="d-flex">
        <Button type="secondary-icon" className={`${s['close-btn']}`} onClick={onClose}>
          <i className={cn('bi bi-x', s['close-btn-icon'])} />
        </Button>
      </div>
    </div>
  )
}

export default NavigationMenuDrawerHeader
