import { useCallback } from 'react'

import checkAccess from './check-access'
import useProfile from '../use-profile'

const useAccessControl = () => {
  const profile = useProfile()
  const userClaims = profile.permission // These are the claims assigned to the user

  // resourceClaims indicate which claims are needed to access that resource
  const hasAccess = useCallback(resourceClaims => checkAccess(userClaims, resourceClaims), [userClaims])

  return { hasAccess, userClaims }
}

export default useAccessControl
