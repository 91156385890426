import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import { validateRequired } from '../../../../util/validation'

import { useItbsQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { ICloneITBFormProps } from './clone-itb-form-types'

const CloneITBForm: FC<ICloneITBFormProps> = ({
  handleSubmit,
  submitting,
  formName
}) => {
  const { airportId } = useProfile()

  const { data: inspectionTypeBuilders } = useItbsQuery({ airportId }, { activeOnly: true })

  const templateOptions = useMemo(
    () => (inspectionTypeBuilders || []).map(inspectionTypeBuilders => ({ label: inspectionTypeBuilders.displayTitle, value: { inspectionTypeId: inspectionTypeBuilders.inspectionTypeId, inspectionSubtypeId: inspectionTypeBuilders.inspectionSubtypeId } })),
    [inspectionTypeBuilders],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Select
        name="inspectionTemplate"
        options={templateOptions}
        label="Inspection Template"
        validate={validateRequired}
        disabled={submitting}
      />
    </form>
  )
}

export default CloneITBForm
