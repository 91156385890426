import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateChecklistTemplateMutationRequest from './get-update-checklist-template-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { IChecklistTemplate, IChecklistTemplateUpsert } from '../../interfaces'

const useUpdateChecklistTemplateMutation: IUseUpdateMutation<IChecklistTemplate, IChecklistTemplateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateChecklistTemplateMutationRequest(params), [params])

  const updateChecklistTemplate: IUpdateFn<IChecklistTemplateUpsert, IChecklistTemplate> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklistTemplate, Error, any>({
    mutationFn: updateChecklistTemplate,
    ...options,
  })

  return response
}

export default useUpdateChecklistTemplateMutation
