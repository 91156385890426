import { FC, useMemo } from 'react'
import cn from 'classnames'

import Badge from '../../badge/badge'
import PriorityBadge from '../../badge/priority-badge'
import DropdownButton from '../../dropdown-button/dropdown-button'
import { IListItemProps } from '../../list'

import { formatDateString } from '../../../util'

import { IWorkOrder } from '../../../interfaces'

import s from '../card.module.scss'

type SafetyConcernWorkOrderCardProps = IWorkOrder & IListItemProps<IWorkOrder>

const SafetyConcernWorkOrderCard: FC<SafetyConcernWorkOrderCardProps> = props => {
  const {
    componentRef,
    id,
    displayTitle,
    safetyItem,
    workOrderStartedAt,
    workOrderStartedByName,
    activeId,
    priorityName,
    userAssignedTo,
    parentStatus,
    logs,
    onTitleClick,
    actionsButtonOptions,
    renderActions,
  } = props

  const info = useMemo(() => {
    const formattedLastUpdatedAt = formatDateString(workOrderStartedAt)

    if (workOrderStartedAt && workOrderStartedByName) {
      return `${formattedLastUpdatedAt} by ${workOrderStartedByName}`
    }

    return ''
  }, [workOrderStartedAt, workOrderStartedByName])

  return (
    <div className={cn('card card-custom border border-2 p-4', activeId === id && s['active-card'])} ref={componentRef}>
      <div className={cn(s['card-header'], 'card-header border-0 m-0 p-0')}>
        <div
          className={cn('card-title font-weight-bold w-100', onTitleClick && 'cursor-pointer')}
          // @ts-ignore
          onClick={() => onTitleClick(props)}
        >
          {displayTitle}
        </div>

        {actionsButtonOptions && (
          <DropdownButton className={s['actions-button']} accessor={props} options={actionsButtonOptions} />
        )}
      </div>

      <div className="card-body p-0">
        {safetyItem?.conditionName && <div className="mt-2">{safetyItem.conditionName}</div>}

        {info && <div className="mt-2 text-truncate text-dark">{info}</div>}

        {safetyItem?.note && <div className="mt-2">{safetyItem.note}</div>}
      </div>

      <div className="border-0 card-footer d-flex mt-2 mx-0 p-0">
        {userAssignedTo && (
          <span className="avatar-circle float-left mt-1">
            {/* @ts-ignore */}
            <span className="initials">{userAssignedTo?.avatarShortName}</span>
          </span>
        )}

        <div className="ml-auto mr-0 d-flex align-items-center">
          <Badge color={parentStatus?.baseColor} status={parentStatus?.name} dataTestId="status-badge" />

          <PriorityBadge priority={priorityName} />

          {renderActions && renderActions(props)}

          <span className={`badge badge-light ml-2 ${s.badge}`}>Logs {logs?.length || 0}</span>
        </div>
      </div>
    </div>
  )
}

export default SafetyConcernWorkOrderCard
