import React from 'react'
import { string } from 'prop-types'

// Generic component to use when there is nothing to show in a list

const EmptyList = ({ children, className = '' }) => (
  <div className={`d-flex flex-column align-items-center p-4 w-100 ${className}`}>{children}</div>
)

export default EmptyList

EmptyList.propTypes = {
  className: string,
}
