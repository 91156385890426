import { FC } from 'react'

type PopUpRowProps = {
  label: string
  value: string | number
}

const PopUpRow: FC<PopUpRowProps> = props => {
  const { label, value } = props

  return (
    <div className="ml-2 mt-2">
      <span className="font-weight-bold">{label}:</span>
      <span className="ml-2">{value}</span>
    </div>
  )
}

export default PopUpRow
