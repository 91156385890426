import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { IFaq, IUseDeleteMutation, IGetDeleteRequest, IDeleteFn } from '../../interfaces'
import config from '../../config'

const getDeleteFaqMutationRequest: IGetDeleteRequest = () => ({
  method: 'delete',
  url: `${config.baseUrl}/help/Faqs/`,
})

const useDeleteFaqMutation: IUseDeleteMutation<IFaq, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteFaqMutationRequest(params), [params])

  const deleteFaq: IDeleteFn<IFaq> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<IFaq, Error, any>({ mutationFn: deleteFaq, ...options })

  return response
}

export default useDeleteFaqMutation
