import React, { useMemo } from 'react'
import { bool, element, string, func } from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'

import { menuModes } from './menu.constants'
import s from './menu-item.module.scss'

export const MenuItem = ({
  icon,
  isSearchCachedOnRedirect,
  label,
  mode,
  to,
  renderMenuItemLabel,
  menuItemClassName,
  onNavigateCb,
}) => {
  const { search } = useLocation()

  const calculatedPathname = useMemo(() => {
    if (isSearchCachedOnRedirect) {
      return `${to}${search}`
    }

    return to
  }, [to, search, isSearchCachedOnRedirect])

  const navClassName = cn(s['menu-item-link'], {
    [s['menu-item-link-horizontal']]: mode === menuModes.horizontal,
    [s['menu-item-link-vertical']]: mode === menuModes.vertical,
  })

  const labelClassName = cn({ [s['menu-item-label']]: Boolean(icon) })

  return (
    <li className={cn(s['menu-item'], menuItemClassName)}>
      <NavLink
        className={({ isActive }) => `${isActive ? s['active'] : ''} ${navClassName}`}
        to={calculatedPathname}
        onClick={onNavigateCb}
      >
        {typeof renderMenuItemLabel === 'function' ? (
          renderMenuItemLabel({ icon, label, labelClassName })
        ) : (
          <>
            {icon}
            {label && <span className={labelClassName}>{label}</span>}
          </>
        )}
      </NavLink>
    </li>
  )
}

MenuItem.defaultProps = {
  icon: null,
  isSearchCachedOnRedirect: false,
}

MenuItem.propTypes = {
  icon: element,
  isSearchCachedOnRedirect: bool,
  menuItemClassName: string,
  label: string.isRequired,
  mode: string.isRequired,
  to: string.isRequired,
  onNavigateCb: func,
}
