import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useActivityLogFilterQueries from './use-activity-log-filter-queries'

import { IActivityLogFilterFormFiledsProps, IActivityLogFilterValues } from './activity-log-filter-form-types'

const nameof = nameofFactory<IActivityLogFilterValues>()

const dateRangeLabel = 'Journal Date Range'
const tagLabel = 'Labels'
const searchTextLabel = 'Text Search'

const ActivityLogFilterFormFields: React.FC<IActivityLogFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const { tagsQuery, tagsOptions } = useActivityLogFilterQueries(values)

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('dateRange')}
            label={dateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(dateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('searchTagIds')}
            label={tagLabel}
            placeholder={getPlaceholderTextFromLabel(tagLabel)}
            options={tagsOptions}
            disabled={disabled || tagsQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <RFFFields.SearchInput
        name={nameof('searchText')}
        label={searchTextLabel}
        placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
        disabled={disabled}
      />
    </>
  )
}

export default ActivityLogFilterFormFields
