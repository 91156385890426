import { Checkbox } from '../../form/checkbox'

export const RowSelectionCell = (props: any) => {
  const { row } = props

  return (
    <Checkbox
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  )
}
