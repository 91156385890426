import { format, startOfDay, sub } from 'date-fns'

import { ITimeSpan } from '../interfaces'

const dateFormat = 'MM/dd/yyyy'

export const timeSpanToDateRange = (timeSpan: ITimeSpan) => {
  const today = startOfDay(new Date())

  const calculatedDate = sub(today, {
    days: timeSpan.days || 0,
    hours: timeSpan.hours || 0,
    minutes: timeSpan.minutes || 0,
    seconds: timeSpan.seconds || 0,
  })

  return {
    0: format(calculatedDate, dateFormat),
    1: format(today, dateFormat),
  }
}
