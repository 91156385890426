import featureType from '../../services/feature-type'

const features = [
  {
    id: featureType.Inspection.id,
    name: featureType.Inspection.name,
    notificationTypes: [
      { id: 1, name: 'Inspection Updated' },
      { id: 2, name: 'Inspection Completed' },
      { id: 18, name: 'Inspection Created' },
      { id: 19, name: 'Inspection Deleted' },
      { id: 20, name: 'Inspection Reopened' },
    ],
  },
  {
    id: featureType.WorkOrder.id,
    name: featureType.WorkOrder.name,
    notificationTypes: [
      { id: 3, name: 'Work Order Created' },
      { id: 4, name: 'Work Order Updated' },
      { id: 5, name: 'Work Order Completed' },
      { id: 15, name: 'Work Order Assigned' },
      { id: 16, name: 'Work Order Deleted' },
      { id: 17, name: 'Work Order Reopened' },
    ],
  },
  {
    id: featureType.Wildlife.id,
    name: featureType.Wildlife.name,
    notificationTypes: [
      { id: 6, name: 'Wildlife Created' },
      { id: 7, name: 'Wildlife Updated' },
      { id: 8, name: 'Wildlife Completed' },
    ],
  },
  {
    id: featureType.Training.id,
    name: featureType.Training.name,
    notificationTypes: [
      { id: 9, name: 'Training Created' },
      { id: 10, name: 'Training Updated' },
      { id: 11, name: 'Training Completed' },
    ],
  },
  {
    id: featureType.Reminder.id,
    name: featureType.Reminder.name,
    notificationTypes: [
      { id: 12, name: 'Reminder Created' },
      { id: 13, name: 'Reminder Updated' },
      { id: 14, name: 'Reminder Completed' },
    ],
  },
  {
    id: featureType.Checklist.id,
    name: featureType.Checklist.name,
    notificationTypes: [
      { id: 21, name: 'Checklist Created' },
      { id: 25, name: 'Checklist Updated' },
      { id: 22, name: 'Checklist Completed' },
    ],
  },
]

export default features
