import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortFacilitiesMutationRequest from './get-sort-facilities-mutation-request'

import { IUseSortFacilitiesMutation, ISortFacilities } from './use-sort-facilities-mutation-types'

const useSortFacilitiesMutation: IUseSortFacilitiesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortFacilitiesMutationRequest(params), [params])

  const sortFacilities: ISortFacilities = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortFacilities,
    ...options,
  })

  return response
}

export default useSortFacilitiesMutation
