import axios, { ResponseType } from 'axios'
import qs from 'qs'

import config from '../../config'

const getBlankInspectionPDF = (params: any, queryObject = {}, htmlPreview = false) => {
  const { airportId, inspectionTypeId } = params

  const queryString = qs.stringify({ ...queryObject, preview: htmlPreview }, { addQueryPrefix: true, skipNulls: true })

  const url = `${config.baseUrl}/${airportId}/Inspections/RenderBlankReport/${inspectionTypeId}${queryString}`

  return axios.get(url, {
    headers: { 'Content-Type': 'application/json' },
    responseType: (htmlPreview ? 'text/html' : 'blob') as ResponseType,
  })
}

export default getBlankInspectionPDF
