import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { UseMutationResult } from '@tanstack/react-query'

function download(blob: Blob, filename: string) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadDocumentMutation = (
  options = {},
): UseMutationResult<Blob | undefined, Error, { url: string; name: string }> => {
  const fetchDocument = useCallback(async ({ url, name }: { url: string; name: string }): Promise<Blob | undefined> => {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        if (blob) {
          download(new Blob([blob]), name)
        }
        return blob
      })
  }, [])

  const mutation = useMutation<Blob | undefined, Error, { url: string; name: string }>({
    mutationFn: fetchDocument,
    ...options,
  })

  return mutation
}

export default useDownloadDocumentMutation
