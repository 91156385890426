import { Method } from 'axios'

import config from '../../config'

export interface IGetDownloadWildlifesCSVMutationRequestParams {
  airportId: number
}

export interface IGetDownloadWildlifesCSVMutationRequestQueryObject {}

export interface IGetDownloadWildlifesCSVMutationRequestRequestObject {
  method: Method
  url: string
}

export interface IGetDownloadWildlifesCSVMutationRequest {
  (params: IGetDownloadWildlifesCSVMutationRequestParams): IGetDownloadWildlifesCSVMutationRequestRequestObject
}

const getDownloadWildlifesCSVMutationRequest: IGetDownloadWildlifesCSVMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/WildlifeSightings/Download`,
  }
}

export default getDownloadWildlifesCSVMutationRequest
