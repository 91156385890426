import { Button } from '../../../components/button'
import { useAuthContext, useProfile } from '../../../security'
import { useAuthUserByIdQuery } from '../../../services'
import { toPhone } from '../../../util'

import sharedDrawerStyles from '../drawer-body.module.scss'
import s from './profile-drawer-body.module.scss'

const ProfileDrawerBody = () => {
  const { airportId, userId } = useProfile()

  const { logout } = useAuthContext()

  const { data: authUser } = useAuthUserByIdQuery({ airportId, id: userId }, undefined, {
    refetchOnWindowFocus: false,
  })

  const userName = authUser?.userName || ''
  const phone = toPhone(authUser?.phoneNumber || '')

  const roles = (authUser?.roles || []).map((role: any) => ({ label: role.name }))

  return (
    <div className={sharedDrawerStyles['container']}>
      <span className={s['user-name']}>{userName}</span>

      {phone && <span className={s['phone']}>{phone}</span>}

      <div className={s['separator']} />

      {Array.isArray(roles) && roles.length > 0 && (
        <div>
          <div className={s['label']}>Associated Roles</div>
          {roles.map(role => (
            <div className={s['value']} key={role.label}>
              {role.label}
            </div>
          ))}
        </div>
      )}

      <Button onClick={logout} className={s['sign-out-btn']}>
        Sign Out
      </Button>
    </div>
  )
}

export default ProfileDrawerBody
