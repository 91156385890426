import React, { useMemo } from 'react'
import get from 'lodash.get'

import RFFFields from '../../react-final-form-fields'
import { validateRequired } from '../../../../util/validation'

import { useWildlifeSeedDataQuery } from '../../../../services'
import { useProfile } from '../../../../security'
import { nameofFactory } from '../../../../util'

import { IWildlifeSightingFormFiledsProps, IWildlifeSightingFormValues } from './wildlife-sighting-form-types'

const nameof = nameofFactory<IWildlifeSightingFormValues>()

const buildName = (name: string, prefix?: string) => (prefix ? `${prefix}.${name}` : name)

const WildlifeSightingFormFields: React.FC<IWildlifeSightingFormFiledsProps> = props => {
  const { values, labelClassName = '', fieldsPrefix } = props

  const { airportId } = useProfile()
  const { data: seedData, isLoading: isSeedDataLoading } = useWildlifeSeedDataQuery({ airportId }, undefined)

  const controlWrapperClassName = 'mb-2'

  const weatherOptions = useMemo(() => {
    return (seedData?.weathers || []).map(weather => ({
      label: weather.name,
      value: weather.id,
    }))
  }, [seedData?.weathers])

  const otherWeatherOptionIds = useMemo(
    () => (seedData?.weathers || []).filter(x => x.requiresComment).map(x => x.id),
    [seedData?.weathers],
  )

  const speciesOptions = useMemo(
    () =>
      (seedData?.species || [])?.map(species => ({
        label: species.name,
        value: species.id,
      })),
    [seedData?.species],
  )

  const otherSpeciesOptionIds = useMemo(
    () => (seedData?.species || []).filter(x => x.requiresComment).map(x => x.id),
    [seedData?.species],
  )

  const activityOptions = useMemo(
    () =>
      (seedData?.activities || [])?.map(activities => ({
        label: activities.name,
        value: activities.id,
      })),
    [seedData],
  )

  const otherActivityOptionIds = useMemo(
    () => (seedData?.activities || []).filter(x => x.requiresComment).map(x => x.id),
    [seedData?.activities],
  )

  const mitigationOptions = useMemo(
    () =>
      (seedData?.mitigations || []).map(mitigations => ({
        label: mitigations.name,
        value: mitigations.id,
      })),
    [seedData],
  )

  const otherMitigationOptionIds = useMemo(
    () => (seedData?.mitigations || []).filter(x => x.requiresComment).map(x => x.id),
    [seedData?.mitigations],
  )

  const weatherId = useMemo(() => get(values, 'weatherId'), [values])

  const wildlifeSpeciesId = useMemo(() => get(values, 'wildlifeSpeciesId'), [values])

  const wildlifeActivityId = useMemo(() => get(values, 'wildlifeActivityId'), [values])

  const wildlifeMitigationId = useMemo(() => get(values, 'wildlifeMitigationId'), [values])

  return (
    <>
      <h5 className="mb-4">Wildlife Sighting Details</h5>
      <div className="d-flex mt-4">
        <div className="flex-fill">
          <RFFFields.Select
            disabled={isSeedDataLoading}
            name={buildName(nameof('weatherId'), fieldsPrefix)}
            label="Weather"
            options={weatherOptions}
            validate={validateRequired}
            labelClassName={labelClassName}
            wrapperClassName={controlWrapperClassName}
          />
        </div>

        {weatherId && otherWeatherOptionIds.includes(weatherId) && (
          <div className="ml-4">
            <RFFFields.Input
              disabled={isSeedDataLoading}
              name={buildName(nameof('weatherOtherDescription'), fieldsPrefix)}
              label="Other Weather"
              validate={validateRequired}
              labelClassName={labelClassName}
              wrapperClassName={controlWrapperClassName}
            />
          </div>
        )}
      </div>

      <div className="d-flex mt-4">
        <div className="flex-fill">
          <RFFFields.Select
            disabled={isSeedDataLoading}
            name={buildName(nameof('wildlifeSpeciesId'), fieldsPrefix)}
            label="Species"
            options={speciesOptions}
            validate={validateRequired}
            labelClassName={labelClassName}
            wrapperClassName={controlWrapperClassName}
          />
        </div>

        {wildlifeSpeciesId && otherSpeciesOptionIds.includes(wildlifeSpeciesId) && (
          <div className="ml-4">
            <RFFFields.Input
              disabled={isSeedDataLoading}
              name={buildName(nameof('wildlifeSpeciesOtherDescription'), fieldsPrefix)}
              label="Other Species"
              validate={validateRequired}
              labelClassName={labelClassName}
              wrapperClassName={controlWrapperClassName}
            />
          </div>
        )}

        <div className="ml-4">
          <RFFFields.Input
            disabled={isSeedDataLoading}
            name={buildName(nameof('wildlifeSpeciesCount'), fieldsPrefix)}
            type="number"
            label="Number"
            validate={validateRequired}
            labelClassName={labelClassName}
            wrapperClassName={controlWrapperClassName}
          />
        </div>
      </div>

      <div className="d-flex mt-4">
        <div className="flex-fill">
          <RFFFields.Select
            disabled={isSeedDataLoading}
            name={buildName(nameof('wildlifeActivityId'), fieldsPrefix)}
            label="Activity"
            options={activityOptions}
            validate={validateRequired}
            labelClassName={labelClassName}
            wrapperClassName={controlWrapperClassName}
          />
        </div>

        {wildlifeActivityId && otherActivityOptionIds.includes(wildlifeActivityId) && (
          <div className="ml-4">
            <RFFFields.Input
              disabled={isSeedDataLoading}
              name={buildName(nameof('wildlifeActivityOtherDescription'), fieldsPrefix)}
              label="Other Activity"
              validate={validateRequired}
              labelClassName={labelClassName}
              wrapperClassName={controlWrapperClassName}
            />
          </div>
        )}
      </div>

      <div className="d-flex mt-4">
        <div className="flex-fill">
          <RFFFields.Select
            disabled={isSeedDataLoading}
            name={buildName(nameof('wildlifeMitigationId'), fieldsPrefix)}
            label="Action"
            options={mitigationOptions}
            validate={validateRequired}
            wrapperClassName={controlWrapperClassName}
          />
        </div>

        {wildlifeMitigationId && otherMitigationOptionIds.includes(wildlifeMitigationId) && (
          <div className="ml-4">
            <RFFFields.Input
              disabled={isSeedDataLoading}
              name={buildName(nameof('wildlifeMitigationOtherDescription'), fieldsPrefix)}
              label="Other Action"
              validate={validateRequired}
              wrapperClassName={controlWrapperClassName}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default WildlifeSightingFormFields
