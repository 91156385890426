import { FC, useCallback, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Button from '../../button/button'
import AttributesRow from './attributes-row'

import { generateId } from '../../../util'
import { IAttribute, IAttributesProps } from './attributes-types'

import s from './attributes.module.scss'

const Attributes: FC<IAttributesProps> = props => {
  const { value, onChange, disabled, readOnly, innerLabel, namePlaceholder, valuePlaceholder, isAutoValue } = props

  const _value = useMemo(
    () =>
      value ||
      (isAutoValue
        ? [{ name: '', value: generateId(), id: generateId() }]
        : [{ name: '', value: '', id: generateId() }]),
    [value, isAutoValue],
  )

  const swapAttributes = useCallback(
    (
      dragItem: { attribute: IAttribute; attributeIndex: number },
      dropItem: { attribute: IAttribute; attributeIndex: number },
    ) => {
      if (!_value) {
        return
      }

      const { attribute: dragRow } = dragItem
      const { attribute: dropRow } = dropItem

      const dragAttribute = _value?.find(attribute => attribute.id === dragRow.id)

      if (!dragAttribute) {
        return
      }

      const dropAttributeIndex = _value?.findIndex(attribute => attribute.id === dropRow.id)

      const _valueCopy: typeof _value = JSON.parse(JSON.stringify(_value))

      const _temporaryValue = _valueCopy.filter(d => !(d.id === dragAttribute.id))

      _temporaryValue.splice(dropAttributeIndex, 0, dragAttribute)

      onChange(_temporaryValue)

      const tempIndex = dragItem.attributeIndex
      dragItem.attributeIndex = dropItem.attributeIndex
      dropItem.attributeIndex = tempIndex
    },
    [_value, onChange],
  )

  const handleAddClick = useCallback(() => {
    const newValue = isAutoValue
      ? { name: '', value: generateId(), id: generateId() }
      : { name: '', value: '', id: generateId() }

    onChange([..._value, newValue])
  }, [isAutoValue, _value, onChange])

  const getHandleAttributeNameChange = useCallback(
    (index: number) => (value: string) => {
      onChange([
        ..._value.slice(0, index),
        {
          ..._value[index],
          name: value,
        },
        ..._value.slice(index + 1),
      ])
    },
    [_value, onChange],
  )

  const getHandleAttributeValueChange = useCallback(
    (index: number) => (e: any) => {
      onChange([
        ..._value.slice(0, index),
        {
          ..._value[index],
          value: e.target.value,
        },
        ..._value.slice(index + 1),
      ])
    },
    [_value, onChange],
  )

  const getHandleDeleteClick = useCallback(
    (index: number) => () => {
      onChange([..._value.slice(0, index), ..._value.slice(index + 1)])
    },
    [_value, onChange],
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5>{innerLabel || 'Attributes'}</h5>

        {!readOnly && (
          <Button onClick={handleAddClick} buttonType="button" disabled={disabled}>
            Add Attribute
          </Button>
        )}
      </div>

      {_value.map((attribute, index) => (
        <AttributesRow
          key={attribute.id}
          isAutoValue={isAutoValue}
          attribute={attribute}
          attributeIndex={index}
          onAttributeNameChange={getHandleAttributeNameChange(index)}
          onAttributeValueChange={getHandleAttributeValueChange(index)}
          onAttributeDeleteClick={getHandleDeleteClick(index)}
          disabled={disabled}
          swapAttributes={swapAttributes}
          namePlaceholder={namePlaceholder}
          valuePlaceholder={valuePlaceholder}
        />
      ))}

      {_value.length === 0 ? (
        <div className={s['no-data-preview']}>
          You can add checklist items here <i className="bi bi-box"></i>
        </div>
      ) : null}
    </DndProvider>
  )
}

export default Attributes
