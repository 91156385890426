import { FC } from 'react'

import RFFFields from '../../react-final-form-fields'
import { getPlaceholderTextFromLabel } from '../../../../util'
import { validateRequired } from '../../../../util/validation'

import { IChecklistTemplateFormProps } from './checklist-template-form-types'

const nameLabel = 'Name'
const descriptionLabel = 'Description'

const ChecklistTemplateForm: FC<IChecklistTemplateFormProps> = ({ handleSubmit, submitting, formName }) => {
  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input
        name="name"
        label={nameLabel}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        validate={validateRequired}
        disabled={submitting}
      />

      <RFFFields.Textarea
        name="description"
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        disabled={submitting}
      />
    </form>
  )
}

export default ChecklistTemplateForm
