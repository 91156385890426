import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getFacilityRequest from './get-facility-request'

import { IFacility } from '../../interfaces'
import { IUseFacilityQuery, IFetchFacility } from './use-facility-query-types'

const useFacilityQuery: IUseFacilityQuery = (params, queryObject, options) => {
  const request = useMemo(() => getFacilityRequest(params, queryObject), [params, queryObject])

  const fetchFacility: IFetchFacility = useCallback(() => axios(request).then(res => res.data), [request])

  const queryKey = useMemo(
    () => ['facility', params.airportId, params.id, queryObject],
    [params.airportId, params.id, queryObject],
  )

  const response = useQuery<IFacility | undefined, Error>(queryKey, fetchFacility, {
    initialData: undefined,
    enabled: Boolean(params.id),
    ...options,
  })

  return { ...response, queryKey }
}

export default useFacilityQuery
