import { FC } from 'react'

import RFFFields from '../../react-final-form-fields'

import { validateRequired } from '../../../../util/validation'

import { IFacilityLocationFormProps } from './facility-location-form-types'

const FacilityLocationForm: FC<IFacilityLocationFormProps> = ({
  handleSubmit,
  submitting,
  formName,
  renderAdditionalFields,
  disabled,
}) => (
  <form onSubmit={handleSubmit} id={formName}>
    <RFFFields.Input name="name" label="Name" disabled={submitting || disabled} validate={validateRequired} />

    <RFFFields.Textarea name="description" label="Description" disabled={submitting || disabled} />

    <RFFFields.Attributes name="attributes" disabled={submitting || disabled} />

    {renderAdditionalFields && renderAdditionalFields()}
  </form>
)

export default FacilityLocationForm
