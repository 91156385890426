import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { ISavedSearchFilters, IUseDeleteMutation, IGetDeleteRequest, IDeleteFn } from '../../interfaces'
import config from '../../config'

const getDeleteSavedSearchFilterMutationRequest: IGetDeleteRequest = () => ({
  method: 'delete',
  url: `${config.baseUrl}/SavedSearchFilters/`,
})

const useDeleteSavedSearchFilterMutationBase: IUseDeleteMutation<ISavedSearchFilters, number> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getDeleteSavedSearchFilterMutationRequest(params), [params])

  const deleteSavedSearchFilter: IDeleteFn<ISavedSearchFilters> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISavedSearchFilters, Error, any>({ mutationFn: deleteSavedSearchFilter, ...options })

  return response
}

const useDeleteSavedSearchFilterMutation = (options?: UseMutationOptions<ISavedSearchFilters, Error, number>) =>
  useDeleteSavedSearchFilterMutationBase({}, options)

export default useDeleteSavedSearchFilterMutation
