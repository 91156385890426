import { SafetyReportSeverity } from '../../interfaces'

const severity = [
  {
    label: 'Low',
    value: SafetyReportSeverity.Low,
  },
  {
    label: 'Medium',
    value: SafetyReportSeverity.Medium,
  },
  {
    label: 'High',
    value: SafetyReportSeverity.High,
  },
  {
    label: 'Urgent',
    value: SafetyReportSeverity.Urgent,
  },
]

export default severity
