import { validateRequired } from '../../../../util/validation'

import { ISafetyReportValues } from './safety-report-form-types'

export const validateSafetyReportValue = (safetyReportValues: ISafetyReportValues) => {
  let errorObject: any = {
    severity: undefined,
    description: undefined,
    documents: undefined,
  }

  errorObject.severity = validateRequired(safetyReportValues?.severity)

  const isDescriptionAbsent = validateRequired(safetyReportValues?.description) !== undefined
  const isDocumentsAbsent = !safetyReportValues?.documents || safetyReportValues?.documents?.length === 0

  if (isDescriptionAbsent && isDocumentsAbsent) {
    errorObject.description = 'You must add description or attachments'
    errorObject.documents = 'You must add description or attachments'
  }

  return errorObject
}
