import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IMessageOfTheDay, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  forEditing?: boolean
}

const getMessageOfTheDayRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/MessageOfTheDay${queryString}`,
  }
}

const useMessageOfTheDayQuery = buildGetQuery<Params, QueryObject | undefined, IMessageOfTheDay | undefined>({
  getRequest: getMessageOfTheDayRequest,
  getQueryKey: (params, queryObject) => ['message-of-the-day', params.airportId, queryObject?.forEditing || ''],
  defaultOptions: { initialData: undefined },
})

export default useMessageOfTheDayQuery
