import { FC, useEffect } from 'react'

import SafetyReportFormFields from './safety-report-form-fields'

import { ISafetyReportFormProps } from './safety-report-form-types'

const SafetyReportForm: FC<ISafetyReportFormProps> = props => {
  const { handleSubmit, formName, submitting, form, setForm, values } = props

  useEffect(() => {
    setForm && setForm(form)
    // eslint-disable-next-line
  }, [setForm])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <SafetyReportFormFields submitting={submitting} airportId={values.airportId} form={form} values={values} />
    </form>
  )
}

export default SafetyReportForm
