import config from '../../config'
import { buildGetQuery } from '../../util'

import { ITrainingTemplate, IGetRequestObject } from '../../interfaces'

const getTrainingTemplatesRequest = (): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/TrainingTemplates`,
})

const useTrainingTemplatesQuery = buildGetQuery<undefined, undefined, ITrainingTemplate[] | undefined>({
  getRequest: getTrainingTemplatesRequest,
  getQueryKey: () => ['training-templates'],
})

export default useTrainingTemplatesQuery
