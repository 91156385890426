import { isValid, format, parse } from 'date-fns'

const defaultValue = undefined

export const dateStringToDate = (dateString: string, format: string) => {
  if (dateString.length === 0) {
    return defaultValue
  }

  let index = 0

  while (true) {
    if (index === format.length) {
      return defaultValue
    }

    const appliedFormat = format.slice(0, format.length - index)
    const appliedDateString = dateString.slice(0, format.length - index)

    let date
    try {
      date = parse(appliedDateString, appliedFormat, new Date())
    } catch (err) {}

    if (isValid(date)) {
      return date
    }

    index++
  }
}

const isDateStringMatchFormatLength = (format: string, dateString: string) =>
  (format.length && format.replace(/'/g, '').length) === dateString?.length

export const getParseDate = (dateFormat: string) => (value: string) => {
  if (!value) return defaultValue

  if (!isDateStringMatchFormatLength(dateFormat, value)) {
    return value
  }

  let date

  try {
    date = parse(value, dateFormat, new Date())
  } catch (err) {
    console.warn(err)
    return defaultValue
  }

  if (isValid(date)) {
    return date
  }

  console.warn('Something went wrong while parsing date: ', value)
  return defaultValue
}

export const getNormalizeDate = (dateFormat: string) => (value: Date) => {
  if (!value) {
    return defaultValue
  }

  try {
    return format(value, dateFormat)
  } catch (err) {
    console.warn('Something went wrong while normalazing date: ', value)
    return defaultValue
  }
}
