import config from '../../config'

import { IGetGetRequest } from '../../interfaces'

const getInspectionByIdQueryRequest: IGetGetRequest = params => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Inspections/${id}`,
  }
}

export default getInspectionByIdQueryRequest
