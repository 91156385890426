export const buildDownloadParams = (
  downloadType: string = '',
  params: { [key: string]: any } = {},
  ids: (string | number)[] = [],
  key: string = 'ids',
) => {
  if (downloadType === 'matching') {
    return params
  }

  return { [key]: ids }
}
