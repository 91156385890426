import { FC, useMemo } from 'react'
import { Row, Table } from '@tanstack/react-table'

import cn from 'classnames'
import { useDrag, useDrop } from 'react-dnd'
// @ts-ignore
import * as get from 'lodash.get'

import { Cell } from '../cell'
import { RowPositionCell } from '../row-position-cell'
import { ReorderSettings } from '../table-types'

import s from '../table.module.scss'

const DND_ITEM_TYPE = 'ROW'

type TableRowProps = {
  row: Row<any>
  tableInstance: Table<any>
  reorderSettings?: ReorderSettings
}

const TableRow: FC<TableRowProps> = props => {
  const { row, tableInstance, reorderSettings } = props

  const [, dropRef] = useDrop<Row<any>>({
    accept: DND_ITEM_TYPE,
    hover(item) {
      if (item.original.id === row.original.id) {
        return
      }

      reorderSettings?.moveRow(item, row)
    },
  })

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      item: row,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: Boolean(reorderSettings?.isRowsDraggable),
    }),
    [reorderSettings?.isRowsDraggable],
  )

  const { rowSelection } = tableInstance.getState()

  const selectedRowIds = useMemo(() => Object.keys(rowSelection), [rowSelection])

  const isRowSelected = get(selectedRowIds, row.id)

  const comuttedRowClassName = cn(s['tr'], {
    [s['tr-selected']]: isRowSelected,
  })

  const opacity = isDragging ? 0 : 1

  return (
    <tr
      style={{ opacity }}
      className={comuttedRowClassName}
      role="row"
      ref={ref => {
        dropRef(ref)
        dragRef(ref)
      }}
    >
      {row.getVisibleCells().map((cell, i) => (
        <Cell cell={cell} key={`cell_${i}`} />
      ))}

      {reorderSettings?.isRowsDraggable && <RowPositionCell row={row} reorderSettings={reorderSettings} />}
    </tr>
  )
}

export default TableRow
