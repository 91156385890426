import axios from 'axios'

import config from '../../config'

const createWorkOrder = async ({ airportId, request }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders`

    return await axios.post(url, request)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default createWorkOrder
