import React from 'react'
import { useState, useEffect } from 'react'
import Select from 'react-select'
import { arrayOf, bool, func, shape, string } from 'prop-types'

import { byPropName } from '../util'

const byLastNameAscending = (a, b) => byPropName(a, b, 'lastName')

const UsersSelect = ({ disabled, onChange, userAssignedTo, users }) => {
  const defaultValue = { label: 'Search users...', value: '' }

  const [selectedInactiveIds, setSelectedInactiveIds] = useState([])
  useEffect(() => {
    if (Array.isArray(users) && userAssignedTo !== undefined) {
      const selectedUser = users.find(u => u.id === userAssignedTo)

      if (selectedUser && !selectedUser.isActive) {
        const isAssignedUserCached = selectedInactiveIds.indexOf(selectedUser.id) > -1

        if (!isAssignedUserCached) {
          setSelectedInactiveIds([...selectedInactiveIds, selectedUser.id])
        }
      }
    }
  }, [userAssignedTo, users, selectedInactiveIds])

  useEffect(
    () => () => {
      setSelectedInactiveIds([])
    },
    [],
  )

  const options = users
    .sort(byLastNameAscending)
    .filter(u => selectedInactiveIds.indexOf(u.id) > -1 || u.isActive)
    .map(u => ({ label: `${u.firstName} ${u.lastName}`, value: u.id, isDisabled: !u.isActive }))
  const value = options.find(o => o.value === userAssignedTo) || ''

  return (
    <Select
      defaultValue={defaultValue}
      escapeClearsValue={true}
      filterOption={(candidate, input) => candidate.label.toLowerCase().includes(input.toLowerCase())}
      isClearable={true}
      isDisabled={disabled}
      isSearchable={true}
      noOptionsMessage={() => 'No users match this filter.'}
      onChange={e => onChange(e ? e.value : '')}
      options={options}
      placeholder="Search users..."
      value={value}
    />
  )
}

UsersSelect.defaultProps = {
  disabled: false,
  onChange: () => {},
  userAssignedTo: '',
  users: [],
}

UsersSelect.propTypes = {
  disabled: bool,
  onChange: func,
  userAssignedTo: string,
  users: arrayOf(
    shape({
      id: string,
      firstName: string,
      lastName: string,
    }),
  ),
}

export default UsersSelect
