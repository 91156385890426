import { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { string, bool, arrayOf, func, oneOfType, number } from 'prop-types'

import ListItem from './list-item'
import { onlyUnique } from '../../../util'

const DraggableListItem = props => {
  const { label, isSelected, item, handleClick, value, options, disabled } = props

  const computedItem = [...item, value].filter(onlyUnique)
  const computedOptions = computedItem.map(item => options.find(option => option.value === item))

  const [, dragRef, preview] = useDrag(
    () => ({
      type: 'OPTION',
      item: computedOptions,
    }),
    [computedItem],
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return (
    <ListItem
      label={label}
      isSelected={isSelected}
      handleClick={handleClick}
      componentRef={dragRef}
      disabled={disabled}
    />
  )
}

DraggableListItem.propTypes = {
  label: oneOfType([string, number]),
  isSelected: bool,
  item: arrayOf(oneOfType([string, number])),
  handleClick: func,
  value: oneOfType([string, number]),
  disabled: bool,
}

export default DraggableListItem
