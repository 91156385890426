import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getStartTrainingSessionMutationRequest from './get-start-training-session-mutation-request'

import { IUseUpdateMutation, IUpdateFn, ITrainingSession } from '../../interfaces'

const useStartTrainingSessionMutation: IUseUpdateMutation<ITrainingSession, number | undefined> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getStartTrainingSessionMutationRequest(params), [params])

  const startTrainingSession: IUpdateFn<number | undefined, ITrainingSession> = useCallback(
    async (id?: number) => {
      let computedRequest = request
      if (id) {
        computedRequest = getStartTrainingSessionMutationRequest({ ...params, id })
      }

      return axios(computedRequest).then(res => res.data)
    },
    [request, params],
  )

  const response = useMutation<ITrainingSession, Error, number | undefined>({
    mutationFn: startTrainingSession,
    ...options,
  })

  return response
}

export default useStartTrainingSessionMutation
