import { FC, useMemo, ReactNode } from 'react'
import { Table } from '@tanstack/react-table'

import { SearchInput, ISearchInputProps } from '../../search-input'

import s from '../table.module.scss'

type TableCaptionProps = {
  tableInstance: Table<any>
  isGlobalFilterEnabled?: boolean
  renderAdditionalFilters: any
  isRowsDraggable: boolean
  actions: ReactNode
}

const TableCaption: FC<TableCaptionProps> = props => {
  const { tableInstance, isGlobalFilterEnabled, renderAdditionalFilters, actions, isRowsDraggable } = props

  const { setGlobalFilter } = tableInstance

  const { globalFilter } = tableInstance.getState()

  const globalFilterProps: ISearchInputProps | null = useMemo(() => {
    if (!isGlobalFilterEnabled) {
      return null
    }

    return {
      value: globalFilter,
      onChange: setGlobalFilter,
    }
  }, [globalFilter, setGlobalFilter, isGlobalFilterEnabled])

  if (!globalFilterProps && !renderAdditionalFilters && !actions) {
    return null
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex justify-center w-100">
        {globalFilterProps && (
          <SearchInput {...globalFilterProps} wrapperClassName={s['query-input']} disabled={isRowsDraggable} />
        )}

        {renderAdditionalFilters &&
          renderAdditionalFilters({
            columns: tableInstance.getAllColumns(),
            isRowsDraggable,
          })}
      </div>

      {actions}
    </div>
  )
}

export default TableCaption
