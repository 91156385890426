import React from 'react'
import { Modal as ReactBootstrapModal, Button, Spinner } from 'react-bootstrap'
import cn from 'classnames'

import s from './modal.module.scss'

type ModalProps = {
  title: string
  isOpen: boolean
  onClose: () => any
  onSubmit?: () => any
  formName?: string
  children?: React.ReactNode
  isDisabled?: boolean
  confirmBtnVariant?: string
  confirmBtnText: string
  cancelBtnText?: string
  size?: 'sm' | 'lg' | 'xl'
  hideCancelBtn?: boolean
  isSubmitBtnLoading?: boolean
  bodyClassName?: string
}

const Modal: React.FC<ModalProps> = props => {
  const {
    title,
    isOpen,
    onClose,
    onSubmit,
    formName,
    children,
    isDisabled,
    isSubmitBtnLoading,
    confirmBtnText,
    cancelBtnText,
    confirmBtnVariant = 'primary',
    size,
    hideCancelBtn,
    bodyClassName,
  } = props

  const submitBtn =
    onSubmit && !formName ? (
      <Button variant={confirmBtnVariant} onClick={onSubmit} disabled={isDisabled}>
        {confirmBtnText}
        {isSubmitBtnLoading && <Spinner animation="border" size="sm" className="ml-2" />}
      </Button>
    ) : (
      <Button variant={confirmBtnVariant} type="submit" form={formName} disabled={isDisabled}>
        {confirmBtnText}
        {isSubmitBtnLoading && <Spinner animation="border" size="sm" className="ml-2" />}
      </Button>
    )

  return (
    <ReactBootstrapModal show={isOpen} onHide={isDisabled ? () => {} : onClose} centered size={size}>
      <ReactBootstrapModal.Header closeButton>
        <ReactBootstrapModal.Title>{title}</ReactBootstrapModal.Title>
      </ReactBootstrapModal.Header>

      {Boolean(children) && (
        <ReactBootstrapModal.Body className={cn(s['body'], bodyClassName)}>{children}</ReactBootstrapModal.Body>
      )}

      <ReactBootstrapModal.Footer className="flex-nowrap">
        {!hideCancelBtn && (
          <Button variant="secondary" onClick={isDisabled ? () => {} : onClose} disabled={isDisabled}>
            {cancelBtnText}
          </Button>
        )}
        {submitBtn}
      </ReactBootstrapModal.Footer>
    </ReactBootstrapModal>
  )
}

export default Modal
