import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortSafetyConcernSearchFilter } from '../../../../interfaces'
import { ISafetyConcernFilterValues, ISafetyConcernFilterUpsertValues } from './safety-concern-filter-form-types'

export const objectToSafetyConcernFilterValues = (object?: {
  [key: string]: any
}): ISafetyConcernFilterUpsertValues => ({
  startedDateRange: { 0: object?.startedAtFrom || '', 1: object?.startedAtTo || '' },
  injuryOccurred: object?.injuryOccurred || 0,
  damageOccurred: object?.damageOccurred || 0,
  recurringConcern: object?.recurringConcern || 0,
  originatedFromSafetyReport: object?.originatedFromSafetyReport || 0,
  statusIds: object?.statusIds || [],
  priorityIds: object?.priorityIds || [],
})

export const savedSearchFilterToSavedSafetyConcernFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): ISafetyConcernFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let workOrderFilterObject
  try {
    workOrderFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    workOrderFilterObject = {}
  }

  const filterValues = objectToSafetyConcernFilterValues(workOrderFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    filterValues.startedDateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return filterValues
}

export const savedSafetyConcernFilterValuesToSavedSearchFilterAddUpsert = (
  savedSafetyConcernFilterValues: ISafetyConcernFilterValues | undefined,
): IShortSafetyConcernSearchFilter => ({
  startedAtBegin: savedSafetyConcernFilterValues?.startedDateRange?.[0] || '',
  startedAtEnd: savedSafetyConcernFilterValues?.startedDateRange?.[1] || '',
  injuryOccurred: savedSafetyConcernFilterValues?.injuryOccurred || 0,
  damageOccurred: savedSafetyConcernFilterValues?.damageOccurred || 0,
  recurringConcern: savedSafetyConcernFilterValues?.recurringConcern || 0,
  originatedFromSafetyReport: savedSafetyConcernFilterValues?.originatedFromSafetyReport || 0,
  statusIds: savedSafetyConcernFilterValues?.statusIds || [],
  priorityIds: savedSafetyConcernFilterValues?.priorityIds || [],
})

export const savedSafetyConcernFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedSafetyConcernFilterValues: ISafetyConcernFilterValues | undefined,
): IShortSafetyConcernSearchFilter =>
  savedSafetyConcernFilterValuesToSavedSearchFilterAddUpsert(savedSafetyConcernFilterValues)
