import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPostRequest,
  IUseAddMutation,
  IAddFn,
  ISafetyConcern,
  ISafetyConcernStatusAddUpsert,
} from '../../interfaces'

const getCreateSafetyConcernStatusMutationRequest: IGetPostRequest = () => ({
  method: 'post',
  url: `${config.baseUrl}/SafetyConcernStatuses`,
})

const useCreateSafetyConcernStatusMutation: IUseAddMutation<ISafetyConcern, ISafetyConcernStatusAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getCreateSafetyConcernStatusMutationRequest(params), [params])

  const createSafetyConcernStatus: IAddFn<ISafetyConcernStatusAddUpsert, ISafetyConcern> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyConcern, Error, ISafetyConcernStatusAddUpsert>({
    mutationFn: createSafetyConcernStatus,
    ...options,
  })

  return response
}

export default useCreateSafetyConcernStatusMutation
