import axios from 'axios'

import config from '../../config'

const updatePersonalizationMapStatuses = async (airportId, personalizationMapStatuses) => {
  const url = `${config.baseUrl}/${airportId}/PersonalizationMapStatuses`
  return await axios.put(url, personalizationMapStatuses)
}

export default updatePersonalizationMapStatuses
