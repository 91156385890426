import config from '../../config'

import { IGetCreateLocationMutation } from './types'

const getCreateLocationMutationRequest: IGetCreateLocationMutation = (params) => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Locations`,
  }
}

export default getCreateLocationMutationRequest
