import React from 'react'

type SpinnerProps = {
  colorClassName?: string
}

const Spinner: React.FC<SpinnerProps> = ({colorClassName = 'text-primary'}) => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center text-center">
      <div className={`spinner-border ${colorClassName}`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Spinner
