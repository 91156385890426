import { useEffect } from 'react'

export const useWindowEvent = (event, callback, enabled = true) => {
  useEffect(() => {
    if (!enabled) {
      return
    }
    window.addEventListener(event, callback)
    return () => window.removeEventListener(event, callback)
  }, [event, callback, enabled])
}
