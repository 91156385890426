import { FC, useMemo } from 'react'

import HorizontalRule from '../../../horizontal-rule'
import RFFFields from '../../react-final-form-fields'
import { ChecklistItems } from './checklist-items'

import { IMyTrainingFormFieldsProps } from './my-training-form-types'

import s from './my-training-form-fields.module.scss'

const MyTrainingFormFields: FC<IMyTrainingFormFieldsProps> = props => {
  const { submitting, disabled } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <>
      <ChecklistItems name="groupedChecklistItems" disabled={isDisabled} />

      <HorizontalRule />

      <RFFFields.DocumentUpload
        name="documents"
        disabled={isDisabled}
        wrapperClassName={s['document-upload']}
        parentType="Training"
      />
    </>
  )
}

export default MyTrainingFormFields
