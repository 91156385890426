import { FC, useState, useMemo, useCallback } from 'react'
import { InfoWindow } from '@react-google-maps/api'

import { calculatePolygonCenter } from '../../util'
import Polygon from './polygon'

import { useGoogleMaps } from './google-maps-provider'

import { ILayoutProps } from './layout-types'
import { IGeoJson } from '../../interfaces/services/geo-json'
import { ACTIVE_MARKER_COLOR } from './hooks/use-marker-icons'

const defaultGetId = (geoJson: IGeoJson) => geoJson?.properties?.id

const PolygonLayout: FC<ILayoutProps> = props => {
  const {
    geoJson,
    renderPopUp,
    activeId,
    setActiveId,
    editingGeoJsonId,
    renderEditPopUp,
    onUpdate,
    infoId,
    setInfoId,
    getId = defaultGetId,
  } = props

  const { isMeasuring } = useGoogleMaps()

  const [activePolygonId, setActivePolygonId] = useState<number | undefined>(undefined)

  const _activePolygonId = useMemo(() => activeId || activePolygonId, [activeId, activePolygonId])

  const _setActivePolygonId = useCallback(
    (id: number | undefined) => (setActiveId ? setActiveId(id) : setActivePolygonId(id)),
    [setActiveId, setActivePolygonId],
  )

  const [infoPolygonId, setInfoPolygonId] = useState<number | undefined>(undefined)

  const _infoPolygonId = useMemo(() => infoId || infoPolygonId, [infoId, infoPolygonId])

  const _setInfoPolygonId = useCallback(
    (id: number | undefined) => (setInfoId ? setInfoId(id) : setInfoPolygonId(id)),
    [setInfoId, setInfoPolygonId],
  )

  const infoPolygon = useMemo(
    () => (_infoPolygonId ? geoJson.find(geoJsonItem => getId(geoJsonItem) === _infoPolygonId) : null),
    [getId, _infoPolygonId, geoJson],
  )

  const onPolygonClick = useCallback(
    (geoJson: IGeoJson) => {
      _setActivePolygonId(getId(geoJson))
      _setInfoPolygonId(getId(geoJson))
    },
    [getId, _setActivePolygonId, _setInfoPolygonId],
  )

  return (
    <>
      {geoJson.map(geoJson => (
        <Polygon
          key={getId(geoJson)}
          geoJson={geoJson}
          onClick={!isMeasuring ? onPolygonClick : undefined}
          color={getId(geoJson) === _activePolygonId ? ACTIVE_MARKER_COLOR : undefined}
          editable={editingGeoJsonId === getId(geoJson)}
          onUpdate={onUpdate}
          renderEditPopUp={renderEditPopUp}
        />
      ))}

      {infoPolygon && getId(infoPolygon) !== editingGeoJsonId && renderPopUp && (
        <InfoWindow
          position={{
            lat: calculatePolygonCenter(infoPolygon.geometry.coordinates[0]).latitude,
            lng: calculatePolygonCenter(infoPolygon.geometry.coordinates[0]).longitude,
          }}
          onCloseClick={() => {
            _setActivePolygonId(undefined)
            _setInfoPolygonId(undefined)
          }}
          options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
        >
          {renderPopUp && <>{renderPopUp(infoPolygon, { setInfoId: _setInfoPolygonId })}</>}
        </InfoWindow>
      )}
    </>
  )
}

export default PolygonLayout
