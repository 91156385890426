import { useCallback, useMemo } from 'react';
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteLocationMutationRequest from './get-delete-location-mutation-request'

import { ILocation } from '../../interfaces'
import { IUseDeleteLocationMutation, IDeleteLocation } from './types'

const useDeleteLocationMutation: IUseDeleteLocationMutation = (params, options = {}) => {
    const request = useMemo(() => getDeleteLocationMutationRequest(params), [params])

    const deleteLocation: IDeleteLocation = useCallback(async (id) => axios({ ...request, url: request.url + id }).then(res => res.data)
        , [request])

    const response = useMutation<ILocation, Error, any>({ mutationFn: deleteLocation, ...options })

    return response
}

export default useDeleteLocationMutation
