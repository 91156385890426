import { QueryObserverOptions } from '@tanstack/react-query'
import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISavedSearchFilters, IGetRequestObject, SavedSearchFilterType } from '../../interfaces'

type QueryObject = {
  savedSearchFiltersType: SavedSearchFilterType
}

const getSavedSearchFiltersRequest = (params: undefined, queryObject: QueryObject): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/SavedSearchFilters${queryString}`,
  }
}

const useSavedSearchFiltersQueryBase = buildGetQuery<undefined, QueryObject, ISavedSearchFilters[] | undefined>({
  getRequest: getSavedSearchFiltersRequest,
  getQueryKey: (_, queryObject) => ['saved-search-filters', queryObject?.savedSearchFiltersType],
})

const useSavedSearchFiltersQuery = (
  queryObject: QueryObject,
  options?: QueryObserverOptions<ISavedSearchFilters[] | undefined, Error>,
) => useSavedSearchFiltersQueryBase(undefined, queryObject, options)

export default useSavedSearchFiltersQuery
