import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ITBListItem, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  inspectionCategoryId?: number
}

const getItbsTemplatesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/InspectionTypeBuilders/Templates${queryString}`,
  }
}

const useItbsTemplatesQuery = buildGetQuery<Params, QueryObject | undefined, ITBListItem[] | undefined>({
  getRequest: getItbsTemplatesRequest,
  getQueryKey: (params, queryObject) => [
    'inspection-type-builders-templates',
    params.airportId,
    queryObject?.inspectionCategoryId || '',
  ],
})

export default useItbsTemplatesQuery
