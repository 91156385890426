import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortSafetyItemResponsesMutationRequest from './get-sort-safety-item-responses-mutation-request'

import {
  IUseSortSafetyItemResponsesMutation,
  ISortSafetyItemResponses,
} from './use-sort-safety-item-responses-mutation-types'

const useSortSafetyItemResponsesMutation: IUseSortSafetyItemResponsesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortSafetyItemResponsesMutationRequest(params), [params])

  const sortSafetyItemResponses: ISortSafetyItemResponses = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortSafetyItemResponses,
    ...options,
  })

  return response
}

export default useSortSafetyItemResponsesMutation
