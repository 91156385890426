import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteLocationMutationRequest from './get-delete-checklist-mutation-request'

import { IUseDeleteMutation, IDeleteFn } from '../../interfaces'
import { IChecklist } from '../../interfaces'

const useDeleteLocationMutation: IUseDeleteMutation<IChecklist, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteLocationMutationRequest(params), [params])

  const deleteLocation: IDeleteFn<IChecklist> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklist, Error, any>({ mutationFn: deleteLocation, ...options })

  return response
}

export default useDeleteLocationMutation
