import config from '../../config'
import { AxiosRequestConfig } from 'axios'

const getUpdateDashboardWidgetsMutationRequest = (): AxiosRequestConfig => {
  return {
    method: 'put',
    url: `${config.baseUrl}/Dashboard/Widgets`,
  }
}

export default getUpdateDashboardWidgetsMutationRequest
