import { components } from 'react-select'

import s from './select-checkbox-dropdown.module.scss'

const SelectCheckboxDropdown = props => {
  return (
    <components.Option {...props}>
      <div className={s['container']}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </div>
    </components.Option>
  )
}

export default SelectCheckboxDropdown
