import React, { useMemo, useContext, useState, useCallback } from 'react'
import { useTable } from 'react-table'
import cn from 'classnames'

import ChecklistPreview from './checklist-preview/checklist-preview'
import ChecklistCell from './checklist-cell/checklist-cell'
import ChecklistRow from './checklist-row/checklist-row'
import { ChecklistHeader } from './checklist-header'
import { Button } from '../button'

import withChecklistContext from './with-checklist-context'

import { ChecklistContext } from './checklist-context'

import { ChecklistProps, ChecklistRow as ChecklistRowType } from './checklist-types'

import s from './checklist.module.scss'

const Checklist: React.FC<ChecklistProps> = props => {
  const { classNames } = props

  const { checklist, isConfigurable, addChecklistRow } = useContext(ChecklistContext)

  const calculatedColumns = useMemo(
    () => (checklist?.columns ? checklist.columns.map(column => ({ ...column, Cell: ChecklistCell })) : []),
    [checklist],
  )

  const tableInstance = useTable<ChecklistRowType>({
    data: checklist ? checklist.data : [],
    columns: calculatedColumns,
  })

  const { rows } = tableInstance

  const [activeRowIndex, setActiveRowIndex] = useState()

  const addRowToEnd = useCallback(
    (isSubheadRow?: boolean) => addChecklistRow((checklist?.data.length || 0) + 1, isSubheadRow),
    [addChecklistRow, checklist],
  )

  return (
    <>
      <div className={cn(s['table-wrapper'])}>
        <table className={cn(s['table'], classNames?.tableClassName)}>
          <ChecklistHeader tableInstance={tableInstance} classNames={classNames} />

          <tbody className={classNames?.tbodyClassName}>
            {rows.map((row, index) => (
              <ChecklistRow
                classNames={classNames}
                isActiveRow={activeRowIndex === index}
                setActiveRowId={setActiveRowIndex}
                key={row.id}
                tableInstance={tableInstance}
                row={row}
                index={index}
              />
            ))}
          </tbody>
        </table>
      </div>

      {isConfigurable && (
        <div className={s['row-actions-section']}>
          <Button buttonType="button" type="secondary" onClick={() => addRowToEnd()}>
            Add Row
          </Button>
          <Button className="ml-2" buttonType="button" type="secondary" onClick={() => addRowToEnd(true)}>
            Add Subtitle Row
          </Button>
        </div>
      )}

      {/* Render drag column */}
      {<ChecklistPreview tableInstance={tableInstance} />}
    </>
  )
}

export default withChecklistContext(Checklist)
