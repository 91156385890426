import React from 'react'
import { Field } from 'react-final-form'
import { string, func } from 'prop-types'

const withRFF = Component => props => {
  const {
    validate,
    parse,
    label,
    info,
    type,
    value,
    errorClassName,
    labelClassName,
    subLabel,
    subLabelClassName,
    wrapperClassName,
    initialValue,
    ...inputProps
  } = props

  return (
    <Field name={props.name} validate={validate} parse={parse} type={type} value={value} initialValue={initialValue}>
      {({ input, meta }) => {
        const isInvalid = (meta.error || meta.submitError) && meta.touched
        const error = meta.error || meta.submitError

        return (
          <Component
            errorProps={{ isInvalid, error, isDisabled: props.disabled, errorClassName }}
            labelProps={{ label, isDisabled: props.disabled, labelClassName, info }}
            subLabelProps={{ subLabel, subLabelClassName }}
            componentProps={{ ...input, ...inputProps, isInvalid, wrapperClassName }}
          />
        )
      }}
    </Field>
  )
}

export const RFFFieldPropTypes = {
  input: {
    value: string,
    onChange: func,
  },
  meta: {
    error: string,
    submitError: string,
  },
}

export default withRFF
