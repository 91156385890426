import mapMode from './map-mode'

const getMapMode = (geoJson, location) => {
  if (location) {
    return mapMode.SAVED_LOCATION
  }

  return geoJson?.geometry?.type === 'Polygon' ? mapMode.POLYGON : mapMode.MARKER
}

export default getMapMode
