import React from 'react'
import { string } from 'prop-types'

// This is a temporary component for initial development to drop in a kitten placeholder image
const Placeholder = props => {
  const { className, text, url } = props

  return (
    <div className={className}>
      <img width="100%" src={url} alt={text} />
    </div>
  )
}

Placeholder.defaultProps = {
  className: '',
  text: 'Placeholder',
  url: 'https://placekitten.com/g/400/400',
}

Placeholder.propTypes = {
  className: string,
  text: string,
  url: string,
}

export default Placeholder
