import { useContext } from 'react'

import { DocumentUploadContext } from './document-upload-context'
import DocumentListItem from './document-list-item'
import DocumentUploadListItem from './document-upload-list-item'

const DocumentsList = () => {
  const {
    downloadingDocumentIds,
    removeDocument,
    removeLocalDocumentState,
    reUploadLocalDocumentState,
    downloadDocument,
    documents,
    localDocumentsUploadState,
    disabled,
  } = useContext(DocumentUploadContext)

  if (!Array.isArray(documents)) {
    return null
  }

  return (
    <>
      {localDocumentsUploadState.map(localDocumentUploadState => (
        <DocumentUploadListItem
          key={`document-${localDocumentUploadState.localDocumentName}`}
          document={localDocumentUploadState}
          removeDocument={() => removeLocalDocumentState(localDocumentUploadState)}
          reUploadDocument={() => reUploadLocalDocumentState(localDocumentUploadState)}
          disabled={disabled}
        />
      ))}

      {documents?.map(document => (
        <DocumentListItem
          key={`document-${document.id}`}
          document={document}
          isDocumentDownloading={downloadingDocumentIds.includes(document.id)}
          downloadDocument={() => downloadDocument(document)}
          removeDocument={() => removeDocument(document)}
          disabled={disabled}
        />
      ))}
    </>
  )
}

export default DocumentsList
