import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IGetPostRequest, IUseAddMutation, IAddFn, ISafetyReport, ISafetyReportAddUpsert } from '../../interfaces'

const getCreateSafetyReportMutationRequest: IGetPostRequest = () => ({
  method: 'post',
  url: `${config.baseUrl}/SafetyReport`,
})

const useCreateSafetyReportMutation: IUseAddMutation<ISafetyReport, ISafetyReportAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getCreateSafetyReportMutationRequest(params), [params])

  const createSafetyReport: IAddFn<ISafetyReportAddUpsert, ISafetyReport> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyReport, Error, ISafetyReportAddUpsert>({
    mutationFn: createSafetyReport,
    ...options,
  })

  return response
}

export default useCreateSafetyReportMutation
