import axios from 'axios'
import config from '../../config'
import { IAirportMapImagery } from './types'

const updateAirportMapImagery = async ({ airportId, imagery }: { airportId: number; imagery: IAirportMapImagery }) => {
  const url = `${config.baseUrl}/Airports/${airportId}/MapImagery`
  return await axios.put<IAirportMapImagery>(url, imagery)
}

export default updateAirportMapImagery
