import { ISafetyConcernValues } from './safety-concern-form-types'
import { ISafetyConcern, ISafetyConcernAddUpsert, ISafetyConcernUpdateUpsert, IGeoJson } from '../../../../interfaces'

export const safetyConcernToSafetyConcernValues = (
  safetyConcern: ISafetyConcern | undefined,
): ISafetyConcernValues | undefined => {
  if (!safetyConcern) {
    return undefined
  }

  return {
    summary: safetyConcern?.summary,
    description: safetyConcern?.description,
    priorityId: safetyConcern?.priority.id,
    statusId: safetyConcern?.parentStatus.id,
  }
}

export const safetyConcernValuesToSafetyConcernAddUpsert = (
  safetyConcernValues: ISafetyConcernValues,
  payload: { geoJson?: IGeoJson; airportId: number },
): ISafetyConcernAddUpsert | undefined => {
  if (!safetyConcernValues) {
    return undefined
  }

  return {
    id: 0,
    airportId: payload?.airportId,
    summary: safetyConcernValues?.summary,
    description: safetyConcernValues?.description,
    priorityId: safetyConcernValues?.priorityId,
    statusId: safetyConcernValues?.statusId,
    completed: false,
    geometry: payload?.geoJson && {
      type: String(payload.geoJson.geometry.type),
      coordinates: JSON.stringify(payload.geoJson.geometry.coordinates),
    },
  }
}

export const safetyConcernValuesToSafetyConcernUpdateUpsert = (
  safetyConcernValues: ISafetyConcernValues,
  payload: { safetyConcern: ISafetyConcern; geoJson?: IGeoJson },
): ISafetyConcernUpdateUpsert | undefined => {
  if (!safetyConcernValues) {
    return undefined
  }

  return {
    id: payload.safetyConcern.id,
    airportId: payload.safetyConcern?.airportId,
    summary: safetyConcernValues?.summary,
    description: safetyConcernValues?.description,
    priorityId: safetyConcernValues?.priorityId,
    statusId: safetyConcernValues?.statusId,
    completed: false,
    geometry: payload?.geoJson && {
      type: String(payload.geoJson.geometry.type),
      coordinates: JSON.stringify(payload.geoJson.geometry.coordinates),
    },
  }
}
