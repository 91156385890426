import { FC, useMemo } from 'react'
import cn from 'classnames'
import { isAfter, parse, isBefore, subDays } from 'date-fns'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { Info } from '../../../info'
import { options, getMaxDaysByMonthIndex } from '../../../../util'
import config from '../../../../config'
import { useAppContext } from '../../../../app-context'

import { IReminderFormProps } from './reminder-form-types'

import s from './reminder-form.module.scss'

const frequencyOptions = [{ label: 'None', value: 0 }, ...options.frequency]

const dayOfWeekOptions = options.dayOfWeek.map(day => ({ ...day, subLabel: day.label }))

const ReminderForm: FC<IReminderFormProps> = props => {
  const { submitting, values, initialValues, frequencyLabel, isForceDisabled, disablePastDates } = props

  // @ts-ignore
  const monthOfYearIndex = values?.monthOfYear

  const maxDays = monthOfYearIndex !== 0 && monthOfYearIndex ? getMaxDaysByMonthIndex(monthOfYearIndex - 1) : 31

  const daysOptions = options.day.slice(0, maxDays)

  const frequencyId = values?.reminder?.reminderFrequencyId

  const isNone = useMemo(() => frequencyId === 0, [frequencyId])
  const isDaily = useMemo(() => frequencyId === 1, [frequencyId])
  const isWeekly = useMemo(() => frequencyId === 2, [frequencyId])
  const isMonthly = useMemo(() => frequencyId === 3, [frequencyId])
  const isYearly = useMemo(() => frequencyId === 4, [frequencyId])

  const isDisabled = useMemo(() => isForceDisabled || submitting, [isForceDisabled, submitting])

  const { state } = useAppContext()
  const timeZoneIanaId = state.airport.timeZoneIanaId
  const yesterday = subDays(new Date(new Date().toLocaleString("en-US", {timeZone: timeZoneIanaId})), 1)
  const excludedDateIntervals = useMemo(() => disablePastDates ? [{ start: new Date(0), end: yesterday }] : [], [disablePastDates, yesterday])

  return (
    <>
      <RFFFields.Select
        name="reminder.reminderFrequencyId"
        label={frequencyLabel || 'Reminder Frequency'}
        placeholder="Select from the list"
        options={frequencyOptions}
        disabled={isDisabled}
      />

      {isDaily && (
        <>
          <label className="form-label">Recurrence pattern</label>

          <div className="d-flex">
            <span className={cn(s['field-text'], s['field-text-before'])}>Every</span>

            <RFFFields.MaskedInput
              type="number"
              name="reminder.daily.separationCount"
              placeholder="Day"
              disabled={isDisabled}
            />

            <span className={cn(s['field-text'], s['field-text-after'])}>day(s)</span>
          </div>
        </>
      )}

      {isWeekly && (
        <>
          <label className="form-label">Recurrence pattern</label>

          <div className="d-flex mb-6">
            <span className={cn(s['field-text'], s['field-text-before'])}>Recur every</span>

            <RFFFields.MaskedInput
              type="number"
              name="reminder.weekly.separationCount"
              placeholder="Week"
              disabled={isDisabled}
            />

            <span className={cn(s['field-text'], s['field-text-after'])}>week(s) on:</span>
          </div>

          <RFFFields.RadioGroup name="reminder.weekly.dayOfWeek" options={dayOfWeekOptions} disabled={isDisabled} />
        </>
      )}

      {isMonthly && (
        <>
          <div className="d-flex">
            <RFFFields.Radio name="reminder.monthly.type" value="type1" wrapperClassName="mt-3" />

            <span className={cn(s['field-text'], s['field-text-before'])}>Day</span>

            <RFFFields.Select
              name="reminder.monthly.type1.dayOfMonth"
              placeholder="Day number"
              options={daysOptions}
              disabled={isDisabled || values?.reminder?.monthly?.type !== 'type1'}
            />

            <span className={cn(s['field-text'], s['field-text-both'])}>of every</span>

            <RFFFields.MaskedInput
              type="number"
              name="reminder.monthly.type1.separationCount"
              placeholder="Month"
              disabled={isDisabled || values?.reminder?.monthly?.type !== 'type1'}
            />

            <span className={cn(s['field-text'], s['field-text-both'])}>month(s)</span>

            {Number(values?.reminder?.monthly?.type1?.dayOfMonth) > 28 && (
              <Info
                className="mt-2"
                info={`Some months have fewer than ${values?.reminder?.monthly?.type1?.dayOfMonth} days. For these months, the occurrence will fall on the last day of the month.`}
              />
            )}
          </div>

          <div className="d-flex">
            <RFFFields.Radio name="reminder.monthly.type" value="type2" wrapperClassName="mt-3" />

            <span className={cn(s['field-text'], s['field-text-before'])}>The</span>

            <RFFFields.Select
              wrapperClassName="mr-6"
              name="reminder.monthly.type2.weekOfMonth"
              placeholder="Week number"
              options={options.weekOfMonth}
              disabled={isDisabled || values?.reminder?.monthly?.type !== 'type2'}
            />

            <RFFFields.Select
              name="reminder.monthly.type2.dayOfWeek"
              placeholder="Day of week"
              options={dayOfWeekOptions}
              disabled={isDisabled || values?.reminder?.monthly?.type !== 'type2'}
            />

            <span className={cn(s['field-text'], s['field-text-both'])}>of every Month</span>
          </div>
        </>
      )}

      {isYearly && (
        <>
          <div className="d-flex">
            <span className={cn(s['field-text'], s['field-text-before'])}>Recur every</span>

            <RFFFields.MaskedInput
              type="number"
              name="reminder.yearly.separationCount"
              placeholder="Year"
              disabled={isDisabled}
            />

            <span className={cn(s['field-text'], s['field-text-after'])}>year(s)</span>
          </div>

          <div className="d-flex">
            <RFFFields.Radio name="reminder.yearly.type" value="type1" wrapperClassName="mt-3" />

            <span className={cn(s['field-text'], s['field-text-before'])}>On</span>

            <RFFFields.Select
              wrapperClassName="mr-6"
              name="reminder.yearly.type1.monthOfYear"
              placeholder="Month"
              options={options.month}
              disabled={isDisabled || values?.reminder?.yearly?.type !== 'type1'}
            />

            <RFFFields.Select
              name="reminder.yearly.type1.dayOfMonth"
              placeholder="Day of month"
              options={daysOptions}
              disabled={isDisabled || values?.reminder?.yearly?.type !== 'type1'}
            />
          </div>

          <div className="d-flex">
            <RFFFields.Radio name="reminder.yearly.type" value="type2" wrapperClassName="mt-3" />

            <span className={cn(s['field-text'], s['field-text-before'])}>On the</span>

            <RFFFields.Select
              wrapperClassName="mr-6"
              name="reminder.yearly.type2.weekOfMonth"
              placeholder="Week of month"
              options={options.weekOfMonth}
              disabled={isDisabled || values?.reminder?.yearly?.type !== 'type2'}
            />

            <RFFFields.Select
              name="reminder.yearly.type2.dayOfWeek"
              placeholder="Day of week"
              options={dayOfWeekOptions}
              disabled={isDisabled || values?.reminder?.yearly?.type !== 'type2'}
            />

            <span className={cn(s['field-text'], s['field-text-both'])}>Of</span>

            <RFFFields.Select
              name="reminder.yearly.type2.monthOfYear"
              placeholder="Month of year"
              options={options.month}
              disabled={isDisabled || values?.reminder?.yearly?.type !== 'type2'}
            />
          </div>
        </>
      )}

      <Row>
        <Col>
          <RFFFields.DatePicker
            wrapperClassName="flex-grow-1"
            label={isNone ? 'Date' : 'Start Date'}
            placeholderText={isNone ? 'Enter Date' : 'Enter Start Date'}
            name="reminder.startDate"
            excludeDateIntervals={excludedDateIntervals}
            filterDate={(date: any) =>
              values?.reminder?.endDate
                ? isBefore(date, parse(values.reminder.endDate, config.dateAndTimeFormats.date, new Date()))
                : true
            }
            disabled={Boolean(isDisabled || initialValues?.reminder?.startDate)}
            isDateOnly
            shouldCloseOnSelect
            popperPlacement="top-end"
          />
        </Col>
        <Col>
          <RFFFields.DatePicker
            wrapperClassName="flex-grow-1"
            label="End Date"
            placeholderText="Enter End Date"
            name="reminder.endDate"
            disabled={Boolean(isDisabled || initialValues?.reminder?.startDate || isNone)}
            excludeDateIntervals={excludedDateIntervals}
            filterDate={(date: any) =>
              values?.reminder?.startDate
                ? isAfter(date, parse(values.reminder.startDate, config.dateAndTimeFormats.date, new Date()))
                : true
            }
            isDateOnly
            shouldCloseOnSelect
            popperPlacement="top-end"
          />
        </Col>
      </Row>
    </>
  )
}

export default ReminderForm
