import { FC, useMemo, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { showErrorAlert } from '../alert'
import { AppContext } from '../../app-context'
import Badge from '../badge/badge'

import { useAddTrainingSessionMutation } from '../../services'
import { useProfile } from '../../security'

import { renderLocalDate, options } from '../../util'

import { ITraining } from '../../interfaces'
import { TrainingSessionType } from '../../interfaces/services/training-session-type'
import { Spinner } from '../spinner'

import s from './training-card.module.scss'

type TrainingCardProps = ITraining

const TrainingCard: FC<TrainingCardProps> = props => {
  const navigate = useNavigate()

  const { id, note, occurrenceDate, trainingTemplateName, myTrainingSessions } = props

  const { airportId, userId } = useProfile()

  const { dispatch } = useContext(AppContext)

  const statusName = useMemo(
    () =>
      options.trainingStatus.find(trainingStatus => trainingStatus.value === myTrainingSessions?.[0]?.statusId)?.label,
    [myTrainingSessions],
  )

  const myTrainingSessionId = useMemo(() => myTrainingSessions?.[0]?.id, [myTrainingSessions])

  const { mutateAsync: addTrainingSessionMutation, isLoading: isTrainingSessionLoading } =
    useAddTrainingSessionMutation({ airportId })

  const handleStartMyTraining = useCallback(async () => {
    if (isTrainingSessionLoading) {
      return
    }

    if (myTrainingSessionId) {
      navigate(`/trainings/my-trainings/update/${myTrainingSessionId}`)
      return
    }

    let trainingSession
    try {
      trainingSession = await addTrainingSessionMutation({
        id: 0,
        trainingId: id,
        attendeeUserIds: [userId],
        sessionType: TrainingSessionType.Individual,
      })
    } catch (error) {
      dispatch(showErrorAlert('Error while creating session'))
      console.warn('Error while creating session', error)
    }

    if (trainingSession) {
      navigate(`/trainings/my-trainings/update/${trainingSession.id}`)
    }
  }, [id, myTrainingSessionId, isTrainingSessionLoading, dispatch, addTrainingSessionMutation, navigate, userId])

  return (
    <div className={cn(s['card-container'], 'card card-custom border border-2 px-4 py-2')}>
      {isTrainingSessionLoading && (
        <div className={s['spinner-container']}>
          <Spinner />
        </div>
      )}

      <div className="card-header border-0 m-0 p-0">
        <div className="card-title font-weight-bold w-100">
          <div className="w-100 pl-2 card-title font-weight-bold" onClick={handleStartMyTraining}>
            {myTrainingSessionId ? `#${myTrainingSessionId} - ${trainingTemplateName}` : trainingTemplateName}
          </div>
        </div>
      </div>

      <div className="card-body p-0">
        <div className="text-truncate">
          Target Date: {occurrenceDate ? renderLocalDate(occurrenceDate, false, true) : '-'}
        </div>

        <div className="text-truncate">
          Started:{' '}
          {myTrainingSessions?.[0]?.trainingStartedAt
            ? renderLocalDate(myTrainingSessions[0].trainingStartedAt, false, true)
            : '-'}
        </div>

        <div className="text-truncate">
          Completed:{' '}
          {myTrainingSessions?.[0]?.trainingEndedAt
            ? renderLocalDate(myTrainingSessions[0].trainingEndedAt, false, true)
            : '-'}
        </div>

        {note && <div className="mt-2 text-truncate">{note}</div>}
      </div>

      <div className="border-0 card-footer d-flex justify-content-end mt-2 mx-0 p-0">
        {statusName && <Badge status={statusName} />}
      </div>
    </div>
  )
}

export default TrainingCard
