import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import config from '../../config'
import { IUpdateFn, ISavedSearchFilters, IUseUpdateMutation } from '../../interfaces'
import { ISavedSearchFiltersUpsert } from '../../interfaces/services/saved-search-filters-upsert'

const getRequest = (params: any) => {
  return {
    method: 'put',
    url: `${config.baseUrl}/SavedSearchFilters`,
  }
}

const useUpdateSavedSearchFiltersMutationBase: IUseUpdateMutation<ISavedSearchFilters, ISavedSearchFiltersUpsert> = (
  params,
  options,
) => {
  const request = useMemo(() => getRequest(params), [params])

  const addSavedSearchFilter: IUpdateFn<ISavedSearchFiltersUpsert, ISavedSearchFilters> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISavedSearchFilters, Error, any>({
    mutationFn: addSavedSearchFilter,
    ...options,
  })

  return response
}

const useUpdateSavedSearchFiltersMutation = (
  options?: UseMutationOptions<ISavedSearchFilters, Error, ISavedSearchFiltersUpsert>,
) => useUpdateSavedSearchFiltersMutationBase({}, options)

export default useUpdateSavedSearchFiltersMutation
