import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IAuthUserSelectList, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
}

const defaultQueryObject = {
  activeOnly: true,
}

const getAuthUsersSelectListRequest = (
  params: Params,
  queryObject: QueryObject = defaultQueryObject,
): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/AuthUsers/SelectList${queryString}`,
  }
}

export const useAuthUsersSelectListQuery = buildGetQuery<
  Params,
  QueryObject | undefined,
  IAuthUserSelectList[] | undefined
>({
  getRequest: (params, queryObject) => getAuthUsersSelectListRequest(params, queryObject),
  getQueryKey: (params, queryObject) => ['authUsersSelectList', params.airportId, queryObject?.activeOnly || ''],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useAuthUsersSelectListQuery
