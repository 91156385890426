import config from '../../config'

import { IGetPostRequest } from '../../interfaces'

const getAddTagMutationRequest: IGetPostRequest = params => {
  const { airportId } = params

  return { method: 'post', url: `${config.baseUrl}/${airportId}/Tags` }
}

export default getAddTagMutationRequest
