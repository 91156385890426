import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getReopenTrainingMutationRequest from './get-reopen-training-session-mutation-request'

import { IUseUpdateMutation, IUpdateFn, ITrainingSession } from '../../interfaces'

const useReopenTrainingMutation: IUseUpdateMutation<ITrainingSession, number | undefined> = (params, options = {}) => {
  const request = useMemo(() => getReopenTrainingMutationRequest(params), [params])

  const reopenTraining: IUpdateFn<number | undefined, ITrainingSession> = useCallback(
    async (id?: number) => {
      let computedRequest = request
      if (id) {
        computedRequest = getReopenTrainingMutationRequest({ ...params, id })
      }

      return axios(computedRequest).then(res => res.data)
    },
    [request, params],
  )

  const response = useMutation<ITrainingSession, Error, number | undefined>({
    mutationFn: reopenTraining,
    ...options,
  })

  return response
}

export default useReopenTrainingMutation
