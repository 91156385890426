import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'

import { useTagsQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { bySortOrderAscending } from '../../../../util'
import { validateArrayRequired } from '../../../../util/validation'

import { ITagsOnlyFormProps } from './tags-only-form-types'
import { ITag } from '../../../../interfaces'

const TagsOnlyForm: FC<ITagsOnlyFormProps> = props => {
  const { airportId } = useProfile()

  const { handleSubmit, tagCategory, submitting, formName } = props

  const { data: tags, isFetching } = useTagsQuery({ airportId }, { tagCategory })

  const sortedTags = useMemo(() => (tags || []).sort(bySortOrderAscending), [tags])

  const tagOptions = useMemo(
    () =>
      sortedTags.map((tag: ITag) => ({
        label: tag.name,
        value: tag.id,
      })),
    [sortedTags],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Select
        options={tagOptions}
        name="tagIds"
        label="Labels"
        disabled={isFetching || submitting}
        isMulti
        validate={validateArrayRequired}
      />
    </form>
  )
}

export default TagsOnlyForm
