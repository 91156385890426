import config from '../../config'
import { buildGetQuery } from '../../util'

import { ITraining, IGetRequestObject } from '../../interfaces'

const getMyTrainingsRequest = (_: undefined): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/Trainings/MyTrainings`,
})

const useMyTrainingsQuery = buildGetQuery<undefined, undefined, ITraining[] | undefined>({
  getRequest: getMyTrainingsRequest,
  getQueryKey: (_, queryObject) => ['my-trainings'],
})

export default useMyTrainingsQuery
