import config from '../../config'

import { IGetPostRequest } from '../../interfaces'

const getAddTrainingSessionMutationRequest: IGetPostRequest = params => {
  return {
    method: 'post',
    url: `${config.baseUrl}/TrainingSessions`,
  }
}

export default getAddTrainingSessionMutationRequest
