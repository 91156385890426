import axios from 'axios'

import config from '../../config'

const updateWorkOrder = async ({ airportId, request }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders` // TODO add this back when the API adds this as a segment/${workOrderId}`
    const response = await axios.put(url, request)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default updateWorkOrder
