import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getCompleteTrainingSessionMutationRequest from './get-complete-training-session-mutation-request'

import { IUseUpdateMutation, IUpdateFn, ITrainingSession } from '../../interfaces'

const useCompleteTrainingSessionMutation: IUseUpdateMutation<ITrainingSession, number | undefined> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getCompleteTrainingSessionMutationRequest(params), [params])

  const completeTrainingSession: IUpdateFn<number | undefined, ITrainingSession> = useCallback(
    async (id?: number) => {
      let computedRequest = request
      if (id) {
        computedRequest = getCompleteTrainingSessionMutationRequest({ ...params, id })
      }

      return axios(computedRequest).then(res => res.data)
    },
    [request, params],
  )

  const response = useMutation<ITrainingSession, Error, number | undefined>({
    mutationFn: completeTrainingSession,
    ...options,
  })

  return response
}

export default useCompleteTrainingSessionMutation
