import { FC } from 'react'

import PopUpRow from './pop-up-row'

import { IWildlifeSighting } from '../../interfaces'

type WildlifePopUpContentProps = {
  wildlifeSightings: IWildlifeSighting
}

const WildlifePopUpContent: FC<WildlifePopUpContentProps> = props => {
  const { wildlifeSightings } = props

  return (
    <>
      {wildlifeSightings.weatherOtherDescription ? (
        <PopUpRow
          label={`Weather ${wildlifeSightings.weatherName}:`}
          value={wildlifeSightings.weatherOtherDescription}
        />
      ) : (
        <PopUpRow label={`Weather`} value={wildlifeSightings.weatherName || ''} />
      )}

      {wildlifeSightings.wildlifeSpeciesOtherDescription ? (
        <PopUpRow
          label={`Species ${wildlifeSightings.wildlifeSpeciesName}`}
          value={wildlifeSightings.wildlifeSpeciesOtherDescription}
        />
      ) : (
        <PopUpRow label={`Species`} value={wildlifeSightings.wildlifeSpeciesName || ''} />
      )}

      {wildlifeSightings.wildlifeSpeciesCount && (
        <PopUpRow label={`Species Count`} value={wildlifeSightings.wildlifeSpeciesCount || ''} />
      )}

      <PopUpRow label={`Activity`} value={wildlifeSightings.wildlifeActivityName || ''} />

      {wildlifeSightings.wildlifeActivityOtherDescription && (
        <PopUpRow label={`Activity Description`} value={wildlifeSightings.wildlifeActivityOtherDescription || ''} />
      )}

      {wildlifeSightings.wildlifeMitigationOtherDescription ? (
        <PopUpRow
          label={`Action ${wildlifeSightings.wildlifeMitigationName}`}
          value={wildlifeSightings.wildlifeMitigationOtherDescription || ''}
        />
      ) : (
        <PopUpRow label={`Action`} value={wildlifeSightings.wildlifeMitigationName || ''} />
      )}
    </>
  )
}

export default WildlifePopUpContent
