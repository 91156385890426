import { useMemo } from 'react'
import qs from 'qs'
import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISafetyItemResponse, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  id: number
}

type QueryObject = {
  activeOnly?: boolean
}

const getWorkOrderSafetyItemResponsesRequest = (
  params: Params,
  queryObject: QueryObject | undefined,
): IGetRequestObject => {
  const { airportId, id } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/SafetyItemResponses/WorkOrder/${id}${queryString}`,
  }
}

const useWorkOrderSafetyItemResponsesQueryBase = buildGetQuery<
  Params,
  QueryObject | undefined,
  ISafetyItemResponse[] | undefined
>({
  getRequest: getWorkOrderSafetyItemResponsesRequest,
  getQueryKey: (_, queryObject) => ['work-order-safety-item-responses', queryObject?.activeOnly],
})

const useWorkOrderSafetyItemResponsesQuery = (
  params: Params,
  queryObject?: QueryObject,
  options?: QueryObserverOptions<ISafetyItemResponse[] | undefined, Error>,
) => {
  const { data, ...restQuery } = useWorkOrderSafetyItemResponsesQueryBase(params, queryObject, options)

  const defaultItem = useMemo(() => (data || []).find(d => d.isDefault), [data])

  return { ...restQuery, data, defaultItem }
}

export default useWorkOrderSafetyItemResponsesQuery
