import { FC } from 'react'
import { Modal } from 'react-bootstrap'

import AuditInfoForm from './audit-info-form'
import { Button } from '../button'

import { IAuditInfoFormValues, IAuditInfoUpsertFormValues } from '../form'

type UpdateAuditInfoModalProps = {
  onSave: (values: IAuditInfoFormValues) => void
  isOpen: boolean
  closeModal: () => void
  initialValues?: IAuditInfoUpsertFormValues
  isEnded?: boolean
  saveButtonText?: string
}

const UpdateAuditInfoModal: FC<UpdateAuditInfoModalProps> = props => {
  const { isOpen, closeModal, onSave, initialValues, isEnded, saveButtonText } = props

  return (
    <Modal animation={false} onHide={closeModal} show={isOpen} centered contentClassName="h-100">
      <Modal.Header closeButton={true}>
        <Modal.Title>Update Audit Info</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AuditInfoForm onSubmit={onSave} initialValues={initialValues} isEnded={isEnded} formName="audit-info" />
      </Modal.Body>

      <Modal.Footer>
        <Button type="secondary" buttonType="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button type="primary" buttonType="submit" form="audit-info">
          {saveButtonText || 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateAuditInfoModal
