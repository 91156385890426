import getUserFromStorage from '../security/get-user-from-storage'
import toInt from '../util/to-int'

const useProfile = () => {
  const user = getUserFromStorage()

  if (!user) {
    return {}
  }

  const profile = user.profile || {}
  profile.airportId = toInt(profile?.airportid, 0)
  profile.userId = profile.sub || ''

  return profile
}

export default useProfile
