import { FC, useMemo } from 'react'

import DiscrepancyFilterFormFields from './discrepancy-filter-fields'

import { IDiscrepancyFilterFormProps } from './discrepancy-filter-form-types'

const DiscrepancyFilterForm: FC<IDiscrepancyFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <DiscrepancyFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default DiscrepancyFilterForm
