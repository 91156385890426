import axios from 'axios'
import config from '../../config'

const updateCompleteLog = async ({ airportId, requestComplete, workOrderId }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders/${workOrderId}/Complete`
    const response = await axios.put(url, requestComplete)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default updateCompleteLog
