import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ITraining, IGetRequestObject } from '../../interfaces'

type Params = {
  id: number
}

type QueryObject = {
  includeDetail?: boolean
}

const getTrainingByIdQueryRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { id } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/Trainings/${id}${queryString}`,
  }
}

const useTrainingByIdQuery = buildGetQuery<Params, QueryObject | undefined, ITraining | undefined>({
  getRequest: getTrainingByIdQueryRequest,
  getQueryKey: (params, queryObject) => ['training-by-id', params.id, queryObject?.includeDetail || ''],
  defaultOptions: { initialData: undefined },
})

export default useTrainingByIdQuery
