import React from 'react'
import { string } from 'prop-types'

// Ensures all pages have the same padding/top level layout
const Page = ({ children, className = '' }) => {
  return <div className={`d-flex flex-column px-4 ${className}`}>{children}</div>
}

Page.propTypes = {
  className: string,
}
export default Page
