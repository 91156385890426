import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortSafetyItemSearchFilter } from '../../../../interfaces'
import { IDiscrepancyFilterValues, IDiscrepancyFilterUpsertValues } from './discrepancy-filter-form-types'

export const objectToDiscrepancyFilterValues = (object?: { [key: string]: any }): IDiscrepancyFilterUpsertValues => ({
  dateRange: { 0: object?.startedAtBegin || '', 1: object?.startedAtEnd || '' },
  inspectionCategoryIds: object?.inspectionCategoryIds || [],
  inspectorIds: object?.inspectorIds || [],
  inspectionTypeIds: object?.inspectionTypeIds || [],
  facilityIds: object?.facilityIds || [],
  conditionIds: object?.conditionIds || [],
  locationIds: object?.locationIds || [],
  searchText: object?.searchText || '',
  responseId: object?.responseId || undefined,
})

export const savedSearchFilterToSavedDiscrepancyFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): IDiscrepancyFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let discrepancyFilterObject
  try {
    discrepancyFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    discrepancyFilterObject = {}
  }

  const discrepancyFilterValues = objectToDiscrepancyFilterValues(discrepancyFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    discrepancyFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return discrepancyFilterValues
}

export const savedDiscrepancyFilterValuesToSavedSearchFilterAddUpsert = (
  savedDiscrepancyFilterValues: IDiscrepancyFilterValues | undefined,
): IShortSafetyItemSearchFilter => ({
  startedAtBegin: savedDiscrepancyFilterValues?.dateRange?.[0] || '',
  startedAtEnd: savedDiscrepancyFilterValues?.dateRange?.[1] || '',
  inspectionCategoryIds: savedDiscrepancyFilterValues?.inspectionCategoryIds || [],
  inspectorIds: savedDiscrepancyFilterValues?.inspectorIds || [],
  inspectionTypeIds: savedDiscrepancyFilterValues?.inspectionTypeIds || [],
  facilityIds: savedDiscrepancyFilterValues?.facilityIds || [],
  conditionIds: savedDiscrepancyFilterValues?.conditionIds || [],
  locationIds: savedDiscrepancyFilterValues?.locationIds || [],
  searchText: savedDiscrepancyFilterValues?.searchText || '',
  responseId: savedDiscrepancyFilterValues?.responseId || undefined,
})

export const savedDiscrepancyFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedDiscrepancyFilterValues: IDiscrepancyFilterValues | undefined,
): IShortSafetyItemSearchFilter => ({
  startedAtBegin: savedDiscrepancyFilterValues?.dateRange?.[0] || '',
  startedAtEnd: savedDiscrepancyFilterValues?.dateRange?.[1] || '',
  inspectionCategoryIds: savedDiscrepancyFilterValues?.inspectionCategoryIds || [],
  inspectorIds: savedDiscrepancyFilterValues?.inspectorIds || [],
  inspectionTypeIds: savedDiscrepancyFilterValues?.inspectionTypeIds || [],
  facilityIds: savedDiscrepancyFilterValues?.facilityIds || [],
  conditionIds: savedDiscrepancyFilterValues?.conditionIds || [],
  locationIds: savedDiscrepancyFilterValues?.locationIds || [],
  searchText: savedDiscrepancyFilterValues?.searchText || '',
  responseId: savedDiscrepancyFilterValues?.responseId || undefined,
})
