import { FC, ReactNode } from 'react'
import cn from 'classnames'

import InfoCardRow from './info-card-row'

import { IInfoCardRow } from './info-card-types'

import s from './info-card.module.scss'

type InfoCardProps = {
  title?: string
  options: IInfoCardRow[]
  children?: ReactNode
}

const InfoCard: FC<InfoCardProps> = props => {
  const { title, options, children } = props

  if (!options) {
    return null
  }

  return (
    <div className={cn(s['info-container'], 'mb-4')}>
      {title && <h5>{title}</h5>}

      {options.map((option, i) => (
        <InfoCardRow {...option} key={option.value + option.title + i} />
      ))}

      {children}
    </div>
  )
}

export default InfoCard
