import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateSortInspectionTemplateMasterMutationRequest from './get-update-sort-inspection-template-master-mutation-request'

import { IUseUpdateMutation, IUpdateFn, IInspectionTemplateMasterItem } from '../../interfaces'

const useUpdateSortInspectionTypeMasterMutation: IUseUpdateMutation<IInspectionTemplateMasterItem[], number[]> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateSortInspectionTemplateMasterMutationRequest(params), [params])

  const updateSortInspectionTypeMaster: IUpdateFn<number[], IInspectionTemplateMasterItem[]> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IInspectionTemplateMasterItem[], Error, number[]>({
    mutationFn: updateSortInspectionTypeMaster,
    ...options,
  })

  return response
}

export default useUpdateSortInspectionTypeMasterMutation
