export const formatDateString = (dateString, preferences) => {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)

  if (!preferences) {
    return renderLocalDate(date)
  }

  if (preferences.useUtc) {
    return renderUtcDate(date, preferences.use24HourClock)
  }

  return renderLocalDate(date, preferences.use24HourClock)
}

export const normalizeDate = date => {
  // Allow for a date string to be passed in; convert it to a Javascript Date
  if (typeof date === 'string') {
    return new Date(date)
  }

  return date
}

export const renderLocalDate = (date, use24HourClock = false, isTimeHidden = false) => {
  if (!date) {
    return ''
  }

  const d = normalizeDate(date)

  const formattedTime = d.toLocaleTimeString(undefined, {
    hour12: !use24HourClock,
  })

  return `${d.toLocaleDateString()}${!isTimeHidden ? ' ' + formattedTime : ''}`
}

export const renderUtcDate = (date, use24HourClock = false) => {
  if (!date) {
    return ''
  }

  const d = normalizeDate(date)

  const formattedDate = d.toLocaleDateString(undefined, {
    timeZone: 'UTC',
  })

  const formattedTime = d.toLocaleTimeString(undefined, {
    timeZone: 'UTC',
    hour12: !use24HourClock,
  })

  return `${formattedDate} ${formattedTime}`
}
