const claims = {
  admin: {
    read: 'admin-read',
    write: 'admin-write',
  },
  inspection: {
    read: 'inspection-read',
    write: 'inspection-write',
  },
  journal: {
    read: 'journal-read',
    write: 'journal-write',
  },
  report: {
    read: 'report-read',
  },
  workorder: {
    read: 'workorder-read',
    write: 'workorder-write',
  },
  safetyConcern: {
    read: 'safetyconcern-read',
    write: 'safetyconcern-write',
  },
  hero: {
    read: 'hero-read',
    write: 'hero-write',
  },
}

export default claims
