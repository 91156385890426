import { FC } from 'react'
import {} from '@tanstack/react-table'

import { PaginationButtons } from '../pagination-buttons'
import { PaginationPageSizeSelector } from '../pagination-page-size-selector'

type PaginationProps = {
  pageIndex: number
  setPageIndex: any
  pageSize: number
  setPageSize: any
  canPreviousPage: boolean
  canNextPage: boolean
  totalItemsCount: number
  totalPageCount: number
}

const Pagination: FC<PaginationProps> = props => {
  const {
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    canPreviousPage,
    canNextPage,
    totalPageCount,
    totalItemsCount,
  } = props

  return (
    <div className="my-3 px-1 d-flex justify-content-between align-items-center w-100">
      <PaginationButtons
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        totalPageCount={totalPageCount}
      />

      <PaginationPageSizeSelector
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItemsCount={totalItemsCount}
      />
    </div>
  )
}

export default Pagination
