import React from 'react'

import RFFFields from '../../react-final-form-fields'
import Tags from '../../../../features/journal/tags'

import { validateRequired } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'

import { IFaqFormFiledsProps, IFaqValues } from './faq-form-types'
import { TagCategory } from '../../../../interfaces'

const nameof = nameofFactory<IFaqValues>()

const questionLabel = 'Question'
const answerLabel = 'Answer'
const urlLabel = 'URL'

const FaqFormFields: React.FC<IFaqFormFiledsProps> = props => {
  const { disabled, addSelectedTagIds } = props

  return (
    <>
      <RFFFields.Textarea
        name={nameof('question')}
        placeholder={getPlaceholderTextFromLabel(questionLabel)}
        label={questionLabel}
        validate={validateRequired}
        disabled={disabled}
        minRows={2}
      />

      <RFFFields.Textarea
        name={nameof('answer')}
        placeholder={getPlaceholderTextFromLabel(answerLabel)}
        label={answerLabel}
        validate={validateRequired}
        disabled={disabled}
        minRows={5}
      />

      {addSelectedTagIds && <Tags addSelectedTagIds={addSelectedTagIds} tagCategory={TagCategory.FAQ} />}

      <RFFFields.Input
        name={nameof('url')}
        placeholder={getPlaceholderTextFromLabel(urlLabel)}
        label={urlLabel}
        disabled={disabled}
      />
    </>
  )
}

export default FaqFormFields
