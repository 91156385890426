import { FC, useMemo } from 'react'

import WorkOrderFilterFormFields from './work-order-filter-fields'

import { IWorkOrderFilterFormProps } from './work-order-filter-form-types'

const WorkOrderFilterForm: FC<IWorkOrderFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <WorkOrderFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default WorkOrderFilterForm
