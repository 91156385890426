import React, { useMemo } from 'react'
import { shape, bool, func, arrayOf, string } from 'prop-types'
import cn from 'classnames'

import CheckboxTreeNode from './checkbox-tree-node'
import { SearchInput } from '../../search-input'

import useCheckboxTree from './use-checkbox-tree'
import { getValueByNode, defaultFilterNodes } from './checkbox-tree.utils'

import s from './checkbox-tree.module.scss'

const CheckboxTree = props => {
  const { onChange, canFilter, filterNodes, renderFilter, value, nodes, className, renderLabel } = props

  const safeValue = useMemo(() => (Array.isArray(value) ? value : []), [value])

  const { handleChange, filter, setFilter, expandedValues, toggleTreeNodeExpand } = useCheckboxTree({
    onChange,
    value: safeValue,
    nodes,
  })

  const computedNodes = canFilter
    ? filterNodes
      ? filterNodes(nodes, filter)
      : defaultFilterNodes(nodes, filter)
    : nodes

  return (
    <>
      {canFilter && (
        <>
          {renderFilter ? (
            renderFilter({ filter, setFilter })
          ) : (
            <SearchInput
              value={filter?.globalSearch}
              onChange={globalSearch => setFilter({ ...filter, globalSearch })}
              wrapperClassName="mb-5"
            />
          )}
        </>
      )}

      <div className={cn(s['checkbox-tree-container'], className)}>
        {computedNodes.length > 0 ? (
          computedNodes.map((node, index) => (
            <CheckboxTreeNode
              node={node}
              handleChange={handleChange}
              key={index}
              value={getValueByNode(safeValue, node)}
              expandedValues={expandedValues}
              toggleTreeNodeExpand={toggleTreeNodeExpand}
              renderLabel={renderLabel}
            />
          ))
        ) : (
          <div className={s['no-nodes-preview']}>
            No Options Found
            <i className="bi bi-box"></i>
          </div>
        )}
      </div>
    </>
  )
}

const NodePropType = {
  label: string.isRequired,
  isOpen: bool,
  checked: bool,
}

NodePropType.children = arrayOf(shape(NodePropType))

CheckboxTree.propTypes = {
  className: string,
  onChange: func,
  nodes: arrayOf(shape(NodePropType)),
  canFilter: bool,
  renderFilter: func,
  renderLabel: func,
}

export default CheckboxTree
