import React from 'react'
import cn from 'classnames'

import s from './drawer.module.scss'

type DrawerProps = {
  isOpen: boolean
  onClose: () => void
  title: any
  body: React.ReactNode
}

const Drawer: React.FC<DrawerProps> = props => {
  const { isOpen, onClose, title, body } = props

  const drawerClassnames = cn(s['drawer'], {
    [s['drawer-open']]: isOpen,
  })

  const drawerMaskClassnames = cn(s['drawer-mask'], {
    [s['drawer-mask-open']]: isOpen,
  })

  const drawerContainerWrapperClassnames = cn(s['drawer-container-wrapper'], {
    [s['drawer-container-wrapper-open']]: isOpen,
  })

  return (
    <div className={drawerClassnames} tabIndex={-1}>
      <div className={drawerMaskClassnames} onClick={onClose} />
      <div className={drawerContainerWrapperClassnames}>
        <div className={s['drawer-header']}>{title}</div>
        {isOpen && <div className={s['drawer-body']}>{body}</div>}
      </div>
    </div>
  )
}

export default Drawer
