import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { pageWasReloaded } from '../util'

const useWasPageReloaded = () => {
  const { pathname } = useLocation()

  return useMemo(() => pageWasReloaded(pathname), [pathname])
}

export default useWasPageReloaded
