import { useState, useEffect } from 'react'
import { Row } from 'react-table'

const useTableReorder = (_data: any[]) => {
  const [data, setData] = useState(_data)
  useEffect(() => {
    setData(_data)
  }, [_data])

  const moveRow = (dragRow: Row, dropRow: Row) => {
    // @ts-ignore
    const dropRowIndex = data.findIndex(d => d.id === dropRow.original.id)

    // @ts-ignore
    const calculatedData = data.filter(d => d.id !== dragRow.original.id)
    calculatedData.splice(dropRowIndex, 0, dragRow.original)

    setData(calculatedData)
  }

  const setRowPosition = (row: Row, position: number) => {
    // @ts-ignore
    const calculatedData = data.filter(d => d.id !== row.original.id)
    calculatedData.splice(position, 0, row.original)

    setData(calculatedData)
  }

  const setRowToEnd = (row: Row) => {
    // @ts-ignore
    const calculatedData = data.filter(d => d.id !== row.original.id)
    calculatedData.splice(data.length, 0, row.original)

    setData(calculatedData)
  }

  const setRowToBegin = (row: Row) => {
    // @ts-ignore
    const calculatedData = data.filter(d => d.id !== row.original.id)
    calculatedData.splice(0, 0, row.original)

    setData(calculatedData)
  }

  return { data, moveRow, setRowToEnd, setRowToBegin, setRowPosition }
}

export default useTableReorder
