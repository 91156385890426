import getWorkOrderRemindersRequest from './get-work-order-reminders-request'
import useAxios from '../use-axios'

const useWorkOrderReminders = (params, queryObject, axiosOptions = {}) => {
  const request = getWorkOrderRemindersRequest(params, queryObject)

  const { data, error, loading, refetch } = useAxios(request, { defaultValue: [], ...axiosOptions })

  return { data, error, loading, execute: refetch }
}

export default useWorkOrderReminders
