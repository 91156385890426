import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'

import getAddDiscrepancyMutationRequest, {
  IGetAddDiscrepancyMutationRequestParams,
} from './get-add-discrepancy-mutation-request'

import { IAddFn } from '../../interfaces'
import { ISafetyItem, IDiscrepancyCreateUpsert } from '../../interfaces'

interface IUseAddDiscrepancyMutation {
  (
    params: IGetAddDiscrepancyMutationRequestParams,
    options?: UseMutationOptions<ISafetyItem, Error, IDiscrepancyCreateUpsert>,
  ): UseMutationResult<ISafetyItem, Error, IDiscrepancyCreateUpsert>
}

const useAddDiscrepancyMutation: IUseAddDiscrepancyMutation = (params, options = {}) => {
  const request = useMemo(() => getAddDiscrepancyMutationRequest(params), [params])

  const addDiscrepancy: IAddFn<IDiscrepancyCreateUpsert, ISafetyItem> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyItem, Error, any>({
    mutationFn: addDiscrepancy,
    ...options,
  })

  return response
}

export default useAddDiscrepancyMutation
