import React from 'react'
import cn from 'classnames'

import './page-title.css'

type PageTitleProps = {
  title: string
  subTitle?: string
  className?: string
}

const PageTitle: React.FC<PageTitleProps> = props => {
  const { title, subTitle = '', className = '' } = props

  return (
    <h3 className={cn('font-weight-bold my-3 page-title text-dark align-self-center', className)}>
      {title}
      {subTitle && <small className="text-muted"> - {subTitle}</small>}
    </h3>
  )
}

PageTitle.defaultProps = {
  subTitle: '',
  className: '',
}

export default PageTitle
