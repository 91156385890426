import { useMemo } from 'react'

import { Drawer } from '../../../components/drawer'
import FaqDrawerHeader from './faq-drawer-header'
import FaqDrawerBody from './faq-drawer-body'

import { useAppContext } from '../../../app-context'
import { actions } from '../../../actions'
import { useCallback } from 'react'

const FaqDrawer = () => {
  const { state, dispatch } = useAppContext()

  const closeDrawer = useCallback(() => dispatch(actions.faqDrawer.closeFaqDrawer()), [dispatch])

  const releaseNotesInfo = state?.releaseNotesInfo

  const showRedBubble = useMemo(() => {
    return releaseNotesInfo?.isReminderOpen === true
  }, [releaseNotesInfo?.isReminderOpen])

  return (
    <Drawer
      isOpen={state.isFaqDrawerOpen}
      onClose={closeDrawer}
      title={<FaqDrawerHeader onClose={closeDrawer} />}
      body={<FaqDrawerBody onClose={closeDrawer} showRedBubble={showRedBubble} />}
    />
  )
}

export default FaqDrawer
