import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getInspectionByIdQueryRequest from './get-inspection-by-id-query-request'
import enrichInspection from './enrich-inspection'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IInspection } from '../../interfaces'

const useInspectionByIdQuery: IUseGetQuery<any, IInspection> = (params, queryObject = {}, options = {}) => {
  const request = useMemo(() => getInspectionByIdQueryRequest(params, queryObject), [params, queryObject])

  const fetchInspectionById: IGetFn<IInspection> = useCallback(
    () => axios(request).then(res => enrichInspection(res.data)),
    [request],
  )

  const queryKey = useMemo(() => ['inspection', params.id, params.airportId, queryObject], [params, queryObject])

  const response = useQuery<IInspection | undefined, Error>(queryKey, fetchInspectionById, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useInspectionByIdQuery
