import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getCompleteTrainingSessionMutationRequest: IGetPutRequest = params => {
  const { id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/TrainingSessions/${id}/Complete`,
  }
}

export default getCompleteTrainingSessionMutationRequest
