import Spinner from '../spinner/spinner'

import { IListProps } from './list-types'

import s from './list.module.scss'

export const List = <T extends { id: number }>(props: IListProps<T>) => {
  const {
    data = [],
    ListItem,
    isLoading,
    isInitialLoading,
    activeId,
    setActiveId,
    activeIds,
    setActiveIds,
    actionsButtonOptions,
    noDataContent = null,
    disabled,
    renderActions,
    onTitleClick,
    refs,
  } = props

  if (isInitialLoading) {
    return <Spinner />
  }

  if (!Array.isArray(data) || data.length === 0) {
    return noDataContent
  }

  return (
    <div className={`${s['container']} d-flex flex-column h-100 overflow-y-auto pr-2`}>
      {isLoading && (
        <div className={s['loader']}>
          <Spinner />
        </div>
      )}

      {data.map((dataItem, i) => (
        <ListItem
          key={dataItem.id || i}
          {...dataItem}
          componentRef={refs?.[dataItem?.id] || undefined}
          activeId={activeId}
          setActiveId={setActiveId}
          activeIds={activeIds}
          setActiveIds={setActiveIds}
          actionsButtonOptions={actionsButtonOptions}
          renderActions={renderActions}
          isLastListItem={i === data.length - 1}
          onTitleClick={onTitleClick}
          disabled={disabled}
        />
      ))}
    </div>
  )
}
