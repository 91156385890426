import { FC, useMemo } from 'react'
import RFFFields from '../../react-final-form-fields'
import { Row, Col } from 'react-bootstrap'

import {
  useWorkOrderTypesQuery,
  useFacilitiesByWorkOrderTypeQuery,
  useSafetyItemUniqueValuesByWorkOrderTypeQuery,
  usePrioritiesQuery,
  useWorkOrderStatusesQuery,
  useNotificationGroupsQuery,
  EntityState,
  useWorkOrderSafetyItemResponsesQuery,
} from '../../../../services'
import { useProfile } from '../../../../security'
import { validateRequired, validateSoftRequired } from '../../../../util/validation'

import { toOptions, getPlaceholderTextFromLabel } from '../../../../util'

import { WorkOrderFormFieldsProps } from './work-order-form-types'

const workOrderTypeLabel = 'Work Order Type'
const facilityLabel = 'Facility'
const conditionLabel = 'Condition'
const uniqueValuesLabel = 'Unique Value'
const priorityLabel = 'Priority'
const statusLabel = 'Status'
const descriptionLabel = 'Note'
const notificationGroupLabel = 'Department'

const WorkOrderFormFields: FC<WorkOrderFormFieldsProps> = props => {
  const { submitting, values, form } = props

  const { airportId } = useProfile()

  const { data: workOrderTypes, isFetching: isWorkOrderTypesLoading } = useWorkOrderTypesQuery(
    { airportId },
    { activeOnly: true },
  )

  const workOrderTypesOptions = useMemo(() => toOptions(workOrderTypes), [workOrderTypes])

  const { data: facilities, isFetching: isFacilitiesLoading } = useFacilitiesByWorkOrderTypeQuery(
    { airportId },
    { activeOnly: true, workOrderTypeId: values.workOrderTypeId },
    { enabled: values.workOrderTypeId !== undefined && values.workOrderTypeId !== null },
  )

  const facilitiesOptions = useMemo(() => toOptions(facilities), [facilities])

  const conditionsOptions = useMemo(() => {
    if (!values?.safetyItem?.facilityId) {
      return []
    }

    const facility = (facilities || []).find(facility => facility.id === Number(values?.safetyItem?.facilityId))

    if (!facility || !facility.conditions) {
      return []
    }

    const sortedConditions = toOptions(facility.conditions.filter(condition => condition.isActive))

    return sortedConditions
  }, [values?.safetyItem?.facilityId, facilities])

  const { data: uniqueValues, isFetching: isUniqueValuesLoading } = useSafetyItemUniqueValuesByWorkOrderTypeQuery(
    { airportId, workOrderTypeId: Number(values.workOrderTypeId) },
    { enabled: values.workOrderTypeId !== undefined && values.workOrderTypeId !== null },
  )

  const uniqueValuesOptions = useMemo(() => toOptions(uniqueValues), [uniqueValues])

  const { data: responses, isFetching: isResponsesLoading } = useWorkOrderSafetyItemResponsesQuery(
    { airportId, id: 0 }, // TODO, figure out why id === 0
    { activeOnly: true },
  )

  const responsesOptions = useMemo(
    () => toOptions(responses, { getLabel: response => response.description }),
    [responses],
  )

  const {
    data: priorities,
    isFetching: isPrioritiesLoading,
    defaultItem: defaultPriority,
  } = usePrioritiesQuery({ airportId }, { activeOnly: true }, { refetchOnWindowFocus: false })

  const prioritiesOptions = useMemo(() => toOptions(priorities), [priorities])

  const {
    data: statuses,
    isFetching: isStatusesLoading,
    defaultItem: defaultStatus,
  } = useWorkOrderStatusesQuery(
    { airportId },
    { activeOnly: true, entityState: EntityState.Open },
    { refetchOnWindowFocus: false },
  )

  const statusesOptions = useMemo(() => toOptions(statuses), [statuses])

  const { data: notificationGroups, isFetching: isNotificationGropsLoading } = useNotificationGroupsQuery(
    { airportId },
    { includeDetail: true, activeOnly: true },
  )

  const notificationGroupsOptions = useMemo(() => toOptions(notificationGroups), [notificationGroups])

  const isDisabledExeptWorkOrderType = useMemo(
    () => submitting || isFacilitiesLoading || values.workOrderTypeId === undefined,
    [submitting, isFacilitiesLoading, values.workOrderTypeId],
  )

  const isUniqueValueControlPresent = useMemo(
    () => values.workOrderTypeId !== undefined && uniqueValues.length > 1,
    [values.workOrderTypeId, uniqueValues],
  )

  const uniqueValuesControl = useMemo(() => {
    if (!isUniqueValueControlPresent) {
      return null
    }

    return (
      <RFFFields.Select
        name="safetyItem.safetyItemUniqueValueId"
        label={uniqueValuesLabel}
        placeholder={getPlaceholderTextFromLabel(uniqueValuesLabel)}
        options={uniqueValuesOptions}
        disabled={isUniqueValuesLoading || isDisabledExeptWorkOrderType}
        validate={validateSoftRequired}
      />
    )
  }, [isUniqueValueControlPresent, uniqueValuesOptions, isDisabledExeptWorkOrderType, isUniqueValuesLoading])

  const responseLabel = useMemo(() => {
    if (!isUniqueValueControlPresent) {
      return 'Response'
    }

    if (uniqueValuesOptions.length === 1) {
      return String(uniqueValuesOptions[0].label)
    }

    return String(
      (uniqueValuesOptions || []).find(uniqueValue => uniqueValue.value === values?.safetyItem?.safetyItemUniqueValueId)
        ?.label || 'Response',
    )
  }, [isUniqueValueControlPresent, uniqueValuesOptions, values?.safetyItem?.safetyItemUniqueValueId])

  return (
    <>
      <RFFFields.Select
        name="workOrderTypeId"
        label={workOrderTypeLabel}
        onChange={form?.mutators?.onWorkOrderTypeChange}
        placeholder={getPlaceholderTextFromLabel(workOrderTypeLabel)}
        options={workOrderTypesOptions}
        disabled={isWorkOrderTypesLoading || submitting}
        validate={validateRequired}
      />

      <Row>
        <Col>
          <RFFFields.Select
            name="safetyItem.facilityId"
            label={facilityLabel}
            onChange={form?.mutators?.onFacilityChange}
            placeholder={getPlaceholderTextFromLabel(facilityLabel)}
            options={facilitiesOptions}
            disabled={isFacilitiesLoading || isDisabledExeptWorkOrderType}
            validate={validateRequired}
          />
        </Col>
        <Col>
          <RFFFields.Select
            name="safetyItem.conditionId"
            label={conditionLabel}
            placeholder={getPlaceholderTextFromLabel(conditionLabel)}
            options={conditionsOptions}
            disabled={isFacilitiesLoading || conditionsOptions.length === 0 || isDisabledExeptWorkOrderType}
            validate={validateRequired}
          />
        </Col>
      </Row>

      {uniqueValuesControl}

      <RFFFields.ButtonGroup
        name="safetyItem.safetyItemResponseId"
        label={responseLabel}
        placeholder={getPlaceholderTextFromLabel(responseLabel)}
        options={responsesOptions}
        disabled={
          isResponsesLoading ||
          isUniqueValuesLoading ||
          (isUniqueValueControlPresent && values?.safetyItem?.safetyItemUniqueValueId === undefined) ||
          isDisabledExeptWorkOrderType
        }
        validate={validateRequired}
      />

      <Row>
        <Col>
          <RFFFields.Select
            initialValue={defaultPriority?.id}
            name="priorityId"
            label={priorityLabel}
            placeholder={getPlaceholderTextFromLabel(priorityLabel)}
            options={prioritiesOptions}
            disabled={isPrioritiesLoading || isDisabledExeptWorkOrderType}
            validate={validateRequired}
          />
        </Col>

        <Col>
          <RFFFields.Select
            initialValue={defaultStatus?.id}
            name="parentStatusId"
            label={statusLabel}
            placeholder={getPlaceholderTextFromLabel(statusLabel)}
            options={statusesOptions}
            disabled={isStatusesLoading || isDisabledExeptWorkOrderType}
            validate={validateRequired}
          />
        </Col>
      </Row>

      <RFFFields.Textarea
        name="safetyItem.note"
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        disabled={isDisabledExeptWorkOrderType}
      />

      <RFFFields.Select
        name="notificationGroupId"
        label={notificationGroupLabel}
        placeholder={getPlaceholderTextFromLabel(notificationGroupLabel)}
        options={notificationGroupsOptions}
        disabled={isNotificationGropsLoading || isDisabledExeptWorkOrderType}
      />
    </>
  )
}

export default WorkOrderFormFields
