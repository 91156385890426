import qs from 'qs'
import { useMemo } from 'react'

import config from '../../config'
import { buildGetQuery } from '../../util'
import { QueryObserverOptions } from '@tanstack/react-query'

import { ISafetyConcernStatus, IGetRequestObject } from '../../interfaces'

type QueryObject = {
  baseStatus?: string
  entityState?: string
  activeOnly?: boolean
}

const getSafetyConcernStatusesRequest = (
  params: undefined,
  queryObject: QueryObject | undefined,
): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/SafetyConcernStatuses${queryString}`,
  }
}

const useSafetyConcernStatusesQueryBase = buildGetQuery<
  undefined,
  QueryObject | undefined,
  ISafetyConcernStatus[] | undefined
>({
  getRequest: getSafetyConcernStatusesRequest,
  getQueryKey: (params, queryObject) => [
    'safety-concern-statuses',
    queryObject?.baseStatus,
    queryObject?.entityState,
    queryObject?.activeOnly,
  ],
})

const useSafetyConcernStatusesQuery = (
  queryObject?: QueryObject,
  options?: QueryObserverOptions<ISafetyConcernStatus[] | undefined, Error>,
) => {
  const safetyConcernStatusesQuery = useSafetyConcernStatusesQueryBase(undefined, queryObject, options)

  const defaultItem = useMemo(
    () => (safetyConcernStatusesQuery?.data || []).find(safetyConcern => safetyConcern.isDefault),
    [safetyConcernStatusesQuery?.data],
  )

  return { ...safetyConcernStatusesQuery, defaultItem }
}
export default useSafetyConcernStatusesQuery
