import withFieldDecorator from './adapters/with-field-decorator'
import withAdditionalProps from '../../hoc/with-additional-props'

import { Attributes } from './attributes'
import { ButtonGroup } from './button-group'
import { Checkbox } from './checkbox'
import { CheckboxGroup } from './checkbox-group'
import { DatePicker } from './date-picker'
import { DocumentUpload } from './document-upload'
import { DateRangePicker } from './date-range-picker'
import { DualList } from './dual-list'
import { Input } from './input'
import { MaskedInput } from './masked-input'
import { SearchInput } from '../search-input'
import { Select } from './select'
import { Switch } from './switch'
import { Radio } from './radio'
import { RadioGroup } from './radio-group'
import { ColorPicker } from './color-picker'
import { Textarea } from './textarea'
import { CheckboxTree } from './checkbox-tree'

const Fields = {
  Attributes: withFieldDecorator(Attributes),
  ButtonGroup: withFieldDecorator(ButtonGroup),
  Input: withFieldDecorator(Input),
  DualList: withFieldDecorator(DualList),
  DocumentUpload: withFieldDecorator(DocumentUpload),
  MaskedInput: withFieldDecorator(MaskedInput),
  SearchInput: withFieldDecorator(SearchInput),
  Select: withFieldDecorator(Select),
  Switch: withFieldDecorator(Switch),
  DatePicker: withFieldDecorator(DatePicker),
  DateRangePicker: withFieldDecorator(DateRangePicker),
  Checkbox: withFieldDecorator(Checkbox),
  CheckboxGroup: withFieldDecorator(CheckboxGroup),
  Radio: withFieldDecorator(Radio),
  RadioGroup: withFieldDecorator(withAdditionalProps(RadioGroup, { Radio })),
  ColorPicker: withFieldDecorator(ColorPicker),
  Textarea: withFieldDecorator(Textarea),
  CheckboxTree: withFieldDecorator(CheckboxTree),
}

export default Fields
