import { useCallback, useEffect } from 'react'
import { toDate } from 'date-fns-tz'

import { Drawer, showErrorAlert, showSuccessAlert } from '../../../components'
import NotificationsDrawerHeader from './notification-drawer-header'
import NotificationsDrawerBody from './notification-drawer-body'

import { useAppContext } from '../../../app-context'
import { useProfile } from '../../../security'
import { deleteNotification, deleteNotifications, useNotificationsQuery } from '../../../services/notifications'

import { actions } from '../../../actions'
import config from '../../../config'

const NotificationsDrawer = () => {
  const { state, dispatch } = useAppContext()

  const timeZoneIanaId = state.airport.timeZoneIanaId

  const { airportId, userId } = useProfile()

  const notifications = useNotificationsQuery({ airportId }, { userId })

  const clickNotification = useCallback(() => {}, [])

  const removeNotification = async (id: number) => {
    try {
      await deleteNotification({ airportId, id, userId })

      notifications.refetch()

      dispatch(showSuccessAlert('Notification dismissed'))
    } catch (error: any) {
      console.error(error.message)
      dispatch(showErrorAlert('Error dismissing notification'))
    }
  }

  const dismissAll = async () => {
    try {
      await deleteNotifications({ airportId, userId })

      notifications.refetch()

      dispatch(showSuccessAlert('All notifications dismissed'))
    } catch (error: any) {
      console.error(error.message)
      dispatch(showErrorAlert('Error dismissing all notifications'))
    }
  }

  useEffect(() => {
    const interval = setInterval(notifications.refetch, config.notifications.pollingIntervalInMilliseconds)

    return () => clearInterval(interval)
  }, [notifications.refetch])

  const closeDrawer = useCallback(() => dispatch(actions.notificationsDrawer.closeNotificationsDrawer()), [dispatch])

  const notificationCount = notifications.data?.length || 0

  return (
    <Drawer
      isOpen={state.isNotificationsDrawerOpen}
      onClose={closeDrawer}
      title={
        <NotificationsDrawerHeader
          dismissAll={dismissAll}
          onClose={closeDrawer}
          notificationCount={notificationCount}
        />
      }
      body={
        <NotificationsDrawerBody
          clickNotification={clickNotification}
          currentDate={toDate(new Date(), { timeZone: timeZoneIanaId })}
          onClose={closeDrawer}
          removeNotification={removeNotification}
          notifications={notifications.data || []}
          timeZoneIanaId={timeZoneIanaId}
        />
      }
    />
  )
}

export default NotificationsDrawer
