import qs from 'qs'
import { useMemo } from 'react'

import config from '../../config'
import { buildGetQuery, bySortOrderAscending } from '../../util'
import { QueryObserverOptions } from '@tanstack/react-query'

import { IPriority, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly: boolean
}

const getPrioritiesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Priorities${queryString}`,
  }
}

const usePrioritiesQueryBase = buildGetQuery<Params, QueryObject | undefined, IPriority[] | undefined>({
  getRequest: getPrioritiesRequest,
  getQueryKey: params => ['priorities', params?.airportId || ''],
})

const usePrioritiesQuery = (
  params: Params,
  queryObject?: QueryObject,
  options?: QueryObserverOptions<IPriority[] | undefined, Error>,
) => {
  const { data, ...restQuery } = usePrioritiesQueryBase(params, queryObject, options)

  const defaultItem = useMemo(() => (data || []).find(d => d.isDefault), [data])

  const sortedData = useMemo(() => (data || []).sort(bySortOrderAscending), [data])

  return { ...restQuery, defaultItem, data: sortedData }
}
export default usePrioritiesQuery
