import config from '../../config'
import axios from 'axios'

const reopenWorkOrder = async ({ airportId, id }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders/${id}/Reopen`
    const response = await axios.put(url)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default reopenWorkOrder
