import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPutRequest,
  IUseUpdateMutation,
  IUpdateFn,
  ISafetyConcern,
  ISafetyConcernUpdateUpsert,
} from '../../interfaces'

const getUpdateSafetyConcernMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/SafetyConcerns`,
})

const useUpdateSafetyConcernMutation: IUseUpdateMutation<ISafetyConcern, ISafetyConcernUpdateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateSafetyConcernMutationRequest(params), [params])

  const updateSafetyConcern: IUpdateFn<ISafetyConcernUpdateUpsert, ISafetyConcern> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyConcern, Error, ISafetyConcernUpdateUpsert>({
    mutationFn: updateSafetyConcern,
    ...options,
  })

  return response
}

export default useUpdateSafetyConcernMutation
