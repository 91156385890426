import { FC } from 'react'

import SafetyConcernPopUpContent from '../safety-concern-pop-up-content'

import { ISafetyConcern } from '../../../interfaces'

type SafetyConcernPopupProps = {
  safetyConcern?: ISafetyConcern
  onNavigateToSafetyConcern?: (safetyConcern?: ISafetyConcern) => void
}

const SafetyConcernPopup: FC<SafetyConcernPopupProps> = props => {
  const { safetyConcern, onNavigateToSafetyConcern } = props

  if (!safetyConcern) {
    return null
  }

  return (
    <>
      <div className="border-bottom text-center w-100 mb-2">
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => onNavigateToSafetyConcern?.(safetyConcern)}
        >
          <h5>Safety Concern #{safetyConcern?.id}</h5>
        </button>
      </div>

      <SafetyConcernPopUpContent safetyConcern={safetyConcern} />
    </>
  )
}

export default SafetyConcernPopup
