import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { IAuthUserLastViewedVersion, IAuthUserLastViewedVersionUpdateUpsert } from '../../interfaces'

import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateAuthUserLastViewedByIdMutationRequest: IGetPutRequest = params => {
  const { airportId, id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/AuthUsers/${id}/LastViewedVersion`,
  }
}

const useUpdateAuthUserLastViewedByIdMutation: IUseUpdateMutation<
  IAuthUserLastViewedVersion,
  IAuthUserLastViewedVersionUpdateUpsert
> = (params, options = {}) => {
  const request = useMemo(() => getUpdateAuthUserLastViewedByIdMutationRequest(params), [params])

  const updateAuthUserLastViewedById: IUpdateFn<IAuthUserLastViewedVersionUpdateUpsert, IAuthUserLastViewedVersion> =
    useCallback(async data => axios({ ...request, data }).then(res => res.data), [request])

  const response = useMutation<IAuthUserLastViewedVersion, Error, IAuthUserLastViewedVersionUpdateUpsert>({
    mutationFn: updateAuthUserLastViewedById,
    ...options,
  })

  return response
}

export default useUpdateAuthUserLastViewedByIdMutation
