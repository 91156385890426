import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getAddTrainingSessionMutationRequest from './get-add-training-session-mutation-request'

import { IUseAddMutation, IAddFn } from '../../interfaces'
import { ITrainingSession, ITrainingSessionAddUpsert } from '../../interfaces'

const useAddTrainingSessionMutation: IUseAddMutation<ITrainingSession, ITrainingSessionAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getAddTrainingSessionMutationRequest(params), [params])

  const addTrainingSession: IAddFn<ITrainingSessionAddUpsert, ITrainingSession> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITrainingSession, Error, any>({
    mutationFn: addTrainingSession,
    ...options,
  })

  return response
}

export default useAddTrainingSessionMutation
