import { FC } from 'react'
import axios from 'axios'

import { Location, HorizontalRule } from '../../../../components'
import RFFFields from '../../react-final-form-fields'

import { toNumbers, options } from '../../../../util'
import { validateRequired, validateEmail, getValidateFileSizeLessThan } from '../../../../util/validation'
import config from '../../../../config'

import { renameFile } from '../../../../services'

import { SafetyReportFormFieldsProps } from './safety-report-form-types'

const validateFile = (file: File) => getValidateFileSizeLessThan(20, 'MB')(file)

const uploadFile = async (file: File, airportId: number) => {
  const url = `${config.baseUrl}/SafetyReport/Upload?airportId=${airportId}`

  const formData = new FormData()
  formData.append('file', renameFile(file))

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const SafetyReportFormFields: FC<SafetyReportFormFieldsProps> = props => {
  const { airportId, submitting, values, form } = props

  return (
    <>
      <RFFFields.Input name="reporterName" label="Name" placeholder={'Enter your name'} disabled={submitting} />

      <RFFFields.Input
        name="reporterEmailAddress"
        label="E-mail Address"
        placeholder={'john.doe@example.com'}
        validate={validateEmail}
        disabled={submitting}
      />

      <RFFFields.MaskedInput
        name="reporterPhoneNumber"
        label="Phone"
        type="phone"
        parse={toNumbers}
        disabled={submitting}
        placeholder={'___-___-____'}
      />

      <RFFFields.DatePicker name="observedAt" label="When did you observe this?" disabled={submitting} withPortal />

      <RFFFields.Select
        name="severity"
        label="Severity"
        options={options.severity}
        validate={validateRequired}
        disabled={submitting}
      />

      <RFFFields.Textarea
        name="description"
        label="Description of Safety Concern"
        maxLength={2000}
        rows="20"
        disabled={submitting}
      />

      <RFFFields.Switch
        name="injuryOccurred"
        label="Was anyone injured?"
        onLabel="Yes"
        offLabel="No"
        disabled={submitting}
      />

      <RFFFields.Switch
        name="damageOccurred"
        label="Did damage occur?"
        onLabel="Yes"
        offLabel="No"
        disabled={submitting}
      />

      <RFFFields.Switch
        name="recurringConcern"
        label="Is this a recurring safety concern?"
        onLabel="Yes"
        offLabel="No"
        disabled={submitting}
      />

      <HorizontalRule />

      <div className="mb-5">
        <Location
          // @ts-ignore
          hideSavedLocations={true}
          mapMode={values?.mapMode}
          setMapMode={form?.mutators?.onMapModeChange}
        />
      </div>

      <RFFFields.DocumentUpload
        name="documents"
        parentType="SafetyReport"
        uploadFile={async (file: File) => await uploadFile(file, airportId)}
        validateSize={validateFile}
        acceptedFileExtensions=".jpg,.jpeg,.png,.heic,.heif,.dng,.raw"
      />
    </>
  )
}

export default SafetyReportFormFields
