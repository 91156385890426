import cn from 'classnames'
import { formatDistance } from 'date-fns'
import { toDate } from 'date-fns-tz'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import { BellIcon, ClipboardIcon, WrenchIcon } from './notification-icons'

import { NotificationChangeGraph } from '../../../components/notification-change-graph'
//@ts-ignore
import s from './notification-drawer-body.module.scss'

const inspectionFeature = 'Inspection'
const workOrderFeature = 'WorkOrder'
const checklistFeature = 'Checklist'
const reminderFeature = 'Reminder'

type NotificationDrawerBodyProps = {
  clickNotification: () => any
  currentDate: Date
  notifications: any[]
  onClose: () => any
  removeNotification: (id: number) => any
  timeZoneIanaId: string
}

const NotificationDrawerBody: React.FC<NotificationDrawerBodyProps> = ({
  clickNotification,
  currentDate,
  notifications,
  onClose,
  removeNotification,
  timeZoneIanaId,
}) => {
  const notificationClick = useCallback(() => {
    clickNotification()
    onClose()
  }, [clickNotification, onClose])

  const getIcon = (feature: { id: number; name: string }) => {
    if (feature.name === inspectionFeature) {
      return <ClipboardIcon />
    }

    if (feature.name === workOrderFeature) {
      return <WrenchIcon />
    }

    if (feature.name === checklistFeature) {
      return <ClipboardIcon />
    }

    if (feature.name === reminderFeature) {
      return <BellIcon />
    }

    return null
  }

  const getUri = useCallback((feature: { id: number; name: string }, id: number) => {
    if (feature.name === reminderFeature) {
      return null
    }

    let segment = ''

    if (feature.name === inspectionFeature) {
      segment = 'inspections'
    }

    if (feature.name === workOrderFeature) {
      segment = 'work-orders'
    }

    if (feature.name === checklistFeature) {
      segment = 'checklists'
    }

    return `/${segment}/${id}`
  }, [])

  const getFeatureShortName = useCallback((feature: { id: number; name: string }) => {
    switch (feature.name) {
      case inspectionFeature:
        return 'INSP'
      case workOrderFeature:
        return 'WO'
      case checklistFeature:
        return 'CHKLST'
      default:
        return ''
    }
  }, [])

  const getIconWithTitle = useCallback(
    (notification: any) => {
      return (
        <div className="d-flex align-items-center">
          <div className="d-flex flex-center position-relative ml-5 mr-15 ml-lg-9 mr-lg-15">
            <span className="svg-icon svg-icon-4x svg-icon-notification position-absolute">
              <svg width="70px" height="70px" viewBox="0 0 70 70" fill="none">
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                  <path
                    d="M28 4.04145C32.3316 1.54059 37.6684 1.54059 42 4.04145L58.3109 13.4585C62.6425 15.9594 65.3109 20.5812 65.3109 25.5829V44.4171C65.3109 49.4188 62.6425 54.0406 58.3109 56.5415L42 65.9585C37.6684 68.4594 32.3316 68.4594 28 65.9585L11.6891 56.5415C7.3575 54.0406 4.68911 49.4188 4.68911 44.4171V25.5829C4.68911 20.5812 7.3575 15.9594 11.6891 13.4585L28 4.04145Z"
                    fill="#000000"
                  ></path>
                </g>
              </svg>
            </span>
            <span className="svg-icon svg-icon-2x svg-icon-notification-inner position-absolute">{getIcon(notification.feature)}</span>
          </div>

          <div className="d-flex flex-column">
            <span>
              {notification.additionalInfo?.displayTitle
                ? notification.additionalInfo.displayTitle
                : `${getFeatureShortName(notification.feature)} #${notification.entityId} - ${
                    notification.entityStatus
                  }`}
            </span>
            <span className="text-muted">
              {formatDistance(currentDate, toDate(notification.lastUpdatedAt, { timeZone: timeZoneIanaId }))} ago
            </span>
          </div>
        </div>
      )
    },
    [currentDate, getFeatureShortName, timeZoneIanaId],
  )

  return (
    <div className="mx-6 text-dark">
      {notifications?.map(notification => (
        <div key={notification.id} className="my-6">
          <Card className={`${cn(!notification.additionalInfo?.changes?.length && s['no-radius-bottom-border'])} ${s['card']}`}>
            <Card.Header className={`d-flex px-2 py-4 ${cn(!notification.additionalInfo?.changes?.length && s['header-no-changes'])}`}>
              <div className="d-flex align-items-center justify-content-between w-100 px-2">
                {getUri(notification.feature, notification.entityId) !== null ? (
                  <Link className={`${s['notification-link']}`} to={getUri(notification.feature, notification.entityId) || ''} onClick={notificationClick}>
                    {getIconWithTitle(notification)}
                  </Link>
                ) : (
                  getIconWithTitle(notification)
                )}
                <button
                  aria-label={`dismiss-notification-${notification.id}`}
                  className="close mx-3"
                  onClick={e => removeNotification(notification.id)}
                  type="button"
                >
                  <i className="bi bi-x-circle-fill text-dark" />
                </button>
              </div>
            </Card.Header>
            {notification.additionalInfo?.changes?.length > 0 && (
              <Card.Body className="py-0">
                <div className="d-flex flex-column py-5 ml-2">
                  {notification.additionalInfo?.changes?.length > 0 && (
                    <NotificationChangeGraph notification={notification} />
                  )}
                </div>
              </Card.Body>
            )}
          </Card>
        </div>
      ))}
    </div>
  )
}

NotificationDrawerBody.defaultProps = {
  clickNotification: () => {},
  notifications: [],
  onClose: () => {},
  removeNotification: () => {},
}

export default NotificationDrawerBody
