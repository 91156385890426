import axios from 'axios'
import config from '../../config'
import { IMessageOfTheDay } from '../../interfaces'

const updateMessageOfTheDay = async ({
  airportId,
  messageOfTheDay,
}: {
  airportId: number
  messageOfTheDay: IMessageOfTheDay
}) => {
  const url = `${config.baseUrl}/${airportId}/MessageOfTheDay`
  return await axios.put<IMessageOfTheDay>(url, messageOfTheDay)
}

export default updateMessageOfTheDay
