export enum SafetyReportSeverity {
  // eslint-disable-next-line
  Low = 1,
  // eslint-disable-next-line
  Medium = 2,
  // eslint-disable-next-line
  High = 3,
  // eslint-disable-next-line
  Urgent = 4,
}
