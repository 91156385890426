import { buildGetQuery } from '../../util'
import config from '../../config'

import { IPagedResults, IInspection } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  entityState?: string
  startedAtBegin?: string
  startedAtEnd?: string
  endedAtBegin?: string
  endedAtEnd?: string
  inspectorIds?: string[]
  inspectionIds?: number[]
  inspectionCategoryIds?: number[]
  inspectionTypeIds?: number[]
  inspectionSubtypeIds?: number[]
  searchText?: string
  limit?: number
  page?: number
  sortBy?: string
  sortOrder?: string
}

const getInspectionsSearchRequest = (params: Params, data?: QueryObject): any => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Inspections/Search`,
    data,
  }
}

const useInspectionsSearchQuery = buildGetQuery<
  Params,
  QueryObject | undefined,
  IPagedResults<IInspection[]> | undefined
>({
  getRequest: getInspectionsSearchRequest,
  getQueryKey: (params, queryObject) => [
    'inspections-search',
    params.airportId,
    queryObject?.entityState,
    queryObject?.startedAtBegin,
    queryObject?.startedAtEnd,
    queryObject?.endedAtBegin,
    queryObject?.endedAtEnd,
    queryObject?.inspectorIds,
    queryObject?.inspectionIds,
    queryObject?.inspectionCategoryIds,
    queryObject?.inspectionTypeIds,
    queryObject?.inspectionSubtypeIds,
    queryObject?.searchText,
    queryObject?.limit,
    queryObject?.page,
    queryObject?.sortBy,
    queryObject?.sortOrder,
  ],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useInspectionsSearchQuery
