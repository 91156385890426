import { useCallback, useContext, useEffect, useState } from 'react'
import { func, number } from 'prop-types'
import { components } from 'react-select'
import { MultiSelectContext } from '../multi-select-context'
import MoreSelectedBadge from './more-selected-badge'

const MultiValue = ({ index, getValue, ...props }) => {
  const { state, dispatch } = useContext(MultiSelectContext)
  const { controlWidth, multiSelectNumLabels } = state

  const value = getValue()
  const [overflow, setOverflow] = useState([])

  useEffect(() => {
    setOverflow(value.slice(multiSelectNumLabels).map(x => x.label))
  }, [controlWidth, multiSelectNumLabels, value])

  const calculateSetMaxValue = useCallback(() => {
    let width = 0
    let maxToShowX = 0
    let maxReached = false
    if (Array.isArray(value)) {
      value.forEach(v => {
        width += v.label.length * 9
        if (width > controlWidth) {
          maxReached = true
        } else {
          if (!maxReached) {
            maxToShowX++
          }
        }
      })
    }
    dispatch({ type: 'setMultiSelectNumLabels', payload: maxToShowX })
  }, [dispatch, controlWidth, value])

  useEffect(() => calculateSetMaxValue(), [calculateSetMaxValue])

  return index < multiSelectNumLabels ? (
    <components.MultiValue {...props} />
  ) : index === multiSelectNumLabels && overflow.length ? (
    <MoreSelectedBadge items={overflow} />
  ) : null
}

export default MultiValue

MultiValue.propTypes = {
  index: number,
  getValue: func,
}
