import s from './radio-group-view.module.scss'

const RadioGroupView = props => {
  const { options, value } = props

  const option = options.find(option => option.value === value)
  if (!option) {
    return null
  }

  return <div className={s['radio-group-view']}>{option.subLabel}</div>
}

export default RadioGroupView
