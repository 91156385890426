import { FC, useMemo } from 'react'
import cn from 'classnames'

import Badge from '../../badge/badge'
import PriorityBadge from '../../badge/priority-badge'
import DropdownButton from '../../dropdown-button/dropdown-button'
import { IListItemProps } from '../../list'

import { formatDateString } from '../../../util'

import { ISafetyConcern } from '../../../interfaces'

import s from '../card.module.scss'

type SafetyConcernCardProps = ISafetyConcern & IListItemProps<ISafetyConcern>

const SafetyConcernCard: FC<SafetyConcernCardProps> = props => {
  const {
    id,
    summary: name,
    description,
    lastUpdatedAt,
    lastUpdatedByName,
    parentStatus,
    priority,
    renderActions,
    componentRef,
    activeId,
    actionsButtonOptions,
    onTitleClick,
  } = props

  const title = useMemo(() => `#${id} - ${name || 'New Public Safety Report'}`, [id, name])

  const info = useMemo(() => {
    const formattedLastUpdatedAt = formatDateString(lastUpdatedAt)

    if (formattedLastUpdatedAt && lastUpdatedByName) {
      return `${formattedLastUpdatedAt} by ${lastUpdatedByName}`
    }

    return ''
  }, [lastUpdatedAt, lastUpdatedByName])

  return (
    <div
      className={cn(
        'card card-custom border border-2 p-4',
        activeId === id && s['active-card'],
        !name && s['highlight-card'],
      )}
      ref={componentRef}
    >
      <div className={cn(s['card-header'], 'card-header border-0 m-0 p-0')}>
        <div
          className={cn('card-title font-weight-bold w-100', onTitleClick && 'cursor-pointer')}
          // @ts-ignore
          onClick={() => onTitleClick(props)}
        >
          {title}
        </div>

        {actionsButtonOptions && (
          <DropdownButton className={s['actions-button']} accessor={props} options={actionsButtonOptions} />
        )}
      </div>

      <div className="card-body p-0">
        {info && <div className="mt-2 text-truncate text-dark">{info}</div>}

        {description && <div className="mt-2">{description}</div>}
      </div>

      <div className="border-0 card-footer d-flex align-items-center justify-content-end mt-2 mx-0 p-0">
        <Badge status={parentStatus?.name} />

        <PriorityBadge priority={priority.name} />

        {renderActions && renderActions(props)}
      </div>
    </div>
  )
}

export default SafetyConcernCard
