import { RowSelectionCell } from '../cells/row-selection-cell'
import { RowSelectionHeader } from '../headers/row-selection-header'

import s from './columns.module.scss'

export const rowSelectionColumn = {
  id: 'row-selection',
  header: RowSelectionHeader,
  cell: RowSelectionCell,

  meta: {
    className: s['column-row-selection'],
  },
}
