import qs from 'qs'

import config from '../../config'

import { IGetGetRequest } from '../../interfaces'

const getWildlifeSpeciesMasterListRequest: IGetGetRequest<any> = (_, queryObject) => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/WildlifeSpecies/MasterList${queryString}`,
  }
}

export default getWildlifeSpeciesMasterListRequest
