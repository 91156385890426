import { useEffect } from 'react'

const useGeolocation = ({ enableHighAccuracy, maximumAge, timeout } = {}, callback) => {
  useEffect(() => {
    let didCancel
    const updateCoordinates = ({ coords = {}, timestamp }) => {
      const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords
      if (!didCancel) {
        if (callback instanceof Function) {
          callback({
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            latitude,
            longitude,
            speed,
            timestamp,
            error: null,
          })
        }
      }
    }

    const setError = error => {
      if (!didCancel) {
        console.error(error)
      }
    }

    let watchId
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError)
      watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
        enableHighAccuracy,
        maximumAge,
        timeout,
      })
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
      didCancel = true
    }
  }, [callback, enableHighAccuracy, maximumAge, timeout])
}

export default useGeolocation
