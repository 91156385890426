import { FC } from 'react'

type PriorityBadgeProps = {
  className?: string
  priority?: string
}

const PriorityBadge: FC<PriorityBadgeProps> = props => {
  const { priority, className: propsClassName } = props

  let className
  let label
  switch (priority) {
    case 'High': {
      className = 'badge-danger'
      label = 'High'
      break
    }
    case 'Medium': {
      className = 'badge-warning'
      label = 'Medium'
      break
    }
    case 'Low': {
      className = 'badge-success'
      label = 'Low'
      break
    }
    default: {
      className = ''
      label = ''
      break
    }
  }

  return (
    <span className={`badge ml-2 text-white ${className} ${propsClassName}`} data-test-id="priority-badge">
      {label}
    </span>
  )
}

export default PriorityBadge
