import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IFaq, IGetPutRequest, IUseUpdateMutation, IUpdateFn } from '../../interfaces'

const getSortFaqsMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/help/FAQs/Sort`,
})

const useSortFaqsMutation: IUseUpdateMutation<IFaq[] | undefined, number[]> = (params, options = {}) => {
  const request = useMemo(() => getSortFaqsMutationRequest(params), [params])

  const sortFaqs: IUpdateFn<number[], IFaq[] | undefined> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IFaq[] | undefined, Error, number[]>({
    mutationFn: sortFaqs,
    ...options,
  })

  return response
}

export default useSortFaqsMutation
