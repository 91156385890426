import { SortingState } from '@tanstack/react-table'

export const buildSortParams = (sortBy?: string, isSortDesc?: boolean) =>
  sortBy ? { sortBy, sortOrder: isSortDesc ? 'desc' : 'asc' } : {}

export const buildTableSortParams = (sorting?: SortingState) => {
  const sortBy = sorting?.[0]?.id
  const isSortDesc = sorting?.[0]?.desc

  return sortBy ? { sortBy, sortOrder: isSortDesc ? 'desc' : 'asc' } : {}
}
