import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortActivityLogSearchFilter } from '../../../../interfaces'
import { IActivityLogFilterValues, IActivityLogFilterUpsertValues } from './activity-log-filter-form-types'

export const objectToActivityLogFilterValues = (object?: { [key: string]: any }): IActivityLogFilterUpsertValues => ({
  dateRange: { 0: object?.searchBeginDate || '', 1: object?.searchEndDate || '' },
  searchTagIds: object?.searchTagIds || [],
  searchText: object?.searchText || '',
})

export const savedSearchFilterToSavedActivityLogFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): IActivityLogFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let activityLogFilterObject
  try {
    activityLogFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    activityLogFilterObject = {}
  }

  const activityLogFilterValues = objectToActivityLogFilterValues(activityLogFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    activityLogFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return activityLogFilterValues
}

export const savedActivityLogFilterValuesToSavedSearchFilterAddUpsert = (
  savedActivityLogFilterValues: IActivityLogFilterValues | undefined,
): IShortActivityLogSearchFilter => ({
  searchBeginDate: savedActivityLogFilterValues?.dateRange?.[0] || '',
  searchEndDate: savedActivityLogFilterValues?.dateRange?.[1] || '',
  searchTagIds: savedActivityLogFilterValues?.searchTagIds || [],
  searchText: savedActivityLogFilterValues?.searchText || '',
})

export const savedActivityLogFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedActivityLogFilterValues: IActivityLogFilterValues | undefined,
): IShortActivityLogSearchFilter =>
  savedActivityLogFilterValuesToSavedSearchFilterAddUpsert(savedActivityLogFilterValues)
