import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IUseAddMutation,
  IAddFn,
  IChecklistReminder,
  IChecklistReminderAddUpsert,
  IGetPostRequest,
} from '../../interfaces'

const getAddChecklistReminderMutationRequest: IGetPostRequest = params => ({
  method: 'post',
  url: `${config.baseUrl}/${params.airportId}/Reminders/Checklist`,
})

const useAddChecklistReminderMutation: IUseAddMutation<IChecklistReminder, IChecklistReminderAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getAddChecklistReminderMutationRequest(params), [params])

  const addChecklistReminder: IAddFn<IChecklistReminderAddUpsert, IChecklistReminder> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklistReminder, Error, any>({
    mutationFn: addChecklistReminder,
    ...options,
  })

  return response
}

export default useAddChecklistReminderMutation
