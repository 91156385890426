import { useCallback, useMemo } from 'react'
import getWorkOrderByIdRequest from './get-work-order-by-id-request'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const useWorkOrder = ({ airportId, workOrderId }) => {
  const request = useMemo(() => getWorkOrderByIdRequest({ airportId, workOrderId }), [airportId, workOrderId])
  const fetchWorkOrder = useCallback(() => axios(request).then(res => res.data), [request])

  const { data, error, isLoading: loading, refetch } = useQuery(['work-order', airportId, workOrderId], fetchWorkOrder)

  return { data, error, loading, execute: refetch }
}

export default useWorkOrder
