import { FC } from 'react'
import { Button, ButtonGroup as BootstrapButtonGroup } from 'react-bootstrap'

import Select, { SelectOption } from '../select/select'

import { getButtonGroupButtonVariant } from '../../../util'

const MAX_BUTTON_COUNT = 3

type ButtonGroupProps = {
  isInvalid?: boolean
  value: string | number
  onChange: Function
  options: Array<SelectOption>
  name: string
  disabled?: boolean | undefined
}

const ButtonGroup: FC<ButtonGroupProps> = props => {
  const { value, onChange, options = [], disabled } = props

  if (options.length <= MAX_BUTTON_COUNT) {
    return (
      <div>
        <BootstrapButtonGroup>
          {(options || []).map(option => (
            <Button
              key={`responses-dropdown${option.value}`}
              type="button"
              variant={`${getButtonGroupButtonVariant(value === option.value)}`}
              value={option.value}
              onClick={() => onChange(option.value)}
              disabled={disabled}
            >
              {option.label}
            </Button>
          ))}
        </BootstrapButtonGroup>
      </div>
    )
  }

  return <Select {...props} />
}

export default ButtonGroup
