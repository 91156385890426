export const toUpperCase = v => v && v.toUpperCase()

export const toLowerCase = v => v && v.toLowerCase()

export const toNumbers = v => v && v.replace(/[^\d]/g, '')

export const toPhoneParser = v => {
  if (!v) return v

  let newV = v
  let onlyNums = v.replace(/[^\d]/g, '')

  if (onlyNums[0] === '1' && onlyNums.length === 1) return ''

  if (onlyNums[0] !== '1') newV = `1${newV}`

  return newV.replace(/[^\d]/g, '')
}
