import { useMemo, useCallback } from 'react'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import cn from 'classnames'

import withAddons from '../adapters/with-addons'
import { MultiSelectContextProvider } from './multi-select-context'
import { Control, MultiValue, SelectCheckboxDropdown } from './custom-components'
import SelectView from './select-view'

import './select.scss'

export type SelectOption = {
  label: string | number
  value: string | number
}

type SelectProps = {
  isInvalid: boolean
  value: string | number | Array<string> | Array<number>
  onChange: Function
  isMulti: boolean
  options: Array<SelectOption>
  name: string
  isClearable: boolean
  isViewMode: boolean
  disabled: boolean | undefined
  canCreate: boolean
  onCreateOption?: (input: string) => any
  innerRef?: any
}

const ALL = 'All'

const isMultiValueIncludeAll = (value: any) => Boolean(value.find((v: any) => v.value === ALL))

const parseOptionValue = (value: any, isMulti: boolean) => {
  if (isMulti) {
    if (Array.isArray(value)) {
      const isAllSelected = isMultiValueIncludeAll(value)
      if (isAllSelected) {
        return ALL
      } else {
        return (value || []).map(v => v.value)
      }
    } else {
      return [value.value]
    }
  } else {
    return value.value
  }
}

const normalizeOptionValue = (value: any, options: Array<SelectOption>) => {
  if (Array.isArray(value)) {
    return value.map(v => options.find(o => o.value === v))
  } else {
    return options.filter(o => o.value === value)
  }
}

const initialContextState = {}

const Select: React.FC<SelectProps> = props => {
  const {
    isInvalid,
    value,
    onChange,
    isMulti,
    options = [],
    disabled,
    isViewMode,
    isClearable,
    innerRef,
    ...selectProps
  } = props
  const _isMulti = isMulti && value !== ALL

  const customStyles = useMemo(
    () => ({
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
      valueContainer: (provided: any, state: any) => ({
        ...provided,
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
      }),
    }),
    [],
  )

  const multiValueComponents = {
    Control: Control,
    MultiValue: MultiValue,
    Option: SelectCheckboxDropdown,
  }

  const customComponents = {
    IndicatorSeparator: () => null,
  }

  if (_isMulti) {
    Object.keys(multiValueComponents).forEach(k => {
      // @ts-ignore
      customComponents[k] = multiValueComponents[k]
    })
  }

  const _onChange = useCallback(
    (value: any) => {
      if (value === null) {
        onChange && onChange(undefined)
        return
      }
      const formattedValue = parseOptionValue(value, isMulti)
      onChange && onChange(formattedValue)
    },
    [onChange, isMulti],
  )

  const _value = useMemo(() => {
    return normalizeOptionValue(value, options)
  }, [value, options])

  const selectClassNames = cn('react-select zindex-5', disabled && 'opacity-50')
  const prefixClassNames = cn({ 'react-select-danger': isInvalid }, 'react-select')

  return isViewMode ? (
    <SelectView value={value} options={options} />
  ) : props.canCreate && props.onCreateOption ? (
    <MultiSelectContextProvider value={initialContextState}>
      <CreatableSelect
        ref={innerRef}
        className={selectClassNames}
        classNamePrefix={prefixClassNames}
        closeMenuOnSelect={!_isMulti}
        components={customComponents}
        hideSelectedOptions={false}
        options={options}
        onChange={_onChange}
        value={_value}
        isMulti={_isMulti}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        styles={customStyles}
        isClearable={isClearable}
        createOptionPosition="first"
        menuPlacement="auto"
        formatCreateLabel={(inputValue: string) => `"${inputValue}"`}
        {...selectProps}
      />
    </MultiSelectContextProvider>
  ) : (
    <MultiSelectContextProvider value={initialContextState}>
      <ReactSelect
        ref={innerRef}
        className={selectClassNames}
        classNamePrefix={prefixClassNames}
        closeMenuOnSelect={!_isMulti}
        components={customComponents}
        hideSelectedOptions={false}
        options={options}
        onChange={_onChange}
        value={_value}
        isMulti={_isMulti}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        styles={customStyles}
        isClearable={isClearable}
        menuPlacement="auto"
        {...selectProps}
      />
    </MultiSelectContextProvider>
  )
}

export default withAddons(Select)
