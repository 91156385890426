import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IAuthUser, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  id: number
}

const getAuthUserByIdRequest = (params: Params, queryObject: undefined): IGetRequestObject => {
  const { airportId, id } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/AuthUsers/${id}${queryString}`,
  }
}

export const useAuthUserByIdQuery = buildGetQuery<Params, undefined, IAuthUser | undefined>({
  getRequest: getAuthUserByIdRequest,
  getQueryKey: params => ['auth-user-by-id', params.airportId, params.id],
  defaultOptions: { initialData: undefined },
})

export default useAuthUserByIdQuery
