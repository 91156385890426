import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import {
  useAuthUsersSelectListQuery,
  useInspectionCategoriesQuery,
  useInspectionTypesQuery,
  useInspectionSubtypesQuery,
} from '../../../../services'
import { toOptions, capitalizeFirst } from '../../../../util'

import { IInspectionFilterUpsertValues } from './inspection-filter-form-types'

const useWorkorderFilterQueries = (values?: IInspectionFilterUpsertValues) => {
  const { airportId } = useProfile()

  const authUsersQuery = useAuthUsersSelectListQuery({ airportId }, undefined)

  const authUsersOptions = useMemo(
    () => toOptions(authUsersQuery.data, { getLabel: user => capitalizeFirst(`${user.lastName}, ${user.firstName}`) }),
    [authUsersQuery.data],
  )

  const inspectionCategoriesQuery = useInspectionCategoriesQuery(undefined, undefined)

  const inspectionCategoriesOptions = useMemo(
    () => toOptions(inspectionCategoriesQuery.data),
    [inspectionCategoriesQuery.data],
  )

  const inspectionTypesQuery = useInspectionTypesQuery(
    { airportId },
    { activeOnly: true, inspectionCategoryIds: values?.inspectionCategoryIds || [] },
  )

  const inspectionTypesOptions = useMemo(
    () => toOptions(inspectionTypesQuery.data, { getLabel: inspectionType => inspectionType.baseName }),
    [inspectionTypesQuery.data],
  )

  const inspectionSubtypesQuery = useInspectionSubtypesQuery({ airportId }, undefined)

  const inspectionSubtypesOptions = useMemo(
    () => toOptions(inspectionSubtypesQuery.data),
    [inspectionSubtypesQuery.data],
  )

  return {
    authUsersQuery,
    authUsersOptions,

    inspectionCategoriesQuery,
    inspectionCategoriesOptions,

    inspectionTypesQuery,
    inspectionTypesOptions,

    inspectionSubtypesQuery,
    inspectionSubtypesOptions,
  }
}

export default useWorkorderFilterQueries
