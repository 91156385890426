import axios from 'axios'

import config from '../../config'

const updateInspectionTypeBuilder = (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/InspectionTypeBuilders`
  return axios.put(url, data)
}

export default updateInspectionTypeBuilder
