import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  userId?: string
}

const getNotificationsRequest = (params: Params, queryObject?: QueryObject): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Notifications${queryString}`,
  }
}

const useNotificationsQuery = buildGetQuery<Params, QueryObject | undefined, any[] | undefined>({
  getRequest: getNotificationsRequest,
  getQueryKey: (params, queryObject) => ['notifications', params.airportId, queryObject?.userId],
})

export default useNotificationsQuery
