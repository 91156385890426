import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IDiscrepancy, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  locationId?: number
}

const getSafetyItemsDiscrepancyRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/SafetyItems/Discrepancy${queryString}`,
  }
}

const useSafetyItemsDiscrepancyQuery = buildGetQuery<Params, QueryObject | undefined, IDiscrepancy[] | undefined>({
  getRequest: getSafetyItemsDiscrepancyRequest,
  getQueryKey: (params, queryObject) => ['safety-items-discrepancy', params.airportId, queryObject?.locationId || ''],
})

export default useSafetyItemsDiscrepancyQuery
