import React, { useEffect, useState } from 'react'
import { Input, Textarea } from './form'
import { Info } from './info'

const ReportIssue = ({ onChange = (val) => {} }) => {
  const [reportIssue, setReportIssue] = useState({
    summary: '',
    description: '',
  })

  useEffect(() => {
    onChange(reportIssue)
  }, [onChange, reportIssue])

  return (
    <>
      <span className="d-block pb-4">Customer Service is never far away.</span>
      <span className="d-block pb-2">Contact our IT Helpdesk:</span>
      <span className="d-block pb-4">
        <strong>866-688-3404</strong>
      </span>
      <span className="d-block pb-4">Or send us a message:</span>
      <span className="d-block pb-2">Message Summary</span>
      <div className="d-flex align-items-center">
        <Input
          onChange={e =>
            setReportIssue(prev => ({
              ...prev,
              summary: e,
            }))
          }
          value={reportIssue.summary}
        />
        <Info className="ml-2" info="Example: Search Bug, Password Issue, Feature Request" />
      </div>
      <span className="d-block pb-2 mt-4">Message Details</span>
      <div className="d-flex align-items-center">
        <Textarea
          rows={'10'}
          onChange={e => {
            setReportIssue(prev => ({
              ...prev,
              description: e.target.value,
            }))
          }}
          value={reportIssue.description}
        />
        <Info className="ml-2" info="Enter your message or question here." />
      </div>
    </>
  )
}

export default ReportIssue
