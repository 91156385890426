import config from '../../config'
import useAxios from '../use-axios'
import { removeNull } from '../../util'

const useWorkOrderInsightsByFacility = ({ aging, airportId, entityState }) => {
  const queryStringParams = [`daysInRange=${aging}`, entityState ? `entityState=${entityState}` : null]
  const queryString = queryStringParams.filter(removeNull).join('&')

  const request = {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WorkOrders/InsightsByFacility?${queryString}`,
  }

  const { data = [], error, loading, refetch } = useAxios(request)

  return { data, error, loading, execute: refetch }
}

export default useWorkOrderInsightsByFacility
