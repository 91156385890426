import config from '../../config'

import { IPostRequestObject } from '../../interfaces'

export interface IGetAddDiscrepancyMutationRequestParams {
  airportId: number
  inspectionId: number
}

export interface IGetAddDiscrepancyMutationRequest {
  (params: IGetAddDiscrepancyMutationRequestParams): IPostRequestObject
}

const getAddDiscrepancyMutationRequest: IGetAddDiscrepancyMutationRequest = params => {
  const { airportId, inspectionId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Inspections/${inspectionId}/Discrepancy`,
  }
}

export default getAddDiscrepancyMutationRequest
