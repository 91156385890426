import { FC, RefObject, HTMLProps, useEffect, useState, useRef, useCallback, ReactNode } from 'react'
import cn from 'classnames'

import { calculateMenuPosition } from './dropdown-menu.utils'

import { useClickOutside } from '../../hooks/use-click-outside'

import s from './dropdown-menu.module.scss'

type DropdownMenuProps = HTMLProps<HTMLButtonElement> & {
  componentRef?: RefObject<HTMLElement>
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  isCloseOnOutsideClick?: boolean
}

const DropdownMenu: FC<DropdownMenuProps> = props => {
  const { componentRef, isOpen, onClose, children, isCloseOnOutsideClick } = props

  const menuRef = useRef<HTMLDivElement>(null)

  const [menuPosition, setMenuPosition] = useState({})

  useClickOutside(menuRef, onClose, isCloseOnOutsideClick === undefined ? isOpen : isCloseOnOutsideClick)

  const positionDropdown = useCallback(() => {
    if (componentRef?.current && menuRef?.current) {
      setMenuPosition(calculateMenuPosition(componentRef, menuRef))
    }
  }, [componentRef])

  const onCloseIfOpen = useCallback(() => isOpen && onClose(), [isOpen, onClose])

  useEffect(() => {
    positionDropdown()

    window.addEventListener('resize', positionDropdown)
    window.addEventListener('scroll', onCloseIfOpen, true)

    return () => {
      window.removeEventListener('resize', positionDropdown)
      window.removeEventListener('scroll', onCloseIfOpen)
    }
  }, [positionDropdown, isOpen, onCloseIfOpen])

  return (
    <div ref={menuRef} className={cn(s['menu'], { [s['menu-visible']]: isOpen })} style={menuPosition}>
      {children}
    </div>
  )
}

export default DropdownMenu
