import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import config from '../../config'
import { IAddFn, ISavedSearchFilters, IUseAddMutation } from '../../interfaces'
import { ISavedSearchFiltersUpsert } from '../../interfaces/services/saved-search-filters-upsert'

const getCreateSavedSearchFilterRequest = () => ({
  method: 'post',
  url: `${config.baseUrl}/SavedSearchFilters`,
})

const useAddSavedSearchFiltersMutationBase: IUseAddMutation<ISavedSearchFilters, ISavedSearchFiltersUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getCreateSavedSearchFilterRequest(), [])

  const addFn: IAddFn<ISavedSearchFiltersUpsert, ISavedSearchFilters> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISavedSearchFilters, Error, any>({
    mutationFn: addFn,
    ...options,
  })

  return response
}

const useAddSavedSearchFiltersMutation = (
  options?: UseMutationOptions<ISavedSearchFilters, Error, ISavedSearchFiltersUpsert>,
) => useAddSavedSearchFiltersMutationBase({}, options)

export default useAddSavedSearchFiltersMutation
