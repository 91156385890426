import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { validateRequired, validateMaxSymbols, composeValidators } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel, options } from '../../../../util'

import { ISavedFilterFormFiledsProps, ISavedFilterValues } from './saved-filter-form-types'

const validateMaxSymbols500 = validateMaxSymbols(500, 'Description must not exceed 500 characters')
const validateMaxSymbols100 = validateMaxSymbols(100, 'Activity must not exceed 100 characters')

const nameof = nameofFactory<ISavedFilterValues>()

const nameLabel = 'Name'
const descriptionLabel = 'Description'
const timeSpanLabel = 'Time Span'
const dynamicLabel = 'Dynamic'
const activeColumnsLabel = 'Active Columns'

const SavedFilterFormFields: React.FC<ISavedFilterFormFiledsProps> = props => {
  const { disabled, values, form, activeColumnsOptions } = props

  return (
    <>
      <RFFFields.Input
        name={nameof('name')}
        label={nameLabel}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        disabled={disabled}
        validate={composeValidators(validateRequired, validateMaxSymbols100)}
      />

      <RFFFields.Textarea
        name={nameof('description')}
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        disabled={disabled}
        validate={validateMaxSymbols500}
      />

      <Row>
        <Col>
          <RFFFields.Switch
            name={nameof('isDynamic')}
            label={dynamicLabel}
            onChange={form?.mutators?.onDynamicChange}
            disabled={disabled || !form?.mutators?.onDynamicChange}
            onLabel="Yes"
            offLabel="No"
          />
        </Col>

        <Col>
          <RFFFields.ButtonGroup
            name={nameof('timeSpan')}
            label={timeSpanLabel}
            disabled={disabled || !values.isDynamic}
            options={options.timeSpan}
          />
        </Col>
      </Row>

      <RFFFields.CheckboxGroup
        name={nameof('activeColumnIds')}
        label={activeColumnsLabel}
        disabled={disabled}
        options={activeColumnsOptions}
        validate={validateRequired}
      />
    </>
  )
}

export default SavedFilterFormFields
