import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPutRequest,
  IUseUpdateMutation,
  IUpdateFn,
  ISafetyConcernAction,
  ISafetyConcernActionUpdateUpsert,
} from '../../interfaces'

const getUpdateSafetyConcernActionMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/SafetyConcernActions`,
})

const useUpdateSafetyConcernActionMutation: IUseUpdateMutation<ISafetyConcernAction, ISafetyConcernActionUpdateUpsert> =
  (params, options = {}) => {
    const request = useMemo(() => getUpdateSafetyConcernActionMutationRequest(params), [params])

    const updateSafetyConcernAction: IUpdateFn<ISafetyConcernActionUpdateUpsert, ISafetyConcernAction> = useCallback(
      async data => axios({ ...request, data }).then(res => res.data),
      [request],
    )

    const response = useMutation<ISafetyConcernAction, Error, ISafetyConcernActionUpdateUpsert>({
      mutationFn: updateSafetyConcernAction,
      ...options,
    })

    return response
  }

export default useUpdateSafetyConcernActionMutation
