import axios from 'axios'

import config from '../../config'

const createInspection = async (airportId, userId, inspectionTypeId, inspectionSubtypeId = null) => {
  try {
    const url = `${config.baseUrl}/${airportId}/Inspections`

    const body = {
      createdBy: userId,
      inspectionTypeId,
      inspectionSubtypeId,
    }

    return await axios.post(url, body)
  } catch (error) {
    throw error
  }
}

export default createInspection
