import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISafetyConcern, IGetRequestObject } from '../../interfaces'

type Params = {
  id: number
}

const getSafetyConcernByIdQueryRequest = (params: Params): IGetRequestObject => {
  const { id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/SafetyConcerns/${id}`,
  }
}

const useSafetyConcernByIdQueryBase = buildGetQuery<Params, undefined, ISafetyConcern | undefined>({
  getRequest: getSafetyConcernByIdQueryRequest,
  getQueryKey: params => ['safety-concern-by-id', params.id],
  defaultOptions: { initialData: undefined },
})

const useSafetyConcernByIdQuery = (params: Params, options?: QueryObserverOptions<ISafetyConcern | undefined, Error>) =>
  useSafetyConcernByIdQueryBase(params, undefined, options)

export default useSafetyConcernByIdQuery
