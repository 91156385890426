import { RefObject } from 'react'

export const calculateMenuPosition = (
  componentRef: RefObject<HTMLElement>,
  menuRef: RefObject<HTMLDivElement>,
): any => {
  if (componentRef.current && menuRef.current) {
    const toggleRect: DOMRect = componentRef.current.getBoundingClientRect()
    const menuRect: DOMRect = menuRef.current.getBoundingClientRect()

    const isBottomHeightFits = window.innerHeight - toggleRect.y - toggleRect.height > menuRect.height
    if (isBottomHeightFits) {
      const isBottomRight = toggleRect.x + toggleRect.width > menuRect.width

      if (isBottomRight) {
        return {
          top: toggleRect.y + toggleRect.height,
          right: window.innerWidth - toggleRect.x - toggleRect.width,
        }
      }

      const isBottomLeft = window.innerWidth - toggleRect.x > menuRect.width
      if (isBottomLeft) {
        return {
          top: toggleRect.y + toggleRect.height,
          left: toggleRect.x,
        }
      }
    }

    const isTopHeightFits = toggleRect.y > menuRect.height
    if (isTopHeightFits) {
      const isTopRight = toggleRect.x + toggleRect.width > menuRect.width
      if (isTopRight) {
        return {
          right: window.innerWidth - toggleRect.x - toggleRect.width,
          bottom: window.innerHeight - toggleRect.y,
        }
      }

      const isTopLeft = window.innerWidth - toggleRect.width > menuRect.width
      if (isTopLeft) {
        return {
          left: toggleRect.x,
          bottom: window.innerHeight - toggleRect.y,
        }
      }
    }

    const isLeftWidthFits = toggleRect.x > menuRect.width

    if (isLeftWidthFits) {
      const isLeftBottom = toggleRect.y + toggleRect.height > menuRect.height
      if (isLeftBottom) {
        return {
          bottom: window.innerHeight - toggleRect.y - toggleRect.height,
          right: window.innerWidth - toggleRect.x,
        }
      }

      const isLeftTop = window.innerHeight - toggleRect.y > menuRect.height

      if (isLeftTop) {
        return {
          top: toggleRect.y,
          right: window.innerWidth - toggleRect.x,
        }
      }
    }

    const isRightWidthFits = window.innerWidth - toggleRect.x - toggleRect.width > menuRect.width

    if (isRightWidthFits) {
      const isRightBottom = toggleRect.y + toggleRect.height > menuRect.height
      if (isRightBottom) {
        return {
          bottom: window.innerHeight - toggleRect.y - toggleRect.height,
          left: toggleRect.x + toggleRect.width,
        }
      }

      const isRightTop = window.innerHeight - toggleRect.y > menuRect.height
      if (isRightTop) {
        return {
          top: toggleRect.y,
          left: toggleRect.x + toggleRect.width,
        }
      }
    }
  }

  return {}
}
