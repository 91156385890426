import React from 'react'

import RFFFields from '../../react-final-form-fields'
import { validateRequired } from '../../../../util/validation'

import { ColumnDefinitionFormProps } from './checklist-column-definition-types'

const dataTypeOptions = [
  {
    label: 'Text',
    value: 'string',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
]

const ColumnDefinitionForm: React.FC<ColumnDefinitionFormProps> = ({
  handleSubmit,
  submitting,
  formName,
  values,
  initialValues,
}) => {
  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input name="name" label="Header Name" validate={validateRequired} disabled={submitting} />

      <RFFFields.Select
        name="type"
        label="Data Type"
        options={dataTypeOptions}
        validate={validateRequired}
        disabled={submitting}
      />

      {initialValues.columnsAmount && <RFFFields.Input label="Amount of columns" type="number" name="columnsAmount" />}

      {values?.type === 'dropdown' && <RFFFields.Attributes name="attributes" disabled={submitting} isAutoValue />}

      {values?.type === 'string' && (
        <RFFFields.Switch name="isReadOnly" label="Read Only" disabled={submitting} onLabel=" " offLabel=" " />
      )}
    </form>
  )
}

export default ColumnDefinitionForm
