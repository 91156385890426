import { IWorkOrderValues, IWorkOrderUpsertValues } from './work-order-form-types'
import { IWorkOrder, IWorkOrderAddUpsert, IWorkOrderUpdateUpsert } from '../../../../interfaces'

export const workOrderToWorkOrderValues = (workOrder: IWorkOrder): IWorkOrderUpsertValues | undefined => {
  if (!workOrder) {
    return undefined
  }

  return {
    safetyItem: {
      facilityId: workOrder.safetyItem.facilityId,
      conditionId: workOrder.safetyItem.conditionId,
      safetyItemResponseId: workOrder.safetyItem.safetyItemResponseId,
      safetyItemUniqueValueId: workOrder.safetyItem.safetyItemUniqueValueId,
      note: workOrder.safetyItem.note,
    },

    workOrderTypeId: workOrder.workOrderTypeId,
    priorityId: workOrder.priorityId,
    parentStatusId: workOrder.parentStatus.id,
    notificationGroupId: workOrder.notificationGroupId,
  }
}

export const workOrderValuesToWorkOrderAddUpsert = (
  workOrderValues: IWorkOrderValues,
  payload: { airportId: number; safetyConcernId?: number },
): IWorkOrderAddUpsert | undefined => {
  if (!workOrderValues || !payload) {
    return undefined
  }

  const { airportId } = payload

  return {
    ...workOrderValues,
    id: 0,
    workOrderTypeId: workOrderValues.workOrderTypeId,
    priorityId: workOrderValues.priorityId,
    parentStatusId: workOrderValues.parentStatusId,
    notificationGroupId: workOrderValues.notificationGroupId,
    safetyConcernId: payload?.safetyConcernId,

    safetyItem: {
      id: 0,
      airportId,

      facilityId: workOrderValues.safetyItem.facilityId,
      conditionId: workOrderValues.safetyItem.conditionId,
      safetyItemResponseId: workOrderValues.safetyItem.safetyItemResponseId,
      safetyItemUniqueValueId: workOrderValues.safetyItem.safetyItemUniqueValueId,
      note: workOrderValues.safetyItem.note,
    },
  }
}

export const workOrderValuesToWorkOrderUpdateUpsert = (
  workOrderValues: IWorkOrderValues,
  workOrder?: IWorkOrder,
): IWorkOrderUpdateUpsert | undefined => {
  if (!workOrderValues || !workOrder) {
    return undefined
  }

  return {
    id: workOrder.id,
    workOrderTypeId: workOrderValues.workOrderTypeId,
    priorityId: workOrderValues.priorityId,
    parentStatusId: workOrderValues.parentStatusId,
    notificationGroupId: workOrderValues.notificationGroupId,
    userAssignedTo: workOrder.userAssignedTo,
    documentIds: (workOrder?.documents || []).map(document => document.id),
    workOrderEndedAt: undefined,
    workOrderEndedBy: undefined,
    workOrderStartedAt: workOrder.workOrderStartedAt || undefined,
    workOrderStartedBy: workOrder.workOrderStartedBy || undefined,
    safetyConcernId: workOrder.safetyConcernId,

    safetyItem: {
      id: workOrder.safetyItem.id,
      airportId: workOrder.safetyItem.airportId,

      facilityId: workOrderValues.safetyItem.facilityId,
      conditionId: workOrderValues.safetyItem.conditionId,
      safetyItemResponseId: workOrderValues.safetyItem.safetyItemResponseId,
      safetyItemUniqueValueId: workOrderValues.safetyItem.safetyItemUniqueValueId,
      note: workOrderValues.safetyItem.note,
      geometry: workOrder.safetyItem?.geometry,
      locationId: workOrder.safetyItem.locationId,
    },
  }
}
