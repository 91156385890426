import { ReactNode } from 'react'
import get from 'lodash.get'
import { ColumnDef } from '@tanstack/react-table'

import { sortColumnByStringInsensitive } from './sort'

export const buildColumns = (columns: ColumnDef<any, ReactNode>[]): any[] =>
  columns.map(({ accessorKey, sortingFn, ...column }: any) => {
    const computedColumn = {
      ...column,
      sortingFn: sortingFn ? sortingFn : sortColumnByStringInsensitive,
    }

    if (accessorKey !== undefined) {
      computedColumn.accessorFn = (data: any) => {
        const value = get(data, accessorKey, '')

        if (value === undefined || value === null) {
          return ''
        }

        return value
      }
    }

    return computedColumn
  })
