import React, { useCallback, useRef, useMemo } from 'react'
import { HexColorPicker } from 'react-colorful'
import cn from 'classnames'

import { useClickOutside } from '../../../hooks/use-click-outside'
import { useModal } from '../../../hooks/use-modal'
import { Input } from '../input'

import s from './color-picker.module.scss'

const ColorPicker = props => {
  const { value, onChange, disabled, isViewMode } = props

  const { isOpen, closeModal, openModal } = useModal()

  const colorPaletteRef = useRef(null)
  useClickOutside(colorPaletteRef, closeModal)

  const previewStyles = useMemo(() => ({ backgroundColor: value }), [value])

  const handlePreviewClick = useCallback(() => {
    if (disabled) {
      return
    }
    openModal()
  }, [disabled, openModal])

  const pickerWrapperClassNames = cn(s['color-picker-wrapper'], {
    [s['color-picker-wrapper-disabled']]: disabled,
  })

  return (
    <div className={pickerWrapperClassNames} ref={colorPaletteRef}>
      <Input value={value} onChange={onChange} isViewMode={isViewMode} disabled={disabled} />

      <div className={s['color-preview']} style={previewStyles} onClick={handlePreviewClick} />

      {isOpen && !isViewMode && (
        <div className={s['color-palette-wrapper']}>
          <HexColorPicker color={value} onChange={onChange} />
        </div>
      )}
    </div>
  )
}

export default ColorPicker
