import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateTrainingSessionMutationRequest: IGetPutRequest = () => {
  return {
    method: 'put',
    url: `${config.baseUrl}/TrainingSessions/`,
  }
}

export default getUpdateTrainingSessionMutationRequest
