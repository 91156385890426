import { useMemo, useState, ReactElement, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import cn from 'classnames'

import {
  PageLayout,
  Button,
  safetyConcernValuesToSafetyConcernAddUpsert,
  showSuccessAlert,
  showErrorAlert,
  ISafetyConcernValues,
} from '../../../components'
import SafetyConcernBuilder from './safety-concern-builder'

import { useAppContext } from '../../../app-context'
import { useProfile } from '../../../security'
import { useCreateSafetyConcernMutation } from '../../../services'
import { claims, useAccessControl } from '../../../security'

import { IGeoJson } from '../../../interfaces'

const pageTitle = 'Create Safety Concern'

const CreateSafetyConcernPage = () => {
  const navigate = useNavigate()

  const { hasAccess } = useAccessControl()

  const hasSafetyConcernWriteAccess = useMemo(() => hasAccess([claims.safetyConcern.write]), [hasAccess])

  const { dispatch: appDispatch } = useAppContext()

  const { airportId } = useProfile()

  const location = useLocation()

  const [headerControls, setHeaderControls] = useState<ReactElement | undefined>(undefined)

  const [backSearch] = useState((location?.state as any)?.backSearch)

  const _headerControls = useMemo(
    () => (
      <div>
        <Button
          onClick={() => navigate({ pathname: '/safety-concern/safety-concern-list', search: backSearch })}
          className={cn(hasSafetyConcernWriteAccess && 'mr-2')}
          type="secondary"
        >
          Back To Safety Concerns
        </Button>

        {hasSafetyConcernWriteAccess && headerControls}
      </div>
    ),
    [hasSafetyConcernWriteAccess, headerControls, navigate, backSearch],
  )

  const { mutateAsync: createSafetyConcern, isLoading: isSafetyConcernCreating } = useCreateSafetyConcernMutation({})

  const [safetyConcernGeoJson, setSafetyConcernGeoJson] = useState<IGeoJson | undefined>()

  const handleCreateSafetyConcern = useCallback(
    async (safetyConcernValues: ISafetyConcernValues) => {
      let safetyConcernUpdateUpsert = safetyConcernValuesToSafetyConcernAddUpsert(safetyConcernValues, {
        geoJson: safetyConcernGeoJson,
        airportId,
      })

      if (!safetyConcernUpdateUpsert) {
        return
      }

      let createdSafetyConcern
      try {
        createdSafetyConcern = await createSafetyConcern(safetyConcernUpdateUpsert)
      } catch (err) {
        appDispatch(showErrorAlert('Error updating safety concern'))
        console.warn(err)
        return
      }

      appDispatch(showSuccessAlert('Safety concern successfully created'))
      if (createdSafetyConcern) {
        navigate(`/safety-concern/${createdSafetyConcern.id}`, { state: { backSearch }, replace: true })
      }
    },
    [appDispatch, createSafetyConcern, safetyConcernGeoJson, airportId, navigate, backSearch],
  )

  const [saveSafetyConcernForm, setSaveSafetyConcernForm] = useState<any>()

  const saveHeaderControls = useMemo(
    () => (
      <Button disabled={isSafetyConcernCreating} onClick={() => saveSafetyConcernForm?.submit()}>
        Create
      </Button>
    ),
    [isSafetyConcernCreating, saveSafetyConcernForm],
  )

  return (
    <PageLayout title={pageTitle} headerControls={_headerControls}>
      <SafetyConcernBuilder
        hasWriteAccess={hasSafetyConcernWriteAccess}
        setHeaderControls={setHeaderControls}
        handleSaveSafetyConcern={handleCreateSafetyConcern}
        saveHeaderControls={saveHeaderControls}
        setSaveSafetyConcernForm={setSaveSafetyConcernForm}
        setSafetyConcernGeoJson={setSafetyConcernGeoJson}
        safetyConcernGeoJson={safetyConcernGeoJson}
      />
    </PageLayout>
  )
}

export default CreateSafetyConcernPage
