import { LocalDocumentUploadState } from './document-upload-context-types'

export const addLocalDocumentUploadStateToState = (
  localDocumentUploadState?: LocalDocumentUploadState,
  localDocumentsUploadState?: LocalDocumentUploadState[],
): LocalDocumentUploadState[] => {
  if (!localDocumentUploadState) {
    if (!localDocumentsUploadState) {
      return []
    }

    return localDocumentsUploadState
  }

  if (!localDocumentsUploadState) {
    return [localDocumentUploadState]
  }

  return [localDocumentUploadState, ...localDocumentsUploadState]
}

export const updateLocalDocumentUploadStateToState = (
  localDocumentUploadState?: LocalDocumentUploadState,
  localDocumentsUploadState?: LocalDocumentUploadState[],
): LocalDocumentUploadState[] => {
  if (!localDocumentUploadState) {
    if (!localDocumentsUploadState) {
      return []
    }

    return localDocumentsUploadState
  }

  if (!localDocumentsUploadState) {
    return [localDocumentUploadState]
  }

  return localDocumentsUploadState.map(_localDocumentUploadState =>
    _localDocumentUploadState.localDocumentName === localDocumentUploadState.localDocumentName
      ? localDocumentUploadState
      : _localDocumentUploadState,
  )
}

export const removeLocalDocumentUploadStateToState = (
  localDocumentUploadState?: LocalDocumentUploadState,
  localDocumentsUploadState?: LocalDocumentUploadState[],
): LocalDocumentUploadState[] => {
  if (!localDocumentUploadState || !localDocumentsUploadState) {
    if (!localDocumentsUploadState) {
      return []
    }

    return localDocumentsUploadState
  }

  return localDocumentsUploadState.filter(
    _localDocumentUploadState =>
      _localDocumentUploadState.localDocumentName !== localDocumentUploadState.localDocumentName,
  )
}
