import React, { useCallback } from 'react'
import { bool, func, string } from 'prop-types'
import { Button, ButtonGroup } from 'react-bootstrap'

import mode from './map-mode'
import { getButtonGroupButtonVariant } from '../../util'

const Location = props => {
  const { hasSavedLocations, mapMode, setMapMode, disabled = false, hideSavedLocations = false } = props

  const changeMapMode = useCallback(
    (mode, target) => {
      if (mode !== mapMode) {
        setMapMode(mode) // only set the map mode state if the map mode has changed
      }

      if (target) {
        // we blur to avoid focus styling
        target.blur()
      }
    },
    [mapMode, setMapMode],
  )

  return (
    <>
      <h5>Location</h5>
      <div>Mark discrepancy on the map</div>
      <div className="d-flex align-items-baseline mt-2">
        <ButtonGroup>
          <Button
            variant={`${getButtonGroupButtonVariant(mapMode === mode.MARKER)}`}
            onClick={x => changeMapMode(mode.MARKER, x.target)}
            key={mode.MARKER}
            disabled={disabled}
          >
            Pin Location
          </Button>
          <Button
            variant={`${getButtonGroupButtonVariant(mapMode === mode.POLYGON)}`}
            onClick={x => changeMapMode(mode.POLYGON, x.target)}
            key={mode.POLYGON}
            disabled={disabled}
          >
            Select Area
          </Button>
          {!hideSavedLocations && (
            <Button
              variant={`${getButtonGroupButtonVariant(mapMode === mode.SAVED_LOCATION)}`}
              disabled={!hasSavedLocations || disabled}
              onClick={x => changeMapMode(mode.SAVED_LOCATION, x.target)}
              key={mode.SAVED_LOCATION}
            >
              Saved Location
            </Button>
          )}
          <Button
            variant={getButtonGroupButtonVariant(mapMode === mode.CLEAR)}
            onClick={x => changeMapMode(mode.CLEAR, x.target)}
            key={mode.CLEAR}
            disabled={disabled}
          >
            Clear Map
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
}

Location.defaultProps = {
  hasSavedLocations: false,
  hideSavedLocations: false,
  disabled: false,
  mapMode: mode.MARKER,
  setMapMode: () => {},
}

Location.propTypes = {
  hasSavedLocations: bool,
  hideSavedLocations: bool,
  disabled: bool,
  mapMode: string,
  setMapMode: func,
}

export default Location
