import { FC, useMemo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { List, Pagination, EmptyList, WorkOrderCard } from '../../../components'
import { IWorkOrder, IActionOption, IRefs } from '../../../interfaces'

type SafetyConcernWorkOrdersCardsListProps = {
  pagination: { pageSize: number; pageIndex: number }
  setPagination: (values: { [key: string]: number }) => void
  workOrders: IWorkOrder[] | undefined
  actionsButtonOptions?: IActionOption<IWorkOrder>[]
  workOrdersRefs: IRefs
  isLoading: boolean
  activeWorkOrderId?: number
}

const SafetyConcernWorkOrdersCardsList: FC<SafetyConcernWorkOrdersCardsListProps> = props => {
  const { workOrders, pagination, setPagination, actionsButtonOptions, isLoading, activeWorkOrderId, workOrdersRefs } =
    props

  const navigate = useNavigate()

  const handleNavigateToWorkOrder = useCallback(
    (workOrder: IWorkOrder) => navigate(`/work-orders/${workOrder.id}`),
    [navigate],
  )

  const totalItemsCount = workOrders?.length || 0

  const { pageIndex, pageSize } = pagination

  const setPageSize = useCallback((pageSize: number) => setPagination({ pageSize }), [setPagination])

  const setPageIndex = useCallback((pageIndex: number) => setPagination({ pageIndex }), [setPagination])

  const totalPageCount = useMemo(() => Math.ceil(totalItemsCount / pageSize), [totalItemsCount, pageSize])

  const workOrdersPage = useMemo(
    () => (workOrders || []).slice(pageIndex * pageSize, pageIndex * pageSize + pageSize),
    [workOrders, pageIndex, pageSize],
  )

  useEffect(() => {
    if (pageIndex > totalPageCount) {
      setPageIndex(totalPageCount)
    }
  }, [pageIndex, setPageIndex, totalPageCount])

  const canNextPage = pageIndex < totalPageCount

  const canPreviousPage = pageIndex > 0

  return (
    <>
      <div className="overflow-auto h-100">
        <List
          data={workOrdersPage}
          ListItem={WorkOrderCard}
          noDataContent={<EmptyList>No Safety Concern Work Orders found.</EmptyList>}
          actionsButtonOptions={actionsButtonOptions}
          onTitleClick={handleNavigateToWorkOrder}
          refs={workOrdersRefs}
          activeId={activeWorkOrderId}
          isLoading={isLoading}
        />
      </div>

      <Pagination
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItemsCount={totalItemsCount}
        totalPageCount={totalPageCount}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </>
  )
}

export default SafetyConcernWorkOrdersCardsList
