import cn from 'classnames'

import { Button } from '../../../components/button'

import s from '../drawer-header.module.scss'

type FaqDrawerHeaderProps = {
  onClose: () => any
}

const FaqDrawerHeader: React.FC<FaqDrawerHeaderProps> = ({ onClose }) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100 text-dark">
      <h4 className="mb-0">Help</h4>

      <div className="d-flex">
        <Button type="secondary-icon" className={`${s['close-btn']}`} onClick={onClose}>
          <i className={cn('bi bi-x', s['close-btn-icon'])} />
        </Button>
      </div>
    </div>
  )
}

FaqDrawerHeader.defaultProps = {
  onClose: () => {},
}

export default FaqDrawerHeader
