import { useMemo, useCallback, useState, useEffect } from 'react'

import { Modal } from '..'
import Table from '../table/table'
import { Button } from '../button'
import { DropdownButton } from '../dropdown-button'
import { rowSelectionColumn } from '../table'

import useCrudTable from './use-crud-table'
import useTableInstance from '../table/hooks/use-table-instance'
import useTableReorder from '../table/hooks/use-table-reorder'

import s from './crud-table.module.scss'

const defaultAddEntityBtnText = 'Add'

const defaultGetRowId = row => row.id

const CrudTable = props => {
  const {
    initialState,
    columns: propsColumns,
    className,
    tableWrapperClassName,
    entities,
    createSettings,
    readSettings,
    updateSettings,
    deleteSettings,
    EntityForm,
    addEntityBtnText: propsAddEntityBtnText,
    getRowId: _getRowId,
    getAdditionalOptions,
    mutateValues,
    renderAdditionalFilters,
    isActionsVisible,
    canReorder,
    reorder,
    isLoading,
    isError,
    isRowSelectionEnabled,
    isGlobalFilterEnabled,
    setSelectedRowsIds,
    filter,
    crudModalBodyClassName,
    validateForm,
  } = props

  const getRowId = useMemo(() => (_getRowId ? _getRowId : defaultGetRowId), [_getRowId])

  const { handleCreateEntityBtn, getActionBtnOptions, modalSettings, tableInstanceRef } = useCrudTable({
    entities,
    createSettings,
    readSettings,
    updateSettings,
    deleteSettings,
    getRowId,
    getAdditionalOptions,
    mutateValues,
  })

  const addEntityBtnText = useMemo(() => propsAddEntityBtnText || defaultAddEntityBtnText, [propsAddEntityBtnText])

  const [isReorder, setIsReorder] = useState(false)

  const actionsColumn = useMemo(
    () => ({
      header: 'Actions',
      disableGlobalFilter: true,
      cell: u => (
        <DropdownButton
          className={s['actions-button']}
          accessor={getRowId(u.cell.row.original)}
          options={getActionBtnOptions(u.cell.row.original)}
          disabled={getActionBtnOptions(u.cell.row.original)?.length === 0}
        />
      ),
      meta: {
        className: s['actions-column'],
        ignoreEllipsis: true,
      },
      sortable: false,
    }),
    [getActionBtnOptions, getRowId],
  )

  const columns = useMemo(
    () =>
      [
        isRowSelectionEnabled && rowSelectionColumn,
        ...propsColumns,
        isActionsVisible && !isReorder && actionsColumn,
      ].filter(Boolean),
    [isRowSelectionEnabled, propsColumns, isActionsVisible, isReorder, actionsColumn],
  )

  const { data, moveRow, setRowToEnd, setRowToBegin, setRowPosition } = useTableReorder(entities)

  const tableInstance = useTableInstance({
    columns,
    data,
    getRowId,
    initialState,
  })

  const [_tableState, _setTableState] = useState(tableInstance.initialState)

  tableInstance.setOptions(prev => ({
    ...prev,
    state: _tableState,
    onStateChange: _setTableState,
  }))

  useEffect(() => {
    if (!filter) {
      return
    }

    const columnFilters = Object.keys(filter || {}).map(key => ({
      id: key,
      value: filter[key],
    }))

    _setTableState(state => ({ ...state, columnFilters }))
  }, [_setTableState, filter])

  tableInstanceRef.current = tableInstance

  const { rowSelection } = tableInstance.getState()

  useEffect(() => {
    if (typeof setSelectedRowsIds === 'function') {
      const selectedRowIds = Object.keys(rowSelection)

      setSelectedRowsIds(selectedRowIds)
    }
  }, [rowSelection, setSelectedRowsIds])

  const renderReorder = useCallback(() => {
    if (isReorder) {
      return (
        <>
          <Button
            onClick={() => {
              setIsReorder(false)
              readSettings.readEntities()
            }}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            className="ml-2"
            onClick={() => {
              reorder(data.map(d => d.id))

              setIsReorder(false)
            }}
          >
            Save
          </Button>
        </>
      )
    } else {
      return (
        <Button
          onClick={() => {
            tableInstance.resetSorting()
            tableInstance.setPageIndex(0)
            tableInstance.setGlobalFilter(undefined)

            setIsReorder(true)
          }}
          type="secondary"
        >
          Reorder
        </Button>
      )
    }
  }, [data, isReorder, reorder, readSettings, tableInstance])

  const _renderAddEntityComponent = useCallback(() => {
    const reorderControls = canReorder ? renderReorder() : null

    if (createSettings.hideCreateEntityBtn) {
      return reorderControls
    }

    if (createSettings.renderAddEntityComponent) {
      return createSettings.renderAddEntityComponent({ handleCreateEntityBtn, addEntityBtnText, renderReorder })
    }

    return (
      <>
        {reorderControls}

        {!isReorder && (
          <Button className="text-nowrap ml-2" onClick={handleCreateEntityBtn}>
            {addEntityBtnText}
          </Button>
        )}
      </>
    )
  }, [addEntityBtnText, handleCreateEntityBtn, createSettings, canReorder, renderReorder, isReorder])

  return (
    <>
      <Modal
        size={modalSettings.size}
        isOpen={modalSettings.isOpen}
        title={modalSettings.title}
        onClose={modalSettings.handleClose}
        isDisabled={modalSettings.isDisabled}
        cancelBtnText={modalSettings.cancelBtnText}
        confirmBtnText={modalSettings.confirmBtnText}
        formName={modalSettings.formName}
        onSubmit={modalSettings.handleSubmit}
        animation={false}
        bodyClassName={crudModalBodyClassName}
      >
        {Boolean(modalSettings.initialValues) && (
          <EntityForm
            initialValues={modalSettings.initialValues}
            onSubmit={modalSettings.handleSubmit}
            formName={modalSettings.formName}
            validate={validateForm}
          />
        )}
      </Modal>

      <Table
        className={className}
        tableWrapperClassName={tableWrapperClassName}
        tableInstance={tableInstance}
        actions={_renderAddEntityComponent()}
        renderAdditionalFilters={renderAdditionalFilters}
        reorderSettings={{ isRowsDraggable: isReorder, data, moveRow, setRowToEnd, setRowToBegin, setRowPosition }}
        isLoading={isLoading}
        isError={isError}
        isGlobalFilterEnabled={isGlobalFilterEnabled}
      />
    </>
  )
}

export const defaultProps = {
  createSettings: {
    hideCreateEntityBtn: false,
  },
  updateSettings: {
    hideUpdateEntityBtn: false,
  },
  deleteSettings: {
    hideDeleteEntityBtn: false,
  },
  getAdditionalOptions: () => [],
  mutateValues: (...values) => values[0],
  renderAdditionalFilters: () => {},
  isActionsVisible: true,
}

CrudTable.defaultProps = defaultProps

export default CrudTable
