import { useCallback, useMemo } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { useQuery } from '@tanstack/react-query'

import config from '../../config'
import { IFeatureFlags } from '../../interfaces'

const useAirportFeaturesQuery = ({ airportId, options = {} }: { airportId: number; options?: any }) => {
  const request = useMemo(
    () => ({
      method: 'get',
      url: `${config.baseUrl}/Airports/${airportId}/Features`,
    }),
    [airportId],
  ) as AxiosRequestConfig

  const fetchAirportFeature = useCallback(() => axios(request).then(res => res.data), [request])

  const queryKey = useMemo(() => ['airport-features', airportId], [airportId])

  const response = useQuery<IFeatureFlags | undefined, Error>(queryKey, fetchAirportFeature, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useAirportFeaturesQuery
