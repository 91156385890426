import config from '../../config'
import { EntityState } from '../entity-state'
import useAxios from '../use-axios'
import { removeNull } from '../../util'

const useWorkOrdersSearch = ({
  airportId,
  entityState = EntityState.Open,
  includeDetail = false,
  workOrderStartedAtBegin,
  workOrderStartedAtEnd,
  workOrderEndedAtBegin,
  workOrderEndedAtEnd,
}) => {
  const queryStringParams = [
    `entityState=${entityState}`,
    `includeDetail=${includeDetail}`,
    workOrderStartedAtBegin ? `workOrderStartedAtBegin=${workOrderStartedAtBegin}` : null,
    workOrderStartedAtEnd ? `workOrderStartedAtEnd=${workOrderStartedAtEnd}` : null,
    workOrderEndedAtBegin ? `workOrderEndedAtBegin=${workOrderEndedAtBegin}` : null,
    workOrderEndedAtEnd ? `workOrderEndedAtEnd=${workOrderEndedAtEnd}` : null,
  ].filter(removeNull)
  const queryString = queryStringParams.join('&')

  const url = `${config.baseUrl}/${airportId}/WorkOrders/Search?${queryString}`
  const request = { method: 'get', url }

  const { data = [], error, loading, refetch } = useAxios(request)

  return { data, error, loading, execute: refetch }
}

export default useWorkOrdersSearch
