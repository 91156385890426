import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import { useTagsQuery } from '../../../../services'
import { toOptions } from '../../../../util'

import { IActivityLogFilterUpsertValues } from './activity-log-filter-form-types'
import { TagCategory } from '../../../../interfaces'

const tagCategory = TagCategory.ActivityLog

const useActivityLogFilterQueries = (values?: IActivityLogFilterUpsertValues) => {
  const { airportId } = useProfile()

  const tagsQuery = useTagsQuery({ airportId }, { tagCategory })

  const tagsOptions = useMemo(() => toOptions(tagsQuery?.data), [tagsQuery?.data])

  return {
    tagsQuery,
    tagsOptions,
  }
}

export default useActivityLogFilterQueries
