import React from 'react'

import './background.css'

const NotFound = () => (
  <div className="background-container">
    <div className="background-contents jumbotron">
      <div className="display-3">Not found</div>
      <p className="mt-8 h5 text-muted">Unfortunately, App-139 has been routed to a page which does not exist.</p>
      <div className="mt-8">
        <a className="btn btn-primary" href="/">
          Return to the Home Page
        </a>
      </div>
    </div>
  </div>
)

export default NotFound
