import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getAddChecklistMutationRequest from './get-add-checklist-mutation-request'

import { IUseAddMutation, IAddFn } from '../../interfaces'
import { IChecklist, IChecklistUpsert } from '../../interfaces'

const useAddChecklistMutation: IUseAddMutation<IChecklist, IChecklistUpsert> = (params, options = {}) => {
  const request = useMemo(() => getAddChecklistMutationRequest(params), [params])

  const addChecklist: IAddFn<IChecklistUpsert, IChecklist> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklist, Error, any>({
    mutationFn: addChecklist,
    ...options,
  })

  return response
}

export default useAddChecklistMutation
