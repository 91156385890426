import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import {
  useAuthUsersSelectListQuery,
  useInspectionCategoriesQuery,
  useInspectionTypesQuery,
  useFacilitiesQuery,
  useSafetyItemResponses,
} from '../../../../services'
import { toOptions, capitalizeFirst } from '../../../../util'

import { IDiscrepancyFilterUpsertValues } from './discrepancy-filter-form-types'
import { ICondition, ILocationListItem } from '../../../../interfaces'

const useDiscrepancyFilterQueries = (values?: IDiscrepancyFilterUpsertValues) => {
  const { airportId } = useProfile()

  const inspectionCategoriesQuery = useInspectionCategoriesQuery(undefined, undefined)

  const inspectionCategoriesOptions = useMemo(
    () => toOptions(inspectionCategoriesQuery.data),
    [inspectionCategoriesQuery.data],
  )

  const authUsersQuery = useAuthUsersSelectListQuery({ airportId }, undefined)

  const authUsersOptions = useMemo(
    () => toOptions(authUsersQuery.data, { getLabel: user => capitalizeFirst(`${user.lastName}, ${user.firstName}`) }),
    [authUsersQuery.data],
  )

  const inspectionTypesQuery = useInspectionTypesQuery(
    { airportId },
    { activeOnly: true, inspectionCategoryIds: values?.inspectionCategoryIds || [] },
  )

  const inspectionTypesOptions = useMemo(
    () => toOptions(inspectionTypesQuery.data, { getLabel: inspectionType => inspectionType.baseName }),
    [inspectionTypesQuery.data],
  )

  const facilitiesQuery = useFacilitiesQuery(
    { airportId },
    { activeOnly: true, includeDetail: true },
    { refetchOnWindowFocus: false },
  )

  const facilitiesOptions = useMemo(() => toOptions(facilitiesQuery?.data), [facilitiesQuery?.data])

  const conditionsOptions = useMemo(() => {
    if (!Array.isArray(values?.facilityIds) || !Array.isArray(facilitiesQuery?.data)) {
      return []
    }

    const conditions = facilitiesQuery?.data
      .filter(facility => (values?.facilityIds || []).indexOf(facility.id) > -1)
      .reduce<ICondition[]>((conditions, facility) => [...conditions, ...facility.conditions], [])

    return conditions.map(condition => ({ label: condition.name, value: condition.id }))
  }, [facilitiesQuery, values?.facilityIds])

  const locationsOptions = useMemo(() => {
    if (!Array.isArray(values?.facilityIds) || !Array.isArray(facilitiesQuery.data)) {
      return []
    }

    const locations = facilitiesQuery.data
      .filter(facility => (values?.facilityIds || []).indexOf(facility.id) > -1)
      .reduce<ILocationListItem[]>((locations, facility) => [...locations, ...facility.locations], [])

    return locations.map(location => ({ label: location.name, value: location.id }))
  }, [facilitiesQuery.data, values?.facilityIds])

  const { data: safetyItemResponses } = useSafetyItemResponses(
    { airportId },
    { activeOnly: true },
  )

  const responseTypeOptions = useMemo(() => {
    if(safetyItemResponses === undefined) return []

    const options = toOptions(safetyItemResponses)
    options.unshift({ label: 'All Defects', value: '-1' })
    return  options;
  }, [safetyItemResponses])


  return {
    authUsersQuery,
    authUsersOptions,

    inspectionCategoriesQuery,
    inspectionCategoriesOptions,

    inspectionTypesQuery,
    inspectionTypesOptions,

    facilitiesQuery,
    facilitiesOptions,
    conditionsOptions,

    locationsOptions,
    responseTypeOptions
  }
}

export default useDiscrepancyFilterQueries
