import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'

import { useWildlifeSpeciesMasterListQuery } from '../../../../services'

import { IAirportWildlifeSpeciesFormProps } from './airport-wildlife-species-form-types'

const AirportWildlifeSpeciesForm: FC<IAirportWildlifeSpeciesFormProps> = props => {
  const { handleSubmit, submitting, formName } = props

  const { data: wildlifeSpecies, isLoading: isWildlifeSpeciesLoading } = useWildlifeSpeciesMasterListQuery({})

  const wildlifeSpeciesOptions = useMemo(
    () =>
      (wildlifeSpecies || []).map(wildlifeSpecies => ({
        label: wildlifeSpecies.name,
        value: wildlifeSpecies.id,
      })),
    [wildlifeSpecies],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.DualList
        name="wildlifeSpeciesIds"
        label="Wildlife Species"
        unselectedTitle="Unselected Wildlife Species"
        selectedTitle="Selected Wildlife Species"
        options={wildlifeSpeciesOptions}
        disabled={submitting || isWildlifeSpeciesLoading}
      />
    </form>
  )
}

export default AirportWildlifeSpeciesForm
