import { useMemo } from 'react'

import { useQueryParams1 } from './use-query-params-1'

export const useSafeQueryParams = <T>(queryParamsToSafeQueryParams: (params?: { [key: string]: any }) => T) => {
  const { params, setParams, wasParamsDefined, clearParams } = useQueryParams1()

  const safeParams = useMemo<T>(() => queryParamsToSafeQueryParams(params), [params, queryParamsToSafeQueryParams])

  return { params: safeParams, setParams, wasParamsDefined, clearParams }
}
