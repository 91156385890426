import axios from 'axios'
import qs from 'qs'

import config from '../../config'

const updateDiscrepancy = async ({ airportId, inspectionId, request, safetyItemId, preserveWildlifeSighting = false }) => {
  try {
    const queryObject = {
      preserveWildlifeSighting,
    }
    const queryString = qs.stringify(queryObject, { addQueryPrefix: true })
    const url = `${config.baseUrl}/${airportId}/Inspections/${inspectionId}/Discrepancy/${safetyItemId}${queryString}`

    const response = await axios.put(url, request)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default updateDiscrepancy
