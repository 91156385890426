import { FC } from 'react'

import PopUpRow from './pop-up-row'

import { ISafetyItem } from '../../interfaces'

import s from './map-popups.module.scss'

type SafetyItemPopUpContentProps = {
  safetyItem?: ISafetyItem
  isStatusVisible?: boolean
}

const SafetyItemPopUpContent: FC<SafetyItemPopUpContentProps> = props => {
  const { safetyItem, isStatusVisible = true } = props

  return (
    <div className={s['safety-item-content']}>
      <PopUpRow label="Facility" value={safetyItem?.facilityName || ''} />

      {isStatusVisible && <PopUpRow label="Status" value={safetyItem?.statusName || ''} />}

      <PopUpRow label="Location" value={safetyItem?.location?.name || 'Adhoc'} />
    </div>
  )
}

export default SafetyItemPopUpContent
