import React, { ReactElement, ReactNode } from 'react'

import './page-layout.css'

type PageLayoutProps = {
  children?: ReactNode
  content?: ReactElement
  headerControls?: ReactElement
  subtitle?: string
  title: string | ReactNode
  titlePostfix?: string
}

// NOTE:  Since the markup is still simple, I opted to put it all here and not have to prop-drill the title/subtitle.
//        It would be possible to extract the title and header components.
const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  content,
  headerControls = null,
  subtitle = '',
  title,
  titlePostfix = null,
}) => {
  return (
    <div className="d-flex flex-column h-100 overflow-y-hidden px-4 pb-1">
      <div className="d-flex page-header">
        <h3 className="align-self-center font-weight-bold m-0 text-dark">
          {title}
          {subtitle && <small className="text-muted"> - {subtitle}</small>}
        </h3>
        <div className="align-self-center ml-2">{titlePostfix}</div>
        <div className="align-items-center d-flex ml-auto page-header-controls">{headerControls}</div>
      </div>

      <div className="d-flex flex-column flex-grow-1 overflow-y-auto mb-1">{children ? children : content}</div>
    </div>
  )
}

export default PageLayout
