import { FC, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'

import { Info } from '../../../info'
import RFFFields from '../../react-final-form-fields'
import ReminderForm from '../reminder-form/reminder-form'

import { useChecklistTemplatesQuery, useNotificationGroupsQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { getPlaceholderTextFromLabel } from '../../../../util'

import { IChecklistReminderFormProps } from './checklist-reminder-form-types'

import { REMINDER_GRACE_PERIOD_TOOLTIP } from '../../../../features/admin.constants'

const nameLabel = 'Reminder Name'
const descriptionLabel = 'Description'
const gracePeriodLabel = 'Grace Period (Days)'

const ChecklistReminderForm: FC<IChecklistReminderFormProps> = props => {
  const { handleSubmit, submitting, values, initialValues, formName } = props

  const { airportId } = useProfile()

  const { data: checklists, isFetching: isChecklistsLoading } = useChecklistTemplatesQuery({ airportId }, undefined)

  const checklistsOptions = useMemo(
    () => (checklists || []).map(checklist => ({ label: checklist.name, value: checklist.id })),
    [checklists],
  )

  const { data: notificationGroups, isFetching: isNotificationGroupsLoading } = useNotificationGroupsQuery(
    { airportId },
    { activeOnly: true },
  )

  const notificationGroupOptions = useMemo(() => {
    if (!notificationGroups?.length) {
      return []
    }

    return notificationGroups.map(notificationGroup => ({
      label: notificationGroup.name,
      value: notificationGroup.id,
    }))
  }, [notificationGroups])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input
        label={nameLabel}
        name="reminder.name"
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        options={checklistsOptions}
        disabled={submitting || isChecklistsLoading}
      />

      <RFFFields.Textarea
        name="reminder.description"
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        maxLength={250}
        disabled={submitting}
      />

      <Row>
        <Col>
          <RFFFields.Select
            label="Checklist"
            name="checklistId"
            placeholder="Select from the list"
            options={checklistsOptions}
            disabled={submitting}
          />
        </Col>

        <Col>
          <RFFFields.Select
            name="reminder.notificationGroupIds"
            label="Departments"
            placeholder="Select from the list"
            options={notificationGroupOptions}
            disabled={submitting || isNotificationGroupsLoading}
            isMulti
          />
        </Col>
      </Row>

      <ReminderForm
        values={values}
        submitting={submitting}
        initialValues={initialValues}
        frequencyLabel="Reminder Frequency"
      />

      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <RFFFields.MaskedInput
              wrapperClassName="w-100"
              name="reminder.gracePeriodInDays"
              label={gracePeriodLabel}
              placeholder="0"
              disabled={submitting}
              type="number"
            />

            <Info className="ml-4" info={REMINDER_GRACE_PERIOD_TOOLTIP} />
          </div>
        </Col>

        <Col>
          <RFFFields.Switch name="reminder.isActive" label="Active" onLabel="yes" offLabel="no" disabled={submitting} />
        </Col>
      </Row>
    </form>
  )
}

export default ChecklistReminderForm
