import { FC, useMemo, useRef } from 'react'
import { Marker as GoogleMarker, InfoWindow } from '@react-google-maps/api'

import { useModal } from '../../hooks'

import { IEditableMarkerProps } from './editable-marker-types'

const EditableMarker: FC<IEditableMarkerProps> = props => {
  const { geoJson, icon, position, onUpdate, renderEditPopUp, clusterer } = props

  const infoWindowOptions = useMemo(() => ({ pixelOffset: new window.google.maps.Size(0, -40) }), [])

  const { isOpen: isEditableInfoOpen, openModal: openEditableInfo, closeModal: closeEditableInfo } = useModal()

  const markerRef = useRef<google.maps.Marker | null>()

  const onMarkerChange = (e: google.maps.MapMouseEvent) => {
    if (!onUpdate) {
      return
    }

    const ltlng = e.latLng

    if (ltlng?.lng && ltlng?.lat) {
      const coordinates = [ltlng?.lng(), ltlng?.lat()]

      onUpdate({
        ...geoJson,
        geometry: {
          ...geoJson.geometry,
          coordinates,
        },
      })
    }
  }

  return (
    <>
      {isEditableInfoOpen && (
        <InfoWindow
          position={{ lat: position.lat, lng: position.lng }}
          onCloseClick={closeEditableInfo}
          options={infoWindowOptions}
        >
          {renderEditPopUp && renderEditPopUp(geoJson)}
        </InfoWindow>
      )}

      <GoogleMarker
        icon={icon}
        onClick={openEditableInfo}
        position={position}
        clusterer={clusterer}
        onDragStart={closeEditableInfo}
        onDragEnd={onMarkerChange}
        onLoad={marker => (markerRef.current = marker)}
        clickable={openEditableInfo !== undefined}
        draggable
      />
    </>
  )
}

export default EditableMarker
