import { useCallback } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getToggleLocationMutationRequest from './get-toggle-location-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { ILocation } from '../../interfaces'

const useToggleLocationMutation: IUseUpdateMutation<ILocation, number> = (params, options = {}) => {
  const toggleLocation: IUpdateFn<number, ILocation> = useCallback(
    async (id: number) => axios({ ...getToggleLocationMutationRequest({ id, ...params }) }).then(res => res.data),
    [params],
  )

  const response = useMutation<ILocation, Error, number>({ mutationFn: toggleLocation, ...options })

  return response
}

export default useToggleLocationMutation
