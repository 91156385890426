import { FC, useMemo } from 'react'
import { Cell as ReactTableCell, flexRender } from '@tanstack/react-table'
import cn from 'classnames'

import s from '../table.module.scss'

interface ICellProps {
  cell: ReactTableCell<any, any>
}

const Cell: FC<ICellProps> = props => {
  const { cell } = props

  const columnClassName = cell?.column?.columnDef?.meta?.className || ''

  const ignoreEllipsis = cell?.column?.columnDef?.meta?.ignoreEllipsis || false

  const grow = cell?.column?.columnDef?.meta?.grow || 1

  const tdClassNames = cn(s['td'], columnClassName, {
    [s['td-ellipsis']]: !ignoreEllipsis,
  })

  const contentClassNames = cn(s['td-content'], {
    [s['td-content-ellipsis']]: !ignoreEllipsis,
  })

  const tdStyle = useMemo(
    () => ({
      flexGrow: grow,
    }),
    [grow],
  )

  return (
    <td className={tdClassNames} style={tdStyle}>
      <div className={contentClassNames}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
    </td>
  )
}

export default Cell
