import { Children } from 'react'

export function iterateReactChildren(
  element: any,
  isWithinBody: boolean = false,
  accumulator: any[] = [],
  stylesAccumulator: any[] = [],
) {
  if (!element) return { content: accumulator, styles: stylesAccumulator }

  // Base case: if the element is a string or number, it's just content.
  if (typeof element === 'string' || typeof element === 'number') {
    return { content: accumulator, styles: stylesAccumulator }
  }

  // Extract styles from the 'head' tag
  if (element.type === 'head') {
    Children.forEach(element.props.children, child => {
      if (child.type === 'style' || (child.type === 'link' && child.props.rel === 'stylesheet')) {
        stylesAccumulator.push(child)
      }
    })
  }

  // Check if the current element is a 'body'.
  if (element.type === 'body') {
    isWithinBody = true
  }

  // If within the body and the element is a div with children, add it to the accumulator.
  if (isWithinBody && element.type === 'div' && Children.count(element.props.children) > 1) {
    accumulator.push(element)
    // We've added the div to the accumulator; now avoid processing its children for div accumulation.
    return { content: accumulator, styles: stylesAccumulator }
  }

  // If the element has props and props.children, recursively process the children.
  if (element.props && element.props.children) {
    Children.forEach(element.props.children, child => {
      const result = iterateReactChildren(child, isWithinBody, accumulator, stylesAccumulator)
      accumulator = result.content
      stylesAccumulator = result.styles
    })
  }

  return { content: accumulator, styles: stylesAccumulator }
}
