import { useMemo } from 'react'
import qs from 'qs'
import { QueryObserverOptions } from '@tanstack/react-query'

import { getShortName } from '../inspections/inspection-type-util'
import config from '../../config'
import { buildGetQuery, byNameAscending } from '../../util'

import { IInspectionType, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  inspectionCategoryIds?: number[]
  includeDetail?: boolean
  activeOnly?: boolean
}

const getInspectionTypesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${params.airportId}/InspectionTypes${queryString}`,
  }
}

const useInspectionTypesQueryBase = buildGetQuery<Params, QueryObject | undefined, IInspectionType[] | undefined>({
  getRequest: getInspectionTypesRequest,
  getQueryKey: (params, queryObject) => [
    'inspection-types',
    params.airportId,
    String(queryObject?.inspectionCategoryIds),
    queryObject?.includeDetail,
    queryObject?.activeOnly,
  ],
  defaultOptions: { refetchOnWindowFocus: false, keepPreviousData: true },
})

const useInspectionTypesQuery = (
  params: Params,
  queryObject: QueryObject | undefined,
  options?: QueryObserverOptions<IInspectionType[] | undefined, Error>,
  applySortByName?: boolean,
) => {
  const { data, ...restResponse } = useInspectionTypesQueryBase(params, queryObject, options)

  const formattedData = useMemo(() => {
    const formattedDate = (data || []).map(inspectionType => {
      const shortName = getShortName(inspectionType.inspectionCategory)

      return {
        ...inspectionType,
        baseName: inspectionType.name,
        name: inspectionType?.name?.startsWith(shortName)
          ? inspectionType.name
          : `${shortName} - ${inspectionType.name}`,
      }
    })

    if (applySortByName) {
      return formattedDate.sort(byNameAscending)
    }

    return formattedDate
  }, [data, applySortByName])

  return { data: formattedData, ...restResponse }
}

export default useInspectionTypesQuery
