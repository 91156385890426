import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateChecklistTemplateMutationRequest: IGetPutRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Checklists/templates`,
  }
}

export default getUpdateChecklistTemplateMutationRequest
