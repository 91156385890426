export const getValueByNode = (values, node) => values.find(value => value.value === node.value)

export const defaultFilterNodes = (nodes, filter) => {
  if (!filter.globalSearch) {
    return nodes
  }

  return nodes.reduce((filteredNodes, node) => {
    if (node.children) {
      const filteredNodeChildren = defaultFilterNodes(node.children, filter)

      if (filteredNodeChildren.length > 0) {
        filteredNodes = [...filteredNodes, { ...node, children: filteredNodeChildren }]
        return filteredNodes
      }
    }

    return node.label.toLowerCase().search(filter.globalSearch.toLowerCase()) > -1
      ? [...filteredNodes, node]
      : filteredNodes
  }, [])
}
