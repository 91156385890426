import { useCallback, useContext, useEffect, useRef } from 'react'
import { components } from 'react-select'

import { useWindowEvent } from '../../../../hooks/use-window-event'
import { MultiSelectContext } from '../multi-select-context'

const Control = props => {
  const controlRef = useRef()
  const { dispatch } = useContext(MultiSelectContext)

  const setContextControlWidth = useCallback(() => {
    if (controlRef.current) {
      dispatch({ type: 'setControlWidth', payload: controlRef.current.clientWidth })
    }
  }, [controlRef, dispatch])

  useWindowEvent('resize', () => {
    setContextControlWidth()
  })

  useEffect(() => {
    setContextControlWidth()
  }, [setContextControlWidth])

  return (
    <div ref={controlRef}>
      <components.Control {...props}></components.Control>
    </div>
  )
}

export default Control
