import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getInspectionTypeByInspectionIdRequest from './get-inspection-type-by-inspection-id'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IInspectionType } from '../../interfaces'

const useInspectionTypeByInspectionIdQuery: IUseGetQuery<any, IInspectionType[]> = (
  params,
  queryObject = {},
  options = {},
) => {
  const request = useMemo(() => getInspectionTypeByInspectionIdRequest(params, queryObject), [params, queryObject])

  const fetchInspectionType: IGetFn<IInspectionType[]> = useCallback(
    () => axios(request).then(res => res.data),
    [request],
  )

  const queryKey = useMemo(
    () => ['inspection-type-by-inspection-id', params.airportId, params.id, queryObject],
    [params.airportId, params.id, queryObject],
  )

  const response = useQuery<IInspectionType[] | undefined, Error>(queryKey, fetchInspectionType, {
    initialData: [],
    ...options,
  })

  return { ...response, queryKey }
}

export default useInspectionTypeByInspectionIdQuery
