import React from 'react'

import RFFFields from '../../react-final-form-fields'

import { RowDefinitionFormProps } from './checklist-row-definition-types'

const RowDefinitionForm: React.FC<RowDefinitionFormProps> = ({
  handleSubmit,
  submitting,
  formName,
  values,
  initialValues,
}) => {
  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input label="Amount of rows" type="number" name="rowsAmount" />
    </form>
  )
}

export default RowDefinitionForm
