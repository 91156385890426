import config from '../../config'

import { IGetSortInspectionSubtypesMutationRequest } from './get-sort-inspection-subtypes-mutation-request-types'

const getSortInspectionSubtypesMutationRequest: IGetSortInspectionSubtypesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/InspectionSubtypes/Sort`,
  }
}

export default getSortInspectionSubtypesMutationRequest
