import { FC } from 'react'

import RFFFields from '../../react-final-form-fields'
import { validateRequired, validateMaxSymbols, composeValidators } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'

import { SafetyConcernStatusFormFieldsProps, ISafetyConcernStatusValues } from './safety-concern-status-form-types'
import { options } from '../../../../util'

const validateMaxSymbols500 = validateMaxSymbols(500, 'Notes must not exceed 500 characters')
const validateMaxSymbols100 = validateMaxSymbols(100, 'Action must not exceed 100 characters')

const nameof = nameofFactory<ISafetyConcernStatusValues>()

const nameLabel = 'Name'
const descriptionLabel = 'Description'
const baseStatusLabel = 'Base Status'
const sortOrderLabel = 'Sort Order'
const activeLabel = 'Active'

const SafetyConcernStatusFormFields: FC<SafetyConcernStatusFormFieldsProps> = props => {
  const { disabled, isIsActiveDisabled } = props

  return (
    <>
      <RFFFields.Input
        name={nameof('name')}
        label={nameLabel}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        disabled={disabled}
        validate={composeValidators(validateRequired, validateMaxSymbols100)}
      />

      <RFFFields.Textarea
        name={nameof('description')}
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        disabled={disabled}
        validate={validateMaxSymbols500}
      />

      <RFFFields.Select
        name={nameof('baseStatus')}
        label={baseStatusLabel}
        placeholder={getPlaceholderTextFromLabel(baseStatusLabel)}
        validate={validateRequired}
        options={options.baseStatus.filter(baseStatus => baseStatus.value > 0)}
        disabled
      />

      <RFFFields.MaskedInput
        type="number"
        name={nameof('sortOrder')}
        label={sortOrderLabel}
        placeholder={getPlaceholderTextFromLabel(sortOrderLabel)}
        disabled={disabled}
      />

      <RFFFields.Switch
        name="isActive"
        label={activeLabel}
        onLabel="yes"
        offLabel="no"
        disabled={disabled || isIsActiveDisabled}
      />
    </>
  )
}

export default SafetyConcernStatusFormFields
