// NOTE:  This code could be adjusted to be localStorage instead via a param or a different file.
const storage = window.sessionStorage

const getItem = (key, defaultValue = null) => {
  const data = storage.getItem(key)

  if (data === 'undefined') {
    return defaultValue
  }

  return data ? JSON.parse(data) : defaultValue
}

const removeItem = key => storage.removeItem(key)

const removeItems = (keys = []) => keys.map(key => removeItem(key))

const setItem = (key, item) => {
  const serializedState = JSON.stringify(item)
  storage.setItem(key, serializedState)
}

const storageUtil = { getItem, removeItem, removeItems, setItem }

export default storageUtil
