import { FC } from 'react'
import cn from 'classnames'

import { Button } from '../../button'
import { IDocument } from '../../../interfaces'
import { useModal } from '../../../hooks'
import { formatBytes } from '../../../util'
import DocumentsPreviewModal from './documents-preview-modal'

// @ts-ignore
import s from './documents-list.module.scss'

type DocumentListItemProps = {
  nameClassName?: string
  document: IDocument
  removeDocument?: () => void
  isDocumentDownloading?: boolean
  downloadDocument: () => void
  disabled?: boolean
}

const DocumentListItem: FC<DocumentListItemProps> = props => {
  const {
    document,
    removeDocument,
    disabled = false,
    isDocumentDownloading = false,
    downloadDocument,
    nameClassName,
  } = props

  const {
    isOpen: isPreviewModalOpen,
    closeModal: closePreviewReportModal,
    openModal: openPreviewReportModal,
  } = useModal()

  return (
    <div className={cn(s['document'])} key={`document-${document.name}`}>
      <div className={cn(s['document-name'], nameClassName)}>{document.name}</div>

      <div className="d-flex align-items-center">
        {document?.size && <div className={s['document-size']}>{formatBytes(document.size)}</div>}

        <Button
          className={cn(s['document-action'], s['document-action-download'])}
          type="ghost-icon"
          buttonType="button"
          onClick={downloadDocument}
          loading={isDocumentDownloading}
          disabled={disabled || isDocumentDownloading}
        >
          <i className="bi bi-download text-dark-75" />
        </Button>

        <Button
          className={cn(s['document-action'], s['document-action-download'])}
          type="ghost-icon"
          buttonType="button"
          onClick={() => openPreviewReportModal()}
          loading={isDocumentDownloading}
          disabled={disabled}
        >
          <i className="bi bi-eye text-dark-75" />
        </Button>

        {removeDocument && (
          <Button
            className={s['document-action']}
            type="ghost-icon"
            buttonType="button"
            onClick={removeDocument}
            disabled={disabled}
          >
            <i className="bi bi-x text-dark-75" />
          </Button>
        )}
      </div>
      <DocumentsPreviewModal
        documents={[document]}
        currentCarouselDocument={document}
        parentType={document.parentType}
        isDocumentsPreviewModalOpen={isPreviewModalOpen}
        toggleDocumentsPreviewModal={() => {
          isPreviewModalOpen ? closePreviewReportModal() : openPreviewReportModal()
        }}
      />
    </div>
  )
}

export default DocumentListItem
