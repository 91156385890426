import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'

import { getPlaceholderTextFromLabel } from '../../../../util'
import { validateRequired } from '../../../../util/validation'

import { ITagFormProps } from './tag-form-types'

export const tagCategories = [
  { name: 'Journal', id: 1 },
  // { name: 'Location', id: 2 },
  { name: 'Maintenance Log', id: 3 },
  { name: 'Facility', id: 4 },
  { name: 'Condition', id: 5 },
]

const nameLabel = 'Name'

const TagForm: FC<ITagFormProps> = ({ handleSubmit, submitting, formName, values }) => {
  const tagCategoryOptions = useMemo(
    () => tagCategories.map(tagCategory => ({ label: tagCategory.name, value: tagCategory.id })),
    [],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input
        name="name"
        label={nameLabel}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        disabled={submitting}
        validate={validateRequired}
      />

      <RFFFields.Select
        name="tagCategory"
        label="Category"
        options={tagCategoryOptions}
        validate={validateRequired}
        disabled={values.id}
      />
    </form>
  )
}

export default TagForm
