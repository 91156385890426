import { FC, MouseEvent, useCallback } from 'react'

import { IUploaderFilePreview } from './uploader-file-preview-types'
import { formatBytes } from '../../../util'

import s from './uploader-file-preview.module.scss'

const UploaderFilePreview: FC<IUploaderFilePreview> = props => {
  const { file, onRemove } = props

  const _onRemove = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      if (onRemove) {
        e.preventDefault()
        e.stopPropagation()

        onRemove(file)
      }

      return
    },
    [onRemove, file],
  )

  return (
    <>
      <div className={s['uploader-file-preview']}>
        <span className={s['uploader-file-preview-label']}>{formatBytes(file?.size)}</span>
        <span className={s['uploader-file-preview-label']}>{file?.name}</span>
      </div>

      {_onRemove && (
        <span onClick={_onRemove} className={s['uploader-file-preview-remove-btn']}>
          Remove File
        </span>
      )}
    </>
  )
}

export default UploaderFilePreview
