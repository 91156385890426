import { FC } from 'react'
import { Link } from 'react-router-dom'

import { IWorkOrderCell } from './work-order-cell-types'

import s from './work-order-cell.module.scss'

const WorkOrderCell: FC<IWorkOrderCell> = props => (
  <div className={s['span-cell']}>{props.value && <Link to={`/work-orders/${props.value}`}>{props.value}</Link>}</div>
)

export default WorkOrderCell
