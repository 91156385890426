import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPostRequest,
  IUseAddMutation,
  IAddFn,
  ISavedSearchFilterReminder,
  ISavedSearchFilterReminderAddUpsert,
} from '../../interfaces'

const getCreateSavedSearchFilterReminderMutationRequest: IGetPostRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Reminders/SavedSearch`,
  }
}

const useCreateSavedSearchFilterReminderMutation: IUseAddMutation<
  ISavedSearchFilterReminder,
  ISavedSearchFilterReminderAddUpsert
> = (params, options = {}) => {
  const request = useMemo(() => getCreateSavedSearchFilterReminderMutationRequest(params), [params])

  const createSavedSearchFilterReminder: IAddFn<ISavedSearchFilterReminderAddUpsert, ISavedSearchFilterReminder> =
    useCallback(async data => axios({ ...request, data }).then(res => res.data), [request])

  const response = useMutation<ISavedSearchFilterReminder, Error, ISavedSearchFilterReminderAddUpsert>({
    mutationFn: createSavedSearchFilterReminder,
    ...options,
  })

  return response
}

export default useCreateSavedSearchFilterReminderMutation
