export const formatErrors = errors => {
  try {
    return Object.keys(errors).reduce((acc, key) => {
      acc[key] = errors[key][0]
      return acc
    }, {})
  } catch (err) {
    console.error('Error while formating BE errors', err)
  }

  return {}
}

export const isLoading = x => (x || []).some(x => x.loading || x.isLoading)

export const getErrors = x => (x || []).map(x => x.error).filter(x => x)

export const buildErrorMessage = (message, errors = []) => `${message}\n${errors.join('\n')}`
