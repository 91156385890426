import { useQuery } from '@tanstack/react-query'

import config from '../../config'
import { EntityState } from '../entity-state'
import enrichInspection from './enrich-inspection'
import request from '../request'

const useInspections2 = ({ airportId, entityState = EntityState.Open, includeDetail = false }) => {
  const {
    data,
    isLoading: loading,
    error,
    refetch,
  } = useQuery(['inspections', entityState, includeDetail], async () => {
    const response = await request({
      method: 'get',
      url: `${config.baseUrl}/${airportId}/Inspections?entityState=${entityState}&includeDetail=${includeDetail}`,
    })
    response?.forEach(inspection => enrichInspection(inspection))
    return response
  })

  return { data, error, loading, execute: refetch }
}

export default useInspections2
