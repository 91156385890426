import config from '../../config'
import { buildGetQuery } from '../../util'

import { IAirport, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

const getAirportByIdRequest = (params: Params): IGetRequestObject => {
  const { airportId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/Airports/${airportId}`,
  }
}

const useAirportByIdQuery = buildGetQuery<Params, undefined, IAirport | undefined>({
  getRequest: getAirportByIdRequest,
  getQueryKey: params => ['airport-by-id', params.airportId],
  defaultOptions: {
    initialData: undefined,
  },
})

export default useAirportByIdQuery
