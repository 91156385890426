import React, { useCallback } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../components/button'

import s from '../drawer-header.module.scss'

type ProfileDrawerHeaderProps = {
  editProfileClick: () => any
  onClose: () => any
}

const ProfileDrawerHeader: React.FC<ProfileDrawerHeaderProps> = ({ editProfileClick, onClose }) => {
  const navigate = useNavigate()

  const handleEditProfileBtn = useCallback(() => {
    editProfileClick()
    navigate('/profile', { replace: true })
    onClose()
  }, [editProfileClick, navigate, onClose])

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <h4 className="mb-0">User Profile</h4>

      <div className="d-flex">
        <Button type="link" className="mr-1" onClick={handleEditProfileBtn}>
          Edit Profile
        </Button>
        <Button type="secondary-icon" className={`${s['close-btn']}`} onClick={onClose}>
          <i className={cn('bi bi-x', s['close-btn-icon'])} />
        </Button>
      </div>
    </div>
  )
}

ProfileDrawerHeader.defaultProps = {
  editProfileClick: () => {},
  onClose: () => {},
}

export default ProfileDrawerHeader
