import { toInt } from './util'

const ONE_SECOND = 1000

// parse the polling interval; if it is NaN, default to 30 seconds
const notificationPollingInterval = toInt(process.env.REACT_APP_NOTIFICATION_POLLING_INTERVAL, 30 * ONE_SECOND)

const config = {
  alert: {
    timeoutInMilliseconds: 3 * ONE_SECOND,
  },
  appInsights: {
    connectionString: process.env.REACT_APP_AZURE_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    maxBatchInterval: 0,
    disableFetchTracking: false,
    isBrowserLinkTrackingEnabled: true,
    samplingPercentage: toInt(process.env.REACT_APP_AZURE_INSIGHTS_SAMPLING_PERCENTAGE, 0),
  },
  baseUrl: process.env.REACT_APP_API_BASEURL,
  dateAndTimeFormats: {
    date: 'yyyy-MM-dd',
    time: "HH:mm:ss'.'SSSSSSS",
    dateAndTime: `yyyy-MM-dd'T'HH:mm:ss'.'SSSSSSS`,
    visualDate: 'MM/dd/yyyy',
    visualTime: 'hh:mm',
    visualTimeFull: `hh:mm aa`,
    visualDateAndTime: 'MM/dd/yyyy hh:mm aa',
  },
  debugMode: process.env.REACT_APP_DEBUG_MODE === 'true',
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['drawing', 'geometry'],
  },
  notifications: {
    pollingIntervalInMilliseconds: Math.max(notificationPollingInterval, 5 * ONE_SECOND), // NEVER let the polling go below 5 seconds to prevent self denial-of-service
  },
  oidc: {
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
    response_type: 'code',
    scope: 'openid profile email airfield-safety-api offline_access',
  },
  publicUrl: process.env.REACT_APP_PUBLIC_URL,
  routes: [
    {
      label: 'Inspections',
      url: '/inspections',
      allowedClaims: ['inspection-read', 'inspection-write'],
      showInMenu: true,
    },
    {
      label: 'Work Orders',
      url: '/work-orders',
      allowedClaims: ['workorder-read', 'workorder-write'],
      showInMenu: true,
    },
    { label: 'Checklists', url: '/checklists', allowedClaims: '*', showInMenu: true },
    {
      label: 'Safety Concerns',
      url: '/safety-concern',
      allowedClaims: ['safetyconcern-read', 'safetyconcern-write'],
      showInMenu: true,
    },
    { label: 'Reports', url: '/reports', allowedClaims: ['report-read'], showInMenu: true },
    { label: 'Training', url: '/trainings', allowedClaims: '*', showInMenu: true },
    { label: 'Journal', url: '/journal', allowedClaims: ['journal-read', 'journal-write'], showInMenu: true },
    { label: 'Search', url: '/search', allowedClaims: '*', showInMenu: true },
    { label: 'Admin', url: '/admin', allowedClaims: ['admin-read', 'admin-write'], showInMenu: true },
    { label: 'Hero', url: '/hero', allowedClaims: ['hero-read', 'hero-write'], showInMenu: true },
    { label: '', url: '/faq', allowedClaims: '*', showInMenu: false },
    { label: '', url: '/profile', allowedClaims: '*', showInMenu: false },
  ],
  savedPageStateKey: 'app-139-saved-page-state',
  messageOfTheDaySettingsKey: 'app-139-message-of-the-day',
}

export default config
