import { useMemo } from 'react'

import {
  Table,
  TableOptions,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
  Row,
  // FilterFn,
} from '@tanstack/react-table'
import { toDate } from 'date-fns-tz'
import { endOfDay, startOfDay } from 'date-fns'

import { clearUndefinedProperties, buildColumns } from '../../../util'
import { useAppContext } from '../../../app-context'

const useTableInstance = <T = any>(props: any): Table<T> => {
  const { state } = useAppContext()
  const timeZoneIanaId = state.airport.timeZoneIanaId

  const {
    columns,
    data,
    manualPagination,
    pageCount,
    getRowId = (row: any) => row.id,
    manualSorting,
    autoResetSortBy,
    disableSortBy,
    initialState,
  } = props

  const computedColumns = useMemo(
    () =>
      buildColumns(
        (columns || []).map((column: ColumnDef<any>) => ({
          ...column,
          sortDescFirst: false,
        })),
      ),
    [columns],
  )

  const tableSettings: TableOptions<any> = useMemo(
    () =>
      clearUndefinedProperties({
        columns: computedColumns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        pageCount,
        manualPagination,
        getRowId: getRowId,
        autoResetPage: false,
        autoResetSelectedRows: false,
        autoResetFilters: false,
        autoResetExpanded: false,
        manualSorting,
        autoResetSortBy,
        disableSortBy,
        initialState,
        filterFns: {
          dateRange: (row: Row<any>, columnId: string, filter?: string[]) => {
            const date: string = row.getValue(columnId)

            if (!date) {
              return false
            }

            const rowDate = new Date(date)

            let startDate, endDate

            // Check if filter is defined and its first element is present for startDate
            if (filter && filter[0]) {
              startDate = startOfDay(toDate(new Date(filter[0]), { timeZone: timeZoneIanaId }))
            }

            // Check if filter is defined and its second element is present for endDate
            if (filter && filter[1]) {
              endDate = endOfDay(toDate(new Date(filter[1]), { timeZone: timeZoneIanaId }))
            }

            // Check against startDate, if defined
            if (startDate && rowDate.getTime() < startDate.getTime()) {
              return false
            }

            // Check against endDate, if defined
            if (endDate && rowDate.getTime() > endDate.getTime()) {
              return false
            }

            return true
          },
          // Used instead of arrIncludesSome for number values
          isIncludedInFilterArray: (row: Row<any>, columnId: string, filterValue: any[]) => {
            if (!filterValue || filterValue.length === 0) {
              return true
            }

            return filterValue.indexOf(row.getValue<any[]>(columnId)) > -1
          },
          booleanFilter: (row: Row<any>, columnId: string, includeAll?: boolean) => {
            // Extract the value from the row based on the columnId
            const value = row.getValue(columnId)

            // When includeAll is true, include all rows.
            // When includeAll is false or undefined, include the row only if its value is falsy.
            return includeAll || !value
          },
        },
      }),
    [
      computedColumns,
      data,
      manualPagination,
      getRowId,
      manualSorting,
      autoResetSortBy,
      pageCount,
      disableSortBy,
      initialState,
      timeZoneIanaId,
    ],
  )

  const tableInstance = useReactTable<T>(tableSettings)

  return tableInstance
}

export default useTableInstance
