import { IMergeGeoJson } from './merge-geo-json-types'

export const mergeGeoJson: IMergeGeoJson = (oldGeoJson, newGeoJson) => ({
  ...oldGeoJson,

  type: newGeoJson.type,

  geometry: {
    ...oldGeoJson.geometry,

    type: newGeoJson.geometry.type,
    coordinates: newGeoJson.geometry.coordinates,
  },
})

export default mergeGeoJson
