import axios from 'axios'

import config from '../../config'

const getLocationsByFacilityId = ({ airportId, facilityId, includeDetail = false }) => {
  const execute = async () => {
    const url = `${config.baseUrl}/${airportId}/Facilities/${facilityId}?includeDetail=${includeDetail}`
    const { data: facility = {} } = await axios.get(url)

    return facility.locations || []
  }

  if (airportId && facilityId) {
    return execute()
  }

  return []
}

export default getLocationsByFacilityId
