import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPutRequest,
  IUseUpdateMutation,
  IAddFn,
  ISafetyReportCustomization,
  ISafetyReportCustomizationAddUpsert,
} from '../../interfaces'

const getUpdateSafetyReportCustomizationMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/SafetyReportCustomization`,
})

const useUpdateSafetyReportCustomizationMutation: IUseUpdateMutation<
  ISafetyReportCustomization,
  ISafetyReportCustomizationAddUpsert
> = (params, options = {}) => {
  const request = useMemo(() => getUpdateSafetyReportCustomizationMutationRequest(params), [params])

  const updateSafetyReportCustomization: IAddFn<ISafetyReportCustomizationAddUpsert, ISafetyReportCustomization> =
    useCallback(async data => axios({ ...request, data }).then(res => res.data), [request])

  const response = useMutation<ISafetyReportCustomization, Error, ISafetyReportCustomizationAddUpsert>({
    mutationFn: updateSafetyReportCustomization,
    ...options,
  })

  return response
}

export default useUpdateSafetyReportCustomizationMutation
