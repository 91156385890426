import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import { getPlaceholderTextFromLabel } from '../../../../util'
import { getStartDateRangeValidator, getEndDateRangeValidator } from '../../../../util/validation'

import { useProfile } from '../../../../security'

import { IAuditInfoFormProps } from './audit-info-form-types'
import { useAuthUsersQuery } from '../../../../services'

const startedAtLabel = 'Started At'
const endedAtLabel = 'Ended At'
const startedByLabel = 'Started By'
const endedByLabel = 'Ended By'

const startedDateValidator = getStartDateRangeValidator('endedAt')
const endedDateValidator = getEndDateRangeValidator('startedAt')

const AuditInfoForm: FC<IAuditInfoFormProps> = props => {
  const { handleSubmit, submitting, formName, isEnded, values } = props

  const { airportId } = useProfile()

  const { data: authUsers } = useAuthUsersQuery({ airportId }, { activeOnly: true, includeDetail: true })

  const authUsersOptions = useMemo(
    () =>
      (authUsers || []).map(authUser => ({
        value: authUser.id,
        label: authUser.userName,
      })),
    [authUsers],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.DatePicker
        name="startedAt"
        label={startedAtLabel}
        placeholderText={getPlaceholderTextFromLabel(startedAtLabel)}
        validate={startedDateValidator}
        disabled={submitting}
      />

      <RFFFields.Select
        name="startedById"
        label={startedByLabel}
        options={authUsersOptions}
        placeholder={getPlaceholderTextFromLabel(startedByLabel)}
        disabled={submitting}
      />

      {isEnded && (
        <RFFFields.DatePicker
          name="endedAt"
          label={endedAtLabel}
          placeholderText={getPlaceholderTextFromLabel(endedAtLabel)}
          validate={endedDateValidator}
          disabled={submitting || !values.endedById}
        />
      )}

      {isEnded && (
        <RFFFields.Select
          name="endedById"
          label={endedByLabel}
          options={authUsersOptions}
          placeholder={getPlaceholderTextFromLabel(endedByLabel)}
          disabled={submitting || !values.endedById}
        />
      )}
    </form>
  )
}

export default AuditInfoForm
