import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IUseUpdateMutation, IGetFn } from '../../interfaces'

const getDownloadNotificationsCSVQueryRequest: any = (params: any) => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Notifications/Download`,
  }
}

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'activity-logs'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadNotificationsCSVMutation: IUseUpdateMutation<any, any> = (params: any, options = {}) => {
  const request = useMemo(() => getDownloadNotificationsCSVQueryRequest(params), [params])

  const fetchNotificationsCSVFile: IGetFn<Blob, any> | undefined = useCallback(
    async (data: any) =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchNotificationsCSVFile,
    ...options,
  })

  return response
}

export default useDownloadNotificationsCSVMutation
