import { FC } from 'react'
import cn from 'classnames'

import { Button } from '../../button'

import { LocalDocumentUploadState } from './document-upload-context-types'

import { formatBytes } from '../../../util'

// @ts-ignore
import s from './documents-list.module.scss'

type DocumentUploadListItemProps = {
  document: LocalDocumentUploadState
  reUploadDocument: () => void
  removeDocument: () => void
  disabled: boolean
}

const DocumentUploadListItem: FC<DocumentUploadListItemProps> = props => {
  const { document, removeDocument, reUploadDocument, disabled } = props

  const formattedName = document.localDocumentName?.slice(0, 40).toLowerCase()

  return (
    <>
      <div
        className={cn(s['document'], {
          [s['document-error']]: !document.isUploading && document.error,
          [s['document-uploading']]: document.isUploading,
        })}
        key={`document-${document.localDocumentName}`}
      >
        <div className={s['document-name']}>{formattedName}</div>

        <div className="d-flex align-items-center">
          <div className={s['document-size']}>{formatBytes(document.localDocument.size)}</div>

          {!document.isUploading && document.isValid && (
            <Button
              className={cn(s['document-action'], s['document-action-re-upload'])}
              type="ghost-icon"
              buttonType="button"
              onClick={reUploadDocument}
            >
              <i className="bi bi-arrow-clockwise text-dark-75" />
            </Button>
          )}

          <Button
            className={cn(s['document-action'], s['document-action-remove'])}
            type="ghost-icon"
            buttonType="button"
            onClick={removeDocument}
            disabled={disabled}
          >
            <i className="bi bi-x text-dark-75" />
          </Button>
        </div>
      </div>

      {document.error && !document.isUploading && <div className={s['document-error-message']}>{document.error}</div>}
    </>
  )
}

export default DocumentUploadListItem
