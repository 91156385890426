import { useEffect, useCallback, useRef } from 'react'

import { useAuthUsersLastViewedVersionByIdQuery } from './services'
import { useProfile } from './security'

import { actions } from './actions'

import versionData from './version.json'

import { IAuthUserLastViewedVersion } from './interfaces'
import { useAppContext } from './app-context'

type UseReleaseNotesConfig = {
  enabled?: boolean
}

export const useReleaseNotesConfig = (configProps: UseReleaseNotesConfig) => {
  const { enabled = true } = configProps

  const releaseVersionRef = useRef<string>()

  const { userId, airportId } = useProfile()
  const { dispatch } = useAppContext()

  const onFetchLastViewedVersionSuccess = useCallback(
    (lastViewedVersionData?: IAuthUserLastViewedVersion) => {
      let lastViewedVersion = lastViewedVersionData?.lastViewedVersion

      if (typeof lastViewedVersion !== 'string') {
        lastViewedVersion = '0.0.0'
      }

      dispatch(actions.releaseNotes.setLastViewedReleaseVersion({ lastViewedReleaseVersion: lastViewedVersion }))

      if ((lastViewedVersion as string) < (releaseVersionRef.current as string)) {
        dispatch(actions.releaseNotes.openReleaseNotesReminder())
      }
    },
    [dispatch],
  )

  const { refetch: fetchLastViewedVersion } = useAuthUsersLastViewedVersionByIdQuery(
    { airportId, id: userId },
    undefined,
    { refetchOnWindowFocus: false, onSuccess: onFetchLastViewedVersionSuccess, enabled },
  )

  useEffect(() => {
    if (!enabled) {
      return
    }

    // Do nothing if there is no version presented
    if (typeof versionData.version !== 'string') {
      console.warn('There is no release version presented')
      return
    }

    // Configure Current Release Version
    dispatch(actions.releaseNotes.setCurrentReleaseVersion({ currentReleaseVersion: versionData.version }))
    releaseVersionRef.current = versionData.version
  }, [dispatch, fetchLastViewedVersion, enabled])
}
