import { byAccessor } from '../../../util'

import { ISafetyConcern } from '../../../interfaces'

export type ISearchFilter = {
  searchText: string
  filterType?: string
  statusIds: number[]
  priorityIds: number[]
  sortBy?: number
  isSortByAsc: boolean
}

export const queryParamsToParams = (queryParams?: {
  [key: string]: any
}): {
  searchFilter: ISearchFilter
  pagination: { pageSize: number; pageIndex: number }
} => ({
  searchFilter: {
    priorityIds: queryParams?.searchFilter?.priorityIds || [],
    statusIds: queryParams?.searchFilter?.statusIds || [],
    filterType: queryParams?.searchFilter?.filterType || undefined,
    sortBy: queryParams?.searchFilter?.sortBy || 4,
    isSortByAsc: queryParams?.searchFilter?.isSortByAsc !== undefined ? queryParams?.searchFilter?.isSortByAsc : true,
    searchText: queryParams?.searchFilter?.searchText || '',
  },
  pagination: {
    pageSize: queryParams?.pagination?.pageSize || 10,
    pageIndex: queryParams?.pagination?.pageIndex || 0,
  },
})

export const filterSafetyConcern = (safetyConcerns: ISafetyConcern[] | undefined, searchFilter: ISearchFilter) => {
  let filteredSafetyConcerns = safetyConcerns || []

  const q = String(searchFilter.searchText).toLowerCase().trim()

  if (q.length > 0) {
    filteredSafetyConcerns = filteredSafetyConcerns.filter(
      safetyConcern =>
        safetyConcern.summary?.toLowerCase().includes(q) ||
        safetyConcern.description?.toLowerCase().includes(q) ||
        safetyConcern.priority?.name?.toLowerCase().includes(q) ||
        safetyConcern.parentStatus?.name?.toLowerCase().includes(q) ||
        safetyConcern.lastUpdatedByName?.toLowerCase().includes(q),
    )
  }

  if (searchFilter.statusIds?.length > 0) {
    filteredSafetyConcerns = filteredSafetyConcerns.filter(
      safetyConcern => searchFilter.statusIds.indexOf(safetyConcern.parentStatus.id) > -1,
    )
  }

  if (searchFilter.priorityIds?.length > 0) {
    filteredSafetyConcerns = filteredSafetyConcerns.filter(
      safetyConcern => searchFilter.priorityIds.indexOf(safetyConcern.priority.id) > -1,
    )
  }

  if (searchFilter.sortBy) {
    switch (searchFilter.sortBy) {
      case 1:
        filteredSafetyConcerns = [...filteredSafetyConcerns].sort((safetyConcern1, safetyConcern2) =>
          byAccessor(
            safetyConcern1,
            safetyConcern2,
            (safetyConcern: ISafetyConcern) => safetyConcern.parentStatus?.sortOrder,
            searchFilter.isSortByAsc,
          ),
        )
        break
      case 2:
        filteredSafetyConcerns = [...filteredSafetyConcerns].sort((safetyConcern1, safetyConcern2) =>
          byAccessor(
            safetyConcern1,
            safetyConcern2,
            (safetyConcern: ISafetyConcern) => safetyConcern?.priority?.sortOrder || 0,
            searchFilter.isSortByAsc,
          ),
        )
        break
      default:
        break
    }
  }

  return filteredSafetyConcerns
}
