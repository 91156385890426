export type MapImageryFileNameFormat = Readonly<'quadkey' | 'zxy'>
type CreateImageLayerOptions = Readonly<{
  tileBaseUrl: string
  tileFileExtension: string
  tileFileFormat: MapImageryFileNameFormat
  tileSize: number
}>

const toZXYUrl = (x: any, y: any, zoom: number, baseUrl: string, filenameExtension: string) => {
  return `${baseUrl}/${zoom}${x}${y}.${filenameExtension}`
}

const toQuadKeyUrl = (x: any, y: any, zoom: any, baseUrl: string, filenameExtension: string) => {
  let quadKey = ''
  for (let i = zoom; i > 0; i--) {
    let digit = 0
    const mask = 1 << (i - 1)
    if ((x & mask) !== 0) {
      digit += 1
    }
    if ((y & mask) !== 0) {
      digit += 2
    }
    quadKey += digit
  }
  return `${baseUrl}/${quadKey}.${filenameExtension}`
}

// Normalizes the coords that tiles repeat across the x axis (horizontally)
// like the standard Google map tiles.
const getNormalizedCoord = (coord: any, zoom: number) => {
  const y = coord.y
  let x = coord.x

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange
  }

  return { x: x, y: y }
}

const createImageMapType = (options: CreateImageLayerOptions) => {
  return new window.google.maps.ImageMapType({
    getTileUrl: function (coord, zoom): string {
      const normalizedCoord = getNormalizedCoord(coord, zoom)

      if (!normalizedCoord) {
        return ''
      }

      switch (options.tileFileFormat) {
        case 'quadkey':
          return toQuadKeyUrl(
            normalizedCoord.x,
            normalizedCoord.y,
            zoom,
            options.tileBaseUrl,
            options.tileFileExtension,
          )
        case 'zxy':
          return toZXYUrl(normalizedCoord.x, normalizedCoord.y, zoom, options.tileBaseUrl, options.tileFileExtension)
        default:
          return toZXYUrl(normalizedCoord.x, normalizedCoord.y, zoom, options.tileBaseUrl, options.tileFileExtension)
      }
    },
    tileSize: new google.maps.Size(options.tileSize, options.tileSize),
    minZoom: 0,
    name: 'Imagery',
  })
}

export default createImageMapType
