import { FC, useCallback } from 'react'
import cn from 'classnames'

import { Button, ActionsButton } from '../../components'
import { formatDateString } from '../../util'

import { IChecklistListCardProps } from './checklist-card-types'

import s from './checklist-card.module.scss'

const ChecklistListCard: FC<IChecklistListCardProps> = props => {
  const { id, name, subName, completedAt, createdAt, description, activeId, setActiveId, actionsButtonOptions } = props

  const onCardClick = useCallback(() => setActiveId && setActiveId(id), [id, setActiveId])

  const wrapperClassName = cn(
    s['card'],
    'card card-custom border border-2 p-4 w-100',
    activeId === id && s['active-card'],
  )

  return (
    <div className={wrapperClassName} onClick={onCardClick}>
      <div className={cn('card-header border-0 m-0 mb-3 p-0', s['card-header'])}>
        <div>
          <p className={cn('card-title font-weight-bold m-0')}>
            {name} {`${createdAt ? ' - ' + formatDateString(createdAt) : ''}`}
          </p>

          {completedAt && <p className="card-subtitle mt-2">{`Completed at: ${formatDateString(completedAt)}`}</p>}

          {subName && <p className="card-subtitle mt-2">{subName}</p>}
        </div>

        {actionsButtonOptions && (
          <ActionsButton
            accessor={id}
            options={actionsButtonOptions}
            wrapperClassName="position-relative"
            Component={(props: any) => (
              <Button {...props} type="ghost-icon" className="position-relative">
                <i className="bi bi-three-dots d-flex" />
              </Button>
            )}
          />
        )}
      </div>

      <div className="card-body p-0">
        <p>{description}</p>
      </div>
    </div>
  )
}

export default ChecklistListCard
