import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { INotificationGroup, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
  includeDetail?: boolean
}

const getNotificationGroupsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/NotificationGroups${queryString}`,
  }
}

const useNotificationGroupsQuery = buildGetQuery<Params, QueryObject | undefined, INotificationGroup[] | undefined>({
  getRequest: getNotificationGroupsRequest,
  getQueryKey: (params, queryObject) => [
    'notification-groups',
    params.airportId,
    queryObject?.activeOnly || '',
    queryObject?.includeDetail || '',
  ],
})

export default useNotificationGroupsQuery
