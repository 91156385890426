import { FC } from 'react'

import s from './info-card.module.scss'

import { IInfoCardRow } from './info-card-types'

type InfoCardRowProps = IInfoCardRow & {}

const InfoCardRow: FC<InfoCardRowProps> = props => {
  const { title, value, noValue = '-', hiddenOnNoValue = false } = props

  if (hiddenOnNoValue && !value) {
    return null
  }

  return (
    <div className="d-flex mb-1">
      <div className={s['info-block-title']}>{title}:</div>
      <div className={s['info-block-value']}>{value || noValue}</div>
    </div>
  )
}

export default InfoCardRow
