import { createContext, useState, useMemo, useCallback, FC, useRef, ChangeEvent, useContext, ReactNode } from 'react'

import DocumentsPreviewModal from './documents-preview-modal'
import { AppContext } from '../../../app-context'

import { showErrorAlert } from '../../alert'
import { uploadFile, useDownloadDocumentMutation } from '../../../services'
import { useProfile } from '../../../security'
import { useModal } from '../../../hooks'
import {
  addLocalDocumentUploadStateToState,
  updateLocalDocumentUploadStateToState,
  removeLocalDocumentUploadStateToState,
} from './document-upload-context-utils'

import { IDocument } from '../../../interfaces'
import { LocalDocumentUploadState, IDocumentUploadState } from './document-upload-context-types'

const initialDocumentUploadState: IDocumentUploadState = {
  documents: [],
  localDocumentsUploadState: [],
  downloadingDocumentIds: [],

  removeDocument: () => {},
  removeLocalDocumentState: () => {},
  reUploadLocalDocumentState: () => {},
  downloadDocument: () => {},

  handleAddDocumentClick: () => {},
  handleDocumentsChange: () => {},

  setCurrentCarouselDocument: () => {},
  toggleDocumentsPreviewModal: () => {},
  handleViewDocumentsClick: (document?: IDocument) => {},

  isDocumentsPreviewModalOpen: false,
  disabled: false,

  parentType: '',
}

export const DocumentUploadContext = createContext<IDocumentUploadState>(initialDocumentUploadState)

type DocumentUploadProps = {
  value: IDocument[]
  onChange: (documents: IDocument[]) => void
  parentType: string
  disabled: boolean
  children: ReactNode
  acceptedFileExtensions?: string
  uploadFile?: (file: File) => Promise<any>
  validateSize?: (file: File) => string | undefined
}

const createLocalDocumentUploadState = (file: File): LocalDocumentUploadState => ({
  localDocument: file,
  localDocumentName: file.name,
  isUploading: true,
  error: null,
  isValid: true,
})

const DocumentUploadContextProvider: FC<DocumentUploadProps> = props => {
  const {
    value: documents,
    onChange: onDocumentsChange,
    parentType,
    disabled,
    acceptedFileExtensions = '*',
    uploadFile: _uploadFile,
    validateSize,
  } = props
  const { dispatch } = useContext(AppContext)

  const { airportId } = useProfile()

  const safeDocuments = useMemo(() => {
    if (Array.isArray(documents)) {
      return documents
    }
    return []
  }, [documents])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { isOpen: isDocumentsPreviewModalOpen, toggleModal: toggleDocumentsPreviewModal } = useModal()

  const [localDocumentsUploadState, setLocalDocumentsUploadState] = useState<LocalDocumentUploadState[]>([])

  const safeDocumentsRef = useRef(safeDocuments)
  safeDocumentsRef.current = safeDocuments

  const uploadDocument = useCallback(
    async (fileToUpload: File, isInitialUploading = true) => {
      if (!fileToUpload) {
        return
      }

      let localDocumentUploadState: LocalDocumentUploadState | undefined

      if (isInitialUploading) {
        localDocumentUploadState = createLocalDocumentUploadState(fileToUpload)

        const fileValidationErrorMessage = validateSize ? validateSize(fileToUpload) : undefined

        if (fileValidationErrorMessage) {
          localDocumentUploadState = {
            ...localDocumentUploadState,
            error: fileValidationErrorMessage,
            isValid: false,
            isUploading: false,
          }
        }

        setLocalDocumentsUploadState(localDocumentsUploadState =>
          addLocalDocumentUploadStateToState(localDocumentUploadState, localDocumentsUploadState),
        )

        if (fileValidationErrorMessage) {
          return
        }
      } else {
        localDocumentUploadState = localDocumentsUploadState.find(
          _localDocumentUploadState => _localDocumentUploadState.localDocumentName === fileToUpload.name,
        )

        if (!localDocumentUploadState) {
          return
        }

        setLocalDocumentsUploadState(
          updateLocalDocumentUploadStateToState(
            {
              ...localDocumentUploadState,
              isUploading: true,
            },
            localDocumentsUploadState,
          ),
        )
      }

      let uploadedDocument: IDocument | undefined
      try {
        uploadedDocument = _uploadFile
          ? (await _uploadFile(fileToUpload))?.data
          : (await uploadFile(airportId, fileToUpload, parentType))?.data
      } catch (e) {
        console.warn('Something went wrong during document upload', e)

        if (localDocumentUploadState === undefined) {
          return
        }

        const localDocumentUploadErrorState = {
          ...localDocumentUploadState,
          isUploading: false,
          error: 'Something went wrong during uploading, please try again',
        }

        setLocalDocumentsUploadState(localDocumentsUploadState =>
          updateLocalDocumentUploadStateToState(localDocumentUploadErrorState, localDocumentsUploadState),
        )

        return
      }

      if (uploadedDocument) {
        setLocalDocumentsUploadState(localDocumentsUploadState =>
          removeLocalDocumentUploadStateToState(
            createLocalDocumentUploadState(fileToUpload),
            localDocumentsUploadState,
          ),
        )

        onDocumentsChange([uploadedDocument, ...safeDocumentsRef.current])
      }
    },
    [_uploadFile, validateSize, localDocumentsUploadState, onDocumentsChange, airportId, parentType],
  )

  const { mutateAsync: downloadDocument } = useDownloadDocumentMutation({ airportId })

  const [downloadingDocumentIds, setDownloadingDocumentIds] = useState<number[]>([])

  const handleDownloadDocument = useCallback(
    async (document: IDocument) => {
      if (!document.url) {
        return
      }

      setDownloadingDocumentIds(downloadingDocumentIds => [...downloadingDocumentIds, document.id])

      try {
        // @ts-ignore
        await downloadDocument({ url: document.url, name: document.name })
      } catch (err) {
        console.warn('Something went wrong during document download', err)
        dispatch(showErrorAlert('Something went wrong during document download'))
      }

      setDownloadingDocumentIds(downloadingDocumentIds =>
        downloadingDocumentIds.filter(documentId => documentId !== document.id),
      )
    },
    [downloadDocument, dispatch],
  )

  const handleAddDocumentClick = useCallback(() => {
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.value = null
      fileInputRef.current.click()
    }
  }, [])

  const handleDocumentsChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }

      const filesToUpload = Array.prototype.slice.call(e.target.files)
      if (filesToUpload.length === 0) {
        return
      }

      filesToUpload.forEach(file => {
        uploadDocument(file)
      })
    },
    [uploadDocument],
  )

  const removeDocument = useCallback(
    (document: IDocument) => {
      const filteredDocuments = safeDocuments.filter(safeDocument => safeDocument.id !== document.id)
      onDocumentsChange(filteredDocuments)
    },
    [safeDocuments, onDocumentsChange],
  )

  const removeLocalDocumentState = useCallback(
    (localDocumentUploadState: LocalDocumentUploadState) =>
      setLocalDocumentsUploadState(localDocumentsUploadState =>
        removeLocalDocumentUploadStateToState(localDocumentUploadState, localDocumentsUploadState),
      ),
    [],
  )

  const reUploadLocalDocumentState = useCallback(
    (localDocumentUploadState: LocalDocumentUploadState) => {
      uploadDocument(localDocumentUploadState.localDocument, false)
    },
    [uploadDocument],
  )

  const [currentCarouselDocument, setCurrentCarouselDocument] = useState<IDocument>()

  const handleViewDocumentsClick = useCallback(
    (document?: IDocument) => {
      toggleDocumentsPreviewModal()
      setCurrentCarouselDocument(document || safeDocuments[0])
    },
    [toggleDocumentsPreviewModal, safeDocuments],
  )

  const value = useMemo(
    () => ({
      documents: safeDocuments,
      localDocumentsUploadState,

      downloadingDocumentIds,

      removeDocument,
      removeLocalDocumentState,
      reUploadLocalDocumentState,
      downloadDocument: handleDownloadDocument,

      toggleDocumentsPreviewModal,
      handleViewDocumentsClick,
      setCurrentCarouselDocument,
      currentCarouselDocument,

      parentType,
      disabled,
      handleAddDocumentClick,
      handleDocumentsChange,
      fileInputRef,
      isDocumentsPreviewModalOpen,
    }),
    [
      safeDocuments,
      localDocumentsUploadState,

      downloadingDocumentIds,

      removeDocument,
      removeLocalDocumentState,
      reUploadLocalDocumentState,
      handleDownloadDocument,

      toggleDocumentsPreviewModal,
      handleViewDocumentsClick,
      setCurrentCarouselDocument,
      currentCarouselDocument,

      parentType,
      disabled,
      handleAddDocumentClick,
      handleDocumentsChange,
      fileInputRef,
      isDocumentsPreviewModalOpen,
    ],
  )

  return (
    <DocumentUploadContext.Provider value={value}>
      <DocumentsPreviewModal
        documents={value.documents}
        currentCarouselDocument={value.currentCarouselDocument}
        setCurrentCarouselDocument={value.setCurrentCarouselDocument}
        parentType={value.parentType}
        isDocumentsPreviewModalOpen={value.isDocumentsPreviewModalOpen}
        toggleDocumentsPreviewModal={value.toggleDocumentsPreviewModal}
      />

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleDocumentsChange}
        style={{ display: 'none' }}
        disabled={disabled}
        accept={acceptedFileExtensions}
        multiple
      />

      {props.children}
    </DocumentUploadContext.Provider>
  )
}

export default DocumentUploadContextProvider
