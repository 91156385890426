import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { IFaq, IFaqUpdateUpsert, IGetPutRequest, IUseUpdateMutation, IUpdateFn } from '../../interfaces'

import config from '../../config'

const getUpdateFaqMutationRequest: IGetPutRequest = params => ({
  method: 'put',
  url: `${config.baseUrl}/help/Faqs/`,
})

const useUpdateFaqMutation: IUseUpdateMutation<IFaq, IFaqUpdateUpsert> = (params, options = {}) => {
  const request = useMemo(() => getUpdateFaqMutationRequest(params), [params])

  const updateFaq: IUpdateFn<IFaqUpdateUpsert, IFaq> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IFaq, Error, IFaqUpdateUpsert>({ mutationFn: updateFaq, ...options })

  return response
}

export default useUpdateFaqMutation
