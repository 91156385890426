import axios from 'axios'

import getAccessToken from '../security/get-access-token'

// Add the bearer token to the outgoing HTTP call
axios.interceptors.request.use(
  axiosConfig => {
    const bearerToken = getAccessToken()
    axiosConfig.headers['Authorization'] = `Bearer ${bearerToken}`

    return axiosConfig
  },
  error => Promise.reject(error),
)
