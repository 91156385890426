export const download = (url, name) =>
  fetch(url)
    .then(response => response.blob())
    .then(result => {
      const blob = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = blob
      link.download = name
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(blob)
    })

export const parseFileExtension = fileName => fileName.split('?')[0].split('.').pop().trim()

export const imageExtensions = ['png', 'jpg', 'jpeg', 'gif']

export const isImage = fileName => imageExtensions.indexOf(parseFileExtension(fileName)) !== -1
