import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import {} from '../../interfaces'

import config from '../../config'

import { IGetPostRequest, IUseAddMutation, IAddFn, IFaq, IFaqAddUpsert } from '../../interfaces'

const getCreateFaqMutationRequest: IGetPostRequest = () => ({
  method: 'post',
  url: `${config.baseUrl}/help/Faqs`,
})

const useCreateFaqMutation: IUseAddMutation<IFaq, IFaqAddUpsert> = (params, options = {}) => {
  const request = useMemo(() => getCreateFaqMutationRequest(params), [params])

  const createFaq: IAddFn<IFaqAddUpsert, IFaq> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IFaq, Error, IFaqAddUpsert>({ mutationFn: createFaq, ...options })

  return response
}

export default useCreateFaqMutation
