import { buildGetQuery } from '../../util'
import config from '../../config'

import { IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  userId: number
}

const getAuthUserDefaultFacilityIdsRequest = (params: Params): IGetRequestObject => {
  const { airportId, userId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/AuthUsers/${userId}/DefaultFacilities`,
  }
}

export const useAuthUserDefaultFacilityIdsQuery = buildGetQuery<Params, undefined, number[] | undefined>({
  getRequest: getAuthUserDefaultFacilityIdsRequest,
  getQueryKey: params => ['auth-user-default-facility-ids', params.airportId, params?.userId],
})

export default useAuthUserDefaultFacilityIdsQuery
