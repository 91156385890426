import config from '../../config'

import { IGetDownloadInspectionCSVMutationRequest } from './types'

const getDownloadInspectionCSVMutationRequest: IGetDownloadInspectionCSVMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Inspections/Download`,
  }
}

export default getDownloadInspectionCSVMutationRequest
