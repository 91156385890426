export const NONE_FREQUENCY = 0
export const DAILY_FREQUENCY = 1
export const WEEKLY_FREQUENCY = 2
export const MONTHLY_FREQUENCY = 3
export const YEARLY_FREQUENCY = 4

export const FREQUENCY_ID_TO_NAME_MAP = {
  [NONE_FREQUENCY]: 'none',
  [DAILY_FREQUENCY]: 'daily',
  [WEEKLY_FREQUENCY]: 'weekly',
  [MONTHLY_FREQUENCY]: 'monthly',
  [YEARLY_FREQUENCY]: 'yearly',
}
