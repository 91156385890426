import { IInspection } from '../interfaces'

const getInspectionAuditInfo = (inspection: IInspection) => ({
  startedAt: inspection.inspectionStartedAt,
  startedById: inspection.inspectionStartedBy,
  startedByName: inspection.inspectionStartedByName,
  endedAt: inspection.inspectionEndedAt,
  endedById: inspection.inspectionEndedBy,
})

export default getInspectionAuditInfo
