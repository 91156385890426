import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortInspectionSubtypesMutationRequest from './get-sort-inspection-subtypes-mutation-request'

import {
  IUseSortInspectionSubtypesMutation,
  ISortInspectionSubtypes,
} from './use-sort-inspection-subtypes-mutation-types'

const useSortInspectionSubtypesMutation: IUseSortInspectionSubtypesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortInspectionSubtypesMutationRequest(params), [params])

  const sortInspectionSubtypes: ISortInspectionSubtypes = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortInspectionSubtypes,
    ...options,
  })

  return response
}

export default useSortInspectionSubtypesMutation
