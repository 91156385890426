import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IGetPostRequest, IUseAddMutation, IAddFn, ISafetyConcern, ISafetyConcernAddUpsert } from '../../interfaces'

const getCreateSafetyConcernMutationRequest: IGetPostRequest = () => ({
  method: 'post',
  url: `${config.baseUrl}/SafetyConcerns`,
})

const useCreateSafetyConcernMutation: IUseAddMutation<ISafetyConcern, ISafetyConcernAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getCreateSafetyConcernMutationRequest(params), [params])

  const createSafetyConcern: IAddFn<ISafetyConcernAddUpsert, ISafetyConcern> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyConcern, Error, ISafetyConcernAddUpsert>({
    mutationFn: createSafetyConcern,
    ...options,
  })

  return response
}

export default useCreateSafetyConcernMutation
