import config from '../../config'

import { IGetDeleteRequest } from '../../interfaces'

const getDeleteTagMutationRequest: IGetDeleteRequest = params => {
  const { airportId } = params

  return {
    method: 'delete',
    url: `${config.baseUrl}/${airportId}/Tags/`,
  }
}

export default getDeleteTagMutationRequest
