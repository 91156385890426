import { FC, useMemo } from 'react'

import NotificationFilterFormFields from './notification-filter-fields'

import { INotificationFilterFormProps } from './notification-filter-form-types'

const NotificationFilterForm: FC<INotificationFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <NotificationFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default NotificationFilterForm
