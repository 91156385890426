import { useCallback, useMemo, useRef } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {
  isValid,
  endOfDay,
  startOfDay,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  subYears,
  endOfYear,
  format,
} from 'date-fns'

import { useWindowDimensions } from '../../../hooks'
import DatePickerHeader from '../common/date/date-picker-header'

import 'react-datepicker/dist/react-datepicker.css'
import '../common/date/date-picker.css'

import s from '../common/date/date-picker.module.scss'

const dateFormat = 'MM/dd/yyyy'

const PredefinedRanges = {
  Last7Days: 'Last 7 Days',
  CurrentMonth: 'This Month',
  LastMonth: 'Last Month',
  CurrentYear: 'Current Year',
  LastYear: 'Last Year',
}

const getPredefinedDatePeriod = period =>
  ({
    [PredefinedRanges.Last7Days]: {
      startDate: startOfDay(subDays(new Date(), 7)),
      endDate: endOfDay(new Date()),
    },
    [PredefinedRanges.CurrentMonth]: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    [PredefinedRanges.LastMonth]: {
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
    },
    [PredefinedRanges.CurrentYear]: {
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    },
    [PredefinedRanges.LastYear]: {
      startDate: startOfYear(subYears(new Date(), 1)),
      endDate: endOfYear(subYears(new Date(), 1)),
    },
  }[period] || {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  })

const DateRangePicker = props => {
  const { name, onChange: propsOnChange, value: propsValue, presetRanges, ...datePickerProps } = props

  const { width } = useWindowDimensions()

  const numMonthsToDisplay = useMemo(() => {
    if (!width) {
      return 1
    }
    return width < 768 ? 1 : 2
  }, [width])

  const value = useMemo(() => {
    let startDate = null

    if (typeof propsValue?.[0] === 'string') {
      const _startDate = new Date(propsValue?.[0])
      startDate = isValid(_startDate) ? _startDate : null
    }

    let endDate = null

    if (typeof propsValue?.[1] === 'string') {
      const _endDate = new Date(propsValue?.[1])
      endDate = isValid(_endDate) ? _endDate : null
    }

    return [startDate, endDate]
  }, [propsValue])

  const onChange = useCallback(
    dates => {
      let startDateString = ''
      if (dates?.[0] instanceof Date && isValid(dates?.[0])) {
        startDateString = format(dates?.[0], dateFormat)
      }

      let endDateString = ''
      if (dates?.[1] instanceof Date && isValid(dates?.[1])) {
        endDateString = format(dates?.[1], dateFormat)
      }

      propsOnChange({
        0: startDateString,
        1: endDateString,
      })
    },
    [propsOnChange],
  )

  const inputRef = useRef()

  return (
    <div className={s['date-picker-wrapper']} id={`date-picker-wrapper-${name}`}>
      <DatePicker
        ref={inputRef}
        renderCustomHeader={DatePickerHeader}
        selectsRange
        startDate={value?.[0]}
        endDate={value?.[1]}
        calendarClassName={s.calendar}
        dayClassName={() => s['calendar-day']}
        weekDayClassName={() => s['calendar-week-day']}
        locale="en"
        name={name}
        portalId={`date-picker-wrapper-${name}`}
        popperProps={{ strategy: 'fixed' }}
        onChangeRaw={e => e.preventDefault()} //prevents from typing into the input
        onChange={onChange}
        isClearable
        dateFormat={dateFormat}
        onFocus={e => e.target.blur()}
        monthsShown={numMonthsToDisplay}
        {...datePickerProps}
      >
        <div className={s['preset-ranges']}>
          {Object.keys(PredefinedRanges).map(rangeKey => (
            <Button
              key={rangeKey}
              variant="primary"
              size="sm"
              onClick={() => {
                const range = getPredefinedDatePeriod(PredefinedRanges[rangeKey])

                const v = [range.startDate, range.endDate]
                onChange(v)
                inputRef.current?.setOpen(false)
              }}
            >
              {PredefinedRanges[rangeKey]}
            </Button>
          ))}
        </div>
      </DatePicker>
    </div>
  )
}

export default DateRangePicker
