import React, { useCallback, useContext, useMemo } from 'react'
import get from 'lodash.get'

import { Input } from '../../form/input'

import { ChecklistContext } from '../checklist-context/checklist-context'

type ChecklistSubheadCellProps = { row: any }

const ChecklistSubheadCell: React.FC<ChecklistSubheadCellProps> = props => {
  const { row } = props

  const { onChecklistSubheadRowChange, isConfigurable, isDisabled } = useContext(ChecklistContext)

  const rowId = useMemo(() => row.original.id, [row.original.id])

  const onChange = useCallback(
    (value: string | number) => onChecklistSubheadRowChange({ rowId, value }),
    [onChecklistSubheadRowChange, rowId],
  )
  const value = get(row.original, 'subheadValue')

  const adProps = useMemo(() => {
    if (!isConfigurable) {
      return { isViewMode: true }
    }
  }, [isConfigurable])

  return (
    <div>
      <Input onChange={onChange} value={value} disabled={isDisabled} placeholder="Subheader Title..." {...adProps} />
    </div>
  )
}

export default ChecklistSubheadCell
