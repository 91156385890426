interface GeoJsonObject {
  LinkedInspectionId: number | null
  LinkedWorkOrderId: number | null
  DiscrepancyId: number | null
}

export const getLinkFromShapeObject = (obj: GeoJsonObject) => {
  if (!obj) return ''
  return obj.LinkedWorkOrderId
    ? `/work-orders/${obj.LinkedWorkOrderId}`
    : `/inspections/${obj.LinkedInspectionId}/discrepancy/${obj.DiscrepancyId}`
}
