import { FC, useMemo } from 'react'

import ActivityLogFormFields from './activity-log-form-fields'

import { IActivityLogFormProps } from './activity-log-form-types'

const ActivityLogForm: FC<IActivityLogFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, form, enableDocumentUpload } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <ActivityLogFormFields
        disabled={isDisabled}
        addSelectedTagIds={form.mutators.addSelectedTagIds}
        enableDocumentUpload={enableDocumentUpload}
      />
    </form>
  )
}

export default ActivityLogForm
