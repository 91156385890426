import { FC } from 'react'
import { Table } from '@tanstack/react-table'

import { Pagination } from '../../pagination'

type TablePaginationProps = {
  tableInstance: Table<any>
  totalItems: number
}

const TablePagination: FC<TablePaginationProps> = props => {
  const { tableInstance, totalItems } = props

  const canPreviousPage = tableInstance.getCanPreviousPage()
  const canNextPage = tableInstance.getCanNextPage()
  const totalPageCount = tableInstance.getPageCount()

  const { setPageIndex, setPageSize } = tableInstance

  const {
    pagination: { pageIndex, pageSize },
  } = tableInstance.getState()

  const { rows } = tableInstance.getSortedRowModel()

  return (
    <Pagination
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      pageSize={pageSize}
      setPageSize={setPageSize}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      totalPageCount={totalPageCount}
      totalItemsCount={totalItems || rows?.length || 0}
    />
  )
}

export default TablePagination
