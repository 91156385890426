import { string, bool, oneOfType, number } from 'prop-types'
import cn from 'classnames'

import s from './switch.module.scss'

const defaultOnLabel = 'on'
const defaultOffLabel = 'off'

const Switch = ({ onLabel, offLabel, name, label, disabled, onChange, checked, isViewMode }) => {
  const _onLabel = onLabel || defaultOnLabel
  const _offLabel = offLabel || defaultOffLabel

  const subLabel = checked ? _onLabel : _offLabel

  const switchWrapperClassName = cn('switch switch-sm', s['switch'], { [s['switch-disabled']]: disabled })

  return (
    <span className={switchWrapperClassName}>
      <label>
        {label}

        {!isViewMode && <input type="checkbox" checked={checked} onChange={onChange} name={name} disabled={disabled} />}
        <span />

        <div className={cn(s['subLabel'], { [s['subLabel-view']]: isViewMode })}>{subLabel}</div>
      </label>
    </span>
  )
}

Switch.defaultProps = {
  onLabel: '',
  offLabel: '',
  label: '',
  disabled: false,
}

Switch.propTypes = {
  name: oneOfType([string, number]),
  onLabel: string,
  offLabel: string,
  label: string,
  isViewMode: bool,
  disabled: bool,
}

export default Switch
