import { useState, useCallback, useEffect } from 'react'
import qs from 'qs'
import { useNavigate, useLocation } from 'react-router-dom'

import { IUseQueryParamsParams, IUseQueryParams } from './use-query-params-types'

export const useQueryParams: IUseQueryParams = (defaultParams, options = { modularChange: true }) => {
  const [params, setParams] = useState(defaultParams)

  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    const queryString = qs.stringify(params)

    navigate({ pathname: location.pathname, search: queryString }, { replace: true })
  }, [params, location.pathname, navigate])

  const _setParams = useCallback(
    (newParams: IUseQueryParamsParams) => {
      if (options?.modularChange) {
        setParams({ ...params, ...newParams })
      } else {
        setParams(newParams)
      }
    },
    [params, options?.modularChange],
  )

  const clearParams = useCallback(() => {
    navigate({ pathname: location.pathname, search: '' }, { replace: true })
    setParams({})
  }, [location.pathname, navigate])

  return { params, setParams: _setParams, clearParams }
}
