import axios from 'axios'

import config from '../../config'

const deleteNotification = ({ airportId, id, userId }) => {
  const url = `${config.baseUrl}/${airportId}/Notifications/${id}?userId=${userId}`

  return axios.delete(url)
}

export default deleteNotification
