import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortWildlifeSearchFilter } from '../../../../interfaces'
import { IWildlifeFilterValues, IWildlifeFilterUpsertValues } from './wildlife-filter-form-types'

export const objectToWildlifeFilterValues = (object?: { [key: string]: any }): IWildlifeFilterUpsertValues => ({
  dateRange: { 0: object?.startedAtBegin || '', 1: object?.startedAtEnd || '' },
  facilityIds: object?.facilityIds || [],
  conditionIds: object?.conditionIds || [],
  locationIds: object?.locationIds || [],
  wildlifeSpeciesIds: object?.wildlifeSpeciesIds || [],
  wildlifeActivityIds: object?.wildlifeActivityIds || [],
  wildlifeMitigationIds: object?.wildlifeMitigationIds || [],
})

export const savedSearchFilterToSavedWildlifeFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): IWildlifeFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let wildlifeFilterObject
  try {
    wildlifeFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    wildlifeFilterObject = {}
  }

  const wildlifeFilterValues = objectToWildlifeFilterValues(wildlifeFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    wildlifeFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return wildlifeFilterValues
}

export const savedWildlifeFilterValuesToSavedSearchFilterAddUpsert = (
  savedWildlifeFilterValues: IWildlifeFilterValues | undefined,
): IShortWildlifeSearchFilter => ({
  startedAtBegin: savedWildlifeFilterValues?.dateRange?.[0] || '',
  startedAtEnd: savedWildlifeFilterValues?.dateRange?.[1] || '',
  facilityIds: savedWildlifeFilterValues?.facilityIds || [],
  conditionIds: savedWildlifeFilterValues?.conditionIds || [],
  locationIds: savedWildlifeFilterValues?.locationIds || [],
  wildlifeSpeciesIds: savedWildlifeFilterValues?.wildlifeSpeciesIds || [],
  wildlifeActivityIds: savedWildlifeFilterValues?.wildlifeActivityIds || [],
  wildlifeMitigationIds: savedWildlifeFilterValues?.wildlifeMitigationIds || [],
})

export const savedWildlifeFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedWildlifeFilterValues: IWildlifeFilterValues | undefined,
): IShortWildlifeSearchFilter => savedWildlifeFilterValuesToSavedSearchFilterAddUpsert(savedWildlifeFilterValues)
