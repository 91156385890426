import { FC } from 'react'

import PopUpRow from './pop-up-row'

import { ISafetyConcern } from '../../interfaces'

import s from './map-popups.module.scss'

type SafetyConcernPopUpContentProps = {
  safetyConcern?: ISafetyConcern
}

const SafetyConcernPopUpContent: FC<SafetyConcernPopUpContentProps> = props => {
  const { safetyConcern } = props

  return (
    <div className={s['safety-concern-content']}>
      <PopUpRow label="Name" value={safetyConcern?.summary || ''} />

      <PopUpRow label="Status" value={safetyConcern?.parentStatus?.name || ''} />

      <PopUpRow label="Location" value={safetyConcern?.location?.name || 'Adhoc'} />
    </div>
  )
}

export default SafetyConcernPopUpContent
