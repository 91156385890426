import { FC, useMemo, useCallback } from 'react'
import cn from 'classnames'

import { useAppContext } from '../../../app-context'
import { Button } from '../../button'

import { actions } from '../../../actions'

import '../navbar-icon-actions-button.scss'

//@ts-ignore
import s from './faq-button.module.scss'

type FAQButtonProps = {
  className?: string
}

const FAQButton: FC<FAQButtonProps> = props => {
  const { className } = props

  const { state, dispatch } = useAppContext()

  const releaseNotesInfo = state?.releaseNotesInfo

  const showRedBubble = useMemo(() => {
    return releaseNotesInfo?.isReminderOpen === true
  }, [releaseNotesInfo?.isReminderOpen])

  const toggleFaqDrawer = useCallback(
    () =>
      state.isProfileDrawerOpen
        ? dispatch(actions.faqDrawer.closeFaqDrawer())
        : dispatch(actions.faqDrawer.openFaqDrawer()),
    [state.isProfileDrawerOpen, dispatch],
  )

  return (
    <>
      <Button type="ghost-icon" className={cn(s['faq-button'], 'navbar-icon-actions-button', className)} onClick={toggleFaqDrawer}>
        <i className="bi bi-question-circle" />

        {showRedBubble && (
          <sup>
            <span className="badge badge-pill bg-danger text-white" style={{minWidth: '17px'}}>!</span>
          </sup>
        )}
      </Button>
    </>
  )
}

export default FAQButton
