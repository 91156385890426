import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

let appInsights = null
export const reactPlugin = new ReactPlugin()

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {Object} appInsightsConfig - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (appInsightsConfig, browserHistory) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service')
    }
    if (!appInsightsConfig?.connectionString) {
      throw new Error('Instrumentation connection string not provided in ./src/telemetry-provider.jsx')
    }

    const config = {
      ...appInsightsConfig,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {
          history: browserHistory,
        },
      },
    }

    appInsights = new ApplicationInsights({
      config: config,
    })

    appInsights.loadAppInsights()
    appInsights.trackPageView()
  }

  return { reactPlugin, appInsights, initialize }
}

export const ai = createTelemetryService()
export const getAppInsights = () => appInsights
