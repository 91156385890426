import { FC } from 'react'

type BadgeProps = {
  alpha?: number
  className?: string
  color?: string
  status?: string
  dataTestId?: string
}

const Badge: FC<BadgeProps> = props => {
  const { alpha = 20, className = '', color, status, dataTestId = undefined } = props

  const backgroundColor = `${color}${alpha}`

  return (
    <span
      data-test-id={dataTestId}
      className={`badge badge-light ml-2 ${className}`}
      style={{ color, backgroundColor }}
    >
      {status}
    </span>
  )
}

export default Badge
