import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateChecklistMutationRequest from './get-update-checklist-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { IChecklist, IChecklistUpsert } from '../../interfaces'

const useUpdateChecklistMutation: IUseUpdateMutation<IChecklist, IChecklistUpsert> = (params, options = {}) => {
  const request = useMemo(() => getUpdateChecklistMutationRequest(params), [params])

  const updateChecklist: IUpdateFn<IChecklistUpsert, IChecklist> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklist, Error, any>({
    mutationFn: updateChecklist,
    ...options,
  })

  return response
}

export default useUpdateChecklistMutation
