import config from '../../config'

import { IGetDeleteRequest } from '../../interfaces'

const getDeleteChecklistMutationRequest: IGetDeleteRequest = params => {
  const { airportId } = params

  return {
    method: 'delete',
    url: `${config.baseUrl}/${airportId}/Checklists/instances/`,
  }
}

export default getDeleteChecklistMutationRequest
