import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IChecklistTemplate, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
}

const getChecklistTemplatesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Checklists/templates${queryString}`,
  }
}

const useChecklistTemplatesQuery = buildGetQuery<Params, QueryObject | undefined, IChecklistTemplate[] | undefined>({
  getRequest: getChecklistTemplatesRequest,
  getQueryKey: (params, queryObject) => ['checklist-templates', params.airportId, queryObject?.activeOnly || ''],
})

export default useChecklistTemplatesQuery
