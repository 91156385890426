import { set, getDaysInMonth } from 'date-fns'

const DEFAULT_DAYS_COUNT = 31

export const getMaxDaysByMonthIndex = monthIndex => {
  if (!monthIndex) {
    return DEFAULT_DAYS_COUNT
  }

  if (monthIndex < 1 || monthIndex > 12) {
    console.error('Month index must be between 1 and 12')
    return DEFAULT_DAYS_COUNT
  }

  return getDaysInMonth(set(new Date(), { month: monthIndex }))
}
