import Papa from 'papaparse'

import { ILocationCsvJson } from '../interfaces'

const csvToLocationCsvJson = async (csv: File): Promise<ILocationCsvJson[] | null> => {
  let csvJson = null

  try {
    csvJson = await new Promise(res => {
      Papa.parse(csv as any, {
        header: true,
        complete: function (results) {
          res(results.data)
        },
      })
    })
  } catch (err) {
    return null
  }

  return csvJson as ILocationCsvJson[]
}

export default csvToLocationCsvJson
