import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback, useMemo } from 'react'
import config from '../../config'

const useFilteredDiscrepancies = (airportId, postData, options) => {
  const getFilteredDiscrepancies = (airportId, data) => ({
    method: 'post',
    url: `${config.baseUrl}/${airportId}/SafetyItems/Search`,
    data,
  })

  const request = useMemo(() => getFilteredDiscrepancies(airportId, postData), [airportId, postData])
  const queryKey = useMemo(() => ['filteredDiscrepancies', airportId, postData], [airportId, postData])

  const fetchDiscrepancies = useCallback(() => axios(request).then(res => res.data), [request])

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    isFetching,
  } = useQuery(queryKey, fetchDiscrepancies, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  })

  return { data, error, loading, isFetching, refetch }
}

export default useFilteredDiscrepancies
