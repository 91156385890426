type Object = {
  [field: string]: any
}

export const clearUndefinedProperties = (obj: Object): any => {
  if (!obj) {
    return obj
  }

  return Object.keys(obj).reduce<Object>((acc, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}
