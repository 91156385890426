import { FC, useRef } from 'react'
import { HeaderGroup } from '@tanstack/react-table'
import cn from 'classnames'

import { TableHeaderGroupHeader } from '../table-header-group-header'

//@ts-ignore
import s from '../table.module.scss'

type TableHeaderGroupProps = {
  headerGroup: HeaderGroup<{}>
  isRowsDraggable?: boolean
}

const TableHeaderGroup: FC<TableHeaderGroupProps> = props => {
  const { headerGroup, isRowsDraggable } = props

  const tableHeaderRowRef = useRef<HTMLTableRowElement>(null)

  return (
    <tr className={s['tr']} ref={tableHeaderRowRef}>
      {headerGroup.headers.map(header => (
        <TableHeaderGroupHeader key={header.id} header={header} isRowsDraggable={isRowsDraggable} />
      ))}

      {isRowsDraggable && (
        <th className={cn(s['th'], s['actions-column'])}>
          <div className={s['th-content']}>Actions</div>
        </th>
      )}
    </tr>
  )
}

export default TableHeaderGroup
