import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useNotificationFilterQueries from './use-notification-log-filter-queries'

import { INotificationFilterFormFiledsProps, INotificationFilterValues } from './notification-filter-form-types'

const nameof = nameofFactory<INotificationFilterValues>()

const startedDateRangeLabel = 'Started Date Range'
const startedByLabel = 'Started By'
const endedDateRangeLabel = 'Ended Date Range'
const endedByLabel = 'Ended By'
const featureLabel = 'Feature'
const notificationTypeLabel = 'Type'
const searchTextLabel = 'Text Search'

const NotificationFilterFormFields: React.FC<INotificationFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const {
    featureOptions,
    notificationTypeOptions,

    authUsersQuery,
    authUsersOptions,
  } = useNotificationFilterQueries(values)

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('startedDateRange')}
            label={startedDateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(startedDateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('startedByIds')}
            label={startedByLabel}
            placeholder={getPlaceholderTextFromLabel(startedByLabel)}
            options={authUsersOptions}
            disabled={disabled || authUsersQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('endedDateRange')}
            label={endedDateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(endedDateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('endedByIds')}
            label={endedByLabel}
            placeholder={getPlaceholderTextFromLabel(endedByLabel)}
            options={authUsersOptions}
            disabled={disabled || authUsersQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('featureIds')}
            label={featureLabel}
            placeholder={getPlaceholderTextFromLabel(featureLabel)}
            options={featureOptions}
            disabled={disabled}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('notificationTypeIds')}
            label={notificationTypeLabel}
            placeholder={getPlaceholderTextFromLabel(notificationTypeLabel)}
            options={notificationTypeOptions}
            disabled={disabled}
            isMulti
          />
        </Col>
      </Row>

      <RFFFields.SearchInput
        name={nameof('searchText')}
        label={searchTextLabel}
        placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
        disabled={disabled}
      />
    </>
  )
}

export default NotificationFilterFormFields
