import getWorkOrdersByIdsRequest from './get-work-orders-by-ids-request'
import useAxios from '../use-axios'

const useWorkOrdersByIds = (params, queryObject, axiosOptions = {}) => {
  const request = getWorkOrdersByIdsRequest(params, queryObject)

  const { data = [], error, loading, loaded, refetch } = useAxios(request, { defaultValue: [], ...axiosOptions })

  return { data, error, loading, loaded, execute: refetch }
}

export default useWorkOrdersByIds
