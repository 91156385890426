import React from 'react'
import { Row, Col } from 'react-bootstrap'

import s from './dual-checkboxes-lists.module.scss'

type DualCheckboxesListsProps = {
  renderMainCheckboxesComponent: () => React.ReactNode
  renderSecondaryCheckboxesComponent: any
  title: string
}

const DualCheckboxesLists: React.FC<DualCheckboxesListsProps> = props => {
  const { renderMainCheckboxesComponent, renderSecondaryCheckboxesComponent, title } = props

  return (
    <div>
      <div className={s['dual-checkboxes-list-title']}>{title}</div>

      <Row>
        <Col>{renderMainCheckboxesComponent && renderMainCheckboxesComponent()}</Col>

        <div className={s['dual-checkboxes-list-separator-container']}>
          <div className={s['dual-checkboxes-list-separator-line']} />
        </div>

        <Col>{renderSecondaryCheckboxesComponent && renderSecondaryCheckboxesComponent({ disabled: true })}</Col>
      </Row>
    </div>
  )
}

export default DualCheckboxesLists
