import { generateId } from '../../util'

import { IChecklistColumn, ChecklistRow, ChecklistValue } from './checklist-types'
import { IColumnFormValues } from '../form'
import { IChecklist, IChecklistTemplate } from '../../interfaces'

export const buildColumn = (values: IColumnFormValues, id?: string): IChecklistColumn => {
  const _id = id ? id : generateId()

  const attributes =
    values.type === 'dropdown'
      ? (values?.attributes || []).map(attribute => ({ label: attribute.name, value: attribute.value }))
      : []

  return {
    Header: values.name,
    columnType: values.type,
    isReadOnly: values.isReadOnly,
    attributes,
    id: _id,
    accessor: _id,
  }
}

const defaultRowValueByRowType = {
  string: '',
  checkbox: false,
  dropdown: [],
  date: undefined,
}

export const buildRow = (columns: IChecklistColumn[], rowValueByRowType: any = {}): ChecklistRow =>
  // @ts-ignore
  columns.reduce<ChecklistRow>(
    (row, column: IChecklistColumn) => ({
      ...row,
      [column.id]: {
        // @ts-ignore
        value: rowValueByRowType ? rowValueByRowType[column.columnType] : defaultRowValueByRowType[column.columnType],
      },
    }),
    // @ts-ignore
    { id: generateId(), isSubhead: false },
  )

// @ts-ignore
export const buildSubheadRow = (): ChecklistRow => ({ id: generateId(), isSubhead: true })

export const checklistToChecklistValue = (checklist: IChecklist): ChecklistValue | null => {
  try {
    const parsedInstanceBody = JSON.parse(checklist.instanceBody)

    const { data, columns } = parsedInstanceBody

    return {
      id: String(checklist.id),
      airportId: checklist.airportId,
      name: checklist.name,
      subName: checklist.subName,
      subtitleDisplayName: checklist.subtitleDisplayName,
      subtitleDisplayHint: checklist.subtitleDisplayHint,
      description: checklist.description,
      note: checklist.note,
      data,
      columns,
      templateId: checklist.templateId,
      sortOrder: checklist.sortOrder,
      isActive: checklist.isActive,
      createdAt: checklist.createdAt,
      createdBy: checklist.createdBy,
      createdByUserName: checklist.createdByUserName,
      checklistStartedByUserName: checklist.checklistStartedByUserName,
      lastUpdatedAt: checklist.lastUpdatedAt,
      lastUpdatedBy: checklist.lastUpdatedBy,
      completedAt: checklist.completedAt,
      checklistStartedAt: checklist.checklistStartedAt,
      checklistStartedBy: checklist.checklistStartedBy,
    }
  } catch {
    return null
  }
}

export const checklistTemplateToChecklistValue = (checklist: IChecklistTemplate): ChecklistValue | null => {
  try {
    const parsedTemplateBody = JSON.parse(checklist.templateBody)

    const { data, columns } = parsedTemplateBody

    return {
      id: String(checklist.id),
      airportId: checklist.airportId,
      name: checklist.name,
      description: checklist.description,
      subtitleDisplayName: checklist.subtitleDisplayName,
      subtitleDisplayHint: checklist.subtitleDisplayHint,
      data,
      columns,
      note: checklist.note,
      sortOrder: checklist.sortOrder,
      isActive: checklist.isActive,
      createdAt: checklist.createdAt,
      createdBy: checklist.createdBy,
      createdByUserName: checklist.createdByUserName,
      lastUpdatedAt: checklist.lastUpdatedAt,
      lastUpdatedBy: checklist.lastUpdatedBy,
    }
  } catch (err) {
    console.warn('err: ', err)
    return null
  }
}

export const checklistValueToChecklist = (checklist: ChecklistValue): IChecklist => ({
  id: checklist.id,
  airportId: checklist.airportId as number,
  name: checklist.name,
  subName: checklist.subName,
  subtitleDisplayName: checklist.subtitleDisplayName,
  subtitleDisplayHint: checklist.subtitleDisplayHint,
  description: checklist.description,
  note: checklist.note,
  instanceBody: JSON.stringify({ data: checklist.data, columns: checklist.columns }),
  templateId: checklist.templateId as number,
  sortOrder: checklist.sortOrder,
  isActive: checklist.isActive,
  createdAt: checklist.createdAt,
  createdBy: checklist.createdBy,
  createdByUserName: checklist.createdByUserName,
  lastUpdatedAt: checklist.lastUpdatedAt,
  lastUpdatedBy: checklist.lastUpdatedBy,
  checklistStartedAt: checklist.checklistStartedAt,
  checklistStartedBy: checklist.checklistStartedBy,
})

export const checklistValueToChecklistTemplate = (checklist: ChecklistValue): IChecklistTemplate => ({
  id: checklist.id,
  airportId: checklist.airportId as number,
  name: checklist.name,
  subtitleDisplayName: checklist.subtitleDisplayName,
  subtitleDisplayHint: checklist.subtitleDisplayHint,
  description: checklist.description,
  note: checklist.note,
  templateBody: JSON.stringify({ data: checklist.data, columns: checklist.columns }),
  sortOrder: checklist.sortOrder,
  isActive: checklist.isActive,
  createdAt: checklist.createdAt,
  createdBy: checklist.createdBy,
  createdByUserName: checklist.createdByUserName,
  lastUpdatedAt: checklist.lastUpdatedAt,
  lastUpdatedBy: checklist.lastUpdatedBy,
})
