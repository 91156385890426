import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IWorkOrder, IWorkOrderAddUpsert, IGetPostRequest, IUseAddMutation, IAddFn } from '../../interfaces'

const getAddWorkOrderMutationRequest: IGetPostRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/WorkOrders`,
  }
}

const useAddWorkOrderMutation: IUseAddMutation<IWorkOrder, IWorkOrderAddUpsert> = (params, options = {}) => {
  const request = useMemo(() => getAddWorkOrderMutationRequest(params), [params])

  const addWorkOrder: IAddFn<IWorkOrderAddUpsert, IWorkOrder> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IWorkOrder, Error, any>({
    mutationFn: addWorkOrder,
    ...options,
  })

  return response
}

export default useAddWorkOrderMutation
