import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import { useAuthUsersSelectListQuery, useChecklistTemplatesQuery } from '../../../../services'
import { toOptions, capitalizeFirst } from '../../../../util'

import { IChecklistFilterUpsertValues } from './checklist-filter-form-types'

const useInspectionFilterQueries = (values?: IChecklistFilterUpsertValues) => {
  const { airportId } = useProfile()

  const authUsersQuery = useAuthUsersSelectListQuery({ airportId }, undefined)

  const authUsersOptions = useMemo(
    () => toOptions(authUsersQuery.data, { getLabel: user => capitalizeFirst(`${user.lastName}, ${user.firstName}`) }),
    [authUsersQuery.data],
  )

  const checklistTemplatesQuery = useChecklistTemplatesQuery(airportId, {})

  const checklistTemplatesOptions = useMemo(
    () => toOptions(checklistTemplatesQuery.data),
    [checklistTemplatesQuery.data],
  )

  return {
    authUsersQuery,
    authUsersOptions,

    checklistTemplatesQuery,
    checklistTemplatesOptions,
  }
}

export default useInspectionFilterQueries
