import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateDashboardWidgetsMutationRequest from './get-update-dashboard-widgets-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { IDashboardWidget } from '../../interfaces'

const useUpdateDashboardWidgetsMutation: IUseUpdateMutation<IDashboardWidget[], IDashboardWidget[]> = (
  _,
  options = {},
) => {
  const request = useMemo(() => getUpdateDashboardWidgetsMutationRequest(), [])

  const updateDashboardWidgets: IUpdateFn<IDashboardWidget[], IDashboardWidget[]> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IDashboardWidget[], Error, IDashboardWidget[]>({
    mutationFn: updateDashboardWidgets,
    ...options,
  })

  return response
}

export default useUpdateDashboardWidgetsMutation
