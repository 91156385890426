import qs from 'qs'

import config from '../../config'

const getSafetyItemUniqueResponses = (params = {}, queryObject) => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/SafetyItemResponses${queryString}`,
  }
}

export default getSafetyItemUniqueResponses
