import qs from 'qs'

import config from '../../config'

import { buildGetQuery } from '../../util'

import { IGetRequestObject, ITag, TagCategory } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  tagCategory?: TagCategory
}

const getTagsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Tags${queryString}`,
  }
}

export const useTagsQuery = buildGetQuery<Params, QueryObject | undefined, ITag[] | undefined>({
  getRequest: getTagsRequest,
  getQueryKey: (params, queryObject) => ['tags', params.airportId, queryObject?.tagCategory || ''],
})

export default useTagsQuery
