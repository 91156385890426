import axios from 'axios'

import config from '../../config'

const updateWorkOrderStatus = (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/WorkOrderStatuses`
  return axios.put(url, data)
}

export default updateWorkOrderStatus
