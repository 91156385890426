export const calculatePolygonCenter = points => {
  let longitudes = points.map(i => i[0])
  let latitudes = points.map(i => i[1])

  latitudes.sort()
  longitudes.sort()

  let lowX = latitudes[0]
  let highX = latitudes[latitudes.length - 1]
  let lowy = longitudes[0]
  let highy = longitudes[longitudes.length - 1]

  let centerX = lowX + (highX - lowX) / 2
  let centerY = lowy + (highy - lowy) / 2

  return { latitude: centerX, longitude: centerY }
}
