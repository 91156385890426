import { useState, useEffect, useMemo } from 'react'

import { onlyUnique, toPng } from '../../../util'
import { getPinUrl } from '../get-pin-url'
import { IUseMarkerIcons, IMarkerIcons } from './use-marker-icons-types'

export const DEFAULT_MARKER_COLOR = '#48276A'
export const TEMPORARY_MARKER_COLOR = '#ffcf00'
export const ACTIVE_MARKER_COLOR = '#FF0000'

const useMarkerIcons: IUseMarkerIcons = props => {
  const { geoJson } = props

  const markersGeoJson = useMemo(() => geoJson.filter(geoJsonItem => geoJsonItem.geometry.type === 'Point'), [geoJson])

  const [pngIcons, setPngIcons] = useState<IMarkerIcons>({})

  useEffect(() => {
    const updateColors = async () => {
      const uniqueColors = [
        DEFAULT_MARKER_COLOR,
        ACTIVE_MARKER_COLOR,
        ...markersGeoJson.map(geoJson => geoJson.properties.color || DEFAULT_MARKER_COLOR).filter(onlyUnique),
      ]

      let newPngIcons = { ...pngIcons }
      let needToUpdate = false

      await Promise.all(
        uniqueColors.map(async color => {
          if (newPngIcons[color] === undefined) {
            newPngIcons[color] = await toPng(getPinUrl(color))
            needToUpdate = true
          }
        }),
      )

      if (needToUpdate) {
        setPngIcons(newPngIcons)
      }
    }

    updateColors()
  }, [markersGeoJson, pngIcons])

  return pngIcons
}

export default useMarkerIcons
