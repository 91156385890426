import { FC, useEffect, useState, useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { Table } from 'react-bootstrap'
import cn from 'classnames'
import { TableInstance } from 'react-table'

import ChecklistHeaderCell from '../checklist-header-cell/checklist-header-cell'

import { DND_COL_ITEM_TYPE } from '../checklist-constants'

import { ChecklistRow } from '../checklist-types'

import s from './checklist-preview.module.scss'

type ChecklistPreviewProps = {
  tableInstance: TableInstance<ChecklistRow>
}

const getListStyles = (currentOffset: any) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }

  return {
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
  }
}

const ChecklistPreview: FC<ChecklistPreviewProps> = props => {
  const { tableInstance } = props

  const { rows, prepareRow } = tableInstance

  const {
    item: column,
    currentOffset,
    isDragging,
    type,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    type: monitor.getItemType(),
  }))

  const [width, setWidth] = useState(0)

  const isDraggingRef = useRef(isDragging)

  useEffect(() => {
    if (type !== DND_COL_ITEM_TYPE || !column?.id) {
      isDraggingRef.current = isDragging
      return
    }

    if (isDraggingRef.current !== isDragging) {
      const draggableTh = document.getElementById(column?.id)

      if (draggableTh) {
        setWidth(draggableTh.offsetWidth)
      }
    }

    isDraggingRef.current = isDragging
  }, [setWidth, isDragging, column?.id, type])

  if (!isDragging || type !== DND_COL_ITEM_TYPE) {
    return null
  }

  rows.forEach(row => prepareRow(row))

  return (
    <Table bordered style={{ ...getListStyles(currentOffset), width }} className={cn('p-0 m-0', s['container'])}>
      <thead>
        <tr>
          <th>
            <ChecklistHeaderCell header={column.header} columnIndex={0} />
          </th>
        </tr>
      </thead>

      <tbody>
        {rows.map(row => (
          <tr key={row.id}>
            {row.cells
              .filter(cell => cell.column.id === column.id)
              .map(cell => (
                <td key={cell.getCellProps().key} style={{ width: `${100 / row.cells.length}%` }}>
                  {cell.render('Cell')}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ChecklistPreview
