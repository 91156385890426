import { TimeSpanType, ITimeSpanUpsert, ITimeSpan } from '../interfaces'

const timeSpanStringToTimeSpanMap = {
  [TimeSpanType.LAST_30_DAYS]: {
    startedAtFromTimeSpan: { days: 30, hours: 0, minutes: 0, seconds: 0 },
    startedAtToTimeSpan: { days: 0, hours: 0, minutes: 0, seconds: 0 },
  },
  [TimeSpanType.LAST_7_DAYS]: {
    startedAtFromTimeSpan: { days: 7, hours: 0, minutes: 0, seconds: 0 },
    startedAtToTimeSpan: { days: 0, hours: 0, minutes: 0, seconds: 0 },
  },
  [TimeSpanType.LAST_1_DAY]: {
    startedAtFromTimeSpan: { days: 1, hours: 0, minutes: 0, seconds: 0 },
    startedAtToTimeSpan: { days: 0, hours: 0, minutes: 0, seconds: 0 },
  },
}

export const areTimeSpansEqual = (timeSpan1?: ITimeSpanUpsert | ITimeSpan, timeSpan2?: ITimeSpanUpsert | ITimeSpan) =>
  timeSpan1?.days === timeSpan2?.days &&
  timeSpan1?.hours === timeSpan2?.hours &&
  timeSpan1?.minutes === timeSpan2?.minutes &&
  timeSpan1?.seconds === timeSpan2?.seconds

export const getTimeSpanTypeByTimeSpan = (fromTimeSpan: ITimeSpan, toTimeSpan: ITimeSpan) => {
  if (!fromTimeSpan || !toTimeSpan) {
    return undefined
  }

  return (Object.keys(timeSpanStringToTimeSpanMap) as unknown as Array<keyof typeof timeSpanStringToTimeSpanMap>).find(
    timeSpanStringToTimeSpanMapKey =>
      areTimeSpansEqual(
        timeSpanStringToTimeSpanMap[timeSpanStringToTimeSpanMapKey].startedAtFromTimeSpan,
        fromTimeSpan,
      ) &&
      areTimeSpansEqual(
        timeSpanStringToTimeSpanMap[timeSpanStringToTimeSpanMapKey].startedAtToTimeSpan,
        toTimeSpan,
      ),
  )
}

export const getTimeSpanByTimeSpanType = (timeSpanType: TimeSpanType) => ({
  fromTimeSpan: timeSpanStringToTimeSpanMap[timeSpanType].startedAtFromTimeSpan || null,
  toTimeSpan: timeSpanStringToTimeSpanMap[timeSpanType].startedAtToTimeSpan || null,
})
