import { validateRequired, validateArrayRequired } from '../../../../util/validation'
import { validateReminderValue } from '../reminder-form/reminder-form.utils'

import { ITrainingTemplateValues } from './training-template-form-types'

export const validateTrainingValue = (trainingTemplateValues: ITrainingTemplateValues) => {
  let errorObject: any = {
    name: undefined,
    checklists: undefined,
    notificationGroupIds: undefined,
    sortOrder: undefined,
    reminder: undefined,
  }

  errorObject.name = validateRequired(trainingTemplateValues?.name)

  if (trainingTemplateValues?.checklistItems && trainingTemplateValues?.checklistItems?.length > 0) {
    errorObject.checklistItems = (trainingTemplateValues?.checklistItems).find(attribute =>
      validateRequired(attribute.name),
    )
      ? 'Name fields are required'
      : undefined

    if (!errorObject.checklistItems) {
      errorObject.checklistItems = trainingTemplateValues?.checklistItems.find(
        (checklistItem, index, checklistItems) =>
          checklistItems.findIndex(selfchecklistItem => selfchecklistItem.name === checklistItem.name) !== index,
      )
        ? 'Name fields must be unique'
        : undefined
    }
  }

  errorObject.notificationGroupIds = validateArrayRequired(trainingTemplateValues?.notificationGroupIds)

  errorObject.sortOrder = validateRequired(trainingTemplateValues?.sortOrder)

  errorObject.reminder = validateReminderValue(trainingTemplateValues)?.reminder

  return errorObject
}
