import { ComponentType } from 'react'

import DocumentUploadProvider from './document-upload-context'

const withDocumentUploadContext = <T extends object>(Component: ComponentType<T>) => {
  const Wrapped = (props: any) => (
    <DocumentUploadProvider {...props}>
      <Component {...props} />
    </DocumentUploadProvider>
  )

  return Wrapped
}

export default withDocumentUploadContext
