import { useMemo, useCallback, useRef } from 'react'
import qs from 'qs'
import { useNavigate, useLocation } from 'react-router-dom'

import { IUseQueryParams1Params, IUseQueryParams1, IUseQueryParams1Options } from './use-query-params-1-types'
import { clearFilter, qsDecoder } from '../util'

const defaultOptions: IUseQueryParams1Options = {
  modularChange: true,
}

const isEmptyFilterAppliedKey = 'isEmptyFilter'

export const useQueryParams1: IUseQueryParams1 = options => {
  const mergedOptions = useMemo(() => Object.assign({}, defaultOptions, options), [options])

  const navigate = useNavigate()

  const location = useLocation()

  const params = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true, decoder: qsDecoder }),
    [location.search],
  )

  const paramsRef = useRef(params)
  paramsRef.current = params

  const setParams = useCallback(
    (newParams: IUseQueryParams1Params) => {
      let calculatedParams: qs.ParsedQs

      if (mergedOptions?.modularChange) {
        calculatedParams = { ...paramsRef.current, ...newParams }
      } else {
        calculatedParams = newParams
      }

      calculatedParams = clearFilter(calculatedParams)
      delete calculatedParams[isEmptyFilterAppliedKey]

      if (Object.keys(calculatedParams).length === 0) {
        calculatedParams = { ...calculatedParams, [isEmptyFilterAppliedKey]: 'true' }
      }

      navigate({ pathname: location.pathname, search: qs.stringify(calculatedParams) }, { replace: true })
    },
    [mergedOptions?.modularChange, location.pathname, navigate],
  )

  const clearParams = useCallback(
    () => navigate({ pathname: location.pathname, search: '' }, { replace: true }),
    [location.pathname, navigate],
  )

  const wasParamsDefinedRef = useRef(Object.keys(params).length > 0 || params[isEmptyFilterAppliedKey] !== undefined)

  return { params, setParams, clearParams, wasParamsDefined: wasParamsDefinedRef.current }
}
