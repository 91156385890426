import { useMemo } from 'react'
import getNotificationGroupsTypeRequest from './get-notification-groups-type-request'
import useAxios from '../use-axios'
import { byNameAscending } from '../../util'

const defaultQueryObject = {
  activeOnly: true,
  includeDetail: false,
}
const useNotificationGroups = (params = {}, queryObject = defaultQueryObject, axiosOptions = {}) => {
  const request = getNotificationGroupsTypeRequest(params, queryObject)

  const { data, error, loading, refetch } = useAxios(request, { defaultValue: [], ...axiosOptions })

  const defaultItem = useMemo(() => data.find(d => d.isDefault), [data])

  const formattedData = useMemo(() => data.sort(byNameAscending), [data])

  return { data: formattedData, defaultItem, error, loading, execute: refetch }
}

export default useNotificationGroups
