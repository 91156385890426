import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useWorkorderFilterQueries from './use-work-order-filter-queries'

import { IWorkOrderFilterFormFiledsProps, IWorkOrderFilterValues } from './work-order-filter-form-types'

const nameof = nameofFactory<IWorkOrderFilterValues>()

const dateRangeLabel = 'Date Range'
const dateTypeLabel = 'Date Type'
const workOrderTypeLabel = 'Work Order Type'
const statusLabel = 'Status'
const priorityLabel = 'Priority'
const facilityLabel = 'Facility'
const conditionLabel = 'Condition'
const notificationGroupLabel = 'Department'
const inspectionSubtypeLabel = 'Inspection Subtype'
const searchTextLabel = 'Text Search'

const WorkOrderFilterFormFields: React.FC<IWorkOrderFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const {
    facilitiesQuery,
    facilitiesOptions,

    conditionsOptions,

    statusesQuery,
    statusOptions,

    prioritiesQuery,
    priorityOptions,

    workOrderTypesQuery,
    workOrderTypesOptions,

    inspectionSubtypesQuery,
    inspectionSubtypeOptions,

    notificationGroupsQuery,
    notificationGroupOptions,

    dateTypeOptions
  } = useWorkorderFilterQueries(values)

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('dateRange')}
            label={dateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(dateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>
        
        <Col>
          <RFFFields.Select
            name={nameof('dateType')}
            label={dateTypeLabel}
            placeholder={getPlaceholderTextFromLabel(dateTypeLabel)}
            options={dateTypeOptions}
            isMulti={false}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('workOrderTypeIds')}
            label={workOrderTypeLabel}
            placeholder={getPlaceholderTextFromLabel(workOrderTypeLabel)}
            options={workOrderTypesOptions}
            disabled={disabled || workOrderTypesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('statusIds')}
            label={statusLabel}
            placeholder={getPlaceholderTextFromLabel(statusLabel)}
            options={statusOptions}
            disabled={disabled || statusesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('priorityIds')}
            label={priorityLabel}
            placeholder={getPlaceholderTextFromLabel(priorityLabel)}
            options={priorityOptions}
            disabled={disabled || prioritiesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('facilityIds')}
            label={facilityLabel}
            placeholder={getPlaceholderTextFromLabel(facilityLabel)}
            options={facilitiesOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('conditionIds')}
            label={conditionLabel}
            placeholder={getPlaceholderTextFromLabel(conditionLabel)}
            options={conditionsOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('notificationGroupIds')}
            label={notificationGroupLabel}
            placeholder={getPlaceholderTextFromLabel(notificationGroupLabel)}
            options={notificationGroupOptions}
            disabled={disabled || notificationGroupsQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('inspectionSubtypeIds')}
            label={inspectionSubtypeLabel}
            placeholder={getPlaceholderTextFromLabel(inspectionSubtypeLabel)}
            options={inspectionSubtypeOptions}
            disabled={disabled || inspectionSubtypesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.SearchInput
            name={nameof('searchText')}
            label={searchTextLabel}
            placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  )
}

export default WorkOrderFilterFormFields
