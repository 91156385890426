import { IGetGeoJsonAccessor, IGetGeoJsonReturnValue } from './get-geo-json-types'

export const getGeoJson = <T>(items: T[] | undefined, accessor: IGetGeoJsonAccessor<T>) => {
  const geoJson = (items || []).reduce<IGetGeoJsonReturnValue>(
    (acc, item) => {
      const geoJson = accessor(item)

      if (!geoJson) {
        return acc
      }

      switch (geoJson?.geometry?.type) {
        case 'Point': {
          acc.markersGeoJson.unshift(geoJson)
          break
        }
        case 'Polygon': {
          acc.polygonsGeoJson.unshift(geoJson)
          break
        }
        default: {
          console.warn('Unhandled geoJson')
        }
      }

      return acc
    },
    {
      markersGeoJson: [],
      polygonsGeoJson: [],
    },
  )

  return geoJson
}
