export const createAction = (type: any) => {
  const actionCreator = (payload?: any) => ({ type, payload })

  actionCreator.toString = () => type

  return actionCreator
}

export const actions = {
  airport: {
    load: {
      request: createAction('AIRPORT_LOADED'),
    },
  },
  maintenance: {
    setMaintenanceBanner: createAction('SET_MAINTENANCE_BANNER'),
    showMaintenanceBanner: createAction('SHOW_MAINTENANCE_BANNER'),
    hideMaintenanceBanner: createAction('HIDE_MAINTENANCE_BANNER'),
  },
  profileDrawer: {
    openProfileDrawer: createAction('OPEN_PROFILE_DRAWER'),
    closeProfileDrawer: createAction('CLOSE_PROFILE_DRAWER'),
  },
  navigationMenuDrawer: {
    openNavigationMenuDrawer: createAction('OPEN_NAVIGATION_MENU_DRAWER'),
    closeNavigationMenuDrawer: createAction('CLOSE_NAVIGATION_MENU_DRAWER'),
  },
  faqDrawer: {
    openFaqDrawer: createAction('OPEN_FAQ_DRAWER'),
    closeFaqDrawer: createAction('CLOSE_FAQ_DRAWER'),
  },
  notificationsDrawer: {
    openNotificationsDrawer: createAction('OPEN_NOTIFICATIONS_DRAWER'),
    closeNotificationsDrawer: createAction('CLOSE_NOTIFICATIONS_DRAWER'),
  },
  releaseNotes: {
    openReleaseNotesReminder: createAction('OPEN_RELEASE_NOTES_REMINDER'),
    closeReleaseNotesReminder: createAction('CLOSE_RELEASE_NOTES_REMINDER'),
    setCurrentReleaseVersion: createAction('SET_CURRENT_RELEASE_VERSION'),
    setLastViewedReleaseVersion: createAction('SET_LAST_VIEWED_RELEASE_VERSION'),
  },
}
