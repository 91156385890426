import { FC } from 'react'
import cn from 'classnames'

import Button from '../button/button'

import { useGoogleMaps } from './google-maps-provider'

import { IDrawingManagerControlProps } from './drawing-manager-control-types'

import s from './drawing-manager-control.module.scss'

const DrawingManagerControl: FC<IDrawingManagerControlProps> = props => {
  const { showDrawingManagerControl = false, renderAdditionalDrawingManagerControl } = props

  // @ts-ignore
  const { mapMode, setMapMode } = useGoogleMaps()

  return (
    <>
      {(showDrawingManagerControl || renderAdditionalDrawingManagerControl) && (
        <div className={s['container']}>
          {showDrawingManagerControl && (
            <>
              <Button
                type="icon-outlined"
                className={cn(s['action'], mapMode === 'clear' && s['action-active'])}
                onClick={() => setMapMode('clear')}
              >
                <i className="bi bi-hand-index text-dark" />
              </Button>
              <Button
                type="icon-outlined"
                className={cn(s['action'], mapMode === 'marker' && s['action-active'])}
                onClick={() => setMapMode('marker')}
              >
                <i className="bi bi-geo-alt text-dark" />
              </Button>
              <Button
                type="icon-outlined"
                className={cn(s['action'], mapMode === 'polygon' && s['action-active'])}
                onClick={() => setMapMode('polygon')}
              >
                <i className="bi bi-pentagon text-dark" />
              </Button>
            </>
          )}

          {renderAdditionalDrawingManagerControl && renderAdditionalDrawingManagerControl()}
        </div>
      )}
    </>
  )
}

export default DrawingManagerControl
