import { buildGetQuery } from '../../util'
import config from '../../config'

import { IDashboardWidget, IGetRequestObject } from '../../interfaces'

const getDashboardWidgetsRequest = (): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/Dashboard/Widgets`,
})

const useDashboardWidgetsQuery = buildGetQuery<undefined, undefined, IDashboardWidget[] | undefined>({
  getRequest: getDashboardWidgetsRequest,
  getQueryKey: () => ['dashboard-widgets'],
})

export default useDashboardWidgetsQuery
