import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getCompleteInspectionMutationRequest from './get-complete-inspection-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'

const useCompleteInspectionMutation: IUseUpdateMutation<undefined, undefined> = (params, options = {}) => {
  const request = useMemo(() => getCompleteInspectionMutationRequest(params), [params])

  const completeInspection: IUpdateFn<number | undefined, undefined> = useCallback(
    async id => {
      const _request =
        typeof id === 'number' ? getCompleteInspectionMutationRequest({ airportId: params.airportId, id }) : request
      return axios(_request).then(res => res.data)
    },
    [request, params.airportId],
  )

  const response = useMutation<undefined, Error, undefined>({
    mutationFn: completeInspection,
    ...options,
  })

  return response
}

export default useCompleteInspectionMutation
