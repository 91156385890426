import config from '../../config'

import { IGetPostRequest } from '../../interfaces'

const getAddChecklistTemplateMutationRequest: IGetPostRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Checklists/templates`,
  }
}

export default getAddChecklistTemplateMutationRequest
