import { Row } from '@tanstack/react-table'

export const byPropName = <TItem>(a: TItem, b: TItem, propName: keyof TItem, ascending = true) => {
  if (a[propName] < b[propName]) {
    return ascending ? -1 : 1
  }

  if (a[propName] > b[propName]) {
    return ascending ? 1 : -1
  }

  return 0
}

export const bySortOrderAscending = <TItem extends { sortOrder: number }>(a: TItem, b: TItem) =>
  byPropName(a, b, 'sortOrder')

export const byTemplateSortOrderAscending = <TItem extends { templateSortOrder: number }>(a: TItem, b: TItem) =>
  byPropName(a, b, 'templateSortOrder')

export const byNameAscending = <TItem extends { name: string }>(a: TItem, b: TItem) => byPropName(a, b, 'name')

export const byAccessor = (a: any, b: any, accessor = (_: any) => {}, ascending = true) => {
  const valueA = accessor(a)
  const valueB = accessor(b)

  if (valueA < valueB) {
    return ascending ? -1 : 1
  }

  if (valueA > valueB) {
    return ascending ? 1 : -1
  }

  return 0
}

export const byStringCaseInsensitive = (a: string, b: string, ascending = true) => {
  const valueA = a === null || a === undefined ? 0 : String(a).toLowerCase()
  const valueB = b === null || b === undefined ? 0 : String(b).toLowerCase()

  if (valueA < valueB) {
    return ascending ? -1 : 1
  }

  if (valueA > valueB) {
    return ascending ? 1 : -1
  }

  return 0
}

export const byNumber = (a: string, b: string, ascending = true) => {
  const valueA = a === null || a === undefined ? 0 : Number(a)
  const valueB = b === null || b === undefined ? 0 : Number(b)

  if (valueA < valueB) {
    return ascending ? -1 : 1
  }

  if (valueA > valueB) {
    return ascending ? 1 : -1
  }

  return 0
}

export const sortColumnByStringInsensitive = (rowA: Row<any>, rowB: Row<any>, columnId: string): number =>
  byStringCaseInsensitive(rowA.getValue(columnId), rowB.getValue(columnId))

export const sortColumnByNumber = (rowA: Row<any>, rowB: Row<any>, columnId: string): number =>
  byNumber(rowA.getValue(columnId), rowB.getValue(columnId))

export const sortByStringDate = (a: any, b: any, asc = true) => {
  const dateA: any = new Date(a)
  const dateB: any = new Date(b)

  return asc ? dateA - dateB : dateB - dateA
}

export const sortColumnByDate = (rowA: Row<any>, rowB: Row<any>, columnId: string) =>
  sortByStringDate(rowA.getValue(columnId), rowB.getValue(columnId))
