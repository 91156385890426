import config from '../../config'
import qs from 'qs'

import { IGetFacilityRequest } from './get-facility-request-types'

const getFacilityRequest: IGetFacilityRequest = (params, queryObject) => {
    const { airportId, id } = params

    const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

    return {
        method: 'get',
        url: `${config.baseUrl}/${airportId}/Facilities/${id}${queryString}`,
    }
}

export default getFacilityRequest
