import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateTrainingSessionMutationRequest from './get-update-training-session-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { ITrainingSession, ITrainingSessionUpdateUpsert } from '../../interfaces'

const useUpdateTrainingSessionMutation: IUseUpdateMutation<ITrainingSession, ITrainingSessionUpdateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateTrainingSessionMutationRequest(params), [params])

  const updateTrainingSession: IUpdateFn<ITrainingSessionUpdateUpsert, ITrainingSession> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITrainingSession, Error, any>({
    mutationFn: updateTrainingSession,
    ...options,
  })

  return response
}

export default useUpdateTrainingSessionMutation
