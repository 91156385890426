import { FC, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Row } from '@tanstack/react-table'
import cn from 'classnames'

import { Modal, RFFFields, DropdownButton } from '../..'
import { useModal } from '../../../hooks'
import { ReorderSettings } from '../table-types'

import s from '../table.module.scss'

type RowPositionCellProps = {
  row: Row<any>
  reorderSettings: ReorderSettings
}

const RowPositionCell: FC<RowPositionCellProps> = props => {
  const { row, reorderSettings } = props

  const { isOpen, closeModal, openModal } = useModal()

  const { isOpen: isDropdownOpen, closeModal: closeDropdown, openModal: openDropdown } = useModal()

  const options = useMemo(
    () => [
      {
        label: 'Move to top',
        // @ts-ignore
        handler: () => reorderSettings.setRowToBegin(row),
      },
      {
        label: 'Move to bottom',
        // @ts-ignore
        handler: () => reorderSettings.setRowToEnd(row),
      },
      {
        label: 'Set the position',
        handler: openModal,
      },
    ],
    [reorderSettings, row, openModal],
  )

  const onSubmit = useCallback(
    (values: any) => {
      // @ts-ignore
      reorderSettings.setRowPosition(row, values.position - 1)
      closeModal()
    },
    [reorderSettings, row, closeModal],
  )

  return (
    <td className={cn(s['td'], s['actions-column'])}>
      <Modal
        isOpen={isOpen}
        title="Update position"
        onClose={closeModal}
        cancelBtnText="Cancel"
        formName="row-position-form"
        confirmBtnText="Update"
      >
        {isOpen && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} id="row-position-form">
                <RFFFields.MaskedInput type="number" label="Position" name="position" />
              </form>
            )}
          />
        )}
      </Modal>

      <div className={s['td-content']}>
        <DropdownButton
          className={s['actions-button']}
          isOpen={isDropdownOpen}
          onClose={closeDropdown}
          onOpen={openDropdown}
          options={options}
        />
      </div>
    </td>
  )
}

export default RowPositionCell
