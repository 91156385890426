import { Drawer } from '../../../components'
import NavigationMenuDrawerHeader from './navigation-menu-drawer-header'
import NavigationMenuDrawerBody from './navigation-menu-drawer-body'

import { useAppContext } from '../../../app-context'
import { actions } from '../../../actions'
import { useCallback } from 'react'

const NavigationMenuDrawer = () => {
  const { state, dispatch } = useAppContext()

  const closeDrawer = useCallback(() => dispatch(actions.navigationMenuDrawer.closeNavigationMenuDrawer()), [dispatch])

  return (
    <Drawer
      isOpen={state.isNavigationMenuDrawerOpen}
      onClose={closeDrawer}
      title={<NavigationMenuDrawerHeader onClose={closeDrawer} />}
      body={<NavigationMenuDrawerBody onClose={closeDrawer} />}
    />
  )
}

export default NavigationMenuDrawer
