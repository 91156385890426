import config from '../../config'

import { IPutRequestObject } from '../../interfaces'

export interface IGetUpdateDiscrepancyMutationRequestParams {
  airportId: number
  inspectionId: number
  discrepancyId: number
}

export interface IGetUpdateDiscrepancyMutationRequest {
  (params: IGetUpdateDiscrepancyMutationRequestParams): IPutRequestObject
}

const getUpdateDiscrepancyMutationRequest: IGetUpdateDiscrepancyMutationRequest = params => {
  const { airportId, inspectionId, discrepancyId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Inspections/${inspectionId}/Discrepancy/${discrepancyId}`,
  }
}

export default getUpdateDiscrepancyMutationRequest
