import { AxiosRequestConfig } from 'axios'

import config from '../../config'

const getUpdateAirportWildlifeSpeciesMutationRequest = (): AxiosRequestConfig => {
  return {
    method: 'put',
    url: `${config.baseUrl}/WildlifeSpecies`,
  }
}

export default getUpdateAirportWildlifeSpeciesMutationRequest
