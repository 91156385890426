import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getReopenInspectionMutationRequest: IGetPutRequest = params => {
  const { airportId, id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Inspections/${id}/Reopen`,
  }
}

export default getReopenInspectionMutationRequest
