import { FC, useRef, useEffect } from 'react'

import RFFFields from '../../react-final-form-fields'
import Button from '../../../button/button'

import { useModal } from '../../../../hooks'

import { IFacilityLocationFilterFormProps } from './facility-locations-filter-types'

const FacilityLocationFilterForm: FC<IFacilityLocationFilterFormProps> = props => {
  const { handleSubmit, submitting, disabled, onChange, values } = props

  const { isOpen: isFilterVisible, toggleModal: toggleFilter } = useModal({
    isOpenByDefault: values?.search !== undefined,
  })

  const prevValuesRef = useRef(values)
  useEffect(() => {
    if (values !== prevValuesRef.current) {
      if (typeof onChange === 'function') {
        onChange(values, prevValuesRef.current)

        prevValuesRef.current = values
      }

      prevValuesRef.current = values
    }
  }, [onChange, values])

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Button onClick={toggleFilter} buttonType="button">
          {isFilterVisible ? 'Hide Filter' : 'Show Filter'}
        </Button>

        <RFFFields.Switch
          name="activeOnly"
          disabled={submitting || disabled}
          onLabel="Active Only"
          offLabel="Active Only"
          wrapperClassName="mb-0"
        />
      </div>

      {isFilterVisible && (
        <>
          <RFFFields.SearchInput
            name="search"
            placeholder="Search locations"
            disabled={submitting || disabled}
            wrapperClassName="w-100 mr-4 mb-2"
          />
        </>
      )}
    </form>
  )
}

export default FacilityLocationFilterForm
