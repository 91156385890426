import { TimeSpanType } from '../../interfaces'

const timeSpan = [
  {
    label: 'Last 30 days',
    value: TimeSpanType.LAST_30_DAYS,
  },
  {
    label: 'Last 7 days',
    value: TimeSpanType.LAST_7_DAYS,
  },
  {
    label: 'Last 1 days',
    value: TimeSpanType.LAST_1_DAY,
  },
]

export default timeSpan
