import React, { MouseEvent, useMemo } from 'react'
import cn from 'classnames'

import { IActionOption } from '../../interfaces'
import useActionsButton from './use-actions-button'
import { Button } from '../button'

import s from './actions-button.module.scss'
import { isPromise } from '../../util'

interface ActionButtonProps {
  options: IActionOption<any>[]
  accessor?: any
  actionClassName?: string
  actionsListClassName?: string
  wrapperClassName?: string
  Component: React.ComponentType<any>
}

const ActionsButton: React.FC<ActionButtonProps> = props => {
  const { options, accessor, actionClassName, wrapperClassName, actionsListClassName, Component } = props
  const { isOpen, toggleModal, buttonRef } = useActionsButton()

  const actions = useMemo(
    () => (
      <div className={cn(s['actions-list'], actionsListClassName)}>
        {options.map(o => (
          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              const res = o.handler(accessor, e)
              if (isPromise(res)) {
                ;(res as Promise<any>).then(() => toggleModal())
              } else {
                toggleModal()
              }
            }}
            key={o.label}
            className={cn(s.action, actionClassName)}
            type="dark-link"
            disabled={o.disabled}
            loading={o.loading}
          >
            {o.label}
          </Button>
        ))}
      </div>
    ),
    [options, actionClassName, actionsListClassName, accessor, toggleModal],
  )

  return (
    <div ref={buttonRef} className={cn(s['actions-button'], wrapperClassName)}>
      <Component
        onClick={(e: any) => {
          e.stopPropagation()
          toggleModal()
        }}
      />

      {isOpen && actions}
    </div>
  )
}

export default ActionsButton
