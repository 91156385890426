import config from '../../config'

import { IGetDeleteLocationMutationRequest } from './types'

const getDeleteLocationMutationRequest: IGetDeleteLocationMutationRequest = (params) => {
  const { airportId, } = params

  return {
    method: 'DELETE',
    url: `${config.baseUrl}/${airportId}/Locations/`,
  }
}

export default getDeleteLocationMutationRequest
