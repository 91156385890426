import { FC, useCallback, useMemo } from 'react'
import cn from 'classnames'

import { Button } from '../button'
import UpdateAuditInfoModal from './update-audit-info-modal'

import { formatDateString } from '../../util'
import { useModal } from '../../hooks'
import { useProfile } from '../../security'

import { IAuthUser, IAuthUserSelectList } from '../../interfaces'

import s from './audit-info.module.scss'
import { IAuditInfoFormValues } from '../form'
import { useAuthUsersQuery } from '../../services'

type AuditInfoProps = {
  info?: {
    startedAt?: string
    startedById?: string
    startedByName?: string
    endedAt?: string
    endedById?: string
  }
  isEnded?: boolean
  isEditable?: boolean
  className?: string
  onChange?: any
  disabled?: boolean
  users?: (IAuthUser | IAuthUserSelectList)[]
  saveButtonText?: string
}

const AuditInfo: FC<AuditInfoProps> = props => {
  const {
    info,
    users,
    className,
    onChange,
    disabled,
    isEditable = true,
    isEnded = false,
    saveButtonText = 'Save',
  } = props

  const { airportId } = useProfile()

  const { data: authUsers } = useAuthUsersQuery(
    { airportId },
    { activeOnly: true, includeDetail: true },
    { enabled: users === undefined && !info?.startedByName },
  )

  const _users = useMemo(() => users || authUsers || [], [users, authUsers])

  const { isOpen: isUpdateModalOpen, closeModal: closeUpdateModalOpen, openModal: openUpdateModalOpen } = useModal()

  const userName = useMemo(() => {
    if (info?.startedByName) {
      return info.startedByName
    }

    if (!Array.isArray(_users)) {
      return ''
    }

    const user = _users.find(u => u.id === info?.startedById)

    return user ? user.email : ''
  }, [_users, info?.startedById, info?.startedByName])

  const date = useMemo(() => formatDateString(info?.startedAt), [info?.startedAt])

  const onSave = useCallback(
    (info: IAuditInfoFormValues) => {
      onChange(info)
      closeUpdateModalOpen()
    },
    [onChange, closeUpdateModalOpen],
  )

  return (
    <>
      <div className={cn(s['audit-info-container'], className)}>
        <div className="text-dark">{`${date} by ${userName}`}</div>

        {isEditable && (
          <Button
            type="ghost-icon"
            className={s['audit-info-edit-btn']}
            onClick={openUpdateModalOpen}
            disabled={disabled}
          >
            <i className="bi bi-pencil-square" />
          </Button>
        )}
      </div>

      <UpdateAuditInfoModal
        isOpen={isUpdateModalOpen}
        closeModal={closeUpdateModalOpen}
        isEnded={isEnded}
        initialValues={info}
        onSave={onSave}
        saveButtonText={saveButtonText}
      />
    </>
  )
}

export default AuditInfo
