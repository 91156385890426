import { useMemo } from 'react'
import { DrawingManager as GoogleDrawingManager } from '@react-google-maps/api'

import { IDrawingManagerProps } from './drawing-manager-types'

const DRAWING_MANAGER_OPTIONS = {
  drawingControl: false,
}

const DrawingManager = (props: IDrawingManagerProps) => {
  const { mapMode } = props

  const { onMarkerComplete, onPolygonComplete } = props?.drawingManagerOptions || {}

  const options = useMemo(() => ({ ...DRAWING_MANAGER_OPTIONS, drawingMode: mapMode }), [mapMode])

  return (
    <GoogleDrawingManager options={options} onMarkerComplete={onMarkerComplete} onPolygonComplete={onPolygonComplete} />
  )
}

export default DrawingManager
