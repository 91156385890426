export type SelectOption = {
  label: string | number
  value: string | number
}

interface IOptions {
  getLabel?: (dataItem: any) => string | number
  getValue?: (dataItem: any) => string | number
}

export const toOptions = (data?: any[], options?: IOptions): SelectOption[] => {
  if (!Array.isArray(data)) {
    return []
  }

  return data.map(dataItem => ({
    label: options?.getLabel ? options?.getLabel(dataItem) : dataItem.name,
    value: options?.getValue ? options?.getValue(dataItem) : dataItem.id,
  }))
}
