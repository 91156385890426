import config from '../../config'

export interface IGetTrainingsSearchByDepartmentPerspectiveQueryObject {
  startedAt?: string
  endedAt?: string
  trainingStatusIds?: number[]
  employeeIds?: number[]
  trainingTemplateIds?: number[]
  notificationGroupIds?: number[]
  searchText?: string
  sortBy?: string
  sortOrder?: string
}

export interface IGetTrainingsSearchByDepartmentPerspectiveRequestObject {
  method: 'post'
  url: string
  data?: IGetTrainingsSearchByDepartmentPerspectiveQueryObject
}

export interface IGetTrainingsSearchByDepartmentPerspectiveRequest {
  (
    querObject?: IGetTrainingsSearchByDepartmentPerspectiveQueryObject,
  ): IGetTrainingsSearchByDepartmentPerspectiveRequestObject
}

const getTrainingsSearchByDepartmentPerspectiveRequest: IGetTrainingsSearchByDepartmentPerspectiveRequest =
  querObject => {
    return {
      method: 'post',
      url: `${config.baseUrl}/Trainings/SearchByDepartmentPerspective`,
      data: querObject,
    }
  }

export default getTrainingsSearchByDepartmentPerspectiveRequest
