import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useInspectionFilterQueries from './use-inspection-filter-queries'

import { IInspectionFilterFormFiledsProps, IInspectionFilterValues } from './inspection-filter-form-types'

const nameof = nameofFactory<IInspectionFilterValues>()

const dateRangeLabel = 'Inspection Date Range'
const inspectorLabel = 'Inspector'
const inspectionCategoryLabel = 'Inspection Category'
const inspectionTypeLabel = 'Inspection Type'
const inspectionSubtypeLabel = 'Inspection Subtype'
const searchTextLabel = 'Text Search'

const InspectionFilterFormFields: React.FC<IInspectionFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const {
    authUsersQuery,
    authUsersOptions,

    inspectionCategoriesQuery,
    inspectionCategoriesOptions,

    inspectionTypesQuery,
    inspectionTypesOptions,

    inspectionSubtypesQuery,
    inspectionSubtypesOptions,
  } = useInspectionFilterQueries(values)

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('dateRange')}
            label={dateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(dateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('inspectorIds')}
            label={inspectorLabel}
            placeholder={getPlaceholderTextFromLabel(inspectorLabel)}
            options={authUsersOptions}
            disabled={disabled || authUsersQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('inspectionCategoryIds')}
            label={inspectionCategoryLabel}
            placeholder={getPlaceholderTextFromLabel(inspectionCategoryLabel)}
            options={inspectionCategoriesOptions}
            disabled={disabled || inspectionCategoriesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('inspectionTypeIds')}
            label={inspectionTypeLabel}
            placeholder={getPlaceholderTextFromLabel(inspectionTypeLabel)}
            options={inspectionTypesOptions}
            disabled={disabled || inspectionTypesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('inspectionSubtypeIds')}
            label={inspectionSubtypeLabel}
            placeholder={getPlaceholderTextFromLabel(inspectionSubtypeLabel)}
            options={inspectionSubtypesOptions}
            disabled={disabled || inspectionSubtypesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.SearchInput
            name={nameof('searchText')}
            label={searchTextLabel}
            placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  )
}

export default InspectionFilterFormFields
