import { useEffect, useCallback } from 'react'

import { Button } from '../../button'

import { useNotificationsQuery } from '../../../services/notifications'
import { useAuthContext, useProfile } from '../../../security'
import { useAppContext } from '../../../app-context'

import config from '../../../config'
import { actions } from '../../../actions'

import '../navbar-icon-actions-button.scss'

import s from './notifications-button.module.scss'
import classNames from 'classnames'

type NotificationButtonProps = {
  notificationClick: () => any
  className?: string
}

const NotificationButton: React.FC<NotificationButtonProps> = ({ notificationClick = () => {}, className }) => {
  const { airportId, userId } = useProfile()

  const { state, dispatch } = useAppContext()

  const { isAuthenticated } = useAuthContext()

  const notifications = useNotificationsQuery({ airportId }, { userId }, { enabled: isAuthenticated })

  useEffect(() => {
    const interval = setInterval(notifications.refetch, config.notifications.pollingIntervalInMilliseconds)

    return () => clearInterval(interval)
  }, [notifications.refetch])

  const toggleNotificationsDrawer = useCallback(
    () =>
      state.isNotificationsDrawerOpen
        ? dispatch(actions.notificationsDrawer.closeNotificationsDrawer())
        : dispatch(actions.notificationsDrawer.openNotificationsDrawer()),
    [state.isNotificationsDrawerOpen, dispatch],
  )

  const notificationCount = notifications.data?.length || 0

  return (
    <>
      <Button
        type="ghost-icon"
        className={classNames(s['notification-button'], 'navbar-icon-actions-button', className)}
        onClick={toggleNotificationsDrawer}
      >
        <i className="bi bi-bell-fill" />
        {notificationCount > 0 && (
          <sup>
            <span className="badge badge-pill bg-danger text-white" style={{ minWidth: '17px' }}>
              {notificationCount}
            </span>
          </sup>
        )}
      </Button>
    </>
  )
}

export default NotificationButton
