import axios from 'axios'
import qs from 'qs'

import config from '../../config'

const deleteInspectionTypeBuilder = (params = {}, queryObject) => {
  const { airportId, id } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  const url = `${config.baseUrl}/${airportId}/InspectionTypeBuilders/${id}${queryString}`
  return axios.delete(url)
}

export default deleteInspectionTypeBuilder
