import { FC, useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import cn from 'classnames'

import { generateId } from '../../util'

import { ITag } from '../../interfaces'

import s from './tags.module.scss'

type TagType =
  | 'lightSuccess'
  | 'lightSecondary'
  | 'lightPrimary'
  | 'lightInfo'
  | 'lightWarning'
  | 'lightDanger'
  | 'lightDark'

type TagsProps = {
  tags?: ITag[]
  tagsType?: TagType
  className?: string
}

type TagProps = {
  tag: ITag
  className?: string
}

export const Tag: FC<TagProps> = props => {
  const { tag, className } = props

  return (
    <span key={tag.id} className={className || 'badge badge-light bg-success text-white'}>
      {tag.name}
    </span>
  )
}

const TAGS_TYPE_TO_CLASS_NAME_MAP: Record<TagType, string> = {
  lightSuccess: cn(s['badge'], 'badge', s['badge-light-success']),
  lightSecondary: cn(s['badge'], 'badge', s['badge-light-secondary']),
  lightPrimary: cn(s['badge'], 'badge', s['badge-light-primary']),
  lightInfo: cn(s['badge'], 'badge', s['badge-light-info']),
  lightWarning: cn(s['badge'], 'badge', s['badge-light-warning']),
  lightDanger: cn(s['badge'], 'badge', s['badge-light-danger']),
  lightDark: cn(s['badge'], 'badge', s['badge-light-dark']),
}

const Tags: FC<TagsProps> = props => {
  const { tags, tagsType, className } = props

  const tagClassName = useMemo(() => tagsType && TAGS_TYPE_TO_CLASS_NAME_MAP[tagsType], [tagsType])

  if (!tags) {
    return null
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip className="tooltip-auto-width" id={generateId()}>
          {tags.map(tag => (
            <Tag tag={tag} key={tag.id} className="mr-1" />
          ))}
        </Tooltip>
      }
    >
      <div className={cn(s['labels-column'], className)}>
        {tags.map(tag => (
          <Tag
            tag={tag}
            key={tag.id}
            className={'ml-1 ' + (tagClassName || 'badge badge-light bg-success text-white')}
          />
        ))}
      </div>
    </OverlayTrigger>
  )
}

export default Tags
