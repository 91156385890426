import { Input } from '../form/input'
import { DatePicker } from '../form/date-picker'
import { Select } from '../form/select'
import { Checkbox } from '../form/checkbox'

export const DND_ROW_ITEM_TYPE = 'CHECKLIST_DND_ROW_ITEM_TYPE'

export const DND_COL_ITEM_TYPE = 'CHECKLIST_DND_COL_ITEM_TYPE'

export const CHECKLIST_CELL_CONFIG: any = {
  string: {
    Component: Input,
    tdClassName: 'td-string',
  },
  date: {
    Component: DatePicker,
    tdClassName: 'td-date',
  },
  facility: {
    Component: Select,
    tdClassName: 'td-facility',
  },
  condition: {
    Component: Select,
    tdClassName: 'td-condition',
  },
  checkbox: {
    Component: Checkbox,
    tdClassName: 'td-checkbox',
  },
}
