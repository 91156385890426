import { createRoot } from 'react-dom/client'
import React from 'react'
import './components/table/react-table-config.d'
import App from './app'
import reportWebVitals from './reportWebVitals'

import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)
const app =
  process.env.NODE_ENV === 'development' ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
root.render(app)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
