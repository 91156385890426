import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IFacility, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  inspectionTypeId?: number
  inspectionSubtypeId?: number
  activeOnly?: boolean
}

const getFacilitiesByInspectionTypeRequest = (
  params: Params,
  queryObject: QueryObject | undefined,
): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${params.airportId}/Facilities/GetAllByInspectionType${queryString}`,
  }
}

const useFacilitiesByInspectionTypeQuery = buildGetQuery<Params, QueryObject | undefined, IFacility[] | undefined>({
  getRequest: getFacilitiesByInspectionTypeRequest,
  getQueryKey: (params, queryObject) => [
    'facilities-by-inspection-type',
    params.airportId,
    queryObject?.inspectionTypeId,
    queryObject?.inspectionSubtypeId,
    queryObject?.activeOnly,
  ],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useFacilitiesByInspectionTypeQuery
