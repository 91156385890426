import axios from 'axios'

import config from '../../config'

const createWorkOrderStatus = async (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/WorkOrderStatuses`

  return axios.post(url, data)
}

export default createWorkOrderStatus
