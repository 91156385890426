import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortWorkOrderStatusesMutationRequest from './get-sort-work-order-statuses-mutation-request'

import {
  IUseSortWorkOrderStatusesMutation,
  ISortWorkOrderStatuses,
} from './use-sort-work-order-statuses-mutation-types'

const useSortWorkOrderStatusesMutation: IUseSortWorkOrderStatusesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortWorkOrderStatusesMutationRequest(params), [params])

  const sortWorkOrderStatuses: ISortWorkOrderStatuses = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortWorkOrderStatuses,
    ...options,
  })

  return response
}

export default useSortWorkOrderStatusesMutation
