import React, { useCallback, useState } from 'react'
import { Button as BsButton, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from '../../../app-context'
import { showErrorAlert, showSuccessAlert } from '../../../components'
import { Button } from '../../../components/button'
import ReleaseNotesButton from './release-notes-button/release-notes-button'
import ReportIssue from '../../../components/report-issue'
import createReportIssue from '../../../services/create-report-issue'
import { useAuthContext } from '../../../security'

import s from '../drawer-body.module.scss'

type FaqDrawerBodyProps = {
  onClose: () => any
  showRedBubble: boolean
}

type ReportIssueType = {
  summary: string
  description: string
  environment: string
  isSaving: boolean
}

const buttonsBgColor = 'transparent'
const iconFontColor = 'var(--gks-blue)'

const FaqDrawerBody: React.FC<FaqDrawerBodyProps> = ({ onClose, showRedBubble }) => {
  const { dispatch: appDispatch } = useAppContext()
  const navigate = useNavigate()

  const { isAuthenticated } = useAuthContext()
  const [showReportIssue, setShowReportIssue] = useState(false)
  const [reportIssue, setReportIssue] = useState<ReportIssueType>({
    summary: '',
    description: '',
    environment: '',
    isSaving: false,
  })

  const setIsIssueReportSaving = useCallback((val: any) => {
    setReportIssue(prev => ({
      ...prev,
      isSaving: val,
    }))
  }, [])

  const submitReportIssue = useCallback(
    async (data: any) => {
      try {
        setIsIssueReportSaving(true)
        await createReportIssue(data)
        appDispatch(showSuccessAlert('Issue has been reported'))
        setIsIssueReportSaving(false)
        setShowReportIssue(false)
      } catch (error) {
        console.error(error)
        appDispatch(showErrorAlert('Unable to report issue'))
        setIsIssueReportSaving(false)
      }
    },
    [appDispatch, setIsIssueReportSaving],
  )

  const reportIssueOnChange = useCallback((val: any) => {
    setReportIssue({
      ...val,
      environment: window.location.toString(),
    })
  }, [])

  return (
    <>
      <div className={s['container']}>
        <div className="d-flex flex-column align-items-start">
          <div className="w-100 mb-4 rounded" style={{ backgroundColor: buttonsBgColor }}>
            <ReleaseNotesButton
              className={`${s['faq-menu-option']} w-100 text-left`}
              additionalOnClick={onClose}
              showRedBubble={showRedBubble}
            />
          </div>
          <div className="w-100 mb-4 rounded" style={{ backgroundColor: buttonsBgColor }}>
            <Button
              className={`${s['faq-menu-option']} w-100 text-left`}
              type="secondary"
              onClick={() => {
                navigate('/faq')
                onClose()
              }}
            >
              <i className="bi bi-question-circle" style={{ color: iconFontColor }} />
              <span className="ml-2">Frequently Asked Questions</span>
            </Button>
          </div>
          {isAuthenticated && (
            <div className="w-100 mb-4 rounded" style={{ backgroundColor: buttonsBgColor }}>
              <Button
                className={`${s['faq-menu-option']} w-100 text-left`}
                type="secondary"
                onClick={() => setShowReportIssue(true)}
              >
                <i className="bi bi-bug" style={{ color: iconFontColor }} />
                <span className="ml-2">Report Issue</span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <Modal show={showReportIssue} animation={false} centered>
        <Modal.Header>
          <Modal.Title>Customer Support</Modal.Title>
        </Modal.Header>

        <Modal.Body>{showReportIssue && <ReportIssue onChange={reportIssueOnChange} />}</Modal.Body>
        <Modal.Footer>
          <BsButton
            variant="secondary"
            onClick={() => {
              setShowReportIssue(false)
            }}
          >
            Cancel
          </BsButton>
          <BsButton
            variant="primary"
            onClick={() => {
              submitReportIssue(reportIssue)
            }}
            disabled={!reportIssue.summary || !reportIssue.description || reportIssue.isSaving}
          >
            Send
          </BsButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

FaqDrawerBody.defaultProps = {
  onClose: () => {},
}

export default FaqDrawerBody
