import qs from 'qs'

import config from '../../config'

const getSafetyItemUniqueValuesRequest = (params = {}, queryObject) => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/SafetyItemUniqueValues${queryString}`,
  }
}

export default getSafetyItemUniqueValuesRequest
