import React, { useCallback, useRef, useContext, useState, useEffect, useMemo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { HeaderGroup } from 'react-table'

import ChecklistColumnModal from '../checklist-column-modal/checklist-column-modal'
import { DropdownButton } from '../../dropdown-button'

import { ChecklistContext } from '../checklist-context/checklist-context'

import { useModal } from '../../../hooks'

import { DND_COL_ITEM_TYPE } from '../checklist-constants'

import { IColumnFormValues } from '../../form/forms/checklist-column-definition/checklist-column-definition-types'
import { ChecklistRow } from '../checklist-types'

import s from './checklist-header-cell.module.scss'

type IChecklistHeaderCellProps = {
  header: HeaderGroup<ChecklistRow>
  columnIndex: number
}

const ChecklistHeaderCell: React.FC<IChecklistHeaderCellProps> = props => {
  const { addChecklistColumn, updateChecklistColumn, removeChecklistColumn, moveColumn, isConfigurable } =
    useContext(ChecklistContext)

  const { columnIndex, header } = props

  // @ts-ignore
  const { Header, attributes, columnType, isReadOnly } = header

  const { isOpen: isAddOpen, openModal: openAddModal, closeModal: closeAddModal } = useModal()
  const { isOpen: isEditOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal()
  const { isOpen: isMenuOpen, closeModal: closeMenu, openModal: openMenu } = useModal()

  const isAddingLeft = useRef<boolean | undefined>()

  const [initialValues, setInitialValues] = useState({})

  const onAddColumn = useCallback(
    (values: IColumnFormValues) => {
      const index = isAddingLeft.current ? columnIndex : columnIndex + 1

      addChecklistColumn(values, index)
      closeAddModal()
    },
    [addChecklistColumn, columnIndex, closeAddModal],
  )

  const onEditColumn = useCallback(
    (values: IColumnFormValues) => {
      updateChecklistColumn(values, columnIndex)
      closeEditModal()
    },
    [updateChecklistColumn, columnIndex, closeEditModal],
  )

  const onRemoveColumn = useCallback(() => {
    removeChecklistColumn(columnIndex)
  }, [removeChecklistColumn, columnIndex])

  const [, dragRef, preview] = useDrag(
    () => ({
      type: DND_COL_ITEM_TYPE,
      item: props,
      canDrag: isConfigurable,
    }),
    [props, isConfigurable],
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  const [, dropRef] = useDrop({
    accept: DND_COL_ITEM_TYPE,
    hover(item) {
      // @ts-ignore
      if (item.id === props.id) {
        return
      }

      moveColumn(item, props)
    },
  })

  const actionsOptions = useMemo(
    () => [
      {
        label: 'Edit',
        handler: () => {
          openEditModal()
          setInitialValues({
            name: Header,
            type: columnType,
            attributes: (attributes || []).map((attribute: any) => ({ name: attribute.label, value: attribute.value })),
            isReadOnly,
          })
        },
      },
      {
        label: 'Add Column Left',
        handler: () => {
          openAddModal()
          isAddingLeft.current = true
        },
      },
      {
        label: 'Add Column Right',
        handler: () => {
          openAddModal()
          isAddingLeft.current = false
        },
      },
      {
        label: 'Delete',
        handler: onRemoveColumn,
      },
    ],
    [Header, columnType, onRemoveColumn, openAddModal, openEditModal, isReadOnly, attributes],
  )

  return (
    <>
      <div
        className={s['header']}
        ref={ref => {
          dropRef(ref)
          dragRef(ref)
        }}
      >
        <div className={s['header-container']}>
          {/* @ts-ignore */}
          <span className="ml-2">{Header}</span>
        </div>

        {isConfigurable && (
          <DropdownButton options={actionsOptions} isOpen={isMenuOpen} onClose={closeMenu} onOpen={openMenu} />
        )}
      </div>

      <ChecklistColumnModal
        values={{ columnsAmount: 1 }}
        isOpen={isAddOpen}
        closeModal={closeAddModal}
        onSubmit={onAddColumn}
      />

      <ChecklistColumnModal
        values={initialValues}
        isOpen={isEditOpen}
        closeModal={closeEditModal}
        onSubmit={onEditColumn}
      />
    </>
  )
}

export default ChecklistHeaderCell
