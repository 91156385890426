import React from 'react'
import { Alert, Button } from 'react-bootstrap'

import { useAppContext } from '../../app-context'
import config from '../../config'

import './alert.css'
import { newVersionAvailableText } from './alert.constants'

type GksAlertProps = {
  timeoutInMilliseconds?: number
}

const GksAlert: React.FC<GksAlertProps> = ({ timeoutInMilliseconds = config.alert.timeoutInMilliseconds }) => {
  const { state, dispatch } = useAppContext()

  const hide = () => dispatch({ type: 'HIDE_ALERT' })

  const { alert } = state
  const { message, show, variant } = alert

  const isNewVersionAlert = message === newVersionAvailableText

  if (show) {
    if (!isNewVersionAlert) {
      setTimeout(() => hide(), timeoutInMilliseconds)
    }
  }

  return isNewVersionAlert ? (
    <Alert className="m-2" dismissible onClose={hide} show={show} transition={false} variant={variant}>
      <div className="d-flex align-items-center">
        <span className="mr-auto">{message}</span>
        <Button variant="primary" onClick={() => window.location.reload()}>Reload</Button>
      </div>
    </Alert>
  ) : (
    <Alert className="m-2" dismissible onClose={hide} show={show} transition={false} variant={variant}>
      {message}
    </Alert>
  )
}

export default GksAlert
