import React from 'react'
import { bool, node, string } from 'prop-types'
import { Info } from '../components'

const LabeledFormGroup = ({ children, className = '', excludeTopMargin = false, label = '', info }) => (
  <div className={`${className} ${excludeTopMargin ? '' : 'mt-4'}`}>
    <label className="d-inline-block">
      {label}
      {info && <Info className="ml-2" info={info} />}
    </label>
    {children}
  </div>
)

LabeledFormGroup.propTypes = {
  children: node.isRequired,
  className: string,
  excludeTopMargin: bool,
  label: string,
}

export default LabeledFormGroup
