import qs from 'qs'
import config from '../../config'

const getNotificationGroupsTypeRequest = (params = {}, queryObject) => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/NotificationGroups${queryString}`,
  }
}

export default getNotificationGroupsTypeRequest
