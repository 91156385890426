import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IFacility, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  workOrderTypeId?: number
  activeOnly?: boolean
}

const getFacilitiesByWorkOrderTypeRequest = (
  params: Params,
  queryObject: QueryObject | undefined,
): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${params.airportId}/Facilities/GetAllByWorkOrderType${queryString}`,
  }
}

const useFacilitiesByWorkOrderTypeQuery = buildGetQuery<Params, QueryObject | undefined, IFacility[] | undefined>({
  getRequest: getFacilitiesByWorkOrderTypeRequest,
  getQueryKey: (params, queryObject) => [
    'facilities-by-work-order-type',
    params.airportId,
    queryObject?.workOrderTypeId,
    queryObject?.activeOnly,
  ],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useFacilitiesByWorkOrderTypeQuery
