import { node } from 'prop-types'

import claimType from './claim-type'
import useAccessControl from './use-access-control'

export const RequiresAllClaims = ({ children, claims }) => {
  const { hasAccess } = useAccessControl()

  const resourceClaims = Array.isArray(claims) ? claims : [claims]

  const allowed = resourceClaims.map(claim => hasAccess(claim)).every(x => x)

  return allowed ? children : null
}

RequiresAllClaims.propTypes = {
  children: node.isRequired,
  claims: claimType.isRequired,
}

export default RequiresAllClaims
