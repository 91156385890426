import React from 'react'
import { shape, string, arrayOf, object, bool, func } from 'prop-types'
import cn from 'classnames'

import { MenuItem } from './menu-item'
import { menuModes } from './menu.constants'

import s from './menu.module.scss'

export const Menu = ({ className, isSearchCachedOnRedirect, mode, options, renderMenuItemLabel, onNavigateCb }) => {
  if (!Array.isArray(options)) {
    return null
  }

  const listClassNames = cn(
    s.menu,
    {
      [s['menu-horizontal']]: mode === menuModes.horizontal,
      [s['menu-vertical']]: mode === menuModes.vertical,
    },
    className,
  )

  return (
    <ul className={listClassNames}>
      {options.map(option => (
        <MenuItem
          {...option}
          onNavigateCb={onNavigateCb}
          mode={mode}
          key={option.to}
          isSearchCachedOnRedirect={isSearchCachedOnRedirect}
          renderMenuItemLabel={renderMenuItemLabel}
        />
      ))}
    </ul>
  )
}

Menu.defaultProps = {
  className: '',
  isSearchCachedOnRedirect: false,
  mode: 'vertical',
}

Menu.propTypes = {
  className: string,
  isSearchCachedOnRedirect: bool,
  mode: string,
  options: arrayOf(shape({ to: string, label: string, icon: object, menuItemClassName: string })).isRequired,
  renderMenuItemLabel: func,
  onNavigateCb: func,
}
