import { createContext, useContext } from 'react'

import { IFeatureFlags, IMapSettings, IMapImagery, IMessageOfTheDay } from './interfaces'

export interface IAppContextAirport {
  id: number | null
  mapImagery: IMapImagery | null
  mapSettings: IMapSettings | null
  name: string
  code: string
  featureFlags?: IFeatureFlags
  timeZoneIanaId: string
}

export interface IAppContextAlert {
  message: string
  show: boolean
  variant?: string
}

export interface IMaintenanceSettings {
  time: string
  hasBeenClosed: boolean
  show: boolean
}

export interface IMaintenance {
  isLoaded: boolean

  messageOfTheDay: IMessageOfTheDay | null
  settings: IMaintenanceSettings | null
}

export interface IReleaseNotesInfo {
  currentReleaseVersion?: string
  lastViewedReleaseVersion?: string
  isReminderOpen: boolean
  discardedReminderVersion?: string
}

export interface IAppContext {
  airport: IAppContextAirport
  alert: IAppContextAlert
  maintenance: IMaintenance
  isProfileDrawerOpen: boolean
  isNavigationMenuDrawerOpen: boolean
  isNotificationsDrawerOpen: boolean
  isFaqDrawerOpen: boolean
  appDataLoaded: boolean
  personalizationMapStatuses: any[]
  releaseNotesInfo?: IReleaseNotesInfo
}

export const initialState: IAppContext = {
  airport: { id: null, mapImagery: null, mapSettings: null, name: '', code: '', timeZoneIanaId: '' },

  alert: { message: '', show: false, variant: '' },

  maintenance: {
    isLoaded: false,

    messageOfTheDay: null,
    settings: null,
  },

  isProfileDrawerOpen: false,

  isNavigationMenuDrawerOpen: false,

  isNotificationsDrawerOpen: false,

  isFaqDrawerOpen: false,

  appDataLoaded: false,

  personalizationMapStatuses: [],

  releaseNotesInfo: {
    currentReleaseVersion: undefined,
    lastViewedReleaseVersion: undefined,
    discardedReminderVersion: undefined,
    isReminderOpen: false,
  },
}

export const AppContext = createContext<{ state: IAppContext; dispatch: React.Dispatch<any> }>({
  state: initialState,
  dispatch: () => null,
})

export const useAppContext = () => useContext(AppContext)
