import { Checkbox } from '../../form/checkbox'

export const RowSelectionHeader = (props: any) => {
  const { table } = props

  return (
    <Checkbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  )
}
