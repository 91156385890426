import cn from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'
import { string, bool, func } from 'prop-types'

import s from './textarea.module.scss'

const Textarea = ({
  isInvalid,
  className,
  onChange,
  value,
  disabled,
  minRows = 2,
  isViewMode,
  placeholder,
  ...inputProps
}) => {
  const inputClassNames = cn('form-control', s['textarea'], className, {
    'border-danger': isInvalid,
    [s['textarea-view']]: isViewMode,
  })

  return (
    <TextareaAutosize
      className={inputClassNames}
      onChange={onChange}
      value={value}
      disabled={disabled || isViewMode}
      placeholder={!isViewMode ? placeholder : undefined}
      minRows={minRows}
      {...inputProps}
    />
  )
}

Textarea.propTypes = {
  isInvalid: bool,
  value: string,
  onChange: func,
  name: string,
  className: string,
  rows: string,
}

export default Textarea
