import { defaultFilterNodes } from '../../checkbox-tree/checkbox-tree.utils'

import { CheckboxTreeFilter, ChecboxTreeNode } from './inspection-type-master-form-types'
import { ITag } from '../../../../interfaces'

const filterByTagIds = (nodes: ChecboxTreeNode[], filter: CheckboxTreeFilter) => {
  if (!filter.tagIds || filter.tagIds.length === 0) {
    return nodes
  }

  return nodes.reduce((filteredNodes: ChecboxTreeNode[], node: ChecboxTreeNode) => {
    if (node.children) {
      const filteredNodeChildren = filterByTagIds(node.children, filter)

      if (filteredNodeChildren.length > 0) {
        filteredNodes = [...filteredNodes, { ...node, children: filteredNodeChildren }]
        return filteredNodes
      }
    }

    return node.original.tags.find((tag: ITag) => (filter.tagIds || []).indexOf(tag.id) > -1)
      ? [...filteredNodes, node]
      : filteredNodes
  }, [])
}

export const filterNodes = (nodes: ChecboxTreeNode[], filter: CheckboxTreeFilter) => {
  const filteredNodesByGlobalFilter = defaultFilterNodes(nodes, filter)

  const filteredNodesByTagIdsFilter = filterByTagIds(filteredNodesByGlobalFilter, filter)

  return filteredNodesByTagIdsFilter
}
