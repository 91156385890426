import config from '../../config'

import { IGetPostRequest } from '../../interfaces'

const getAddTrainingTemplateMutationRequest: IGetPostRequest = params => {
  return {
    method: 'post',
    url: `${config.baseUrl}/TrainingTemplates`,
  }
}

export default getAddTrainingTemplateMutationRequest
