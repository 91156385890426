import { FC, useEffect, useRef } from 'react'

import SafetyConcernFormFields from './safety-concern-form-fields'

import { ISafetyConcernFormProps } from './safety-concern-form-types'

const SafetyConcernForm: FC<ISafetyConcernFormProps> = props => {
  const {
    handleSubmit,
    formName,
    submitting,
    baseStatus,
    additionalOptions,
    values,
    setForm,
    form,
    valid,
    onChange,
    disabled = false,
    isViewMode,
  } = props

  const prevValuesRef = useRef(values)
  useEffect(() => {
    if (typeof onChange === 'function') {
      if (values !== prevValuesRef.current) {
        onChange(values, valid)
        prevValuesRef.current = values
      }
    }
  }, [onChange, valid, values])

  useEffect(() => {
    setForm && setForm(form)
    // eslint-disable-next-line
  }, [setForm])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <SafetyConcernFormFields
        disabled={submitting || disabled}
        baseStatus={baseStatus}
        additionalOptions={additionalOptions}
        isViewMode={isViewMode}
        values={values}
      />
    </form>
  )
}

export default SafetyConcernForm
