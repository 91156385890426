import { useRef, useEffect, useCallback, useState } from 'react'

import { IUseIntervalUpdate } from './use-interval-update-types'

export const useIntervalUpdate: IUseIntervalUpdate = (props) => {
  const { step, max, min, defaultValue, active, interval } = props

  const [value, setValue] = useState(defaultValue)

  const isActive = useRef(false)
  const isUp = useRef(false)

  const animatePolygonIntervalIdRef = useRef<NodeJS.Timer | null>()

  const animatePolygon = useCallback(() => {
    if (animatePolygonIntervalIdRef.current) {
      clearInterval(animatePolygonIntervalIdRef.current)
    }

    isActive.current = true

    const intervalId = setInterval(() => {
      setValue((prev: number) => {
        const acc = prev + step

        if (acc >= max) {
          isUp.current = false
        }

        if (acc <= min) {
          isUp.current = true
        }

        if (isUp.current) {
          return prev + step
        } else {
          return prev - step
        }
      })
    }, interval)

    animatePolygonIntervalIdRef.current = intervalId
  }, [max, min, step, setValue, interval])

  useEffect(() => {
    if (active) {
      if (isActive.current) {
        return
      }

      animatePolygon()
    } else {
      if (animatePolygonIntervalIdRef.current) {
        clearInterval(animatePolygonIntervalIdRef.current)
        setValue(defaultValue)
        isActive.current = false
      }
    }
  }, [animatePolygon, active, setValue, defaultValue])

  return { value }
}
