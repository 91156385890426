import { ClusterIconStyle } from '@react-google-maps/marker-clusterer'

const baseClusterIconStyle: Partial<ClusterIconStyle> = {
  textColor: 'white',
  fontFamily: 'Arial',
  fontStyle: 'normal',
  fontWeight: '700',
}

const smallClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgaWQ9ImJvZHlfMSIgd2lkdGg9IjY4IiBoZWlnaHQ9IjY4Ij4KCjxnIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIDEgMCAwKSI+Cgk8Zz4KICAgICAgICA8cGF0aCBkPSJNNTggMzRDIDU4IDQwLjYyNzQyIDU1LjY1Njg1NyA0Ni4yODQyNyA1MC45NzA1NjIgNTAuOTcwNTYyQyA0Ni4yODQyNyA1NS42NTY4NTcgNDAuNjI3NDIgNTggMzQgNThDIDI3LjM3MjU4MyA1OCAyMS43MTU3MjkgNTUuNjU2ODU3IDE3LjAyOTQzOCA1MC45NzA1NjJDIDEyLjM0MzE0NiA0Ni4yODQyNyAxMCA0MC42Mjc0MiAxMCAzNEMgMTAgMjcuMzcyNTgzIDEyLjM0MzE0NiAyMS43MTU3MjkgMTcuMDI5NDM4IDE3LjAyOTQzOEMgMjEuNzE1NzI5IDEyLjM0MzE0NiAyNy4zNzI1ODMgMTAgMzQgMTBDIDQwLjYyNzQyIDEwIDQ2LjI4NDI3IDEyLjM0MzE0NiA1MC45NzA1NjIgMTcuMDI5NDM4QyA1NS42NTY4NTcgMjEuNzE1NzI5IDU4IDI3LjM3MjU4MyA1OCAzNEMgNTggMzQuMjc5MjggNTcuOTk1MTMgMzQuNTU4NDggNTcuOTg1MzgyIDM0LjgzNzU5IiBzdHJva2U9Im5vbmUiIGZpbGw9IiM0ODI3NkEiIGZpbGwtcnVsZT0ibm9uemVybyIgLz4KICAgICAgICA8cGF0aCBkPSJNNTcgMzRDIDU3IDQwLjM1MTI3NiA1NC43NTQ0ODYgNDUuNzcyNDMgNTAuMjYzNDYgNTAuMjYzNDZDIDQ1Ljc3MjQzIDU0Ljc1NDQ4NiA0MC4zNTEyNzYgNTcgMzQgNTdDIDI3LjY0ODcyNiA1NyAyMi4yMjc1NzMgNTQuNzU0NDg2IDE3LjczNjU0NCA1MC4yNjM0NkMgMTMuMjQ1NTE1IDQ1Ljc3MjQzIDExIDQwLjM1MTI3NiAxMSAzNEMgMTEgMjcuNjQ4NzI2IDEzLjI0NTUxNSAyMi4yMjc1NzMgMTcuNzM2NTQ0IDE3LjczNjU0NEMgMjIuMjI3NTczIDEzLjI0NTUxNSAyNy42NDg3MjYgMTEgMzQgMTFDIDQwLjM1MTI3NiAxMSA0NS43NzI0MyAxMy4yNDU1MTUgNTAuMjYzNDYgMTcuNzM2NTQ0QyA1NC43NTQ0ODYgMjIuMjI3NTczIDU3IDI3LjY0ODcyNiA1NyAzNEMgNTcgMzQuMjY3NjQ3IDU2Ljk5NTMzIDM0LjUzNTIxIDU2Ljk4NTk5IDM0LjgwMjY5IiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgLz4KCTwvZz4KPC9nPgo8L3N2Zz4=',
  height: 40,
  width: 40,
  textSize: 10,
}

const mediumClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgaWQ9ImJvZHlfMSIgd2lkdGg9IjY4IiBoZWlnaHQ9IjY4Ij4KCjxnIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIDEgMCAwKSI+Cgk8Zz4KICAgICAgICA8cGF0aCBkPSJNNTggMzRDIDU4IDQwLjYyNzQyIDU1LjY1Njg1NyA0Ni4yODQyNyA1MC45NzA1NjIgNTAuOTcwNTYyQyA0Ni4yODQyNyA1NS42NTY4NTcgNDAuNjI3NDIgNTggMzQgNThDIDI3LjM3MjU4MyA1OCAyMS43MTU3MjkgNTUuNjU2ODU3IDE3LjAyOTQzOCA1MC45NzA1NjJDIDEyLjM0MzE0NiA0Ni4yODQyNyAxMCA0MC42Mjc0MiAxMCAzNEMgMTAgMjcuMzcyNTgzIDEyLjM0MzE0NiAyMS43MTU3MjkgMTcuMDI5NDM4IDE3LjAyOTQzOEMgMjEuNzE1NzI5IDEyLjM0MzE0NiAyNy4zNzI1ODMgMTAgMzQgMTBDIDQwLjYyNzQyIDEwIDQ2LjI4NDI3IDEyLjM0MzE0NiA1MC45NzA1NjIgMTcuMDI5NDM4QyA1NS42NTY4NTcgMjEuNzE1NzI5IDU4IDI3LjM3MjU4MyA1OCAzNEMgNTggMzQuMjc5MjggNTcuOTk1MTMgMzQuNTU4NDggNTcuOTg1MzgyIDM0LjgzNzU5IiBzdHJva2U9Im5vbmUiIGZpbGw9IiM0ODI3NkEiIGZpbGwtcnVsZT0ibm9uemVybyIgLz4KICAgICAgICA8cGF0aCBkPSJNNTcgMzRDIDU3IDQwLjM1MTI3NiA1NC43NTQ0ODYgNDUuNzcyNDMgNTAuMjYzNDYgNTAuMjYzNDZDIDQ1Ljc3MjQzIDU0Ljc1NDQ4NiA0MC4zNTEyNzYgNTcgMzQgNTdDIDI3LjY0ODcyNiA1NyAyMi4yMjc1NzMgNTQuNzU0NDg2IDE3LjczNjU0NCA1MC4yNjM0NkMgMTMuMjQ1NTE1IDQ1Ljc3MjQzIDExIDQwLjM1MTI3NiAxMSAzNEMgMTEgMjcuNjQ4NzI2IDEzLjI0NTUxNSAyMi4yMjc1NzMgMTcuNzM2NTQ0IDE3LjczNjU0NEMgMjIuMjI3NTczIDEzLjI0NTUxNSAyNy42NDg3MjYgMTEgMzQgMTFDIDQwLjM1MTI3NiAxMSA0NS43NzI0MyAxMy4yNDU1MTUgNTAuMjYzNDYgMTcuNzM2NTQ0QyA1NC43NTQ0ODYgMjIuMjI3NTczIDU3IDI3LjY0ODcyNiA1NyAzNEMgNTcgMzQuMjY3NjQ3IDU2Ljk5NTMzIDM0LjUzNTIxIDU2Ljk4NTk5IDM0LjgwMjY5IiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgLz4KCTwvZz4KPC9nPgo8L3N2Zz4=',
  height: 50,
  width: 50,
  textSize: 12,
}

const largeClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgaWQ9ImJvZHlfMSIgd2lkdGg9IjY4IiBoZWlnaHQ9IjY4Ij4KCjxnIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIDEgMCAwKSI+Cgk8Zz4KICAgICAgICA8cGF0aCBkPSJNNTggMzRDIDU4IDQwLjYyNzQyIDU1LjY1Njg1NyA0Ni4yODQyNyA1MC45NzA1NjIgNTAuOTcwNTYyQyA0Ni4yODQyNyA1NS42NTY4NTcgNDAuNjI3NDIgNTggMzQgNThDIDI3LjM3MjU4MyA1OCAyMS43MTU3MjkgNTUuNjU2ODU3IDE3LjAyOTQzOCA1MC45NzA1NjJDIDEyLjM0MzE0NiA0Ni4yODQyNyAxMCA0MC42Mjc0MiAxMCAzNEMgMTAgMjcuMzcyNTgzIDEyLjM0MzE0NiAyMS43MTU3MjkgMTcuMDI5NDM4IDE3LjAyOTQzOEMgMjEuNzE1NzI5IDEyLjM0MzE0NiAyNy4zNzI1ODMgMTAgMzQgMTBDIDQwLjYyNzQyIDEwIDQ2LjI4NDI3IDEyLjM0MzE0NiA1MC45NzA1NjIgMTcuMDI5NDM4QyA1NS42NTY4NTcgMjEuNzE1NzI5IDU4IDI3LjM3MjU4MyA1OCAzNEMgNTggMzQuMjc5MjggNTcuOTk1MTMgMzQuNTU4NDggNTcuOTg1MzgyIDM0LjgzNzU5IiBzdHJva2U9Im5vbmUiIGZpbGw9IiM0ODI3NkEiIGZpbGwtcnVsZT0ibm9uemVybyIgLz4KICAgICAgICA8cGF0aCBkPSJNNTcgMzRDIDU3IDQwLjM1MTI3NiA1NC43NTQ0ODYgNDUuNzcyNDMgNTAuMjYzNDYgNTAuMjYzNDZDIDQ1Ljc3MjQzIDU0Ljc1NDQ4NiA0MC4zNTEyNzYgNTcgMzQgNTdDIDI3LjY0ODcyNiA1NyAyMi4yMjc1NzMgNTQuNzU0NDg2IDE3LjczNjU0NCA1MC4yNjM0NkMgMTMuMjQ1NTE1IDQ1Ljc3MjQzIDExIDQwLjM1MTI3NiAxMSAzNEMgMTEgMjcuNjQ4NzI2IDEzLjI0NTUxNSAyMi4yMjc1NzMgMTcuNzM2NTQ0IDE3LjczNjU0NEMgMjIuMjI3NTczIDEzLjI0NTUxNSAyNy42NDg3MjYgMTEgMzQgMTFDIDQwLjM1MTI3NiAxMSA0NS43NzI0MyAxMy4yNDU1MTUgNTAuMjYzNDYgMTcuNzM2NTQ0QyA1NC43NTQ0ODYgMjIuMjI3NTczIDU3IDI3LjY0ODcyNiA1NyAzNEMgNTcgMzQuMjY3NjQ3IDU2Ljk5NTMzIDM0LjUzNTIxIDU2Ljk4NTk5IDM0LjgwMjY5IiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgLz4KCTwvZz4KPC9nPgo8L3N2Zz4=',
  height: 60,
  width: 60,
  textSize: 14,
}

export const clusterIconStyles = [smallClusterIconStyle, mediumClusterIconStyle, largeClusterIconStyle]
