import { useEffect } from 'react'

export const useClickOutside = (ref, cb, enabled = true) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb()
      }
    }

    if (!enabled) {
      return
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, cb, enabled])
}
