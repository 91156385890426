import { FC, useMemo, useState, useCallback, ReactElement, createRef } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { DefinedUseQueryResult } from '@tanstack/react-query'
import { FormApi } from 'final-form'

import {
  Tabs,
  SafetyItemPopup,
  SafetyConcernPopup,
  SelectOption,
  ISafetyConcernValues,
  ISafetyConcernUpsertValues,
} from '../../../components'
import ContentMapLayout from '../../../hoc/content-map-layout'
import Map from '../../../components/map/map'
import MarkerLayout from '../../../components/map/marker-layout'
import PolygonLayout from '../../../components/map/polygon-layout'
import SafetyConcernInfo from './safety-concern-info'
import SafetyConcernActions from './safety-concern-actions'
import SafetyConcernWorkOrders from './safety-concern-work-orders'

import { getGeoJson, markerToGeoJson, polygonToGeoJson } from '../../../util'
import { useSafetyConcernWorkOrdersQuery } from '../../../services'
import { useSafeQueryParams } from '../../../hooks'
import { queryParamsToParams } from './safety-concerns-info-utils'
import { DEFAULT_MARKER_COLOR } from '../../../components/map/hooks/use-marker-icons'

import { ISafetyConcern, IWorkOrder, IGeoJson, IRefs } from '../../../interfaces'

import s from './safety-concerns-page.module.scss'

type SafetyConcernPageBuilderType = {
  hasWriteAccess?: boolean
  handleSaveSafetyConcern: (safetyConcernValues: ISafetyConcernValues) => void
  safetyConcernQuery?: DefinedUseQueryResult<ISafetyConcern | undefined, Error>
  setHeaderControls: (headerControls?: ReactElement) => void
  saveHeaderControls: ReactElement
  setSafetyConcernGeoJson: (geoJson: IGeoJson | undefined) => void
  safetyConcernGeoJson?: IGeoJson
  setSaveSafetyConcernForm?: (saveSafetyConcernForm: FormApi<ISafetyConcernValues>) => void
  saveSafetyConcernInitialValues?: ISafetyConcernUpsertValues
  setSaveSafetyConcernValues?: (safetyConcernUpsertValues: ISafetyConcernUpsertValues) => void
  isSaveFormDisabled?: boolean
}

const SafetyConcernPageBuilder: FC<SafetyConcernPageBuilderType> = props => {
  const {
    hasWriteAccess,
    handleSaveSafetyConcern,
    safetyConcernQuery,
    setHeaderControls,
    setSafetyConcernGeoJson,
    saveHeaderControls,
    safetyConcernGeoJson,
    setSaveSafetyConcernForm,
    saveSafetyConcernInitialValues,
    setSaveSafetyConcernValues,
    isSaveFormDisabled,
  } = props

  const location = useLocation()

  const safetyConcern = safetyConcernQuery?.data

  const additionalSaveSafetyConcernOptions = useMemo(
    () => ({
      getStatusesOptions: (options: SelectOption[]) => {
        if (!safetyConcern) {
          return options
        }

        if (options.find(option => option.value === safetyConcern.parentStatus.id)) {
          return options
        }

        return [
          { value: safetyConcern.parentStatus.id, label: safetyConcern.parentStatus.name || 'Unknown Status' },
          ...options,
        ]
      },
    }),
    [safetyConcern],
  )

  const isSafetyConcernFilled = useMemo(() => Boolean(safetyConcernQuery?.data?.summary), [safetyConcernQuery?.data])

  const routes = useMemo(
    () => [
      { to: 'info', label: 'General', allowedClaims: '*', replace: true },
      {
        to: 'actions',
        label: 'Actions Taken',
        disabled: !isSafetyConcernFilled,
        allowedClaims: '*',
        replace: true,
        search: location.search,
      },
      {
        to: 'work-orders',
        label: 'Work Orders',
        disabled: !isSafetyConcernFilled,
        allowedClaims: '*',
        search: location.search,
        replace: true,
      },
    ],
    [isSafetyConcernFilled, location.search],
  )

  const [workOrderActiveGeoJsonId, setWorkOrderActiveGeoJsonId] = useState<number | undefined>(undefined)
  const [workOrderInfoGeoJsonId, setWorkOrderInfoGeoJsonId] = useState<number | undefined>(undefined)

  const workOrdersQuery = useSafetyConcernWorkOrdersQuery(
    { id: Number(safetyConcern?.id) },
    { enabled: safetyConcern?.id !== undefined && safetyConcern?.id !== null },
  )

  const workOrdersRefs = useMemo(
    () =>
      ((workOrdersQuery?.data || []) as IWorkOrder[]).reduce<IRefs>((acc, value) => {
        if (value.id) {
          acc[value.id] = createRef()
        }

        return acc
      }, {}),
    [workOrdersQuery?.data],
  )

  const { params, setParams } = useSafeQueryParams(queryParamsToParams)

  const workOrdersPagination = useMemo(() => params.workOrders.pagination, [params.workOrders.pagination])

  const setWorkOrdersPagination = useCallback(
    (values: { [key: string]: number }) => {
      setParams({
        workOrders: {
          pagination: {
            ...workOrdersPagination,
            ...values,
          },
        },
      })
    },
    [setParams, workOrdersPagination],
  )

  const _setWorkOrderActiveGeoJsonId = useCallback(
    (workOrderActiveGeoJsonId: number | undefined) => {
      setWorkOrderActiveGeoJsonId(workOrderActiveGeoJsonId)

      if (!workOrderActiveGeoJsonId) {
        return
      }

      const activeSafetyConcernPageIndex = Math.floor(
        (workOrdersQuery?.data || []).findIndex((workOrder: IWorkOrder) => workOrder.id === workOrderActiveGeoJsonId) /
          workOrdersPagination.pageSize,
      )

      if (activeSafetyConcernPageIndex === workOrdersPagination.pageIndex) {
        if (workOrderActiveGeoJsonId && workOrdersRefs[workOrderActiveGeoJsonId]) {
          workOrdersRefs[workOrderActiveGeoJsonId]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      } else {
        setWorkOrdersPagination({
          pageIndex: activeSafetyConcernPageIndex,
        })

        setTimeout(() => {
          if (workOrderActiveGeoJsonId && workOrdersRefs[workOrderActiveGeoJsonId]) {
            workOrdersRefs[workOrderActiveGeoJsonId]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }, 0)
      }
    },
    [workOrdersRefs, workOrdersPagination, setWorkOrdersPagination, workOrdersQuery?.data],
  )

  const { markersGeoJson: workOrdersMarkersGeoJson, polygonsGeoJson: workOrdersPolygonsGeoJson } = getGeoJson(
    workOrdersQuery?.data,
    (workOrder: IWorkOrder) => workOrder?.safetyItem?.geoJson || workOrder?.safetyItem?.location?.geoJson,
  )

  const activeWorkOrderId = useMemo(
    () =>
      workOrderActiveGeoJsonId &&
      workOrdersQuery?.data?.find(
        (workOrder: IWorkOrder) =>
          workOrder.safetyItem?.geoJson?.properties?.id === workOrderActiveGeoJsonId ||
          workOrder.safetyItem?.location?.geoJson?.properties?.id === workOrderActiveGeoJsonId,
      )?.id,
    [workOrdersQuery?.data, workOrderActiveGeoJsonId],
  )

  const renderWorkOrderPopUp = useCallback(
    (geoJson: IGeoJson) => <SafetyItemPopup safetyItemId={geoJson?.properties?.description?.DiscrepancyId} />,
    [],
  )

  const safetyConcernActionsPagination = useMemo(
    () => params.safetyConcernActions.pagination,
    [params.safetyConcernActions.pagination],
  )

  const setSafetyConcernActionsPagination = useCallback(
    (values: { [key: string]: number }) => {
      setParams({
        safetyConcernActions: {
          pagination: {
            ...safetyConcernActionsPagination,
            ...values,
          },
        },
      })
    },
    [setParams, safetyConcernActionsPagination],
  )

  const { markersGeoJson: safetyConcernMarkersGeoJson, polygonsGeoJson: safetyConcernPolygonsGeoJson } = getGeoJson(
    safetyConcernGeoJson ? [safetyConcernGeoJson] : [],
    (geoJson: IGeoJson) => geoJson,
  )

  const renderSafetyConcernPopUp = useCallback(
    () => <SafetyConcernPopup safetyConcern={safetyConcern} />,
    [safetyConcern],
  )

  const [safetyConcernActiveGeoJsonId, setSafetyConcernActiveGeoJsonId] = useState<number | undefined>(undefined)
  const [safetyConcernInfoGeoJsonId, setSafetyConcernInfoGeoJsonId] = useState<number | undefined>(undefined)

  const onSafetyConcernMarkerComplete = useCallback(
    (marker: google.maps.Marker) => {
      if (isSaveFormDisabled) {
        marker.setMap(null)
        return
      }

      const newGeoJson = markerToGeoJson(marker, DEFAULT_MARKER_COLOR)

      if (!newGeoJson) {
        return
      }

      setSafetyConcernGeoJson(newGeoJson)
      marker.setMap(null)
    },
    [isSaveFormDisabled, setSafetyConcernGeoJson],
  )

  const onSafetyConcernPolygonComplete = useCallback(
    (polygon: google.maps.Polygon) => {
      if (isSaveFormDisabled) {
        polygon.setMap(null)
        return
      }

      const newGeoJson = polygonToGeoJson(polygon, DEFAULT_MARKER_COLOR)

      if (!newGeoJson) {
        return
      }

      setSafetyConcernGeoJson(newGeoJson)

      polygon.setMap(null)
    },
    [setSafetyConcernGeoJson, isSaveFormDisabled],
  )

  return (
    <ContentMapLayout contentWrapperClassName={s['content-wrapper-class-name']}>
      {{
        content: (
          <div className="d-flex flex-column w-100">
            <Tabs routes={routes} />

            <Routes>
              <Route index element={<Navigate to="info" replace />} />

              <Route
                path="/info"
                element={
                  <SafetyConcernInfo
                    hasWriteAccess={hasWriteAccess}
                    setHeaderControls={setHeaderControls}
                    setSafetyConcernGeoJson={setSafetyConcernGeoJson}
                    additionalSaveSafetyConcernOptions={additionalSaveSafetyConcernOptions}
                    isSafetyConcernFilled={isSafetyConcernFilled}
                    handleSaveSafetyConcern={handleSaveSafetyConcern}
                    saveHeaderControls={saveHeaderControls}
                    setSaveSafetyConcernForm={setSaveSafetyConcernForm}
                    saveSafetyConcernInitialValues={saveSafetyConcernInitialValues}
                    setSaveSafetyConcernValues={setSaveSafetyConcernValues}
                    isSaveFormDisabled={isSaveFormDisabled}
                    safetyReport={safetyConcern?.safetyReport}
                  />
                }
              />

              {safetyConcern && isSafetyConcernFilled && (
                <Route
                  path="/actions"
                  element={
                    <SafetyConcernActions
                      hasWriteAccess={hasWriteAccess}
                      safetyConcernQuery={safetyConcernQuery}
                      setHeaderControls={setHeaderControls}
                      safetyConcernActionsPagination={safetyConcernActionsPagination}
                      setSafetyConcernActionsPagination={setSafetyConcernActionsPagination}
                    />
                  }
                />
              )}

              {safetyConcern && isSafetyConcernFilled && (
                <Route
                  path="/work-orders"
                  element={
                    <SafetyConcernWorkOrders
                      hasWriteAccess={hasWriteAccess}
                      safetyConcernQuery={safetyConcernQuery}
                      setHeaderControls={setHeaderControls}
                      workOrdersQuery={workOrdersQuery}
                      workOrdersRefs={workOrdersRefs}
                      workOrdersPagination={workOrdersPagination}
                      setWorkOrdersPagination={setWorkOrdersPagination}
                      activeWorkOrderId={activeWorkOrderId}
                    />
                  }
                />
              )}

              <Route path="*" element={null} />
            </Routes>
          </div>
        ),
        map: (
          <Map
            drawingManagerOptions={{
              onMarkerComplete: onSafetyConcernMarkerComplete,
              onPolygonComplete: onSafetyConcernPolygonComplete,
            }}
          >
            <MarkerLayout
              activeId={safetyConcernActiveGeoJsonId}
              setActiveId={setSafetyConcernActiveGeoJsonId}
              infoId={safetyConcernInfoGeoJsonId}
              setInfoId={setSafetyConcernInfoGeoJsonId}
              geoJson={safetyConcernMarkersGeoJson}
              renderPopUp={renderSafetyConcernPopUp}
            />

            <PolygonLayout
              activeId={safetyConcernActiveGeoJsonId}
              setActiveId={setSafetyConcernActiveGeoJsonId}
              infoId={safetyConcernInfoGeoJsonId}
              setInfoId={setSafetyConcernInfoGeoJsonId}
              geoJson={safetyConcernPolygonsGeoJson}
              renderPopUp={renderSafetyConcernPopUp}
            />

            <MarkerLayout
              activeId={workOrderActiveGeoJsonId}
              setActiveId={_setWorkOrderActiveGeoJsonId}
              infoId={workOrderInfoGeoJsonId}
              setInfoId={setWorkOrderInfoGeoJsonId}
              geoJson={workOrdersMarkersGeoJson}
              renderPopUp={renderWorkOrderPopUp}
            />

            <PolygonLayout
              activeId={workOrderActiveGeoJsonId}
              setActiveId={_setWorkOrderActiveGeoJsonId}
              infoId={workOrderInfoGeoJsonId}
              setInfoId={setWorkOrderInfoGeoJsonId}
              geoJson={workOrdersPolygonsGeoJson}
              renderPopUp={renderWorkOrderPopUp}
            />
          </Map>
        ),
      }}
    </ContentMapLayout>
  )
}

export default SafetyConcernPageBuilder
