import { IInspectionTypeBuilder } from '../interfaces'

const isItbPresented = (itbs: IInspectionTypeBuilder[], inspectionTypeId: number, inspectionSubtypeId?: number) => {
  const isPresented = (itbs || []).find(
    itb =>
      Number(itb.inspectionTypeId) === Number(inspectionTypeId) &&
      ((!itb.inspectionSubtypeId && !inspectionSubtypeId) || itb.inspectionSubtypeId === inspectionSubtypeId),
  )

  return isPresented
}

export default isItbPresented
