import React from 'react'

import RFFFields from '../../react-final-form-fields'

import { validateMaxSymbols } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'

import {
  ISafetyReportCustomizationFormFiledsProps,
  ISafetyReportCustomizationValues,
} from './safety-report-customization-form-types'

const nameof = nameofFactory<ISafetyReportCustomizationValues>()

const logoUrlLabel = 'Logo Url'
const instructionTextLabel = 'Instruction Text'
const thankYouTextLabel = 'Thank You Text'

const validateInstructionTextMaxSymbols500 = validateMaxSymbols(
  500,
  `${instructionTextLabel} must not exceed 500 characters`,
)
const validateThankYouTextMaxSymbols500 = validateMaxSymbols(500, `${thankYouTextLabel} must not exceed 500 characters`)

const SafetyReportCustomizationFormFields: React.FC<ISafetyReportCustomizationFormFiledsProps> = props => {
  const { disabled } = props

  return (
    <>
      <RFFFields.Input
        name={nameof('logoUrl')}
        label={logoUrlLabel}
        placeholder={getPlaceholderTextFromLabel(logoUrlLabel)}
        disabled={disabled}
      />

      <RFFFields.Textarea
        name={nameof('instructionText')}
        label={instructionTextLabel}
        placeholder={getPlaceholderTextFromLabel(instructionTextLabel)}
        disabled={disabled}
        validate={validateInstructionTextMaxSymbols500}
      />

      <RFFFields.Textarea
        name={nameof('thankYouText')}
        label={thankYouTextLabel}
        placeholder={getPlaceholderTextFromLabel(thankYouTextLabel)}
        disabled={disabled}
        validate={validateThankYouTextMaxSymbols500}
      />
    </>
  )
}

export default SafetyReportCustomizationFormFields
