import { getTimeSpanTypeByTimeSpan, getTimeSpanByTimeSpanType } from '../../../../util'

import { ISavedSearchFilters, ISavedSearchFiltersUpsert, SavedSearchFilterType } from '../../../../interfaces'
import { ISavedFilterValues } from './saved-filter-form-types'

export const savedSearchFilterToSavedFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
): ISavedFilterValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let savedFilterValues: ISavedFilterValues = {
    name: savedSearchFilter.name,
    description: savedSearchFilter.description,
    activeColumnIds: savedSearchFilter.activeColumnIds,
  }

  if (savedSearchFilter.startedAtFromTimeSpan && savedSearchFilter.startedAtToTimeSpan) {
    const timeSpanType = getTimeSpanTypeByTimeSpan(
      savedSearchFilter.startedAtFromTimeSpan,
      savedSearchFilter.startedAtToTimeSpan,
    )

    if (timeSpanType) {
      savedFilterValues.isDynamic = true
      savedFilterValues.timeSpan = Number(timeSpanType)
    } else {
      console.warn(
        'There is no pre-defined type for: ',
        savedSearchFilter.startedAtFromTimeSpan,
        savedSearchFilter.startedAtToTimeSpan,
      )
    }
  }

  return savedFilterValues
}

export const savedFilterValuesToSavedSearchFilterAddUpsert = (
  savedFilterValues: ISavedFilterValues | undefined,
  savedFilterOptions: { savedSearchFilters: any; filterType: SavedSearchFilterType; filterJson: string },
): ISavedSearchFiltersUpsert | undefined => {
  if (!savedFilterValues) {
    return savedFilterValues
  }

  const savedSearchFilters = savedFilterOptions.savedSearchFilters;

  let savedSearchFilterAddUpsert: ISavedSearchFiltersUpsert = {
    id: 0,
    name: savedFilterValues.name,
    description: savedFilterValues.description,
    filterType: savedFilterOptions?.filterType,
    filterJson: savedFilterOptions?.filterJson,
    activeColumnIds: savedFilterValues.activeColumnIds,
    isActive: true,
  }

  if (savedFilterValues.isDynamic && savedFilterValues.timeSpan) {
    const timeSpan = getTimeSpanByTimeSpanType(savedFilterValues.timeSpan)

    if(savedSearchFilters.dateType === 'EndedAt'){
      savedSearchFilterAddUpsert.endedAtFromTimeSpan = timeSpan.fromTimeSpan
      savedSearchFilterAddUpsert.endedAtToTimeSpan = timeSpan.toTimeSpan
    } else {
      savedSearchFilterAddUpsert.startedAtFromTimeSpan = timeSpan.fromTimeSpan
      savedSearchFilterAddUpsert.startedAtToTimeSpan = timeSpan.toTimeSpan
    }
  }

  return savedSearchFilterAddUpsert
}

export const savedFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedFilterValues: ISavedFilterValues | undefined,
  savedFilterOptions: { savedSearchFilters: ISavedSearchFilters; filterJson: string },
): ISavedSearchFiltersUpsert | undefined => {
  if (!savedFilterValues) {
    return savedFilterValues
  }

  const savedSearchFilters = savedFilterOptions.savedSearchFilters;
  let savedSearchFilterUpdateUpsert: ISavedSearchFiltersUpsert = {
    id: savedFilterOptions.savedSearchFilters.id,
    filterType: savedFilterOptions.savedSearchFilters.filterType,

    name: savedFilterValues.name,
    description: savedFilterValues.description,
    filterJson: savedFilterOptions.filterJson,
    activeColumnIds: savedFilterValues.activeColumnIds,
    isActive: true,
  }

  if (savedFilterValues.isDynamic && savedFilterValues.timeSpan) {
    const timeSpan = getTimeSpanByTimeSpanType(savedFilterValues.timeSpan)

    if(savedSearchFilters.dateType === 'EndedAt'){
      savedSearchFilterUpdateUpsert.endedAtFromTimeSpan = timeSpan.fromTimeSpan
      savedSearchFilterUpdateUpsert.endedAtToTimeSpan = timeSpan.toTimeSpan
    } else {
      savedSearchFilterUpdateUpsert.startedAtFromTimeSpan = timeSpan.fromTimeSpan
      savedSearchFilterUpdateUpsert.startedAtToTimeSpan = timeSpan.toTimeSpan
    }
  }

  return savedSearchFilterUpdateUpsert
}
