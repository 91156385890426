import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'

import { byPropName } from '../util'

const byLastNameAscending = (a, b) => byPropName(a, b, 'lastName')

export const UsersDropdown = ({ className = '', onChange, showDefault = false, users = [], value }) => {
  if (!users) {
    return null
  }

  return (
    <select className={`${className} form-control form-select`} onChange={onChange} value={value}>
      {showDefault && <option value="">(Select a user)</option>}
      {users.sort(byLastNameAscending).map(u => (
        <option key={`user-${u.id}`} value={u.id}>
          {u.firstName} {u.lastName}
        </option>
      ))}
    </select>
  )
}

UsersDropdown.propTypes = {
  className: string,
  onChange: func,
  showDefault: bool,
  users: arrayOf(
    shape({
      firstName: string,
      id: string,
      lastName: string,
    }),
  ),
  value: string,
}

export default UsersDropdown
