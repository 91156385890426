import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import { validateRequired, validateMaxSymbols, composeValidators } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel, toOptions } from '../../../../util'

import { SafetyConcernFormFieldsProps, ISafetyConcernValues } from './safety-concern-form-types'
import { useProfile } from '../../../../security'
import { usePrioritiesQuery, useSafetyConcernStatusesQuery } from '../../../../services'

const validateMaxSymbols500 = validateMaxSymbols(500, 'Notes must not exceed 500 characters')
const validateMaxSymbols100 = validateMaxSymbols(100, 'Action must not exceed 100 characters')

const nameof = nameofFactory<ISafetyConcernValues>()

const nameLabel = 'Summary'
const descriptionLabel = 'Description'
const priorityLabel = 'Priority'
const statusLabel = 'Status'

const SafetyConcernFormFields: FC<SafetyConcernFormFieldsProps> = props => {
  const { disabled, baseStatus, additionalOptions, values, isViewMode } = props

  const { airportId } = useProfile()

  const prioritiesQuery = usePrioritiesQuery({ airportId }, { activeOnly: true })

  const prioritiesOptions = useMemo(() => toOptions(prioritiesQuery?.data), [prioritiesQuery?.data])

  const statusesQuery = useSafetyConcernStatusesQuery({ activeOnly: true, baseStatus })

  const statusesOptions = useMemo(
    () =>
      additionalOptions?.getStatusesOptions
        ? additionalOptions?.getStatusesOptions(toOptions(statusesQuery?.data), values)
        : toOptions(statusesQuery?.data),
    [statusesQuery?.data, additionalOptions, values],
  )

  return (
    <>
      <RFFFields.Input
        name={nameof('summary')}
        label={nameLabel}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        disabled={disabled}
        isViewMode={isViewMode}
        validate={composeValidators(validateRequired, validateMaxSymbols100)}
      />

      <RFFFields.Textarea
        name={nameof('description')}
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        disabled={disabled}
        isViewMode={isViewMode}
        validate={validateMaxSymbols500}
      />

      <RFFFields.Select
        name={nameof('priorityId')}
        label={priorityLabel}
        placeholder={getPlaceholderTextFromLabel(priorityLabel)}
        validate={validateRequired}
        options={prioritiesOptions}
        isViewMode={isViewMode}
        disabled={prioritiesQuery?.isFetching || disabled}
      />

      <RFFFields.Select
        name={nameof('statusId')}
        label={statusLabel}
        placeholder={getPlaceholderTextFromLabel(statusLabel)}
        validate={validateRequired}
        options={statusesOptions}
        isViewMode={isViewMode}
        disabled={statusesQuery?.isFetching || disabled}
      />
    </>
  )
}

export default SafetyConcernFormFields
