import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IUseDeleteMutation, IDeleteFn, IGetDeleteRequest } from '../../interfaces'
import { IWorkOrder } from '../../interfaces'

const getDeleteWorkOrderMutationRequest: IGetDeleteRequest = params => {
  const { airportId } = params

  return {
    method: 'delete',
    url: `${config.baseUrl}/${airportId}/WorkOrders/`,
  }
}

const useDeleteWorkOrderMutation: IUseDeleteMutation<IWorkOrder, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteWorkOrderMutationRequest(params), [params])

  const deleteWorkOrder: IDeleteFn<IWorkOrder> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<IWorkOrder, Error, any>({ mutationFn: deleteWorkOrder, ...options })

  return response
}

export default useDeleteWorkOrderMutation
