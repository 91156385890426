import { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { formatErrors } from '../../util'
import { useModal } from '../../hooks/use-modal'
import { mergeValues } from './crud-table.utils'

const modalModes = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  default: '',
}

const defaultGetCreateEntityInitialValues = entity => ({ ...entity })
const defaultCreateEntityTitle = () => 'Create'
const defaultCreateEntitySubmitBtnText = () => 'Save'
const defaultCreateEntityFormName = 'update-entity-form'

const defaultGetUpdateEntityInitialValues = entity => ({ ...entity })
const defaultGetUpdateEntityTitle = () => 'Update'
const defaultGetUpdateEntitySubmitBtnText = () => 'Update'
const defaultUpdateEntityFormName = 'update-entity-form'

const defaultGetDeleteEntityTitle = () => 'Delete'
const defaultGetDeleteEntitySubmitBtnText = () => 'Delete'

const useCrudTable = ({
  entities,
  createSettings,
  readSettings,
  updateSettings,
  deleteSettings,
  getRowId,
  getAdditionalOptions,
  mutateValues,
}) => {
  const tableInstanceRef = useRef(undefined)

  const {
    createEntity,
    isEntityCreating,
    getCreateEntityInitialValues,
    createEntitySubmitBtnText,
    createEntityFormName,
    createEntityTitle,
    customHandleCreateEntityBtn,
  } = createSettings

  const { readEntities } = readSettings

  const {
    updateEntity,
    isEntityUpdating,
    getUpdateEntityInitialValues,
    getUpdateEntitySubmitBtnText,
    updateEntityFormName,
    getUpdateEntityTitle,
    customHandleUpdateEntityBtn,
    hideUpdateEntityBtn,
    getUpdateLabelOption,
  } = updateSettings

  const { deleteEntity, isEntityDeleting, getDeleteEntitySubmitBtnText, getDeleteEntityTitle, hideDeleteEntityBtn } =
    deleteSettings

  useEffect(() => {
    readEntities()
  }, [readEntities])

  const [modalMode, setModalMode] = useState(modalModes.default)

  const { isOpen: isCRUDModalOpen, closeModal: closeCRUDModal, openModal: openCRUDModal } = useModal()

  const resetModal = useCallback(() => {
    closeCRUDModal()
    setModalMode(modalModes.default)
  }, [setModalMode, closeCRUDModal])

  // Create section
  const handleCreateEntityBtn = useCallback(() => {
    if (customHandleCreateEntityBtn) {
      customHandleCreateEntityBtn()
      return
    }

    setModalMode(modalModes.create)
    openCRUDModal()
  }, [setModalMode, openCRUDModal, customHandleCreateEntityBtn])

  const handleCreateEntity = useCallback(
    async values => {
      const [, error] = await createEntity(mutateValues(values, values))

      if (error) {
        return formatErrors(error)
      }

      resetModal()
      readEntities()
    },
    [createEntity, mutateValues, resetModal, readEntities],
  )

  // Update section
  const [updatingId, setUpdatingId] = useState(null)

  const closeUpdateEntityModal = useCallback(() => {
    resetModal()
    setUpdatingId(null)
  }, [resetModal, setUpdatingId])

  const handleUpdateEntityBtn = useCallback(
    id => {
      if (customHandleUpdateEntityBtn) {
        const updatingEntity = entities.find(r => getRowId(r) === id)

        customHandleUpdateEntityBtn(id, updatingEntity)
        return
      }

      setModalMode(modalModes.update)
      setUpdatingId(id)
      openCRUDModal()
    },
    [setModalMode, openCRUDModal, customHandleUpdateEntityBtn, entities, getRowId],
  )

  const handleUpdateEntity = useCallback(
    async (values, form) => {
      const updatingEntity = entities.find(r => getRowId(r) === updatingId)

      if (!updatingEntity) {
        closeUpdateEntityModal()
        return
      }

      const formFields = form.getRegisteredFields()

      const computedValues = mergeValues(updatingEntity, formFields, values)

      const [, error] = await updateEntity({ ...mutateValues(computedValues, values) }, updatingEntity)
      if (error) {
        return formatErrors(error)
      }

      closeUpdateEntityModal()

      readEntities()
    },
    [entities, mutateValues, updateEntity, closeUpdateEntityModal, readEntities, getRowId, updatingId],
  )

  // Delete section
  const [deletingId, setDeletingId] = useState(null)

  const closeDeleteEntityModal = useCallback(() => {
    resetModal()
    setDeletingId(null)
  }, [resetModal, setDeletingId])

  const handleDeleteEntityBtn = useCallback(
    id => {
      setModalMode(modalModes.delete)
      setDeletingId(id)
      openCRUDModal()
    },
    [setModalMode, openCRUDModal],
  )

  const handleDeleteEntity = useCallback(async () => {
    const deletingEntity = entities.find(r => getRowId(r) === deletingId)

    await deleteEntity(deletingId, deletingEntity)

    closeDeleteEntityModal()
    readEntities()
  }, [deleteEntity, deletingId, readEntities, closeDeleteEntityModal, entities, getRowId])

  const _getCreateEntityInitialValues = useMemo(
    () => getCreateEntityInitialValues || defaultGetCreateEntityInitialValues,
    [getCreateEntityInitialValues],
  )
  const _createEntityTitle = useMemo(() => createEntityTitle || defaultCreateEntityTitle, [createEntityTitle])
  const _createEntityFormName = useMemo(
    () => createEntityFormName || defaultCreateEntityFormName,
    [createEntityFormName],
  )
  const _createEntitySubmitBtnText = useMemo(
    () => createEntitySubmitBtnText || defaultCreateEntitySubmitBtnText,
    [createEntitySubmitBtnText],
  )

  const _getUpdateEntityInitialValues = useMemo(
    () => getUpdateEntityInitialValues || defaultGetUpdateEntityInitialValues,
    [getUpdateEntityInitialValues],
  )
  const _getUpdateEntityTitle = useMemo(
    () => getUpdateEntityTitle || defaultGetUpdateEntityTitle,
    [getUpdateEntityTitle],
  )
  const _updateEntityFormName = useMemo(
    () => updateEntityFormName || defaultUpdateEntityFormName,
    [updateEntityFormName],
  )
  const _getUpdateEntitySubmitBtnText = useMemo(
    () => getUpdateEntitySubmitBtnText || defaultGetUpdateEntitySubmitBtnText,
    [getUpdateEntitySubmitBtnText],
  )

  const _getDeleteEntityTitle = useMemo(
    () => getDeleteEntityTitle || defaultGetDeleteEntityTitle,
    [getDeleteEntityTitle],
  )
  const _getDeleteEntitySubmitBtnText = useMemo(
    () => getDeleteEntitySubmitBtnText || defaultGetDeleteEntitySubmitBtnText,
    [getDeleteEntitySubmitBtnText],
  )

  const modalSettings = useMemo(() => {
    const settings = {
      isOpen: isCRUDModalOpen,
      cancelBtnText: 'Cancel',
    }

    switch (modalMode) {
      case modalModes.create: {
        settings.size = 'lg'
        settings.title = _createEntityTitle
        settings.handleSubmit = handleCreateEntity
        settings.handleClose = resetModal
        settings.initialValues = _getCreateEntityInitialValues({}, { tableInstanceRef })
        settings.formName = _createEntityFormName
        settings.confirmBtnText = _createEntitySubmitBtnText
        settings.isDisabled = isEntityCreating
        break
      }

      case modalModes.update: {
        const updatingEntity = entities.find(r => r.id === updatingId)

        settings.size = 'lg'
        settings.title = _getUpdateEntityTitle(updatingEntity)
        settings.handleSubmit = handleUpdateEntity
        settings.handleClose = closeUpdateEntityModal
        settings.initialValues = _getUpdateEntityInitialValues(updatingEntity)
        settings.formName = _updateEntityFormName
        settings.confirmBtnText = _getUpdateEntitySubmitBtnText(updatingEntity)
        settings.isDisabled = isEntityUpdating
        break
      }

      case modalModes.delete: {
        const deletingEntity = entities.find(r => r.id === deletingId)

        settings.size = 'sm'
        settings.title = _getDeleteEntityTitle(deletingEntity)
        settings.handleSubmit = handleDeleteEntity
        settings.handleClose = closeDeleteEntityModal
        settings.confirmBtnText = _getDeleteEntitySubmitBtnText(deletingEntity)
        settings.isDisabled = isEntityDeleting
        break
      }
      default: {
      }
    }

    return settings
  }, [
    isCRUDModalOpen,
    modalMode,
    handleCreateEntity,
    handleUpdateEntity,
    handleDeleteEntity,
    isEntityCreating,
    isEntityUpdating,
    isEntityDeleting,
    resetModal,
    closeUpdateEntityModal,
    closeDeleteEntityModal,
    entities,
    updatingId,
    _getCreateEntityInitialValues,
    _createEntityTitle,
    _createEntityFormName,
    _createEntitySubmitBtnText,
    _getUpdateEntityInitialValues,
    _getUpdateEntityTitle,
    _updateEntityFormName,
    _getUpdateEntitySubmitBtnText,
    deletingId,
    _getDeleteEntityTitle,
    _getDeleteEntitySubmitBtnText,
  ])

  const getActionBtnOptions = useCallback(
    entity => {
      let editLabel = 'Edit'
      if (getUpdateLabelOption) {
        editLabel = getUpdateLabelOption(entity)
      }
      let actions = [
        !hideUpdateEntityBtn && { label: editLabel, handler: handleUpdateEntityBtn },
        !hideDeleteEntityBtn && { label: 'Delete', handler: handleDeleteEntityBtn },
        ...getAdditionalOptions(entity, { reFetch: readEntities, handleDeleteEntityBtn, handleUpdateEntityBtn }),
      ].filter(Boolean)

      return actions
    },
    [
      getUpdateLabelOption,
      hideUpdateEntityBtn,
      handleUpdateEntityBtn,
      hideDeleteEntityBtn,
      handleDeleteEntityBtn,
      getAdditionalOptions,
      readEntities,
    ],
  )

  return {
    handleCreateEntityBtn,
    handleDeleteEntityBtn,
    handleUpdateEntityBtn,
    modalSettings,
    getActionBtnOptions,
    tableInstanceRef,
  }
}

export default useCrudTable
