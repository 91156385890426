import qs from 'qs'

import config from '../../config'

import { IGetDownloadLocationsQueryRequest } from './types'

const getDownloadLocationsQueryRequest: IGetDownloadLocationsQueryRequest = (params, queryObject) => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Locations/Download${queryString}`,
  }
}

export default getDownloadLocationsQueryRequest
