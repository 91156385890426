import config from '../../config'

import { IGetGetRequest } from '../../interfaces'

const getChecklistByIdQueryRequest: IGetGetRequest = params => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Checklists/instances/${id}`,
  }
}

export default getChecklistByIdQueryRequest
