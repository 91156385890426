import { FC, useRef, HTMLProps, useMemo, ReactNode } from 'react'
import cn from 'classnames'

import { DropdownMenu } from '../dropdown-menu'
import { Button, ButtonProps } from '../button'

import { useModal } from '../../hooks'

import s from './dropdown-button.module.scss'
import { IActionOption } from '../../interfaces'

type DropdownButtonProps = HTMLProps<HTMLButtonElement> & {
  isOpen?: boolean
  disabled?: boolean
  hoverBackgroundDisabled?: boolean
  iconClassName?: string
  onClose?: () => void
  onOpen?: () => void
  accessor?: any
  options: IActionOption<any>[]
  className?: string
  type?: ButtonProps['type']
  children?: ReactNode
}

const DropdownButton: FC<DropdownButtonProps> = props => {
  const {
    options,
    accessor,
    disabled,
    iconClassName = 'bi-three-dots',
    hoverBackgroundDisabled = false,
    className,
    isOpen,
    onOpen,
    onClose,
    type = 'ghost-icon',
    children,
  } = props

  const buttonRef = useRef<HTMLButtonElement>(null)

  const { isOpen: isDropdownOpen, openModal: openDropdown, closeModal: closeDropdown } = useModal()

  const _isOpen = useMemo(() => (isOpen !== undefined ? isOpen : isDropdownOpen), [isOpen, isDropdownOpen])

  const _openDropdown = useMemo(() => (onOpen !== undefined ? onOpen : openDropdown), [onOpen, openDropdown])

  const _closeDropdown = useMemo(() => (onClose !== undefined ? onClose : closeDropdown), [onClose, closeDropdown])

  return (
    <>
      <Button
        type={type}
        className={cn(className, hoverBackgroundDisabled ? s['transparent-background'] : '')}
        componentRef={buttonRef}
        onClick={_openDropdown}
        disabled={disabled}
      >
        {children}

        {iconClassName && <i className={`bi ${s['dropdown-icon']} ${iconClassName}`} />}
      </Button>

      <DropdownMenu isOpen={_isOpen} componentRef={buttonRef} onClose={_closeDropdown}>
        <div className={s['list']}>
          {options.map(option => (
            <Button
              className={s['action']}
              onClick={() => {
                option.handler(accessor)
                _closeDropdown()
              }}
              key={option.label}
              type="dark-link"
              disabled={option.disabled}
              loading={option.loading}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </DropdownMenu>
    </>
  )
}

export default DropdownButton
