import { useMemo } from 'react'

import getWorkOrderSafetyItemResponsesRequest from './get-responses'
import useAxios from '../use-axios'

const useWorkOrderSafetyItemResponses = ({ airportId, activeOnly = false, workOrderId = 0 }) => {
  const request = getWorkOrderSafetyItemResponsesRequest({ airportId, activeOnly, workOrderId })

  const { data = [], error, loading, refetch } = useAxios(request)

  const defaultItem = useMemo(() => data.find(d => d.isDefault), [data])

  return { data, defaultItem, error, loading, execute: refetch }
}

export default useWorkOrderSafetyItemResponses
