import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getReopenChecklistMutationRequest: IGetPutRequest = params => {
  const { airportId, id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Checklists/instances/${id}/Reopen`,
  }
}

export default getReopenChecklistMutationRequest
