import { useCallback, useMemo } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { useQuery } from '@tanstack/react-query'

import config from '../../config'
import { IAirportMapImagery } from './types'

const useAirportMapImageryQuery = ({ airportId, options = {} }: { airportId: number; options?: any }) => {
  const request = useMemo(
    () => ({
      method: 'get',
      url: `${config.baseUrl}/Airports/${airportId}/MapImagery`,
    }),
    [airportId],
  ) as AxiosRequestConfig

  const fetchAirportMapImagery = useCallback(() => axios(request).then(res => res.data), [request])

  const queryKey = useMemo(() => ['airport-map-imagery', airportId], [airportId])

  const response = useQuery<IAirportMapImagery | undefined, Error>(queryKey, fetchAirportMapImagery, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useAirportMapImageryQuery
