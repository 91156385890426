import axios from 'axios'

import config from '../../config'

const deleteInspection = async (airportId, inspectionId) => {
  try {
    const url = `${config.baseUrl}/${airportId}/Inspections/${inspectionId}`

    return await axios.delete(url)
  } catch (error) {
    throw error
  }
}

export default deleteInspection
