import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
// @ts-ignore
import MeasureTool from 'measuretool-googlemaps-v3'

import { Spinner } from '../spinner'
import { useGoogleMaps } from './google-maps-provider'
import DrawingManager from './drawing-manager'
import { IMapProps } from './map-types'
import { useAppContext } from '../../app-context'

import DrawingManagerControl from './drawing-manager-control'
import HomeButton from './home-button'
import MeasureButton from './measure-button'
import createImageMapType from './image-map-type'
import GeoLocationButton from './geo-location-button'

import useFeatureFlags from '../../features/use-feature-flags'
import useGeolocation from '../../hooks/use-geolocation'
import { getGeolocationPinUrl } from './get-pin-url'

import s from './map.module.scss'

const MAP_OPTIONS = {
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: 'satellite',
  streetViewControl: false,
  rotateControl: false,
  tilt: 0,
  maxZoom: 20,
  zoom: 15,
} as google.maps.MapOptions

const Map: FC<IMapProps> = props => {
  const { children, drawingManagerOptions, showDrawingManagerControl, renderAdditionalDrawingManagerControl } = props

  const { imageryEnabled } = useFeatureFlags()

  const {
    isLoaded,
    loadError,
    centerCoordinates,
    setCenterCoordinates,
    mapMode,
    setMapMode,
    // @ts-ignore
    setMap,
    // @ts-ignore
    DEFAULT_ZOOM,
    // @ts-ignore
    setZoom,
    isMeasuring,
    setIsMeasuring,
    setMeasureTool,
    onStartMeasure,
    onStopMeasure,
  } = useGoogleMaps()

  useEffect(() => {
    return () => {
      setMapMode('clear')
    }
  }, [setMapMode])

  const mapRef = useRef<any>()

  const { state } = useAppContext()
  const { mapSettings } = state.airport

  const [geoCoordinates, setGeoCoordinates] = useState({ lat: undefined, lng: undefined })
  const [trackGeoLocation, setTrackGeoLocation] = useState(false)
  const pauseGeoCoordinateUpdateRef = useRef(false) // used during polygon drawing to stop geoLocation from triggering re-renders which erases the polygon being drawn

  const onHomeClick = useCallback(() => {
    setCenterCoordinates({ lat: mapSettings?.latitude, lng: mapSettings?.longitude })
    mapRef.current.setZoom(mapSettings?.defaultZoom || DEFAULT_ZOOM)
  }, [setCenterCoordinates, mapSettings?.latitude, mapSettings?.longitude, mapSettings?.defaultZoom, DEFAULT_ZOOM])

  const onGeolocationUpdate = useCallback(
    (geolocation: any) => {
      if (pauseGeoCoordinateUpdateRef.current) {
        return // don't track while geo coordinates updates are paused
      }

      const coords = {
        lat: geolocation.latitude,
        lng: geolocation.longitude,
      }

      if (trackGeoLocation) {
        setCenterCoordinates(coords)
      }

      setGeoCoordinates(coords)
    },
    [trackGeoLocation, setCenterCoordinates],
  )

  const onMapDrag = useCallback(() => setTrackGeoLocation(false), [])

  useGeolocation(
    {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: undefined,
    },
    onGeolocationUpdate,
  )

  const onPolygonComplete = useCallback(
    (polygon: any) => {
      pauseGeoCoordinateUpdateRef.current = false
      if (drawingManagerOptions?.onPolygonComplete) {
        drawingManagerOptions.onPolygonComplete(polygon)
      }
    },
    [drawingManagerOptions],
  )

  const onGeoLocationClick = useCallback(() => {
    setCenterCoordinates({ ...geoCoordinates })
    setTrackGeoLocation(true)
  }, [geoCoordinates, setCenterCoordinates])

  if (Boolean(loadError)) {
    return <div>Something went wrong</div>
  }

  if (!isLoaded) {
    return <Spinner />
  }

  return (
    <div className={s['map-wrapper']}>
      <DrawingManagerControl
        showDrawingManagerControl={showDrawingManagerControl}
        renderAdditionalDrawingManagerControl={renderAdditionalDrawingManagerControl}
      />

      <div className={s['map-drawing-controls']}>
        <MeasureButton onStartMeasure={onStartMeasure} onStopMeasure={onStopMeasure} isMeasuring={isMeasuring} />
        <HomeButton onClick={onHomeClick} />
        <GeoLocationButton onClick={onGeoLocationClick} />
      </div>

      <GoogleMap
        id="googlemap"
        mapContainerClassName="h-100 w-100"
        center={centerCoordinates}
        options={MAP_OPTIONS}
        zoom={mapSettings?.defaultZoom || DEFAULT_ZOOM}
        onDrag={onMapDrag}
        onMouseDown={() => {
          if (mapMode === 'polygon') {
            pauseGeoCoordinateUpdateRef.current = true
          }
        }}
        onZoomChanged={() => {
          if (mapRef?.current?.zoom) {
            setZoom(mapRef.current.zoom)
          }
        }}
        onLoad={map => {
          mapRef.current = map
          setMap(map)

          if (imageryEnabled && state?.airport?.mapImagery) {
            map.overlayMapTypes.insertAt(
              0,
              // @ts-ignore
              createImageMapType({
                tileBaseUrl: state?.airport?.mapImagery.tileBaseUrl || '',
                tileFileExtension: state?.airport?.mapImagery.tileFileExtension || '',
                // @ts-ignore
                tileFileFormat: state?.airport?.mapImagery.tileFileFormat,
                tileSize: state?.airport?.mapImagery.tileSize,
              }),
            )
          }

          const measureTool = new MeasureTool(map, {
            showSegmentLength: true,
            contextMenu: false,
            unit: MeasureTool.UnitTypeId.IMPERIAL,
          })

          measureTool.addListener('measure_start', () => {
            setMapMode('clear')
            setIsMeasuring(true)
          })
          measureTool.addListener('measure_end', () => {
            setIsMeasuring(false)
          })

          setMeasureTool(measureTool)
        }}
        onUnmount={() => {
          isMeasuring && onStopMeasure()
        }}
      >
        {mapMode !== 'clear' && (
          <DrawingManager
            drawingManagerOptions={{
              ...drawingManagerOptions,
              onPolygonComplete: onPolygonComplete,
            }}
            mapMode={mapMode as google.maps.drawing.OverlayType}
          />
        )}
        {children}
        {geoCoordinates.lat && geoCoordinates.lng && (
          <Marker
            icon={getGeolocationPinUrl()}
            // @ts-ignore
            position={geoCoordinates}
          />
        )}
      </GoogleMap>
    </div>
  )
}

export default Map
