import type { SelectOption } from './select'

import s from './select-view.module.scss'

type SelectViewProps = {
  options: Array<SelectOption>
  value: any
}

const SelectView: React.FC<SelectViewProps> = props => {
  const { options, value } = props

  if (Array.isArray(value)) {
    const result = value.map(valueItem => {
      const option = options.find(option => option.value === valueItem)

      if (!option) {
        return null
      }
      return (
        <div className={s['select-view']} key={option.label}>
          {option.label}
        </div>
      )
    })
    return <>{result}</>
  }

  const option = options.find(option => option.value === value)
  if (!option) {
    return null
  }

  return <div className={s['select-view']}>{option.label}</div>
}

export default SelectView
