import React from 'react'
import { Form } from 'react-bootstrap'
import { Field } from 'react-final-form'

type FFSelectProps = any

export const FFSelect: React.FC<FFSelectProps> = props => (
  <Field name={props.name} {...props}>
    {({ input, meta }) => (
      <Form.Group controlId="validationCustom01">
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          as="select"
          isInvalid={(meta.error || meta.submitError) && meta.touched}
          {...input}
          disabled={props.disabled}
        >
          <option value="">{props.optionPreview}</option>
          {props.options.map((option: any) => (
            <option value={option.value} key={option.value}>{option.label}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
      </Form.Group>
    )}
  </Field>
)
