import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateTagMutationRequest: IGetPutRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Tags`,
  }
}

export default getUpdateTagMutationRequest
