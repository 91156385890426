import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ITraining, IGetRequestObject } from '../../interfaces'

type QueryObject = {
  includeDetail?: boolean
}

const getTrainingsRequest = (_: undefined, queryObject: QueryObject | undefined): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/Trainings${queryString}`,
  }
}

const useTrainingsQuery = buildGetQuery<undefined, QueryObject | undefined, ITraining[] | undefined>({
  getRequest: getTrainingsRequest,
  getQueryKey: (_, queryObject) => ['trainings', queryObject?.includeDetail || ''],
})

export default useTrainingsQuery
