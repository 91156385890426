import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject, INotam } from '../../interfaces'

const getInspectionNotamsRequest = (inspectionId: number): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/Notams/Inspection/${inspectionId}`,
})

export const useInspectionNotamsQueryBase = buildGetQuery<number, undefined, INotam[] | undefined>({
  getRequest: getInspectionNotamsRequest,
  getQueryKey: (inspectionId) => ['notams', inspectionId],
})

const useInspectionNotamsQuery = (inspectionId: number, options?: QueryObserverOptions<INotam[] | undefined, Error>) =>
  useInspectionNotamsQueryBase(inspectionId, undefined, options)


export default useInspectionNotamsQuery