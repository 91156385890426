import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IFacility, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  includeDetail?: boolean
  activeOnly?: boolean
}

const getFacilitiesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Facilities${queryString}`,
  }
}

const useFacilitiesQuery = buildGetQuery<Params, QueryObject | undefined, IFacility[] | undefined>({
  getRequest: getFacilitiesRequest,
  getQueryKey: (_, queryObject) => ['facilities', queryObject?.includeDetail, queryObject?.activeOnly],
})

export default useFacilitiesQuery
