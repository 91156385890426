import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteTagMutationRequest from './get-delete-tag-mutation-request'

import { IUseDeleteMutation, IDeleteFn } from '../../interfaces'
import { ITag } from '../../interfaces'

const useDeleteTagMutation: IUseDeleteMutation<ITag, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteTagMutationRequest(params), [params])

  const deleteTag: IDeleteFn<ITag> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITag, Error, number>({ mutationFn: deleteTag, ...options })

  return response
}

export default useDeleteTagMutation
