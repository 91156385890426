import React from 'react'
import { Tab, TabProps } from '@mui/material'

function samePageLinkNavigation(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): boolean {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false
  }
  return true
}

interface LinkTabProps extends TabProps {
  label?: string
  href?: string
}

const LinkTab: React.FC<LinkTabProps> = props => {

  return (
    <Tab
      component="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault()
        }
      }}
      {...props}
    />
  )
}

export default LinkTab
