import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import cn from 'classnames'
import { Placement } from '@popperjs/core'

type InfoProps = {
  className?: string
  info: string
  placement?: Placement
}

const Info: React.FC<InfoProps> = ({ info, className, placement = 'right' }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={`tooltip-left`}>{info}</Tooltip>}>
      <i className={cn('bi bi-question-circle', className)} />
    </OverlayTrigger>
  )
}

export default Info
