import { FC, useMemo, useCallback, useEffect, useState, ReactElement } from 'react'
import { DefinedUseQueryResult } from '@tanstack/react-query'

import {
  CrudModal,
  Button,
  WorkOrderForm,
  workOrderValuesToWorkOrderAddUpsert,
  workOrderValuesToWorkOrderUpdateUpsert,
  workOrderToWorkOrderValues,
  IWorkOrderValues,
} from '../../../components'
import SafetyConcernWorkOrdersCardsList from './safety-concern-work-orders-cards-list'

import { useModal } from '../../../hooks'
import { useAddWorkOrderMutation, useUpdateWorkOrderMutation, useDeleteWorkOrderMutation } from '../../../services'
import { useProfile } from '../../../security'

import { IWorkOrder, IRefs, ISafetyConcern } from '../../../interfaces'

type SafetyConcernWorkOrdersProps = {
  hasWriteAccess?: boolean
  safetyConcernQuery: DefinedUseQueryResult<ISafetyConcern | undefined, Error>
  setHeaderControls: (headerControls?: ReactElement) => void
  workOrdersRefs: IRefs
  workOrdersPagination: {
    pageSize: number
    pageIndex: number
  }
  setWorkOrdersPagination: (values: { [key: string]: number }) => void
  workOrdersQuery: DefinedUseQueryResult<IWorkOrder[] | undefined, Error>
  activeWorkOrderId?: number
}

const SafetyConcernWorkOrders: FC<SafetyConcernWorkOrdersProps> = props => {
  const {
    hasWriteAccess,
    safetyConcernQuery,
    setHeaderControls,
    workOrdersQuery,
    workOrdersRefs,
    workOrdersPagination,
    setWorkOrdersPagination,
    activeWorkOrderId,
  } = props

  const { airportId } = useProfile()

  const { data: workOrders, isFetching: isWorkordersLoading, refetch: refetchWorkOrders } = workOrdersQuery

  const { isOpen: isWorkOrderModalOpen, closeModal: closeWorkOrderModal, openModal: openWorkOrderModal } = useModal()

  const [crudModalMode, setCrudModalMode] = useState<'create' | 'delete' | 'update'>()

  const handleOpenCreateSafetyConcernWorkOrderModal = useCallback(() => {
    setCrudModalMode('create')
    openWorkOrderModal()
  }, [openWorkOrderModal])

  const [deletingWorkOrder, setDeletingWorkOrder] = useState<IWorkOrder>()

  const handleOpenDeleteSafetyConcernWorkOrderModal = useCallback(
    (safetyConcernAction: IWorkOrder) => {
      setDeletingWorkOrder(safetyConcernAction)
      setCrudModalMode('delete')
      openWorkOrderModal()
    },
    [openWorkOrderModal],
  )

  const [updatingWorkOrder, setUpdatingWorkOrder] = useState<IWorkOrder>()

  const handleOpenUpdateSafetyConcernWorkOrderModal = useCallback(
    (safetyConcernAction: IWorkOrder) => {
      setUpdatingWorkOrder(safetyConcernAction)
      setCrudModalMode('update')
      openWorkOrderModal()
    },
    [openWorkOrderModal],
  )

  const safetyConcernWorkOrderActionsButtonOptions = useMemo(
    () =>
      hasWriteAccess
        ? [
            {
              label: 'Update',
              handler: (workOrder: IWorkOrder) => handleOpenUpdateSafetyConcernWorkOrderModal(workOrder),
            },
            {
              label: 'Delete',
              handler: (safetyConcernAction: IWorkOrder) =>
                handleOpenDeleteSafetyConcernWorkOrderModal(safetyConcernAction),
            },
          ]
        : undefined,
    [hasWriteAccess, handleOpenUpdateSafetyConcernWorkOrderModal, handleOpenDeleteSafetyConcernWorkOrderModal],
  )

  const onWorkOrderTypeChangeMutator = useCallback((args: any, state: any, mutate: any) => {
    const workOrderTypeId = args?.[0]

    mutate.changeValue(state, 'workOrderTypeId', () => workOrderTypeId)

    mutate.changeValue(state, 'safetyItem.facilityId', () => undefined)
    mutate.changeValue(state, 'safetyItem.conditionId', () => undefined)
    mutate.changeValue(state, 'safetyItem.safetyItemUniqueValueId', () => undefined)
    mutate.changeValue(state, 'safetyItem.safetyItemResponseId', () => undefined)
  }, [])

  const onFacilityChangeMutator = useCallback((args: any, state: any, mutate: any) => {
    const facilityId = args?.[0]

    mutate.changeValue(state, 'safetyItem.facilityId', () => facilityId)

    mutate.changeValue(state, 'safetyItem.conditionId', () => undefined)
  }, [])

  const mutators = useMemo(
    () => ({
      onWorkOrderTypeChange: onWorkOrderTypeChangeMutator,
      onFacilityChange: onFacilityChangeMutator,
    }),
    [onWorkOrderTypeChangeMutator, onFacilityChangeMutator],
  )

  const headerControls = useMemo(
    () => <Button onClick={handleOpenCreateSafetyConcernWorkOrderModal}>Create Work Order</Button>,
    [handleOpenCreateSafetyConcernWorkOrderModal],
  )

  useEffect(() => {
    setHeaderControls(headerControls)
    return () => setHeaderControls(undefined)
  }, [setHeaderControls, headerControls])

  return (
    <>
      <CrudModal
        modalSettings={{
          isOpen: isWorkOrderModalOpen,
          closeModal: closeWorkOrderModal,
          mode: crudModalMode,
        }}
        createSettings={{
          title: 'Safety Concern Action',
          useCreateMutation: useAddWorkOrderMutation,
          formValuesToAddUpsert: (workOrderValues: IWorkOrderValues) =>
            workOrderValuesToWorkOrderAddUpsert(workOrderValues, {
              airportId,
              safetyConcernId: safetyConcernQuery?.data?.id,
            }),
          onCreateSuccessMessage: 'Action successfully created',
          onCreateErrorMessage: 'Something when wrong while creating action',
          onSuccessCallback: refetchWorkOrders,
        }}
        updateSettings={{
          title: 'Safety Concern Action',
          useUpdateMutation: useUpdateWorkOrderMutation,
          entityToFormValues: workOrderToWorkOrderValues,
          formValuesToUpdateUpsert: (workOrderValues: IWorkOrderValues) =>
            workOrderValuesToWorkOrderUpdateUpsert(workOrderValues, updatingWorkOrder),
          onUpdateSuccessMessage: 'Action successfully created',
          onUpdateErrorMessage: 'Something when wrong while creating action',
          onSuccessCallback: refetchWorkOrders,
          updatingItem: updatingWorkOrder,
        }}
        deleteSettings={{
          title: 'Safety Concern Action',
          useDeleteMutation: useDeleteWorkOrderMutation,
          onDeleteSuccessMessage: 'Action successfully deleted',
          onDeleteErrorMessage: 'Something when wrong while creating action',
          onSuccessCallback: refetchWorkOrders,
          deletingItem: deletingWorkOrder,
        }}
        FormComponent={WorkOrderForm}
        formName="work-order"
        mutators={mutators}
      />

      <SafetyConcernWorkOrdersCardsList
        workOrders={workOrders || []}
        pagination={workOrdersPagination}
        setPagination={setWorkOrdersPagination}
        actionsButtonOptions={safetyConcernWorkOrderActionsButtonOptions}
        workOrdersRefs={workOrdersRefs}
        activeWorkOrderId={activeWorkOrderId}
        isLoading={isWorkordersLoading}
      />
    </>
  )
}

export default SafetyConcernWorkOrders
