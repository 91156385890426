import { FC, useMemo } from 'react'

import NotamFilterFormFields from './notam-filter-fields'

import { INotamFilterFormProps } from './notam-filter-form-types'

const NotamFilterForm: FC<INotamFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <NotamFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default NotamFilterForm
