import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IUseUpdateMutation, IAddFn } from '../../interfaces'

const getDownloadNotamsCSVQueryRequest: any = (params: undefined) => {
  return {
    method: 'post',
    url: `${config.baseUrl}/Notams/Download`,
  }
}

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'notams'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadNotamsCSVMutation: IUseUpdateMutation<any, any> = (params: any, options = {}) => {
  const request = useMemo(() => getDownloadNotamsCSVQueryRequest(params), [params])

  const fetchNotamsCSVFile: IAddFn<any, any> | undefined = useCallback(
    async (data: any) =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchNotamsCSVFile,
    ...options,
  })

  return response
}

export default useDownloadNotamsCSVMutation
