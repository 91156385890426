import { FC, useState, DragEvent, ChangeEvent, useRef } from 'react'
import cn from 'classnames'

import { IUploaderProps } from './uploader-types'

import UploaderFilePreview from './uploader-file-preview'

import s from './uploader.module.scss'

const _defaultTitle = 'Drop file here or click to upload.'
const _defaultSubtitle = 'Only previously downloaded template can be used.'

const isFileHasCorrectFileType = (file: File, allowTypes: string[] | undefined) => {
  if (!allowTypes) {
    return true
  }

  return allowTypes.indexOf(file.type) > -1
}

const uploaderErrors = {
  getFileTypeError: (allowTypes: string[] | undefined) =>
    allowTypes ? `Incorrect file type, uploader accept ${allowTypes} types` : null,
}

const Uploader: FC<IUploaderProps> = props => {
  const { onChange: setFiles, value: file, title, subTitle, allowTypes } = props

  const [isDragActive, setIsDragActive] = useState(false)

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const [error, setError] = useState<null | string>(null)

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const isCorrectFile = isFileHasCorrectFileType(e.dataTransfer.files[0], allowTypes)

      if (isCorrectFile) {
        setFiles(e.dataTransfer.files[0])
        setError(null)
      } else {
        setError(uploaderErrors.getFileTypeError(allowTypes))
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      setFiles(e.target.files[0])
    }
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const onUploaderClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const uploaderClassName = cn(s['uploader-wrapper'], isDragActive && s['uploader-wrapper-active'])

  const _title = title ? title : _defaultTitle

  const _subtitle = title ? subTitle : _defaultSubtitle

  const accept = allowTypes ? allowTypes.join(',') : '*'

  return (
    <>
      <div className={uploaderClassName} onDragEnter={handleDrag} onClick={onUploaderClick}>
        {isDragActive && (
          <div
            className={s['uploader-overlay']}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}

        <input
          ref={inputRef}
          onChange={handleChange}
          type="file"
          className={s['uploader-input']}
          name="file"
          accept={accept}
        />

        {file ? (
          <UploaderFilePreview file={file} onRemove={() => setFiles(null)} />
        ) : (
          <>
            <p className={s['uploader-title']}>{_title}</p>
            <p className={s['uploader-subtitle']}>{_subtitle}</p>
          </>
        )}
      </div>

      {error && <span className="invalid-feedback d-block mt-3">{error}</span>}
    </>
  )
}

export default Uploader
