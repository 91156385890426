/* eslint-disable no-unused-vars */
export enum SavedSearchFilterType {
  Inspection = 1,
  Discrepancy = 2,
  WorkOrder = 3,
  Wildlife = 4,
  Checklist = 5,
  Notification = 6,
  ActivityLogs = 7,
  Notams = 8,
  Training = 9,
  SafetyConcern = 10,
}
