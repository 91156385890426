import { FC, useMemo } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import AppTitle from '../../app-title'
import NotFound from '../not-found'
import SafetyConcernsListPage from './safety-concerns-page/safety-concerns-page'
import CreateSafetyConcernPage from './safety-concern-page/create-safety-concern-page'
import UpdateSafetyConcernPage from './safety-concern-page/update-safety-concern-page'

import useFeatureFlags from '../../features/use-feature-flags'
import { useAccessControl, claims } from '../../security'
import { buildPath } from '../../util'

type SafetyConcernProps = {
  basePath: string
  baseTitle: string
}

const SafetyConcern: FC<SafetyConcernProps> = props => {
  const { baseTitle, basePath } = props

  const { hasAccess } = useAccessControl()

  const { safetyManagementEnabled } = useFeatureFlags()

  const hasSafetyConcernAnyAccess = useMemo(() => hasAccess([claims.admin.read, claims.admin.write]), [hasAccess])

  if (!safetyManagementEnabled || !hasSafetyConcernAnyAccess) {
    return <NotFound />
  }

  return (
    <AppTitle title={baseTitle + 'Safety Concern'}>
      <Routes>
        <Route index element={<Navigate to={buildPath('/safety-concern-list', basePath)} replace />} />

        <Route path="/safety-concern-list" element={<SafetyConcernsListPage />} />

        <Route path="/create-safety-concern/*" element={<CreateSafetyConcernPage />} />

        <Route path="/:safetyConcernId/*" element={<UpdateSafetyConcernPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppTitle>
  )
}

export default SafetyConcern
