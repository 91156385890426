import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getAddTagMutationRequest from './get-add-tag-mutation-request'

import { IUseAddMutation, IAddFn } from '../../interfaces'
import { ITag, ITagAddUpsert } from '../../interfaces'

const useAddTagMutation: IUseAddMutation<ITag, ITagAddUpsert> = (params, options = {}) => {
  const request = useMemo(() => getAddTagMutationRequest(params), [params])

  const addTag: IAddFn<ITagAddUpsert, ITag> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITag, Error, ITagAddUpsert>({ mutationFn: addTag, ...options })

  return response
}

export default useAddTagMutation
