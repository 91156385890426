// TODO consider extracting these if they are used other places
export const LATITUDE = 1
export const LONGITUDE = 0

// Convert geoJson coordinates stored in an array [ longitude, latitude ] to
// the lat/long object which Google Maps uses for positioning
export const toLatLong = coordinates => {
  if (coordinates?.length === 2) {
    return {
      lat: coordinates[LATITUDE],
      lng: coordinates[LONGITUDE],
    }
  }

  return { lat: null, lng: null }
}
