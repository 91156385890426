import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { IUseAddMutation, IAddFn, IGetPostRequest, IFacilityTagAddUpsert } from '../../interfaces'

import config from '../../config'

const getAddConditionsTagsMutationRequest: IGetPostRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Conditions/AddTagsBulk`,
  }
}

const useAddConditionsTagsMutation: IUseAddMutation<unknown, IFacilityTagAddUpsert[]> = (params, options = {}) => {
  const request = useMemo(() => getAddConditionsTagsMutationRequest(params), [params])

  const addConditionsTags: IAddFn<IFacilityTagAddUpsert[], unknown> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<unknown, Error, IFacilityTagAddUpsert[]>({
    mutationFn: addConditionsTags,
    ...options,
  })

  return response
}

export default useAddConditionsTagsMutation
