import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateSortInspectionTemplateMasterMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/InspectionTemplateMaster/Sort`,
})

export default getUpdateSortInspectionTemplateMasterMutationRequest
