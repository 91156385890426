import { buildGetQuery } from '../../util'
import config from '../../config'

import { IPagedResults } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  notificationIds?: number[]
  featureIds?: number[]
  notificationTypeIds?: number[]
  searchText?: string
  startedAtFrom?: string
  startedAtTo?: string
  startedByIds?: string[]
  endedAtFrom?: string
  endedAtTo?: string
  endedByIds?: string[]
  limit: number
  page: number
  sortBy?: string
  sortOrder?: string
}

const getNotificationsSearchRequest = (params: Params, data?: QueryObject): any => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Notifications/Search`,
    data,
  }
}

const useNotificationsSearchQuery = buildGetQuery<Params, QueryObject | undefined, IPagedResults<any[]> | undefined>({
  getRequest: getNotificationsSearchRequest,
  getQueryKey: (params, queryObject) => [
    'notifications-search',
    params.airportId,
    queryObject?.notificationIds,
    queryObject?.featureIds,
    queryObject?.notificationTypeIds,
    queryObject?.searchText,
    queryObject?.startedAtFrom,
    queryObject?.startedAtTo,
    queryObject?.startedByIds,
    queryObject?.endedAtFrom,
    queryObject?.endedAtTo,
    queryObject?.endedByIds,
    queryObject?.limit,
    queryObject?.page,
    queryObject?.sortBy,
    queryObject?.sortOrder,
  ],
})

export default useNotificationsSearchQuery
