import { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react'
import { AuthProvider as OIDCAuthProvider } from 'oidc-react'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { useNavigate } from 'react-router-dom'

import config from '../config'

const userManager = new UserManager({
  authority: String(config.oidc.authority),
  client_id: String(config.oidc.client_id),
  post_logout_redirect_uri: String(config.oidc.post_logout_redirect_uri),
  redirect_uri: String(config.oidc.redirect_uri),
  response_type: String(config.oidc.response_type),
  scope: String(config.oidc.scope),
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  loadUserInfo: true,
  automaticSilentRenew: true,
})

export const initialState = {
  logout: () => {},
  isAuthenticated: false,
}

export const AuthContext = createContext(initialState)

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const navigate = useNavigate()

  const logout = useCallback(() => {
    setIsAuthenticated(false)

    userManager
      .signoutRedirect({
        id_token_hint: String(localStorage.getItem('id_token')),
      })
      .catch(error => {
        console.warn('Something went wrong during logout: ', error)
      })
      .finally(() => {
        userManager.clearStaleState()
      })
  }, [])

  useEffect(() => {
    // Check if user presented after reload
    userManager.getUser().then(userData => {
      if (userData) {
        setIsAuthenticated(true)
      } else {
        // Prevent user from being stuck on a white screen if they're using a stale bookmark
        userManager.signinCallback(window.location.href).catch(
          error => {
            if(error.toString().startsWith('Error: No matching state found in storage')) {
              window.location.href = String(config.oidc.redirect_uri)
            } 
          }
        )
      }
    })

    // This callback is called after login
    userManager.events.addUserLoaded(() => {
      setIsAuthenticated(true)
      navigate('/', { replace: true })
    })

    userManager.events.addUserSignedOut(() => setIsAuthenticated(false))

    userManager.events.addAccessTokenExpired(() => {
      setIsAuthenticated(false)
      logout()
    })
  }, [logout, navigate])

  const value = useMemo(() => ({ isAuthenticated, logout }), [isAuthenticated, logout])

  return (
    <OIDCAuthProvider userManager={userManager}>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </OIDCAuthProvider>
  )
}
