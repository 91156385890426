import React from 'react'

const MultiSelectContext = React.createContext()

let initialState = {
    multiSelectNumLabels: 1
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setControlWidth':
      return {
        ...state,
        controlWidth: action.payload,
      }
    case 'setMultiSelectNumLabels':
      return {
        ...state,
        multiSelectNumLabels: action.payload,
      }
    default:
      return state
  }
}

const MultiSelectContextProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <MultiSelectContext.Provider value={value}>{props.children}</MultiSelectContext.Provider>
}

const MultiSelectContextConsumer = MultiSelectContext.Consumer

export { MultiSelectContext, MultiSelectContextProvider, MultiSelectContextConsumer }
