import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery, QueryObserverOptions, DefinedUseQueryResult, QueryKey } from '@tanstack/react-query'

type IGetRequestObject = {
  method: 'get'
  url: string
}

type IPostRequestObject = {
  method: 'post'
  url: string
  data?: { [key: string]: any }
}

export interface IGetFn<T> {
  (): Promise<T>
}

export const buildGetQuery = <TParams, TQueryObject, TData>({
  getRequest,
  getQueryKey,
  defaultOptions = {},
}: {
  getRequest: (params: TParams, queryObject: TQueryObject) => IGetRequestObject | IPostRequestObject
  getQueryKey: (params: TParams, queryObject: TQueryObject) => QueryKey
  defaultOptions?: QueryObserverOptions<TData, Error>
}) => {
  const useGetQuery = (
    params: TParams,
    queryObject: TQueryObject,
    options: QueryObserverOptions<TData, Error> = {},
  ): DefinedUseQueryResult<TData, Error> => {
    const request = useMemo(() => getRequest(params, queryObject), [params, queryObject])

    const fetchQuery: IGetFn<TData> = useCallback(() => axios(request).then(res => res.data), [request])

    const queryKey = useMemo(() => getQueryKey(params, queryObject), [params, queryObject])

    const response = useQuery<TData, Error>(queryKey, fetchQuery, {
      initialData: [] as TData,
      refetchOnWindowFocus: false,
      retry: false,
      ...defaultOptions,
      ...options,
    })

    return { ...response, queryKey } as never as DefinedUseQueryResult<TData, Error>
  }

  return useGetQuery
}
