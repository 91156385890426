import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateTrainingSessionRecordProgressMutationRequest: IGetPutRequest = params => {
  const { id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/TrainingSessions/${id}/RecordProgress`,
  }
}

export default getUpdateTrainingSessionRecordProgressMutationRequest
