import { FC, useMemo } from 'react'

import SafetyReportCustomizationFormFields from './safety-report-customization-form-fields'

import { ISafetyReportCustomizationFormProps } from './safety-report-customization-form-types'

const SafetyReportCustomizationForm: FC<ISafetyReportCustomizationFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <SafetyReportCustomizationFormFields disabled={isDisabled} />
    </form>
  )
}

export default SafetyReportCustomizationForm
