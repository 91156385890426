import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IUseUpdateMutation, IGetFn } from '../../interfaces'

const getDownloadActivityLogsCSVQueryRequest: any = (params: any) => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/ActivityLogs/Download`,
  }
}

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'journals'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadActivityLogsCSVMutation: IUseUpdateMutation<any, any> = (params: any, options = {}) => {
  const request = useMemo(() => getDownloadActivityLogsCSVQueryRequest(params), [params])

  const fetchActivityLogsCSVFile: IGetFn<Blob, any> | undefined = useCallback(
    async (data: any) =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchActivityLogsCSVFile,
    ...options,
  })

  return response
}

export default useDownloadActivityLogsCSVMutation
