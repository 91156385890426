import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IInspectionSubtype, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly: boolean
}

const getInspectionSubtypesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/InspectionSubtypes${queryString}`,
  }
}

const useInspectionSubtypesQuery = buildGetQuery<Params, QueryObject | undefined, IInspectionSubtype[] | undefined>({
  getRequest: getInspectionSubtypesRequest,
  getQueryKey: (params, queryObject) => ['inspection-subtypes', params.airportId, queryObject?.activeOnly || ''],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useInspectionSubtypesQuery
