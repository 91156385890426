import config from '../../config'
import { buildGetQuery } from '../../util'

import { IWildlifeSeedData } from './use-wildlife-seed-data-types'
import { IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

const getWildlifeSeedDataRequest = (params: Params): IGetRequestObject => {
  const { airportId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WildlifeSightings/SeedData`,
  }
}

const useWildlifeSeedDataQuery = buildGetQuery<Params, undefined, IWildlifeSeedData | undefined>({
  getRequest: getWildlifeSeedDataRequest,
  getQueryKey: params => ['wildlife-seed-data', params.airportId || ''],
  defaultOptions: { initialData: undefined },
})

export default useWildlifeSeedDataQuery
