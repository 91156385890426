import { FC, Fragment } from 'react'
import { FieldArray } from 'react-final-form-arrays'

import ChecklistItem from './checklist-item'
import Spinner from '../../../../spinner/spinner'

import { useAuthUsersQuery } from '../../../../../services'
import { useProfile, useAccessControl, claims } from '../../../../../security'

type ChecklistItemsProps = {
  name?: string
  disabled?: boolean
}

const ChecklistItems: FC<ChecklistItemsProps> = (props: any) => {
  const { name, disabled } = props

  const { airportId, userId } = useProfile()

  const { hasAccess } = useAccessControl()

  const { data: authUsers, isInitialLoading: isAuthUsersInitialLoading } = useAuthUsersQuery({ airportId }, undefined)

  if (!authUsers && isAuthUsersInitialLoading) {
    return <Spinner />
  }

  const hasAdminWriteAccess = hasAccess([claims.admin.write])

  return (
    <>
      <h5>Checklist</h5>

      <FieldArray name={name}>
        {({ fields }) =>
          fields.map((fieldName, index) => {
            const original = fields.value?.find((_, i) => i === index)
            const user = authUsers?.find(authUsers => authUsers.id === original?.userId)

            return (
              <Fragment key={original?.userId}>
                {fields.value?.length > 1 && <h5 className="mb-5 mt-5">{user?.userName}</h5>}

                {original.checklistItems.map((checklistItem: any, checklistItemIndex: number) => (
                  <ChecklistItem
                    name={`${fieldName}.checklistItems[${checklistItemIndex}]`}
                    key={checklistItem.id}
                    original={checklistItem}
                    disabled={disabled || (!hasAdminWriteAccess && userId !== original?.userId)}
                  />
                ))}
              </Fragment>
            )
          })
        }
      </FieldArray>
    </>
  )
}

export default ChecklistItems
