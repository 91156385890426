import React, { useState } from 'react'
import { any, bool, string } from 'prop-types'

import prettifyJson from './prettify-json'

// A developer utility to help debug
// It uses some bootstrap styling to make it look nice, but will work without it too.
const Debug = props => {
  const [show, setShow] = useState(props.show)

  const prefix = show ? 'Hide' : 'Show'
  return (
    <div className="my-2">
      <button className="btn btn-danger" onClick={() => setShow(!show)} type="button">
        {prefix} {props.text}
      </button>
      {show && <pre className="text-danger">{prettifyJson(props.data)}</pre>}
    </div>
  )
}

Debug.defaultProps = {
  data: 'No data passed in to display',
  show: false,
  text: 'Debug',
}

Debug.propTypes = {
  data: any,
  show: bool,
  text: string,
}

export default Debug
