// This is a FE representation of what the backend has for Feature Type values
const Inspection = { id: 1, name: 'Inspection', description: 'Inspection' }
const WorkOrder = { id: 2, name: 'Work Order', description: 'Work Order' }
const Wildlife = { id: 3, name: 'Wildlife', description: 'Wildlife' }
const Training = { id: 4, name: 'Training', description: 'Training' }
const Reminder = { id: 5, name: 'Reminder', description: 'Reminder' }
const Checklist = { id: 6, name: 'Checklist', description: 'Checklist' }
const features = [Inspection, WorkOrder, Wildlife, Training, Reminder, Checklist]

const unknownFeature = { id: -1, name: 'Unknown', description: 'Unknown feature type' }

const getById = (id: number) => features.find(f => f.id === id) || unknownFeature

const featureType = {
  getById,
  Inspection,
  Training,
  Wildlife,
  WorkOrder,
  Reminder,
  Checklist,
}
export default featureType
