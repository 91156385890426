import { FC, useMemo } from 'react'
import cn from 'classnames'

import { IContentMapLayout } from './content-map-layout-types'
import { useContentMapLayout } from '../hooks'

import './content-map-layout.scss'

const ContentMapLayout: FC<IContentMapLayout> = ({
  children,
  contentDisplayName = 'List',
  mapDisplayName = 'Map',
  toggleClassName,
  contentShrink = 2,
  contentGrow = 1,
  contentWrapperClassName = '',
  showMap: externalShowMap,
  showContent: externalShowContent,
  toggleView: externalToggleView,
}) => {
  const isExternalControl =
    externalShowMap !== undefined || externalShowContent !== undefined || externalToggleView !== undefined

  const {
    showMap: internalShowMap,
    showContent: internalShowContent,
    toggleView: internalToggleView,
  } = useContentMapLayout({
    defaultShowMapOnFullScreen: true,
    enabled: !isExternalControl,
  })

  const showMap = useMemo(
    () => (isExternalControl ? externalShowMap : internalShowMap),
    [isExternalControl, externalShowMap, internalShowMap],
  )

  const showContent = useMemo(
    () => (isExternalControl ? externalShowContent : internalShowContent),
    [isExternalControl, externalShowContent, internalShowContent],
  )

  const toggleView = useMemo(
    () => (isExternalControl ? externalToggleView : internalToggleView),
    [isExternalControl, externalToggleView, internalToggleView],
  )

  const contentCssProperties = useMemo(
    () => ({ flex: `${contentGrow} ${contentShrink} auto` }),
    [contentGrow, contentShrink],
  )

  const contentClassName = useMemo(
    () =>
      cn(
        `content-map-layout__content ${
          showContent ? 'content-map-layout__content-show w-100' : 'content-map-layout__content-hide'
        }`,
        contentWrapperClassName,
      ),
    [showContent, contentWrapperClassName],
  )

  const mapClassName = useMemo(
    () =>
      `content-map-layout__map__content ${
        showMap ? 'content-map-layout__map__content-show w-100' : 'content-map-layout__map__content-hide'
      }`,
    [showMap],
  )

  const toggleIconClassName = showMap ? 'bi bi-list' : 'bi bi-map'

  const toggleText = showMap ? contentDisplayName : mapDisplayName

  return (
    <>
      <div className={cn('content-map-layout__content-map__toggle', toggleClassName)}>
        <button className="btn btn-secondary" onClick={toggleView}>
          <i className={toggleIconClassName} />

          {toggleText}
        </button>
      </div>
      <div className="content-map-layout__page__container mb-0">
        <div className="content-map-layout__container">
          <div className={contentClassName} style={contentCssProperties}>
            {children?.content}
          </div>
          <div className={mapClassName}>{children?.map}</div>
        </div>
      </div>
    </>
  )
}

export default ContentMapLayout
