import { FC, useMemo } from 'react'

import InspectionFilterFormFields from './inspection-filter-fields'

import { IInspectionFilterFormProps } from './inspection-filter-form-types'

const InspectionFilterForm: FC<IInspectionFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <InspectionFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default InspectionFilterForm
