import { FC, useMemo } from 'react'

import TableSettingsFormFields from './table-settings-form-fields'

import { ITableSettingsFormProps } from './table-settings-form-types'

const TableSettingsForm: FC<ITableSettingsFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, columnsOptions } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <TableSettingsFormFields disabled={isDisabled} columnsOptions={columnsOptions} />
    </form>
  )
}

export default TableSettingsForm
