import React from 'react'
import { string, bool, func, oneOfType, number } from 'prop-types'
import cn from 'classnames'

import s from './list-item.module.scss'

const ListItem = ({ label, isSelected, handleClick, componentRef, disabled }) => (
  <li
    dangerouslySetInnerHTML={{ __html: label }}
    className={cn(s['list-item'], { [s['list-item-disabled']]: disabled })}
    onClick={disabled ? () => {} : handleClick}
    role="option"
    aria-selected={isSelected}
    ref={componentRef}
  />
)

ListItem.propTypes = {
  label: oneOfType([string, number]),
  isSelected: bool,
  handleClick: func,
  componentRef: func,
  disabled: bool,
}

export default ListItem
