import { getPolygonCoordinates } from './get-polygon-coorinates'

import { IPolygonToGeoJson } from './polygon-to-geo-json-types'

export const polygonToGeoJson: IPolygonToGeoJson = (polygon, color) => {
  const coordinates = getPolygonCoordinates(polygon)

  if (!coordinates) {
    return null
  }

  const geoJson = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates,
    },
    properties: {
      id: Math.random(),
      color: color,
    },
  }

  return geoJson
}
