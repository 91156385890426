import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import './navbar-menu.scss'

type NavbarMenuProps = {
  onMenuItemClick: () => void
  routes: Array<any>
  className?: string
}

const NavbarMenu: React.FC<NavbarMenuProps> = ({ onMenuItemClick, routes, className }) => (
  <ul className={cn('d-flex navbar-nav mr-auto mt-lg-0 navbar-menu-items', className)}>
    {routes
      .filter(x => x.showInMenu)
      .map(route => (
        <li className="nav-item my-auto px-2" key={route.url}>
          <NavLink to={route.url} className="nav-link nav-link-override" onClick={onMenuItemClick}>
            {route.label}
          </NavLink>
        </li>
      ))}
  </ul>
)

export default NavbarMenu
