const createMarkerIcon = options => {
  const borderColor = options?.borderColor || '#ffffff'
  const borderWidth = options?.borderWidth || 3
  const fillColor = options?.fillColor || '#00B881'

  return `<svg width="38" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M 22 40 L 33 20 H 11 L 22 40 Z" fill="white" />
    <g filter="url(#filter0_d_0_1169)">
      <circle cx="22" cy="20" r="12" fill="${fillColor}" />
      <circle cx="22" cy="20" r="10.5" stroke="${borderColor}" stroke-width="${borderWidth}" />
    </g>
    <defs>
      <filter
        id="filter0_d_0_1169"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1169" result="shape" />
      </filter>
    </defs>
  </svg>`
}

export default createMarkerIcon
