import useAxios from '../use-axios'

import getSafetyItemUniqueValuesRequest from './get-safety-item-unique-values-request'

const useSafetyItemsUniqueValues = (params, queryObject, axiosOptions = {}) => {
  const request = getSafetyItemUniqueValuesRequest(params, queryObject)

  const { data, error, loading, refetch } = useAxios(request, { defaultValue: [], ...axiosOptions })

  return { data, error, loading, execute: refetch }
}

export default useSafetyItemsUniqueValues
