import { QueryObserverOptions } from '@tanstack/react-query'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IPersonalizationMapStatus } from '../../interfaces'

type Params = {
  airportId: number
}

export const usePersonalizationMapStatusesQueryBase = buildGetQuery<
  Params,
  undefined,
  IPersonalizationMapStatus[] | undefined
>({
  getRequest: params => ({ method: 'get', url: `${config.baseUrl}/${params.airportId}/PersonalizationMapStatuses` }),
  getQueryKey: () => ['personalization-map-statuses'],
  defaultOptions: {
    initialData: undefined,
  },
})

const usePersonalizationMapStatusesQuery = (
  params: Params,
  options?: QueryObserverOptions<IPersonalizationMapStatus[] | undefined, Error>,
) => usePersonalizationMapStatusesQueryBase(params, undefined, options)

export default usePersonalizationMapStatusesQuery
