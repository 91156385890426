import { FC, useMemo } from 'react'

import ActivityLogFilterFormFields from './activity-log-filter-fields'

import { IActivityLogFilterFormProps } from './activity-log-filter-form-types'

const ActivityLogFilterForm: FC<IActivityLogFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <ActivityLogFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default ActivityLogFilterForm
