import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject, IWorkOrderType } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
  includeDetail?: boolean
}

const getWorkOrderTypesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WorkOrderTypes${queryString}`,
  }
}

const useWorkOrderTypesQuery = buildGetQuery<Params, QueryObject | undefined, IWorkOrderType[] | undefined>({
  getRequest: getWorkOrderTypesRequest,
  getQueryKey: (params, queryObject) => [
    'work-order-types',
    params.airportId,
    queryObject?.activeOnly,
    queryObject?.includeDetail,
  ],
  defaultOptions: { initialData: undefined },
})

export default useWorkOrderTypesQuery
