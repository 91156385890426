import config from '../../config'
import { buildGetQuery } from '../../util'

import { IChecklistReminder, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

const getChecklistReminderssRequest = (params: Params): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/${params.airportId}/Reminders/Checklist`,
})

const useChecklistReminderssQuery = buildGetQuery<Params, undefined, IChecklistReminder[] | undefined>({
  getRequest: getChecklistReminderssRequest,
  getQueryKey: () => ['checklist-reminders'],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useChecklistReminderssQuery
