import cn from 'classnames'

import { Button } from '../../../components/button'

import s from '..//drawer-header.module.scss'

type ProfileDrawerHeaderProps = {
  dismissAll: () => any
  onClose: () => any
  notificationCount: number
}

const NotificationDrawerHeader: React.FC<ProfileDrawerHeaderProps> = ({ dismissAll, onClose, notificationCount }) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100 text-dark">
      <h4 className="mb-0">Notifications ({notificationCount})</h4>

      <div className="d-flex">
        {notificationCount > 0 && (
          <button type="button" className="btn btn-link-primary mr-4" onClick={dismissAll}>
            Dismiss All
          </button>
        )}

        <Button type="secondary-icon" className={`${s['close-btn']}`} onClick={onClose}>
          <i className={cn('bi bi-x', s['close-btn-icon'])} />
        </Button>
      </div>
    </div>
  )
}

NotificationDrawerHeader.defaultProps = {
  dismissAll: () => {},
  onClose: () => {},
  notificationCount: 0,
}

export default NotificationDrawerHeader
