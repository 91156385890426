import qs from 'qs'
import { useMemo } from 'react'

import config from '../../config'
import { buildGetQuery, bySortOrderAscending } from '../../util'
import { QueryObserverOptions } from '@tanstack/react-query'

import { IWorkOrderStatus, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  baseStatus?: string
  entityState?: string
  activeOnly?: boolean
}

const getWorkOrderStatusesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WorkOrderStatuses${queryString}`,
  }
}

const useWorkOrderStatusesQueryBase = buildGetQuery<Params, QueryObject | undefined, IWorkOrderStatus[] | undefined>({
  getRequest: getWorkOrderStatusesRequest,
  getQueryKey: (params, queryObject) => [
    'work-order-statuses',
    params?.airportId,
    queryObject?.baseStatus,
    queryObject?.entityState,
    queryObject?.activeOnly,
  ],
})

const useWorkOrderStatusesQuery = (
  params: Params,
  queryObject?: QueryObject,
  options?: QueryObserverOptions<IWorkOrderStatus[] | undefined, Error>,
  applyNameSorting = true,
) => {
  const { data, ...restQuery } = useWorkOrderStatusesQueryBase(params, queryObject, options)

  const defaultItem = useMemo(() => (data || []).find(d => d.isDefault), [data])

  const sortedData = useMemo(
    () => (applyNameSorting ? (data || []).sort(bySortOrderAscending) : data),
    [data, applyNameSorting],
  )

  return { ...restQuery, defaultItem, data: sortedData }
}
export default useWorkOrderStatusesQuery
