import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject, IFaq } from '../../interfaces'

const getFAQsRequest = (): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/help/Faqs`,
})

const useFAQsQuery = buildGetQuery<undefined, undefined, IFaq[] | undefined>({
  getRequest: getFAQsRequest,
  getQueryKey: () => ['faqs'],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useFAQsQuery
