import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getInspectionTypeByIdQueryRequest from './get-inspection-type-by-id-query-request'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IInspectionType } from '../../interfaces'

const useInspectionTypeByIdQuery: IUseGetQuery<any, IInspectionType> = (params, queryObject = {}, options = {}) => {
  const request = useMemo(() => getInspectionTypeByIdQueryRequest(params, queryObject), [params, queryObject])

  const fetchInspectionTypeById: IGetFn<IInspectionType> = useCallback(
    () => axios(request).then(res => res.data),
    [request],
  )

  const queryKey = useMemo(
    () => ['inspection-type-by-id', params.id, params.airportId, queryObject],
    [params, queryObject],
  )

  const response = useQuery<IInspectionType | undefined, Error>(queryKey, fetchInspectionTypeById, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useInspectionTypeByIdQuery
