import { buildGetQuery } from '../../util'
import config from '../../config'

import { IPostRequestObject, IPagedResults, IWorkOrderListItem, IWorkOrderSearchFilter } from '../../interfaces'

type Params = {
  airportId: number
}

const getFilteredWorkOrdersRequest = (
  params: Params,
  queryObject: IWorkOrderSearchFilter | undefined,
): IPostRequestObject => ({
  method: 'post',
  url: `${config.baseUrl}/${params.airportId}/WorkOrders/Search`,
  data: queryObject,
})

const useWorkOrdersSearchQuery = buildGetQuery<
  Params,
  IWorkOrderSearchFilter | undefined,
  IPagedResults<IWorkOrderListItem[] | undefined>
>({
  getRequest: getFilteredWorkOrdersRequest,
  getQueryKey: (params, queryObject) => [
    'work-orders-search',
    params.airportId,
    queryObject?.entityState,
    queryObject?.startedAtBegin,
    queryObject?.startedAtEnd,
    queryObject?.endedAtBegin,
    queryObject?.endedAtEnd,
    queryObject?.workOrderIds,
    queryObject?.workOrderTypeIds,
    queryObject?.facilityIds,
    queryObject?.conditionIds,
    queryObject?.locationIds,
    queryObject?.statusIds,
    queryObject?.priorityIds,
    queryObject?.notificationGroupIds,
    queryObject?.inspectionSubtypeIds,
    queryObject?.assignedToIds,
    queryObject?.searchText,
    queryObject?.searchIds,
    queryObject?.limit,
    queryObject?.page,
    queryObject?.sortBy,
    queryObject?.sortOrder,
  ],
  defaultOptions: { initialData: undefined, refetchOnWindowFocus: false },
})

export default useWorkOrdersSearchQuery
