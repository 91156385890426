import { useCallback, useEffect, useState, useRef, useMemo } from 'react'

import axios from 'axios'

import './bearer-token-interceptor'

import { IUseAxiosParams, IUseAxiosOptions, IUseAxiosReturnValues, IMergeReqArguments } from './use-axios-types'

export const defaultParams = {
  data: null,
  method: 'get',
}

export const defaultOptions = {
  defaultValue: undefined,
  ignoreInitialRequest: false,
}

const mergeObjects = <T>(options: IUseAxiosOptions<T>, defaultOptions: IUseAxiosOptions<T>): IUseAxiosOptions<T> => ({
  ...defaultOptions,
  ...options,
})

const useAxios = <T>(params: IUseAxiosParams<T>, options: IUseAxiosOptions<T>): IUseAxiosReturnValues<T> => {
  // @ts-ignore:next-line
  const mergedOptions: IUseAxiosOptions<T> = useMemo(() => mergeObjects(options, defaultOptions), [options])

  const { data, method, url } = params
  const { defaultValue, ignoreInitialRequest } = mergedOptions

  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(!ignoreInitialRequest)
  const [responseData, setResponseData] = useState<T>(defaultValue)

  const makeRequest = useCallback(
    async (mergeReqArguments?: IMergeReqArguments<T>) => {
      let response

      try {
        if (mergeReqArguments) {
          setLoading(true)
        }

        const defaultArgs = { data, method, url }
        const args = mergeReqArguments ? mergeReqArguments(defaultArgs) : defaultArgs

        response = await axios(args)
        setResponseData(response.data)
      } catch (error: any) {
        let errorMessage

        if (!error?.message) {
          console.warn('Unhandled error during useAxios: ', error)
          errorMessage = 'Unknow Error'
        } else {
          errorMessage = error.message
        }

        setError(errorMessage)
      } finally {
        setLoading(false)
      }
    },
    [data, method, url],
  )

  const isRequestIgnored = useRef(false)

  useEffect(() => {
    if (ignoreInitialRequest) {
      if (isRequestIgnored.current) {
        makeRequest()
      } else {
        isRequestIgnored.current = true
      }

      return
    }

    makeRequest()
  }, [makeRequest, ignoreInitialRequest])

  return { data: responseData, error, loading, refetch: makeRequest }
}

export default useAxios
