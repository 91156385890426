import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDownloadWorkOrdersCSVQueryRequest from './get-download-work-orders-mutation-request'

import { IUseDownloadWorkOrdersCSVMutation, IFetchWorkOrdersCSVFile } from './types'

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'work-orders'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadWorkOrdersCSVQuery: IUseDownloadWorkOrdersCSVMutation = (params, options = {}) => {
  const request = useMemo(() => getDownloadWorkOrdersCSVQueryRequest(params), [params])

  const fetchWorkOrdersCSVFile: IFetchWorkOrdersCSVFile = useCallback(
    async (data: any) =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchWorkOrdersCSVFile,
    ...options,
  })

  return response
}

export default useDownloadWorkOrdersCSVQuery
