import axios from 'axios'

import config from '../../config'

const dismissReminder = ({ airportId, id }) => {
  const url = `${config.baseUrl}/${airportId}/Reminders/Occurrences/${id}/Dismiss`

  return axios.put(url)
}

export default dismissReminder
