import { useState, useMemo, useCallback } from 'react'
import { useDrop } from 'react-dnd'

const filterOptions = (options, filter, canFilter) =>
  canFilter ? options.filter(option => option.label.toLowerCase().includes(filter.toLowerCase())) : options

const useList = props => {
  const { canFilter, options, value, onChange, handleDropItems } = props

  const [filter, setFilter] = useState('')

  const computedOptions = useMemo(() => filterOptions(options, filter, canFilter), [filter, canFilter, options])

  const handleOptionChange = useCallback(
    accessor => {
      if (value.indexOf(accessor) > -1) {
        onChange(value.filter(v => v !== accessor))
      } else {
        onChange([...value, accessor])
      }
    },
    [value, onChange],
  )

  const checkIsSelected = useCallback(accessor => value.indexOf(accessor) > -1, [value])

  const [, dropRef] = useDrop(
    () => ({
      accept: 'OPTION',
      drop: handleDropItems,
    }),
    [handleDropItems],
  )

  return { filter, setFilter, computedOptions, handleOptionChange, checkIsSelected, dropRef }
}

export default useList
