import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject, INotam } from '../../interfaces'

const getNotamsRequest = (): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/Notams`,
})

export const useNotamsQueryBase = buildGetQuery<undefined, undefined, INotam[] | undefined>({
  getRequest: getNotamsRequest,
  getQueryKey: () => ['notams'],
})

const useNotamsQuery = (options?: QueryObserverOptions<INotam[] | undefined, Error>) =>
  useNotamsQueryBase(undefined, undefined, options)

export default useNotamsQuery
