import config from '../../config'

import { IGetDeleteRequest } from '../../interfaces'

const getDeleteTrainingSessionMutationRequest: IGetDeleteRequest = params => {
  return {
    method: 'delete',
    url: `${config.baseUrl}/TrainingSessions/`,
  }
}

export default getDeleteTrainingSessionMutationRequest
