import useAxios from '../use-axios'

import getInspectionRemindersRequest from './get-inspection-reminders-request'

const useInspectionReminders = (params, queryObject, axiosOptions = {}) => {
  const request = getInspectionRemindersRequest(params, queryObject)

  const { data, error, loading, refetch } = useAxios(request, { defaultValue: [], ...axiosOptions })

  return { data, error, loading, execute: refetch }
}

export default useInspectionReminders
