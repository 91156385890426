import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getCompleteInspectionMutationRequest: IGetPutRequest = params => {
  const { airportId, id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Inspections/${id}/Complete`,
  }
}

export default getCompleteInspectionMutationRequest
