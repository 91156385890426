import { FC } from 'react'
import { Table } from '@tanstack/react-table'

import { TableHeaderGroup } from '../table-header-group'

type TableHeaderProps = {
  tableInstance: Table<any>
  isRowsDraggable?: boolean
}

const TableHeader: FC<TableHeaderProps> = props => {
  const { tableInstance, isRowsDraggable } = props

  return (
    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
      {tableInstance.getHeaderGroups().map((headerGroup, index) => (
        <TableHeaderGroup key={index} headerGroup={headerGroup} isRowsDraggable={isRowsDraggable} />
      ))}
    </thead>
  )
}

export default TableHeader
