import config from '../../config'

import { IGetUpdateLocationMutationRequest } from './types'

const getUpdateLocationMutationRequest: IGetUpdateLocationMutationRequest = (params) => {
  const { airportId } = params

  return {
    method: 'PUT',
    url: `${config.baseUrl}/${airportId}/Locations/`,
  }
}

export default getUpdateLocationMutationRequest
