import { useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import { useCallback, useMemo } from 'react'
import config from '../../config'
import { IChecklist, IGetFn, IPagedResults } from '../../interfaces'

const useChecklistsFilteredQuery = (airportId: number, postData: any, options: any) => {
  const getFilteredChecklists = (airportId: any, data: any) => ({
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Checklists/instances/Search`,
    data,
  }) as AxiosRequestConfig

  const request = useMemo(() => getFilteredChecklists(airportId, postData), [airportId, postData])

  const queryKey = useMemo(() => ['filteredChecklists', airportId, postData], [airportId, postData])

  const fetchChecklists: IGetFn<IPagedResults<IChecklist[]>> = useCallback(() => axios(request).then(res => res.data), [request])

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    isFetching,
  } = useQuery(queryKey, fetchChecklists, {
    keepPreviousData: true,
    ...options,
  })

  return { data, error, loading, isFetching, refetch }
}

export default useChecklistsFilteredQuery
