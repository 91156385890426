import { ChangeEvent, FC, LegacyRef, useCallback } from 'react'
import cn from 'classnames'
import withAddons from '../adapters/with-addons'

import s from './input.module.scss'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  isInvalid: boolean
  className: string
  isViewMode: boolean
  componentRef: LegacyRef<HTMLInputElement> | undefined
  onChange: (value: string | number) => void
}

const Input: FC<InputProps> = props => {
  const { isInvalid, componentRef, className, onChange, value, isViewMode, disabled, ...inputProps } = props

  const inputClassNames = cn('form-control', className, {
    'border-danger': isInvalid,
    [s['input-view']]: isViewMode,
  })

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value)
      }
    },
    [onChange],
  )

  return (
    <input
      className={inputClassNames}
      ref={componentRef}
      onChange={handleChange}
      value={value}
      disabled={disabled || isViewMode}
      {...inputProps}
    />
  )
}

export default withAddons(Input)
