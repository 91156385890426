import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getWildlifeSpeciessRequest from './get-airport-wildlife-species-request'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IWildlifeSpecies } from '../../interfaces'

const useAirportWildlifeSpeciesQuery: IUseGetQuery<any, IWildlifeSpecies[]> = (
  params,
  queryObject = {},
  options = {},
) => {
  const request = useMemo(() => getWildlifeSpeciessRequest(params, queryObject), [params, queryObject])

  const fetchWildlifeSpeciess: IGetFn<IWildlifeSpecies[]> = useCallback(
    () => axios(request).then(res => res.data),
    [request],
  )

  const queryKey = useMemo(() => ['airport-wildlife-species'], [])

  const response = useQuery<IWildlifeSpecies[] | undefined, Error>(queryKey, fetchWildlifeSpeciess, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useAirportWildlifeSpeciesQuery
