import { parse, isValid, isBefore, parseISO, isAfter, isEqual } from 'date-fns'

const validationRegExp = {
  hexColor: /^#([0-9A-F]{3}){1,2}$/i,
  email: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  domain: /^@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  phone: /\d{10}/,
}

export const validationErrors = {
  email: 'Invalid email address',
  phone: 'Invalid phone number',
  time: 'Invalid time',
  hexColor: 'Should be HEX color',
  required: 'Required',
  maxSymbols: n => `Must not exceed ${n} ${n > 1 ? 'characters' : 'character'}`,
  maxFileSize: (size, scale) => `File size must be less than ${size} ${scale}`,
  passwordEqual: 'Confirm password should be equal to the new password',
  startDateRange: 'Start date should be before end date',
  endDateRange: 'End date should be after start date',
  onlyUnique: 'Should contain only unique symbols',
  domain: 'Should be domain (@test.com)',
  latitude: 'Should be latitude',
  longitude: 'Should be longitude',
}

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

export const validateHexColor = v => {
  if (!v) return v

  return validationRegExp.hexColor.test(v) ? undefined : validationErrors.hexColor
}

export const validateEmail = v => {
  if (!v) return v

  return validationRegExp.email.test(v) ? undefined : validationErrors.email
}

export const validatePhone = v => {
  if (!v) return v

  return validationRegExp.phone.test(v) ? undefined : validationErrors.phone
}

export const validateMaxSymbols = (max, errorMessage) => v => {
  if (!v) return v

  return v.length <= max ? undefined : errorMessage || validationErrors.maxSymbols(max)
}

export const validateOnlyUniqueSymbols = v => {
  if (!v) return v

  const isUnique = Object.values(v).reduce((acc, key) => {
    if (v.split(key).length - 1 > 1) {
      acc = false
    }

    return acc
  }, true)

  return isUnique ? undefined : validationErrors.onlyUnique
}

export const validateTime = (time, date = new Date()) =>
  isValid(parse(time, 'HH:mm', date)) ? undefined : validationErrors.time

export const validateRequired = value => (value ? undefined : validationErrors.required)

export const validateArrayRequired = value => (value && value.length > 0 ? undefined : validationErrors.required)

export const validateSoftRequired = value => (typeof value != 'undefined' ? undefined : validationErrors.required)

export const validateDomain = v => {
  if (!v) return v

  return validationRegExp.domain.test(v) ? undefined : validationErrors.domain
}

export const validateLatitude = v => {
  if (!v) return v

  return isFinite(v) && Math.abs(v) <= 90 ? undefined : validationErrors.latitude
}

export const validateLongitude = v => {
  if (!v) return v

  return isFinite(v) && Math.abs(v) <= 180 ? undefined : validationErrors.longitude
}

export const getValidateEquality = (dependentFieldName, errorMessage) => (value, values) => {
  if (!value) return value

  return values[dependentFieldName] === value ? undefined : errorMessage
}

export const getStartDateRangeValidator = endDateName => (startDate, allValues) => {
  const endDate = allValues[endDateName]

  let formattedStartDate
  let formattedEndDate

  try {
    formattedStartDate = parseISO(startDate)
    formattedEndDate = parseISO(endDate)
  } catch (error) {
    return undefined
  }

  return !endDate ||
    !startDate ||
    isEqual(formattedStartDate, formattedEndDate) ||
    isBefore(formattedStartDate, formattedEndDate)
    ? undefined
    : validationErrors.startDateRange
}

export const getEndDateRangeValidator = startDateName => (endDate, allValues) => {
  const startDate = allValues[startDateName]

  let formattedStartDate
  let formattedEndDate

  try {
    formattedStartDate = parseISO(startDate)
    formattedEndDate = parseISO(endDate)
  } catch (error) {
    return undefined
  }

  return !startDate ||
    !endDate ||
    isEqual(formattedStartDate, formattedEndDate) ||
    isAfter(formattedEndDate, formattedStartDate)
    ? undefined
    : validationErrors.endDateRange
}

export const getValidateFileSizeLessThan = (size, scale) => file => {
  if (!+file.size) {
    return undefined
  }

  const k = 1024
  const scales = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(file.size) / Math.log(k))

  if (scales.indexOf(scale) > i) {
    return undefined
  } else if (scales.indexOf(scale) === i) {
    const fileSizeInScale = file.size / Math.pow(k, i)
    return fileSizeInScale < size ? undefined : validationErrors.maxFileSize(size, scale)
  }

  return validationErrors.maxFileSize(size, scale)
}
