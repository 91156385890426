import { FC } from 'react'
import cn from 'classnames'
import { SortDirection } from '@tanstack/react-table'

import s from './sort-icon.module.scss'

type SortIconProps = {
  isSorted: boolean | SortDirection
}

const SortIcon: FC<SortIconProps> = props => {
  const { isSorted } = props

  const iconCN = cn(
    s['search-icon'],
    isSorted ? s['search-icon-active'] : 'bi-arrow-down-up',
    isSorted ? (isSorted === 'asc' ? 'bi-arrow-up' : 'bi-arrow-down') : '',
  )

  return <i className={iconCN} />
}

export default SortIcon
