import ReactMarkdown from 'react-markdown'
import { Spinner } from '../spinner'
import { useChangelogQuery } from '../../services'

import s from './changelog.module.scss'

type ChangelogProps = {
  version: string
}

const Changelog: React.FC<ChangelogProps> = props => {
  const { version } = props

  const { data: markdownContent, isFetching: isMarkdownContentLoading } = useChangelogQuery(
    { version },
    { refetchOnWindowFocus: false, enabled: version !== undefined },
  )

  if (isMarkdownContentLoading) {
    return (
      <div className="w-100 h-100">
        <Spinner />
      </div>
    )
  }

  if (markdownContent) {
    return (
      <div className={s['markdown-content-scope']}>
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </div>
    )
  } else {
    return <div>Not found</div>
  }
}

export default Changelog
