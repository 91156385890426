import { FC, useCallback, useMemo, SyntheticEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tabs as MuiTabs } from '@mui/material'

import HorizontalRule from '../horizontal-rule'
import HorizontalTabContainer from './horizontal-tab-container'
import LinkTab from './link-tab'

import { useNavigationRoutes } from '../../hooks'

// @ts-ignore
import ls from './link-tab.module.scss'

type TabsProps = {
  routes: any
}

const Tabs: FC<TabsProps> = props => {
  const { routes } = props

  const options = useNavigationRoutes(routes)

  const { pathname } = useLocation()

  const navigate = useNavigate()

  const currentTabIndex = useMemo(() => {
    const currentOption = options.findIndex(o => pathname.toLowerCase().includes(o.to.toLowerCase()))

    return currentOption !== -1 ? currentOption : 0
  }, [options, pathname])

  const tabs = options.map(option => (
    <LinkTab
      className={`mat-tab ${ls['mat-tab']}`}
      key={option.to}
      label={option.label}
      href={option.to}
      disabled={option.disabled}
    />
  ))

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      const option = options[newValue]

      if (option) {
        navigate({ pathname: option.to, search: option.search }, { replace: option.replace || false })
      }
    },
    [options, navigate],
  )

  return (
    <HorizontalTabContainer>
      <MuiTabs onChange={handleChange} scrollButtons="auto" variant="scrollable" value={currentTabIndex}>
        {tabs}
      </MuiTabs>

      <HorizontalRule className={ls['horizontal-rule']} />
    </HorizontalTabContainer>
  )
}

export default Tabs
