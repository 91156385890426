import { FC, useMemo } from 'react'

import SavedFilterFormFields from './saved-filter-form-fields'

import { ISavedFilterFormProps } from './saved-filter-form-types'

const SavedFilterForm: FC<ISavedFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values, form } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <SavedFilterFormFields disabled={isDisabled} values={values} form={form} />
    </form>
  )
}

export default SavedFilterForm
