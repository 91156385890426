import { array } from 'prop-types'

const MoreSelectedTooltip = ({ items }) => {
  return items.map((x, index) => {
    return (
      <div className="d-flex" key={index}>
        {x}
      </div>
    )
  })
}

export default MoreSelectedTooltip

MoreSelectedTooltip.propTypes = {
  items: array,
}
