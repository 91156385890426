import config from '../../config'

import { IGetSortPrioritiesMutationRequest } from './get-sort-priorities-mutation-request-types'

const getSortPrioritiesMutationRequest: IGetSortPrioritiesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Priorities/Sort`,
  }
}

export default getSortPrioritiesMutationRequest
