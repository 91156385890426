import config from '../../config'

import { IGetPutRequest } from '../../interfaces'

const getUpdateSetInspectionTemplateMasterMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/InspectionTemplateMaster/Set`,
})

export default getUpdateSetInspectionTemplateMasterMutationRequest
