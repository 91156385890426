import { createContext, FC, useMemo, useCallback } from 'react'

import { buildColumn, buildRow, buildSubheadRow } from '../checklist-utils'

import { ChecklistRow } from '../checklist-types'
import { IColumnFormValues } from '../../form'

import { IChecklistState, IChecklistContextProviderProps } from './checklist-context-types'

const initialState: IChecklistState = {
  checklist: undefined,
  addChecklistColumn: () => {},
  updateChecklistColumn: () => {},
  removeChecklistColumn: () => {},
  addChecklistRow: () => {},
  removeChecklistRow: () => {},
  onChecklistCellDataChange: () => {},
  onChecklistSubheadRowChange: () => {},
  moveRow: () => {},
  moveColumn: () => {},
  isConfigurable: false,
  isDisabled: false,
}

export const ChecklistContext = createContext<IChecklistState>(initialState)

const ChecklistContextProvider: FC<IChecklistContextProviderProps> = props => {
  const { value: checklist, onChange: setChecklist, isConfigurable = false, isDisabled = false } = props

  const addChecklistColumn = useCallback(
    (values: IColumnFormValues, columnIndex: number) => {
      if (!checklist) {
        return
      }

      const amountOfColumns = Number(values.columnsAmount) || 1

      const newColumns = Array.from(Array(amountOfColumns).keys()).map(() => buildColumn(values))

      const columns = [
        ...checklist.columns.slice(0, columnIndex),
        ...newColumns,
        ...checklist.columns.slice(columnIndex),
      ]

      const newColumnsData = newColumns.reduce<any>((acc, column) => {
        acc[column.id] = {
          value: '',
        }
        return acc
      }, {})

      const data = checklist.data.map(dataItem => ({
        ...dataItem,
        ...newColumnsData,
      }))

      setChecklist({ ...checklist, columns, data })
    },
    [checklist, setChecklist],
  )

  const updateChecklistColumn = useCallback(
    (values: IColumnFormValues, columnIndex: number) => {
      if (!checklist) {
        return
      }

      const updatingColumnId = checklist.columns[columnIndex].id

      let isClearValueRequired = false
      const columns = checklist.columns.map(column => {
        if (column.id === updatingColumnId) {
          isClearValueRequired = column.columnType !== values.type

          return { ...buildColumn(values), id: updatingColumnId }
        }
        return column
      })

      const data = checklist.data.map(dataItem =>
        !dataItem.isSubhead
          ? {
              ...dataItem,

              [updatingColumnId]: {
                value: isClearValueRequired ? '' : dataItem[updatingColumnId].value,
              },
            }
          : dataItem,
      )

      setChecklist({ ...checklist, columns, data })
    },
    [checklist, setChecklist],
  )

  const removeChecklistColumn = useCallback(
    (columnIndex: number) => {
      if (!checklist) {
        return
      }

      const columnId = checklist.columns[columnIndex].id

      const columns = checklist.columns.filter((column, index) => index !== columnIndex)

      const data = checklist.data.map((dataItem: any) => {
        const { [columnId]: omit, ...restDataItem } = dataItem

        return restDataItem as ChecklistRow
      })

      setChecklist({ ...checklist, columns, data })
    },
    [checklist, setChecklist],
  )

  const addChecklistRow = useCallback(
    (rowIndex: number, isSubheadRow = false) => {
      if (!checklist) {
        return
      }

      const row = isSubheadRow ? buildSubheadRow() : buildRow(checklist.columns)

      const data = [...checklist.data.slice(0, rowIndex), row, ...checklist.data.slice(rowIndex)]

      setChecklist({ ...checklist, data })
    },
    [checklist, setChecklist],
  )

  const removeChecklistRow = useCallback(
    (rowIndex: number) => {
      if (!checklist) {
        return
      }

      const data = checklist.data.filter((row, index) => index !== rowIndex)

      setChecklist({ ...checklist, data })
    },
    [checklist, setChecklist],
  )

  const onChecklistCellDataChange = useCallback(
    ({ rowId, columnId, value }: any) => {
      if (!checklist) {
        return
      }

      const data = checklist.data.map(dataItem => {
        if (dataItem.id === rowId) {
          return {
            ...dataItem,
            [columnId]: {
              ...dataItem[columnId],

              value: value,
            },
          }
        } else {
          return dataItem
        }
      })

      // @ts-ignore
      setChecklist({ ...checklist, data })
    },
    [checklist, setChecklist],
  )

  const onChecklistSubheadRowChange = useCallback(
    ({ rowId, value }: any) => {
      if (!checklist) {
        return
      }

      const data = checklist.data.map(dataItem => {
        if (dataItem.id === rowId) {
          return {
            ...dataItem,
            subheadValue: value,
          }
        } else {
          return dataItem
        }
      })

      // @ts-ignore
      setChecklist({ ...checklist, data })
    },
    [checklist, setChecklist],
  )

  const moveRow = useCallback(
    (dragRow: any, dropRow: any) => {
      if (!checklist) {
        return
      }

      const dropRowIndex = checklist.data.findIndex(d => d.id === dropRow.id)

      const data = checklist.data.filter(d => d.id !== dragRow.id)
      data.splice(dropRowIndex, 0, dragRow)

      setChecklist({ ...checklist, data })
    },
    [checklist, setChecklist],
  )

  const moveColumn = useCallback(
    (dragColumn: any, dropColumn: any) => {
      if (!checklist) {
        return
      }

      const dropColumnIndex = checklist.columns.findIndex(c => c.id === dropColumn.id)

      const columns = checklist.columns.filter(c => c.id !== dragColumn.id)
      columns.splice(dropColumnIndex, 0, dragColumn)

      setChecklist({ ...checklist, columns })
    },
    [checklist, setChecklist],
  )

  const state = useMemo(
    () => ({
      checklist,
      addChecklistColumn,
      updateChecklistColumn,
      removeChecklistColumn,
      addChecklistRow,
      removeChecklistRow,
      onChecklistCellDataChange,
      onChecklistSubheadRowChange,
      moveRow,
      moveColumn,
      isConfigurable,
      isDisabled,
    }),
    [
      checklist,
      addChecklistColumn,
      updateChecklistColumn,
      addChecklistRow,
      removeChecklistColumn,
      removeChecklistRow,
      onChecklistCellDataChange,
      onChecklistSubheadRowChange,
      moveRow,
      moveColumn,
      isConfigurable,
      isDisabled,
    ],
  )

  return <ChecklistContext.Provider value={state}>{props.children}</ChecklistContext.Provider>
}

export default ChecklistContextProvider
