import React from 'react'
import s from './switch-middle-text.module.scss'

export const SwitchMiddleText = ({ onText, offText }) => {
  return (
    <div className={s['container']}>
      <label className={s['switch']}>
        <input type="checkbox" />
        <div className={s['slider']}>
          <span className={s['on']}>{onText}</span>
          <span className={s['off']}>{offText}</span>
        </div>
      </label>
    </div>
  )
}

export default SwitchMiddleText
