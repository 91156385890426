import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'

import { ISafetyConcernFilterFormFieldsProps, ISafetyConcernFilterValues } from './safety-concern-filter-form-types'
import useSafetyConcernFilterQueries from './use-safety-concern-filter-queries'

const nameof = nameofFactory<ISafetyConcernFilterValues>()

const startedDateRangeLabel = 'Started Date Range'
const injuryOccurredLabel = 'Injury Occurred'
const damageOccurredLabel = 'Damage Occurred'
const recurringConcernLabel = 'Recurring Concern'
const statusLabel = 'Status'
const priorityLabel = 'Priority'
const originatedFromSafetyReportLabel = 'Originated From Safety Report'

const SafetyConcernFilterFormFields: React.FC<ISafetyConcernFilterFormFieldsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo } = props

  const {
    statusOptions,
    statusesQuery,
  
    priorityOptions,
    prioritiesQuery,
  
    booleanSearchOptions
  } = useSafetyConcernFilterQueries()

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('startedDateRange')}
            label={startedDateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(startedDateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('statusIds')}
            label={statusLabel}
            isMulti
            placeholderText={getPlaceholderTextFromLabel(statusLabel)}
            disabled={disabled || statusesQuery?.isFetching}
            options={statusOptions}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('priorityIds')}
            label={priorityLabel}
            isMulti
            placeholderText={getPlaceholderTextFromLabel(priorityLabel)}
            disabled={disabled || prioritiesQuery?.isFetching}
            options={priorityOptions}
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('injuryOccurred')}
            label={injuryOccurredLabel}
            options={booleanSearchOptions}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('damageOccurred')}
            label={damageOccurredLabel}
            options={booleanSearchOptions}
            disabled={disabled}
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('recurringConcern')}
            label={recurringConcernLabel}
            options={booleanSearchOptions}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('originatedFromSafetyReport')}
            label={originatedFromSafetyReportLabel}
            options={booleanSearchOptions}
            disabled={disabled}
          />
        </Col>

        <Col></Col>
      </Row>
    </>
  )
}

export default SafetyConcernFilterFormFields
