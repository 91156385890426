import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import config from '../../config'

import { IUseDeleteMutation, IDeleteFn, IGetDeleteRequest, IChecklistReminder } from '../../interfaces'

const getDeleteChecklistReminderMutationRequest: IGetDeleteRequest = params => ({
  method: 'delete',
  url: `${config.baseUrl}/${params.airportId}/Reminders/`,
})

const useDeleteChecklistReminderMutation: IUseDeleteMutation<IChecklistReminder, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteChecklistReminderMutationRequest(params), [params])

  const deleteChecklistReminder: IDeleteFn<IChecklistReminder> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklistReminder, Error, any>({ mutationFn: deleteChecklistReminder, ...options })

  return response
}

export default useDeleteChecklistReminderMutation
