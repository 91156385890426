import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IAuthRole, IGetRequestObject } from '../../interfaces'
type Params = {
  airportId: number
}

type QueryObject = {
  includeDetail?: boolean
}

const getAuthRolesRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/AuthRoles${queryString}`,
  }
}

const useAuthRolesQuery = buildGetQuery<Params, QueryObject | undefined, IAuthRole[] | undefined>({
  getRequest: getAuthRolesRequest,
  getQueryKey: (_, queryObject) => ['auth-roles', queryObject?.includeDetail],
})

export default useAuthRolesQuery
