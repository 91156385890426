import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getChecklistByIdQueryRequest from './get-checklist-by-id-query-request'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IChecklist } from '../../interfaces'

const useChecklistByIdQuery: IUseGetQuery<any, IChecklist> = (params, queryObject = {}, options = {}) => {
  const request = useMemo(() => getChecklistByIdQueryRequest(params, queryObject), [params, queryObject])

  const fetchChecklistById: IGetFn<IChecklist> = useCallback(() => axios(request).then(res => res.data), [request])

  const queryKey = useMemo(
    () => ['checklist-by-id', params.airportId, params.id, queryObject],
    [params.airportId, params.id, queryObject],
  )

  const response = useQuery<IChecklist | undefined, Error>(queryKey, fetchChecklistById, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useChecklistByIdQuery
