import { useState, useMemo, useCallback, createRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { PageLayout, SafetyConcernPopup, Button, IListItemProps } from '../../../components'
import ContentMapLayout from '../../../hoc/content-map-layout'
import Map from '../../../components/map/map'
import MarkerLayout from '../../../components/map/marker-layout'
import PolygonLayout from '../../../components/map/polygon-layout'
import SafetyConcernsCardsList from './safety-concerns-cards-list'
import SafetyConcernFilter from './safety-concerns-filter'

import { useSafetyConcernsQuery } from '../../../services'
import { getGeoJson } from '../../../util'
import { queryParamsToParams, filterSafetyConcern } from './safety-concerns-page-utils'
import { useSafeQueryParams } from '../../../hooks'
import { claims, useAccessControl } from '../../../security'

import { IGeoJson, ISafetyConcern, IRefs, EntityState } from '../../../interfaces'

import s from './safety-concerns-page.module.scss'

const pageTitle = 'Open Safety Concerns'

const SafetyConcernsPage = () => {
  const location = useLocation()

  const { hasAccess } = useAccessControl()

  const hasSafetyConcernWriteAccess = useMemo(() => hasAccess([claims.safetyConcern.write]), [hasAccess])

  const { params, setParams } = useSafeQueryParams(queryParamsToParams)

  const navigate = useNavigate()

  const searchFilter = useMemo(() => params.searchFilter, [params.searchFilter])

  const setSearchFilter = useCallback(
    (values: { [key: string]: any }) => {
      setParams({ searchFilter: { ...searchFilter, ...values } })
    },
    [setParams, searchFilter],
  )

  const pagination = useMemo(() => params.pagination, [params.pagination])

  const setPagination = useCallback(
    (values: { [key: string]: number }) => {
      setParams({ pagination: { ...pagination, ...values } })
    },
    [setParams, pagination],
  )

  const [activeGeoJsonId, setActiveGeoJsonId] = useState<number | undefined>(undefined)
  const [infoGeoJsonId, setInfoGeoJsonId] = useState<number | undefined>(undefined)

  const {
    data: safetyConcerns,
    isInitialLoading: isSafetyConcernsInitialLoading,
    isFetching: isSafetyConcernsLoading,
  } = useSafetyConcernsQuery({ entityState: EntityState.Open })

  const filteredSafetyConcerns = useMemo(
    () => filterSafetyConcern(safetyConcerns, searchFilter),
    [safetyConcerns, searchFilter],
  )

  const refs = useMemo(
    () =>
      (safetyConcerns || []).reduce<IRefs>((acc, value) => {
        if (value.id) {
          acc[value.id] = createRef()
        }

        return acc
      }, {}),
    [safetyConcerns],
  )

  const { markersGeoJson: safetyConcernsMarkersGeoJson, polygonsGeoJson: safetyConcernsPolygonsGeoJson } = getGeoJson(
    filteredSafetyConcerns,
    (safetyConcern: ISafetyConcern) =>
      safetyConcern.geoJson
        ? {
            ...safetyConcern.geoJson,
            properties: { ...(safetyConcern?.geoJson?.properties || {}), id: safetyConcern.id },
          }
        : undefined,
  )

  const _setActiveGeoJsonId = useCallback(
    (activeGeoJsonId: number | undefined) => {
      setActiveGeoJsonId(activeGeoJsonId)

      if (!activeGeoJsonId) {
        return
      }

      const activeSafetyConcernPageIndex = Math.floor(
        filteredSafetyConcerns.findIndex(safetyConcern => safetyConcern.id === activeGeoJsonId) / pagination.pageSize,
      )

      if (activeSafetyConcernPageIndex === pagination.pageIndex) {
        if (activeGeoJsonId && refs[activeGeoJsonId]) {
          refs[activeGeoJsonId].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      } else {
        setPagination({
          pageIndex: activeSafetyConcernPageIndex,
        })

        setTimeout(() => {
          if (activeGeoJsonId && refs[activeGeoJsonId]) {
            refs[activeGeoJsonId].current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }, 0)
      }
    },
    [refs, pagination, setPagination, filteredSafetyConcerns],
  )

  const handleNavigateToSafetyConcern = (safetyConcern?: ISafetyConcern) =>
    safetyConcern?.id && navigate(`/safety-concern/${safetyConcern?.id}`, { state: { backSearch: location.search } })

  const handleCreateSafetyConcern = useCallback(() => navigate(`/safety-concern/create-safety-concern`), [navigate])

  const headerControls = useMemo(
    () => (hasSafetyConcernWriteAccess ? <Button onClick={handleCreateSafetyConcern}>Create</Button> : undefined),
    [hasSafetyConcernWriteAccess, handleCreateSafetyConcern],
  )

  const renderSafetyConcernCardActions = useCallback(
    (listItemProps: ISafetyConcern & IListItemProps<ISafetyConcern>) => {
      const { id, geoJson } = listItemProps

      const onLocationClick = () => {
        if (!geoJson) {
          return
        }

        setInfoGeoJsonId(geoJson.properties.id || id)
        setActiveGeoJsonId(geoJson.properties.id || id)
      }

      return (
        <Button type="ghost-icon" className="ml-2" onClick={onLocationClick} disabled={!geoJson}>
          <i className="bi bi-geo-alt-fill text-primary" />
        </Button>
      )
    },
    [],
  )

  return (
    <PageLayout title={pageTitle} headerControls={headerControls}>
      <ContentMapLayout contentWrapperClassName={s['content-wrapper-class-name']}>
        {{
          content: (
            <div className="d-flex flex-column w-100">
              <SafetyConcernFilter searchFilter={searchFilter} setSearchFilter={setSearchFilter} />

              <SafetyConcernsCardsList
                pagination={pagination}
                setPagination={setPagination}
                safetyConcerns={filteredSafetyConcerns}
                isSafetyConcernsInitialLoading={isSafetyConcernsInitialLoading}
                renderActions={renderSafetyConcernCardActions}
                safetyConcernsRefs={refs}
                activeSafetyConcernId={activeGeoJsonId}
                isSafetyConcernsLoading={isSafetyConcernsLoading}
                handleNavigateToSafetyConcern={handleNavigateToSafetyConcern}
              />
            </div>
          ),
          map: (
            <Map>
              <MarkerLayout
                activeId={activeGeoJsonId}
                setActiveId={_setActiveGeoJsonId}
                infoId={infoGeoJsonId}
                setInfoId={setInfoGeoJsonId}
                geoJson={safetyConcernsMarkersGeoJson}
                renderPopUp={(geoJson: IGeoJson) => {
                  const safetyConcern = safetyConcerns?.find(
                    safetyConcern => safetyConcern.id === geoJson.properties.id,
                  )

                  return (
                    <SafetyConcernPopup
                      safetyConcern={safetyConcern}
                      onNavigateToSafetyConcern={handleNavigateToSafetyConcern}
                    />
                  )
                }}
              />

              <PolygonLayout
                activeId={activeGeoJsonId}
                setActiveId={_setActiveGeoJsonId}
                infoId={infoGeoJsonId}
                setInfoId={setInfoGeoJsonId}
                geoJson={safetyConcernsPolygonsGeoJson}
                renderPopUp={(geoJson: IGeoJson) => {
                  const safetyConcern = safetyConcerns?.find(
                    safetyConcern => safetyConcern.id === geoJson.properties.id,
                  )

                  return (
                    <SafetyConcernPopup
                      safetyConcern={safetyConcern}
                      onNavigateToSafetyConcern={handleNavigateToSafetyConcern}
                    />
                  )
                }}
              />
            </Map>
          ),
        }}
      </ContentMapLayout>
    </PageLayout>
  )
}

export default SafetyConcernsPage
