import { FC } from 'react'

import { IMeasureButtonProps } from './measure-button-types'

const MeasureButton: FC<IMeasureButtonProps> = props => {
  const { isMeasuring, onStartMeasure, onStopMeasure } = props

  return isMeasuring ? (
    <button className="btn bg-white google-map-button mb-3 mx-3 p-0 h-40px w-40px rounded-sm" onClick={onStopMeasure}>
      <i className="bi bi-trash p-0" />
    </button>
  ) : (
    <button className="btn bg-white google-map-button mb-3 mx-3 p-0 h-40px w-40px rounded-sm" onClick={onStartMeasure}>
      <i className="bi bi-rulers p-0" />
    </button>
  )
}

export default MeasureButton
