import React from 'react'

import { Form } from 'react-final-form'

import { ColumnDefinitionForm, Modal } from '../..'

import { IColumnFormValues, IColumnFormUpsertValues } from '../../form'

type IChecklistColumnModalProps = {
  values: IColumnFormUpsertValues
  onSubmit: (values: IColumnFormValues) => void
  closeModal: () => void
  isOpen: boolean
}

const ChecklistColumnModal: React.FC<IChecklistColumnModalProps> = props => {
  const { onSubmit, values = {}, closeModal, isOpen } = props

  return (
    <Modal
      isOpen={isOpen}
      title="Column"
      confirmBtnText="Save"
      cancelBtnText="Cancel"
      onClose={closeModal}
      formName="add-column-form"
    >
      {isOpen && (
        <Form
          onSubmit={onSubmit}
          initialValues={values}
          // @ts-ignore
          component={ColumnDefinitionForm}
          formName="add-column-form"
          destroyOnUnregister
        />
      )}
    </Modal>
  )
}

export default ChecklistColumnModal
