import axios from 'axios'
import { format } from 'date-fns'

import config from '../../config'

const generateName = () => {
  const isoDate = new Date()
  const date = format(isoDate, 'yyyyMMdd')
  const time = format(isoDate, 'hh:mm:ss')
  return `${date}_${time}`
}

export const renameFile = file => {
  const nameParts = file.name.split('.')
  const fileType = nameParts.pop()
  let fileName = nameParts
    .join('.')
    .replace(/\W/g, '-')
    .replace(/_/g, '-')
    .replace(/-+/g, '-')
    .toLowerCase()
    .slice(0, 40)

  if (!fileName) {
    fileName = generateName()
  }

  return new File([file], `${fileName}.${fileType}`)
}

const uploadFile = async (airportId, file, parentType) => {
  const url = `${config.baseUrl}/${airportId}/Documents/Upload?documentParentType=${parentType}`
  const formData = new FormData()
  formData.append('file', renameFile(file))

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default uploadFile
