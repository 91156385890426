import config from '../../config'

import { IGetSortWorkOrderStatusesMutationRequest } from './get-sort-work-order-statuses-mutation-request-types'

const getSortWorkOrderStatusesMutationRequest: IGetSortWorkOrderStatusesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/WorkOrderStatuses/Sort`,
  }
}

export default getSortWorkOrderStatusesMutationRequest
