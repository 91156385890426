import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getAddChecklistTemplateMutationRequest from './get-add-checklist-template-mutation-request'

import { IUseAddMutation, IAddFn } from '../../interfaces'
import { IChecklistTemplate, IChecklistTemplateUpsert } from '../../interfaces'

const useAddChecklistTemplateMutation: IUseAddMutation<IChecklistTemplate, IChecklistTemplateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getAddChecklistTemplateMutationRequest(params), [params])

  const addChecklistTemplate: IAddFn<IChecklistTemplateUpsert, IChecklistTemplate> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IChecklistTemplate, Error, IChecklistTemplateUpsert>({
    mutationFn: addChecklistTemplate,
    ...options,
  })

  return response
}

export default useAddChecklistTemplateMutation
