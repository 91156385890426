import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { ISafetyConcernAction, IUseDeleteMutation, IGetDeleteRequest, IDeleteFn } from '../../interfaces'
import config from '../../config'

const getDeleteSafetyConcernActionMutationRequest: IGetDeleteRequest = () => ({
  method: 'delete',
  url: `${config.baseUrl}/SafetyConcernActions/`,
})

const useDeleteSafetyConcernActionMutation: IUseDeleteMutation<ISafetyConcernAction, number> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getDeleteSafetyConcernActionMutationRequest(params), [params])

  const deleteSafetyConcernAction: IDeleteFn<ISafetyConcernAction> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyConcernAction, Error, any>({ mutationFn: deleteSafetyConcernAction, ...options })

  return response
}

export default useDeleteSafetyConcernActionMutation
