import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IFacilityLocation, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  includeDetail?: boolean
  activeOnly?: boolean
}

const getFacilityLocationsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Locations/GetAllByFacility${queryString}`,
  }
}

const useFacilityLocationsQuery = buildGetQuery<Params, QueryObject | undefined, IFacilityLocation[] | undefined>({
  getRequest: getFacilityLocationsRequest,
  getQueryKey: (params, queryObject) => [
    'locations',
    params.airportId,
    queryObject?.includeDetail || '',
    queryObject?.activeOnly || '',
  ],
})

export default useFacilityLocationsQuery
