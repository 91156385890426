import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import React, { Component } from 'react'

import { ai } from './telemetry-service'

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
  state = {
    initialized: false,
  }

  componentDidMount() {
    const history = createBrowserHistory()

    const { initialized } = this.state
    let config = this.props.config

    config = {
      ...config,
      extensions: [ai.reactPlugin],
      extensionConfig: {
        [ai.reactPlugin.identifier]: { history: createBrowserHistory({ basename: ''}) }
      }
    }

    if (!Boolean(initialized) && Boolean(config?.connectionString) && Boolean(history)) {
      ai.initialize(config, history)
      this.setState({ initialized: true })
    }

    this.props.after && this.props.after()
  }

  render() {
    return <>{this.props.children}</>
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider, null, 'h-100')
