const geolocationMarkerIcon = options => {
  const strokeColor = options?.strokeColor || '#FFFFFF'
  const strokeWidth = options?.strokeWidth || '2px'
  const fillColor = options?.fillColor || '#5383EC'
  const widthAndHeight = '18'

  return `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="${widthAndHeight}" cy="${widthAndHeight}" r="10.5" stroke="#FFFFFF" stroke-width="${strokeWidth}" fill="transparent" opacity="1">
      <animate attributeName="r" from="10" to="20" dur="1.5s" begin="0s" repeatCount="indefinite" fill="freeze" />
      <animate attributeType="CSS" attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" fill="freeze" />
    </circle>
    <circle cx="${widthAndHeight}" cy="${widthAndHeight}" r="9" stroke="${strokeColor}" stroke-width="${strokeWidth}" fill="${fillColor}" opacity="0.9" />
  </svg>`
}

export default geolocationMarkerIcon
