import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortWorkOrderTypesMutationRequest from './get-sort-work-order-types-mutation-request'

import { IUseSortWorkOrderTypesMutation, ISortWorkOrderTypes } from './use-sort-work-order-types-mutation-types'

const useSortWorkOrderTypesMutation: IUseSortWorkOrderTypesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortWorkOrderTypesMutationRequest(params), [params])

  const sortWorkOrderTypes: ISortWorkOrderTypes = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortWorkOrderTypes,
    ...options,
  })

  return response
}

export default useSortWorkOrderTypesMutation
