import { FC } from 'react'

import { IHomeButtonProps } from './home-button-types'

const HomeButton: FC<IHomeButtonProps> = ({ onClick }) => (
  <button className="btn bg-white google-map-button mb-3 mx-3 p-0 h-40px w-40px rounded-sm" onClick={onClick}>
    <i className="bi bi-house-door-fill p-0" />
  </button>
)

export default HomeButton
