import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateTrainingSessionRecordProgressMutationRequest from './get-update-training-session-record-progress-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { ITrainingSession, IUserTrainingChecklistProgressUpsert } from '../../interfaces'

const useUpdateTrainingSessionRecordProgressMutation: IUseUpdateMutation<
  ITrainingSession,
  IUserTrainingChecklistProgressUpsert[]
> = (params, options = {}) => {
  const request = useMemo(() => getUpdateTrainingSessionRecordProgressMutationRequest(params), [params])

  const updateTrainingSession: IUpdateFn<IUserTrainingChecklistProgressUpsert[], ITrainingSession> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITrainingSession, Error, any>({
    mutationFn: updateTrainingSession,
    ...options,
  })

  return response
}

export default useUpdateTrainingSessionRecordProgressMutation
