import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import { validateRequired } from '../../../../util/validation'

import { useInspectionTypesQuery, useInspectionSubtypesQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { IShortITBFormProps } from './short-itb-form-types'

const ShortITBForm: FC<IShortITBFormProps> = ({
  handleSubmit,
  submitting,
  formName,
  inspectionTypesQuery,
  inspectionSubtypesQuery,
}) => {
  const { airportId } = useProfile()

  const _inspectionTypesQuery = useInspectionTypesQuery({ airportId }, undefined, {
    enabled: !Boolean(inspectionTypesQuery),
  })

  const computedInspectionTypesQuery = useMemo(
    () => inspectionTypesQuery || _inspectionTypesQuery,
    [inspectionTypesQuery, _inspectionTypesQuery],
  )

  const { data: inspectionTypes, isLoading: isInspectionTypesLoading } = computedInspectionTypesQuery

  const inspectionTypeOptions = useMemo(
    () => (inspectionTypes || []).map(inspectionType => ({ label: inspectionType.name, value: inspectionType.id })),
    [inspectionTypes],
  )

  const _inspectionSubtypesQuery = useInspectionSubtypesQuery({ airportId }, undefined, {
    enabled: !Boolean(inspectionTypesQuery),
  })

  const computedInspectionSubtypesQuery = useMemo(
    () => inspectionSubtypesQuery || _inspectionSubtypesQuery,
    [inspectionSubtypesQuery, _inspectionSubtypesQuery],
  )

  const { data: inspectionSubtypes, isLoading: isInspectionSubtypesLoading } = computedInspectionSubtypesQuery

  const inspectionSubypeOptions = useMemo(
    () =>
      (inspectionSubtypes || []).map(inspectionSubtype => ({
        label: inspectionSubtype.name,
        value: inspectionSubtype.id,
      })),
    [inspectionSubtypes],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Select
        name="inspectionTypeId"
        options={inspectionTypeOptions}
        label="Type"
        validate={validateRequired}
        disabled={submitting || isInspectionTypesLoading}
      />

      <RFFFields.Select
        name="inspectionSubtypeId"
        options={inspectionSubypeOptions}
        label="Subtype"
        disabled={submitting || isInspectionSubtypesLoading}
      />
    </form>
  )
}

export default ShortITBForm
