import axios from 'axios'
import config from '../../config'

const createQuickLog = async ({ airportId, woId, request }) => {
  try {
    const url = `${config.baseUrl}/${airportId}/WorkOrders/${woId}/QuickLog`
    const response = await axios.put(url, request)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default createQuickLog
