import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUploadLocationMutationRequest from './get-upload-location-mutation-request'

import { ILocation, ILocationUpsert } from '../../interfaces'
import { IUseUploadLocationMutation, IUploadLocation } from './types'

const useUploadLocationMutation: IUseUploadLocationMutation = (params, options = {}) => {
  const request = useMemo(() => getUploadLocationMutationRequest(params), [params])

  const uploadLocation: IUploadLocation = useCallback(
    async data => {
      const formData = new FormData()

      formData.append('file', data.file)

      return axios({ ...request, data: formData }).then(res => res.data)
    },
    [request],
  )

  const response = useMutation<ILocation, Error, ILocationUpsert>({ mutationFn: uploadLocation, ...options })

  return response
}

export default useUploadLocationMutation
