import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortInspectionTypesMutationRequest from './get-sort-inspection-types-mutation-request'

import { IUseSortInspectionTypesMutation, ISortInspectionTypes } from './use-sort-inspection-types-mutation-types'

const useSortInspectionTypesMutation: IUseSortInspectionTypesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortInspectionTypesMutationRequest(params), [params])

  const sortInspectionTypes: ISortInspectionTypes = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortInspectionTypes,
    ...options,
  })

  return response
}

export default useSortInspectionTypesMutation
