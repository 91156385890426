import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { enrichInspectionImmutable } from './enrich-inspection'

import { buildGetQuery } from '../../util'

import { IInspection, IGetRequestObject } from '../../interfaces'
import { useMemo } from 'react'

type Params = {
  airportId: number
  days: number
}

const getClosedInspectionsRequest = (params: Params): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/${params.airportId}/Inspections/ClosedWithinDays/${params.days}`,
})

const useClosedInspectionsQueryBase = buildGetQuery<Params, undefined, IInspection[] | undefined>({
  getRequest: getClosedInspectionsRequest,
  getQueryKey: params => ['closed-inspections', params.airportId, params.days],
})

const useClosedInspectionsQuery = (
  params: Params,
  options?: QueryObserverOptions<IInspection[] | undefined, Error>,
) => {
  const { data, ...restFields } = useClosedInspectionsQueryBase(params, undefined, options)

  const formattedData = useMemo(() => (data || []).map(enrichInspectionImmutable), [data])

  return { data: formattedData, ...restFields }
}

export default useClosedInspectionsQuery
