import {
  ISafetyReportCustomizationValues,
  ISafetyReportCustomizationUpsertValues,
} from './safety-report-customization-form-types'
import {
  ISafetyReportCustomization,
  ISafetyReportCustomizationAddUpsert,
  ISafetyReportCustomizationUpdateUpsert,
} from '../../../../interfaces'

export const safetyReportCustomizationToSafetyReportCustomizationValues = (
  safetyReportCustomization: ISafetyReportCustomization | undefined,
): ISafetyReportCustomizationUpsertValues | undefined => {
  if (!safetyReportCustomization) {
    return undefined
  }

  return {
    logoUrl: safetyReportCustomization?.logoUrl,
    instructionText: safetyReportCustomization?.instructionText,
    thankYouText: safetyReportCustomization?.thankYouText,
  }
}

export const safetyReportCustomizationValuesToSafetyReportCustomizationAddUpsert = (
  safetyReportCustomizationValues: ISafetyReportCustomizationValues,
  payload: { airportId: number },
): ISafetyReportCustomizationAddUpsert | undefined => {
  if (!safetyReportCustomizationValues) {
    return undefined
  }

  return {
    airportId: payload.airportId,
    logoUrl: safetyReportCustomizationValues?.logoUrl,
    instructionText: safetyReportCustomizationValues?.instructionText,
    thankYouText: safetyReportCustomizationValues?.thankYouText,
  }
}

export const safetyReportCustomizationValuesToSafetyReportCustomizationUpdateUpsert = (
  safetyReportCustomizationValues: ISafetyReportCustomizationValues,
  safetyReportCustomization?: ISafetyReportCustomization,
): ISafetyReportCustomizationUpdateUpsert | undefined => {
  if (!safetyReportCustomizationValues || !safetyReportCustomization) {
    return undefined
  }

  return {
    airportId: safetyReportCustomization?.airportId,
    logoUrl: safetyReportCustomizationValues?.logoUrl,
    instructionText: safetyReportCustomizationValues?.instructionText,
    thankYouText: safetyReportCustomizationValues?.thankYouText,
  }
}
