import { buildGetQuery } from '../../util'
import config from '../../config'

import { IInspectionTemplateMasterItem, IGetRequestObject } from '../../interfaces'

const getInspectionTemplateMasterRequest = (): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/InspectionTemplateMaster`,
})

const useInspectionTemplateMasterQuery = buildGetQuery<
  undefined,
  undefined,
  IInspectionTemplateMasterItem[] | undefined
>({
  getRequest: getInspectionTemplateMasterRequest,
  getQueryKey: () => ['inspection-template-master'],
})

export default useInspectionTemplateMasterQuery
