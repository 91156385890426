import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

type Params = {
  airportId: number
}

type QueryObject = {
  isPreview?: boolean
}

const getInspectionsReportRequest = (params: Params, queryObject: QueryObject | undefined): any => {
  const { airportId } = params

  const { isPreview, ...data } = queryObject || {}

  const queryString = qs.stringify({ preview: isPreview }, { addQueryPrefix: true })

  return {
    method: 'post',
    responseType: isPreview ? 'text/html' : 'blob',
    url: `${config.baseUrl}/${airportId}/Inspections/RenderReport${queryString}`,
    data: data,
  }
}

const useInspectionsReportQuery = buildGetQuery<Params, QueryObject | undefined, Blob | string | undefined>({
  getRequest: getInspectionsReportRequest,
  getQueryKey: (params, queryObject) => ['inspections-report', params.airportId, queryObject?.isPreview],
  defaultOptions: {
    retry: false,
  },
})

export default useInspectionsReportQuery
