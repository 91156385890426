import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import { useFacilitiesQuery, useWildlifeSeedDataQuery } from '../../../../services'
import { toOptions } from '../../../../util'

import { IWildlifeFilterUpsertValues } from './wildlife-filter-form-types'
import { ICondition, ILocationListItem } from '../../../../interfaces'

const useWildlifeFilterQueries = (values?: IWildlifeFilterUpsertValues) => {
  const { airportId } = useProfile()

  const facilitiesQuery = useFacilitiesQuery(
    { airportId },
    { activeOnly: true, includeDetail: true },
    { refetchOnWindowFocus: false },
  )

  const facilitiesOptions = useMemo(() => toOptions(facilitiesQuery?.data), [facilitiesQuery?.data])

  const conditionsOptions = useMemo(() => {
    if (!Array.isArray(values?.facilityIds) || !Array.isArray(facilitiesQuery?.data)) {
      return []
    }

    const conditions = facilitiesQuery?.data
      .filter(facility => (values?.facilityIds || []).indexOf(facility.id) > -1)
      .reduce<ICondition[]>((conditions, facility) => [...conditions, ...facility.conditions], [])

    return conditions.map(condition => ({ label: condition.name, value: condition.id }))
  }, [facilitiesQuery, values?.facilityIds])

  const locationsOptions = useMemo(() => {
    if (!Array.isArray(values?.facilityIds) || !Array.isArray(facilitiesQuery?.data)) {
      return []
    }

    const locations = facilitiesQuery?.data
      .filter(facility => (values?.facilityIds || []).indexOf(facility.id) > -1)
      .reduce<ILocationListItem[]>((locations, facility) => [...locations, ...facility.locations], [])

    return locations.map(location => ({ label: location.name, value: location.id }))
  }, [facilitiesQuery?.data, values?.facilityIds])

  const wildlifeSeedDataQuery = useWildlifeSeedDataQuery({ airportId }, undefined)

  const wildlifeSpeciesOptions = useMemo(
    () => toOptions(wildlifeSeedDataQuery?.data?.species),
    [wildlifeSeedDataQuery?.data?.species],
  )

  const wildlifeActivityOptions = useMemo(
    () => toOptions(wildlifeSeedDataQuery?.data?.activities),
    [wildlifeSeedDataQuery?.data?.activities],
  )

  const wildlifeMitigationOptions = useMemo(
    () => toOptions(wildlifeSeedDataQuery?.data?.mitigations),
    [wildlifeSeedDataQuery?.data?.mitigations],
  )

  return {
    facilitiesQuery,
    facilitiesOptions,

    conditionsOptions,
    locationsOptions,

    wildlifeSeedDataQuery,
    wildlifeSpeciesOptions,
    wildlifeActivityOptions,
    wildlifeMitigationOptions,
  }
}

export default useWildlifeFilterQueries
