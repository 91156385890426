import { useState, useMemo, useCallback } from 'react'
import { onlyUnique } from '../../../util'

const toSafeValue = value => (Array.isArray(value) ? value.map(value => value) : [])

const toSafeOptions = options =>
  Array.isArray(options) ? options.map(option => ({ label: option?.label, value: option?.value })) : []

const getExcludeFilterByValues = values => option => values.indexOf(option.value) === -1
const getIncludeFilterByValues = values => option => values.indexOf(option.value) > -1

const useDualList = props => {
  const { value, onChange, options, disabled } = props

  const safeValue = useMemo(() => toSafeValue(value), [value])
  const safeOptions = useMemo(() => toSafeOptions(options), [options])

  const unselectedOptions = useMemo(
    () => safeOptions.filter(getExcludeFilterByValues(safeValue)),
    [safeOptions, safeValue],
  )

  const selectedOptions = useMemo(
    () => safeOptions.filter(getIncludeFilterByValues(safeValue)),
    [safeOptions, safeValue],
  )

  const [itemsToAdd, setItemsToAdd] = useState([])
  const [itemsToRemove, setItemsToRemove] = useState([])

  const handleSelectedItemsToAddChange = useCallback(values => {
    setItemsToAdd(values)
    setItemsToRemove([])
  }, [])

  const handleAddAllClick = useCallback(() => {
    onChange(safeOptions.map(option => option.value))
    setItemsToAdd([])
  }, [safeOptions, onChange])

  const handleAddClick = useCallback(() => {
    onChange([...itemsToAdd, ...safeValue])
    setItemsToAdd([])
  }, [safeValue, itemsToAdd, onChange])

  const handleSelectedItemsToRemoveChange = useCallback(values => {
    setItemsToRemove(values)
    setItemsToAdd([])
  }, [])

  const handleRemoveClick = useCallback(() => {
    const filteredValues = safeValue.filter(value => itemsToRemove.indexOf(value) === -1)

    onChange(filteredValues)
    setItemsToRemove([])
  }, [safeValue, itemsToRemove, onChange])

  const handleRemoveAllClick = useCallback(() => {
    onChange([])
    setItemsToRemove([])
  }, [onChange])

  const isAddAllBtnDisabled = unselectedOptions.length === 0 || disabled
  const isAddBtnDisabled = isAddAllBtnDisabled || itemsToAdd.length === 0
  const isRemoveAllBtnDisabled = selectedOptions.length === 0 || disabled
  const isRemoveBtnDisabled = isRemoveAllBtnDisabled || itemsToRemove.length === 0

  const handleDropUnselectedItems = useCallback(
    items => {
      const values = items.map(item => item.value)
      onChange([...values, ...safeValue].filter(onlyUnique))
      setItemsToAdd([])
    },
    [safeValue, onChange],
  )

  const handleDropSelectedItems = useCallback(
    items => {
      const values = items.map(item => item.value)

      onChange(safeValue.filter(safeValue => values.indexOf(safeValue) === -1).filter(onlyUnique))
      setItemsToRemove([])
    },
    [safeValue, onChange],
  )

  return {
    unselectedOptions,
    selectedOptions,
    itemsToAdd,
    itemsToRemove,
    handleSelectedItemsToAddChange,
    handleAddAllClick,
    isAddAllBtnDisabled,
    handleAddClick,
    isAddBtnDisabled,
    handleSelectedItemsToRemoveChange,
    handleRemoveClick,
    isRemoveAllBtnDisabled,
    handleRemoveAllClick,
    isRemoveBtnDisabled,
    handleDropUnselectedItems,
    handleDropSelectedItems,
  }
}

export default useDualList
