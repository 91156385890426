import { newVersionAvailableText } from './components/alert/alert.constants'

const reducer = (state, action) => {
  const newState = { ...state }

  const { payload } = action

  switch (action.type) {
    case 'AIRPORT_LOADED': {
      newState.airport = payload.airport
      break
    }
    case 'APP_DATA_LOADED': {
      newState.airport = payload.airport
      newState.appDataLoaded = true
      newState.personalizationMapStatuses = payload.personalizationMapStatuses
      break
    }
    case 'HIDE_ALERT':
      newState.alert = { message: '', show: false, variant: '' }
      break
    case 'SHOW_ERROR_ALERT':
      newState.alert = {
        message: payload.message,
        show: true,
        variant: 'danger',
      }
      break
    case 'SHOW_PRIMARY_ALERT':
      newState.alert = {
        message: payload.message,
        show: true,
        variant: 'primary',
      }
      break
    case 'SHOW_SUCCESS_ALERT':
      newState.alert = {
        message: payload.message,
        show: true,
        variant: 'success',
      }
      break
    case 'SHOW_WARNING_ALERT':
      newState.alert = {
        message: payload.message,
        show: true,
        variant: 'warning',
      }
      break

    case 'SHOW_NEW_VERSION_ALERT':
      newState.alert = {
        message: newVersionAvailableText,
        show: true,
        variant: 'primary',
      }
      break

    case 'SET_MAINTENANCE_BANNER':
      return {
        ...state,
        maintenance: {
          ...state.maintenance,

          isLoaded: true,

          messageOfTheDay: payload.messageOfTheDay,
          settings: payload.settings,
        },
      }
    case 'SHOW_MAINTENANCE_BANNER':
      return {
        ...state,
        maintenance: {
          ...state.maintenance,

          settings: {
            ...state.maintenance.settings,
            show: true,
          },
        },
      }
    case 'HIDE_MAINTENANCE_BANNER':
      return {
        ...state,
        maintenance: {
          ...state.maintenance,

          settings: {
            ...state.maintenance.settings,

            hasBeenClosed: true,
            time: new Date(),
            show: false,
          },
        },
      }
    case 'OPEN_PROFILE_DRAWER':
      return {
        ...state,
        isProfileDrawerOpen: true,
      }
    case 'CLOSE_PROFILE_DRAWER':
      return {
        ...state,
        isProfileDrawerOpen: false,
      }

    case 'OPEN_NAVIGATION_MENU_DRAWER':
      return {
        ...state,
        isNavigationMenuDrawerOpen: true,
      }
    case 'CLOSE_NAVIGATION_MENU_DRAWER':
      return {
        ...state,
        isNavigationMenuDrawerOpen: false,
      }

    case 'OPEN_NOTIFICATIONS_DRAWER':
      return {
        ...state,
        isNotificationsDrawerOpen: true,
      }
    case 'CLOSE_NOTIFICATIONS_DRAWER':
      return {
        ...state,
        isNotificationsDrawerOpen: false,
      }

    case 'OPEN_FAQ_DRAWER':
      return {
        ...state,
        isFaqDrawerOpen: true,
      }
    case 'CLOSE_FAQ_DRAWER':
      return {
        ...state,
        isFaqDrawerOpen: false,
      }

    case 'OPEN_RELEASE_NOTES_REMINDER':
      return {
        ...state,
        releaseNotesInfo: {
          ...state.releaseNotesInfo,
          isReminderOpen: true,
        },
      }
    case 'CLOSE_RELEASE_NOTES_REMINDER':
      return {
        ...state,
        releaseNotesInfo: {
          ...state.releaseNotesInfo,
          isReminderOpen: false,
        },
      }
    case 'SET_RELEASE_NOTES_CONFIG':
      return {
        ...state,
        releaseNotesInfo: {
          ...state.releaseNotesInfo,
          releaseNotesConfig: payload.releaseNotesConfig,
        },
      }
    case 'SET_CURRENT_RELEASE_VERSION':
      return {
        ...state,
        releaseNotesInfo: {
          ...state.releaseNotesInfo,
          currentReleaseVersion: payload.currentReleaseVersion,
        },
      }
    case 'SET_LAST_VIEWED_RELEASE_VERSION':
      return {
        ...state,
        releaseNotesInfo: {
          ...state.releaseNotesInfo,
          lastViewedReleaseVersion: payload.lastViewedReleaseVersion,
        },
      }

    default:
      console.warn('Unknown action type', action.type)
      break
  }

  return newState
}

export default reducer
