import { IMarkerToGeoJson } from './marker-to-geo-json-types'

export const markerToGeoJson: IMarkerToGeoJson = (marker, color) => {
  const markerPosition = marker.getPosition()

  if (!markerPosition) {
    return null
  }

  const geoJson = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [markerPosition.lng(), markerPosition.lat()],
    },
    properties: {
      id: Math.random(),
      color: color,
    },
  }

  return geoJson
}
