import { format } from 'date-fns'

const downloadBlob = ({ contentType = 'text/plain', data, fileName = 'download.txt', includeTimestamp = false }) => {
  let downloadFileName
  if (includeTimestamp) {
    const timestamp = format(new Date(), 'yyyy-MM-dd-hhmmss')
    downloadFileName = `${timestamp}-${fileName}`
  } else {
    downloadFileName = fileName
  }

  const blob = new Blob([data], { type: contentType })
  const href = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href

  link.download = downloadFileName

  link.click()
  link.remove()
}

export default downloadBlob
