import { buildGetQuery } from '../../util'
import config from '../../config'

import { IPagedResults, IWildlifeSearchResultItem } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  entityState?: string
  startedAtBegin?: string
  startedAtEnd?: string
  endedAtBegin?: string
  endedAtEnd?: string
  wildlifeSightingIds?: number[]
  facilityIds?: number[]
  conditionIds?: number[]
  locationIds?: number[]
  wildlifeSpeciesIds?: number[]
  wildlifeMitigationIds?: number[]
  wildlifeActivityIds?: number[]
  searchText?: string
  limit?: number
  page?: number
  sortBy?: string
  sortOrder?: string
}

const getWildlifeSearchRequest = (params: Params, data?: QueryObject): any => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/WildlifeSightings/Search`,
    data,
  }
}

const useWildlifeSearchQuery = buildGetQuery<Params, QueryObject | undefined, IPagedResults<IWildlifeSearchResultItem[]> | undefined>(
  {
    getRequest: getWildlifeSearchRequest,
    getQueryKey: (params, queryObject) => [
      'wildlife-search',
      params.airportId,
      queryObject?.entityState,
      queryObject?.startedAtBegin,
      queryObject?.startedAtEnd,
      queryObject?.endedAtBegin,
      queryObject?.endedAtEnd,
      queryObject?.wildlifeSightingIds,
      queryObject?.facilityIds,
      queryObject?.conditionIds,
      queryObject?.locationIds,
      queryObject?.wildlifeSpeciesIds,
      queryObject?.wildlifeMitigationIds,
      queryObject?.wildlifeActivityIds,
      queryObject?.searchText,
      queryObject?.limit,
      queryObject?.page,
      queryObject?.sortBy,
      queryObject?.sortOrder,
    ],
    defaultOptions: { refetchOnWindowFocus: false },
  },
)

export default useWildlifeSearchQuery
