import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  id: number
}

const getTagAssociatedItemsRequest = (params: Params): IGetRequestObject => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Tags/${id}/AssociationCount`,
  }
}

const useTagAssociatedItemsQuery = buildGetQuery<Params, undefined, number | undefined>({
  getRequest: getTagAssociatedItemsRequest,
  getQueryKey: params => ['tag-associated-items', params.airportId, params.id],
  defaultOptions: { initialData: 0 },
})

export default useTagAssociatedItemsQuery
