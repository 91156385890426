import { FC } from 'react'
import { Modal, Button } from 'react-bootstrap'

import DocumentsCarousel from './documents-carousel'

import { download } from '../../../util/file'
import { IDocument } from '../../../interfaces'

type DocumentsPreviewModalProps = {
  documents?: IDocument[]
  currentCarouselDocument?: IDocument
  setCurrentCarouselDocument?: (document: IDocument) => void
  parentType?: string
  isDocumentsPreviewModalOpen?: boolean
  toggleDocumentsPreviewModal?: () => void
}

const DocumentsPreviewModal: FC<DocumentsPreviewModalProps> = ({
  documents,
  currentCarouselDocument,
  setCurrentCarouselDocument,
  parentType,
  isDocumentsPreviewModalOpen,
  toggleDocumentsPreviewModal,
}) => (
  <Modal size="lg" show={isDocumentsPreviewModalOpen} onHide={toggleDocumentsPreviewModal} animation={false} centered>
    <Modal.Header closeButton>
      <Modal.Title>{currentCarouselDocument?.name}</Modal.Title>
    </Modal.Header>

    <Modal.Body className={parentType === 'Journal' ? 'journal-viewer' : ''}>
      {currentCarouselDocument && (
        <DocumentsCarousel
          documents={documents ?? []}
          currentDocument={currentCarouselDocument}
          setCurrentDocument={setCurrentCarouselDocument !== undefined ? setCurrentCarouselDocument : () => {}}
        />
      )}
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={toggleDocumentsPreviewModal}>
        Cancel
      </Button>

      {currentCarouselDocument && (
        <Button variant="primary" onClick={() => download(currentCarouselDocument.url, currentCarouselDocument.name)}>
          Download File
        </Button>
      )}
    </Modal.Footer>
  </Modal>
)

export default DocumentsPreviewModal
