import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortSafetyItemUniqueValuesMutationRequest from './get-sort-safety-item-unique-values-mutation-request'

import {
  IUseSortSafetyItemUniqueValuesMutation,
  ISortSafetyItemUniqueValues,
} from './use-sort-safety-item-unique-values-mutation-types'

const useSortSafetyItemUniqueValuesMutation: IUseSortSafetyItemUniqueValuesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortSafetyItemUniqueValuesMutationRequest(params), [params])

  const sortSafetyItemUniqueValues: ISortSafetyItemUniqueValues = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortSafetyItemUniqueValues,
    ...options,
  })

  return response
}

export default useSortSafetyItemUniqueValuesMutation
