import React from 'react'
import { useDragLayer } from 'react-dnd'

import ListItem from './list-item'

import s from './drag-preview.module.scss'

const getListStyles = currentOffset => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }

  return {
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
  }
}

const DragPreview = () => {
  const { item, currentOffset, isDragging } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging) {
    return null
  }

  return (
    <div className={s['container']}>
      <ul className={s['list']} style={getListStyles(currentOffset)}>
        {(item || []).map(option => (
          <ListItem label={option.label} key={option.value} isSelected />
        ))}
      </ul>
    </div>
  )
}

export default DragPreview
