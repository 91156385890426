import config from '../../config'

import { IGetSortInspectionTypesMutationRequest } from './get-sort-inspection-types-mutation-request-types'

const getSortInspectionTypesMutationRequest: IGetSortInspectionTypesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/InspectionTypes/Sort`,
  }
}

export default getSortInspectionTypesMutationRequest
