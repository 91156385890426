import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateTagMutationRequest from './get-update-tag-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { ITag, ITagUpdateUpsert } from '../../interfaces'

const useUpdateChecklistMutation: IUseUpdateMutation<ITag, ITagUpdateUpsert> = (params, options = {}) => {
  const request = useMemo(() => getUpdateTagMutationRequest(params), [params])

  const updateTag: IUpdateFn<ITagUpdateUpsert, ITag> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITag, Error, ITagUpdateUpsert>({
    mutationFn: updateTag,
    ...options,
  })

  return response
}

export default useUpdateChecklistMutation
