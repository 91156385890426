import { format } from 'date-fns'
import { string, func, bool } from 'prop-types'

import s from './date-picker-header.module.scss'

const DatePickerHeader = ({ monthDate, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
  <div className={s['calendar-header']}>
    <button
      className={s['calendar-header-change-month-btn']}
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
      type="button"
    >
      <i className="bi bi-chevron-left" />
    </button>

    <div className={s['calendar-header-date']}>{format(monthDate, 'MMMM yyyy')}</div>

    <button
      className={s['calendar-header-change-month-btn']}
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
      type="button"
    >
      <i className="bi bi-chevron-right" />
    </button>
  </div>
)

DatePickerHeader.propTypes = {
  date: string,
  decreaseMonth: func,
  increaseMonth: func,
  prevMonthButtonDisabled: bool,
  nextMonthButtonDisabled: bool,
}

export default DatePickerHeader
