// request.js
import axios from 'axios'
import config from '../config'
import { getAccessToken } from '../security'

// optionaly add base url
const client = axios.create({ baseUrl: config.baseUrl })

const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`

  const onSuccess = response => response.data
  const onError = error => {
    // optionaly catch errors and add some additional logging here
    return error
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request
