import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react'
import get from 'lodash.get'

// import { Input } from '../../form/input'
import { Textarea } from '../../form/textarea'
import { DatePicker } from '../../form/date-picker'
import { Checkbox } from '../../form/checkbox'
import { Select } from '../../form/select'

import { ChecklistContext } from '../checklist-context/checklist-context'

import { Cell, ColumnInstance } from 'react-table'

import { ChecklistRow } from '../checklist-types'

type ChecklistCellProps = {
  column: ColumnInstance<ChecklistRow>
  cell: Cell<ChecklistRow, any>
}

const ChecklistCell: React.FC<ChecklistCellProps> = props => {
  const { column, cell } = props

  const { onChecklistCellDataChange, isConfigurable, isDisabled } = useContext(ChecklistContext)

  const rowId = useMemo(() => cell.row.original.id, [cell.row.original.id])
  const columnId = useMemo(() => cell.column.id, [cell.column.id])

  const value = get(cell.row.original, `${columnId}.value`)

  const onChange = useCallback(
    (value: string | number) => onChecklistCellDataChange({ rowId, columnId, value }),
    [onChecklistCellDataChange, rowId, columnId],
  )

  let content = null

  const adProps = useMemo(() => {
    if (column.isReadOnly && !isConfigurable) {
      return { isViewMode: true }
    }
  }, [column.isReadOnly, isConfigurable])

  switch (column.columnType) {
    case 'string': {
      content = (
        // @ts-ignore
        <Textarea
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
          value={value}
          disabled={isDisabled}
          minRows={1}
          {...adProps}
        />
      )
      break
    }
    case 'date': {
      content = (
        <DatePicker
          onChange={onChange}
          value={value}
          isDateOnly
          disabled={isDisabled}
          withPortal
          shouldCloseOnSelect
          placeholderText="Select date"
          {...adProps}
        />
      )
      break
    }
    case 'dropdown': {
      content = (
        <Select
          onChange={onChange}
          value={value}
          // @ts-ignore
          options={column?.attributes || []}
          isDateOnly
          placeholder="Select value"
          disabled={isDisabled}
          {...adProps}
        />
      )
      break
    }
    case 'checkbox': {
      content = (
        <div className="d-flex align-items-center justify-content-center">
          <Checkbox
            onChange={(e: any) => onChange(e.target.checked)}
            // @ts-ignore
            checked={value}
            disabled={isDisabled}
            placeholder="Free text..."
            {...adProps}
          />
        </div>
      )
      break
    }
    default: {
      content = <div>Unknown column type</div>
      break
    }
  }

  return <div>{content}</div>
}

export default ChecklistCell
