import { Alert } from 'react-bootstrap'

import { Button } from '../../components'

import { useAppContext } from '../../app-context'
import { useProfile } from '../../security'
import useMessageOfTheDay from './use-message-of-the-day'

import { actions as appActions } from '../../actions'

//@ts-ignore
import s from './maintenance-banner.module.scss'

import { useCallback } from 'react'

const MaintenanceBanner = () => {
  const { state, dispatch: appDispatch } = useAppContext()
  const { airportId } = useProfile()

  const { maintenance } = state

  const hideMaintenanceBanner = useCallback(() => {
    appDispatch(appActions.maintenance.hideMaintenanceBanner())
  }, [appDispatch])

  useMessageOfTheDay(airportId)

  if (!maintenance.settings?.show) {
    return null
  }
  const textStyle = {
    color: maintenance.messageOfTheDay?.fontColor || '#31708f',
  }

  const content = maintenance.messageOfTheDay?.url ? (
    <a target="_blank" rel="noreferrer" href={maintenance.messageOfTheDay?.url} style={textStyle}>
      {maintenance.messageOfTheDay?.message}
    </a>
  ) : (
    <span style={textStyle}>{maintenance.messageOfTheDay?.message}</span>
  )
  return (
    <Alert
      variant="light"
      className={s['alert-bar']}
      style={{
        backgroundColor: maintenance.messageOfTheDay?.backgroundColor || '#d9edf7',
      }}
      dismissible={false}
    >
      <div className={s['alert-content']}>{content}</div>

      <Button className={s['alert-close-btn']} type="ghost-icon" buttonType="button" onClick={hideMaintenanceBanner}>
        <i className="bi bi-x" />
      </Button>
    </Alert>
  )
}

export default MaintenanceBanner
