import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'
import { SearchReportType } from '../../components/report-modal/search-report-type'

type Params = {
  airportId: number
}

type QueryObject = {
  isPreview?: boolean
  startedAtBegin?: string
  startedAtEnd?: string
  facilityIds?: number[]
  conditionIds?: number[]
  locationIds?: number[]
  wildlifeSpeciesIds?: number[]
  wildlifeMitigationIds?: number[]
  wildlifeActivityIds?: number[]
  wildlifeSightingIds?: number[]
  limit: number
  page: number
  sortBy?: string
  sortOrder?: string
  searchReportType?: SearchReportType
}

const getWorkOrdersReportRequest = (params: Params, queryObject: QueryObject | undefined): any => {
  const { airportId } = params

  const { isPreview, searchReportType, ...data } = queryObject || {}

  const queryString = qs.stringify({ preview: isPreview, searchReportType }, { addQueryPrefix: true })

  return {
    method: 'post',
    responseType: isPreview ? 'text/html' : 'blob',
    url: `${config.baseUrl}/${airportId}/WildlifeSightings/RenderReport${queryString}`,
    data: data,
  }
}

const useWildlifeReportQuery = buildGetQuery<Params, QueryObject | undefined, Blob | string | undefined>({
  getRequest: getWorkOrdersReportRequest,
  getQueryKey: (params, queryObject) => [
    'wildlife-report',
    params.airportId,
    queryObject?.isPreview,
    queryObject?.startedAtBegin,
    queryObject?.startedAtEnd,
    queryObject?.facilityIds,
    queryObject?.conditionIds,
    queryObject?.locationIds,
    queryObject?.wildlifeSightingIds,
    queryObject?.wildlifeMitigationIds,
    queryObject?.wildlifeSpeciesIds,
    queryObject?.wildlifeActivityIds,
    queryObject?.limit,
    queryObject?.page,
    queryObject?.sortBy,
    queryObject?.sortOrder,
    queryObject?.searchReportType,
  ],
  defaultOptions: {
    retry: false,
  },
})

export default useWildlifeReportQuery
