import { validateRequired } from '../../../../util/validation'
import { validateReminderValue } from '../reminder-form/reminder-form.utils'

import { IChecklistReminderValues } from './checklist-reminder-form-types'

export const validateChecklistReminderValue = (сhecklistReminderValues: IChecklistReminderValues) => {
  let errorObject: any = {
    name: undefined,
    checklistId: undefined,
    reminder: undefined,
  }

  errorObject.checklistId = validateRequired(сhecklistReminderValues?.checklistId)

  errorObject.reminder = validateReminderValue(сhecklistReminderValues)?.reminder

  errorObject.reminder.name = validateRequired(сhecklistReminderValues?.reminder?.name)

  return errorObject
}
