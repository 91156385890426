import { buildGetQuery } from '../../util'

import config from '../../config'
import { IAppContextAirport } from '../../app-context'

export const useAirportsQuery = buildGetQuery<undefined, undefined, IAppContextAirport[] | undefined>({
  getRequest: () => ({ method: 'get', url: `${config.baseUrl}/Airports` }),
  getQueryKey: () => ['airports'],
})

export default useAirportsQuery
