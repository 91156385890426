import { FC, useMemo, useCallback, useEffect, ReactNode } from 'react'

import { List, Pagination, EmptyList, SafetyConcernCard, IListItemProps } from '../../../components'
import { ISafetyConcern, IRefs } from '../../../interfaces'

type SafetyConcernsCardsListProps = {
  pagination: { pageSize: number; pageIndex: number }
  setPagination: (values: { [key: string]: number }) => void
  safetyConcerns: ISafetyConcern[] | undefined
  isSafetyConcernsInitialLoading: boolean
  renderActions: (listItemProps: ISafetyConcern & IListItemProps<ISafetyConcern>) => ReactNode
  safetyConcernsRefs: IRefs
  activeSafetyConcernId?: number
  isSafetyConcernsLoading: boolean
  handleNavigateToSafetyConcern: (safetyConcern: ISafetyConcern) => void
}

const SafetyConcernsCardsList: FC<SafetyConcernsCardsListProps> = props => {
  const {
    safetyConcerns,
    isSafetyConcernsInitialLoading,
    pagination,
    setPagination,
    renderActions,
    safetyConcernsRefs,
    activeSafetyConcernId,
    isSafetyConcernsLoading,
    handleNavigateToSafetyConcern,
  } = props

  const totalItemsCount = safetyConcerns?.length || 0

  const { pageIndex, pageSize } = pagination

  const setPageSize = useCallback((pageSize: number) => setPagination({ pageSize }), [setPagination])

  const setPageIndex = useCallback((pageIndex: number) => setPagination({ pageIndex }), [setPagination])

  const totalPageCount = useMemo(() => Math.ceil(totalItemsCount / pageSize), [totalItemsCount, pageSize])

  const safetyConcernsPage = useMemo(
    () => (safetyConcerns || []).slice(pageIndex * pageSize, pageIndex * pageSize + pageSize),
    [safetyConcerns, pageIndex, pageSize],
  )

  useEffect(() => {
    if (pageIndex > totalPageCount) {
      setPageIndex(totalPageCount)
    }
  }, [pageIndex, setPageIndex, totalPageCount])

  const canNextPage = pageIndex < totalPageCount - 1

  const canPreviousPage = pageIndex > 0

  return (
    <>
      <div className="overflow-auto h-100">
        <List
          data={safetyConcernsPage}
          ListItem={SafetyConcernCard}
          isInitialLoading={isSafetyConcernsInitialLoading}
          noDataContent={<EmptyList>No Safety Concerns found.</EmptyList>}
          renderActions={renderActions}
          activeId={activeSafetyConcernId}
          refs={safetyConcernsRefs}
          onTitleClick={handleNavigateToSafetyConcern}
          isLoading={isSafetyConcernsLoading}
        />
      </div>

      <Pagination
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItemsCount={totalItemsCount}
        totalPageCount={totalPageCount}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </>
  )
}

export default SafetyConcernsCardsList
