import config from '../../config'
import useAxios from '../use-axios'
import { byAccessor } from '../../util'

const byOccurrenceDateAscending = (a, b) => byAccessor(a, b, x => x.occurrenceDate, true)

const useReminders = ({ aging, airportId, includeDismissed = false }) => {
  const queryStringParams = [`daysInRange=${aging}`, `includeDismissed=${includeDismissed}`]

  const url = `${config.baseUrl}/${airportId}/Reminders/Occurrences?${queryStringParams.join('&')}`

  const { data = [], error, loading, refetch } = useAxios({ method: 'get', url })

  // convert date string to javascript date so we can use it for sorting
  data.forEach(d => (d.occurrenceDate = new Date(d.occurrenceDate)))

  data.sort(byOccurrenceDateAscending)

  return { data: data, error, loading, execute: refetch }
}

export default useReminders
