import { FC, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import HorizontalRule from '../../../horizontal-rule'

import { useNotificationGroupsQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { ITrainingTemplateFormProps } from './training-template-form-types'

import ReminderForm from '../reminder-form/reminder-form'

const TrainingTemplateForm: FC<ITrainingTemplateFormProps> = props => {
  const { handleSubmit, submitting, values, initialValues, formName } = props

  const { airportId } = useProfile()

  const { data: departments } = useNotificationGroupsQuery({ airportId }, undefined)

  const departmentsOptions = useMemo(
    () =>
      (departments || []).map(department => ({
        value: department.id,
        label: department.name,
      })),
    [departments],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input label="Training Name" name="name" placeholder="Enter training name" disabled={submitting} />

      <RFFFields.Input
        name="referenceLinkUrl"
        label="Reference Link (optional)"
        placeholder="Paste reference link here"
        disabled={submitting}
        addonBefore={() => <i className="bi bi-link-45deg" style={{ color: '#00304E' }} />}
      />

      <RFFFields.Input
        name="referenceLinkText"
        placeholder="Type text to display (e.g. § 123.456)"
        disabled={!values.referenceLinkUrl || submitting}
        addonBefore={() => <i className="bi bi-list" style={{ color: '#00304E' }} />}
      />

      <RFFFields.Select
        label="Departments"
        name="notificationGroupIds"
        placeholder="Select one or more"
        options={departmentsOptions}
        disabled={submitting}
        isMulti
      />

      <ReminderForm
        values={values}
        submitting={submitting}
        initialValues={initialValues}
        frequencyLabel="Schedule"
        isForceDisabled={initialValues?.reminder?.reminderFrequencyId !== undefined}
        disablePastDates={true}
      />

      <RFFFields.MaskedInput type="number" name="sortOrder" label="Sort Order" placeholder="0" disabled={submitting} />

      <HorizontalRule />

      <RFFFields.Attributes
        name="checklistItems"
        innerLabel="Checklist"
        namePlaceholder="Name"
        valuePlaceholder="Description"
      />
    </form>
  )
}

export default TrainingTemplateForm
