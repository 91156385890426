import { FC, useState, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'

import { validateRequired, validateMaxSymbols } from '../../../../util/validation'
import { nameofFactory, getPlaceholderTextFromLabel, options } from '../../../../util'

import { ISafetyConcernActionFormFiledsProps, ISafetyConcernActionValues } from './safety-concern-action-form-types'

const validateMaxSymbols500 = validateMaxSymbols(500, 'Description must not exceed 500 characters')

const nameof = nameofFactory<ISafetyConcernActionValues>()

const actionLabel = 'Action Taken'
const notesLabel = 'Notes'

const SafetyConcernActionFormFields: FC<ISafetyConcernActionFormFiledsProps> = props => {
  const { disabled, form, initialValues } = props

  const initialSafetyConcernActions = useMemo(
    () =>
      initialValues?.action
        ? [{ label: initialValues?.action, value: initialValues?.action }, ...options.safetyConcernActions]
        : options.safetyConcernActions,
    [initialValues?.action],
  )

  const [actionOptions, setActionOptions] = useState(initialSafetyConcernActions)

  return (
    <>
      <RFFFields.Select
        name={nameof('action')}
        label={actionLabel}
        placeholder="Select from default actions or begin typing to provide your own response"
        disabled={disabled}
        options={actionOptions}
        validate={validateRequired}
        onCreateOption={(optionLabel: string) => {
          setActionOptions(actionOptions => [{ label: optionLabel, value: optionLabel }, ...actionOptions])
          form?.mutators?.onActivityChange?.(optionLabel)
        }}
        canCreate={true}
        isClearable={true}
      />

      <RFFFields.Textarea
        name={nameof('notes')}
        label={notesLabel}
        placeholder={getPlaceholderTextFromLabel(notesLabel)}
        disabled={disabled}
        validate={validateMaxSymbols500}
      />

      <RFFFields.DocumentUpload name={nameof('documents')} parentType="SafetyConcernAction" disabled={disabled} />
    </>
  )
}

export default SafetyConcernActionFormFields
