import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateItbsTemplatesMutationRequest from './get-update-itbs-templates-mutation-request'

import { IUseUpdateMutation, IUpdateFn, ITBListItem } from '../../interfaces'

const useUpdateItbsTemplatesMutation: IUseUpdateMutation<ITBListItem[], ITBListItem[]> = (params, options = {}) => {
  const request = useMemo(() => getUpdateItbsTemplatesMutationRequest(params), [params])

  const updateItbsTemplates: IUpdateFn<ITBListItem[], ITBListItem[]> = useCallback(
    async (data: ITBListItem[] | undefined) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITBListItem[], Error, ITBListItem[]>({
    mutationFn: updateItbsTemplates,
    ...options,
  })

  return response
}

export default useUpdateItbsTemplatesMutation
