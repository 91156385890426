import { FC } from 'react'

import SafetyConcernStatusFormFields from './safety-concern-status-form-fields'

import { ISafetyConcernStatusFormProps } from './safety-concern-status-form-types'

const SafetyConcernStatusForm: FC<ISafetyConcernStatusFormProps> = props => {
  const { handleSubmit, formName, submitting, initialValues } = props

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <SafetyConcernStatusFormFields disabled={submitting} isIsActiveDisabled={initialValues.baseStatus !== 2} />
    </form>
  )
}

export default SafetyConcernStatusForm
