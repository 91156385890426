import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPostRequest,
  IUseAddMutation,
  IAddFn,
  ISafetyConcernAction,
  ISafetyConcernActionAddUpsert,
} from '../../interfaces'

const getCreateSafetyConcernActionMutationRequest: IGetPostRequest = () => ({
  method: 'post',
  url: `${config.baseUrl}/SafetyConcernActions`,
})

const useCreateSafetyConcernActionMutation: IUseAddMutation<ISafetyConcernAction, ISafetyConcernActionAddUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getCreateSafetyConcernActionMutationRequest(params), [params])

  const createSafetyConcernAction: IAddFn<ISafetyConcernActionAddUpsert, ISafetyConcernAction> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyConcernAction, Error, ISafetyConcernActionAddUpsert>({
    mutationFn: createSafetyConcernAction,
    ...options,
  })

  return response
}

export default useCreateSafetyConcernActionMutation
