import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateTrainingTemplateMutationRequest from './get-update-training-template-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { ITrainingTemplate, ITrainingTemplateUpdateUpsert } from '../../interfaces'

const useUpdateTrainingTemplateMutation: IUseUpdateMutation<ITrainingTemplate, ITrainingTemplateUpdateUpsert> = (
  params,
  options = {},
) => {
  const request = useMemo(() => getUpdateTrainingTemplateMutationRequest(params), [params])

  const updateTraining: IUpdateFn<ITrainingTemplateUpdateUpsert, ITrainingTemplate> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITrainingTemplate, Error, any>({
    mutationFn: updateTraining,
    ...options,
  })

  return response
}

export default useUpdateTrainingTemplateMutation
