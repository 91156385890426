import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { SearchReportType } from '../../components/report-modal/search-report-type'

type Params = {
  airportId: number
}

type QueryObject = {
  isPreview?: boolean
  searchReportType?: SearchReportType
}

const getActivityLogsReportRequest = (params: Params, queryObject: QueryObject | undefined): any => {
  const { airportId } = params

  const { isPreview, searchReportType, ...data } = queryObject || {}

  const queryString = qs.stringify({ preview: isPreview, searchReportType }, { addQueryPrefix: true })

  return {
    method: 'post',
    responseType: isPreview ? 'text/html' : 'blob',
    url: `${config.baseUrl}/${airportId}/ActivityLogs/RenderReport${queryString}`,
    data: data,
  }
}

const useActivityLogsReportQuery = buildGetQuery<Params, QueryObject | undefined, Blob | string | undefined>({
  getRequest: getActivityLogsReportRequest,
  getQueryKey: (params, queryObject) => ['activity-logs-report', params.airportId, queryObject?.isPreview || ''],
})

export default useActivityLogsReportQuery
