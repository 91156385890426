import { FC, useContext, Dispatch, SetStateAction, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { TableInstance, Row } from 'react-table'
import cn from 'classnames'

import { Button } from '../../button'

import { ChecklistSubheadCell } from '../checklist-subhead-cell'
import { ChecklistContext } from '../checklist-context/checklist-context'

import { DND_ROW_ITEM_TYPE } from '../checklist-constants'

import { ChecklistRow as ChecklistRowType, ChecklistClassNames } from '../checklist-types'

import s from '../checklist.module.scss'
// @ts-ignore
import rowStyles from './checklist-row.module.scss'

export type ChecklistRowProps = {
  tableInstance: TableInstance<ChecklistRowType>
  row: Row<ChecklistRowType>
  index: number
  isActiveRow: boolean
  setActiveRowId: Dispatch<SetStateAction<any>>
  classNames?: ChecklistClassNames
}

const ChecklistRow: FC<ChecklistRowProps> = props => {
  const { checklist, moveRow, isConfigurable, addChecklistRow, removeChecklistRow } = useContext(ChecklistContext)

  const { tableInstance, row, index, setActiveRowId, classNames } = props

  const { prepareRow } = tableInstance

  prepareRow(row)

  const [, dragRef] = useDrag<ChecklistRowType>(
    () => ({
      type: DND_ROW_ITEM_TYPE,
      item: row.original,
      canDrag: isConfigurable,
    }),
    [row.original, isConfigurable],
  )

  const [, dropRef] = useDrop<ChecklistRowType>({
    accept: DND_ROW_ITEM_TYPE,
    hover(item) {
      if (item.id === row.original.id) {
        return
      }

      moveRow(item, row.original)
    },
  })

  const onAddRow = useCallback(() => {
    addChecklistRow(index + 1)
    setActiveRowId(index + 1)
  }, [addChecklistRow, index, setActiveRowId])

  const onRemoveRow = useCallback(() => {
    removeChecklistRow(index)
  }, [removeChecklistRow, index])

  const { isSubhead } = row?.original || {}

  return (
    <tr
      className={classNames?.trClassName}
      ref={ref => {
        dropRef(ref)
        dragRef(ref)
      }}
    >
      {isConfigurable && (
        <td className={cn(s['drag-icon-cell'], isSubhead && s['subhead-cell'], classNames?.tdClassName)}>
          <i style={{ fontSize: '20px' }} className="bi bi-grip-vertical pr-2"></i>
        </td>
      )}

      {isSubhead && (
        <td colSpan={checklist?.columns.length || 0} className={cn(isSubhead && s['subhead-cell'])}>
          <ChecklistSubheadCell row={row} />
        </td>
      )}

      {!isSubhead &&
        row.cells.map(cell => {
          const className = cn(classNames?.tdClassName)

          return (
            <td key={cell.getCellProps().key} className={className}>
              {cell.render('Cell')}
            </td>
          )
        })}

      {isConfigurable && (
        <td className={cn(s['actions-cell'], isSubhead && s['subhead-cell'], classNames?.tdClassName)}>
          <div className="d-flex align-items-center mx-4">
            <Button className={cn(rowStyles['add-delete-button'], 'mr-4')} type="ghost-icon" onClick={onAddRow}>
              <i className="bi bi-plus" />
            </Button>
            <Button className={rowStyles['add-delete-button']} type="ghost-icon" onClick={onRemoveRow}>
              <i className="bi bi-x" />
            </Button>
          </div>
        </td>
      )}
    </tr>
  )
}

export default ChecklistRow
