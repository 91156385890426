import React from 'react'
import { string } from 'prop-types'

const HorizontalRule = ({ className = '' }) => <hr className={`${className || 'mt-4'}`} />

HorizontalRule.propTypes = {
  className: string,
}

export default HorizontalRule
