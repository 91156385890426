import React from 'react'

import config from '../config'
import s from './footer.module.scss'

const Footer = () => {
  return (
    <div className={`d-flex ${s['container']}`}>
      <div className="d-flex justify-content-center flex-nowrap ml-auto">
        <span>Powered by&nbsp;</span>
        <a
          alt="GateKeeper Systems Inc. home page"
          className={`flex-shrink-0 ${s['link']}`}
          href="https://gksys.com"
          rel="noreferrer"
          target="_blank"
        >
          <span>GateKeeper Systems</span>
        </a>
        <span className={`${s['spacer']}`}>|</span>
        <span>Copyright 2023</span>
        {config.debugMode && (
          <a alt="debug" className="ml-8" href="/debug">
            🕵️
          </a>
        )}
      </div>
    </div>
  )
}

export default Footer
