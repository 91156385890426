import React from 'react'
import { string, bool, arrayOf, func, shape, oneOfType, number } from 'prop-types'

import { SearchInput } from '../../search-input'
import DraggableListItem from './draggable-list-item'

import useList from './use-list'

import s from './list.module.scss'

const List = props => {
  const { canFilter, disabled, options, title, value, onChange, handleDropItems, extraOptions, getSelectedOptionName } =
    props

  const { filter, setFilter, computedOptions, handleOptionChange, checkIsSelected, dropRef } = useList({
    canFilter,
    options,
    value,
    onChange,
    handleDropItems,
  })

  return (
    <div className={s['container']}>
      {canFilter && (
        <SearchInput value={filter} onChange={setFilter} inputClassName={s['filter']} disabled={disabled} />
      )}

      <div className={s['list-wrapper']} ref={dropRef}>
        <div className={s['list-title']}>{title}</div>

        <ul className={s['list']} role="listbox" aria-orientation="vertical">
          {computedOptions.map(option => (
            <div key={option.value} style={{ position: 'relative' }}>
              <DraggableListItem
                handleClick={() => handleOptionChange(option.value)}
                label={getSelectedOptionName(option)}
                item={value}
                options={options}
                value={option.value}
                disabled={disabled}
                isSelected={checkIsSelected(option.value)}
              />
              {typeof extraOptions === 'function' ? extraOptions(option.value) : <></>}
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}

List.defaultProps = {
  getSelectedOptionName: ({ label, value }) => label,
}

List.propTypes = {
  canFilter: bool,
  disabled: bool,
  options: arrayOf(
    shape({
      label: oneOfType([string, number]),
      value: oneOfType([string, number]),
    }),
  ).isRequired,
  title: oneOfType([string, number]),
  value: arrayOf(oneOfType([string, number])),
  onChange: func,
  handleDropItems: func,
  getSelectedOptionName: func,
}

export default List
