import { FC, useMemo } from 'react'
import { Marker as GoogleMarker } from '@react-google-maps/api'

import EditableMarker from './editable-marker'

import { IMarkerProps } from './marker-type'

if (!window.OffscreenCanvas) {
  // @ts-ignore
  window.OffscreenCanvas = class OffscreenCanvas {
    // @ts-ignore
    constructor(width, height) {
      // @ts-ignore
      this.canvas = document.createElement('canvas')
      // @ts-ignore
      this.canvas.width = width
      // @ts-ignore
      this.canvas.height = height

      // @ts-ignore
      this.canvas.convertToBlob = () => {
        return new Promise(resolve => {
          // @ts-ignore
          this.canvas.toBlob(resolve)
        })
      }

      // @ts-ignore
      return this.canvas
    }
  }
}

const Marker: FC<IMarkerProps> = ({
  geoJson,
  icon,
  onClick,
  editable,
  renderEditPopUp,
  onUpdate,
  visible = true,
  clusterer,
}) => {
  const position = useMemo(() => {
    if (Array.isArray(geoJson.geometry.coordinates[0]) || Array.isArray(geoJson.geometry.coordinates[1])) {
      return null
    }

    return {
      lng: geoJson.geometry.coordinates[0],
      lat: geoJson.geometry.coordinates[1],
    }
  }, [geoJson])

  if (!icon || !position) {
    return null
  }

  return editable ? (
    <EditableMarker
      geoJson={geoJson}
      icon={{ url: icon }}
      position={position}
      onUpdate={onUpdate}
      renderEditPopUp={renderEditPopUp}
      clusterer={clusterer}
    />
  ) : (
    <GoogleMarker
      icon={{ url: icon }}
      onClick={onClick ? () => onClick(geoJson) : undefined}
      position={position}
      clusterer={clusterer}
      visible={visible}
      clickable={onClick !== undefined}
    />
  )
}

export default Marker
