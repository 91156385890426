import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IInspectionTypeBuilder, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
  includeDetail?: boolean
  inspectionCategoryId?: number
  denoteMostUsed?: boolean
}

const getItbsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/InspectionTypeBuilders${queryString}`,
  }
}

const useItbsQuery = buildGetQuery<Params, QueryObject | undefined, IInspectionTypeBuilder[] | undefined>({
  getRequest: getItbsRequest,
  getQueryKey: (params, queryObject) => [
    'inspection-type-builders',
    params.airportId,
    queryObject?.activeOnly || '',
    queryObject?.includeDetail || '',
    queryObject?.inspectionCategoryId || '',
    queryObject?.denoteMostUsed || '',
  ],
})

export default useItbsQuery
