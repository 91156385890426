type NotificationChangeGraphProps = {
  notification: any
}

const NotificationChangeGraph: React.FC<NotificationChangeGraphProps> = ({ notification }) => {
  return (
    <>
      {notification.additionalInfo?.changes?.map((change: any, index: number) => (
        <div key={index} className="d-flex align-items-center">
          <span className="font-weight-bold text-nowrap">{change.propertyName}:&nbsp;&nbsp;</span>
          <span className="text-nowrap text-truncate">
            {change.originalValue ? change.originalValue : 'None'} &rarr; {change.newValue}
          </span>
        </div>
      ))}
    </>
  )
}

export default NotificationChangeGraph
