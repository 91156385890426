import axios from 'axios'

import config from '../config'

const createReportIssue = async data => {
  try {
    const url = `${config.baseUrl}/help/bug`
    const response = await axios.post(url, data)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default createReportIssue
