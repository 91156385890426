import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortNotificationSearchFilter } from '../../../../interfaces'
import { INotificationFilterValues, INotificationFilterUpsertValues } from './notification-filter-form-types'

export const objectToNotificationFilterValues = (object?: { [key: string]: any }): INotificationFilterUpsertValues => ({
  startedDateRange: { 0: object?.startedAtFrom || '', 1: object?.startedAtTo || '' },
  endedDateRange: { 0: object?.endedAtFrom || '', 1: object?.endedAtTo || '' },
  featureIds: object?.featureIds || [],
  notificationTypeIds: object?.notificationTypeIds || [],
  startedByIds: object?.startedByIds || [],
  endedByIds: object?.endedByIds || [],
  searchText: object?.searchText || '',
})

export const savedSearchFilterToSavedNotificationFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): INotificationFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let workOrderFilterObject
  try {
    workOrderFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    workOrderFilterObject = {}
  }

  const workOrderFilterValues = objectToNotificationFilterValues(workOrderFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    workOrderFilterValues.startedDateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return workOrderFilterValues
}

export const savedNotificationFilterValuesToSavedSearchFilterAddUpsert = (
  savedNotificationFilterValues: INotificationFilterValues | undefined,
): IShortNotificationSearchFilter => ({
  startedAtFrom: savedNotificationFilterValues?.startedDateRange?.[0] || '',
  startedAtTo: savedNotificationFilterValues?.startedDateRange?.[1] || '',
  endedAtFrom: savedNotificationFilterValues?.endedDateRange?.[0] || '',
  endedAtTo: savedNotificationFilterValues?.endedDateRange?.[1] || '',
  featureIds: savedNotificationFilterValues?.featureIds || [],
  notificationTypeIds: savedNotificationFilterValues?.notificationTypeIds || [],
  startedByIds: savedNotificationFilterValues?.startedByIds || [],
  endedByIds: savedNotificationFilterValues?.endedByIds || [],
  searchText: savedNotificationFilterValues?.searchText || '',
})

export const savedNotificationFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedNotificationFilterValues: INotificationFilterValues | undefined,
): IShortNotificationSearchFilter =>
  savedNotificationFilterValuesToSavedSearchFilterAddUpsert(savedNotificationFilterValues)
