import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISafetyItemListItem, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  ids?: number[]
}

const getDiscrepanciesByIdsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${params.airportId}/SafetyItems/GetByIds${queryString}`,
  }
}

const useDiscrepanciesByIdsQuery = buildGetQuery<Params, QueryObject | undefined, ISafetyItemListItem[] | undefined>({
  getRequest: getDiscrepanciesByIdsRequest,
  getQueryKey: (params, queryObject) => ['safety-items-by-ids', params?.airportId, queryObject?.ids],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useDiscrepanciesByIdsQuery
