import React from 'react'
import cn from 'classnames'
import { shape, number, func, string, bool, arrayOf } from 'prop-types'

import { Checkbox } from '../checkbox'
import { Button } from '../../button'

import useCheckboxTreeNode from './use-checkbox-tree-node'
import { getValueByNode } from './checkbox-tree.utils'

import s from './checkbox-tree-node.module.scss'

const DEFAULT_SHIFT = 23

const CheckboxTreeNode = props => {
  const { node, shift = 0, handleChange, value, expandedValues, toggleTreeNodeExpand, renderLabel } = props

  const _checked = Boolean(value)

  const { handleParentNodeChange, handleChildNodeChange } = useCheckboxTreeNode({
    handleChange,
    node,
    value,
  })

  if (node.children) {
    const isExpanded = expandedValues.indexOf(node.value) > -1

    const nodeValues = node.children.map(child => getValueByNode(value?.children || [], child))
    const isParentChecked = nodeValues.filter(Boolean).length === nodeValues.length

    return (
      <>
        <div
          className={s['checkbox-tree-node-container']}
          style={{
            marginLeft: shift,
          }}
        >
          <Button
            type="ghost-icon"
            className={s['checkbox-tree-node-dropdown-button']}
            onClick={() => toggleTreeNodeExpand(node.value)}
            buttonType="button"
          >
            <i
              className={cn(
                isExpanded ? 'bi bi bi-caret-down-fill' : 'bi bi-caret-right-fill',
                s['checkbox-tree-node-dropdown-icon'],
              )}
            />
          </Button>

          <Checkbox
            label={node.label}
            renderLabel={
              renderLabel
                ? () => renderLabel(node?.original || node?.label, { labelDataTestId: node?.labelDataTestId })
                : undefined
            }
            indeterminate={_checked}
            checked={isParentChecked}
            onChange={handleParentNodeChange}
            labelDataTestId={node.labelDataTestId}
          />
        </div>

        {isExpanded &&
          node.children.map((node, index) => (
            <CheckboxTreeNode
              node={node}
              shift={shift + DEFAULT_SHIFT}
              value={getValueByNode(value?.children || [], node)}
              handleChange={handleChildNodeChange}
              expandedValues={expandedValues}
              toggleTreeNodeExpand={toggleTreeNodeExpand}
              renderLabel={
                renderLabel
                  ? () => renderLabel(node?.original || node?.label, { labelDataTestId: node?.labelDataTestId })
                  : undefined
              }
              key={index}
            />
          ))}
      </>
    )
  }

  return (
    <div
      className="d-flex"
      style={{
        marginBottom: 8,
        marginLeft: shift,
      }}
    >
      <i className={cn('bi bi-dash-lg', s['checkbox-tree-node-grip-icon'])} />

      <Checkbox
        classNames={s['checkbox-tree-node']}
        label={node.label}
        checked={_checked}
        onChange={handleParentNodeChange}
        renderLabel={renderLabel}
      />
    </div>
  )
}
const NodePropType = {
  label: string.isRequired,
  isOpen: bool,
  checked: bool,
}

NodePropType.children = arrayOf(shape(NodePropType))

CheckboxTreeNode.propTypes = {
  node: shape(NodePropType),
  shift: number,
  handleChange: func,
  renderLabel: func,
  index: number,
}

export default CheckboxTreeNode
