const safetyConcernActions = [
  { label: 'Triage', value: 'Triage' },
  { label: 'Analysis', value: 'Analysis' },
  { label: 'Investigation', value: 'Investigation' },
  { label: 'Hazard Logging', value: 'Hazard Logging' },
  { label: 'Risk Assessment', value: 'Risk Assessment' },
  { label: 'Mitigation', value: 'Mitigation' },
  { label: 'Corrective Action', value: 'Corrective Action' },
]

export default safetyConcernActions
