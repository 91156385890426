import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useWorkorderFilterQueries from './use-wildlife-filter-queries'

import { IWildlifeFilterFormFiledsProps, IWildlifeFilterValues } from './wildlife-filter-form-types'

const nameof = nameofFactory<IWildlifeFilterValues>()

const dateRangeLabel = 'Wildlife Sighting Date Range'
const facilityLabel = 'Facility'
const conditionLabel = 'Condition'
const locationLabel = 'Location'
const wildlifeSpeciesLabel = 'Species'
const wildlifeActivityLabel = 'Activity'
const wildlifeMitigationLabel = 'Action'

const WildlifeFilterFormFields: React.FC<IWildlifeFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const {
    facilitiesQuery,
    facilitiesOptions,

    conditionsOptions,
    locationsOptions,

    wildlifeSeedDataQuery,
    wildlifeSpeciesOptions,
    wildlifeActivityOptions,
    wildlifeMitigationOptions,
  } = useWorkorderFilterQueries(values)

  return (
    <>
      <RFFFields.DateRangePicker
        name={nameof('dateRange')}
        label={dateRangeLabel}
        placeholderText={getPlaceholderTextFromLabel(dateRangeLabel)}
        disabled={disabled || isDateRangeDisabled}
        info={dateRangeInfo}
        isDateOnly
      />

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('facilityIds')}
            label={facilityLabel}
            placeholder={getPlaceholderTextFromLabel(facilityLabel)}
            options={facilitiesOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('conditionIds')}
            label={conditionLabel}
            placeholder={getPlaceholderTextFromLabel(conditionLabel)}
            options={conditionsOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <RFFFields.Select
        name={nameof('locationIds')}
        label={locationLabel}
        placeholder={getPlaceholderTextFromLabel(locationLabel)}
        options={locationsOptions}
        disabled={disabled || facilitiesQuery.isFetching}
        isMulti
      />

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('wildlifeSpeciesIds')}
            label={wildlifeSpeciesLabel}
            placeholder={getPlaceholderTextFromLabel(wildlifeSpeciesLabel)}
            options={wildlifeSpeciesOptions}
            disabled={disabled || wildlifeSeedDataQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('wildlifeActivityIds')}
            label={wildlifeSpeciesLabel}
            placeholder={getPlaceholderTextFromLabel(wildlifeActivityLabel)}
            options={wildlifeActivityOptions}
            disabled={disabled || wildlifeSeedDataQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <RFFFields.Select
        name={nameof('wildlifeMitigationIds')}
        label={wildlifeMitigationLabel}
        placeholder={getPlaceholderTextFromLabel(wildlifeMitigationLabel)}
        options={wildlifeMitigationOptions}
        disabled={disabled || wildlifeSeedDataQuery.isFetching}
        isMulti
      />
    </>
  )
}

export default WildlifeFilterFormFields
