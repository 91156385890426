import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortNotamSearchFilter } from '../../../../interfaces'
import { INotamFilterValues, INotamFilterUpsertValues } from './notam-filter-form-types'

export const objectToNotamFilterValues = (object?: { [key: string]: any }): INotamFilterUpsertValues => ({
  issuedDateRange: { 0: object?.issuedFrom || '', 1: object?.issuedTo || '' },
  effectiveStartDateRange: { 0: object?.effectiveStartFrom || '', 1: object?.effectiveStartTo || '' },
  effectiveEndDateRange: { 0: object?.effectiveEndFrom || '', 1: object?.effectiveEndTo || '' },
  typeIds: object?.types || [],
  classificationIds: object?.classifications || [],
  searchText: object?.searchText || '',
})

export const savedSearchFilterToSavedNotamFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): INotamFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let notamFilterObject
  try {
    notamFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    notamFilterObject = {}
  }

  const notamFilterValues = objectToNotamFilterValues(notamFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    notamFilterValues.issuedDateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return notamFilterValues
}

export const savedNotamFilterValuesToSavedSearchFilterAddUpsert = (
  savedNotamFilterValues: INotamFilterValues | undefined,
): IShortNotamSearchFilter => ({
  issuedFrom: savedNotamFilterValues?.issuedDateRange?.[0] || '',
  issuedTo: savedNotamFilterValues?.issuedDateRange?.[1] || '',
  effectiveStartFrom: savedNotamFilterValues?.effectiveStartDateRange?.[0] || '',
  effectiveStartTo: savedNotamFilterValues?.effectiveStartDateRange?.[1] || '',
  effectiveEndFrom: savedNotamFilterValues?.effectiveEndDateRange?.[0] || '',
  effectiveEndTo: savedNotamFilterValues?.effectiveEndDateRange?.[1] || '',
  types: savedNotamFilterValues?.typeIds || [],
  classifications: savedNotamFilterValues?.classificationIds || [],
  searchText: savedNotamFilterValues?.searchText || '',
})

export const savedNotamFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedNotamFilterValues: INotamFilterValues | undefined,
): IShortNotamSearchFilter => savedNotamFilterValuesToSavedSearchFilterAddUpsert(savedNotamFilterValues)
