import qs from 'qs'
import { QueryObserverOptions } from '@tanstack/react-query'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IGetRequestObject } from '../../interfaces'

type QueryObject = {
  version?: string
}

const getChangelogRequest = (_: undefined, queryObject: QueryObject | undefined): IGetRequestObject => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/Changelog${queryString}`,
  }
}

const useChangelogQueryBase = buildGetQuery<undefined, QueryObject | undefined, string | undefined>({
  getRequest: getChangelogRequest,
  getQueryKey: (_, queryObject) => ['Changelog', queryObject?.version],
})

const useChangelogQuery = (
  queryObject: QueryObject | undefined,
  options: QueryObserverOptions<string | undefined, Error>,
) => useChangelogQueryBase(undefined, queryObject, options)

export default useChangelogQuery
