import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material'
import Typography from '@mui/material/Typography'
import { createTheme } from '@mui/material/styles'

// Creating a Material Theme to override the Bootstrap/KeenTheme styles for the child nodes
// Ideally, we could create an application theme for all components to use once we start leveraging more Material components
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: 13,
    fontWeight: 'inherit',
  },
})

const TabPanel = ({ children, index, value, ...rest }) => (
  <ThemeProvider theme={theme}>
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...rest}
    >
      <Box className="h-100">{children}</Box>
    </Typography>
  </ThemeProvider>
)

export default TabPanel
