const calculatePagedRecordRange = (pageNumber, pageSize, totalItems) => {
  const from = pageNumber * pageSize + 1

  // The pagination is currently 0 based instead of 1 based.
  // Add 1 to correct the page number before doing the calculation.
  let to = (pageNumber + 1) * pageSize

  if (to > totalItems) {
    to = totalItems
  }

  return { from, to }
}

export default calculatePagedRecordRange
