import { forwardRef, useCallback, useMemo } from 'react'
import { bool, shape, instanceOf, string, func } from 'prop-types'

import { MaskedInput } from '../masked-input'

import s from './date-picker-input.module.scss'

const DatePickerInput = forwardRef(({ isAddonBeforeVisible, onChange, ...restProps }, ref) => {
  const addonAfter = useMemo(
    () => ref =>
      (
        <button className={s['date-picker-icon-btn']} type="button" onClick={() => ref.current.element.focus()}>
          <i className="bi bi-calendar text-dark" />
        </button>
      ),
    [],
  )

  const handleChange = useCallback(value => onChange({ target: { value } }), [onChange])

  return <MaskedInput addonAfter={addonAfter} onChange={handleChange} {...restProps} />
})

DatePickerInput.propTypes = {
  isInvalid: bool,
  componentRef: shape({ current: instanceOf(Element) }),
  value: string,
  onChange: func,
  name: string,
}

export default DatePickerInput
