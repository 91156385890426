import qs from 'qs'

import config from '../../config'

import { IGetGetRequest } from '../../interfaces'

const getAirportWildlifeSpeciesRequest: IGetGetRequest<any> = (params, queryObject) => {
  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/WildlifeSpecies${queryString}`,
  }
}

export default getAirportWildlifeSpeciesRequest
