import { ISafetyConcernStatusValues } from './safety-concern-status-form-types'
import {
  ISafetyConcernStatus,
  ISafetyConcernStatusAddUpsert,
  ISafetyConcernStatusUpdateUpsert,
  IGeoJson,
} from '../../../../interfaces'

export const safetyConcernStatusToSafetyConcernStatusValues = (
  safetyConcernStatus: ISafetyConcernStatus | undefined,
): ISafetyConcernStatusValues | undefined => {
  if (!safetyConcernStatus) {
    return undefined
  }

  return {
    name: safetyConcernStatus?.name,
    description: safetyConcernStatus?.description,
    baseStatus: safetyConcernStatus?.baseStatus,
    // @ts-ignore
    sortOrder: String(safetyConcernStatus?.sortOrder),
    isActive: safetyConcernStatus?.isActive,
  }
}

export const safetyConcernStatusValuesToSafetyConcernStatusAddUpsert = (
  safetyConcernStatusValues: ISafetyConcernStatusValues,
  payload: { airportId: number },
): ISafetyConcernStatusAddUpsert | undefined => {
  if (!safetyConcernStatusValues) {
    return undefined
  }

  return {
    id: 0,
    airportId: payload?.airportId,
    name: safetyConcernStatusValues?.name,
    description: safetyConcernStatusValues?.description,
    baseStatus: safetyConcernStatusValues?.baseStatus,
    sortOrder: safetyConcernStatusValues?.sortOrder || 0,
    isActive: safetyConcernStatusValues?.isActive || false,
  }
}

export const safetyConcernStatusValuesToSafetyConcernStatusUpdateUpsert = (
  safetyConcernStatusValues: ISafetyConcernStatusValues,
  payload: { safetyConcernStatus: ISafetyConcernStatus; geoJson?: IGeoJson },
): ISafetyConcernStatusUpdateUpsert | undefined => {
  if (!safetyConcernStatusValues || !payload?.safetyConcernStatus?.id || !payload?.safetyConcernStatus?.airportId) {
    return undefined
  }

  return {
    id: payload.safetyConcernStatus.id,
    airportId: payload.safetyConcernStatus.airportId,
    name: safetyConcernStatusValues?.name,
    description: safetyConcernStatusValues?.description,
    baseStatus: safetyConcernStatusValues?.baseStatus,
    sortOrder: safetyConcernStatusValues?.sortOrder,
    isActive: safetyConcernStatusValues?.isActive,
  }
}
