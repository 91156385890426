import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IChecklist, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  entityState?: string
}

const getChecklistsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Checklists/instances${queryString}`,
  }
}

const useChecklistsQuery = buildGetQuery<Params, QueryObject | undefined, IChecklist[] | undefined>({
  getRequest: getChecklistsRequest,
  getQueryKey: (params, queryObject) => ['checklists', params.airportId, queryObject?.entityState || ''],
  defaultOptions: { refetchOnWindowFocus: false },
})

export default useChecklistsQuery
