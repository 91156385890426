import { useMemo } from 'react'

import { CrudTable } from '../crud-table'
import { defaultProps } from '../crud-table/crud-table'

import { useAccessControl } from '../../security'

const PermissionsCrudTable = props => {
  const { createSettings, readSettings, updateSettings, deleteSettings, ...restProps } = props

  const { hasAccess } = useAccessControl()

  const { accessClaim: readAccessClaim, ...restReadSettings } = readSettings

  const isReadAccessPresent = [readAccessClaim].map(claim => hasAccess(claim)).every(x => x)

  const { accessClaim: createAccessClaim, ...restCreateSettings } = createSettings

  const isCreateAccessPresent = [createAccessClaim].map(claim => hasAccess(claim)).every(x => x)

  const { accessClaim: updateAccessClaim, ...restUpdateSettings } = updateSettings

  const isUpdateAccessPresent = [updateAccessClaim].map(claim => hasAccess(claim)).every(x => x)

  const { accessClaim: deleteAccessClaim, ...restDeleteSettings } = deleteSettings

  const isDeleteAccessPresent = [deleteAccessClaim].map(claim => hasAccess(claim)).every(x => x)

  const isActionsVisible = useMemo(
    () => isUpdateAccessPresent || isDeleteAccessPresent,
    [isUpdateAccessPresent, isDeleteAccessPresent],
  )

  if (!isReadAccessPresent) {
    return null
  }

  return (
    <CrudTable
      createSettings={{ hideCreateEntityBtn: !isCreateAccessPresent, ...restCreateSettings }}
      readSettings={restReadSettings}
      updateSettings={{ hideUpdateEntityBtn: !isUpdateAccessPresent, ...restUpdateSettings }}
      deleteSettings={{ hideDeleteEntityBtn: !isDeleteAccessPresent, ...restDeleteSettings }}
      isActionsVisible={isActionsVisible}
      {...restProps}
    />
  )
}

PermissionsCrudTable.defaultProps = defaultProps

export default PermissionsCrudTable
