import { useCallback, useEffect, useRef } from 'react'
import { differenceInMinutes } from 'date-fns'

import { useAppContext } from '../../app-context'
import { useMessageOfTheDayQuery } from '../../services'

import { storageUtil } from '../../util'
import { actions as appActions } from '../../actions'
import config from '../../config'

import { IMessageOfTheDay } from '../../interfaces'

const areMessagesOfTheDayEqual = (message1: any, message2: any) => {
  if (
    message1?.message === message2?.message &&
    message1?.fontColor === message2?.fontColor &&
    message1?.url === message2?.url &&
    message1?.backgroundColor === message2?.backgroundColor &&
    message1?.effectiveAt === message2?.effectiveAt &&
    message1?.expiresAt === message2?.expiresAt
  ) {
    return true
  }

  return false
}

const messageOfTheDayIntervalInMinutes = 30

const useMessageOfTheDay = (airportId: number) => {
  const { state: appState, dispatch: appDispatch } = useAppContext()

  const onMessageOfTheDaySuccess = useCallback(
    (messageOfTheDay: IMessageOfTheDay | undefined) => {
      if (!messageOfTheDay) {
        const settings = {
          time: new Date(),
          hasBeenClosed: true,
          show: false,
        }
        storageUtil.setItem(config.messageOfTheDaySettingsKey, {})
        appDispatch(appActions.maintenance.setMaintenanceBanner({ messageOfTheDay, settings }))
        return
      }

      const { messageOfTheDay: cachedMessageOfTheDay } = storageUtil.getItem(
        config.messageOfTheDaySettingsKey,
        // @ts-ignore
        {},
      )

      if (!areMessagesOfTheDayEqual(messageOfTheDay, cachedMessageOfTheDay)) {
        const settings = {
          time: new Date(),
          hasBeenClosed: false,
          show: true,
        }

        storageUtil.setItem(config.messageOfTheDaySettingsKey, {
          messageOfTheDay,
          settings,
        })

        appDispatch(appActions.maintenance.setMaintenanceBanner({ messageOfTheDay, settings }))
        appDispatch(appActions.maintenance.showMaintenanceBanner())
      }
    },
    [appDispatch],
  )

  const { refetch: fetchMessageOfTheDay } = useMessageOfTheDayQuery({ airportId }, undefined, {
    onSuccess: onMessageOfTheDaySuccess,
    enabled: false,
  })

  const timerIdRef = useRef<number | null>(null)
  const intervalIdRef = useRef<number | null>(null)

  useEffect(() => {
    if (!appState.appDataLoaded) {
      return
    }

    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    const { messageOfTheDay, settings } = storageUtil.getItem(
      config.messageOfTheDaySettingsKey,
      // @ts-ignore
      { messageOfTheDay: null, settings: null },
    )

    if (!messageOfTheDay || !settings?.time) {
      fetchMessageOfTheDay()
    }

    appDispatch(appActions.maintenance.setMaintenanceBanner({ messageOfTheDay, settings }))

    const messageOfTheDayMinutesAgo = differenceInMinutes(new Date(), new Date(settings?.time))
    if (messageOfTheDayMinutesAgo > messageOfTheDayIntervalInMinutes) {
      fetchMessageOfTheDay()

      intervalIdRef.current = window.setInterval(fetchMessageOfTheDay, messageOfTheDayIntervalInMinutes * 1000 * 60)

      return
    } else {
      const refetchTimeInMilliseconds = (messageOfTheDayIntervalInMinutes - messageOfTheDayMinutesAgo) * 1000 * 60

      timerIdRef.current = window.setTimeout(() => {
        fetchMessageOfTheDay()
        intervalIdRef.current = window.setInterval(fetchMessageOfTheDay, messageOfTheDayIntervalInMinutes * 1000 * 60)
      }, refetchTimeInMilliseconds)
    }

    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current)
      }
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
    }
  }, [appDispatch, appState.appDataLoaded, fetchMessageOfTheDay])

  useEffect(() => {
    if (appState.maintenance.isLoaded) {
      storageUtil.setItem(config.messageOfTheDaySettingsKey, {
        messageOfTheDay: appState.maintenance.messageOfTheDay,
        settings: appState.maintenance.settings,
      })
    }
  }, [appState.maintenance])
}

export default useMessageOfTheDay
