import { useCallback } from 'react'

const useCheckboxTreeNode = props => {
  const { handleChange, node: _node, value } = props

  const handleChildNodeChange = useCallback(
    (node, checked) => {
      let computedNode

      if (value) {
        let computedNodeChildren

        if (checked) {
          const nodeIndex = value.children.findIndex(v => v.value === node.value)

          if (nodeIndex !== -1) {
            computedNodeChildren = [...value.children.slice(0, nodeIndex), node, ...value.children.slice(nodeIndex + 1)]
          } else {
            computedNodeChildren = [...value.children, node]
          }
        } else {
          computedNodeChildren = value.children.filter(v => v.value !== node.value)
        }

        computedNode = { label: _node.label, value: _node.value, children: computedNodeChildren }
      } else {
        computedNode = {
          label: _node.label,
          value: _node.value,
          children: [{ label: node.label, value: node.value, children: node.children }],
        }
      }

      const _checked = computedNode.children.length > 0

      handleChange(computedNode, _checked)
    },
    [handleChange, _node, value],
  )

  const handleParentNodeChange = useCallback(
    e => {
      handleChange(_node, e.target.checked)
    },
    [handleChange, _node],
  )

  return {
    handleChildNodeChange,
    handleParentNodeChange,
  }
}

export default useCheckboxTreeNode
