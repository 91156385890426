import { FC, Fragment } from 'react'
import { Table } from '@tanstack/react-table'

import { Spinner } from '../../spinner'
import { Row } from '../row'

import { ReorderSettings } from '../table-types'

type TableBodyProps = {
  tableInstance: Table<any>
  reorderSettings?: ReorderSettings
  isLoading?: boolean
  isError?: boolean
}

const TableBody: FC<TableBodyProps> = props => {
  const { tableInstance, reorderSettings, isLoading, isError } = props

  const { rows } = tableInstance.getRowModel()

  if (rows.length === 0 && isLoading) {
    return (
      <tr className="d-flex justify-content-center">
        <td>
          <Spinner />
        </td>
      </tr>
    )
  }

  if (isError) {
    return (
      <tr>
        <td rowSpan={1} colSpan={1} className="vw-100">
          <div className="d-flex justify-content-center text-danger">Failed to fetch data</div>
        </td>
      </tr>
    )
  }

  if (rows.length === 0) {
    return (
      <tr>
        <td rowSpan={1} colSpan={1} className="vw-100">
          <div className="d-flex justify-content-center">No records to display</div>
        </td>
      </tr>
    )
  }

  return (
    <>
      {rows.map(row => (
        <Row key={row.id} row={row} tableInstance={tableInstance} reorderSettings={reorderSettings} />
      ))}
    </>
  )
}

export default TableBody
