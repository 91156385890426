import axios from 'axios'

import config from '../../config'

const createAuthUser = (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/AuthUsers`
  return axios.post(url, data)
}

export default createAuthUser
