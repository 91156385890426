import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortConditionsMutationRequest from './get-sort-conditions-mutation-request'

import { IUseSortConditionsMutation, ISortConditions } from './use-sort-conditions-mutation-types'

const useSortConditionsMutation: IUseSortConditionsMutation = (params, options = {}) => {
  const request = useMemo(() => getSortConditionsMutationRequest(params), [params])

  const sortConditions: ISortConditions = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortConditions,
    ...options,
  })

  return response
}

export default useSortConditionsMutation
