import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IAuthUser, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

type QueryObject = {
  activeOnly?: boolean
  includeDetail?: boolean
}

const defaultQueryObject = {
  activeOnly: true,
}

const getAuthUsersRequest = (params: Params, queryObject: QueryObject = defaultQueryObject): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/AuthUsers${queryString}`,
  }
}

export const useAuthUsersQuery = buildGetQuery<Params, QueryObject | undefined, IAuthUser[] | undefined>({
  getRequest: getAuthUsersRequest,
  getQueryKey: (params, queryObject) => [
    'auth-users',
    params.airportId,
    queryObject?.activeOnly,
    queryObject?.includeDetail,
  ],
})

export default useAuthUsersQuery
