import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { Polygon as GooglePolygon, InfoWindow } from '@react-google-maps/api'

import { useModal } from '../../hooks'
import { calculatePolygonCenter, getPolygonCoordinates } from '../../util'

import { IGeoJson } from '../../interfaces'
import { IEditablePolygonProps } from './editable-polygon-types'

const EditablePolygon: FC<IEditablePolygonProps> = props => {
  const { geoJson, path, options, onUpdate, renderEditPopUp } = props

  const infoWindowOptions = useMemo(() => ({ pixelOffset: new window.google.maps.Size(0, -40) }), [])

  const { isOpen: isEditableInfoOpen, openModal: openEditableInfo, closeModal: closeEditableInfo } = useModal()

  const polygonRef = useRef<google.maps.Polygon | null>()

  const getGeoJson = useCallback((): IGeoJson | null => {
    if (!polygonRef.current) {
      return null
    }

    const coordinates = getPolygonCoordinates(polygonRef.current)

    if (!coordinates) {
      return null
    }

    return {
      ...geoJson,
      geometry: {
        ...geoJson.geometry,
        coordinates,
      },
    }
  }, [geoJson])

  const _onUpdate = useCallback(() => {
    if (!onUpdate) {
      return
    }

    const geoJson = getGeoJson()

    if (geoJson) {
      onUpdate(geoJson)
    }
  }, [onUpdate, getGeoJson])

  const listenersRef = useRef<google.maps.MapsEventListener[]>([])
  const pathRef = useRef<google.maps.MVCArray<google.maps.LatLng> | null>(null)

  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon

      const path = polygon.getPath()

      pathRef.current = path

      listenersRef.current.push(
        path.addListener('set_at', _onUpdate),
        path.addListener('insert_at', _onUpdate),
        path.addListener('remove_at', _onUpdate),
      )
    },
    [_onUpdate],
  )

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
  }, [])

  useEffect(() => {
    // For some reasons after adding/removing/updating polygon the path is changed
    // and ower listeners doesn't work, reseting listeners is a trick to avoid this behaviour
    if (polygonRef.current && pathRef.current !== polygonRef.current.getPath()) {
      listenersRef.current.forEach(lis => lis.remove())

      const path = polygonRef.current.getPath()

      listenersRef.current.push(
        path.addListener('set_at', _onUpdate),
        path.addListener('insert_at', _onUpdate),
        path.addListener('remove_at', _onUpdate),
      )
    }
  })

  return (
    <>
      {isEditableInfoOpen && renderEditPopUp && (
        <InfoWindow
          position={{
            lat: calculatePolygonCenter(geoJson.geometry.coordinates[0]).latitude,
            lng: calculatePolygonCenter(geoJson.geometry.coordinates[0]).longitude,
          }}
          onCloseClick={closeEditableInfo}
          options={infoWindowOptions}
        >
          {renderEditPopUp(geoJson)}
        </InfoWindow>
      )}

      <GooglePolygon
        onClick={openEditableInfo}
        options={options}
        path={path}
        onLoad={onLoad}
        onUnmount={onUnmount}
        editable
        draggable
      />
    </>
  )
}

export default EditablePolygon
