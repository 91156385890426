import * as util from './inspection-type-util'

// This function adds some properties to the inspection so we do not have to repeat the logic in each component.
function enrichInspection(inspection) {
  if (!inspection) {
    return inspection
  }

  const category = inspection.inspectionCategory

  inspection.isPart139 = util.isPart139(category)
  inspection.isSPI = util.isSPI(category)
  inspection.categoryShortName = util.getShortName(category)

  return inspection
}

export const enrichInspectionImmutable = inspection => {
  if (!inspection) {
    return inspection
  }

  return {
    ...inspection,
    isPart139: util.isPart139(inspection.inspectionCategory),
    isSPI: util.isSPI(inspection.inspectionCategory),
    categoryShortName: util.getShortName(inspection.inspectionCategory),
  }
}

export default enrichInspection
