import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'

import s from './hide-show-button.module.scss'

type HideShowButtonProps = {
  className?: string
  show: boolean
  text: string
  onHide: Function
  onShow: Function
  disabled?: boolean
}

const HideShowButton: React.FC<HideShowButtonProps> = ({
  className = 'btn-outline-secondary',
  show = true,
  text = '',
  onHide = () => {},
  onShow = () => {},
  disabled,
}) => {
  const buttonText = useMemo(() => {
    return `${show ? 'Hide' : 'Show'} ${text}`
  }, [show, text])

  const onClick = useCallback(() => {
    if (show) {
      onHide()
    } else {
      onShow()
    }
  }, [onHide, onShow, show])

  return (
    <button className={cn(s['hide-show-button'], 'btn', className)} onClick={onClick} disabled={disabled}>
      <i className="bi bi-funnel-fill" /> <span>{buttonText}</span>
    </button>
  )
}

export default HideShowButton
