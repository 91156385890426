import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import NavbarMenu from './navbar-menu'
import NotificationButton from './notifications-button/notification-button'
import FAQButton from './faq-button/faq-button'
import ProfileButton from './profile-button/profile-button'
import HamburgerButton from './hamburger-button/hamburger-button'

import useAirport from '../../features/use-airport'
import { useNavigationRoutes } from '../../hooks'
import { useAuthContext } from '../../security'
import { useAppContext } from '../../app-context'

import config from '../../config'

import s from './navigation-menu.module.scss'

const NavigationMenu = () => {
  const airport = useAirport()

  const routes = useNavigationRoutes(config.routes)

  const { isAuthenticated } = useAuthContext()
  const { state } = useAppContext()
  const appDataLoaded = state.appDataLoaded

  return (
    <nav className={s['navigation-menu']}>
      <NavLink to="/" className={s['logo']}>
        <img src="/images/logo.svg" alt="App 139 logo" />
      </NavLink>

      {isAuthenticated && appDataLoaded && (
        <div className={s['navigation-list']}>
          <NavbarMenu
            onMenuItemClick={() => {}}
            routes={routes}
            className={cn(s['navigation-list-wrapper'], 'navbarMainMenu-override')}
          />
        </div>
      )}

      <div className={s['airport-name']}>{airport.name || ''}</div>

      <span className={s['separator']}>|</span>

      <FAQButton className={s['faq-button']} />

      <NotificationButton className={s['notification-button']} notificationClick={() => {}} />

      <ProfileButton className={s['profile-button']} />

      <HamburgerButton className={s['hamburger-button']} />
    </nav>
  )
}

export default NavigationMenu
