import React from 'react'
import { string, bool } from 'prop-types'
import cn from 'classnames'

import s from './radio.module.scss'

const Radio = ({ subLabel, name, disabled, onChange, value, checked }) => {
  const labelClassName = cn(s['radio-wrapper'], { [s['radio-wrapper-disabled']]: disabled })

  return (
    <label className={labelClassName}>
      <div className={s['radio-content']}>
        <div className={s['radio']}>
          <input type="radio" value={value} checked={checked} onChange={onChange} name={name} disabled={disabled} />
          <span className={s['slider']} />
        </div>

        <span className={s['sub-label']}>{subLabel}</span>
      </div>
    </label>
  )
}

Radio.defaultProps = {
  onLabel: '',
  offLabel: '',
  label: '',
  disabled: false,
}

Radio.propTypes = {
  name: string.isRequired,
  onLabel: string,
  offLabel: string,
  label: string,
  disabled: bool,
}

export default Radio
