import { useCallback, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { Form } from 'react-final-form'

import Button from '../button/button'
import { showSuccessAlert, showErrorAlert } from '../alert/alert-actions'

import { useProfile } from '../../security'

import { IUseAddMutation, IUseUpdateMutation, IUseDeleteMutation } from '../../interfaces'
import { useAppContext } from '../../app-context'

export type crudModalMode = 'create' | 'delete' | 'update'

interface CrudModalProps<TData, TDataValues, TDataUpsertValues, TDataAddUpsert, TDataUpdateUpsert> {
  modalSettings: {
    isOpen: boolean
    mode?: crudModalMode
    closeModal: () => void
  }
  createSettings?: {
    title: string
    useCreateMutation: IUseAddMutation<TData, NonNullable<TDataAddUpsert>>
    formValuesToAddUpsert: (data: TDataValues) => TDataAddUpsert | undefined
    onCreateSuccessMessage: string
    onCreateErrorMessage: string
    onSuccessCallback?: () => void
  }
  updateSettings?: {
    title: string
    entityToFormValues: (data: TData) => TDataUpsertValues | undefined
    useUpdateMutation: IUseUpdateMutation<TData, NonNullable<TDataUpdateUpsert>>
    formValuesToUpdateUpsert: (data: TDataValues) => TDataUpdateUpsert | undefined
    onUpdateSuccessMessage: string
    onUpdateErrorMessage: string
    updatingItem?: TData
    onSuccessCallback?: () => void
  }
  deleteSettings?: {
    title: string
    useDeleteMutation: IUseDeleteMutation<TData, number>
    onDeleteSuccessMessage: string
    onDeleteErrorMessage: string
    deletingItem?: TData
    onSuccessCallback?: () => void
  }
  FormComponent: any
  formName: string
  mutators?: any
}

const CrudModal = <TData, TDataValues, TDataUpsertValues, TDataAddUpsert, TDataUpdateUpsert>(
  props: CrudModalProps<TData, TDataValues, TDataUpsertValues, TDataAddUpsert, TDataUpdateUpsert>,
) => {
  const { modalSettings, createSettings, deleteSettings, updateSettings, FormComponent, formName, mutators } = props

  const { dispatch: appDispatch } = useAppContext()

  const { airportId } = useProfile()

  const { isOpen, closeModal, mode } = modalSettings

  const { mutateAsync: createMutation } = (createSettings?.useCreateMutation || (() => ({ mutateAsync: () => {} })))({
    airportId,
  })

  const handleCreate = useCallback(
    async (formValues: TDataValues) => {
      let addUpsert = createSettings?.formValuesToAddUpsert(formValues)

      if (!addUpsert) {
        return
      }

      try {
        await createMutation(addUpsert)
      } catch (err) {
        console.warn(createSettings?.onCreateErrorMessage, err)
        appDispatch(showErrorAlert(createSettings?.onCreateErrorMessage))
        return
      }

      appDispatch(showSuccessAlert(createSettings?.onCreateSuccessMessage))
      closeModal()
      createSettings?.onSuccessCallback?.()
    },
    [appDispatch, createSettings, createMutation, closeModal],
  )

  const createContent = useMemo(
    () => (
      <>
        <Modal.Header closeButton={true}>
          <Modal.Title>{createSettings?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleCreate} component={FormComponent} formName={formName} mutators={mutators} />
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button type="secondary" onClick={closeModal} className="mr-2">
              Cancel
            </Button>

            <Button form={formName} buttonType="submit">
              Create
            </Button>
          </div>
        </Modal.Footer>
      </>
    ),
    [FormComponent, closeModal, createSettings, formName, handleCreate, mutators],
  )

  const { mutateAsync: updateMutation } = (updateSettings?.useUpdateMutation || (() => ({ mutateAsync: () => {} })))({
    airportId,
  })

  const handleUpdate = useCallback(
    async (formValues: TDataValues) => {
      let updateUpsert = updateSettings?.formValuesToUpdateUpsert(formValues)

      if (!updateUpsert) {
        return
      }

      try {
        await updateMutation(updateUpsert)
      } catch (err) {
        console.warn(updateSettings?.onUpdateErrorMessage, err)
        appDispatch(showErrorAlert(updateSettings?.onUpdateErrorMessage))
        return
      }

      appDispatch(showSuccessAlert(updateSettings?.onUpdateSuccessMessage))
      closeModal()
      updateSettings?.onSuccessCallback?.()
    },
    [appDispatch, updateSettings, updateMutation, closeModal],
  )

  const entityToFormValues = updateSettings?.entityToFormValues
  const updatingItem = updateSettings?.updatingItem

  const updateInitialValues = useMemo(() => {
    if (!entityToFormValues || !updatingItem) {
      return undefined
    }

    return entityToFormValues(updatingItem)
  }, [entityToFormValues, updatingItem])

  const updateContent = useMemo(
    () => (
      <>
        <Modal.Header closeButton={true}>
          <Modal.Title>{updateSettings?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={handleUpdate}
            component={FormComponent}
            formName={formName}
            initialValues={updateInitialValues}
            mutators={mutators}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button type="secondary" onClick={closeModal} className="mr-2">
              Cancel
            </Button>

            <Button form={formName} buttonType="submit">
              Update
            </Button>
          </div>
        </Modal.Footer>
      </>
    ),
    [FormComponent, updateSettings?.title, closeModal, formName, handleUpdate, updateInitialValues, mutators],
  )

  const { mutateAsync: deleteMutation } = (deleteSettings?.useDeleteMutation || (() => ({ mutateAsync: () => {} })))({})

  const handleDelete = useCallback(async () => {
    try {
      await deleteMutation(
        // @ts-ignore
        deleteSettings?.deletingItem?.id || 0,
      )
    } catch (err) {
      appDispatch(showErrorAlert(deleteSettings?.onDeleteErrorMessage))
      console.warn(deleteSettings?.onDeleteErrorMessage, err)
      return
    }

    appDispatch(showSuccessAlert(deleteSettings?.onDeleteSuccessMessage))
    closeModal()
    deleteSettings?.onSuccessCallback?.()
  }, [appDispatch, deleteMutation, deleteSettings, closeModal])

  const deleteContent = useMemo(
    () => (
      <>
        <Modal.Header closeButton={true}>
          <Modal.Title>{deleteSettings?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to delete? This action cannot be undone.</p>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button type="secondary" onClick={closeModal} className="mr-2">
              Cancel
            </Button>

            <Button form={formName} onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </>
    ),
    [closeModal, deleteSettings, formName, handleDelete],
  )

  if (!mode) {
    return null
  }

  return (
    <Modal animation={false} onHide={closeModal} show={isOpen} contentClassName="h-100" size="lg">
      {mode === 'create' && createContent}

      {mode === 'update' && updateContent}

      {mode === 'delete' && deleteContent}
    </Modal>
  )
}

export default CrudModal
