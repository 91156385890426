import { FC } from 'react'

import WorkOrderFormFields from './work-order-form-fields'

import { IWorkOrderFormProps } from './work-order-form-types'

const WorkOrderForm: FC<IWorkOrderFormProps> = props => {
  const { handleSubmit, formName, submitting, values, form } = props

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <WorkOrderFormFields submitting={submitting} values={values} form={form} />
    </form>
  )
}

export default WorkOrderForm
