import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateSetInspectionTemplateMasterMutationRequest from './get-update-set-inspection-template-master-mutation-request'

import {
  IUseUpdateMutation,
  IUpdateFn,
  IInspectionTemplateMasterItem,
  IInspectionTemplateMasterItemUpdateUpsert,
} from '../../interfaces'

const useUpdateSetInspectionTypeMasterMutation: IUseUpdateMutation<
  IInspectionTemplateMasterItem[],
  IInspectionTemplateMasterItemUpdateUpsert[]
> = (params, options = {}) => {
  const request = useMemo(() => getUpdateSetInspectionTemplateMasterMutationRequest(params), [params])

  const updateSetInspectionTypeMaster: IUpdateFn<
    IInspectionTemplateMasterItemUpdateUpsert[],
    IInspectionTemplateMasterItem[]
  > = useCallback(async data => axios({ ...request, data }).then(res => res.data), [request])

  const response = useMutation<IInspectionTemplateMasterItem[], Error, IInspectionTemplateMasterItemUpdateUpsert[]>({
    mutationFn: updateSetInspectionTypeMaster,
    ...options,
  })

  return response
}

export default useUpdateSetInspectionTypeMasterMutation
