import { FC, useMemo } from 'react'
import { Header, flexRender } from '@tanstack/react-table'
import cn from 'classnames'

import { SortIcon } from '../../sort-icon'

import s from '../table.module.scss'

type TableHeaderGroupHeaderProps = {
  header: Header<any, unknown>
  isRowsDraggable?: boolean
}

const TableHeaderGroupHeader: FC<TableHeaderGroupHeaderProps> = props => {
  const { header, isRowsDraggable } = props

  const canSort = header.column.getCanSort()

  const onHeaderClick = useMemo(
    () => (canSort ? header.column.getToggleSortingHandler() : undefined),
    [canSort, header.column],
  )

  const columnClassName = header?.column?.columnDef?.meta?.className

  const grow = header?.column?.columnDef?.meta?.grow || 1

  const headerClassName = useMemo(() => cn(s['th'], columnClassName), [columnClassName])

  const headerContentClassName = useMemo(() => cn(s['th-content'], canSort && s['th-content-pointer']), [canSort])

  const thStyle = useMemo(
    () => ({
      flexGrow: grow,
    }),
    [grow],
  )

  return (
    <th colSpan={header.colSpan} className={headerClassName} style={thStyle} title={header.column.getCanSort() ? "Toggle Sort By" : ""}>
      <div className={headerContentClassName} onClick={onHeaderClick}>
        {flexRender(header.column.columnDef.header, header.getContext())}

        {header.column.getCanSort() && !isRowsDraggable && <SortIcon isSorted={header.column.getIsSorted()} />}
      </div>
    </th>
  )
}

export default TableHeaderGroupHeader
