type AppTitleProps = {
  title: string
  children: any
}

const AppTitle: React.FC<AppTitleProps> = ({ title, children }) => {
  if (title) {
    document.title = title
  }
  return { ...children }
}

export default AppTitle
