import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import { IWorkOrder, IWorkOrderUpdateUpsert, IGetPutRequest, IUseUpdateMutation, IUpdateFn } from '../../interfaces'

const getUpdateWorkOrderMutationRequest: IGetPutRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/WorkOrders`,
  }
}

const useUpdateWorkOrderMutation: IUseUpdateMutation<IWorkOrder, IWorkOrderUpdateUpsert> = (params, options = {}) => {
  const request = useMemo(() => getUpdateWorkOrderMutationRequest(params), [params])

  const UpdateWorkOrder: IUpdateFn<IWorkOrderUpdateUpsert, IWorkOrder> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IWorkOrder, Error, any>({
    mutationFn: UpdateWorkOrder,
    ...options,
  })

  return response
}

export default useUpdateWorkOrderMutation
