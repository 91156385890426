import { FC, useState } from 'react'

import { Modal } from '../../components'

type DeleteModalProps = {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  title: string
  content: any
}

const DeleteModal: FC<DeleteModalProps> = props => {
  const { isOpen, onClose, onDelete, title, content } = props

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      await onDelete()
    } catch (e) {
      console.error(e)
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      cancelBtnText="Cancel"
      confirmBtnVariant="danger"
      confirmBtnText="Delete"
      isDisabled={isLoading}
    >
      {isOpen && <div>{content}</div>}
    </Modal>
  )
}

export default DeleteModal
