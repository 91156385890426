import axios from 'axios'

import config from '../../config'

const createInspectionTypeBuilder = (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/InspectionTypeBuilders`
  return axios.post(url, data)
}

export default createInspectionTypeBuilder
