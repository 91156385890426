import { QueryObserverOptions } from '@tanstack/react-query'

import { buildGetQuery } from '../../util'
import config from '../../config'

import { IPostRequestObject, IPagedResults, ISafetyConcern, ISafetyConcernSearchFilter } from '../../interfaces'

const getFilteredSafetyConcernsRequest = (
  params: undefined,
  queryObject: ISafetyConcernSearchFilter | undefined,
): IPostRequestObject => ({
  method: 'post',
  url: `${config.baseUrl}/SafetyConcerns/Search`,
  data: queryObject,
})

const useSafetyConcernsSearchQueryBase = buildGetQuery<
  undefined,
  ISafetyConcernSearchFilter | undefined,
  IPagedResults<ISafetyConcern | undefined>
>({
  getRequest: getFilteredSafetyConcernsRequest,
  getQueryKey: (params, queryObject) => [
    'safety-concern-search',
    queryObject?.startedAtBegin,
    queryObject?.startedAtEnd,
    queryObject?.injuryOccurred,
    queryObject?.damageOccurred,
    queryObject?.recurringConcern,
    queryObject?.originatedFromSafetyReport,
    queryObject?.statusIds,
    queryObject?.priorityIds,
    queryObject?.limit,
    queryObject?.page,
    queryObject?.sortBy,
    queryObject?.sortOrder,
  ],
  defaultOptions: { initialData: undefined, refetchOnWindowFocus: false },
})

const useSafetyConcernsSearchQuery = (
  queryObject: ISafetyConcernSearchFilter | undefined,
  options?: QueryObserverOptions<IPagedResults<ISafetyConcern | undefined>, Error>,
) => useSafetyConcernsSearchQueryBase(undefined, queryObject, options)

export default useSafetyConcernsSearchQuery
