import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject } from '../../interfaces'
import { IAirportLite } from '../../interfaces'

type Params = {
  airportCode: string
}

const request = (params: Params): IGetRequestObject => {
  const { airportCode } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/SafetyReport/LookupAirport?airportCode=${airportCode}`,
  }
}

const useSafetyReportAirportByCode = buildGetQuery<Params, undefined, IAirportLite | undefined>({
  getRequest: request,
  getQueryKey: params => ['safety-report-airport-by-code', params.airportCode],
})

export default useSafetyReportAirportByCode
