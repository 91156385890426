export const queryParamsToParams = (queryParams?: {
  [key: string]: any
}): {
  workOrders: { pagination: { pageSize: number; pageIndex: number } }
  safetyConcernActions: { pagination: { pageSize: number; pageIndex: number } }
} => ({
  workOrders: {
    pagination: {
      pageSize: queryParams?.workOrders?.pagination?.pageSize || 10,
      pageIndex: queryParams?.workOrders?.pagination?.pageIndex || 0,
    },
  },
  safetyConcernActions: {
    pagination: {
      pageSize: queryParams?.safetyConcernActions?.pagination?.pageSize || 10,
      pageIndex: queryParams?.safetyConcernActions?.pagination?.pageIndex || 0,
    },
  },
})
