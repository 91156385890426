import { IAuthUser } from '../interfaces'

export const getUsersNamesByIds = (authUsers: IAuthUser[] | undefined, userIds: string[] | undefined) => {
  if (!userIds || !authUsers) {
    return []
  }

  return userIds
    .map(attendeeUserId => authUsers.find(authUser => authUser.id === attendeeUserId))
    .map(authUser => authUser?.userName)
}
