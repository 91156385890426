import s from './no-preview.module.scss'

type NoPreviewProps = {
  message?: string
}

const NoPreview: React.FC<NoPreviewProps> = ({ message = 'Nothing to preview.' }) => {
  return (
    <div className={`${s['no-preview']} `}>
      <span>{message}</span>
    </div>
  )
}

export default NoPreview
