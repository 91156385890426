import { buildGetQuery } from '../../util'
import config from '../../config'

import { IAuthUserLastViewedVersion, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  id: number
}

const getAuthUserLastViewedVersionByIdRequest = (params: Params): IGetRequestObject => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/AuthUsers/${id}/LastViewedVersion`,
  }
}

export const useAuthUsersLastViewedVersionByIdQuery = buildGetQuery<
  Params,
  undefined,
  IAuthUserLastViewedVersion | undefined
>({
  getRequest: params => getAuthUserLastViewedVersionByIdRequest(params),
  getQueryKey: params => ['auth-users-last-viewed-version', params.airportId],
})

export default useAuthUsersLastViewedVersionByIdQuery
