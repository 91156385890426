import config from '../../config'

import { IGetDownloadWorkOrdersCSVMutationRequest } from './types'

const getDownloadWorkOrdersCSVMutationRequest: IGetDownloadWorkOrdersCSVMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/WorkOrders/Download`,
  }
}

export default getDownloadWorkOrdersCSVMutationRequest
