import cn from 'classnames'
import s from './button-filtered-dropdown.module.scss'

type ButtonFilteredDropdownBlanketProps = {
  className: string
  onClick: any
}

// This is the "overlay" with a lower z-index that, when clicked, closes the select menu
const ButtonFilteredDropdownBlanket: React.FC<ButtonFilteredDropdownBlanketProps> = ({ className, ...restProps }) => (
  <div className={cn(s['filtered-dropdown-blanket'], className)} {...restProps} />
)

export default ButtonFilteredDropdownBlanket
