import React, { FC, useCallback, useMemo, useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { QueryObserverOptions, DefinedUseQueryResult, QueryKey } from '@tanstack/react-query'
import Parser from 'html-react-parser'
import cn from 'classnames'

import { Spinner, Button } from '../../components'
import ReportPreviewPagination from './report-preview-pagination'

import { useProfile } from '../../security'

import { downloadBlob } from '../../util'
import { iterateReactChildren } from './report-modal.utils'
import ReportPreviewRenderer from './report-preview-renderer'

import s from './report-modal.module.scss'

type ReportParams = {
  [key: string]: any
}

type ReportQueryObject = {
  [key: string]: any
}

export type ReportModalProps = {
  title?: string
  isOpen: boolean
  closeModal: () => void
  searchParams: { [key: string]: any }
  reportQueryParams?: ReportParams
  queryObject?: ReportQueryObject
  totalItems?: number
  useReportQuery: (
    params: ReportParams | any,
    queryObject: ReportQueryObject | any,
    options?: QueryObserverOptions<
      string | Blob | undefined,
      Error,
      string | Blob | undefined,
      string | Blob | undefined,
      QueryKey
    >,
  ) => DefinedUseQueryResult<string | Blob | undefined, Error>
  activeColumnIds?: string[]
}

const ReportModal: FC<ReportModalProps> = props => {
  const {
    title = 'Report Preview',
    isOpen,
    closeModal,
    searchParams,
    useReportQuery,
    reportQueryParams,
    queryObject = {},
    totalItems,
    activeColumnIds,
  } = props

  const { airportId } = useProfile()

  const computedParams = useMemo(
    () => ({ airportId, ...(reportQueryParams ? reportQueryParams : {}) }),
    [airportId, reportQueryParams],
  )

  const { data: reportPreview, isFetching: isReportPreviewFetching } = useReportQuery(
    computedParams,
    { isPreview: true, ...queryObject, ...searchParams, activeColumnIds },
    { enabled: isOpen, refetchOnWindowFocus: false },
  )

  const downloadPdfReport = useCallback((blob: any) => {
    downloadBlob({
      contentType: 'application/pdf',
      data: blob,
      fileName: 'report.pdf',
      includeTimestamp: true,
    })
  }, [])

  const { refetch: fetchPdf, isFetching: isReportPdfFetching } = useReportQuery(
    computedParams,
    { isPreview: false, ...queryObject, ...searchParams },
    { enabled: false, refetchOnWindowFocus: false, onSuccess: downloadPdfReport },
  )

  const [pageIndex, setPageIndex] = useState(0)

  const isReportPreviewReady = useMemo(
    () => reportPreview && !isReportPreviewFetching && typeof reportPreview === 'string',
    [reportPreview, isReportPreviewFetching],
  )

  const { content, styles } = useMemo(() => {
    if (isReportPreviewReady) {
      return iterateReactChildren(Parser(reportPreview as string))
    }
    return { content: [], styles: [] }
  }, [isReportPreviewReady, reportPreview])

  return (
    <Modal
      animation={false}
      onHide={closeModal}
      show={isOpen}
      dialogClassName="modal-dialog-lg"
      contentClassName="h-100"
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <div className="d-flex flex-column h-100">
        {isReportPreviewFetching && (
          <div className="h-auto">
            <div className="px-12 pt-6 text-center">Please wait while your report is being generated.</div>
            <div className="px-12 py-6">
              <Spinner />
            </div>
          </div>
        )}

        {isReportPreviewReady && <ReportPreviewRenderer pageIndex={pageIndex} content={content} styles={styles} />}
      </div>

      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <ReportPreviewPagination setPageIndex={setPageIndex} pageIndex={pageIndex} totalPages={content.length} />

          <div>
            <Button type="secondary" onClick={closeModal} className="mr-2">
              Cancel
            </Button>

            <Button
              onClick={() => fetchPdf()}
              disabled={!reportPreview || isReportPreviewFetching || isReportPdfFetching}
              loading={isReportPdfFetching}
            >
              Download All
            </Button>

            {totalItems && totalItems >= 9999 && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id={`tooltip-right`}>You can export maximum 9999 items</Tooltip>}
              >
                <i className={cn('bi bi-exclamation-triangle ml-2', s['warning-icon'])} />
              </OverlayTrigger>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ReportModal
