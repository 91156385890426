import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'
import { SearchReportType } from '../../components/report-modal/search-report-type'

type Params = {
  airportId: number
}

type QueryObject = {
  isPreview?: boolean
  searchReportType: SearchReportType
  entityState: string
  templateIds?: number[]
  createdBy?: string[]
  createdAtBegin?: string
  createdAtEnd?: string
  completedBy?: string[]
  completedAtBegin?: string
  completedAtEnd?: string
  searchText?: string
  searchIds?: number[]
  limit: number
  page: number
  sortBy?: string
  sortOrder?: string
}

const getChecklistsReportRequest = (params: Params, queryObject: QueryObject | undefined): any => {
  const { airportId } = params

  const { isPreview, searchReportType, ...data } = queryObject || {}

  const queryString = qs.stringify({ preview: isPreview, searchReportType }, { addQueryPrefix: true })

  return {
    method: 'post',
    responseType: isPreview ? 'text/html' : 'blob',
    url: `${config.baseUrl}/${airportId}/Checklists/RenderReport${queryString}`,
    data: data,
  }
}

const useChecklistsReportQuery = buildGetQuery<Params, QueryObject | undefined, Blob | string | undefined>({
  getRequest: getChecklistsReportRequest,
  getQueryKey: (params, queryObject) => ['checklists-report', params.airportId, queryObject?.isPreview || ''],
  defaultOptions: {
    retry: false,
  },
})

export default useChecklistsReportQuery
