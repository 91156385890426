// This function is adapted from https://stackoverflow.com/questions/27281405/group-by-object-ids-in-javascript
const groupByPropertyName = (values = [], propertyName) => {
  const groupedByPropertyName = values.reduce((results, value) => {
    ;(results[value[propertyName]] = results[value[propertyName]] || []).push(value)

    return results
  }, {})

  return groupedByPropertyName
}

export default groupByPropertyName
