export enum TagCategory {
  // eslint-disable-next-line
  ActivityLog = 1,
  // eslint-disable-next-line
  Location = 2,
  // eslint-disable-next-line
  WorkOrderLog = 3,
  // eslint-disable-next-line
  Facility = 4,
  // eslint-disable-next-line
  Condition = 5,
  // eslint-disable-next-line
  FAQ = 6,
}

export interface ITag {
  id: number
  airportId: number
  tagCategory: TagCategory
  name: string
  sortOrder: number
}
