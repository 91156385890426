import { FC, Dispatch, SetStateAction, useCallback } from 'react'
import { Button } from 'react-bootstrap'

type ReportPreviewPaginationProps = {
  setPageIndex: Dispatch<SetStateAction<number>>
  pageIndex: number
  totalPages: number
}

const ReportPreviewPagination: FC<ReportPreviewPaginationProps> = props => {
  const { pageIndex, setPageIndex, totalPages } = props

  const onPageIndexChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      // If the value is empty or a valid number, process further
      if (value === '' || /^[0-9]+$/.test(value)) {
        if (value === '') {
          setPageIndex(0) // Or whatever default value you'd like
          return
        }

        const newIndex = parseInt(value, 10) - 1
        if (newIndex >= 0 && newIndex < totalPages) {
          setPageIndex(newIndex)
        }
      }
    },
    [setPageIndex, totalPages],
  )

  return (
    <div className="d-flex align-items-center">
      <Button
        variant="secondary"
        onClick={() => setPageIndex(pageIndex => Math.max(pageIndex - 1, 0))}
        disabled={pageIndex === 0}
        size="sm"
      >
        Previous
      </Button>

      <Button
        className="ml-2"
        variant="secondary"
        onClick={() => setPageIndex(prev => Math.min(prev + 1, totalPages - 1))}
        disabled={pageIndex === totalPages - 1}
        size="sm"
      >
        Next
      </Button>

      <span className="ml-6">Report</span>

      <input
        className="ml-2"
        type="text"
        value={pageIndex + 1}
        onChange={onPageIndexChange}
        style={{ width: '50px', textAlign: 'center' }}
      />

      <span className="ml-2">of {totalPages}</span>
    </div>
  )
}

export default ReportPreviewPagination
