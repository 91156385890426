import config from '../../config'

import { IGetUploadLocationMutation } from './types'

const getUploadLocationMutationRequest: IGetUploadLocationMutation = params => {
  const { airportId } = params

  return {
    method: 'post',
    url: `${config.baseUrl}/${airportId}/Locations/Upload`,
  }
}

export default getUploadLocationMutationRequest
