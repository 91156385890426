import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { ISafetyReportCustomization, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

const getSafetyReportCustomizationRequest = (params: Params): IGetRequestObject => ({
  method: 'get',
  url: `${config.baseUrl}/SafetyReportCustomization/${params.airportId}`,
})

const useSafetyReportCustomizationQueryBase = buildGetQuery<Params, undefined, ISafetyReportCustomization | undefined>({
  getRequest: getSafetyReportCustomizationRequest,
  getQueryKey: params => ['safety-report-customization', params.airportId],
})

const useSafetyReportCustomizationQuery = (
  params: Params,
  options?: QueryObserverOptions<ISafetyReportCustomization | undefined, Error>,
) => useSafetyReportCustomizationQueryBase(params, undefined, options)

export default useSafetyReportCustomizationQuery
