import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateLocationMutationRequest from './get-update-location-mutation-request'

import { ILocation, ILocationUpsert } from '../../interfaces'
import { IUseUpdateLocationMutation, IUpdateLocation } from './types'

const useUpdateLocationMutation: IUseUpdateLocationMutation = (params, options = {}) => {
  const request = useMemo(() => getUpdateLocationMutationRequest(params), [params])

  const updateLocation: IUpdateLocation = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ILocation, Error, ILocationUpsert>({ mutationFn: updateLocation, ...options })

  return response
}

export default useUpdateLocationMutation
