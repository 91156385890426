import { FC, useRef, useEffect } from 'react'

import MyTrainingFormFields from './my-training-form-fields'

import { IMyTrainingFormProps } from './my-training-form-types'

const MyTrainingForm: FC<IMyTrainingFormProps> = props => {
  const { form, handleSubmit, submitting, formName, disabled, onChange, values, valid, setForm } = props

  const prevValuesRef = useRef(values)
  useEffect(() => {
    if (typeof onChange === 'function') {
      if (values !== prevValuesRef.current) {
        onChange(values, valid)
        prevValuesRef.current = values
      }
    }
  }, [onChange, valid, values])

  useEffect(() => {
    setForm(form)
    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <MyTrainingFormFields submitting={submitting} disabled={disabled} />
    </form>
  )
}

export default MyTrainingForm
