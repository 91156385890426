import React, { FC, MouseEvent, useMemo } from 'react'
import cn from 'classnames'

import s from './button.module.scss'

const MAP_STYLES_BY_TYPE: any = {
  primary: s['button-primary'],
  'primary-icon': s['button-primary-icon'],
  secondary: s['button-secondary'],
  'secondary-icon': s['button-secondary-icon'],
  ghost: s['button-ghost'],
  'ghost-icon': s['button-ghost-icon'],
  'primary-outlined': s['button-primary-outlined'],
  'secondary-outlined': s['button-secondary-outlined'],
  'dark-link': s['button-dark-link'],
  link: s['button-link'],
  'icon-outlined': s['button-outlined-icon'],
}

export type ButtonProps = {
  className?: string
  children?: React.ReactNode
  type?:
    | 'primary'
    | 'primary-icon'
    | 'secondary-outlined'
    | 'secondary'
    | 'secondary-icon'
    | 'ghost'
    | 'ghost-icon'
    | 'primary-outlined'
    | 'dark-link'
    | 'link'
    | 'icon-outlined'
  addonBefore?: any
  addonAfter?: any
  buttonType?: 'button' | 'submit' | 'reset' | undefined
  loading?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  componentRef?: any
  disabled?: boolean
  form?: string
  dataTestId?: string
}

const Button: FC<ButtonProps> = props => {
  const {
    children,
    className,
    type = 'primary',
    addonBefore,
    addonAfter,
    buttonType,
    loading,
    componentRef,
    dataTestId,
    ...restProps
  } = props

  const buttonClassNames = cn(s.button, MAP_STYLES_BY_TYPE[type], className)

  const content = useMemo(() => {
    const iconTypes = ['primary-icon', 'secondary-icon', 'ghost-icon', 'icon-outlined']

    if (loading) {
      if (iconTypes.includes(type)) {
        return <span className="spinner-border spinner-border-sm" />
      } else {
        return (
          <>
            {children}
            {<span className={cn(s['button-loader'], 'spinner-border spinner-border-sm')} />}
          </>
        )
      }
    }
    return children
  }, [children, loading, type])

  return (
    <button className={buttonClassNames} type={buttonType} {...restProps} ref={componentRef} data-test-id={dataTestId}>
      {addonBefore && addonBefore(props)}

      {content}

      {addonAfter && addonAfter(props)}
    </button>
  )
}

Button.defaultProps = {
  loading: false,
}

export default Button
