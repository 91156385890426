import { FC, useMemo } from 'react'
import { Carousel } from 'react-bootstrap'

import { isImage } from '../../../util/file'
import { IDocument } from '../../../interfaces'

// @ts-ignore
import s from './documents-carousel.module.scss'

type DocumentsCarouselProps = {
  documents: IDocument[]
  setCurrentDocument: (document: IDocument) => void
  currentDocument: IDocument
}

const DocumentsCarousel: FC<DocumentsCarouselProps> = props => {
  const { documents, currentDocument, setCurrentDocument } = props

  const slideHandler = (carouselIndex: number) => setCurrentDocument(documents[carouselIndex])

  const activeCarouselIndex = useMemo(
    () => documents.findIndex(document => document.id === currentDocument.id),
    [documents, currentDocument.id],
  )

  return (
    <Carousel
      keyboard
      onSelect={slideHandler}
      activeIndex={activeCarouselIndex > -1 ? activeCarouselIndex : 0}
      wrap={false}
      interval={null}
      className="h-100"
    >
      {documents.map(document => (
        <Carousel.Item key={document.id} className={s['carousel-item']}>
          <div className={s['carousel-inner']}>
            {isImage(document.name) ? (
              <img className="slider-image" src={document.url} alt={document.name} />
            ) : (
              <span className="default-inner-content">Preview is not available for this file.</span>
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default DocumentsCarousel
