import useAirport from './use-airport'

import { IFeatureFlags } from '../interfaces'

const useFeatureFlags = () => {
  const airport = useAirport()
  const featureFlags = airport?.featureFlags || ({} as IFeatureFlags)

  return {
    imageryEnabled: !!featureFlags?.imageryEnabled,
    journalEnabled: !!featureFlags?.journalEnabled,
    notamsEnabled: !!featureFlags?.notamsEnabled,
    notificationsEnabled: !!featureFlags?.notificationsEnabled,
    savedLocationsEnabled: !!featureFlags?.savedLocationsEnabled,
    trainingEnabled: !!featureFlags?.trainingEnabled,
    checklistsEnabled: !!featureFlags?.checklistsEnabled,
    workOrderFilterUserDepartmentsEnabled: !!featureFlags?.workOrderFilterUserDepartmentsEnabled,
    safetyManagementEnabled: !!featureFlags?.safetyManagementEnabled,
    communityTemplatesEnabled: !!featureFlags?.communityTemplatesEnabled,
    scheduledSavedSearchesEnabled: !!featureFlags?.scheduledSavedSearchesEnabled,
  }
}

export default useFeatureFlags
