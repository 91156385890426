import { ISafetyConcernActionValues, ISafetyConcernActionUpsertValues } from './safety-concern-action-form-types'
import { ISafetyConcernAction, ISafetyConcernActionAddUpsert, IDocument } from '../../../../interfaces'

export const safetyConcernActionToSafetyConcernActionValues = (
  safetyConcernAction: ISafetyConcernAction,
): ISafetyConcernActionUpsertValues | undefined => {
  if (!safetyConcernAction) {
    return undefined
  }

  return {
    action: safetyConcernAction?.action,
    notes: safetyConcernAction?.notes,
    documents: safetyConcernAction?.documents,
  }
}

export const safetyConcernActionValuesToSafetyConcernActionAddUpsert = (
  safetyConcernActionValues: ISafetyConcernActionValues,
  payload: { safetyConcernId?: number; airportId: number },
): ISafetyConcernActionAddUpsert | undefined => {
  if (!safetyConcernActionValues || !payload?.safetyConcernId) {
    return undefined
  }

  return {
    id: 0,
    airportId: payload?.airportId,
    safetyConcernId: payload?.safetyConcernId,
    action: safetyConcernActionValues?.action,
    notes: safetyConcernActionValues?.notes,
    documentIds: (safetyConcernActionValues?.documents || [])?.map((document: IDocument) => document.id),
  }
}

export const safetyConcernActionValuesToSafetyConcernActionUpdateUpsert = (
  safetyConcernActionValues: ISafetyConcernActionValues,
  safetyConcernAction?: ISafetyConcernAction,
): ISafetyConcernActionAddUpsert | undefined => {
  if (!safetyConcernActionValues || !safetyConcernAction) {
    return undefined
  }

  return {
    ...safetyConcernAction,
    action: safetyConcernActionValues?.action,
    notes: safetyConcernActionValues?.notes,
    documentIds: (safetyConcernActionValues?.documents || [])?.map((document: IDocument) => document.id),
  }
}
