import React from 'react'
import cn from 'classnames'
import { string, bool } from 'prop-types'
import { Info } from '../../info'

const withFieldDecorator = Component => props => {
  const { labelProps = {}, subLabelProps = {}, errorProps = {}, componentProps = {} } = props

  const { wrapperClassName, tip, ...restComponentProps } = componentProps

  const labelFontWeightClass = labelProps.normalFontWeight ? 'font-weight-normal' : 'font-weight-bold'
  const labelStyles = labelProps.style ? labelProps.style : {}
  const fieldDecoratorClassName = cn('form-group', wrapperClassName)
  const labelClassName = cn('form-label', labelFontWeightClass, labelProps.labelClassName)
  const errorClassName = cn('invalid-feedback d-block', labelProps.errorClassName)

  return (
    <div className={fieldDecoratorClassName}>
      {labelProps.label && <label className={labelClassName} style={labelStyles}>{labelProps.label}</label>}
      {labelProps.info && <Info className="ml-2" info={labelProps.info}/>}
      {subLabelProps.subLabel && <label className="text-muted pl-2">- {subLabelProps.subLabel}</label>}

      {Component && <Component {...restComponentProps} />}

      {errorProps.isInvalid && <span className={errorClassName}>{errorProps.error}</span>}
    </div>
  )
}

export const fieldDecoratorLabelProps = {
  label: string,
}

export const fieldDecoratorErrorProps = {
  error: string,
  isInvalid: bool,
}

export default withFieldDecorator
