import config from '../../config'
import { buildGetQuery } from '../../util'

import { IWorkOrder, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  id?: number
}

const getWorkOrderByIdQueryRequest = (params: Params): IGetRequestObject => {
  const { airportId, id } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WorkOrders/${id}`,
  }
}

const useWorkOrderByIdQuery = buildGetQuery<Params, undefined, IWorkOrder | undefined>({
  getRequest: getWorkOrderByIdQueryRequest,
  getQueryKey: params => ['work-order-by-id', params.id],
  defaultOptions: { initialData: undefined },
})

export default useWorkOrderByIdQuery
