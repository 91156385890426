import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getSortPrioritiesMutationRequest from './get-sort-priorities-mutation-request'

import { IUseSortPrioritiesMutation, ISortPriorities } from './use-sort-priorities-mutation-types'

const useSortPrioritiesMutation: IUseSortPrioritiesMutation = (params, options = {}) => {
  const request = useMemo(() => getSortPrioritiesMutationRequest(params), [params])

  const sortPriorities: ISortPriorities = useCallback(
    async (data: any) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<any, Error>({
    mutationFn: sortPriorities,
    ...options,
  })

  return response
}

export default useSortPrioritiesMutation
