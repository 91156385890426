import * as get from 'lodash.get'

const set = (object, path, value) => {
  let schema = object

  const pList = path.split('.')

  for (let i = 0; i < pList.length - 1; i++) {
    const elem = pList[i]

    if (!schema[elem]) {
      schema[elem] = {}
    }

    schema = schema[elem]
  }

  schema[pList[pList.length - 1]] = value
}

export const mergeValues = (entity, fields, values) => {
  const cloneEntity = JSON.parse(JSON.stringify(entity))

  fields.forEach(field => {
    set(cloneEntity, field, get(values, field))
  })

  return cloneEntity
}
