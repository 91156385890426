import { FC, useMemo } from 'react'
import { Menu } from '../../../components'

import { useNavigationRoutes } from '../../../hooks'

import config from '../../../config'

import sharedDrawerStyles from '../drawer-body.module.scss'
import s from './navigation-menu-drawer-body.module.scss'

type NavigationMenuDrawerBodyProps = {
  onClose: () => void
}

const NavigationMenuDrawerBody: FC<NavigationMenuDrawerBodyProps> = props => {
  const { onClose } = props

  const routes = useNavigationRoutes(config.routes)

  const menuOptions = useMemo(
    () => (routes || []).map(route => ({ label: route.label, to: route.url, menuItemClassName: s['menu-item'] })),
    [routes],
  )

  return (
    <div className={sharedDrawerStyles['container']}>
      <Menu options={menuOptions} onNavigateCb={onClose} />
    </div>
  )
}

export default NavigationMenuDrawerBody
