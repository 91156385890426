import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getCompleteChecklistMutationRequest from './get-complete-checklist-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'

const useCompleteChecklistMutation: IUseUpdateMutation<undefined, undefined> = (params, options = {}) => {
  const request = useMemo(() => getCompleteChecklistMutationRequest(params), [params])

  const completeChecklist: IUpdateFn<undefined, undefined> = useCallback(
    async () => axios({ ...request }).then(res => res.data),
    [request],
  )

  const response = useMutation<undefined, Error, undefined>({
    mutationFn: completeChecklist,
    ...options,
  })

  return response
}

export default useCompleteChecklistMutation
