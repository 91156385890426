import { FC, useMemo, ChangeEvent, CSSProperties, forwardRef, useEffect, ReactNode, MouseEventHandler } from 'react'
import cn from 'classnames'

import s from './checkbox.module.scss'

type CheckboxProps = {
  className?: string
  checked?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClick?: MouseEventHandler<HTMLInputElement>
  style?: CSSProperties
  label?: string
  text?: string
  indeterminate?: boolean
  disabled?: boolean
  renderLabel?: () => ReactNode
  labelDataTestId?: string
}

const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    className,
    checked,
    indeterminate,
    label,
    renderLabel,
    text,
    disabled,
    onChange,
    style = {},
    labelDataTestId,
    onClick,
  } = props

  const checkboxClassName = useMemo(
    () => cn(s.checkbox, { [s['checkbox-disabled']]: disabled }, className),
    [className, disabled],
  )

  useEffect(() => {
    if (ref) {
      // @ts-ignore
      ref.indeterminate = indeterminate
    }
  }, [ref, indeterminate])

  return (
    <label className={checkboxClassName} style={style}>
      <div
        className={cn(s['checkbox-input-wrapper'], { [s['checkbox-input-wrapper-checked']]: checked || indeterminate })}
      >
        <input
          type="checkbox"
          className={cn('form-control', s['checkbox-input'])}
          ref={ref}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
        />

        {checked && <i className={cn('bi bi-check2', s['checkbox-icon'])} />}

        {indeterminate && <i className={cn('bi bi-dash', s['checkbox-icon'])} />}
      </div>

      {renderLabel
        ? renderLabel()
        : (label || text) && (
            <span className={s['checkbox-label']} data-test-id={labelDataTestId}>
              {label || text}
            </span>
          )}
    </label>
  )
})

export default Checkbox
