import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'

import getUpdateDiscrepancyMutationRequest, {
  IGetUpdateDiscrepancyMutationRequestParams,
} from './get-update-discrepancy-mutation-request'

import { IUpdateFn, ISafetyItem, IDiscrepancyUpdateUpsert } from '../../interfaces'

interface IUseUpdateDiscrepancyMutation {
  (
    params: IGetUpdateDiscrepancyMutationRequestParams,
    options?: UseMutationOptions<ISafetyItem, Error, IDiscrepancyUpdateUpsert>,
  ): UseMutationResult<ISafetyItem, Error, IDiscrepancyUpdateUpsert>
}

const useUpdateDiscrepancyMutation: IUseUpdateDiscrepancyMutation = (params, options = {}) => {
  const request = useMemo(() => getUpdateDiscrepancyMutationRequest(params), [params])

  const updateDiscrepancy: IUpdateFn<IDiscrepancyUpdateUpsert, ISafetyItem> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<ISafetyItem, Error, any>({
    mutationFn: updateDiscrepancy,
    ...options,
  })

  return response
}

export default useUpdateDiscrepancyMutation
