import { IGeoJson } from '../interfaces'
import { IGetGeoJsonCenter } from './get-geo-json-center-types'
import { calculatePolygonCenter } from './calculate-polygon-center'

export const getGeoJsonCenter: IGetGeoJsonCenter = (geoJson: IGeoJson) => {
  let lat: number
  let lng: number

  if (geoJson.geometry.type === 'Point') {
    lat = geoJson.geometry.coordinates[1] as number
    lng = geoJson.geometry.coordinates[0] as number
  } else {
    const center = calculatePolygonCenter(geoJson.geometry.coordinates[0])

    lat = center.latitude as number
    lng = center.longitude as number
  }

  return { lat, lng }
}
