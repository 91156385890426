import { SavedSearchFilterType } from '../../interfaces'

const savedSearchFilterTypesOptions = [
  { value: SavedSearchFilterType.Checklist, label: 'Checklist' },
  { value: SavedSearchFilterType.WorkOrder, label: 'Work Order' },
  // { value: SavedSearchFilterType.Inspection, label: 'Inspection' },
  // { value: SavedSearchFilterType.Discrepancy, label: 'Discrepancy' },
  { value: SavedSearchFilterType.ActivityLogs, label: 'Journal' },
  // { value: SavedSearchFilterType.Notification, label: 'Activity' },
  // { value: SavedSearchFilterType.Notams, label: 'Notams' },
  { value: SavedSearchFilterType.Wildlife, label: 'WildLife' },
]

export default savedSearchFilterTypesOptions
