import { timeSpanToDateRange } from '../../../../util'

import { ISavedSearchFilters, ITimeSpan, IShortChecklistSearchFilter } from '../../../../interfaces'
import { IChecklistFilterValues, IChecklistFilterUpsertValues } from './checklist-filter-form-types'

export const objectToChecklistFilterValues = (object?: { [key: string]: any }): IChecklistFilterUpsertValues => ({
  dateRange: { 0: object?.createdAtBegin || '', 1: object?.createdAtEnd || '' },
  createdBy: object?.createdBy || [],
  templateIds: object?.templateIds || [],
  searchText: object?.searchText || '',
})

export const savedSearchFilterToSavedChecklistFilterValues = (
  savedSearchFilter: ISavedSearchFilters | undefined,
  dynamicOptions?: { startedAtFromTimeSpan?: ITimeSpan | null },
): IChecklistFilterUpsertValues | undefined => {
  if (!savedSearchFilter) {
    return savedSearchFilter
  }

  let checklistFilterObject
  try {
    checklistFilterObject = JSON.parse(savedSearchFilter.filterJson)
  } catch (err) {
    checklistFilterObject = {}
  }

  const checklistFilterValues = objectToChecklistFilterValues(checklistFilterObject)

  if (dynamicOptions?.startedAtFromTimeSpan) {
    checklistFilterValues.dateRange = timeSpanToDateRange(dynamicOptions.startedAtFromTimeSpan)
  }

  return checklistFilterValues
}

export const savedChecklistFilterValuesToSavedSearchFilterAddUpsert = (
  savedChecklistFilterValues: IChecklistFilterValues | undefined,
): IShortChecklistSearchFilter => ({
  createdAtBegin: savedChecklistFilterValues?.dateRange?.[0] || '',
  createdAtEnd: savedChecklistFilterValues?.dateRange?.[1] || '',
  createdBy: savedChecklistFilterValues?.createdBy || [],
  templateIds: savedChecklistFilterValues?.templateIds || [],
  searchText: savedChecklistFilterValues?.searchText || '',
})

export const savedChecklistFilterValuesToSavedSearchFilterUpdateUpsert = (
  savedChecklistFilterValues: IChecklistFilterValues | undefined,
): IShortChecklistSearchFilter => ({
  createdAtBegin: savedChecklistFilterValues?.dateRange?.[0] || '',
  createdAtEnd: savedChecklistFilterValues?.dateRange?.[1] || '',
  createdBy: savedChecklistFilterValues?.createdBy || [],
  templateIds: savedChecklistFilterValues?.templateIds || [],
  searchText: savedChecklistFilterValues?.searchText || '',
})
