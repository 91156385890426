import { FC, Fragment, useContext } from 'react'
import { TableInstance } from 'react-table'
import cn from 'classnames'

import ChecklistHeaderCell from '../checklist-header-cell/checklist-header-cell'
import { ChecklistContext } from '../checklist-context/checklist-context'

import { ChecklistRow, ChecklistClassNames } from '../checklist-types'

import s from '../checklist.module.scss'

type ChecklistHeaderProps = {
  tableInstance: TableInstance<ChecklistRow>
  classNames?: ChecklistClassNames
}

const ChecklistHeader: FC<ChecklistHeaderProps> = props => {
  const { isConfigurable } = useContext(ChecklistContext)

  const { tableInstance, classNames } = props

  const { headerGroups } = tableInstance

  return (
    <thead className={cn(s['thead'], classNames?.theadClassName)}>
      {headerGroups.map((headerGroup, index) => (
        <Fragment key={index}>
          <tr className={cn(s['tr'], classNames?.trClassName)}>
            {isConfigurable && <th className={cn('py-1', s['drag-icon-cell'])}></th>}

            {headerGroup.headers.map((header, index) => {
              const isFullWidthCell = headerGroup.headers.length === 1 && headerGroup.headers[0].columnType !== 'text'

              const className = cn(
                'py-1',
                {
                  [s['cell-string']]: !isFullWidthCell && header.columnType === 'string',
                  [s['checkbox-cell']]: !isFullWidthCell && header.columnType === 'checkbox',
                  [s['date-cell']]: !isFullWidthCell && header.columnType === 'date',
                  [s['dropdown-cell']]: !isFullWidthCell && header.columnType === 'dropdown',
                },
                classNames?.thClassName,
              )

              return (
                <Fragment key={header.id}>
                  <th className={className} id={header.id}>
                    <ChecklistHeaderCell header={header} columnIndex={index} />
                  </th>
                </Fragment>
              )
            })}

            {isConfigurable && <th className={cn(s['actions-cell'], classNames?.thClassName)}>Actions</th>}
          </tr>
        </Fragment>
      ))}
    </thead>
  )
}

export default ChecklistHeader
