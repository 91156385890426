import qs from 'qs'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { IGetRequestObject } from '../../interfaces'
import { IWildlifeSighting } from '../../interfaces/services/wildlife-sighting'

type Params = {
  airportId: number
}

type QueryObject = {
  ids?: number[] | string[]
}

const getWildlifeSightingsByIdsRequest = (params: Params, queryObject: QueryObject | undefined): IGetRequestObject => {
  const { airportId } = params

  const queryString = qs.stringify(queryObject, { addQueryPrefix: true })

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/WildlifeSightings/GetByIds${queryString}`,
  }
}

const useWildlifeSightingsByIdsQuery = buildGetQuery<Params, QueryObject | undefined, IWildlifeSighting[] | undefined>({
  getRequest: getWildlifeSightingsByIdsRequest,
  getQueryKey: (params, queryObject) => ['wildlife-sightings-by-ids', params.airportId, queryObject?.ids],
})

export default useWildlifeSightingsByIdsQuery
