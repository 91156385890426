import { useCallback, useRef, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Button } from '../../../../components/button'
import { useAppContext } from '../../../../app-context'
import { actions } from '../../../../actions'

const activeRoute = '/release-notes'

type ReleaseNotesButtonProps = {
  additionalOnClick: () => any
  showRedBubble: boolean
  className?: string
}

const ReleaseNotesButton: React.FC<ReleaseNotesButtonProps> = ({ additionalOnClick, showRedBubble, className }) => {
  const location = useLocation()

  const navigate = useNavigate()

  const { state, dispatch } = useAppContext()

  const releaseNotesInfo = state?.releaseNotesInfo

  const isRouteActive = useMemo(() => location.pathname?.includes(activeRoute), [location.pathname])

  const onReleaseNotesNavigate = useCallback(() => {
    if (!isRouteActive) {
      navigate('/release-notes')
    }

    if (releaseNotesInfo?.isReminderOpen) {
      dispatch(actions.releaseNotes.closeReleaseNotesReminder())
    }
  }, [navigate, releaseNotesInfo?.isReminderOpen, dispatch, isRouteActive])

  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button
        type="secondary"
        className={className}
        onClick={() => {
          onReleaseNotesNavigate()
          additionalOnClick()
        }}
        componentRef={buttonRef}
      >
        <i className="bi bi-newspaper" style={{ color: 'var(--gks-blue)' }} />
        <span className="ml-2">What's New?</span>
        {showRedBubble && (
          <sup className="ml-1">
            <span className="badge badge-pill bg-danger text-white" style={{minWidth: '17px'}}> ! </span>
          </sup>
        )}
      </Button>
    </>
  )
}

ReleaseNotesButton.defaultProps = {
  additionalOnClick: () => {},
}

export default ReleaseNotesButton
