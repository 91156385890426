import { FC, useMemo } from 'react'
import cn from 'classnames'

import DropdownButton from '../../dropdown-button/dropdown-button'
import { IListItemProps } from '../../list'

import { formatDateString } from '../../../util'

import { ISafetyConcernAction } from '../../../interfaces'

import s from '../card.module.scss'

type SafetyConcernActionCardProps = ISafetyConcernAction & IListItemProps<ISafetyConcernAction>

const SafetyConcernActionCard: FC<SafetyConcernActionCardProps> = props => {
  const { id, action, notes, lastUpdatedAt, lastUpdatedBy, componentRef, activeId, actionsButtonOptions } = props

  const title = useMemo(() => `#${id} ${action ? ` - ${action}` : ''}`, [id, action])

  const info = useMemo(() => {
    const formattedLastUpdatedAt = formatDateString(lastUpdatedAt)

    if (formattedLastUpdatedAt && lastUpdatedBy) {
      return `${formattedLastUpdatedAt} by ${lastUpdatedBy}`
    }

    return ''
  }, [lastUpdatedAt, lastUpdatedBy])

  return (
    <div className={cn('card card-custom border border-2 p-4', activeId === id && s['active-card'])} ref={componentRef}>
      <div className={cn(s['card-header'], 'card-header border-0 m-0 p-0')}>
        <div className="card-title font-weight-bold">{title}</div>

        {actionsButtonOptions && (
          <DropdownButton className={s['actions-button']} accessor={props} options={actionsButtonOptions} />
        )}
      </div>

      <div className="card-body p-0">
        {info && <div className="mt-2 text-truncate text-dark">{info}</div>}

        {notes && <div className="mt-2">{notes}</div>}
      </div>
    </div>
  )
}

export default SafetyConcernActionCard
