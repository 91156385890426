import config from '../config'

const getUserFromStorage = () => {
  const { oidc } = config

  const key = `oidc.user:${oidc.authority}:${oidc.client_id}`

  if (!sessionStorage.getItem(key)) {
    return null
  }

  try {
    return JSON.parse(sessionStorage.getItem(key))
  } catch {
    return null
  }
}

export default getUserFromStorage
