import { FC } from 'react'
import cn from 'classnames'

import { Button } from '../../../../button'

import RFFFields from '../../../react-final-form-fields'

import { useModal } from '../../../../../hooks'

import s from './checklist-item.module.scss'

type ChecklistItemProps = {
  original: any
  name: string
  disabled: boolean
}

const ChecklistItem: FC<ChecklistItemProps> = props => {
  const { original, name, disabled } = props

  const { isOpen: isDescriptionModalOpen, toggleModal: toggleDescriptionModal } = useModal()

  return (
    <>
      <div className={s['checklist-item']} key={original.id}>
        <RFFFields.Checkbox
          wrapperClassName="mb-0 d-flex align-items-center"
          name={`${name}.isComplete`}
          type="checkbox"
          disabled={disabled}
          renderLabel={() => <div className="p-5 ml-2">{original.name}</div>}
        />

        {original.description && (
          <Button buttonType="button" type="secondary-icon" onClick={toggleDescriptionModal}>
            <i className={cn('bi bi-eye-fill', isDescriptionModalOpen && s['eye-icon-active'])} />
          </Button>
        )}
      </div>

      {original.description && isDescriptionModalOpen && (
        <div className={s['checklist-item-description']}>{original.description}</div>
      )}
    </>
  )
}

export default ChecklistItem
