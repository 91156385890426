import { node } from 'prop-types'

import claimType from './claim-type'
import useAccessControl from './use-access-control'

const RequiresAnyClaim = ({ children, claims }) => {
  const { hasAccess } = useAccessControl()

  const allowed = claims.map(claim => hasAccess(claim)).some(x => x)

  return allowed ? children : null
}

RequiresAnyClaim.defaultProps = {
  claims: [],
}

RequiresAnyClaim.propTypes = {
  children: node.isRequired,
  claims: claimType.isRequired,
}

export default RequiresAnyClaim
