import { FC, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'
import ReminderForm from '../reminder-form/reminder-form'

import { useProfile } from '../../../../security'
import { getPlaceholderTextFromLabel, options, toOptions, capitalizeFirst } from '../../../../util'
import { validateRequired, validateMaxSymbols } from '../../../../util/validation'
import {
  useSavedSearchFiltersQuery,
  useNotificationGroupsQuery,
  useAuthUsersSelectListQuery,
} from '../../../../services'

import { ISavedSearchFilterReminderFormProps } from './saved-search-filter-reminder-form-types'

const validateMaxSymbols250 = validateMaxSymbols(250, 'Activity must not exceed 100 characters')

const nameLabel = 'Reminder Name'
const featureLabel = 'Feature'
const notificationGroupLabel = 'Department'
const userLabel = 'Users'
const savedSearchFilterLabel = 'Saved Search Filter'
const descriptionLabel = 'Description'

const SavedSearchFilterReminderForm: FC<ISavedSearchFilterReminderFormProps> = props => {
  const { handleSubmit, submitting, values, initialValues, formName, form } = props

  const { airportId } = useProfile()

  const { data: savedSearchFilters } = useSavedSearchFiltersQuery(
    { savedSearchFiltersType: values.featureId },
    { enabled: values.featureId !== undefined && values.featureId !== null },
  )

  const savedSearchFiltersOptions = useMemo(() => toOptions(savedSearchFilters), [savedSearchFilters])

  const isSavedSearchFilterCreated = useMemo(
    () => initialValues?.reminder?.reminderFrequencyId !== undefined,
    [initialValues?.reminder?.reminderFrequencyId],
  )

  const notificationGroupsQuery = useNotificationGroupsQuery({ airportId }, { includeDetail: true, activeOnly: true })

  const notificationGroupOptions = useMemo(
    () => toOptions(notificationGroupsQuery?.data),
    [notificationGroupsQuery?.data],
  )

  const authUsersQuery = useAuthUsersSelectListQuery({ airportId }, undefined)

  const authUsersOptions = useMemo(
    () => toOptions(authUsersQuery.data, { getLabel: user => capitalizeFirst(`${user.lastName}, ${user.firstName}`) }),
    [authUsersQuery.data],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.Input
        name="reminder.name"
        label={nameLabel}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        validate={validateRequired}
        disabled={submitting}
      />

      <Row>
        <Col>
          <RFFFields.Select
            name="featureId"
            label={featureLabel}
            placeholder={getPlaceholderTextFromLabel(featureLabel)}
            options={options.savedSearchFilterTypesOptions}
            validate={validateRequired}
            onChange={form?.mutators?.onFeatureChange}
            disabled={submitting || isSavedSearchFilterCreated || !form?.mutators?.onFeatureChange}
          />
        </Col>

        <Col>
          <RFFFields.Select
            name="savedSearchFilterId"
            label={savedSearchFilterLabel}
            placeholder={getPlaceholderTextFromLabel(savedSearchFilterLabel)}
            options={savedSearchFiltersOptions}
            validate={validateRequired}
            disabled={submitting || isSavedSearchFilterCreated || !values.featureId}
          />
        </Col>
      </Row>

      <RFFFields.Textarea
        name="reminder.description"
        label={descriptionLabel}
        placeholder={getPlaceholderTextFromLabel(descriptionLabel)}
        validate={validateMaxSymbols250}
        disabled={submitting}
      />

      <RFFFields.Select
        name="reminder.notificationGroupIds"
        label={notificationGroupLabel}
        placeholder={getPlaceholderTextFromLabel(notificationGroupLabel)}
        options={notificationGroupOptions}
        disabled={submitting || notificationGroupsQuery.isFetching}
        isMulti
      />

      <RFFFields.Select
        name="userIds"
        label={userLabel}
        placeholder={getPlaceholderTextFromLabel(userLabel)}
        options={authUsersOptions}
        disabled={submitting}
        isMulti
      />

      <ReminderForm
        values={values}
        submitting={submitting}
        initialValues={initialValues}
        isForceDisabled={isSavedSearchFilterCreated}
        disablePastDates={true}
      />

      <RFFFields.Switch name="reminder.isActive" label="Active" onLabel="yes" offLabel="no" disabled={submitting} />
    </form>
  )
}

export default SavedSearchFilterReminderForm
