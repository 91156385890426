import React, { useMemo } from 'react'
import { IMaskInput, IMask } from 'react-imask'
import cn from 'classnames'
import { bool, string, func, oneOfType, number } from 'prop-types'
import withAddons from '../adapters/with-addons'

import s from '../input/input.module.scss'

const dateBlocks = {
  DD: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 31,
    maxLength: 2,
  },
  MM: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 12,
    maxLength: 2,
  },
  YYYY: {
    mask: IMask.MaskedRange,
    from: 1000,
    to: 3000,
    maxLength: 4,
  },
}

const timeBlocks = {
  HH: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 12,
    maxLength: 2,
  },
  mm: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 59,
    maxLength: 2,
  },
  aa: {
    mask: IMask.MaskedEnum,
    enum: ['AM', 'PM', 'am', 'pm'],
  },
}

const dateMask = 'MM/DD/YYYY'

const timeMask = 'HH:mm aa'

const phoneMask = '000-000-0000'

const getSettingsByType = type => {
  switch (type) {
    case 'date-and-time': {
      return { mask: `${dateMask} ${timeMask}`, blocks: { ...dateBlocks, ...timeBlocks } }
    }
    case 'date': {
      return { mask: dateMask, blocks: dateBlocks }
    }
    case 'phone': {
      return {
        mask: phoneMask,
      }
    }
    case 'number': {
      return {
        mask: Number,
      }
    }
    case 'hours': {
      return {
        mask: IMask.MaskedRange,
        from: 0,
        to: 999999999999,
        maxLength: 2,
      }
    }
    case 'minutes': {
      return {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
      }
    }
    case 'currency': {
      return {
        mask: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,2})?$/,
      }
    }
    default: {
      return { mask: undefined, blocks: undefined }
    }
  }
}

const MaskedInput = props => {
  const { isInvalid, type, componentRef, onChange, isViewMode, disabled, ...restProps } = props

  const inputClassNames = cn('form-control', {
    'border-danger': isInvalid,
    [s['input-view']]: isViewMode,
  })

  const settings = useMemo(() => getSettingsByType(type), [type])

  return (
    <IMaskInput
      {...settings}
      className={inputClassNames}
      ref={componentRef}
      onAccept={onChange}
      overwrite
      autofix
      lazy
      disabled={disabled || isViewMode}
      {...restProps}
    />
  )
}

MaskedInput.propTypes = {
  isInvalid: bool,
  value: oneOfType([string, number]),
  onChange: func,
  name: string,
  className: string,
  isViewMode: bool,
  disabled: bool,
  type: string,
}

export default withAddons(MaskedInput)
