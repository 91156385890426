import { Drawer } from '../../../components/drawer'
import ProfileDrawerHeader from './profile-drawer-header'
import ProfileDrawerBody from './profile-drawer-body'

import { useAppContext } from '../../../app-context'
import { actions } from '../../../actions'

const ProfileDrawer = () => {
  const { state, dispatch } = useAppContext()

  return (
    <Drawer
      isOpen={state.isProfileDrawerOpen}
      onClose={() => dispatch(actions.profileDrawer.closeProfileDrawer())}
      title={
        <ProfileDrawerHeader
          editProfileClick={() => {}}
          onClose={() => dispatch(actions.profileDrawer.closeProfileDrawer())}
        />
      }
      body={<ProfileDrawerBody />}
    />
  )
}

export default ProfileDrawer
