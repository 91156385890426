import { FC, useMemo } from 'react'

import calculatePagedRecordRange from '../../table/calculate-paged-record-range'
import { Select } from '../../form/select'

import s from './pagination-page-size-selector.module.scss'

const pageSizeOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
]

type TablePaginationPageSizeSelectorProps = {
  pageIndex: number
  pageSize: number
  setPageSize: any
  totalItemsCount: number
}

const TablePaginationPageSizeSelector: FC<TablePaginationPageSizeSelectorProps> = props => {
  const { pageIndex, pageSize, setPageSize, totalItemsCount } = props

  const hint = useMemo(() => {
    if (totalItemsCount === 0) {
      return 'No records to display'
    }

    const range = calculatePagedRecordRange(pageIndex, pageSize, totalItemsCount)

    return `Displaying ${range.from} to ${range.to} of ${totalItemsCount} records`
  }, [pageIndex, pageSize, totalItemsCount])

  return (
    <div className={s['page-size']}>
      <div className={s['page-size-select-wrapper']}>
        <Select
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          onChange={setPageSize}
          value={pageSize}
          options={pageSizeOptions}
        />
      </div>

      <div className="h-100 mb-2">
        <div className={s['page-size-hint']}>{hint}</div>
      </div>
    </div>
  )
}

export default TablePaginationPageSizeSelector
