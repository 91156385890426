import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDeleteTrainingMutationRequest from './get-delete-training-session-mutation-request'

import { IUseDeleteMutation, IDeleteFn } from '../../interfaces'
import { ITraining } from '../../interfaces'

const useDeleteTrainingSessionMutation: IUseDeleteMutation<ITraining, number> = (params, options = {}) => {
  const request = useMemo(() => getDeleteTrainingMutationRequest(params), [params])

  const deleteTrainingSession: IDeleteFn<ITraining> = useCallback(
    async id => axios({ ...request, url: request.url + id }).then(res => res.data),
    [request],
  )

  const response = useMutation<ITraining, Error, any>({ mutationFn: deleteTrainingSession, ...options })

  return response
}

export default useDeleteTrainingSessionMutation
