export const ADMIN_TAB_NAMES = {
  GENERAL_SETTINGS: 'General',
  USER_MANAGEMENT: 'User Management',
  INSPECTION_MANAGEMENT: 'Inspections',
  WORK_ORDER_MANAGEMENT: 'Work Orders',
  CHECKLIST_MANAGEMENT: 'Checklists',
  TAGS_LABELS_MANAGEMENT: 'Labels',
  SAVED_LOCATION_MANAGEMENT: 'Locations',
  TRAINING: 'Training',
  SAFETY_CONCERN: 'Safety Concern',
  SAVED_SEARCHES: 'Saved Searches',
}

export const REMINDER_GRACE_PERIOD_TOOLTIP = 'The number of days after the reminder that something is considered late.'
