import React from 'react'
import { Row, Col } from 'react-bootstrap'

import RFFFields from '../../react-final-form-fields'

import { nameofFactory, getPlaceholderTextFromLabel } from '../../../../util'
import useDiscrepancyFilterQueries from './use-discrepancy-filter-queries'

import { IDiscrepancyFilterFormFiledsProps, IDiscrepancyFilterValues } from './discrepancy-filter-form-types'

const nameof = nameofFactory<IDiscrepancyFilterValues>()

const dateRangeLabel = 'Discrepancy Date Range'
const inspectionCategoryLabel = 'Inspection Category'
const inspectorLabel = 'Inspector'
const inspectionTypeLabel = 'Inspection Type'
const facilityLabel = 'Facility'
const conditionLabel = 'Condition'
const locationLabel = 'Location'
const searchTextLabel = 'Text Search'
const responseTypeLabel = 'Response Type'

const DiscrepancyFilterFormFields: React.FC<IDiscrepancyFilterFormFiledsProps> = props => {
  const { disabled, isDateRangeDisabled, dateRangeInfo, values } = props

  const {
    authUsersQuery,
    authUsersOptions,

    inspectionCategoriesQuery,
    inspectionCategoriesOptions,

    inspectionTypesQuery,
    inspectionTypesOptions,

    facilitiesQuery,
    facilitiesOptions,
    conditionsOptions,

    locationsOptions,
    responseTypeOptions,
  } = useDiscrepancyFilterQueries(values)

  return (
    <>
      <Row>
        <Col>
          <RFFFields.DateRangePicker
            name={nameof('dateRange')}
            label={dateRangeLabel}
            placeholderText={getPlaceholderTextFromLabel(dateRangeLabel)}
            disabled={disabled || isDateRangeDisabled}
            info={dateRangeInfo}
            isDateOnly
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('inspectionCategoryIds')}
            label={inspectionCategoryLabel}
            placeholder={getPlaceholderTextFromLabel(inspectionCategoryLabel)}
            options={inspectionCategoriesOptions}
            disabled={disabled || inspectionCategoriesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('inspectorIds')}
            label={inspectorLabel}
            placeholder={getPlaceholderTextFromLabel(inspectorLabel)}
            options={authUsersOptions}
            disabled={disabled || authUsersQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('inspectionTypeIds')}
            label={inspectionTypeLabel}
            placeholder={getPlaceholderTextFromLabel(inspectionTypeLabel)}
            options={inspectionTypesOptions}
            disabled={disabled || inspectionTypesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('facilityIds')}
            label={facilityLabel}
            placeholder={getPlaceholderTextFromLabel(facilityLabel)}
            options={facilitiesOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('conditionIds')}
            label={conditionLabel}
            placeholder={getPlaceholderTextFromLabel(conditionLabel)}
            options={conditionsOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.Select
            name={nameof('locationIds')}
            label={locationLabel}
            placeholder={getPlaceholderTextFromLabel(locationLabel)}
            options={locationsOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isMulti
          />
        </Col>

        <Col>
          <RFFFields.Select
            name={nameof('responseId')}
            label={responseTypeLabel}
            placeholder={getPlaceholderTextFromLabel(responseTypeLabel)}
            options={responseTypeOptions}
            disabled={disabled || facilitiesQuery.isFetching}
            isClearable
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RFFFields.SearchInput
            name={nameof('searchText')}
            label={searchTextLabel}
            placeholder={getPlaceholderTextFromLabel(searchTextLabel)}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  )
}

export default DiscrepancyFilterFormFields
