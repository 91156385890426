import axios from 'axios'

import config from '../../config'

const deleteActivityLog = (params = {}) => {
  const { airportId, id } = params

  const url = `${config.baseUrl}/${airportId}/ActivityLogs/${id}`
  return axios.delete(url)
}

export default deleteActivityLog
