import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import SafetyItemPopUpContent from '../safety-item-pop-up-content'
import WildlifePopUpContent from '../wildlife-pop-up-content'

import { useProfile } from '../../../security'
import { useWildlifeSightingsByIdsQuery, useSafetyItemByIdQuery } from '../../../services'

type SafetyItemPopupProps = {
  safetyItemId?: number
}

const SafetyItemPopup: FC<SafetyItemPopupProps> = props => {
  const { safetyItemId } = props

  const navigate = useNavigate()

  const { airportId } = useProfile()

  const safetyItemQuery = useSafetyItemByIdQuery({ airportId, id: Number(safetyItemId) }, undefined, {
    enabled: safetyItemId !== undefined && safetyItemId !== null,
  })

  const safetyItem = useMemo(() => safetyItemQuery?.data, [safetyItemQuery])

  const linkedWorkOrderId = useMemo(() => safetyItem?.linkedWorkOrderId, [safetyItem?.linkedWorkOrderId])

  const linkedInspectionId = useMemo(() => safetyItem?.linkedInspectionId, [safetyItem?.linkedInspectionId])

  const linkedWildlifeSightingId = useMemo(
    () => safetyItem?.linkedWildlifeSightingId,
    [safetyItem?.linkedWildlifeSightingId],
  )

  const { data: wildlifeSightings } = useWildlifeSightingsByIdsQuery(
    { airportId },
    { ids: linkedWildlifeSightingId ? [linkedWildlifeSightingId] : [] },
    {
      enabled: linkedWildlifeSightingId !== undefined && linkedWildlifeSightingId !== null,
    },
  )

  const wildlifeSightingsItem = useMemo(() => wildlifeSightings?.[0], [wildlifeSightings])

  return (
    <div>
      {linkedInspectionId && (
        <div className="border-bottom text-center w-100 mb-2">
          <button
            className="btn btn-link p-0 text-decoration-none"
            onClick={() => navigate(`/inspections/${linkedInspectionId}`)}
          >
            <h5>INSP #{linkedInspectionId}</h5>
          </button>
        </div>
      )}

      {linkedWorkOrderId && (
        <div className="border-bottom text-center w-100 mb-2">
          <button
            className="btn btn-link p-0 text-decoration-none"
            onClick={() => navigate(`/work-orders/${linkedWorkOrderId}`)}
          >
            <h5>WO #{linkedWorkOrderId}</h5>
          </button>
        </div>
      )}

      <SafetyItemPopUpContent
        safetyItem={safetyItem}
        isStatusVisible={Boolean(!safetyItem?.isDiscrepancy || linkedWorkOrderId)}
      />

      {wildlifeSightingsItem && <WildlifePopUpContent wildlifeSightings={wildlifeSightingsItem} />}
    </div>
  )
}

export default SafetyItemPopup
