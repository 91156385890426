import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import config from '../../config'

import {
  IGetPutRequest,
  IUseUpdateMutation,
  IUpdateFn,
  ISafetyConcernStatus,
  ISafetyConcernStatusUpdateUpsert,
} from '../../interfaces'

const getUpdateSafetyConcernStatusMutationRequest: IGetPutRequest = () => ({
  method: 'put',
  url: `${config.baseUrl}/SafetyConcernStatuses`,
})

const useUpdateSafetyConcernStatusMutation: IUseUpdateMutation<ISafetyConcernStatus, ISafetyConcernStatusUpdateUpsert> =
  (params, options = {}) => {
    const request = useMemo(() => getUpdateSafetyConcernStatusMutationRequest(params), [params])

    const updateSafetyConcernStatus: IUpdateFn<ISafetyConcernStatusUpdateUpsert, ISafetyConcernStatus> = useCallback(
      async data => axios({ ...request, data }).then(res => res.data),
      [request],
    )

    const response = useMutation<ISafetyConcernStatus, Error, ISafetyConcernStatusUpdateUpsert>({
      mutationFn: updateSafetyConcernStatus,
      ...options,
    })

    return response
  }

export default useUpdateSafetyConcernStatusMutation
