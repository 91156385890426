import config from '../../config'

export interface IGetTrainingsSearchByTrainingPerspectiveQueryObject {
  startedAt?: string
  endedAt?: string
  trainingStatusIds?: number[]
  employeeIds?: number[]
  trainingTemplateIds?: number[]
  notificationGroupIds?: number[]
  searchText?: string
  sortBy?: string
  sortOrder?: string
}

export interface IGetTrainingsSearchByTrainingPerspectiveRequestObject {
  method: 'post'
  url: string
  data?: IGetTrainingsSearchByTrainingPerspectiveQueryObject
}

export interface IGetTrainingsSearchByTrainingPerspectiveRequest {
  (
    querObject?: IGetTrainingsSearchByTrainingPerspectiveQueryObject,
  ): IGetTrainingsSearchByTrainingPerspectiveRequestObject
}

const getTrainingsSearchByTrainingPerspectiveRequest: IGetTrainingsSearchByTrainingPerspectiveRequest = querObject => {
  return {
    method: 'post',
    url: `${config.baseUrl}/Trainings/SearchByTrainingPerspective`,
    data: querObject,
  }
}

export default getTrainingsSearchByTrainingPerspectiveRequest
