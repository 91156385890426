import { cloneElement, useMemo } from 'react'
import { node } from 'prop-types'

import claimType from './claim-type'
import useAccessControl from './use-access-control'

export const ReadOnly = ({ children, claims }) => {
  const { hasAccess } = useAccessControl()

  const resourceClaims = useMemo(() => (Array.isArray(claims) ? claims : [claims]), [claims])

  const allowed = useMemo(
    () => resourceClaims.map(claim => hasAccess(claim)).every(x => x),
    [hasAccess, resourceClaims],
  )

  return allowed ? children : <div>{cloneElement(children, { disabled: true })}</div>
}

ReadOnly.propTypes = {
  children: node.isRequired,
  claims: claimType.isRequired,
}

export default ReadOnly
