import config from '../../config'

import { IGetSortSafetyItemUniqueValuesMutationRequest } from './get-sort-safety-item-unique-values-mutation-request-types'

const getSortSafetyItemUniqueValuesMutationRequest: IGetSortSafetyItemUniqueValuesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/SafetyItemUniqueValues/Sort`,
  }
}

export default getSortSafetyItemUniqueValuesMutationRequest
