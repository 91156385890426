import React, { useReducer } from 'react'

import { AppContext, initialState } from './app-context'
import reducer from './reducer'

const withAppContext = Component => {
  const Wrapped = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const value = { state, dispatch }

    return (
      <AppContext.Provider value={value}>
        <Component {...props} state={state} dispatch={dispatch} />
      </AppContext.Provider>
    )
  }

  return Wrapped
}

export default withAppContext
