import config from '../../config'
import { buildGetQuery } from '../../util'

import { IPostRequestObject, IPagedResults, INotam } from '../../interfaces'

type QueryObject = {
  notamIds?: number
  numbers?: string[]
  types?: string[]
  classifications?: string[]
  searchText?: string
  issuedFrom?: string
  issuedTo?: string
  effectiveStartFrom?: string
  effectiveStartTo?: string
  effectiveEndFrom?: string
  effectiveEndTo?: string
  limit?: number
  page?: number
  sortBy?: string
  sortOrder?: string
}

const getNotamsSearchRequest = (queryObject: QueryObject | undefined): IPostRequestObject => ({
  method: 'post',
  url: `${config.baseUrl}/Notams/Search`,
  data: queryObject,
})

const useNotamsSearchQuery = buildGetQuery<undefined, QueryObject | undefined, IPagedResults<INotam[]> | undefined>({
  getRequest: (_, queryObject) => getNotamsSearchRequest(queryObject),
  getQueryKey: (_, queryObject) => [
    'notams-search',
    queryObject?.notamIds,
    queryObject?.numbers,
    queryObject?.types,
    queryObject?.classifications,
    queryObject?.searchText,
    queryObject?.issuedFrom,
    queryObject?.issuedTo,
    queryObject?.effectiveStartFrom,
    queryObject?.effectiveStartTo,
    queryObject?.effectiveEndFrom,
    queryObject?.effectiveEndTo,
    queryObject?.limit,
    queryObject?.page,
    queryObject?.sortBy,
    queryObject?.sortOrder,
  ],
})

export default useNotamsSearchQuery
