import React from 'react'
import { string, bool, func, arrayOf, shape, oneOfType, number } from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import DragPreview from './drag-preview'
import { Button } from '../../button'

import useDualList from './use-dual-list'
import List from './list'

import s from './dual-list.module.scss'

const DualList = props => {
  const {
    unselectedTitle,
    selectedTitle,
    options,
    value,
    onChange,
    canFilter,
    disabled,
    extraOptions,
    getSelectedOptionName,
  } = props

  const {
    unselectedOptions,
    selectedOptions,
    itemsToAdd,
    itemsToRemove,
    handleSelectedItemsToAddChange,
    handleAddAllClick,
    isAddAllBtnDisabled,
    handleAddClick,
    isAddBtnDisabled,
    handleSelectedItemsToRemoveChange,
    handleRemoveClick,
    isRemoveAllBtnDisabled,
    handleRemoveAllClick,
    isRemoveBtnDisabled,
    handleDropUnselectedItems,
    handleDropSelectedItems,
  } = useDualList({ options, value, onChange, disabled })

  return (
    <DndProvider backend={HTML5Backend}>
      <DragPreview />

      <div className={s['dual-list-wrapper']}>
        <List
          canFilter={canFilter}
          disabled={disabled}
          options={unselectedOptions}
          value={itemsToAdd}
          onChange={handleSelectedItemsToAddChange}
          title={unselectedTitle}
          handleDropItems={handleDropSelectedItems}
        />

        <div className={s['dual-list-controll-wrapper']}>
          <Button type="secondary" onClick={handleAddAllClick} disabled={isAddAllBtnDisabled} buttonType="button">
            Add All
          </Button>
          <Button type="secondary" onClick={handleAddClick} disabled={isAddBtnDisabled} buttonType="button">
            Add
          </Button>
          <Button type="secondary" onClick={handleRemoveClick} disabled={isRemoveBtnDisabled} buttonType="button">
            Remove
          </Button>
          <Button type="secondary" onClick={handleRemoveAllClick} disabled={isRemoveAllBtnDisabled} buttonType="button">
            Remove All
          </Button>
        </div>

        <List
          canFilter={canFilter}
          disabled={disabled}
          options={selectedOptions}
          value={itemsToRemove}
          onChange={handleSelectedItemsToRemoveChange}
          title={selectedTitle}
          handleDropItems={handleDropUnselectedItems}
          extraOptions={extraOptions}
          getSelectedOptionName={getSelectedOptionName}
        />
      </div>
    </DndProvider>
  )
}

DualList.defaultProps = {
  options: [],
  value: [],
  getSelectedOptionName: ({ label, value }) => label,
}

DualList.propTypes = {
  unselectedTitle: string,
  selectedTitle: string,
  options: arrayOf(
    shape({
      label: oneOfType([string, number]),
      value: oneOfType([string, number]),
    }),
  ).isRequired,
  value: arrayOf(oneOfType([string, number])),
  onChange: func,
  canFilter: bool,
  disabled: bool,
  getSelectedOptionName: func,
}

export default DualList
