import { FC, useMemo } from 'react'

import ChecklistFilterFormFields from './checklist-filter-fields'

import { IChecklistFilterFormProps } from './checklist-filter-form-types'

const ChecklistFilterForm: FC<IChecklistFilterFormProps> = props => {
  const { formName, handleSubmit, submitting, disabled, values } = props

  const isDisabled = useMemo(() => submitting || disabled, [submitting, disabled])

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <ChecklistFilterFormFields disabled={isDisabled} values={values} />
    </form>
  )
}

export default ChecklistFilterForm
