import { FC, useMemo, useCallback, useEffect, useState, ReactElement } from 'react'
import { DefinedUseQueryResult } from '@tanstack/react-query'

import {
  Button,
  CrudModal,
  SafetyConcernActionForm,
  safetyConcernActionValuesToSafetyConcernActionAddUpsert,
  safetyConcernActionValuesToSafetyConcernActionUpdateUpsert,
  safetyConcernActionToSafetyConcernActionValues,
  ISafetyConcernActionValues,
} from '../../../components'
import SafetyConcernActionsCardsList from './safety-concern-actions-cards-list'

import { useModal } from '../../../hooks'
import {
  useDeleteSafetyConcernActionMutation,
  useCreateSafetyConcernActionMutation,
  useUpdateSafetyConcernActionMutation,
} from '../../../services'
import { useProfile } from '../../../security'

import { ISafetyConcern, ISafetyConcernAction } from '../../../interfaces'

type SafetyConcernInfoProps = {
  hasWriteAccess?: boolean
  safetyConcernQuery: DefinedUseQueryResult<ISafetyConcern | undefined, Error>
  setHeaderControls: (headerControls?: ReactElement) => void
  safetyConcernActionsPagination: {
    pageSize: number
    pageIndex: number
  }
  setSafetyConcernActionsPagination: (values: { [key: string]: number }) => void
}

const SafetyConcernInfo: FC<SafetyConcernInfoProps> = props => {
  const {
    hasWriteAccess,
    safetyConcernQuery,
    setHeaderControls,
    safetyConcernActionsPagination,
    setSafetyConcernActionsPagination,
  } = props

  const { airportId } = useProfile()

  const safetyConcern = safetyConcernQuery?.data

  const safetyConcernActions = safetyConcern?.safetyConcernActions

  const {
    isOpen: isSafetyConcernActionModalOpen,
    closeModal: closeSafetyConcernActionModal,
    openModal: openSafetyConcernActionModal,
  } = useModal()

  const [crudModalMode, setCrudModalMode] = useState<'create' | 'delete' | 'update'>()

  const handleOpenCreateSafetyConcernActionModal = useCallback(() => {
    setCrudModalMode('create')
    openSafetyConcernActionModal()
  }, [openSafetyConcernActionModal])

  const [deletingSafetyConcernAction, setDeletingSafetyConcernAction] = useState<ISafetyConcernAction>()

  const handleOpenDeleteSafetyConcernActionModal = useCallback(
    (safetyConcernAction: ISafetyConcernAction) => {
      setDeletingSafetyConcernAction(safetyConcernAction)
      setCrudModalMode('delete')
      openSafetyConcernActionModal()
    },
    [openSafetyConcernActionModal],
  )

  const [updatingSafetyConcernAction, setUpdatingSafetyConcernAction] = useState<ISafetyConcernAction>()

  const handleOpenUpdateSafetyConcernActionModal = useCallback(
    (safetyConcernAction: ISafetyConcernAction) => {
      setUpdatingSafetyConcernAction(safetyConcernAction)
      setCrudModalMode('update')
      openSafetyConcernActionModal()
    },
    [openSafetyConcernActionModal],
  )

  const headerControls = useMemo(
    () => <Button onClick={handleOpenCreateSafetyConcernActionModal}>Log New Action</Button>,
    [handleOpenCreateSafetyConcernActionModal],
  )

  useEffect(() => {
    setHeaderControls(headerControls)
    return () => setHeaderControls(undefined)
  }, [setHeaderControls, headerControls])

  const safetyConcernActionActionsButtonOptions = useMemo(
    () =>
      hasWriteAccess
        ? [
            {
              label: 'Update',
              handler: (safetyConcernAction: ISafetyConcernAction) =>
                handleOpenUpdateSafetyConcernActionModal(safetyConcernAction),
            },
            {
              label: 'Delete',
              handler: (safetyConcernAction: ISafetyConcernAction) =>
                handleOpenDeleteSafetyConcernActionModal(safetyConcernAction),
            },
          ]
        : undefined,
    [hasWriteAccess, handleOpenDeleteSafetyConcernActionModal, handleOpenUpdateSafetyConcernActionModal],
  )

  const onActivityChangeMutator = useCallback(async (args: any, state: any, mutate: any) => {
    mutate.changeValue(state, 'action', () => args[0])
  }, [])

  const mutators = useMemo(() => ({ onActivityChange: onActivityChangeMutator }), [onActivityChangeMutator])

  if (!safetyConcern) {
    return null
  }

  return (
    <>
      <CrudModal
        modalSettings={{
          isOpen: isSafetyConcernActionModalOpen,
          closeModal: closeSafetyConcernActionModal,
          mode: crudModalMode,
        }}
        createSettings={{
          title: 'Safety Concern Action Taken',
          useCreateMutation: useCreateSafetyConcernActionMutation,
          formValuesToAddUpsert: (safetyConcernActionValues: ISafetyConcernActionValues) =>
            safetyConcernActionValuesToSafetyConcernActionAddUpsert(safetyConcernActionValues, {
              safetyConcernId: safetyConcern.id,
              airportId,
            }),
          onCreateSuccessMessage: 'Action successfully created',
          onCreateErrorMessage: 'Something when wrong while creating action',
          onSuccessCallback: safetyConcernQuery?.refetch,
        }}
        updateSettings={{
          title: 'Safety Concern Action Taken',
          useUpdateMutation: useUpdateSafetyConcernActionMutation,
          entityToFormValues: safetyConcernActionToSafetyConcernActionValues,
          formValuesToUpdateUpsert: (safetyConcernActionValues: ISafetyConcernActionValues) =>
            safetyConcernActionValuesToSafetyConcernActionUpdateUpsert(
              safetyConcernActionValues,
              updatingSafetyConcernAction,
            ),
          onUpdateSuccessMessage: 'Action successfully updated',
          onUpdateErrorMessage: 'Something when wrong while updating action',
          onSuccessCallback: safetyConcernQuery?.refetch,
          updatingItem: updatingSafetyConcernAction,
        }}
        deleteSettings={{
          title: 'Safety Concern Action Taken',
          useDeleteMutation: useDeleteSafetyConcernActionMutation,
          onDeleteSuccessMessage: 'Action successfully deleted',
          onDeleteErrorMessage: 'Something when wrong while deleting action',
          onSuccessCallback: safetyConcernQuery?.refetch,
          deletingItem: deletingSafetyConcernAction,
        }}
        FormComponent={SafetyConcernActionForm}
        formName="safety-concern-action"
        mutators={mutators}
      />

      <SafetyConcernActionsCardsList
        safetyConcernActions={safetyConcernActions}
        pagination={safetyConcernActionsPagination}
        setPagination={setSafetyConcernActionsPagination}
        actionsButtonOptions={safetyConcernActionActionsButtonOptions}
        isLoading={safetyConcernQuery.isFetching}
      />
    </>
  )
}

export default SafetyConcernInfo
