import { FC, useCallback, useMemo } from 'react'

import RFFFields from '../../react-final-form-fields'
import Fields from '../../fields'
import { Tags } from '../../../tags'
import { SearchInput } from '../../../search-input'

import { useFacilitiesQuery, useTagsQuery } from '../../../../services'
import { useProfile } from '../../../../security'

import { getPlaceholderTextFromLabel } from '../../../../util'
import { validateRequired } from '../../../../util/validation'
import { filterNodes } from './inspection-type-master-form-utils'

import {
  IInspectionTypeMasterFormProps,
  CheckboxTreeFilter,
  SetCheckboxTreeFilter,
} from './inspection-type-master-form-types'
import { ICondition, IFacility } from '../../../../interfaces'

import s from './inspection-type-master-form.module.scss'

const nameLabel = 'Facility & Locations'

const InspectionTypeMasterForm: FC<IInspectionTypeMasterFormProps> = props => {
  const { handleSubmit, submitting, formName } = props

  const { airportId } = useProfile()

  const { data: facilities } = useFacilitiesQuery(
    { airportId },
    { includeDetail: true, activeOnly: true },
    { refetchOnWindowFocus: false },
  )

  const nodeOptions = useMemo(
    () =>
      ((facilities as IFacility[] | undefined) || [])
        .filter(facility => facility.conditions.length > 0)
        .map(facility => ({
          label: facility.name,
          value: facility.id,
          original: facility,
          labelDataTestId: 'facility',
          children: facility.conditions.map(condition => ({
            original: condition,
            label: condition.name,
            value: condition.id,
            labelDataTestId: 'condition',
          })),
        })),
    [facilities],
  )

  const { data: tags, isFetching: isTagsLoading } = useTagsQuery({ airportId }, undefined, {
    refetchOnWindowFocus: false,
  })

  const tagOptions = useMemo(
    () =>
      (tags || [])
        .filter(tag => tag.tagCategory === 4 || tag.tagCategory === 5)
        .map(tag => ({ label: tag.name, value: tag.id })),
    [tags],
  )

  const renderFilter = useCallback(
    ({ filter, setFilter }: { filter: CheckboxTreeFilter; setFilter: SetCheckboxTreeFilter }) => {
      return (
        <div className="d-flex">
          <div className="w-100 mr-1">
            <SearchInput
              value={filter?.globalSearch}
              onChange={globalSearch => setFilter({ ...filter, globalSearch })}
            />
          </div>

          <div className="w-100 ml-1">
            <Fields.Select
              componentProps={{
                options: tagOptions,
                value: filter?.tagIds || [],
                onChange: (tagIds: number[]) => setFilter({ ...filter, tagIds }),
                placeholder: 'Enter Labels...',
                disabled: isTagsLoading,
                isMulti: true,
              }}
            />
          </div>
        </div>
      )
    },
    [tagOptions, isTagsLoading],
  )

  return (
    <form onSubmit={handleSubmit} id={formName}>
      <RFFFields.CheckboxTree
        renderFilter={renderFilter}
        className={s['nodes-tree-wrapper']}
        name="facilityConditions"
        nodes={nodeOptions}
        placeholder={getPlaceholderTextFromLabel(nameLabel)}
        validate={validateRequired}
        disabled={submitting}
        filterNodes={filterNodes}
        renderLabel={(original: IFacility | ICondition, payload: any) => (
          <div className="ml-2 d-flex" data-test-id={payload?.labelDataTestId}>
            {original.name}

            <div className="ml-2">
              <Tags tags={original?.tags || []} tagsType="lightSuccess" />
            </div>
          </div>
        )}
        canFilter
      />
    </form>
  )
}

export default InspectionTypeMasterForm
