import qs from 'qs'

import { buildGetQuery } from '../../util'
import config from '../../config'

type Params = {
  airportId: number
  inspectionTypeId: number
}

type QueryObject = {
  inspectionSubtypeId?: number
  isPreview?: boolean
  detailed?: boolean
}

const getInspectionReportRequest = (params: Params, queryObject: QueryObject | undefined): any => {
  const { airportId, inspectionTypeId } = params

  const { isPreview, ...data } = queryObject || {}

  const queryString = qs.stringify({ preview: isPreview }, { addQueryPrefix: true })

  return {
    method: 'get',
    responseType: isPreview ? 'text/html' : 'blob',
    url: `${config.baseUrl}/${airportId}/Inspections/RenderBlankReport/${inspectionTypeId}${queryString}`,
    data: data,
  }
}

const useInspectionReportQuery = buildGetQuery<Params, QueryObject | undefined, Blob | string | undefined>({
  getRequest: getInspectionReportRequest,
  getQueryKey: (params, queryObject) => ['inspection-report', params.airportId, queryObject?.isPreview || ''],
})

export default useInspectionReportQuery
