import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getReopenChecklistMutationRequest from './get-reopen-checklist-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'

const useReopenChecklistMutation: IUseUpdateMutation<undefined, undefined> = (params, options = {}) => {
  const request = useMemo(() => getReopenChecklistMutationRequest(params), [params])

  const reopenChecklist: IUpdateFn<undefined, undefined> = useCallback(
    async () => axios({ ...request }).then(res => res.data),
    [request],
  )

  const response = useMutation<undefined, Error, undefined>({
    mutationFn: reopenChecklist,
    ...options,
  })

  return response
}

export default useReopenChecklistMutation
