import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { IUseUpdateMutation, IUpdateFn, IGetPutRequest } from '../../interfaces'
import config from '../../config'

const getAssociateNotamsToInspectionMutationRequest: IGetPutRequest = params => {
  const { airportId, id } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Inspections/${id}/AssociateNotams`,
  }
}

const useAssociateNotamsToInspectionMutation: IUseUpdateMutation<void, number[]> = (params, options = {}) => {
  const request = useMemo(() => getAssociateNotamsToInspectionMutationRequest(params), [params])

  const associateNotamsToInspection: IUpdateFn<number[], void> = useCallback(
    async (data: number[]) => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<void, Error, number[]>({
    mutationFn: associateNotamsToInspection,
    ...options,
  })

  return response
}

export default useAssociateNotamsToInspectionMutation
