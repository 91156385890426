import { useState, useEffect } from 'react'

import { useAppContext } from '../../../app-context'

import { IUseMapCenter } from './use-map-center-types'

export const useMapCenter: IUseMapCenter = (props = {}) => {
  const { center } = props

  const { state } = useAppContext()
  const { mapSettings } = state.airport

  const [centerCoordinates, setCenterCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral | undefined
  >({
    // @ts-ignore
    lat: mapSettings?.latitude,
    // @ts-ignore
    lng: mapSettings?.longitude,
  })

  useEffect(() => {
    const coordinates = {
      lat: center?.lat || mapSettings?.latitude,
      lng: center?.lng || mapSettings?.longitude,
    }
    // @ts-ignore
    setCenterCoordinates(coordinates)
  }, [center, mapSettings])

  return [centerCoordinates, setCenterCoordinates]
}
