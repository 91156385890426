import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getUpdateWildlifeSpeciesMutationRequest from './get-update-airport-wildlife-species-mutation-request'

import { IUseUpdateMutation, IUpdateFn } from '../../interfaces'
import { IWildlifeSpecies } from '../../interfaces'

const useUpdateWildlifeSpeciesMutation: IUseUpdateMutation<IWildlifeSpecies[], number[]> = (params, options = {}) => {
  const request = useMemo(() => getUpdateWildlifeSpeciesMutationRequest(), [])

  const updateWildlifeSpecies: IUpdateFn<number[], IWildlifeSpecies[]> = useCallback(
    async data => axios({ ...request, data }).then(res => res.data),
    [request],
  )

  const response = useMutation<IWildlifeSpecies[], Error, number[]>({
    mutationFn: updateWildlifeSpecies,
    ...options,
  })

  return response
}

export default useUpdateWildlifeSpeciesMutation
