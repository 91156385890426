import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getDiscrepancyByIdQueryRequest from './get-discrepancy-by-id-query-request'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IDiscrepancy } from '../../interfaces'

const useDiscrepancyByIdQuery: IUseGetQuery<any, IDiscrepancy> = (params, queryObject = {}, options = {}) => {
  const request = useMemo(() => getDiscrepancyByIdQueryRequest(params, queryObject), [params, queryObject])

  const fetchDiscrepancyById: IGetFn<IDiscrepancy> = useCallback(() => axios(request).then(res => res.data), [request])

  const queryKey = useMemo(
    // @ts-ignore
    () => ['discrepancy-by-id', params.airportId, params.inspectionId, params.discrepancyId, queryObject],
    // @ts-ignore
    [params.airportId, params.inspectionId, params.discrepancyId, queryObject],
  )

  const response = useQuery<IDiscrepancy | undefined, Error>(queryKey, fetchDiscrepancyById, {
    initialData: undefined,
    refetchOnWindowFocus: false,
    ...options,
  })

  return { ...response, queryKey }
}

export default useDiscrepancyByIdQuery
