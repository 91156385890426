import baseStatus from './base-status'
import classification from './classification'
import day from './day'
import features from './features'
import dayOfWeek from './day-of-week'
import frequency from './frequency'
import month from './month'
import notamType from './notam-type'
import safetyConcernActions from './safety-concern-actions'
import savedSearchFilterTypesOptions from './saved-search-filter-types'
import timeSpan from './time-span'
import trainingStatus from './training-status'
import weekOfMonth from './week-of-month'
import severity from './severity'

export const options = {
  baseStatus,
  classification,
  day,
  features,
  frequency,
  month,
  notamType,
  safetyConcernActions,
  dayOfWeek,
  weekOfMonth,
  trainingStatus,
  severity,
  timeSpan,
  savedSearchFilterTypesOptions,
}
