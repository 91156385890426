import useAccessControl from '../../security/access-control/use-access-control'
import useFeatureFlags from '../../features/use-feature-flags'
import removeNull from '../../util/remove-null'

// Returns an array of routes accessible to this user based on acccess control and feature flags
export const useNavigationRoutes = (routes: any[]) => {
  const { hasAccess } = useAccessControl()

  const { journalEnabled, notamsEnabled, trainingEnabled, checklistsEnabled, safetyManagementEnabled } =
    useFeatureFlags()

  const navRoutes = routes && Array.isArray(routes) ? routes : []

  // TODO try out adding the props to config to have this check a property vs hardcoding names here
  return navRoutes
    .map(route => {
      if (route.label === 'Journal' && !journalEnabled) {
        return null
      }

      if (route.label === 'NOTAMs' && !notamsEnabled) {
        return null
      }

      if ((route.label === 'Trainings' && !trainingEnabled) || (route.label === 'Training' && !trainingEnabled)) {
        return null
      }

      if (route.label === 'Checklists' && !checklistsEnabled) {
        return null
      }

      if (route.label === 'Safety Concerns' && !safetyManagementEnabled) {
        return null
      }

      return hasAccess(route.allowedClaims) ? route : null
    })
    .filter(removeNull)
}
