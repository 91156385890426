import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import config from '../../config'
import { EntityState } from '../entity-state'
import axios from 'axios'

const useWorkOrders = ({ airportId, entityState = EntityState.Open, includeDetail = false }) => {
  const url = `${config.baseUrl}/${airportId}/WorkOrders?entityState=${entityState}&includeDetail=${includeDetail}`
  const request = useMemo(() => ({ method: 'get', url }), [url])
  const fetchWorkOrders = useCallback(() => axios(request).then(res => res.data), [request])

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery(['work-orders', airportId, entityState, includeDetail], fetchWorkOrders, {
    initialData: [],
  })

  return { data, error, loading, execute: refetch }
}

export default useWorkOrders
