import { FC, useMemo, useRef } from 'react'
import { Table as ReactBootstrapTable } from 'react-bootstrap'
import cn from 'classnames'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { TableCaption } from './table-caption'
import { Spinner } from '../spinner'
import { TableHeader } from './table-header'
import TableBody from './table-body/table-body'
import { TablePagination } from './table-pagination'

import { TableProps } from './table-types'

import s from './table.module.scss'

const Table: FC<TableProps> = props => {
  const {
    tableInstance,
    isGlobalFilterEnabled = false,
    actions,
    totalItems = 0,
    className = '',
    tableWrapperClassName = '',
    renderAdditionalFilters,
    renderSecondHeader,
    reorderSettings,
    isLoading,
    isError,
  } = props

  const tableRef = useRef<HTMLTableElement>(null)

  const { rows } = tableInstance.getRowModel()

  const isTableLoading = useMemo(() => rows.length !== 0 && isLoading, [rows.length, isLoading])

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={cn(s['table-container'], className)}>
        <TableCaption
          tableInstance={tableInstance}
          isGlobalFilterEnabled={isGlobalFilterEnabled}
          renderAdditionalFilters={renderAdditionalFilters}
          actions={actions}
          isRowsDraggable={Boolean(reorderSettings?.isRowsDraggable)}
        />

        {typeof renderSecondHeader === 'function' && renderSecondHeader()}

        <div className={cn(s['table-wrapper'], tableWrapperClassName)}>
          {isTableLoading && (
            <div className={s['tr-loader']}>
              <Spinner />
            </div>
          )}

          <ReactBootstrapTable className={cn(s['table'])} ref={tableRef}>
            <TableHeader tableInstance={tableInstance} isRowsDraggable={Boolean(reorderSettings?.isRowsDraggable)} />

            <tbody>
              <TableBody
                tableInstance={tableInstance}
                reorderSettings={reorderSettings}
                isLoading={!isTableLoading && isLoading}
                isError={isError}
              />
            </tbody>
          </ReactBootstrapTable>
        </div>

        {tableInstance.getPaginationRowModel() && (
          <TablePagination tableInstance={tableInstance} totalItems={totalItems} />
        )}
      </div>
    </DndProvider>
  )
}

export default Table
