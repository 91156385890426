import React, { useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Select } from '..'
import ButtonFilteredDropdownSelect from './button-filtered-dropdown-select'
import ButtonFilteredDropdownGroup from './button-filtered-dropdown-group'

import s from './button-filtered-dropdown.module.scss'
import { useClickOutside } from '../../hooks'

type ButtonFilteredDropdownProps = {
  options: any[]
  onSelectOption: Function
  buttonText?: string
  placeholderText: string
  noMatchesText: string
  className: string
  btnClassName: string
  onItemSelected: Function
  disabled?: boolean
}

const ButtonFilteredDropdown: React.FC<ButtonFilteredDropdownProps> = ({
  options = [],
  onSelectOption = () => {},
  onItemSelected = () => {},
  buttonText,
  placeholderText = 'Type here to filter...',
  noMatchesText = 'No matches found.',
  className = '',
  btnClassName = '',
  disabled,
}) => {
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [buttonRef, setButtonRef] = useState(null)
  const buttonRefCB = useCallback((node: any) => {
    if (node) {
      setButtonRef(node)
    }
  }, [])

  useClickOutside({ current: buttonRef }, () => setIsCreateOpen(false))

  return (
    <ButtonFilteredDropdownSelect
      className={`${s['filtered-dropdown-container']} ${className}`}
      isOpen={isCreateOpen}
      buttonRef={buttonRef}
      onClose={() => {
        setIsCreateOpen(!isCreateOpen)
      }}
      target={
        <Button
          className={'text-nowrap '.concat(btnClassName)}
          ref={buttonRefCB}
          onClick={() => {
            setIsCreateOpen(!isCreateOpen)
          }}
          disabled={disabled}
        >
          {buttonText}
          <i className="bi bi-chevron-down ml-2" />
        </Button>
      }
    >
      <Select
        autoFocus
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null,
        }}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        escapeClearsValue={false}
        filterOption={(candidate: any, input: any) => candidate.label.toLowerCase().includes(input.toLowerCase())}
        isClearable={false}
        isSearchable={true}
        menuIsOpen
        noOptionsMessage={() => noMatchesText}
        // @ts-ignore
        onChange={(...args) => {
          setIsCreateOpen(false)
          onSelectOption(...args)
        }}
        options={options}
        formatGroupLabel={ButtonFilteredDropdownGroup}
        placeholder={placeholderText}
        disabled={disabled}
      />
    </ButtonFilteredDropdownSelect>
  )
}

export default ButtonFilteredDropdown
