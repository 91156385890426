import s from './button-filtered-dropdown.module.scss'

type ButtonFilteredDropdownMenuProps = {
  menuref: any
  isPopperReady: boolean
  children: any
}

const ButtonFilteredDropdownMenu: React.FC<ButtonFilteredDropdownMenuProps> = ({
  menuref,
  isPopperReady,
  children,
}) => {
  return (
    <div className={s['filtered-dropdown-menu']} ref={menuref}>
      {isPopperReady && children}
    </div>
  )
}

export default ButtonFilteredDropdownMenu
