import { useMemo } from 'react'

import { useProfile } from '../../../../security'
import {
  useWorkOrderStatusesQuery,
  usePrioritiesQuery,
  useWorkOrderTypesQuery,
  useFacilitiesQuery,
  useNotificationGroupsQuery,
  useInspectionSubtypesQuery,
} from '../../../../services'

import { IWorkOrderFilterUpsertValues } from './work-order-filter-form-types'
import { ICondition } from '../../../../interfaces'

const useWorkorderFilterQueries = (values?: IWorkOrderFilterUpsertValues) => {
  const { airportId } = useProfile()

  const statusesQuery = useWorkOrderStatusesQuery(
    { airportId },
    { activeOnly: true, entityState: 'All' },
    { refetchOnWindowFocus: false },
  )
  const statusOptions = useMemo(
    () =>
      (statusesQuery?.data || []).map(status => ({
        label: status.name,
        value: status.id,
      })),
    [statusesQuery?.data],
  )

  const prioritiesQuery = usePrioritiesQuery({ airportId }, { activeOnly: true }, { refetchOnWindowFocus: false })

  const priorityOptions = useMemo(
    () =>
      (prioritiesQuery?.data || []).map(priority => ({
        label: priority.name,
        value: priority.id,
      })),
    [prioritiesQuery?.data],
  )

  const workOrderTypesQuery = useWorkOrderTypesQuery(
    { airportId },
    { activeOnly: true },
    { refetchOnWindowFocus: false },
  )

  const workOrderTypesOptions = useMemo(
    () =>
      (workOrderTypesQuery?.data || []).map(workOrderType => ({
        label: workOrderType.name,
        value: workOrderType.id,
      })),
    [workOrderTypesQuery?.data],
  )

  const facilitiesQuery = useFacilitiesQuery(
    { airportId },
    { activeOnly: true, includeDetail: true },
    { refetchOnWindowFocus: false },
  )

  const facilitiesOptions = useMemo(
    () =>
      (facilitiesQuery?.data || []).map(facility => ({
        label: facility.name,
        value: facility.id,
      })),
    [facilitiesQuery?.data],
  )

  const inspectionSubtypesQuery = useInspectionSubtypesQuery({ airportId }, { activeOnly: true })

  const inspectionSubtypeOptions = useMemo(
    () =>
      (inspectionSubtypesQuery?.data || []).map(inspectionSubtypes => ({
        label: inspectionSubtypes.name,
        value: inspectionSubtypes.id,
      })),
    [inspectionSubtypesQuery?.data],
  )

  const conditionsOptions = useMemo(() => {
    if (!Array.isArray(values?.facilityIds) || !Array.isArray(facilitiesQuery?.data)) {
      return []
    }

    const conditions = facilitiesQuery?.data
      .filter(facility => (values?.facilityIds || []).indexOf(facility.id) > -1)
      .reduce<ICondition[]>((conditions, facility) => [...conditions, ...facility.conditions], [])

    return conditions.map(condition => ({ label: condition.name, value: condition.id }))
  }, [facilitiesQuery, values?.facilityIds])

  const notificationGroupsQuery = useNotificationGroupsQuery({ airportId }, { includeDetail: true, activeOnly: true })

  const notificationGroupOptions = useMemo(
    () =>
      (notificationGroupsQuery?.data || []).map(notificationGroup => ({
        label: notificationGroup.name,
        value: notificationGroup.id,
      })),
    [notificationGroupsQuery?.data],
  )

  const dateTypeOptions = [
    { label: 'WO Started', value: 'StartedAt' },
    { label: 'WO Ended', value: 'EndedAt' },
  ]

  return {
    facilitiesQuery,
    facilitiesOptions,

    conditionsOptions,

    statusesQuery,
    statusOptions,

    prioritiesQuery,
    priorityOptions,

    workOrderTypesQuery,
    workOrderTypesOptions,

    inspectionSubtypesQuery,
    inspectionSubtypeOptions,

    notificationGroupsQuery,
    notificationGroupOptions,

    dateTypeOptions
  }
}

export default useWorkorderFilterQueries
