import config from '../../config'
import { buildGetQuery } from '../../util'

import { IActivityLog, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
}

const getActivityLogsRequest = (params: Params): IGetRequestObject => {
  const { airportId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/ActivityLogs`,
  }
}

const useActivityLogsQuery = buildGetQuery<Params, undefined, IActivityLog[] | undefined>({
  getRequest: getActivityLogsRequest,
  getQueryKey: params => ['activity-logs', params.airportId],
})

export default useActivityLogsQuery
