import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import getDownloadInspectionCSVQueryRequest from './get-download-locations-mutation-request'

import { IUseDownloadInspectionCSVMutation, IFetchInspectionCSVFile } from './types'

function download(blob: Blob) {
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.href = url
  link.download = 'inspections'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

const useDownloadInspectionCSVQuery: IUseDownloadInspectionCSVMutation = (params, options = {}) => {
  const request = useMemo(() => getDownloadInspectionCSVQueryRequest(params), [params])

  const fetchFacilityLocationsCSVFile: IFetchInspectionCSVFile = useCallback(
    async (data: any) =>
      axios({ ...request, data }).then(res => {
        download(new Blob([res.data], { type: 'text/csv' }))

        return res.data
      }),
    [request],
  )

  const response = useMutation<Blob, Error>({
    mutationFn: fetchFacilityLocationsCSVFile,
    ...options,
  })

  return response
}

export default useDownloadInspectionCSVQuery
