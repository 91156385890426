import qs from 'qs'
import { QueryObserverOptions } from '@tanstack/react-query'

import { buildGetQuery } from '../../util'
import config from '../../config'

type QueryObject = {
  isPreview?: boolean
  detailed?: boolean
}

const getSafetyConcernReportRequest = (params: undefined, queryObject: QueryObject | undefined): any => {
  const { isPreview, ...data } = queryObject || {}

  const queryString = qs.stringify({ preview: isPreview }, { addQueryPrefix: true })

  return {
    method: 'get',
    responseType: isPreview ? 'text/html' : 'blob',
    url: `${config.baseUrl}/SafetyConcerns/RenderBlankReport/${queryString}`,
    data: data,
  }
}

const useSafetyConcernsReportQueryBase = buildGetQuery<undefined, QueryObject | undefined, Blob | string | undefined>({
  getRequest: getSafetyConcernReportRequest,
  getQueryKey: (params, queryObject) => ['safety-concern-report', queryObject?.isPreview, queryObject?.detailed],
})

const useSafetyConcernsReportQuery = (
  queryObject: QueryObject | undefined,
  options?: QueryObserverOptions<Blob | string | undefined, Error>,
) => useSafetyConcernsReportQueryBase(undefined, queryObject, options)

export default useSafetyConcernsReportQuery
