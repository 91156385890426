import React from 'react'

type NotesProps = {
  className?: string
  onChange: () => any
  rows: number
  value: string
  required: boolean
}

const Notes: React.FC<NotesProps> = ({ className = '', onChange, rows = 10, value = '', required = false, ...rest }) => (
  <textarea
    className={`form-control ${className}`}
    onChange={onChange}
    rows={rows}
    value={value || ''}
    required={required}
    {...rest}
  ></textarea>
)

export default Notes
