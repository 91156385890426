import { useMemo } from 'react'
import { QueryObserverOptions } from '@tanstack/react-query'

import config from '../../config'
import { buildGetQuery } from '../../util'

import { bySortOrderAscending } from '../../util'

import { ISafetyItemUniqueValueListItem, IGetRequestObject } from '../../interfaces'

type Params = {
  airportId: number
  workOrderTypeId: number
}

const getUniqueValuesByWorkOrderTypeRequest = (params: Params): IGetRequestObject => {
  const { airportId, workOrderTypeId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/SafetyItemUniqueValues/ByWorkOrderType/${workOrderTypeId}`,
  }
}

const useSafetyItemUniqueValuesByWorkOrderTypeQueryBase = buildGetQuery<
  Params,
  undefined,
  ISafetyItemUniqueValueListItem[] | undefined
>({
  getRequest: getUniqueValuesByWorkOrderTypeRequest,
  getQueryKey: params => ['unique-values-by-work-order-type', params.airportId, params.workOrderTypeId],
})

const useSafetyItemUniqueValuesByWorkOrderTypeQuery = (
  params: Params,
  options?: QueryObserverOptions<ISafetyItemUniqueValueListItem[] | undefined, Error>,
) => {
  const safetyItemUniqueValuesByWorkOrderTypeQuery = useSafetyItemUniqueValuesByWorkOrderTypeQueryBase(
    params,
    undefined,
    options,
  )

  const data = useMemo(
    () => (safetyItemUniqueValuesByWorkOrderTypeQuery.data || []).sort(bySortOrderAscending),
    [safetyItemUniqueValuesByWorkOrderTypeQuery.data],
  )

  return { ...safetyItemUniqueValuesByWorkOrderTypeQuery, data }
}

export default useSafetyItemUniqueValuesByWorkOrderTypeQuery
