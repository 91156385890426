import axios from 'axios'

import config from '../../config'

const updateWorkOrderType = (params = {}, data) => {
  const { airportId } = params

  const url = `${config.baseUrl}/${airportId}/WorkOrderTypes`
  return axios.put(url, data)
}

export default updateWorkOrderType
