import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getTrainingsRequest, {
  IGetTrainingsSearchByTrainingPerspectiveQueryObject,
} from './get-trainings-search-by-training-perspective-query-request'

import { IUseGetQuery, IGetFn, ITrainingsByTrainingPerspectiveSearchResult } from '../../interfaces'

const useTrainingsSearchByTrainingPerspective: IUseGetQuery<
  IGetTrainingsSearchByTrainingPerspectiveQueryObject,
  ITrainingsByTrainingPerspectiveSearchResult[]
> = (params, queryObject, options = {}) => {
  const request = useMemo(() => getTrainingsRequest(queryObject), [queryObject])

  const fetchTrainings: IGetFn<ITrainingsByTrainingPerspectiveSearchResult[]> = useCallback(
    () => axios({ ...request }).then(res => res.data),
    [request],
  )

  const queryKey = useMemo(() => ['trainings-search-by-training-perspective', queryObject], [queryObject])

  const response = useQuery<ITrainingsByTrainingPerspectiveSearchResult[] | undefined, Error>(
    queryKey,
    fetchTrainings,
    {
      initialData: [],
      ...options,
    },
  )

  return { ...response, queryKey }
}

export default useTrainingsSearchByTrainingPerspective
