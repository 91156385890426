import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import getWildlifeSpeciesMasterListRequest from './get-wildlife-species-master-list-request'

import { IUseGetQuery, IGetFn } from '../../interfaces'
import { IWildlifeSpecies } from '../../interfaces'

const useWildlifeSpeciesMasterListQuery: IUseGetQuery<any, IWildlifeSpecies[]> = (
  params,
  queryObject = {},
  options = {},
) => {
  const request = useMemo(() => getWildlifeSpeciesMasterListRequest(params, queryObject), [params, queryObject])

  const fetchWildlifeSpeciesMasterList: IGetFn<IWildlifeSpecies[]> = useCallback(
    () => axios(request).then(res => res.data),
    [request],
  )

  const queryKey = useMemo(() => ['wildlife-species-master-list'], [])

  const response = useQuery<IWildlifeSpecies[] | undefined, Error>(queryKey, fetchWildlifeSpeciesMasterList, {
    initialData: undefined,
    ...options,
  })

  return { ...response, queryKey }
}

export default useWildlifeSpeciesMasterListQuery
