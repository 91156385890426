import React from 'react'
import cn from 'classnames'
import { bool, string, func } from 'prop-types'

import { radioGroupModes } from './radio-group.constants'
import RadioGroupView from './radio-group-view'

import s from './radio-group.module.scss'

const RadioGroup = props => {
  const { Radio, options, mode, onChange, value, name, disabled, isViewMode } = props

  if (!Array.isArray(options)) {
    return null
  }

  const containerClassName = cn(s['container'], {
    [s['container-horizontal']]: mode === radioGroupModes.horizontal,
    [s['container-vertical']]: mode === radioGroupModes.vertical,
  })

  return (
    <div className={containerClassName}>
      {isViewMode ? (
        <RadioGroupView value={value} options={options} />
      ) : (
        options.map(({ subLabel, value: optionValue }) => {
          const checked = value === optionValue

          return (
            <Radio
              key={optionValue}
              onChange={() => onChange(optionValue)}
              value={value}
              checked={checked}
              subLabel={subLabel}
              name={name}
              disabled={disabled}
            />
          )
        })
      )}
    </div>
  )
}

RadioGroup.defaultProps = { mode: radioGroupModes.horizontal }

RadioGroup.propTypes = {
  mode: string,
  onChange: func,
  isViewMode: bool,
  disabled: bool,
}

export default RadioGroup
