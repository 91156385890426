import { ComponentType } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import ChecklistContextProvider from './checklist-context/checklist-context'

const withChecklistContext = <T extends object>(Component: ComponentType<T>) => {
  const Wrapped = (props: any) => (
    <DndProvider backend={HTML5Backend}>
      <ChecklistContextProvider {...props}>
        <Component {...props} />
      </ChecklistContextProvider>
    </DndProvider>
  )

  return Wrapped
}

export default withChecklistContext
