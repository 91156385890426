import { Method } from 'axios'

import config from '../../config'

export interface IGetSortChecklistTemplatesMutationRequestParams {
  airportId: number
}

export interface IGetSortChecklistTemplatesMutationRequestObject {
  method: Method
  url: string
}

export interface IGetSortChecklistTemplatesMutationRequest {
  (params: IGetSortChecklistTemplatesMutationRequestParams): IGetSortChecklistTemplatesMutationRequestObject
}

const getSortChecklistTemplatesMutationRequest: IGetSortChecklistTemplatesMutationRequest = params => {
  const { airportId } = params

  return {
    method: 'put',
    url: `${config.baseUrl}/${airportId}/Checklists/Sort`,
  }
}

export default getSortChecklistTemplatesMutationRequest
